
const paymentConfirmationStepConfig = {
    
    
    component: () => import('./PaymentConfirmation'),
    flow: 'postBuy',
    hideCartLink: false,
    hidePromoBanner: true
};

export default paymentConfirmationStepConfig;
