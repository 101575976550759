import { List as IList } from 'immutable';

import programMessages, { optionalProgramMessageIds } from './programMessages';
import { formatProgramMessage } from './formatProgramMessage';
import { getInCatalog } from '../catalogs/catalogUtils';
import { getBundleValue } from '../bundles/bundlesUtils';


const findCatalogRange = (catalog) => {
    const minRange = Math.min(
        getInCatalog(catalog, ['egcRangedCatalog', 'start'], Infinity),
        getInCatalog(catalog, ['plasticRangedCatalog', 'start'], Infinity),
        ...getInCatalog(catalog, ['egcFixedCatalog'], [Infinity]),
        ...getInCatalog(catalog, ['plasticFixedCatalog'], [Infinity])
    );
    const maxRange = Math.max(
        getInCatalog(catalog, ['egcRangedCatalog', 'end'], 0),
        getInCatalog(catalog, ['plasticRangedCatalog', 'end'], 0),
        ...getInCatalog(catalog, ['egcFixedCatalog'], [0]),
        ...getInCatalog(catalog, ['plasticFixedCatalog'], [0])
    );
    return { minRange, maxRange };
};

export const generateProgramProps = (group, index, {
    name,
    intl,
    classicCardsDescription,
    defaultCurrencyCode,
    classicCatalogs,
    getFaceplateByProgram,
    selectedProgram,
    windowWidth,
    shouldFocus,
    DEFAULT_ELECTRONIC_FACEPLATES,
    DEFAULT_PHYSICAL_FACEPLATES,
    getAltImageByProgram,
    brandCode,
    showCurrencyCode,
    showCurrencyList,
    getExcludedBillingStates,
    getIncludedBillingStates
}) => {
    const programCode = group.get('programCode');
    const descriptionMessageKey = `${programCode}.${optionalProgramMessageIds.programDescription}`;
    const descriptionExists = !!intl.messages[descriptionMessageKey];
    const description = descriptionExists ? formatProgramMessage(intl,
        { id: descriptionMessageKey }, programCode, { programCode }) : null;
    const text = programCode
        ? formatProgramMessage(intl, programMessages.programTitle, programCode, { programCode })
        : intl.formatMessage(programMessages.classicExperienceTitle);
    const showClassicCardsDescription = Boolean(!programCode && classicCardsDescription);
    
    let previewFaceplate = getFaceplateByProgram(programCode, DEFAULT_ELECTRONIC_FACEPLATES, brandCode);
    if (previewFaceplate && previewFaceplate.size <= 0) {
        previewFaceplate = getFaceplateByProgram(programCode, DEFAULT_PHYSICAL_FACEPLATES, brandCode);
    }
    
    const previewFaceplates = IList.of(previewFaceplate);
    const catalogs = group.get('catalogs', classicCatalogs);
    const rangeCurrencyCode = catalogs.has(defaultCurrencyCode) ? defaultCurrencyCode : catalogs.keySeq().first();
    let { minRange, maxRange } = findCatalogRange(catalogs.get(rangeCurrencyCode));

    
    
    const bundleAmount = getBundleValue(catalogs, rangeCurrencyCode);

    
    
    
    
    if (bundleAmount !== 0) {
        minRange = bundleAmount;
        maxRange = bundleAmount;
    }

    const currencyList = catalogs.keySeq().toList().filter(currencyCode => currencyCode !== rangeCurrencyCode);
    const altImage = getAltImageByProgram(programCode);
    const previewImageFilename = altImage.get('previewImageFilename');
    const previewImageAltText = altImage.get('previewImageAltText');
    const excludedBillingStates = getExcludedBillingStates(programCode);
    const includedBillingStates = getIncludedBillingStates(programCode);
    return {
        name,
        intl,
        programCode,
        text,
        description,
        classicCardsDescription,
        showClassicCardsDescription,
        previewFaceplates,
        defaultCurrencyCode,
        rangeCurrencyCode,
        minRange,
        maxRange,
        currencyList,
        showDenomRange: true,
        isChecked: programCode === selectedProgram,
        windowWidth,
        shouldFocus,
        previewImageAltText,
        previewImageFilename,
        brandCode,
        showCurrencyCode,
        showCurrencyList,
        excludedBillingStates,
        includedBillingStates
    };
};
