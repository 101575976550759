import isLength from './isLength';

export const PHONE_E164_REGEX = /^((\+)?(\d{1,3}))?[- ]?((\(\d{3,4}\))|\d{3,4})[- ]?((\d{3}[- ]?\d{4})|\d{6,8})$/gm;

export const stripPhoneNumber = dirtyInput => dirtyInput.replace(/[^+\d]/g, '');


export const validateBillingPhoneNumberRule = invalidMessage => (phoneNumber) => {
    if (phoneNumber && !isLength((stripPhoneNumber(phoneNumber)), { min: 10, max: 15 })) {
        return invalidMessage;
    }
    return null;
};

export const validatePhoneNumberRule = invalidMessage => (phoneNumber) => {
    if (phoneNumber) {
        if (!stripPhoneNumber(phoneNumber).match(PHONE_E164_REGEX)) {
            return invalidMessage;
        }
        
        if (!isLength((stripPhoneNumber(phoneNumber)), { min: 12, max: 18 })) {
            return invalidMessage;
        }
    }
    return null;
};

export default validatePhoneNumberRule;
