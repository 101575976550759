import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { injectIntl, intlShape } from 'react-intl';
import { Seq } from 'immutable';
import { Panel } from 'cstar-react-primitives/lib/redux-form/layout/Panel';
import { TextAreaGroup } from 'cstar-react-primitives/lib/redux-form/TextAreaGroup';

import FormButton from '../primitive/buttons/FormButton';
import getFieldCounterAriaLabel from '../primitive/getFieldCounterAriaLabel';
import { newItemReduxForm, MESSAGE_FIELD } from '../item/newItemForm';
import Accessories from '../accessories/Accessories';

import MessageInspiration from './MessageInspiration';
import messageIntl from './messageIntl';
import { validate, warn } from './validate';



export const normalizeLineBreaks = isPlastic => (value) => {
    if (!value || !isPlastic) return value;
    
    
    const EST_MAX_LINE = 68;
    const nineLines = value.split('\n', 9);
    const ninthLine = nineLines[8];
    if (ninthLine && ninthLine.length > EST_MAX_LINE) {
        const trimmedNinth = ninthLine.substring(0, EST_MAX_LINE);
        nineLines.pop();
        nineLines.push(trimmedNinth);
    }
    return nineLines.join('\n');
};

export const MessageForm = ({
    anyTouched,
    invalid,
    handleSubmit,
    submitAction,
    getSubmitMessage,
    submitFailed,
    intl,
    isAccessoriesSupported,
    isPlastic,
    isSelfBuy,
    maxLength,
    messageInspiration,
    cashbotName,
    form
}) => {
    const messageFormProps = {
        atts: {
            maxLength
        },
        isPlastic,
        component: TextAreaGroup,
        name: MESSAGE_FIELD,
        label: intl.formatMessage(messageIntl.label),
        formName: form,
        cashbotName,
        doFocus: false,
        format: normalizeLineBreaks(isPlastic),
        normalize: normalizeLineBreaks(isPlastic),
        counterAriaLabel: getFieldCounterAriaLabel(intl)
    };
    const messageInspirationProps = {
        name: MESSAGE_FIELD,
        formName: form,
        options: messageInspiration
    };
    const buttonProps = {
        btnText: intl.formatMessage(getSubmitMessage({ isSelfBuy, isPlastic })),
        anyTouched,
        invalid,
        submitFailed,
        cashbotName,
        formName: form
    };

    return (
        <form
            method="POST"
            onSubmit={handleSubmit((values) => {
                let submitValues = values;
                if (!submitValues.has('message')) {
                    
                    submitValues = submitValues.set('message', null);
                }
                submitAction(submitValues);
            })}
        >
            <Panel>
                { !isSelfBuy && (
                    <Fragment>
                        <div className="message-field-container">
                            <Field {...messageFormProps} />
                        </div>
                        { messageInspiration.size > 0
                            ? <MessageInspiration {...messageInspirationProps} />
                            : null
                        }
                    </Fragment>
                )}
                { isAccessoriesSupported && <Accessories isPlastic={isPlastic} />}
            </Panel>
            <FormButton {...buttonProps} />
        </form>
    );
};

MessageForm.defaultProps = {
    isPlastic: false,
    anyTouched: false,
    invalid: false,
    submitFailed: false
};

MessageForm.propTypes = {
    isAccessoriesSupported: PropTypes.bool.isRequired,
    isPlastic: PropTypes.bool,
    isSelfBuy: PropTypes.bool.isRequired,
    anyTouched: PropTypes.bool,
    invalid: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    getSubmitMessage: PropTypes.func.isRequired,
    submitFailed: PropTypes.bool,
    intl: intlShape.isRequired,
    maxLength: PropTypes.number.isRequired,
    messageInspiration: PropTypes.instanceOf(Seq).isRequired,
    cashbotName: PropTypes.string.isRequired,
    form: PropTypes.string.isRequired
};

export default injectIntl(newItemReduxForm(MessageForm, { validate, warn }));
