import { List as IList, Map as IMap } from 'immutable';
import { createSelector } from 'reselect';

import { newItemSelectedGroupOrdinal, newItemIsPlastic } from '../item/newItemForm';
import { getActiveProgramCode } from '../program/programSelectors';
import alphaNumericNormalizer from '../validate/alphaNumericNormalizer';

export const ordinalSort = (itemA, itemB) => (itemA.get('ordinal') > itemB.get('ordinal') ? 1 : -1);



export const getFaceplates = state => state.get('faceplate', IList());


export const convertFaceplateGroupsToMap = faceplateGroups => faceplateGroups
    .reduce((red, val) => red.concat(val.get('faceplates')), IList())
    .reduce((coll, fp) => coll.set(fp.get('code'), fp), IMap());


export const getFaceplatesFromGroups = createSelector(
    getFaceplates,
    convertFaceplateGroupsToMap
);


export const faceplateForCode = (state, code) => (
    getFaceplatesFromGroups(state).get(code)
);


export const hasFaceplates = createSelector(
    getFaceplates,
    faceplates => (faceplates ? Boolean(faceplates.size) : false)
);


export const getDigitalFaceplateGroups = createSelector(
    getFaceplates,
    faceplates => faceplates.filter(group => group.get('delivery') === 'ELECTRONIC').sort(ordinalSort)
);


export const hasDigital = createSelector(
    getDigitalFaceplateGroups,
    faceplates => (faceplates ? Boolean(faceplates.size) : false)
);


export const getPhysicalFaceplateGroups = createSelector(
    getFaceplates,
    faceplates => faceplates.filter(group => group.get('delivery') === 'PHYSICAL').sort(ordinalSort)
);


export const hasPlastic = createSelector(
    getPhysicalFaceplateGroups,
    faceplates => (faceplates ? Boolean(faceplates.size) : false)
);

export const isPlasticOnly = createSelector(
    hasDigital,
    hasPlastic,
    (digital, plastic) => (!digital && plastic)
);

export const hasPlasticAndDigital = createSelector(
    hasDigital,
    hasPlastic,
    (digital, plastic) => (digital && plastic)
);


export const primaryFaceplateForGroup = group => (
    group.get('faceplates', IList()).sort(ordinalSort).first(0)
);


export const physicalPrimaryFaceplate = createSelector(
    getPhysicalFaceplateGroups,
    groups => primaryFaceplateForGroup(groups.get(0, IMap()))
);


export const digitalPrimaryFaceplate = createSelector(
    getDigitalFaceplateGroups,
    groups => primaryFaceplateForGroup(groups.get(0, IMap()))
);

export const programPrimaryFaceplate = createSelector(
    getActiveProgramCode,
    getDigitalFaceplateGroups,
    getPhysicalFaceplateGroups,
    (programCode, digitalGroups, physicalGroups) => {
        if (!programCode) {
            return IMap();
        }
        const digital = digitalGroups.find(group => group.get('context') === `PROGRAM_${programCode}`);
        if (digital && digital.size > 0) {
            return digital.get('faceplates', IMap()).get(0, IMap());
        }
        const physical = physicalGroups.find(group => group.get('context') === `PROGRAM_${programCode}`);
        if (physical && physical.size) {
            return physical.get('faceplates', IMap()).get(0, IMap());
        }
        return IMap();
    }
);


export const primaryFaceplateForDeliveryType = (state, isPhysical) => (
    (isPhysical ? physicalPrimaryFaceplate(state) : digitalPrimaryFaceplate(state))
);


export const hasPhysicalCategories = createSelector(
    getPhysicalFaceplateGroups,
    groups => (groups.size > 1)
);


export const hasDigitalCategories = createSelector(
    getDigitalFaceplateGroups,
    groups => (groups.size > 1)
);


export const getSelectedFaceplateCategory = createSelector(
    newItemSelectedGroupOrdinal,
    newItemIsPlastic,
    getDigitalFaceplateGroups,
    getPhysicalFaceplateGroups,
    (ordinal, isPlastic, digitalGroups, physicalGroups) => {
        const groups = isPlastic ? physicalGroups : digitalGroups;
        return groups.find(group => group.get('ordinal') === ordinal);
    }
);

export const getFaceplateGroupForOrdinal = createSelector(
    getDigitalFaceplateGroups,
    getPhysicalFaceplateGroups,
    (digitalGroups, physicalGroups) => (isPhysical, ordinal) => {
        if (isPhysical !== null) {
            if (isPhysical) {
                return physicalGroups.find(group => group.get('ordinal') === ordinal);
            }
            return digitalGroups.find(group => group.get('ordinal') === ordinal);
        }
        return null;
    }
);


export const getSelectedCategoryHasFaceplate = createSelector(
    getSelectedFaceplateCategory,
    selectedCategory => (faceplateCode) => {
        if (selectedCategory) {
            return selectedCategory
                .get('faceplates', IList())
                .some(faceplate => faceplate.get('code') === faceplateCode);
        }
        return false;
    }
);


const groupsHaveSingleFaceplate = (groups) => {
    if (groups.size !== 1) {
        return false;
    }
    const faceplates = groups.get(0).get('faceplates', IList());
    return faceplates.size === 1;
};


export const hasSingleFaceplateForNewItem = createSelector(
    newItemIsPlastic,
    getDigitalFaceplateGroups,
    getPhysicalFaceplateGroups,
    (isPlastic, digitalGroups, physicalGroups) => (
        isPlastic
            ? groupsHaveSingleFaceplate(physicalGroups)
            : groupsHaveSingleFaceplate(digitalGroups)
    )
);


export const canSkipDesignPageForASingleFaceplate = createSelector(
    getDigitalFaceplateGroups,
    getPhysicalFaceplateGroups,
    hasPlasticAndDigital,
    hasSingleFaceplateForNewItem,
    (digitalGroups, physicalGroups, plasticAndDigital, singleFaceplateForNewItem) => {
        if (!plasticAndDigital) {
            return singleFaceplateForNewItem;
        }
        return groupsHaveSingleFaceplate(physicalGroups) && groupsHaveSingleFaceplate(digitalGroups);
    }
);


export const hasSingleFaceplateForOneCardType = createSelector(
    getDigitalFaceplateGroups,
    getPhysicalFaceplateGroups,
    (digitalGroups, physicalGroups) => (
        groupsHaveSingleFaceplate(physicalGroups) || groupsHaveSingleFaceplate(digitalGroups)
    )
);


export const isFaceplateInGroup = faceplateGroups => (faceplateCode) => {
    if (faceplateGroups) {
        return faceplateGroups.some(group => group.get('faceplates')
            .some(faceplate => (faceplate.get('code') === faceplateCode)));
    }
    return false;
};


export const isFaceplateDigital = createSelector(
    getDigitalFaceplateGroups,
    isFaceplateInGroup
);


export const isFaceplatePhysical = createSelector(
    getPhysicalFaceplateGroups,
    isFaceplateInGroup
);

export const isFaceplateInPreferredGroup = createSelector(
    getDigitalFaceplateGroups,
    getPhysicalFaceplateGroups,
    (digitalFaceplateGroups, physicalFaceplateGroups) => (cardTypeIsPlastic, faceplateCode) => {
        if (cardTypeIsPlastic !== null) {
            if (cardTypeIsPlastic) {
                return physicalFaceplateGroups.some(group => group.get('faceplates')
                    .some(faceplate => faceplate.get('code') === faceplateCode));
            }
            return digitalFaceplateGroups.some(group => group.get('faceplates')
                .some(faceplate => faceplate.get('code') === faceplateCode));
        }
        return false;
    }
);

export const getFaceplateCategoryOrdinalForCategoryName = createSelector(
    getDigitalFaceplateGroups,
    getPhysicalFaceplateGroups,
    (digitalFaceplateGroups, physicalFaceplateGroups) => (isPlastic, faceplateCategory) => {
        if (isPlastic !== null) {
            if (isPlastic) {
                const physical = physicalFaceplateGroups.find(
                    group => alphaNumericNormalizer(group.get('name')
                        .toLowerCase()) === faceplateCategory
                );
                return physical ? physical.get('ordinal') : null;
            }
            const digital = digitalFaceplateGroups.find(
                group => alphaNumericNormalizer(group.get('name')
                    .toLowerCase()) === faceplateCategory
            );
            return digital ? digital.get('ordinal') : null;
        }
        return null;
    }
);
