import React from 'react';
import { useSelector } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';

import { getBrandCode } from '../brand/brandSelectors';

import pluginsConfig from './pluginsConfig';
import { TwitterFeed } from './TwitterFeed';




const allPlugins = {
    TwitterFeed
};

export const Plugins = (props) => {
    const { intl } = props;
    const brandCode = useSelector(getBrandCode);

    if (!pluginsConfig[brandCode]) {
        return null;
    }

    const brandPluginsNames = Object.keys(pluginsConfig[brandCode]);

    return (
        <aside className="plugins-wrapper">
            {
                brandPluginsNames.map((brandPluginName) => {
                    if (allPlugins[brandPluginName]) {
                        const PluginComponent = allPlugins[brandPluginName];
                        const pluginProps = pluginsConfig[brandCode][brandPluginName] || {};
                        pluginProps.intl = intl;
                        return <PluginComponent key={`${brandPluginName}Key`} {...pluginProps} />;
                    }
                    return null;
                })
            }
        </aside>
    );
};

Plugins.propTypes = {
    intl: intlShape.isRequired
};

export default injectIntl(Plugins);
