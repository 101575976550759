import { fromJS, Set as ISet } from 'immutable';

import isEmptyRule from '../validate/isEmpty';
import postalCodeValidatorMX from '../validate/postalCodeValidatorMX';
import postalCodeValidatorAU from '../validate/postalCodeValidatorAU';
import postalCodeValidatorCA from '../validate/postalCodeValidatorCA';
import postalCodeValidatorGB from '../validate/postalCodeValidatorGB';
import postalCodeValidatorUS from '../validate/postalCodeValidatorUS';
import postalCodeValidatorBR from '../validate/postalCodeValidatorBR';
import postalCodeValidatorNL from '../validate/postalCodeValidatorNL';
import postalCodeValidatorJP from '../validate/postalCodeValidatorJP';
import { validatePhoneNumberRule, validateBillingPhoneNumberRule } from '../validate/validatePhoneNumberRule';
import { addressFieldNames } from '../address/addressModule';
import {
    ADDRESS_CONTEXT,
    COUNTRY_FIELDS
} from './addressFieldsModule';
import addressFields from './addressFieldMessages';

const ADDRESS_VALIDATION_FORMAT = /[()<>{}?~]+/;
const NAME_VALIDATION_FORMAT = /[()<>{}@#$%^&?/:]+/;

export const postalCodeValidator = (validatorFn, intlMessage) => (value) => {
    if (!value) {
        
        
        
        
        return undefined;
    }
    const valid = validatorFn(value);
    return (valid ? undefined : intlMessage);
};


export const defaultPostCodeValidator = value => value.length <= 10;

export const specialCharValidator = (validatorFn, intlMessage) => (value) => {
    if (!value) {
        return undefined;
    }
    const isSpecialCharExist = validatorFn(value);
    return (!isSpecialCharExist ? undefined : intlMessage);
};

export const defaultAddressSpecialCharValidator = value => ADDRESS_VALIDATION_FORMAT.test(value);

export const defaultNameSpecialCharValidator = value => NAME_VALIDATION_FORMAT.test(value);

export const countriesRequiringPostalCode = ISet([
    'AM',
    'AR',
    'AT',
    'AU',
    'AZ',
    'A2',
    'BA',
    'BD',
    'BE',
    'BG',
    'BL',
    'BN',
    'BR',
    'BY',
    'CA',
    'CH',
    'CN',
    'CY',
    'CZ',
    'DE',
    'DK',
    'DZ',
    'EE',
    'EN',
    'ES',
    'FI',
    'FO',
    'FR',
    'GB',
    'GE',
    'GG',
    'GL',
    'GR',
    'GU',
    'HO',
    'HR',
    'HU',
    'IC',
    'ID',
    'IL',
    'IN',
    'IT',
    'JE',
    'JP',
    'KG',
    'KO',
    'KR',
    'KZ',
    'LI',
    'LK',
    'LT',
    'LU',
    'LV',
    'ME',
    'MG',
    'MH',
    'MK',
    'MN',
    'MQ',
    'MX',
    'MY',
    'M3',
    'NB',
    'NL',
    'NO',
    'NZ',
    'PH',
    'PK',
    'PL',
    'PO',
    'PR',
    'PT',
    'RE',
    'RU',
    'RS',
    'SA',
    'SE',
    'SF',
    'SG',
    'SK',
    'SI',
    'SX',
    'TH',
    'TJ',
    'TM',
    'TN',
    'TR',
    'TU',
    'TW',
    'UA',
    'US',
    'UV',
    'UY',
    'UZ',
    'VA',
    'VI',
    'VN',
    'VL',
    'YT',
    'WL',
    'YA',
    'ZA'
]);




export const formValidatorsCommon = fromJS({
    [addressFieldNames.ADDRESS_COUNTRY]: [isEmptyRule(addressFields.COMMON.countryRequired)],
    [addressFieldNames.ADDRESS_STREET1]: [isEmptyRule(addressFields.COMMON.street1Required),
        specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)],
    [addressFieldNames.ADDRESS_STREET2]: [
        specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)]
});

export const fieldValidatorsCommon = fromJS({
    [addressFieldNames.ADDRESS_COUNTRY]: isEmptyRule(addressFields.COMMON.countryRequired),
    [addressFieldNames.ADDRESS_STREET1]: value => isEmptyRule(addressFields.COMMON.street1Required)(value)
        || specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)(value),
    [addressFieldNames.ADDRESS_STREET2]: value => specialCharValidator(
        defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed
    )(value)
});



export const formValidatorsPostalReq = fromJS({
    [addressFieldNames.ADDRESS_POSTCODE]: [isEmptyRule(addressFields.DEFAULT.postCodeRequired),
        postalCodeValidator(defaultPostCodeValidator, addressFields.DEFAULT.postCodeInvalid)],
    [addressFieldNames.ADDRESS_CITY]: [isEmptyRule(addressFields.DEFAULT.cityRequired),
        specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)]
}).merge(formValidatorsCommon);

export const fieldValidatorsPostalReq = fromJS({
    [addressFieldNames.ADDRESS_CITY]: value => isEmptyRule(addressFields.DEFAULT.cityRequired)(value)
        || specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)(value),
    [addressFieldNames.ADDRESS_POSTCODE]: value => isEmptyRule(addressFields.DEFAULT.postCodeRequired)(value)
        || postalCodeValidator(defaultPostCodeValidator, addressFields.DEFAULT.postCodeInvalid)(value)
}).merge(fieldValidatorsCommon);


export const formValidatorsUS = fromJS({
    [addressFieldNames.ADDRESS_CITY]: [isEmptyRule(addressFields.US.cityRequired),
        specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)],
    [addressFieldNames.ADDRESS_STATE]: [isEmptyRule(addressFields.US.stateRequired)],
    [addressFieldNames.ADDRESS_POSTCODE]: [
        isEmptyRule(addressFields.US.postCodeRequired),
        postalCodeValidator(postalCodeValidatorUS, addressFields.US.postCodeInvalid)
    ]
}).merge(formValidatorsCommon);

export const fieldValidatorsUS = fromJS({
    [addressFieldNames.ADDRESS_CITY]: value => isEmptyRule(addressFields.US.cityRequired)(value)
        || specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)(value),
    [addressFieldNames.ADDRESS_STATE]: isEmptyRule(addressFields.US.stateRequired),
    [addressFieldNames.ADDRESS_POSTCODE]: value => isEmptyRule(addressFields.US.postCodeRequired)(value)
        || postalCodeValidator(postalCodeValidatorUS, addressFields.US.postCodeInvalid)(value)
}).merge(fieldValidatorsCommon);


export const formValidatorsAU = fromJS({
    [addressFieldNames.ADDRESS_CITY]: [isEmptyRule(addressFields.DEFAULT.cityRequired),
        specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)],
    [addressFieldNames.ADDRESS_STATE]: [isEmptyRule(addressFields.AU.stateRequired)],
    [addressFieldNames.ADDRESS_POSTCODE]: [
        isEmptyRule(addressFields.AU.postCodeRequired),
        postalCodeValidator(postalCodeValidatorAU, addressFields.AU.postCodeInvalid)
    ]
}).merge(formValidatorsCommon);

export const fieldValidatorsAU = fromJS({
    [addressFieldNames.ADDRESS_CITY]: value => isEmptyRule(addressFields.DEFAULT.cityRequired)(value)
        || specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)(value),
    [addressFieldNames.ADDRESS_STATE]: isEmptyRule(addressFields.AU.stateRequired),
    [addressFieldNames.ADDRESS_POSTCODE]: value => isEmptyRule(addressFields.AU.postCodeRequired)(value)
        || postalCodeValidator(postalCodeValidatorAU, addressFields.AU.postCodeInvalid)(value)
}).merge(fieldValidatorsCommon);


export const formValidatorsCA = fromJS({
    [addressFieldNames.ADDRESS_CITY]: [isEmptyRule(addressFields.CA.cityRequired),
        specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)],
    [addressFieldNames.ADDRESS_STATE]: [isEmptyRule(addressFields.CA.stateRequired)],
    [addressFieldNames.ADDRESS_POSTCODE]: [
        isEmptyRule(addressFields.CA.postCodeRequired),
        postalCodeValidator(postalCodeValidatorCA, addressFields.CA.postCodeInvalid)
    ]
}).merge(formValidatorsCommon);

export const fieldValidatorsCA = fromJS({
    [addressFieldNames.ADDRESS_CITY]: value => isEmptyRule(addressFields.CA.cityRequired)(value)
        || specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)(value),
    [addressFieldNames.ADDRESS_STATE]: isEmptyRule(addressFields.CA.stateRequired),
    [addressFieldNames.ADDRESS_POSTCODE]: value => isEmptyRule(addressFields.CA.postCodeRequired)(value)
        || postalCodeValidator(postalCodeValidatorCA, addressFields.CA.postCodeInvalid)(value)
}).merge(fieldValidatorsCommon);


export const formValidatorsGB = fromJS({
    [addressFieldNames.ADDRESS_CITY]: [isEmptyRule(addressFields.GB.cityRequired),
        specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)],
    [addressFieldNames.ADDRESS_STATE]: [isEmptyRule(addressFields.GB.stateRequired)],
    [addressFieldNames.ADDRESS_POSTCODE]: [
        isEmptyRule(addressFields.GB.postCodeRequired),
        postalCodeValidator(postalCodeValidatorGB, addressFields.GB.postCodeInvalid)
    ]
}).merge(formValidatorsCommon);

export const fieldValidatorsGB = fromJS({
    [addressFieldNames.ADDRESS_CITY]: value => isEmptyRule(addressFields.GB.cityRequired)(value)
        || specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)(value),
    [addressFieldNames.ADDRESS_STATE]: isEmptyRule(addressFields.GB.stateRequired),
    [addressFieldNames.ADDRESS_POSTCODE]: value => isEmptyRule(addressFields.GB.postCodeRequired)(value)
        || postalCodeValidator(postalCodeValidatorGB, addressFields.GB.postCodeInvalid)(value)
}).merge(fieldValidatorsCommon);


export const formValidatorsIE = fromJS({
    [addressFieldNames.ADDRESS_CITY]: [isEmptyRule(addressFields.IE.cityRequired),
        specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)],
    [addressFieldNames.ADDRESS_POSTCODE]: [postalCodeValidator(defaultPostCodeValidator,
        addressFields.DEFAULT.postCodeInvalid)]
}).merge(formValidatorsCommon);

export const fieldValidatorsIE = fromJS({
    [addressFieldNames.ADDRESS_CITY]: value => isEmptyRule(addressFields.IE.cityRequired)(value)
        || specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)(value),
    [addressFieldNames.ADDRESS_POSTCODE]: value => postalCodeValidator(defaultPostCodeValidator,
        addressFields.DEFAULT.postCodeInvalid)(value)
}).merge(fieldValidatorsCommon);


export const formValidatorsJP = fromJS({
    [addressFieldNames.ADDRESS_CITY]: [isEmptyRule(addressFields.DEFAULT.cityRequired),
        specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)],
    [addressFieldNames.ADDRESS_STATE]: [isEmptyRule(addressFields.JP.stateRequired)],
    [addressFieldNames.ADDRESS_POSTCODE]: [
        isEmptyRule(addressFields.DEFAULT.postCodeRequired),
        postalCodeValidator(postalCodeValidatorJP, addressFields.DEFAULT.postCodeInvalid)
    ]
}).merge(formValidatorsCommon);

export const fieldValidatorsJP = fromJS({
    [addressFieldNames.ADDRESS_CITY]: value => isEmptyRule(addressFields.DEFAULT.cityRequired)(value)
        || specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)(value),
    [addressFieldNames.ADDRESS_STATE]: isEmptyRule(addressFields.JP.stateRequired),
    [addressFieldNames.ADDRESS_POSTCODE]: value => postalCodeValidator(postalCodeValidatorJP,
        addressFields.DEFAULT.postCodeInvalid)(value) || isEmptyRule(addressFields.DEFAULT.postCodeRequired)(value)
}).merge(fieldValidatorsCommon);


export const formValidatorsHK = fromJS({
    [addressFieldNames.ADDRESS_CITY]: [isEmptyRule(addressFields.HK.cityRequired),
        specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)],
    [addressFieldNames.ADDRESS_POSTCODE]: [postalCodeValidator(defaultPostCodeValidator,
        addressFields.DEFAULT.postCodeInvalid)]
}).merge(formValidatorsCommon);

export const fieldValidatorsHK = fromJS({
    [addressFieldNames.ADDRESS_CITY]: value => isEmptyRule(addressFields.HK.cityRequired)(value)
        || specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)(value),
    [addressFieldNames.ADDRESS_POSTCODE]: postalCodeValidator(defaultPostCodeValidator,
        addressFields.DEFAULT.postCodeInvalid)
}).merge(fieldValidatorsCommon);


export const formValidatorsCN = fromJS({
    [addressFieldNames.ADDRESS_CITY]: [isEmptyRule(addressFields.CN.cityRequired),
        specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)],
    [addressFieldNames.ADDRESS_STATE]: [isEmptyRule(addressFields.CN.stateRequired)],
    [addressFieldNames.ADDRESS_POSTCODE]: [isEmptyRule(addressFields.CN.postCodeRequired),
        postalCodeValidator(defaultPostCodeValidator, addressFields.DEFAULT.postCodeInvalid)]
}).merge(formValidatorsCommon);

export const fieldValidatorsCN = fromJS({
    [addressFieldNames.ADDRESS_CITY]: value => isEmptyRule(addressFields.CN.cityRequired)(value)
        || specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)(value),
    [addressFieldNames.ADDRESS_STATE]: isEmptyRule(addressFields.CN.stateRequired),
    [addressFieldNames.ADDRESS_POSTCODE]: value => isEmptyRule(addressFields.CN.postCodeRequired)(value)
        || postalCodeValidator(defaultPostCodeValidator, addressFields.DEFAULT.postCodeInvalid)(value)
}).merge(fieldValidatorsCommon);


export const formValidatorsMX = fromJS({
    [addressFieldNames.ADDRESS_CITY]: [isEmptyRule(addressFields.DEFAULT.cityRequired),
        specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)],
    [addressFieldNames.ADDRESS_STATE]: [isEmptyRule(addressFields.MX.stateRequired)],
    [addressFieldNames.ADDRESS_POSTCODE]: [isEmptyRule(addressFields.DEFAULT.postCodeRequired),
        postalCodeValidator(postalCodeValidatorMX, addressFields.DEFAULT.postCodeInvalid)]
}).merge(formValidatorsCommon);

export const fieldValidatorsMX = fromJS({
    [addressFieldNames.ADDRESS_CITY]: value => isEmptyRule(addressFields.DEFAULT.cityRequired)(value)
        || specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)(value),
    [addressFieldNames.ADDRESS_STATE]: isEmptyRule(addressFields.MX.stateRequired),
    [addressFieldNames.ADDRESS_POSTCODE]: value => isEmptyRule(addressFields.DEFAULT.postCodeRequired)(value)
        || postalCodeValidator(postalCodeValidatorMX, addressFields.DEFAULT.postCodeInvalid)(value)
}).merge(fieldValidatorsCommon);


export const formValidatorsBR = fromJS({
    [addressFieldNames.ADDRESS_CITY]: [isEmptyRule(addressFields.DEFAULT.cityRequired),
        specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)],
    [addressFieldNames.ADDRESS_STATE]: [isEmptyRule(addressFields.BR.stateRequired)],
    [addressFieldNames.ADDRESS_POSTCODE]: [
        isEmptyRule(addressFields.DEFAULT.postCodeRequired),
        postalCodeValidator(postalCodeValidatorBR, addressFields.DEFAULT.postCodeInvalid)
    ]
}).merge(formValidatorsCommon);

export const fieldValidatorsBR = fromJS({
    [addressFieldNames.ADDRESS_CITY]: value => isEmptyRule(addressFields.DEFAULT.cityRequired)(value)
        || specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)(value),
    [addressFieldNames.ADDRESS_STATE]: isEmptyRule(addressFields.BR.stateRequired),
    [addressFieldNames.ADDRESS_POSTCODE]: value => isEmptyRule(addressFields.DEFAULT.postCodeRequired)(value)
        || postalCodeValidator(postalCodeValidatorBR, addressFields.DEFAULT.postCodeInvalid)(value)
}).merge(fieldValidatorsCommon);


export const formValidatorsNL = fromJS({
    [addressFieldNames.ADDRESS_CITY]: [isEmptyRule(addressFields.DEFAULT.cityRequired),
        specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)],
    [addressFieldNames.ADDRESS_STATE]: [isEmptyRule(addressFields.NL.stateRequired)],
    [addressFieldNames.ADDRESS_POSTCODE]: [
        isEmptyRule(addressFields.NL.postCodeRequired),
        postalCodeValidator(postalCodeValidatorNL, addressFields.NL.postCodeInvalid)
    ]
}).merge(formValidatorsCommon);

export const fieldValidatorsNL = fromJS({
    [addressFieldNames.ADDRESS_CITY]: value => isEmptyRule(addressFields.DEFAULT.cityRequired)(value)
        || specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)(value),
    [addressFieldNames.ADDRESS_STATE]: isEmptyRule(addressFields.NL.stateRequired),
    [addressFieldNames.ADDRESS_POSTCODE]: value => isEmptyRule(addressFields.NL.postCodeRequired)(value)
        || postalCodeValidator(postalCodeValidatorNL, addressFields.NL.postCodeInvalid)(value)
}).merge(fieldValidatorsCommon);


export const formValidatorsDEFAULT = fromJS({
    [addressFieldNames.ADDRESS_CITY]: [isEmptyRule(addressFields.DEFAULT.cityRequired),
        specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)],
    
    [addressFieldNames.ADDRESS_POSTCODE]: [postalCodeValidator(defaultPostCodeValidator,
        addressFields.DEFAULT.postCodeInvalid)]

}).merge(formValidatorsCommon);

export const fieldValidatorsDEFAULT = fromJS({
    [addressFieldNames.ADDRESS_CITY]: value => isEmptyRule(addressFields.DEFAULT.cityRequired)(value)
        || specialCharValidator(defaultAddressSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)(value)
}).merge(fieldValidatorsCommon);


export const shippingNameAndPhoneValidation = fromJS({
    [addressFieldNames.ADDRESS_FULLNAME]: [isEmptyRule(addressFields.SHIPPING.fullNameRequired),
        specialCharValidator(defaultNameSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)],
    [addressFieldNames.ADDRESS_PHONE]: [validateBillingPhoneNumberRule(addressFields.COMMON.phoneInvalid)]
});

export const billingNameAndPhoneValidation = fromJS({
    [addressFieldNames.ADDRESS_FIRSTNAME]: [isEmptyRule(addressFields.BILLING.firstNameRequired),
        specialCharValidator(defaultNameSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)],
    [addressFieldNames.ADDRESS_LASTNAME]: [isEmptyRule(addressFields.BILLING.lastNameRequired),
        specialCharValidator(defaultNameSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)],
    [addressFieldNames.ADDRESS_PHONE]: [
        isEmptyRule(addressFields.COMMON.phoneRequired),
        validateBillingPhoneNumberRule(addressFields.COMMON.phoneInvalid)
    ]
});


export const getLocalizedFieldValidation = (countryCode) => {
    if (typeof countryCode === 'string' && countryCode.length === 2) {
        if (countryCode === COUNTRY_FIELDS.CANADA) {
            return formValidatorsCA;
        } if (countryCode === COUNTRY_FIELDS.HONG_KONG) {
            return formValidatorsHK;
        } if (countryCode === COUNTRY_FIELDS.IRELAND) {
            return formValidatorsIE;
        } if (countryCode === COUNTRY_FIELDS.JAPAN) {
            return formValidatorsJP;
        } if (countryCode === COUNTRY_FIELDS.GREAT_BRITAIN) {
            return formValidatorsGB;
        } if (countryCode === COUNTRY_FIELDS.UNITED_STATES) {
            return formValidatorsUS;
        } if (countryCode === COUNTRY_FIELDS.CHINA) {
            return formValidatorsCN;
        } if (countryCode === COUNTRY_FIELDS.AUSTRALIA) {
            return formValidatorsAU;
        } if (countryCode === COUNTRY_FIELDS.MEXICO) {
            return formValidatorsMX;
        } if (countryCode === COUNTRY_FIELDS.BRAZIL) {
            return formValidatorsBR;
        } if (countryCode === COUNTRY_FIELDS.NETHERLANDS) {
            return formValidatorsNL;
        } if (countriesRequiringPostalCode.has(countryCode)) {
            return formValidatorsPostalReq;
        }
        return formValidatorsDEFAULT;
    }
    return formValidatorsUS;
};


export const getNameAndPhoneFieldValidation = addressContext => (
    addressContext === ADDRESS_CONTEXT.BILLING ? billingNameAndPhoneValidation : shippingNameAndPhoneValidation
);

export const getLocalizedAddressFieldValidation = (countryCode, addressContext) => {
    const nameAndNumberValidation = getNameAndPhoneFieldValidation(addressContext);
    const addressFieldValidation = getLocalizedFieldValidation(countryCode);
    return addressFieldValidation.merge(nameAndNumberValidation);
};

export const defaultFieldValidators = fromJS({
    [addressFieldNames.ADDRESS_FULLNAME]: value => (
        isEmptyRule(addressFields.COMMON.fullNameRequired)(value)
        || specialCharValidator(defaultNameSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)(value)),
    [addressFieldNames.ADDRESS_FIRSTNAME]: value => (
        isEmptyRule(addressFields.COMMON.firstNameRequired)(value)
        || specialCharValidator(defaultNameSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)(value)),
    [addressFieldNames.ADDRESS_LASTNAME]: value => (
        isEmptyRule(addressFields.COMMON.lastNameRequired)(value)
        || specialCharValidator(defaultNameSpecialCharValidator, addressFields.COMMON.invalidCharacterUsed)(value)),
    [addressFieldNames.ADDRESS_PHONE]: value => (
        isEmptyRule(addressFields.COMMON.phoneRequired)(value)
        || validatePhoneNumberRule(addressFields.COMMON.phoneInvalid)(value)),
    [addressFieldNames.ADDRESS_POSTCODE]: value => (
        postalCodeValidator(defaultPostCodeValidator, addressFields.DEFAULT.postCodeInvalid)(value))
});

export default defaultFieldValidators;
