import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Map as IMap, List as IList } from 'immutable';
import { Panel } from 'cstar-react-primitives/lib/redux-form/layout/Panel';

import ProgramPreview from '../program/ProgramPreview';
import { giftCardFieldNames, newItemReduxForm } from '../item/newItemForm';
import FormButton from '../primitive/buttons/FormButton';
import RecipientNameField from './RecipientNameField';
import SenderNameField from './SenderNameField';
import SelfBuyFields from './SelfBuyFields';
import validate from './validate';
import TabPanel from '../primitive/TabPanel';

export const NameFields = props => (
    <div>
        <RecipientNameField {...props} />
        <SenderNameField {...props} />
    </div>
);

NameFields.propTypes = {
    intl: intlShape.isRequired,
    cashbotName: PropTypes.string.isRequired
};

const { IS_SELFBUY_FIELD } = giftCardFieldNames;

export const RecipientNameForm = ({
    anyTouched,
    invalid,
    handleSubmit,
    submitAction,
    submitFailed,
    cashbotName,
    intl,
    form,
    isSelfBuyEnabled,
    isSelfBuy,
    faceplate,
    faceplates,
    showFaceplate,
    submitButtonMessage
}) => {
    const fieldProps = { intl, cashbotName };
    const btnText = intl.formatMessage(submitButtonMessage);
    const buttonProps = {
        btnText,
        anyTouched,
        invalid,
        submitFailed,
        cashbotName,
        formName: form
    };
    const programPreviewProps = {
        defaultFaceplates: faceplates.size ? faceplates : IList.of(faceplate)
    };

    return (
        <form method="POST" onSubmit={handleSubmit(submitAction)}>
            { showFaceplate && <ProgramPreview {...programPreviewProps} /> }
            <Panel>
                { isSelfBuyEnabled && <SelfBuyFields {...fieldProps} /> }
                {}
                { !isSelfBuy && isSelfBuyEnabled && (
                    <TabPanel ariaLabelledBy={`${IS_SELFBUY_FIELD}-0-label`}>
                        <NameFields {...fieldProps} />
                    </TabPanel>
                )}
                { !isSelfBuyEnabled && !isSelfBuy && <NameFields {...fieldProps} />}
            </Panel>
            <FormButton {...buttonProps} />
        </form>
    );
};

RecipientNameForm.defaultProps = {
    handleSubmit: () => {},
    submitFailed: false,
    isSelfBuyEnabled: true,
    anyTouched: false,
    invalid: false,
    isSelfBuy: false,
    faceplate: IMap(),
    faceplates: IList(),
    showFaceplate: false
};

RecipientNameForm.propTypes = {
    anyTouched: PropTypes.bool,
    invalid: PropTypes.bool,
    cashbotName: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
    submitAction: PropTypes.func.isRequired,
    submitFailed: PropTypes.bool,
    intl: intlShape.isRequired,
    isSelfBuy: PropTypes.bool,
    isSelfBuyEnabled: PropTypes.bool,
    form: PropTypes.string.isRequired,
    faceplate: PropTypes.instanceOf(IMap),
    showFaceplate: PropTypes.bool,
    faceplates: PropTypes.instanceOf(IList),
    submitButtonMessage: PropTypes.object.isRequired
};

export default injectIntl(newItemReduxForm(RecipientNameForm, { validate }));
