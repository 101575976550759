import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Map as IMap } from 'immutable';
import { connect } from 'react-redux';
import { getFormSyncErrors } from 'redux-form/immutable';
import { ButtonErrorCount } from 'cstar-react-primitives/lib/redux-form/buttons/ButtonErrorCount';

import { primitiveMessages } from '../primitiveMessages';



export const getErrorCount = (syncErrors) => {
    if (syncErrors === undefined) return 0;
    return IMap.isMap(syncErrors)
        ? syncErrors.size : Object.keys(syncErrors).length;
};

export const getFirstErrorFieldName = (syncErrors) => {
    if (syncErrors === undefined) return null;
    if (Object.keys(syncErrors).length > 0) {
        return Object.keys(syncErrors)[0];
    }
    return null;
};

export const FormButton = ({
    anyTouched,
    invalid,
    submitFailed,
    type,
    btnText,
    cashbotName,
    errorCount,
    firstErrorField,
    ariaDescribedBy,
    intl,
    doFocus,
    disabled
}) => {
    const buttonProps = {
        anyTouched,
        invalid,
        submitFailed,
        type,
        btnText,
        cashbotName,
        errorCount,
        ariaDescribedBy,
        iconType: 'error',
        getErrorMessage: errors => intl.formatMessage(primitiveMessages.buttonErrorCount, { errorCount: errors }),
        skipToErrorLinkMessage: intl.formatMessage(primitiveMessages.accessibilityLinkError),
        firstErrorField,
        doFocus,
        disabled
    };

    return (
        <ButtonErrorCount {...buttonProps} />
    );
};

FormButton.defaultProps = {
    type: 'submit',
    errorCount: 0,
    firstErrorField: null,
    ariaDescribedBy: null,
    doFocus: false,
    disabled: false
};

FormButton.propTypes = {
    btnText: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['button', 'submit']),
    anyTouched: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    formName: PropTypes.string.isRequired,
    submitFailed: PropTypes.bool.isRequired,
    cashbotName: PropTypes.string.isRequired,
    doFocus: PropTypes.bool,
    
    errorCount: PropTypes.number,
    firstErrorField: PropTypes.string,
    ariaDescribedBy: PropTypes.string,
    
    intl: intlShape.isRequired,
    disabled: PropTypes.bool
};

export const mapStateToProps = (state, ownProps) => {
    const { formName } = ownProps;
    const syncErrors = getFormSyncErrors(formName, null)(state);

    return {
        errorCount: getErrorCount(syncErrors),
        firstErrorField: getFirstErrorFieldName(syncErrors)
    };
};

export default connect(mapStateToProps)(injectIntl(FormButton));
