export const isRegexMatch = (regExp, testString) => {
    let re;
    try {
        re = new RegExp(regExp);
    } catch (error) {
        return false;
    }
    return re.test(testString);
};


export const matchesRegexRule = (invalidMessage, regExp) => (testString) => {
    if (testString && !isRegexMatch(regExp, testString)) {
        return invalidMessage;
    }
    return null;
};

export default matchesRegexRule;
