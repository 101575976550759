import { connect, useDispatch, useSelector } from 'react-redux';
import { RadioGroup } from 'cstar-react-primitives/lib/microFields/RadioGroup';
import { injectIntl, intlShape } from 'react-intl';
import React, { useEffect, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List as IList } from 'immutable';
import PropTypes from 'prop-types';
import CardRow from './CardRow';
import { getDigitalFaceplateGroups, getPhysicalFaceplateGroups } from '../faceplate/faceplateSelectors';
import {
    giftCardFieldNames, newItemIsPlastic,
    newItemSelectedGroupOrdinal
} from '../item/newItemForm';
import { changeValue } from '../item/newItemFormModule';
import productDetailMessages from './ProductDetailMessages';
import { getPreferredCardType, getStorefrontFlowIsPDP } from '../routing/flowSelectors';

export const CardType = ({
    groups, pfaceplates, intl, preferredCardtype
}) => {
    const dispatch = useDispatch();

    const { search } = window.location;
    const params = new URLSearchParams(search);
    const faceplateCode = String(params.get('faceplateCode'));

    const [Dcode, setDcode] = useState(faceplateCode);
    const [Pcode, setPcode] = useState(faceplateCode);


    const [DigitalImageURL, setDigitalImageURL] = useState('');
    const [PhysicalImageURL, setPhysicalImageURL] = useState('');
    const [digitalAltText, setDigitalAltText] = useState('');
    const [physicalAltText, setPhysicalAltText] = useState('');
    const [isPhysicalFaceplatesPresent, setIsPhysicalFaceplatesPresent] = useState(true);
    const [isDigitalFaceplatesPresent, setIsDigitallFaceplatesPresent] = useState(true);

    const currentCardType = useSelector(state => newItemIsPlastic(state));
    const [cardType, setCardType] = useState(currentCardType);

    const isPDPFlow = useSelector(state => getStorefrontFlowIsPDP(state));

    useEffect(() => {
        if (isPDPFlow) {
            setCardType(currentCardType);
        } else {
            setCardType(preferredCardtype);
        }
        const hasCategories = (groups.size > 1);
        const digitalGroup = hasCategories
            ? groups.find(grp => grp.get('ordinal') === 1)
            : groups.first();
        const fs = digitalGroup && digitalGroup.get('faceplates');

        let firstFaceplate;

        if (fs && fs.size > 0) {
            firstFaceplate = fs.first();
        }

        if (!firstFaceplate) {
            setIsDigitallFaceplatesPresent(false);
        }

        const fpGroups = [];
        groups.forEach(
            (gO) => {
                fpGroups.push(gO);
            }
        );

        const fpgroupslength = fpGroups.length;

        let digitalFaceplate = '';
        let physicalFaceplate = '';

        for (let i = 0; i < fpgroupslength; i++) {
            const group = fpGroups[i];
            const faceplates = group.get('faceplates');
            const faceplate = faceplates.find(fp => fp.toJS().code === faceplateCode);

            if (faceplate) {
                digitalFaceplate = faceplate;
                break; 
            }
        }
        setIsPhysicalFaceplatesPresent(pfaceplates.toJS().length > 0);
        const pfaceplate = pfaceplates.find(fp => fp.toJS().code === faceplateCode);
        if (pfaceplate) {
            physicalFaceplate = pfaceplate;
        }

        if (digitalFaceplate !== '') {
            const { imageURL, altText, code } = digitalFaceplate.toJS();
            setDcode(code);
            setDigitalImageURL(imageURL);
            setDigitalAltText(altText);
        } else if (firstFaceplate) {
            
            const { imageURL, altText, code } = firstFaceplate.toJS();
            setDcode(code);
            setDigitalImageURL(imageURL);
            setDigitalAltText(altText);
        }

        if (physicalFaceplate !== '') {
            const { imageURL, altText, code } = physicalFaceplate.toJS();
            setPcode(code);
            setPhysicalImageURL(imageURL);
            setPhysicalAltText(altText);
        } else {
            
            const DefaultphysicalFaceplate = pfaceplates.toJS();
            if (DefaultphysicalFaceplate.length > 0) {
                const { imageURL, altText, code } = DefaultphysicalFaceplate[0];
                setPcode(code);
                setPhysicalImageURL(imageURL);
                setPhysicalAltText(altText);
            }
        }
    }, []); 

    useEffect(() => {
        if (cardType) {
            dispatch(changeValue(giftCardFieldNames.FACEPLATE_FIELD, Pcode));
        } else {
            dispatch(changeValue(giftCardFieldNames.FACEPLATE_FIELD, Dcode));
        }
    }, [Dcode, Pcode]);

    const handleNewDeliveryChange = (value) => {
        if (value === true) {
            dispatch(changeValue(giftCardFieldNames.IS_PLASTIC_FIELD, false));
            dispatch(changeValue(giftCardFieldNames.FACEPLATE_FIELD, Dcode));
        } else {
            dispatch(changeValue(giftCardFieldNames.FACEPLATE_FIELD, Pcode));
            dispatch(changeValue(giftCardFieldNames.IS_PLASTIC_FIELD, true));
        }
    };

    const digitalProps = {
        imageSrc: DigitalImageURL,
        altText: digitalAltText,
        title: intl.formatMessage(productDetailMessages.digitalHeading),
        description: intl.formatMessage(productDetailMessages.digitalInfo)
    };

    const physicalProps = {
        imageSrc: PhysicalImageURL,
        altText: physicalAltText,
        title: intl.formatMessage(productDetailMessages.physicalHeading),
        description: intl.formatMessage(productDetailMessages.physicalInfo)
    };

    const radioOptions = [];

    if (isDigitalFaceplatesPresent) {
        radioOptions.push({
            value: true,
            children:
    <div>
        <CardRow {...digitalProps} />
    </div>
        });
    }

    if (isPhysicalFaceplatesPresent) {
        radioOptions.push({
            value: false,
            children:
    <div>
        <CardRow {...physicalProps} />
    </div>
        });
    }

    const checkcardType = () => {
        if (isDigitalFaceplatesPresent && isPhysicalFaceplatesPresent) {
            if (cardType) {
                return radioOptions[1].value;
            }
            return radioOptions[0].value;
        }
        return radioOptions[0].value;
    };


    const radioGroupProps = {
        name: giftCardFieldNames.IS_PLASTIC_FIELD,
        cashbotName: 'delivery-method-picker',
        legend: 'Choose your delivery method',
        styleType: 'radiobutton',
        checkmarkClass: 'radio-icon',
        containerClass: 'delivery-method-container',
        radioButtonClass: 'delivery-method-radio-button',
        radioOptions,
        defaultValue: checkcardType(),
        handleChange: handleNewDeliveryChange
    };

    const singleCard = isPhysicalFaceplatesPresent ? <CardRow {...physicalProps} /> : <CardRow {...digitalProps} />;

    return (
        <div>
            {
                (isDigitalFaceplatesPresent && isPhysicalFaceplatesPresent)
                    ? <RadioGroup {...radioGroupProps} />
                    : singleCard
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    const groups = getDigitalFaceplateGroups(state);
    const groupOrdinal = newItemSelectedGroupOrdinal(state);
    const physicalGroups = getPhysicalFaceplateGroups(state);
    const preferredCardtype = getPreferredCardType(state);
    let pfaceplates;
    if (physicalGroups.size > 0) {
        pfaceplates = physicalGroups.first() ? physicalGroups.first().get('faceplates') : IList();
    } else {
        pfaceplates = IList();
    }
    return {
        groups, groupOrdinal, physicalGroups, pfaceplates, preferredCardtype
    };
};

CardType.propTypes = {
    intl: intlShape.isRequired,
    groups: ImmutablePropTypes.list.isRequired,
    pfaceplates: ImmutablePropTypes.list.isRequired,
    preferredCardtype: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(injectIntl(CardType));
