import { defineMessages } from 'react-intl';

const choiceMessages = defineMessages({
    physical: {
        id: 'choice.choiceMessages.physical',
        description: 'Label displayed on the card type design button for physical (plastic) gift cards',
        defaultMessage: 'Physical'
    },
    electronic: {
        id: 'choice.choiceMessages.electronic',
        description: 'Label displayed on the card type design button for electronic gift cards',
        defaultMessage: 'Digital'
    },
    physicalDesignHeader: {
        id: 'choice.choiceMessages.physicalDesignHeader',
        description: 'Section heading for selecting a faceplate image for the physical (plastic) gift card(s)',
        defaultMessage: 'Choose your Gift Card Design'
    },
    electronicDesignHeader: {
        id: 'choice.choiceMessages.electronicDesignHeader',
        description: 'Section heading for selecting a faceplate image for the electronic gift card(s)',
        defaultMessage: 'Choose your Gift Card Design'
    },
    cardTypeLegend: {
        id: 'choice.choiceMessages.cardTypeLegend',
        description: 'Descriptive text for screen readers to give card type selection context',
        defaultMessage: 'Choose a card type'
    },
    submitButtonDigitalText: {
        id: 'choice.choiceMessages.submitButtonDigitalText',
        description: 'Text shown on preceding steps on buttons which will move the user to the digital design step',
        defaultMessage: 'Send A Digital Card'
    },
    submitButtonPhysicalText: {
        id: 'choice.choiceMessages.submitButtonPhysicalText',
        description: 'Text shown on preceding steps on buttons which will move the user to the physical design step',
        defaultMessage: 'Send A Physical Card'
    }
});

export default choiceMessages;
