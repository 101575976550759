import { defineMessages } from 'react-intl';

export const primitiveMessages = defineMessages({
    checkboxToggleYes: {
        id: 'field.primitiveMessages.checkboxToggleYes',
        description: 'Toggle checkbox text for true/yes',
        defaultMessage: 'yes'
    },
    checkboxToggleNo: {
        id: 'field.primitiveMessages.checkboxToggleNo',
        description: 'Toggle checkbox text for false/no',
        defaultMessage: 'no'
    },
    paginationCount: {
        id: 'field.primitiveMessages.paginationCount',
        description: 'Descriptive text indicating how many items are being displayed (currentCount) '
        + 'out of how many in total (totalCount)',
        defaultMessage: 'Showing {currentCount, number} of {totalCount, number}'
    },
    requiredInstructionText: {
        id: 'primitive.layout.container.reqMsg.requiredInstructionText',
        description: 'instructional text that indicates that all fields are required, all form pages - ADA requirement',
        defaultMessage: 'All fields are required, unless they are marked optional.'
    },
    formLengthCount: {
        id: 'field.fieldMessages.formLengthCount',
        description: 'Helper text displaying how long some text is (current) out of a maximum length (maxLength)',
        defaultMessage: '{current, number}/{maxLength, number}'
    },
    fieldCounterAriaLabel: {
        id: 'field.fieldMessages.fieldCounterAriaLabel',
        description: 'Descriptive text for screen readers indicating the maximum length of a field',
        defaultMessage: 'The current character count is {current, number} out of {maxLength, number} max'
    },
    buttonErrorCount: {
        id: 'field.primitiveMessages.buttonErrorCount',
        description: 'Helper text displayed when there are one or more errors present on the page',
        defaultMessage: 'You have {errorCount, plural, one {1 error} other {{errorCount} errors}} to fix'
    },
    accessibilityLinkError: {
        id: 'field.primitiveMessages.accessibilityLinkError',
        description: 'accessibility hyperlink text shown when there is an error present on the page',
        defaultMessage: 'Click Here'
    }
});
