import React from 'react';
import { Alert } from 'cstar-react-primitives/lib/redux-form/alert/Alert';
import { Container } from 'cstar-react-primitives/lib/redux-form/layout/Container';

import errorMessages from './errorMessages';
import TranslatedPageTitle from '../header/TranslatedPageTitle';


export const ErrorRefresh = () => (
    <Container hasSlimPadding>
        <TranslatedPageTitle pageTitle={errorMessages.errorRefreshTitle} />
        <Alert
            alertType="error"
            hasHeading
            heading={errorMessages.errorRefreshTitle}
            message={errorMessages.errorRefreshDescription}
        />
    </Container>
);


export default ErrorRefresh;
