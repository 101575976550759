import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Map as IMap } from 'immutable';

import TimezoneField from '../react-timezone/TimezoneField';

import TimeFields from './TimeFields';


export const TimePicker = ({
    intl,
    cashbotName,
    recipientName,
    hour,
    onHourChange,
    minute,
    onMinuteChange,
    meridiem,
    onMeridiemChange,
    timeZone,
    onTimeZoneChange,
    hasTimePicker,
    isInitialDate
}) => {
    const timeFieldsProps = {
        intl,
        cashbotName,
        hour,
        onHourChange,
        minute,
        onMinuteChange,
        meridiem,
        onMeridiemChange,
        timeZone
    };
    const timezoneProps = {
        intl,
        cashbotName,
        recipientName,
        initialTimeZone: timeZone,
        onTimeZoneChange
    };

    if (!hasTimePicker || isInitialDate) {
        return null;
    }

    return (
        <div>
            <TimeFields {...timeFieldsProps} />
            <TimezoneField {...timezoneProps} />
        </div>
    );
};

TimePicker.defaultProps = {
    hasTimePicker: false,
    isInitialDate: false
};

TimePicker.propTypes = {
    intl: intlShape.isRequired,
    cashbotName: PropTypes.string.isRequired,
    isInitialDate: PropTypes.bool,
    recipientName: PropTypes.string.isRequired,
    hour: PropTypes.number.isRequired,
    onHourChange: PropTypes.func.isRequired,
    minute: PropTypes.number.isRequired,
    onMinuteChange: PropTypes.func.isRequired,
    meridiem: PropTypes.string.isRequired,
    onMeridiemChange: PropTypes.func.isRequired,
    timeZone: PropTypes.instanceOf(IMap).isRequired,
    onTimeZoneChange: PropTypes.func.isRequired,
    hasTimePicker: PropTypes.bool
};

export default TimePicker;
