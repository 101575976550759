import React, { useEffect } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import { passAlongPayerAuthenticationResultData } from 'capi';
import { getTransactionId, getMD } from '../app/bootstrap';
import { getCapiClientFormEncoded } from '../session/sessionSelectors';
import { logError } from '../utils/errorUtils';
import appStore from '../app/appStore';
import paymentMessages from '../payment/paymentMessages';

export const PayerAuthenticationStep = ({ intl }) => {
    const passAlongResultData = async (resultData) => {
        try {
            const state = appStore.getState();
            const capiFormEncoded = getCapiClientFormEncoded(state);

            const response = await passAlongPayerAuthenticationResultData(capiFormEncoded, resultData);

            if (response.decision !== 'ACCEPTED') {
                throw new Error(`Error passing along 3DS / PSD2 data: ${response}`);
            }
        } catch (error) {
            
            logError(error);
        }
    };

    useEffect(() => {
        const transactionId = getTransactionId();
        const merchantData = getMD();

        if (transactionId && merchantData) {
            const dataAsJson = {
                TransactionId: transactionId,
                MD: merchantData
            };

            
            
            const formattedData = Object.keys(dataAsJson)
                .map(key => `${key}=${encodeURIComponent(dataAsJson[key])}`).join('&');

            
            passAlongResultData(formattedData);
        }
    }, []);

    const stepUpResultsProps = {
        style: {
            position: 'relative',
            margin: '0 auto',
            width: '50%',
            minWidth: '260px',
            textAlign: 'center'
        }
    };

    
    const svgProps = {
        style: {
            enableBackground: 'new 0 0 46 46',
            height: '75px',
            display: 'block',
            margin: '30px auto'
        }
    };

    const outerStrokeProps = {
        points: '33,16 18,30 13,25',
        style: {
            fill: 'none',
            stroke: '#049647',
            strokeWidth: 2,
            strokeLineCap: 'round',
            strokeLineJoin: 'round',
            strokeMiterLimit: 10
        }
    };

    const innerStrokeProps = {
        
        d: 'M23,2c11.6,0,21,9.4,21,21s-9.4,21-21,21S2,34.6,2,23S11.4,2,23,2 M23,0C10.3,0,0,10.3,0,23c0,12.7,10.3,23,23,23c12.7,0,23-10.3,23-23C46,10.3,35.7,0,23,0L23,0z',
        style: {
            fill: '#049647'
        }
    };

    return (
        <div data-testid="payer-auth-step" style={{ position: 'relative', top: '100px' }}>
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 46 46"
                xmlSpace="preserve"
                {...svgProps}
            >
                <g>
                    <polyline {...outerStrokeProps} />
                    <g>
                        <path {...innerStrokeProps} />
                    </g>
                </g>
            </svg>
            <h2 {...stepUpResultsProps}>
                { intl.formatMessage(paymentMessages.stepUpResultsMessage) }
            </h2>
        </div>
    );
};

PayerAuthenticationStep.propTypes = {
    
    intl: intlShape.isRequired
};

export default injectIntl(PayerAuthenticationStep);
