import md5 from 'js-md5';
import { Map as IMap } from 'immutable';

export const ADDRESS_FULLNAME = 'fullName';
export const ADDRESS_FIRSTNAME = 'firstName';
export const ADDRESS_LASTNAME = 'lastName';
export const ADDRESS_STREET1 = 'street1';
export const ADDRESS_STREET2 = 'street2';
export const ADDRESS_CITY = 'city';
export const ADDRESS_STATE = 'state';
export const ADDRESS_POSTCODE = 'postalCode';
export const ADDRESS_PHONE = 'phoneNumber';
export const ADDRESS_COUNTRY = 'countryCode';

export const addressFieldNames = {
    ADDRESS_FULLNAME,
    ADDRESS_FIRSTNAME,
    ADDRESS_LASTNAME,
    ADDRESS_STREET1,
    ADDRESS_STREET2,
    ADDRESS_CITY,
    ADDRESS_STATE,
    ADDRESS_POSTCODE,
    ADDRESS_PHONE,
    ADDRESS_COUNTRY
};

export const initialState = IMap();
export const fieldKeys = IMap(addressFieldNames).toList();


export const SAVE_ADDRESS_FIELDS = '@@storefront/SAVE_ADDRESS_FIELDS';
export const REMOVE_ADDRESS_ID = '@@storefront/REMOVE_ADDRESS_ID';
export const SHIPPING_ADDRESS_SELECTED = '@@storefront/SHIPPING_ADDRESS_SELECTED';
export const SHIPPING_ADDRESS_UPDATED = '@@storefront/SHIPPING_ADDRESS_UPDATED';



export function addressReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_ADDRESS_FIELDS: {
            const address = action.fieldValues.filter(
                (_, key) => fieldKeys.includes(key)
            );
            return state.set(
                getAddressHash(address),
                address
            );
        }
        case REMOVE_ADDRESS_ID:
            return state.remove(action.uid);
        default:
            return state;
    }
}



export function saveAddressFields(fieldValues) {
    return {
        type: SAVE_ADDRESS_FIELDS,
        fieldValues
    };
}


export function removeAddressId(uid) {
    return {
        type: REMOVE_ADDRESS_ID,
        uid
    };
}

export function shippingAddressSelected(address, placeId) {
    return {
        type: SHIPPING_ADDRESS_SELECTED,
        address,
        placeId
    };
}


export function shippingAddressUpdated() {
    return { type: SHIPPING_ADDRESS_UPDATED };
}


export function getAddressHash(fieldValues) {
    const name = fieldValues.get(ADDRESS_FULLNAME)
        ? fieldValues.get(ADDRESS_FULLNAME)
        : `${fieldValues.get(ADDRESS_FIRSTNAME)}
        ${fieldValues.get(ADDRESS_LASTNAME)}`;
    const hashString = `${name}
    ${fieldValues.get(ADDRESS_STREET1)}
    ${fieldValues.get(ADDRESS_STREET2)}
    ${fieldValues.get(ADDRESS_CITY)}
    ${fieldValues.get(ADDRESS_STATE)}
    ${fieldValues.get(ADDRESS_POSTCODE)}
    ${fieldValues.get(ADDRESS_COUNTRY)}`;
    return md5(hashString.replace(/\s/g, '').toLowerCase());
}


export function addressToID(fieldValues, key = 'addressID') {
    return fieldValues.set(
        key, getAddressHash(fieldValues)
    ).filterNot((_, fieldKey) => fieldKeys.includes(fieldKey));
}

export default addressReducer;
