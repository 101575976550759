import { defineMessages } from 'react-intl';

const denomMessages = defineMessages({
    radiogroupLabel: {
        id: 'denom.denomMessages.radiogroupLabel',
        description: 'label for denomination radiogroup',
        defaultMessage: 'Choose an amount'
    },
    denomRange: {
        id: 'denom.denomMessages.denomRange',
        description: 'Min to max range of denomination',
        defaultMessage: '{minRange} — {maxRange}'
    },
    denomRangeWithCurrencyCode: {
        id: 'denom.denomMessages.denomRangeWithCurrencyCode',
        description: 'Min to max range of denomination, including the currency code',
        defaultMessage: '{minRange} — {maxRange} {currencyCode}'
    },
    otherAvailableCurrenciesLabel: {
        id: 'denom.denomMessages.otherAvailableCurrenciesLabel',
        description: 'A label preceding a list of other currencies that are supported by the program',
        defaultMessage: 'Also available in:'
    },
    denomRadioButtonValue: {
        id: 'denom.denomMessages.denomRadioButtonValue',
        description: 'The value displayed on the denomination picker radio button',
        defaultMessage: '{formattedDenom}'
    }
});

export default denomMessages;
