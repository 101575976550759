import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { CheckboxToggle } from 'cstar-react-primitives/lib/redux-form/microFields/CheckboxToggle';

import { ITEM_ACCESSORY } from '../item/newItemForm';

import accessoriesMessages from './accessoriesMessages';


export const AccessoriesToggle = ({
    cashbotName,
    intl,
    handleClick,
    value
}) => {
    const toggleFieldProps = {
        label: intl.formatMessage(accessoriesMessages.toggleLabel),
        cashbotName,
        input: {
            value,
            onChange: handleClick,
            name: ITEM_ACCESSORY
        },
        styleType: 'checkbox'
    };
    return <CheckboxToggle {...toggleFieldProps} />;
};

AccessoriesToggle.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    handleClick: PropTypes.func.isRequired,
    value: PropTypes.bool.isRequired
};

export default AccessoriesToggle;
