import { reducer as baseCapiReducer } from 'capi/redux/immutable';
import { Map as IMap } from 'immutable';
import { compose } from 'redux';
import { combineReducers } from 'redux-immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import { mergePersistedState } from 'redux-localstorage';
import { intlReducer } from 'react-intl-redux';

import { getCapiUrl, getOrderingToken } from './bootstrap';

import brandReducer from '../brand/brandModule';
import promoReducer from '../promo/promoModule';
import paymentReducer from '../payment/paymentModule';
import { paypalReducer } from '../paypal/paypalModule';
import addressReducer from '../address/addressModule';
import { faceplateReducer } from '../faceplate/faceplateModule';
import futureSendReducer from '../futureSend/futureSendModule';
import modalDisplayReducer from '../modal/modalModule';
import notifyDisplayReducer from '../notifications/notifyModule';
import { senderReducer } from '../sender/senderModule';
import { shippingMethodReducer } from '../shipping/shippingModule';
import { iovationReducer } from '../iovation/iovationModule';
import itemDisplayReducer, { ITEM_DISPLAY_STORE_NAME } from '../item/itemDisplayModule';
import itemReducer from '../item/itemModule';
import { newItemFormReducer } from '../item/newItemFormModule';
import sessionReducer, { sessionLifetimeDeserializer, capiCallReducer } from '../session/sessionModule';
import paymentPlusReducer from '../paymentJS/paymentPlusModule';
import uyoReducer from '../uyo/uyoModule';
import getNowTimestamp from '../utils/time/getNowTimestamp';
import { masterpassReducer } from '../masterpass/masterpassModule';
import programReducer, { PROGRAM_STORE_NAME } from '../program/programModule';
import loyaltyReducer from '../loyalty/loyaltyModule';
import flowReducer, { FLOW_STORE_NAME } from '../routing/flowModule';
import { flowProgressReducer, FLOW_PROGRESS_STORE_NAME } from '../routing/flowProgressModule';
import consentReducer, { CONSENT_STORE_NAME } from '../consent/consentModule';
import cartReducer from '../cart/cartModule';

export const capiInitialState = IMap({
    url: getCapiUrl(),
    token: getOrderingToken()
});


export const capiReducer = (state = capiInitialState, action) => baseCapiReducer(state, action);





export const lastActionReducer = () => getNowTimestamp();


const appReducer = combineReducers({
    addresses: addressReducer,
    brand: brandReducer,
    capi: capiReducer,
    cart: cartReducer,
    faceplate: faceplateReducer,
    form: formReducer.plugin(newItemFormReducer),
    future: futureSendReducer,
    intl: intlReducer,
    iovation: iovationReducer,
    items: itemReducer,
    [ITEM_DISPLAY_STORE_NAME]: itemDisplayReducer,
    lastAction: lastActionReducer,
    masterpass: masterpassReducer,
    modals: modalDisplayReducer,
    notifications: notifyDisplayReducer,
    payment: paymentReducer,
    paypal: paypalReducer,
    [PROGRAM_STORE_NAME]: programReducer,
    promo: promoReducer,
    sender: senderReducer,
    session: sessionReducer,
    capiCall: capiCallReducer,
    paymentPlus: paymentPlusReducer,
    shippingMethods: shippingMethodReducer,
    uyo: uyoReducer,
    loyalty: loyaltyReducer,
    [FLOW_STORE_NAME]: flowReducer,
    [CONSENT_STORE_NAME]: consentReducer,
    [FLOW_PROGRESS_STORE_NAME]: flowProgressReducer
});

const reducers = compose(
    mergePersistedState(sessionLifetimeDeserializer)
)(appReducer);

export default reducers;
