import { Map as IMap } from 'immutable';
import { take, put, call } from 'redux-saga/effects';

import loadSnare from './loader';



export const SET_IOVATION = '@@storefront/SET_IOVATION';
export const RESET_IOVATION = '@@storefront/RESET_IOVATION';


export function iovationReducer(state = IMap(), action) {
    if (action.type === SET_IOVATION) {
        return action.data;
    }

    return state;
}


export const setIovation = data => ({
    type: SET_IOVATION,
    data
});
export const resetIovation = () => ({
    type: RESET_IOVATION
});



export function* blackboxResetWatcher() {
    
    while (true) {
        yield take(RESET_IOVATION);
        const data = yield call(loadSnare);
        yield put(setIovation(data));
    }
}


export const getIovation = state => state.get('iovation');


export const initializeIovation = async (store, loader = loadSnare) => {
    if (!store.getState().getIn(['iovation', 'blackbox'])) {
        const data = await loader();
        store.dispatch(setIovation(data));
    }
};

export default initializeIovation;
