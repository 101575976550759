import React from 'react';


export const windowSize = Component => (
    class WindowSizeWrapper extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                windowWidth: 0,
                windowHeight: 0
            };
            this.updateSize = this.updateSize.bind(this);
        }

        componentDidMount() {
            this.updateSize();
            window.addEventListener('resize', this.updateSize);
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.updateSize);
        }

        updateSize() {
            this.setState({
                windowWidth: window.innerWidth,
                windowHeight: window.innerHeight
            });
        }

        render() {
            return (
                <Component
                    {...this.props}
                    {...this.state}
                />
            );
        }
    }
);

export default windowSize;
