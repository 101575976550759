import { defineMessages } from 'react-intl';

const messages = defineMessages({
    invalidEmail: {
        id: 'ofac.invalidEmail',
        description: 'Email violates OFAC sanctions',
        defaultMessage: 'Unfortunately, we cannot send an email to this address to comply with applicable laws and '
            + 'manage related risks. Please try another email address or email domain.'
    }
});

const SANCTIONED_COUNTRIES_TOP_LEVEL_DOMAINS = ['cu', 'ir', 'kp', 'sy', 'ua'];


export function isValidEmail(value) {
    const topLevelDomain = value && value.split('.').pop();
    if (!topLevelDomain) {
        return null;
    }

    return (
        SANCTIONED_COUNTRIES_TOP_LEVEL_DOMAINS.includes(topLevelDomain)
            ? messages.invalidEmail
            : null
    );
}
