import { createSelector } from 'reselect';

import { getAccessoriesPromoData } from '../promo/promoSelectors';



export const getAccessoryCode = accessory => (
    accessory.get('code')
);


export const getAccessoryLabel = accessory => (
    accessory.get('label')
);


export const getAccessoryImage = accessory => (
    accessory.getIn(['images', 'FULL'])
);


export const getAccessoryCostByCurrency = (accessory, accessoryPromoData, currency) => {
    const accessoryCode = getAccessoryCode(accessory);
    if (accessoryPromoData.has(accessoryCode)) {
        const matchingAccessory = accessoryPromoData.get(accessoryCode);
        return matchingAccessory.get('updatedCost');
    }
    return accessory.getIn(['costs', currency, 'cost'], '0');
};


export const getHasAccessoryPromo = createSelector(
    getAccessoriesPromoData,
    accessoryPromoData => accessory => accessoryPromoData.has(getAccessoryCode(accessory))
);


export const formatAccessoryMessage = (intl, accessory, type, values = {}) => {
    const accessoryMessageKey = `${type}.${getAccessoryCode(accessory)}`;
    const labelExists = !!intl.messages[accessoryMessageKey];
    return labelExists
        ? intl.formatMessage({ id: accessoryMessageKey }, values)
        : '';
};
