import { takeEvery, put } from 'redux-saga/effects';


import {
    NOTIFICATION_CREATE,
    addNotification
    
} from './notifyModule';


export function* handleNotification(action) {
    yield put(addNotification(action.notification));
    
    
}


export function* watchNotifications() {
    yield takeEvery(NOTIFICATION_CREATE, handleNotification);
}
