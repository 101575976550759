import {
    take, all, call, fork, put, race, takeLatest, select, spawn, cancel
} from 'redux-saga/effects';
import { Map as IMap, fromJS } from 'immutable';
import Big from 'big.js';
import { RETRIEVED_BRAND_CONFIG } from 'capi/redux';

import { logError } from '../utils/errorUtils';
import { formatAmountToString } from '../utils/numberUtils';
import {
    IS_PLASTIC_FIELD,
    CURRENCY_FIELD,
    AMOUNT_FIELD,
    PROGRAM_CODE,
    newItemIsPlastic,
    newItemCurrency
} from '../item/newItemForm';
import {
    SET_CURRENCY,
    setCurrency,
    updateIntlCurrency,
    translateCurrency
} from '../intl/intlModule';
import {
    formats, getIntl,
    getIntlCurrencyCode,
    getIntlLocale
} from '../intl/intlSelectors';
import {
    setDefaultFieldValues,
    changeValue,
    fieldChangedAction
} from '../item/newItemFormModule';
import { getPaidItemsCount, getCurrencyCodes } from '../item/itemSelectors';
import { FACEPLATES_LOADED } from '../faceplate/faceplateModule';
import { PROGRAM_LOADED } from '../program/programModule';
import { CATALOGS_LOADED } from '../brand/brandModule';
import { getActiveProgramCode } from '../program/programSelectors';
import { predictIntlCurrency } from '../intl/data/currencyByLocale';
import { updateCurrency } from '../intl/intlSagas';
import { getCartCurrencyCode } from '../payment/paymentSelectors';
import {
    getPreferredAmount,
    getPreferredCurrencyCode
} from '../routing/flowSelectors';
import {
    FLOW_STATE_INITIALIZED,
    removeFieldFromFlowProgress,
    removePreferredValue
} from '../routing/flowProgressModule';

import {
    getCatalogs,
    getElectronicCurrencyList,
    getPlasticCurrencyList,
    getValidDefaultAmountForCatalogs
} from './catalogSelectors';
import { getInCatalog } from './catalogUtils';


const IS_PLASTIC_FIELD_CHANGED = fieldChangedAction(IS_PLASTIC_FIELD);
const CURRENCY_FIELD_CHANGED = fieldChangedAction(CURRENCY_FIELD);
const AMOUNT_FIELD_CHANGED = fieldChangedAction(AMOUNT_FIELD);



export const getCurrencyDefault = (
    isPlastic, electronicCurrencyList, plasticCurrencyList, preferredCurrency = null, intlCurrency = null
) => {
    const selectedCurrencyList = isPlastic ? plasticCurrencyList : electronicCurrencyList;
    if (preferredCurrency && selectedCurrencyList.contains(preferredCurrency)) {
        return preferredCurrency;
    } if (intlCurrency && selectedCurrencyList.contains(intlCurrency)) {
        
        
        return intlCurrency;
    }
    return selectedCurrencyList.first();
};

const getCatalogValuesForCurrency = (catalogs, currency, isPlastic) => {
    const defaultAmountSelector = isPlastic ? 'plasticDefaultAmount' : 'egcDefaultAmount';
    const fixedCatalogSelector = isPlastic ? 'plasticFixedCatalog' : 'egcFixedCatalog';
    const rangedCatalogSelector = isPlastic ? 'plasticRangedCatalog' : 'egcRangedCatalog';

    const catalogForCurrency = catalogs.get(translateCurrency(currency)) || IMap();
    const fixedCatalog = getInCatalog(catalogForCurrency, [fixedCatalogSelector]);
    const rangedCatalog = getInCatalog(catalogForCurrency, [rangedCatalogSelector]);
    const catalogDefaultAmount = getInCatalog(catalogForCurrency, [defaultAmountSelector]);
    return { fixedCatalog, rangedCatalog, catalogDefaultAmount };
};


export const getAmountDefault = (isPlastic, currency, catalogs, preferredAmount) => {
    const {
        fixedCatalog,
        rangedCatalog,
        catalogDefaultAmount
    } = getCatalogValuesForCurrency(catalogs, translateCurrency(currency), isPlastic);

    let defaultAmount;
    if (!catalogDefaultAmount) {
        logError(new Error(`No catalog default amount found for currency: ${translateCurrency(currency)}`));
        defaultAmount = new Big('0');
    } else {
        defaultAmount = new Big(preferredAmount || catalogDefaultAmount);
    }

    if (!fixedCatalog && !rangedCatalog) {
        logError(new Error(`No fixed or ranged catalog found for currency: ${translateCurrency(currency)}`));
        return new Big('0');
    }
    return new Big(getValidDefaultAmountForCatalogs(fixedCatalog, rangedCatalog, defaultAmount).toFixed(2));
};


function* resetDefaultCurrency(updatedIsPlastic) {
    const isCartEmpty = !(yield select(getPaidItemsCount));
    const currentCurrency = yield select(newItemCurrency);
    let updatedCurrency = currentCurrency;

    
    
    if (isCartEmpty) {
        const electronicCurrencyList = yield select(getElectronicCurrencyList);
        const plasticCurrencyList = yield select(getPlasticCurrencyList);
        const selectedCurrencyList = updatedIsPlastic ? plasticCurrencyList : electronicCurrencyList;

        
        if (!selectedCurrencyList.contains(currentCurrency)) {
            updatedCurrency = yield call(getCurrencyDefault,
                updatedIsPlastic, electronicCurrencyList, plasticCurrencyList);
        }
    } else {
        
        
        
        
        const cartCurrency = yield select(getCartCurrencyCode);
        if (currentCurrency !== cartCurrency) {
            updatedCurrency = cartCurrency;
        }
    }

    if (updatedCurrency !== currentCurrency) {
        yield put(setCurrency(updatedCurrency));
    } else {
        
        yield call(updateCurrencyAndAmount, currentCurrency, updatedIsPlastic);
    }
}


function* updateCurrencyAndAmount(updatedCurrency, updatedIsPlastic) {
    const parsedCatalogs = yield select(getCatalogs);
    const intlFormats = yield select(formats);
    const isPlastic = updatedIsPlastic || (yield select(newItemIsPlastic));
    const preferredAmount = yield select(getPreferredAmount);

    
    const updatedAmount = formatAmountToString(
        new Big(
            yield call(getAmountDefault, isPlastic, updatedCurrency, parsedCatalogs, preferredAmount)
        )
    );
    
    yield put(updateIntlCurrency(intlFormats, updatedCurrency));

    
    const updatedValues = fromJS({
        [CURRENCY_FIELD]: updatedCurrency,
        [AMOUNT_FIELD]: updatedAmount
    });
    yield put(setDefaultFieldValues(updatedValues, true));
}


export function* handleSetDefaults() {
    const catalogs = yield select(getCatalogs);
    const isPlastic = yield select(newItemIsPlastic);
    const intlFormats = yield select(formats);
    const intlCurrencyCode = yield select(getIntlCurrencyCode);
    const preferredCurrencyCode = yield select(getPreferredCurrencyCode);
    const cartCurrency = (yield select(getCurrencyCodes)).first();
    const electronicCurrencyList = yield select(getElectronicCurrencyList);
    const plasticCurrencyList = yield select(getPlasticCurrencyList);
    const activeProgramCode = yield select(getActiveProgramCode);

    
    const defaultCurrency = cartCurrency || (yield call(getCurrencyDefault,
        isPlastic,
        electronicCurrencyList,
        plasticCurrencyList,
        preferredCurrencyCode,
        intlCurrencyCode));
    
    yield put(updateIntlCurrency(intlFormats, defaultCurrency));
    if (preferredCurrencyCode && defaultCurrency !== preferredCurrencyCode) {
        yield put(removeFieldFromFlowProgress(CURRENCY_FIELD));
        yield put(removePreferredValue(CURRENCY_FIELD));
    }

    const preferredAmount = yield select(getPreferredAmount);
    
    const defaultAmount = formatAmountToString(
        new Big(yield call(getAmountDefault, isPlastic, defaultCurrency, catalogs, preferredAmount))
    );
    if (preferredAmount && defaultAmount !== preferredAmount) {
        yield put(removePreferredValue(AMOUNT_FIELD));
        yield put(removeFieldFromFlowProgress(AMOUNT_FIELD));
    }

    
    const defaultValues = fromJS({
        [CURRENCY_FIELD]: defaultCurrency,
        [AMOUNT_FIELD]: defaultAmount,
        [IS_PLASTIC_FIELD]: isPlastic
    });
    if (activeProgramCode) {
        yield put(changeValue(PROGRAM_CODE, activeProgramCode));
    }

    yield put(setDefaultFieldValues(defaultValues, true));

    yield put({ type: CATALOGS_LOADED });
}


export function* watchIsPlasticAndCurrencyChange() {
    
    yield take(CATALOGS_LOADED);

    
    yield takeLatest(SET_CURRENCY, function* handleUpdate(action) {
        const currentCurrency = yield select(newItemCurrency);
        const newCurrency = action.currency;
        if (currentCurrency !== newCurrency) {
            yield fork(updateCurrencyAndAmount, newCurrency);
        }
    });

    
    yield spawn(function* watchIsPlasticChange() {
        while (true) {
            
            const action = yield take(IS_PLASTIC_FIELD_CHANGED);
            const handleIsPlasticUpdateTask = yield fork(resetDefaultCurrency, action.newValue);

            
            
            
            
            
            const { currencyChanged } = yield race({
                currencyChanged: take(CURRENCY_FIELD_CHANGED),
                amountChanged: take(AMOUNT_FIELD_CHANGED)
            });
            if (currencyChanged) {
                yield cancel(handleIsPlasticUpdateTask);
            }
        }
    });
}

export function* watchCatalogsReceived() {
    
    
    const actionsToAwait = {
        catalog: take(RETRIEVED_BRAND_CONFIG.CATALOG),
        faceplates: take(FACEPLATES_LOADED),
        programs: take(PROGRAM_LOADED),
        flowInit: take(FLOW_STATE_INITIALIZED)
    };

    
    yield all(actionsToAwait);
    const intl = yield select(getIntl);
    const hasStoredIntlData = Boolean(intl && intl.formats);
    
    if (!hasStoredIntlData) {
        const locale = yield select(getIntlLocale);
        const preferredCurrencyCode = yield select(getPreferredCurrencyCode);
        if (locale) {
            const currency = preferredCurrencyCode || predictIntlCurrency(locale);
            yield call(updateCurrency, currency);
        } else {
            yield call(updateCurrency, 'USD');
        }
    }
    yield call(handleSetDefaults);
}
