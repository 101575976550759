




const postalCodeValidatorGB = (postcode) => {
    
    const ukGovtProvidedPattern = '^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$';
    const pattern = new RegExp(ukGovtProvidedPattern);
    return postcode && pattern.test(postcode);
};

export default postalCodeValidatorGB;
