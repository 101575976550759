import numbersOnlyNormalizer from './numbersOnlyNormalizer';


const postalCodeNormalizerAU = (value) => {
    if (!value || typeof value !== 'string') return value;
    let normalized = numbersOnlyNormalizer(value);
    if (normalized.length > 4) {
        normalized = normalized.slice(0, 4);
    }
    return normalized;
};

export default postalCodeNormalizerAU;
