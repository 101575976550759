import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Container } from 'cstar-react-primitives/lib/redux-form/layout/Container';

import { newItemCurrency, newItemIsPlastic } from '../item/newItemForm';
import {
    canAddDigitalItem, canAddPlasticItem, getPaidItemsCount, getRemainingPlasticCardsAllowed
} from '../item/itemSelectors';
import {
    getElectronicCurrencyList,
    getPlasticCurrencyList,
    getDefaultItemDigitalCurrency,
    getDefaultItemPlasticCurrency
} from '../catalogs/catalogSelectors';

import CardTypeForm from './CardTypeForm';
import cardTypeMessages from './cardTypeMessages';
import cartMessages from '../cart/cartMessages';
import { useFlow } from '../routing/FlowProvider';
import { hasFaceplates } from '../faceplate/faceplateSelectors';
import { getPreferredCardType } from '../routing/flowSelectors';
import LoadingIndicator from '../primitive/LoadingIndicator';
import { brandCatalogIsLoaded } from '../brand/brandSelectors';
import TranslatedPageTitle from '../header/TranslatedPageTitle';


export const CardTypeStep = ({
    intl,
    stepName
}) => {
    const { goToNextStep, getSubmitButtonText } = useFlow();
    const digitalSubmitMessage = getSubmitButtonText(stepName, { isPlastic: false });
    const physicalSubmitMessage = getSubmitButtonText(stepName, { isPlastic: true });

    
    const allowChangingCurrency = useSelector(state => getPaidItemsCount(state) === 0);
    const selectedElectronicCurrency = useSelector(state => getDefaultItemDigitalCurrency(state));
    const selectedPhysicalCurrency = useSelector(state => getDefaultItemPlasticCurrency(state));
    const electronicCurrencyList = useSelector(state => getElectronicCurrencyList(state));
    const physicalCurrencyList = useSelector(state => getPlasticCurrencyList(state));
    const currentCurrency = useSelector(state => newItemCurrency(state));
    const remainingPlasticCardsAllowed = useSelector(state => getRemainingPlasticCardsAllowed(state));
    const isPlastic = useSelector(state => newItemIsPlastic(state));
    const showCurrencyPicker = electronicCurrencyList.size > 1
        || !electronicCurrencyList.equals(physicalCurrencyList);

    
    const isReady = useSelector(state => hasFaceplates(state) && brandCatalogIsLoaded(state));
    const preferredCardTypeSelected = useSelector(state => getPreferredCardType(state) !== null);
    const digitalSupported = useSelector(state => canAddDigitalItem(state));
    const plasticSupported = useSelector(state => canAddPlasticItem(state));

    useEffect(() => {
        if (isReady && (
            
            
            preferredCardTypeSelected
            || !digitalSupported
            || !plasticSupported
        )) {
            goToNextStep(stepName, { shouldPushHistory: false });
        }
    }, [isReady, digitalSupported, plasticSupported]);

    if (!isReady || !digitalSupported || !plasticSupported) {
        return <LoadingIndicator />;
    }

    return (
        <Container
            heading={intl.formatMessage(cardTypeMessages.heading)}
            showAlert={remainingPlasticCardsAllowed === 0}
            alertMessage={cartMessages.maximumPhysicalCards}
            HeadingElement='h1'
        >
            <TranslatedPageTitle pageTitle={cardTypeMessages.heading} />
            <CardTypeForm
                cashbotName="cardType"
                isPlastic={isPlastic}
                morePlasticCardsAllowed={remainingPlasticCardsAllowed > 0}
                showCurrencyPicker={showCurrencyPicker}
                allowChangingCurrency={allowChangingCurrency}
                selectedElectronicCurrency={selectedElectronicCurrency}
                selectedPhysicalCurrency={selectedPhysicalCurrency}
                submitAction={(values) => {
                    goToNextStep(stepName, {}, values);
                }}
                digitalSubmitMessage={digitalSubmitMessage}
                physicalSubmitMessage={physicalSubmitMessage}
                electronicCurrencyList={electronicCurrencyList}
                physicalCurrencyList={physicalCurrencyList}
                currentCurrency={currentCurrency}
            />
        </Container>
    );
};

CardTypeStep.displayName = 'CardTypeStep';

CardTypeStep.propTypes = {
    
    intl: intlShape.isRequired,
    stepName: PropTypes.string.isRequired
};

export default injectIntl(CardTypeStep);
