import { v4 as uuid } from 'uuid';
import { fromJS } from 'immutable';
import { put } from 'redux-saga/effects';
import { saveNewItemAction } from '../item/itemModule';
import { setCartViewed } from '../cart/cartModule';
import { DEFAULT_PHYSICAL_FACEPLATES, DEFAULT_ELECTRONIC_FACEPLATES } from '../program/programListSelectors';
import { itemSegmentData } from '../segment/segmentSelectors';


export const submitItem = (
    dispatch,
    values,
    programCode,
    activecurrentCatalogs,
    cartViewedSegment,
    getFaceplates,
    brandCode
) => {
    if (programCode) {
        const isPlastic = values.get('isPlastic', false);
        const catalogsByActiveCurrency = activecurrentCatalogs.get(values.get('currencyCode'), 'USD').toJS();

        if (catalogsByActiveCurrency.length > 1) {
            
            
            
            const bundleId = uuid();
            catalogsByActiveCurrency.forEach((currentCatalog) => {
                let newValues = values.set('brandCode', currentCatalog.brandCode);

                
                newValues = newValues.set('amount', currentCatalog.egcFixedCatalog[0].toString());
                newValues = isPlastic
                    ? newValues.set('fee', currentCatalog.plasticActivationFeeAmount)
                    : newValues.set('fee', currentCatalog.digitalActivationFeeAmount);

                const faceplateData = getFaceplates(
                    programCode,
                    isPlastic ? DEFAULT_PHYSICAL_FACEPLATES : DEFAULT_ELECTRONIC_FACEPLATES,
                    currentCatalog.brandCode
                );

                newValues = newValues.set('faceplateData', faceplateData);
                newValues = newValues.set('faceplate', faceplateData.get('code'));

                newValues = newValues.set('id', bundleId);
                
                
                dispatch(saveNewItemAction(newValues, false, true));
            });
        
        } else {
            const catalog = catalogsByActiveCurrency[0];
            const catalogBrandCode = catalog.brandCode;

            let newValues = values.set('brandCode', catalogBrandCode);
            
            
            
            if (!newValues.get('faceplate')) {
                const faceplateData = getFaceplates(
                    programCode,
                    isPlastic ? DEFAULT_PHYSICAL_FACEPLATES : DEFAULT_ELECTRONIC_FACEPLATES,
                    catalogBrandCode
                );
                newValues = newValues.set('faceplateData', faceplateData);
                newValues = newValues.set('faceplate', faceplateData.get('code'));
            }
            dispatch(saveNewItemAction(newValues));
        }
        
        
        
        
        dispatch(setCartViewed({}));
    } else {
        
        
        const brandAddedValues = values.set('brandCode', brandCode);
        dispatch(saveNewItemAction(brandAddedValues));
        if (cartViewedSegment) {
            cartViewedSegment.products.push(
                itemSegmentData(brandAddedValues)
            );
            cartViewedSegment.currency = cartViewedSegment.products.length >= 1
                ? cartViewedSegment.products[0].currency : 'None';
        }
        dispatch(setCartViewed(cartViewedSegment));
    }
};


export const groupItemsById = (items) => {
    const itemsArray = items.toJS();

    const groupedItems = itemsArray.reduce((item, attributes) => {
        item[attributes.id] = item[attributes.id] || [];
        item[attributes.id].push(fromJS(attributes));
        return item;
    }, Object.create(null));

    return groupedItems;
};


export const getBundleValue = (currentCatalogs, currentCurrency) => {
    let bundleAmount = 0;
    const currentCatalogsPerCurrency = currentCatalogs.toJS()[currentCurrency];
    if (currentCatalogsPerCurrency.length > 1) {
        currentCatalogsPerCurrency.forEach((currentCatalog) => {
            bundleAmount += parseFloat(currentCatalog.egcFixedCatalog, 2);
        });
    }
    return bundleAmount;
};


export function* segmentCallForBundles(items, segmentEventFunction) {
    
    const bundleGroups = Object.values(groupItemsById(items));

    
    
    for (let i = 0; i < bundleGroups.length; i++) {
        const group = bundleGroups[i];
        if (group.length > 1) {
            const bundleSegmentData = {
                quantity: group[0].get('quantity'), 
                brands: [], 
                amounts: [], 
                programCode: group[0].get('programCode')
            };
            group.forEach((item) => {
                bundleSegmentData.brands.push(item.get('brandCode'));
                bundleSegmentData.amounts.push(item.get('amount'));
            });
            yield put(segmentEventFunction(bundleSegmentData));
        }
    }
}
