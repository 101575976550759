import { Map as IMap } from 'immutable';


export const CART_STATUS = {
    INVALID: 'invalid',
    PROCESSING: 'processing',
    READY: 'ready',
    REDIRECT_TO_DESIGN: 'redirect-to-design'
};


export const SET_CART_STATUS = '@@storefront/SET_CART_STATUS';
export const SET_CART_PROMO_ERROR = '@@storefront/SET_CART_PROMO_ERROR';
export const SET_CART_CUSTOM_PROMO = '@@storefront/SET_CART_CUSTOM_PROMO';
export const CART_CHECKOUT_STARTED = '@@storefront/CHECKOUT_STARTED';
export const CART_VIEWED = '@@storefront/CART_VIEWED';
export const PROMO_EXPIRY_VIEWED = '@@storefront/PROMO_EXPIRY_VIEWED';
export const PROMO_EXPIRY_CHECKOUT_CLICKED = '@@storefront/PROMO_EXPIRY_CHECKOUT_CLICKED';


export default function cartReducer(state = IMap({}), action) {
    switch (action.type) {
        case SET_CART_STATUS:
            return state.set('cartStatus', action.status);
        case SET_CART_PROMO_ERROR:
            return state.set('cartPromoError', action.status);
        case SET_CART_CUSTOM_PROMO:
            return state.set('cartCustomPromo', action.status);
        default:
            return state;
    }
}




export function setCartStatusProcessing(replaceHistory = false) {
    return {
        type: SET_CART_STATUS,
        status: CART_STATUS.PROCESSING,
        replaceHistory
    };
}


export function setCartStatusInvalid() {
    return {
        type: SET_CART_STATUS,
        status: CART_STATUS.INVALID
    };
}

export function setCartStatusRedirectToDesign() {
    return {
        type: SET_CART_STATUS,
        status: CART_STATUS.REDIRECT_TO_DESIGN
    };
}



export function setCartStatusReady(replaceHistory = false) {
    return {
        type: SET_CART_STATUS,
        status: CART_STATUS.READY,
        replaceHistory
    };
}

export function setCartViewed(eventData) {
    return {
        type: CART_VIEWED,
        meta: {
            segment: {
                event: 'track',
                eventAction: 'Cart Viewed',
                eventData
            }
        }
    };
}

export function setCartCheckoutStarted(eventData) {
    return {
        type: CART_CHECKOUT_STARTED,
        meta: {
            segment: {
                event: 'track',
                eventAction: 'Checkout Started',
                eventData
            }
        }
    };
}


export function setCartPromoError(errorDetails) {
    return {
        type: SET_CART_PROMO_ERROR,
        status: errorDetails
    };
}


export function clearCartPromoError() {
    return {
        type: SET_CART_PROMO_ERROR,
        status: null
    };
}


export function setCartCustomPromo(promoCode) {
    return {
        type: SET_CART_CUSTOM_PROMO,
        status: promoCode
    };
}


export function clearCartCustomPromo() {
    return {
        type: SET_CART_CUSTOM_PROMO,
        status: null
    };
}

export function setPromoExpiryViewed(eventData) {
    return {
        type: PROMO_EXPIRY_VIEWED,
        meta: {
            segment: {
                event: 'track',
                eventAction: 'Promo expiry pop-up viewed',
                eventData
            }
        }
    };
}

export function setPromoExpiryCheckoutClicked(eventData) {
    return {
        type: PROMO_EXPIRY_CHECKOUT_CLICKED,
        meta: {
            segment: {
                event: 'track',
                eventAction: 'Promo expiry check out clicked',
                eventData
            }
        }
    };
}
