import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';

import { getStaticMediaURL } from '../app/bootstrap';


export const CustomCard = ({
    text,
    description,
    header,
    imgUrl,
    imgAlt,
    brandCode
}) => {
    const staticUrl = getStaticMediaURL(`/images/${brandCode}/${imgUrl}`);
    const showImage = Boolean(imgAlt && imgUrl);

    return (
        <Fragment>
            <span className="page-header corporate-header">{header}</span>
            <Fragment>
                { showImage && <img className='card-image' src={staticUrl} alt='' /> }
                <div className='card-head'>
                    <span className="card-title" aria-hidden>{text}</span>
                </div>
                { description && <span className="card-description"><Markdown>{description}</Markdown></span> }
            </Fragment>
        </Fragment>
    );
};

CustomCard.defaultProps = {
    description: '',
    header: '',
    imgUrl: '',
    imgAlt: ''
};

CustomCard.propTypes = {
    text: PropTypes.string.isRequired,
    description: PropTypes.string,
    header: PropTypes.string,
    imgUrl: PropTypes.string,
    imgAlt: PropTypes.string,
    brandCode: PropTypes.string.isRequired
};
