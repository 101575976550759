import alphaNumericNormalizer from './alphaNumericNormalizer';


const postalCodeNormalizerCA = (value) => {
    if (!value) return value;
    let normalize = alphaNumericNormalizer(value).toUpperCase();
    if (normalize.length && value.length > 3) {
        normalize = `${normalize.slice(0, 3)} ${normalize.slice(3, 6)}`;
    }
    return normalize;
};

export default postalCodeNormalizerCA;
