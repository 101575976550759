import getNowTimestamp from './getNowTimestamp';


const dateStringToDate = (dateString) => {
    if (dateString) {
        const date = new Date(dateString);
        if (!Number.isNaN(date.getTime())) {
            return date;
        }
    }
    return new Date(getNowTimestamp());
};

export default dateStringToDate;
