import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { logError } from '../utils/errorUtils';

import ErrorRefresh from './ErrorRefresh';

import { getCurrentPageUrl } from '../utils/urlUtils';
import { PAYMENT_STEP } from '../routing/flowConstants';

import { trackPaymentPerformance } from '../payment/paymentModule';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) { 
        
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) { 
        logError(error, errorInfo);
        const { trackPaymentPerformanceAction } = this.props;

        const currentPageUrl = getCurrentPageUrl();
        const isPaymentPage = currentPageUrl.includes(PAYMENT_STEP);

        if (isPaymentPage) {
            const errorData = {
                error,
                errorInfo
            };
            trackPaymentPerformanceAction('JavaScript Error.', errorData);
        }
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;

        if (hasError) {
            return <ErrorRefresh />;
        }

        return children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
    trackPaymentPerformanceAction: PropTypes.func.isRequired
};

const mapDispatchToProps = { trackPaymentPerformanceAction: trackPaymentPerformance };

export default connect(null, mapDispatchToProps)(injectIntl(ErrorBoundary));
