import { defineMessages } from 'react-intl';

const messageIntl = defineMessages({
    title: {
        id: 'recipient.messageIntl.title',
        description: 'Top-level heading for providing a personal message with the gift card',
        defaultMessage: 'Add a personalized message'
    },
    accessoryHeader: {
        id: 'recipient.messageIntl.accessoryHeader',
        description: 'Top-level heading for message self buy page with accessories',
        defaultMessage: 'Add an accessory'
    },
    label: {
        id: 'recipient.messageIntl.label',
        description: 'Label text for personal message text input',
        defaultMessage: 'Your Message (optional)'
    },
    msgInspirationOffer: {
        id: 'recipient.messageIntl.msgInspirationOffer',
        description: 'Descriptive text offering personal message inspiration help',
        defaultMessage: 'Not sure what to say?  We can help.'
    },
    msgInspirationSubheader: {
        id: 'recipient.messageIntl.msgInspirationSubheader',
        description: 'Subheader for personal message inspiration help',
        defaultMessage: 'Here are some ideas to get you started.'
    },
    msgInspirationExample1: {
        id: 'recipient.messageIntl.msgInspirationExample1',
        description: 'First personal message inspiration example',
        defaultMessage: 'Happy birthday, hope you get everything you want!'
    },
    msgInspirationExample2: {
        id: 'recipient.messageIntl.msgInspirationExample2',
        description: 'Second personal message inspiration example',
        defaultMessage: 'Congratulations, I\'m so happy for your good fortune!'
    },
    msgInspirationExample3: {
        id: 'recipient.messageIntl.msgInspirationExample3',
        description: 'Third personal message inspiration example',
        defaultMessage: 'Thank you, for all your help.  It is really quite appreciated.'
    },
    msgInspirationHelper: {
        id: 'recipient.messageIntl.msgInspirationHelper',
        description: 'Helper text instructing on the use of the personal message inspiration',
        defaultMessage: 'Feel free to edit the message above.'
    },
    msgInspirationOpenLink: {
        id: 'recipient.messageIntl.msgInspirationOpenLink',
        description: 'Link text for showing the personal message inspiration help',
        defaultMessage: 'Get inspired.'
    },
    msgInspirationCloseLink: {
        id: 'recipient.messageIntl.msgInspirationCloseLink',
        description: 'Link text for hiding the personal message inspiration help',
        defaultMessage: 'Thanks. I don\'t need ideas.'
    },
    inspMessageRadioLegend: {
        id: 'recipient.messageIntl.inspMessageRadioLegend',
        description: 'aria label for the inspirational message radiogroup',
        defaultMessage: 'Here are some ideas for messages'
    },
    
    inspMessageRadioAria: {
        id: 'recipient.messageIntl.inspMessageRadioAria',
        description: 'aria label for the inspirational message radiogroup',
        defaultMessage: 'Here are some ideas for messages'
    },
    inspMessageRegionAriaLabel: {
        id: 'recipient.messageIntl.inspMessageRegionAriaLabel',
        description: 'aria label for get inspired region',
        defaultMessage: 'Get inspired'
    },
    submitButtonText: {
        id: 'recipient.messageIntl.submitButtonMessageText',
        description: 'Text shown on preceding steps on buttons which will move the user to the message step',
        defaultMessage: 'Add a message'
    }
});

export default messageIntl;
