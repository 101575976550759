import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { icons } from 'cstar-react-primitives/lib/redux-form/icons/iconConst';
import { IconButton } from 'cstar-react-primitives/lib/redux-form/buttons/IconButton';
import { Map as IMap } from 'immutable';

import { removeNotification } from './notifyModule';
import notificationMessages from './notificationMessages';


export const PureNotificationDismiss = ({
    intl,
    notification,
    dispatchRemove
}) => {
    const closeProps = {
        cashbotName: 'notification-dismiss',
        onClick: () => dispatchRemove(notification.get('uid')),
        cssClass: 'notification-dismiss',
        ariaLabel: intl.formatMessage(notificationMessages.ariaLabelDismiss),
        icon: icons.XICON
    };
    return <IconButton {...closeProps} />;
};

PureNotificationDismiss.defaultProps = {
    dispatchRemove: () => {}
};

PureNotificationDismiss.propTypes = {
    intl: intlShape.isRequired,
    dispatchRemove: PropTypes.func,
    notification: PropTypes.instanceOf(IMap).isRequired
};

export const mapDispatchToProps = {
    dispatchRemove: uid => removeNotification(uid)
};

export const NotificationDismiss = injectIntl(PureNotificationDismiss);

export default connect(null, mapDispatchToProps)(NotificationDismiss);
