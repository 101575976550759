import Big from 'big.js';
import { getCurrencySymbol, getStrippedNumber } from '../intl/getCurrencySymbol';
import currencyFractionMap from '../currency/data/currencyFractionMap';

export const isDecimal = price => price % 1 !== 0;


export const isIncomplete = number => (
    number.charAt(number.length - 1) === '.' || number.charAt(number.length - 2) === '.'
);

export const formatPrice = price => (
    isDecimal(price) ? parseFloat(price.toFixed(2)) : parseInt(price, 10)
);


export const stripCurrencySymbol = (intl, number, currency) => {
    const currencySymbol = getCurrencySymbol(intl, currency);
    const symbol = currencySymbol.prefix ? currencySymbol.prefix : currencySymbol.suffix;
    return number.replace(symbol, '').trim();
};


export const fixDecimalFormat = (value, digits = 2) => {
    if (!value) return '';
    return isDecimal(value) ? new Big(value).toFixed(digits) : new Big(value).toFixed(0);
};

export const getCurrencyFractionDigits = (currencyCode) => {
    const fractionDigits = currencyFractionMap.get(currencyCode, currencyFractionMap.get('DEFAULT'));
    return fractionDigits;
};

export const getFormattedAmountWithCurrency = (intl, number, currency, forceDecimal = false) => {
    const symbols = getCurrencySymbol(intl, currency);
    const formatConfig = {};
    const currencyFractionDigits = getCurrencyFractionDigits(currency);
    const value = fixDecimalFormat(number, currencyFractionDigits);
    if (currency) { formatConfig.currency = currency; }
    if (isDecimal(value) || forceDecimal) {
        formatConfig.format = 'currency';
        formatConfig.minimumFractionDigits = currencyFractionDigits;
        formatConfig.maximumFractionDigits = currencyFractionDigits;
    } else {
        formatConfig.format = 'currencyInteger';
    }
    return `${Object.values(symbols)[0]}${getStrippedNumber(intl.formatNumber(number, formatConfig))}${Object.values(symbols)[1]}`;
};


export const formatAmountToString = amount => (
    isDecimal(amount) ? amount.toFixed(2) : amount.toString()
);


export const stripValue = (value, decimalChar = '', groupChar = '') => {
    const strippedValue = value.toString()
        .replace(new RegExp(`[${groupChar}]`, 'g'), '')
        .replace(new RegExp(`[${decimalChar}]`, 'g'), '.')
        .replace(/(-(?![0-9.])|[^0-9-.])/g, '');
    return strippedValue.match(/^\d*(?:\.\d{0,2})?/)[0];
};


const getInternationalParts = (intl, currency) => {
    let decimalChar = '';
    let groupChar = '';
    new Intl.NumberFormat(intl.locale, { style: 'currency', currency })
        .formatToParts('1000.50').forEach(({ type, value }) => {
            if (type === 'decimal') { decimalChar = value; }
            if (type === 'group') { groupChar = value; }
        });
    return { decimalChar, groupChar };
};


export const normalizeInternationalCurrency = (intl, currency) => (
    (value) => {
        let val = value;
        let currencyFractionDigits;
        let fractionDigits;
        const intlParts = getInternationalParts(intl, currency);
        val = stripValue(val, intlParts.decimalChar, intlParts.groupChar);
        if (isDecimal(val)) {
            currencyFractionDigits = getCurrencyFractionDigits(currency);
            fractionDigits = val.toString().split('.')[1].length;
            val = fixDecimalFormat(val, Math.min(currencyFractionDigits, fractionDigits));
        }
        return val;
    }
);
