import { createSelector } from 'reselect';
import { List as IList, fromJS } from 'immutable';

import { FEATURES, isFeatureEnabled } from '../utils/featureFlag';
import {
    getAllowedBillingCountries,
    getAllowsAllBillingCountries,
    getAllowedShippingCountries,
    getExcludedBillingCountries
} from '../brand/brandSelectors';

import billableCountries from './data/billableCountryList';



export const hasIntlShipping = isFeatureEnabled(FEATURES.INTL_SHIPPING);


export const hasIntlBilling = isFeatureEnabled(FEATURES.INTL_BILLING);



export const createCountryList = (preferredList, fullList) => {
    if (!fullList || !fullList.size) return IList();

    if (preferredList.size) {
        const finalList = preferredList.filter(country => fullList.includes(country));
        return finalList.push(null) 
            .concat(fullList.filter(
                countryCode => !preferredList.includes(countryCode)
            ));
    }
    return fullList;
};


export const getPreferredCountriesList = createSelector(
    getAllowedBillingCountries,
    allowedList => (allowedList.size ? allowedList.filter(country => country !== 'EU') : IList())
);


export const getBillingCountryList = createSelector(
    [getPreferredCountriesList, getAllowsAllBillingCountries, getExcludedBillingCountries],
    (preferredCountries, allowAll, excludedBillingCountries) => {
        if (allowAll) {
            return createCountryList(preferredCountries, fromJS(billableCountries))
                .filter(country => !excludedBillingCountries.includes(country));
        }
        return preferredCountries;
    }
);


export const getShippingCountryList = createSelector(
    getPreferredCountriesList,
    getAllowedShippingCountries,
    (preferredCountries, allowedShippingCountries) => createCountryList(preferredCountries, allowedShippingCountries)
);
