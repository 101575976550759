import { defineMessages } from 'react-intl';


const flowMessages = defineMessages({
    genericSubmitButtonText: {
        id: 'flow.flowMessages.genericSubmitButtonText',
        description: 'A generic message used on buttons advancing the user to the next step. Used when a more specific message cannot be located.',
        defaultMessage: 'Next'
    }
});


export default flowMessages;
