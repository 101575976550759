import { defineMessages } from 'react-intl';

const deliveryMessages = defineMessages({
    headerEgc: {
        id: 'delivery.deliveryMessages.headerEgc',
        description: 'Top-level heading for choosing where the digital gift card should be delivered',
        defaultMessage: 'Where is it going?'
    },
    headerEgcSharableLink: {
        id: 'delivery.deliveryMessages.headerEgcSharableLink',
        description: 'Top-level heading for choosing the delivery method when Sharable link is enabled',
        defaultMessage: 'How would you like it delivered?'
    },
    headerPlastic: {
        id: 'delivery.deliveryMessages.headerPlastic',
        description: 'Top-level heading for choosing where the plastic gift card should be delivered',
        defaultMessage: 'Where is it going?'
    },
    emailOrSmsSubheader: {
        id: 'delivery.deliveryMessages.emailOrSmsSubheader',
        description: 'Top-level sub-header for choosing where the digital gift card should be delivered',
        defaultMessage: 'Send {recipient_name}\'s gift by email, text message, or both.'
    },
    plasticButton: {
        id: 'delivery.deliveryMessages.plasticButton',
        description: 'Text displayed on the shipping details sub-heading',
        defaultMessage: 'Shipping'
    },
    emailLabel: {
        id: 'delivery.deliveryMessages.emailLabel',
        description: 'Label text for recipient email input',
        defaultMessage: '{name}\'s email'
    },
    emailLabelNoName: {
        id: 'delivery.deliveryMessages.emailLabelNoName',
        description: 'Label text for recipient email input if we do not have a recipient name',
        defaultMessage: 'Recipient\'s email'
    },
    confirmEmailLabel: {
        id: 'delivery.deliveryMessages.confirmEmailLabel',
        description: 'Label text for confirm recipient email input',
        defaultMessage: 'Confirm {name}\'s email'
    },
    confirmEmailLabelNoName: {
        id: 'delivery.deliveryMessages.confirmEmailLabelNoName',
        description: 'Label text for confirm recipient email input if we do not have a recipient name',
        defaultMessage: 'Confirm Recipient\'s email'
    },
    sendByEmail: {
        id: 'delivery.deliveryMessages.sendByEmail',
        description: 'Label text for send by email checkbox',
        defaultMessage: 'Send by Email'
    },
    sendItByEmail: {
        id: 'delivery.deliveryMessages.sendItByEmail',
        description: 'Label text for send it by email radio checkbox',
        defaultMessage: 'Send it by email'
    },
    emailHelper: {
        id: 'delivery.deliveryMessages.emailHelper',
        description: 'Helper text for the recipient email input',
        defaultMessage: 'Don’t have {name}’s email? Enter your own email and share the gift with them later.'
    },
    emailRequired: {
        id: 'delivery.deliveryMessages.emailRequired',
        description: 'Error message displayed when no delivery email is provided',
        defaultMessage: 'Please enter an email'
    },
    emailInvalid: {
        id: 'delivery.deliveryMessages.emailInvalid',
        description: 'Error message displayed when an invalid delivery email is provided',
        defaultMessage: 'Please enter a valid email address'
    },
    sendBySms: {
        id: 'delivery.deliveryMessages.sendBySms',
        description: 'Label text for send by sms checkbox',
        defaultMessage: 'Send by Text'
    },
    smsPhoneInvalid: {
        id: 'delivery.deliveryMessages.smsPhoneInvalid',
        description: 'Error messages displayed when an invalid SMS delivery phone number is provided',
        defaultMessage: 'Please enter a valid phone number'
    },
    phoneRequired: {
        id: 'delivery.deliveryMessages.phoneRequired',
        description: 'Error message displayed when no delivery email is provided',
        defaultMessage: 'Please enter a phone number'
    },
    selectAnyField: {
        id: 'delivery.deliveryMessages.selectAnyField',
        description: 'Error message displayed when no fields selected',
        defaultMessage: 'At least one delivery method must be selected.'
    },
    recipientNotificationLabel: {
        id: 'delivery.deliveryMessages.recipientNotificationLabel',
        description: 'Label text for toggling notification to the recipient of a physical delivery',
        defaultMessage: 'Tell {name} to expect a gift?'
    },
    submitButtonText: {
        id: 'delivery.deliveryMessages.submitButtonPhysicalText',
        description: 'Text shown on preceding steps on buttons which will move the user to the delivery step',
        defaultMessage: 'Where is it going?'
    },
    emailDomainSpellCheckText: {
        id: 'delivery.deliveryMessages.emailDomainSpellCheckText',
        description: 'Text on a clickable link for a user to change their email to the suggested value',
        defaultMessage: 'Did you mean ***{ suggestion }***?'
    },
    sendBySharableLink: {
        id: 'delivery.deliveryMessages.sendBySharableLink',
        description: 'Label text for send by sharable Link checkbox',
        defaultMessage: 'Get a link to share'
    },
    sharableLinkMessage: {
        id: 'delivery.deliveryMessages.sharableLinkMessage',
        description: 'Helper text message to inform that the sharable link will be sent to recipient',
        defaultMessage: `After purchase, you'll get a link to the eGift. You can send the link to {name} 
                        by email, text, or any way you choose.\n\n\u200b\n\n We will also email the link to you.`
    }
});

export default deliveryMessages;
