import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Seq } from 'immutable';
import { icons } from 'cstar-react-primitives/lib/redux-form/icons/iconConst';
import { SubHeader } from 'cstar-react-primitives/lib/redux-form/layout/Container';
import { TertiaryButton } from 'cstar-react-primitives/lib/redux-form/buttons/TertiaryButton';

import InspMessageFields from './InspMessageFields';
import messageIntl from './messageIntl';


export const MessageInspExpanded = ({
    intl,
    name,
    options,
    onClick
}) => {
    const cashbotName = 'message-inspiration-expanded';
    const tertiaryBtnProps = {
        btnText: intl.formatMessage(messageIntl.msgInspirationCloseLink),
        onClick,
        cashbotName,
        isSmall: false,
        isLeftAligned: false,
        icon: icons.CARET_UP,
        atts: {
            'aria-expanded': 'true'
        }
    };
    const inspProps = {
        cashbotName,
        name,
        options
    };
    return (
        <div>
            <SubHeader heading={intl.formatMessage(messageIntl.msgInspirationSubheader)} HeadingElement="h2" />
            <InspMessageFields {...inspProps} />
            <TertiaryButton {...tertiaryBtnProps} />
        </div>
    );
};

MessageInspExpanded.defaultProps = {
    onClick: () => {}
};

MessageInspExpanded.propTypes = {
    intl: intlShape.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.instanceOf(Seq).isRequired,
    onClick: PropTypes.func
};

export default MessageInspExpanded;
