import { defineMessages } from 'react-intl';

const cashstarLogoMessages = defineMessages({
    cashstarLogoAlt: {
        id: 'footer.cashstarLogoMessages.cashstarLogoAlt',
        description: 'Alt text for CashStar logo image',
        defaultMessage: 'CashStar, A Blackhawk Network Business'
    },
    cashstarLogoAriaLabelMessage: {
        id: 'footer.cashstarLogoMessages.cashstarLogoAriaLabelMessage',
        description: 'Aria Label text for CashStar logo image',
        defaultMessage: 'CashStar, A Blackhawk Network Business - opens in a new window'
    }
});

export default cashstarLogoMessages;
