import { fromJS } from 'immutable';
import { addressFieldNames } from '../address/addressModule';
import { SENDER_EMAIL_FIELD } from '../payment/paymentConstants';

export const APPLE_PAY_PAYMENT_TYPE = 'applePay';

export const mapContact = (billingContact, shippingContact) => (fromJS({
    [addressFieldNames.ADDRESS_FIRSTNAME]: billingContact.givenName,
    [addressFieldNames.ADDRESS_LASTNAME]: billingContact.familyName,
    [addressFieldNames.ADDRESS_CITY]: billingContact.locality,
    [addressFieldNames.ADDRESS_STATE]: billingContact.administrativeArea,
    [addressFieldNames.ADDRESS_STREET1]: billingContact.addressLines[0],
    [addressFieldNames.ADDRESS_STREET2]: billingContact.addressLines[1] || null,
    [addressFieldNames.ADDRESS_POSTCODE]: billingContact.postalCode,
    [addressFieldNames.ADDRESS_COUNTRY]: billingContact.countryCode.toUpperCase(),
    [addressFieldNames.ADDRESS_PHONE]: shippingContact.phoneNumber,
    [SENDER_EMAIL_FIELD]: shippingContact.emailAddress
}));


export const applePayPaymentDataHandler = paymentData => (
    fromJS({
        approved: true,
        paymentMethod: APPLE_PAY_PAYMENT_TYPE,
        token: btoa(JSON.stringify(paymentData.token.paymentData))
    }).merge(mapContact(paymentData.billingContact, paymentData.shippingContact))
);

export const getContactValidationMessages = validationMessages => [
    {
        field: addressFieldNames.ADDRESS_FIRSTNAME,
        errorArgs: {
            code: 'billingContactInvalid',
            contactField: 'name',
            message: validationMessages.firstName
        }
    },
    {
        field: addressFieldNames.ADDRESS_LASTNAME,
        errorArgs: {
            code: 'billingContactInvalid',
            contactField: 'name',
            message: validationMessages.lastName
        }
    },
    {
        field: addressFieldNames.ADDRESS_CITY,
        errorArgs: { code: 'billingContactInvalid', contactField: 'locality', message: validationMessages.city }
    },
    {
        field: addressFieldNames.ADDRESS_STATE,
        errorArgs: {
            code: 'billingContactInvalid',
            contactField: 'administrativeArea',
            message: validationMessages.state
        }
    },
    {

        field: addressFieldNames.ADDRESS_POSTCODE,
        errorArgs: {
            code: 'billingContactInvalid',
            contactField: 'postalCode',
            message: validationMessages.postalCode
        }
    },
    {

        field: addressFieldNames.ADDRESS_STREET1,
        errorArgs: {
            code: 'billingContactInvalid',
            contactField: 'postalAddress',
            message: validationMessages.street1
        }
    },
    {

        field: addressFieldNames.ADDRESS_PHONE,
        errorArgs: {
            code: 'shippingContactInvalid',
            contactField: 'phoneNumber',
            message: validationMessages.phoneNumber
        }
    },
    {

        field: SENDER_EMAIL_FIELD,
        errorArgs: {
            code: 'shippingContactInvalid',
            contactField: 'emailAddress',
            message: validationMessages.senderEmail
        }
    }
];
