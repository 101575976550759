import { defineMessages } from 'react-intl';

const amountMessages = defineMessages({
    title: {
        id: 'amount.amountMessages.title',
        description: 'Top-level heading for gift card value and quantity selection',
        defaultMessage: 'How much is it for?'
    },
    amountFieldHelper: {
        id: 'amount.amountMessages.amountFieldHelper',
        description: 'Label for custom gift card value input',
        defaultMessage: 'Enter an amount or choose a preset amount'
    },
    customAmountLabel: {
        id: 'amount.amountMessages.customAmountLabel',
        description: 'Label for custom gift card value input - this is hidden from display',
        defaultMessage: 'Custom amount'
    },
    customAmountHelper: {
        id: 'amount.amountMessages.customAmountHelper',
        description: 'Instructional text for custom gift card value input',
        defaultMessage: 'Enter an amount by typing, or to decrement the amount by {interval}, press Down Arrow, '
            + 'to increment the amount by {interval}, press Up Arrow.'
    },
    customAmountIncrement: {
        id: 'amount.amountMessages.customAmountIncrement',
        description: 'Label for the button to increase the value of the gift card',
        defaultMessage: 'Increment monetary amount'
    },
    customAmountDecrement: {
        id: 'amount.amountMessages.customAmountDecrement',
        description: 'Label for the button to increase the value of the gift card',
        defaultMessage: 'Decrement monetary amount'
    },
    quantityLabel: {
        id: 'quantity.quantityMessages.label',
        description: 'Label for the input specifying the quantity of gift cards to purchase',
        defaultMessage: 'Quantity'
    },
    quantityHelper: {
        id: 'quantity.quantityMessages.quantityHelper',
        description: 'Instructional text for the the input specifying the quantity of gift cards to purchase',
        defaultMessage: 'Enter a quantity by typing, or to decrement the quantity by {interval}, press Down Arrow, '
            + 'to increment the quantity by {interval}, press Up Arrow.'
    },
    quantityIncrement: {
        id: 'quantity.quantityMessages.increment',
        description: 'Label for the button to increase the quantity of gift cards to purchase',
        defaultMessage: 'Increment card quantity'
    },
    quantityDecrement: {
        id: 'quantity.quantityMessages.decrement',
        description: 'Label for the button to decrease the quantity of gift cards to purchase',
        defaultMessage: 'Decrement card quantity'
    },
    amountMissingErr: {
        id: 'quantity.quantityMessages.amountMissingErr',
        description: 'Error message displayed when a gift card value was not selected or provided',
        defaultMessage: 'Please enter an amount for your gift'
    },
    amountRangeErr: {
        id: 'quantity.quantityMessages.amountRangeErr',
        description: 'Error message displayed when the provided gift card value was not allowed '
            + 'because it was too high or too low',
        defaultMessage: 'Please enter an amount for your gift between {min} '
            + 'and {max}'
    },
    amountRange: {
        id: 'quantity.quantityMessages.amountRange',
        description: 'Message displayed on the screen to give the user info about the range',
        defaultMessage: '{min} - {max}'
    },
    amountCartMaxError: {
        id: 'quantity.quantityMessages.amountCartMaxError',
        description: 'Error message displayed when the provided gift card value was not allowed '
            + 'because it would exceed the maximum allowed value of a single purchase',
        defaultMessage: 'Your order total is {total}, adding {amount} '
            + 'will exceed the maximum order amount of {max}.'
    },
    amountCartMaxErrorEmptyCart: {
        id: 'quantity.quantityMessages.amountCartMaxErrorEmptyCart',
        description: 'Error message displayed when the provided gift card value was not allowed '
            + 'because it would exceed the maximum allowed value of a single purchase and the cart is empty',
        defaultMessage: '{amount} exceeds the maximum order amount of {max}.'
    },
    submitButtonDigitalText: {
        id: 'quantity.quantityMessages.submitButtonDigitalText',
        description: 'Text shown on preceding steps on buttons which will move the user to the digital amount step',
        defaultMessage: 'How much?'
    },
    submitButtonPhysicalText: {
        id: 'quantity.quantityMessages.submitButtonPhysicalText',
        description: 'Text shown on preceding steps on buttons which will move the user to the physical amount step',
        defaultMessage: 'How much?'
    }
});

export default amountMessages;
