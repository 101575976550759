
export const getAddressCityQueryId = (countryCode) => {
    const CityQueryMap = {
        CN: 'sublocality',
        GB: 'postal_town',
        HK: 'neighborhood',
        IE: 'postal_town'
    };

    return CityQueryMap[countryCode];
};
