import { fromJS } from 'immutable';


const commonLanguages = fromJS({
    
    fr: async () => (await import('react-intl/locale-data/fr')).default,
    en: async () => (await import('react-intl/locale-data/en')).default,
    es: async () => (await import('react-intl/locale-data/es')).default,
    sv: async () => (await import('react-intl/locale-data/sv')).default,
    de: async () => (await import('react-intl/locale-data/de')).default,
    pt: async () => (await import('react-intl/locale-data/pt')).default,
    ja: async () => (await import('react-intl/locale-data/ja')).default,
    nl: async () => (await import('react-intl/locale-data/nl')).default,
    it: async () => (await import('react-intl/locale-data/it')).default,
    da: async () => (await import('react-intl/locale-data/da')).default,
    fi: async () => (await import('react-intl/locale-data/fi')).default,
    no: async () => (await import('react-intl/locale-data/no')).default,
    pl: async () => (await import('react-intl/locale-data/pl')).default
});



export async function dynamicLocaleImport(locale) {
    
    
    
    
    const language = locale.split('-')[0];
    if (commonLanguages.has(language)) {
        return commonLanguages.get(language)();
    }
    
    const languageFile = `react-intl/locale-data/${language}.js`;
    return (await import(languageFile)).default;
}
