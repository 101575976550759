import { preferencesAreLoaded } from '../brand/brandSelectors';



const aboutStepConfig = {
    component: () => import('./AboutStep'),
    hideCartLink: true,
    isReady: state => preferencesAreLoaded(state)
};

export default aboutStepConfig;
