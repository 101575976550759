import { primitiveMessages } from './primitiveMessages';



const getFieldCounterAriaLabel = intl => (current, maxLength) => intl.formatMessage(
    primitiveMessages.fieldCounterAriaLabel,
    { current, maxLength }
);


export default getFieldCounterAriaLabel;
