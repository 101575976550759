import { defineMessages } from 'react-intl';


const creditCardMessages = defineMessages({
    creditCardLabel: {
        id: 'creditCard.messages.creditCardLabel',
        description: 'Label for credit card number field on payment form',
        defaultMessage: 'Credit Card Number'
    },
    expiryLabelMonth: {
        id: 'creditCard.messages.expiryLabelMonth',
        description: 'Label for expiration month field on payment form',
        defaultMessage: 'Expiration Month'
    },
    expiryMonthDropdownEmptyLabel: {
        id: 'creditCard.messages.expiryMonthDropdownEmptyLabel',
        description: 'Label for expiration month empty as a dropdown value',
        defaultMessage: 'Month'
    },
    expiryLabelYear: {
        id: 'creditCard.messages.expiryLabelYear',
        description: 'Label for expiration year field on payment form',
        defaultMessage: 'Expiration Year'
    },
    expiryYearDropdownEmptyLabel: {
        id: 'creditCard.messages.expiryYearDropdownEmptyLabel',
        description: 'Label for expiration year empty as a dropdown value',
        defaultMessage: 'Year'
    },
    securityCodeLabel: {
        id: 'creditCard.messages.securityCodeLabel',
        description: 'Label for security code (CVV) field on payment form',
        defaultMessage: 'Security Code'
    },
    billingAddressTitle: {
        id: 'creditCard.messages.billingAddressTitle',
        description: 'Title of billing address section of credit card form',
        defaultMessage: 'Billing Address'
    },
    contactInformationTitle: {
        id: 'creditCard.messages.contactInformationTitle',
        description: 'Title of contact information section of credit card form',
        defaultMessage: 'Contact Information'
    },
    creditcardNumberRequired: {
        id: 'creditCard.messages.creditcardNumberRequired',
        description: 'Error message displayed when a credit card number was not provided on the payment form',
        defaultMessage: 'Please enter a valid credit card'
    },
    creditCardExpired: {
        id: 'creditCard.messages.creditCardExpired',
        description: 'Error message to display when a credit card is no longer valid due to expiration',
        defaultMessage: 'Your credit card has expired'
    },
    expMonthRequired: {
        id: 'creditCard.messages.expMonthRequired',
        description: 'Error message displayed when a credit card expiration month was not provided on the payment form',
        defaultMessage: 'Please enter a valid expiration month'
    },
    expYearRequired: {
        id: 'creditCard.messages.expYearRequired',
        description: 'Error message displayed when a credit card expiration year was not provided on the payment form',
        defaultMessage: 'Please enter a valid expiration year'
    },
    securityCodeRequired: {
        id: 'creditCard.messages.securityCodeRequired',
        description: 'Error message displayed when a credit card security code (CVV) '
            + 'was not provided on the payment form',
        defaultMessage: 'Please enter a security code'
    },
    securityCodeInvalid: {
        id: 'creditCard.messages.securityCodeInvalid',
        description: 'Error message displayed when an invalid credit card security code (CVV) '
            + 'was provided on the payment form',
        defaultMessage: 'Please enter a {length, number} digit security code'
    },
    limitedBillingStatesHeading: {
        id: 'creditCard.messages.limitedBillingStatesHeading',
        description: 'Heading for information alert displayed at top of the Payment Page which indicates that cards are only available in select markets.',
        defaultMessage: 'Reminder!'
    },
    limitedBillingStatesEGift: {
        id: 'creditCard.messages.limitedBillingStatesEGift',
        description: 'Information alert for digital-only brands displayed at top of the Payment Page which indicates that cards are only available in select markets.',
        defaultMessage: '{brandName} eGift Cards are only available in {filteredCount, plural, one {{state}} other {select markets}}.'
    },
    limitedBillingStatesEGiftAndPhysical: {
        id: 'creditCard.messages.limitedBillingStatesEGiftAndPhysical',
        description: 'Information alert for physical (or both digital and physical) brands displayed at top of the Payment Page which indicates that cards are only available in select markets.',
        defaultMessage: '{brandName} Gift Cards are only available in {filteredCount, plural, one {{state}} other {select markets}}.'
    },
    creditCardInformationHeading: {
        id: 'creditCard.messages.creditCardInformationHeading',
        description: 'Heading for the credit card information form',
        defaultMessage: 'Credit Card Information'
    },
    firstNameLabel: {
        id: 'creditCard.messages.firstNameLabel',
        description: 'Label for a first name field on an credit card form',
        defaultMessage: 'First Name on Card'
    },
    lastNameLabel: {
        id: 'creditCard.messages.lastNameLabel',
        description: 'Label for a last name field on an credit card form',
        defaultMessage: 'Last Name on Card'
    }
});

export const creditCardExpiryMonthDropdownMap = defineMessages({
    JANUARY: {
        id: 'creditCard.messages.expiryMonth.january',
        description: 'drop down value for January',
        defaultMessage: '01 - January'
    },
    FEBRUARY: {
        id: 'creditCard.messages.expiryMonth.february',
        description: 'drop down value for February',
        defaultMessage: '02 - February'
    },
    MARCH: {
        id: 'creditCard.messages.expiryMonth.march',
        description: 'drop down value for March',
        defaultMessage: '03 - March'
    },
    APRIL: {
        id: 'creditCard.messages.expiryMonth.april',
        description: 'drop down value for April',
        defaultMessage: '04 - April'
    },
    MAY: {
        id: 'creditCard.messages.expiryMonth.may',
        description: 'drop down value for May',
        defaultMessage: '05 - May'
    },
    JUNE: {
        id: 'creditCard.messages.expiryMonth.june',
        description: 'drop down value for June',
        defaultMessage: '06 - June'
    },
    JULY: {
        id: 'creditCard.messages.expiryMonth.july',
        description: 'drop down value for July',
        defaultMessage: '07 - July'
    },
    AUGUST: {
        id: 'creditCard.messages.expiryMonth.august',
        description: 'drop down value for August',
        defaultMessage: '08 - August'
    },
    SEPTEMBER: {
        id: 'creditCard.messages.expiryMonth.september',
        description: 'drop down value for September',
        defaultMessage: '09 - September'
    },
    OCTOBER: {
        id: 'creditCard.messages.expiryMonth.october',
        description: 'drop down value for October',
        defaultMessage: '10 - October'
    },
    NOVEMBER: {
        id: 'creditCard.messages.expiryMonth.november',
        description: 'drop down value for November',
        defaultMessage: '11 - November'
    },
    DECEMBER: {
        id: 'creditCard.messages.expiryMonth.december',
        description: 'drop down value for December',
        defaultMessage: '12 - December'
    }
});

export const creditCardExpiryMonthDropdownValueMap = {
    JANUARY: '01',
    FEBRUARY: '02',
    MARCH: '03',
    APRIL: '04',
    MAY: '05',
    JUNE: '06',
    JULY: '07',
    AUGUST: '08',
    SEPTEMBER: '09',
    OCTOBER: '10',
    NOVEMBER: '11',
    DECEMBER: '12'
};

export default creditCardMessages;
