export const feeTypes = {
    DIGITAL_ACTIVATION: 'ACTV',
    PLASTIC_ACTIVATION: 'PLAC',
    UYOP: 'UYOP'
};


export const getFeeAmountByTypeAndCost = (fees = [], feeType, certAmount = '0', programCode = 'DEFAULT') => {
    if (fees.length === 0) {
        return 0.00;
    }
    if (!certAmount) {
        return 0.00;
    }
    let feesForType = fees.filter(fee => fee.type === feeType && fee.programCode === programCode);
    if (feesForType.length === 0 || feesForType[0].tiers.length === 0) {
        
        feesForType = fees.filter(fee => fee.type === feeType && fee.programCode === 'DEFAULT');
        if (feesForType.length === 0) {
            return 0.00;
        }
    }
    let currentAmount = 0.00;
    let currentThreshold = 0.00;
    feesForType.forEach(fee => fee.tiers.forEach((tier) => {
        if ((currentThreshold <= Number(tier.threshold))
            && (Number(tier.threshold) <= Number(certAmount))) {
            currentAmount = Number(tier.amount);
            currentThreshold = Number(tier.threshold);
        }
    }));
    return currentAmount;
};

export const getFeeTypeFromItem = (item) => {
    if (item.get('isPlastic')) {
        if (item.get('uyoImageURL')) {
            return feeTypes.UYOP;
        }
        return feeTypes.PLASTIC_ACTIVATION;
    }
    return feeTypes.DIGITAL_ACTIVATION;
};
