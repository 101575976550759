import React from 'react';
import { icons } from 'cstar-react-primitives/lib/redux-form/icons/iconConst';
import { LinkExternal } from 'cstar-react-primitives/lib/redux-form/LinkExternal';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getIntlLocale } from '../intl/intlSelectors';
import headerMessages from './headerMessages';
import { setSelfieLinkClicked } from '../routing/flowModule';

export const GiftCardIcon = (props) => {
    const { selectedLocale, intl, dispatchSelfieLinkClicked } = props;
    const linkProps = {
        url: `/self_service/register/?locale=${selectedLocale}`
    };
    return (
        <React.Fragment>
            <LinkExternal
                {...linkProps}
                atts={{
                    'aria-label': intl.formatMessage(headerMessages.giftCardIconTitle),
                    onClick: dispatchSelfieLinkClicked
                }}
                cssClass="gift-card-icon"
            >
                {icons.GIFT_CARD_ICON}
            </LinkExternal>
            <div className="header-icon-title">
                {intl.formatMessage(headerMessages.giftCardIconTitle)}
            </div>
        </React.Fragment>
    );
};

GiftCardIcon.propTypes = {
    selectedLocale: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    dispatchSelfieLinkClicked: PropTypes.func.isRequired
};

export const mapStateToProps = state => ({
    selectedLocale: getIntlLocale(state)
});

export const mapDispatchToProps = dispatch => ({
    dispatchSelfieLinkClicked: () => dispatch(setSelfieLinkClicked({}))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GiftCardIcon));
