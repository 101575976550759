import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { intlShape } from 'react-intl';


export const ADDRESS_CONTEXT = {
    BILLING: 'BILLING',
    SHIPPING: 'SHIPPING'
};

export const COUNTRY_FIELDS = {
    CANADA: 'CA',
    GREAT_BRITAIN: 'GB',
    JAPAN: 'JP',
    HONG_KONG: 'HK',
    IRELAND: 'IE',
    UNITED_STATES: 'US',
    CHINA: 'CN',
    AUSTRALIA: 'AU',
    MEXICO: 'MX',
    NETHERLANDS: 'NL',
    BRAZIL: 'BR'
};

export const defaultFieldProps = {
    doFieldValidation: false,
    showUntouchedErrors: false,
    updateShippingAddress: () => {},
    useAddressAutocomplete: false,
    excludedBillingStates: ''
};

export const addressPropTypes = {
    addressContext: PropTypes.oneOf([
        ADDRESS_CONTEXT.BILLING,
        ADDRESS_CONTEXT.SHIPPING
    ]).isRequired,
    cashbotName: PropTypes.string.isRequired,
    doFieldValidation: PropTypes.bool, 
    intl: intlShape.isRequired,
    showUntouchedErrors: PropTypes.bool, 
    updateShippingAddress: PropTypes.func,
    useAddressAutocomplete: PropTypes.bool,
    validators: ImmutablePropTypes.map
};

export const addressFieldPropTypes = {
    ...addressPropTypes,
    countryCode: PropTypes.string,
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    phoneNumber: PropTypes.string,
    form: PropTypes.string.isRequired,
    setAddressChanged: PropTypes.func
};
