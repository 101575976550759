import { Map as IMap, fromJS } from 'immutable';


const removeKeyNamespace = (key, separator) => (
    key.split(separator).slice(1).join(separator)
);


const extractNamespacedMessages = (messages, namespace, separator = '.') => (
    messages
        .filter((message, key) => key.startsWith(`${namespace}${separator}`))
        .mapKeys(key => removeKeyNamespace(key, separator))
);


const extractOriginalMessages = (originalMessages, programMessages) => IMap().withMutations((map) => {
    programMessages.forEach((message, key) => {
        const originalValue = originalMessages.get(key);
        const newKey = `${key}.ORIGINAL`;
        if (originalValue) {
            map.set(newKey, originalValue);
        }
    });
});


export const getProgramCopy = (messages = {}, programCode) => {
    const messagesMap = fromJS(messages);
    const programMessages = extractNamespacedMessages(messagesMap, programCode);
    const originalMessages = extractOriginalMessages(messagesMap, programMessages);
    return messagesMap.merge(programMessages).merge(originalMessages).toJS();
};
