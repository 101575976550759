import validateFieldsByRules from '../validate/ruleValidator';

import itemMessages from '../item/itemMessages'; 
import { isValidEmail } from '../validate/email';
import { giftCardFieldNames, RECIPIENT_PLASTIC_NOTIFICATION } from '../item/newItemForm';
import {
    getLocalizedAddressFieldValidation
} from '../addressFields/addressValidation';
import { REQUIRED } from './PhysicalDeliveryFormConstants';
import { addressFieldNames } from '../address/addressModule';


const validate = (values, props) => {
    const {
        requireRecipientEmailForPhysicalGift,
        countryCode,
        addressContext,
        intl
    } = props;
    const plasticNotify = values.get(RECIPIENT_PLASTIC_NOTIFICATION);
    const recipientEmail = values.get(giftCardFieldNames.RECIPIENT_EMAIL_FIELD);
    
    const addressConstraints = getLocalizedAddressFieldValidation(countryCode, addressContext);
    const fieldSequence = [addressFieldNames.ADDRESS_FULLNAME, addressFieldNames.ADDRESS_COUNTRY,
        addressFieldNames.ADDRESS_STREET1, addressFieldNames.ADDRESS_STREET2, addressFieldNames.ADDRESS_CITY,
        addressFieldNames.ADDRESS_STATE, addressFieldNames.ADDRESS_POSTCODE, addressFieldNames.ADDRESS_PHONE];
    const errors = validateFieldsByRules(values, addressConstraints, intl, false, fieldSequence);

    if (plasticNotify || requireRecipientEmailForPhysicalGift === REQUIRED) {
        if (!recipientEmail) {
            errors[giftCardFieldNames.RECIPIENT_EMAIL_FIELD] = intl.formatMessage(itemMessages.emailRequired);
        } else if (!isValidEmail(recipientEmail)) {
            errors[giftCardFieldNames.RECIPIENT_EMAIL_FIELD] = intl.formatMessage(itemMessages.emailInvalid);
        }
    }

    return errors;
};

export default validate;
