import {
    put,
    select,
    takeEvery,
    takeLatest,
    throttle,
    call
} from 'redux-saga/effects';
import {
    createCapiSession,
    clearCapiSession,
    SESSION_CREATION_ERROR,
    SET_CAPI_SESSION
} from 'capi/redux';
import { getCapiSessionID } from 'capi/redux/immutable';
import { v4 as uuid } from 'uuid';

import { LOCATION_CHANGE } from '../routing/routingModule';

import {
    getHostname,
    getReferrer,
    getUrlParams
} from './utils';
import {
    clearStorefrontSession,
    setStorefrontSession,
    GET_OR_CREATE_STOREFRONT_SESSION,
    logSessionInfoInSegment,
    capiSessionCallIndicator
} from './sessionModule';
import { getStorefrontSessionKey, getCapiSessionCallIndicator } from './sessionSelectors';
import { logError } from '../utils/errorUtils';



const getCreateCapiSessionAction = sessionKey => createCapiSession({
    sessionKey,
    host: getHostname(),
    refUrl: getReferrer(),
    ...getUrlParams()
});


function* getOrCreateSession({ location = null }) {
    const callInProgress = yield select(getCapiSessionCallIndicator);
    if (!callInProgress) {
        const sessionId = yield select(getCapiSessionID);
        if (!sessionId) {
            yield call(getOrCreateSessionKey);
            const sessionKey = yield select(getStorefrontSessionKey);
            yield put(capiSessionCallIndicator());
            yield put(logSessionInfoInSegment('Session API called'));
            yield put(getCreateCapiSessionAction(sessionKey));
            if (location && window.RISKX) {
                
                window.RISKX.setSid(sessionKey);
                window.RISKX.go(location.location.pathname);
            }
        }
    }
}


function* getOrCreateSessionKey() {
    let sessionKey = yield select(getStorefrontSessionKey);
    if (!sessionKey) {
        sessionKey = yield uuid();
        yield put(setStorefrontSession(sessionKey));
    }
}


function* innerClearSessionSaga() {
    yield put(logSessionInfoInSegment('Clearing storefront and capi session'));
    yield put(clearStorefrontSession());
    yield put(clearCapiSession());
}



export const createClearSessionSaga = (action) => {
    function* clearSessionSaga() {
        yield takeEvery(action, innerClearSessionSaga);
    }
    return clearSessionSaga;
};


export function* getOrCreateSessionSaga() {
    yield takeLatest(GET_OR_CREATE_STOREFRONT_SESSION, getOrCreateSession);
}


export function* sessionApiFailureWatcherSaga() {
    yield takeEvery(SESSION_CREATION_ERROR, function* sessionApiFailureSaga(action) {
        const { error } = action;
        logError(`Session Api failed with: ${error}`);
        yield put(capiSessionCallIndicator(false));
        yield put(logSessionInfoInSegment('Session Api failed with error', { error }));
    });
}


export function* sessionApiSuccessWatcherSaga() {
    yield takeEvery(SET_CAPI_SESSION, function* sessionApiSuccessSaga() {
        yield put(capiSessionCallIndicator(false));
        yield put(logSessionInfoInSegment('Session Api call successful'));
    });
}


export function* checkSessionSaga() {
    yield throttle(1000, LOCATION_CHANGE, getOrCreateSession);
}
