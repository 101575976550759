
import { defineMessages } from 'react-intl';


const shippingMessages = defineMessages({
    title: {
        id: 'shippingMethod.messages.title',
        description: 'Top-level heading for selecting a physical shipping method',
        defaultMessage: 'How will you send it?'
    },
    subTitle: {
        id: 'shippingMethod.messages.subTitle',
        description: 'Sub title of selecting a physical shipping method',
        defaultMessage: 'How soon would you like your gift cards?'
    },
    editSubTitle: {
        id: 'shippingMethod.messages.editSubTitle',
        description: 'Sub title of the modal to change a card\'s shipping method',
        defaultMessage: 'Update shipping of the '
            + '{count, plural, one {gift card} other {{count, number} gift cards}} '
            + 'to {recipient} for {amount}'
    },
    editSubTitleSelfBuy: {
        id: 'shippingMethod.messages.editSubTitleSelfBuy',
        description: 'Sub title of the modal to change the shipping method for a card that is being shipped to the purchaser',
        defaultMessage: 'Update shipping of the '
            + '{count, plural, one {gift card} other {{count, number} gift cards}} '
            + 'to you for {amount}'
    },
    editButtonText: {
        id: 'shippingMethod.messages.editButtonText',
        description: 'Button text on the modal to change a card\'s shipping method '
            + 'to save the newly selected shipping method',
        defaultMessage: 'Update shipping method'
    },
    editErrorCloseButtonText: {
        id: 'shippingMethod.messages.editErrorCloseButtonText',
        description: 'Button text on the modal when there is an error trying to change a card\'s shipping method',
        defaultMessage: 'Close'
    },
    shippingRules: {
        id: 'shippingMethod.messages.shippingRules.md',
        description: 'Describes the shipping rules, supports Markdown',
        defaultMessage: "Please allow 1-2 business days for order processing.\n\n\u200b\n\n"
            + "Please note: Multiple cards shipped via USPS will be shipped one per envelope.\n\n\u200b\n\n"
            + "Items sent to P.O. Boxes, US territories, or military APO/FPO addresses can only be shipped via USPS."
    },
    shippingRadiogroupLegend: {
        id: 'shippingMethod.messages.shippingRadiogroupLegend',
        description: 'legend for shipping method radiogroup',
        defaultMessage: 'Choose a shipping method'
    },
    unableToDetermineShippingMethodsError: {
        id: 'shippingMethod.messages.unableToDetermineShippingMethodsError',
        description: 'Error message displayed when we could not find shipping methods for the requested address',
        defaultMessage: 'We are unable to determine a shipping method at this time. '
            + 'Please try your order again later, or purchase a digital card instead.'
    },
    purchaseDigitalCardInsteadButton: {
        id: 'shippingMethod.messages.purchaseDigitalCardInsteadButton',
        description: 'The button displayed with shipping methods error that allows user to switch to a Digital Card',
        defaultMessage: 'Purchase a Digital Card'
    },
    submitButtonText: {
        id: 'shippingMethod.messages.submitButtonText',
        description: 'Text shown on preceding steps on buttons which will move the user to the shipping method step',
        defaultMessage: 'Shipping'
    }
});

export default shippingMessages;
