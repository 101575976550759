import { Map as IMap, List as IList } from 'immutable';
import { createSelector } from 'reselect';

import { messages } from '../intl/intlSelectors';
import { getShippingPromoData } from '../promo/promoSelectors';
import {
    giftCardFieldNames,
    newItemCurrency,
    newItemQuantity,
    newItemAddressHash,
    newItemHasAccessory
} from '../item/newItemForm';

import { METHOD_REQUEST_STATES } from './shippingModule';

const sortByCarrier = (methodA, methodB) => (
    methodA.get('carrier').localeCompare(methodB.get('carrier'))
);

export const sortByCost = (methodA, methodB) => (
    parseFloat(methodA.get('cost')) > parseFloat(methodB.get('cost')) ? 1 : -1
);


export const getShippingProfiles = state => state.get('shippingMethods', IMap());


export const getShippingMethodProfile = (state, profile) => (
    state.getIn(['shippingMethods', profile], IMap())
);


export const getShippingMethods = createSelector(
    getShippingMethodProfile,
    methods => methods.get('methods', IList())
);


export const getProfileByCarrier = createSelector(
    getShippingMethods,
    shippingMethods => shippingMethods.sort(-sortByCarrier)
);


export const getProfileLowestCostMethod = createSelector(
    getShippingMethods,
    methods => methods.sort(sortByCost).get(0)
);


export const getMethodStatus = createSelector(
    getShippingMethodProfile,
    profile => profile.get('status')
);


export const getMethodErrorStatus = createSelector(
    getShippingMethodProfile,
    profile => profile.get('errorStatus')
);


export const hasProfile = createSelector(
    getMethodStatus,
    status => status === METHOD_REQUEST_STATES.get('SUCCESS')
);


export const getShippingMethod = (state, profile, token) => {
    if (state.hasIn(['shippingMethods', profile, 'methods'])) {
        return state.getIn(['shippingMethods', profile, 'methods']).find(
            method => method.get('token') === token
        );
    }
    return null;
};


export const hasActiveRequest = createSelector(
    getShippingProfiles,
    profiles => profiles.reduce(
        (result, profile) => result || profile.get('status') === METHOD_REQUEST_STATES.get('ACTIVE'),
        false
    )
);


export const getShippingMethodProfileId = (addressID, quantity, currency, hasAccessory) => (
    `${addressID}_${currency}_${quantity > 1 ? 'multi' : 'single'}${hasAccessory ? '_has_accessory' : ''}`
);


export const getItemShippingMethodProfile = item => (
    getShippingMethodProfileId(
        item.get(giftCardFieldNames.ADDRESS_ID),
        item.get(giftCardFieldNames.QUANTITY_FIELD),
        item.get(giftCardFieldNames.CURRENCY_FIELD),
        !!item.get(giftCardFieldNames.ITEM_ACCESSORY, false)
    )
);


export const getCurrentShippingMethodProfile = createSelector(
    [newItemAddressHash, newItemQuantity, newItemCurrency, newItemHasAccessory],
    (addressHash, quantity, currency, hasAccessory) => getShippingMethodProfileId(
        addressHash,
        quantity,
        currency,
        hasAccessory
    )
);


export const getShippingRules = createSelector(
    messages,
    msgs => msgs.get('shippingMethod.messages.shippingRules.md', '')
);



export const getRewardShippingMethods = createSelector(
    getShippingMethods,
    getShippingPromoData,
    (existingMethods, promoShippingMethodTokens) => promoShippingMethodTokens
);
