import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import CartIcon from '../cart/CartIcon';
import { getBrandUrl, getBrandName, getBrandCode } from '../brand/brandSelectors';
import { getShowCartPaths } from '../routing/flowSelectors';
import { setCartStatusReady, setCartViewed } from '../cart/cartModule';
import { parseCartViewed } from '../segment/segmentSelectors';
import HeaderImageLink from './HeaderImageLink';

import GiftCardIcon from './headerGiftCardsRedirect';


export const Header = ({
    brandUrl,
    brandName,
    brandCode,
    showCartPaths,
    goToCart,
    cartViewed,
    cartViewedSegment
}) => {
    const cartIconHandler = () => {
        goToCart();
        cartViewed(cartViewedSegment);
    };
    return (
        <header className="header">
            <div className="header-container">
                <HeaderImageLink brandUrl={brandUrl} brandName={brandName} brandCode={brandCode} />
                <div className='header-left-icons'>
                    <div className="self-service-redirect">
                        <GiftCardIcon />
                    </div>
                    <Route
                        path={showCartPaths}
                        render={() => (
                            <CartIcon clickHandler={cartIconHandler} />
                        )}
                    />
                </div>
            </div>
        </header>
    );
};

Header.defaultProps = {
    cartViewedSegment: null
};


Header.propTypes = {
    
    brandUrl: PropTypes.string.isRequired,
    brandName: PropTypes.string.isRequired,
    brandCode: PropTypes.string.isRequired,
    showCartPaths: PropTypes.array.isRequired,
    cartViewedSegment: PropTypes.object,
    
    goToCart: PropTypes.func.isRequired,
    cartViewed: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    brandUrl: getBrandUrl(state),
    brandName: getBrandName(state),
    brandCode: getBrandCode(state),
    showCartPaths: getShowCartPaths(state),
    cartViewedSegment: parseCartViewed(state)
});

const mapDispatchToProps = {
    goToCart: setCartStatusReady,
    cartViewed: setCartViewed

};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
