import cardTypeMessages from '../cardType/cardTypeMessages';
import cartMessages from '../cart/cartMessages';
import choiceMessages from '../design/choiceMessages';
import deliveryMessages from '../delivery/deliveryMessages';
import recipientMessages from '../recipient/recipientMessages';
import amountMessages from '../amount/amountMessages';
import messageIntl from '../message/messageIntl';


export default {
    recipient: {
        getSubmitMessage: () => recipientMessages.submitButtonText
    },
    type: {
        getSubmitMessage: () => cardTypeMessages.submitButtonText
    },
    design: {
        
        getSubmitMessage: (flowParams, submitMessageParams) => {
            const {
                isSingleFaceplate,
                isSingleDenomination,
                isSingleCardType,
                supportsAnyUyo,
                isMessageSkippable
            } = flowParams;
            const { isPlastic, isSelfBuy } = submitMessageParams;
            if (isSingleCardType && isSingleFaceplate && !supportsAnyUyo) {
                if (isSingleDenomination) {
                    if (isSelfBuy) {
                        return cartMessages.submitButtonText;
                    }
                    if (!isMessageSkippable) {
                        return messageIntl.submitButtonText;
                    }
                    return deliveryMessages.submitButtonText;
                }
                return isPlastic ? amountMessages.submitButtonPhysicalText : amountMessages.submitButtonDigitalText;
            }
            return isPlastic ? choiceMessages.submitButtonPhysicalText : choiceMessages.submitButtonDigitalText;
        }
    },
    amount: {
        getSubmitMessage: ({ isPlastic }) => (
            isPlastic ? amountMessages.submitButtonPhysicalText : amountMessages.submitButtonDigitalText
        )
    }
};
