import { fromJS } from 'immutable';

const uyoFlow = fromJS([
    'uyo-init',
    'uyo-select',
    'uyo-review'
]);

const postUyoPgcGiftFlow = fromJS([
    'amount',
    'message',
    'delivery',
    'shipping'
]);

const postUyoEgcGiftFlow = fromJS([
    'amount',
    'message',
    'delivery'
]);

const pgcGiftFlow = fromJS([
    'design',
    ({ isUYO }) => (isUYO ? uyoFlow.concat(postUyoPgcGiftFlow) : postUyoPgcGiftFlow)
]);
const egcGiftFlow = fromJS([
    'design',
    ({ isUYO }) => (isUYO ? uyoFlow.concat(postUyoEgcGiftFlow) : postUyoEgcGiftFlow)
]);
const giftFlow = fromJS([
    'type',
    ({ isPlastic }) => (isPlastic ? pgcGiftFlow : egcGiftFlow)
]);

const postUyoPgcSelfFlow = fromJS([
    ({ hasAccessories }) => (
        hasAccessories
            ? fromJS(['amount', 'message', 'delivery', 'shipping'])
            : fromJS(['amount', 'delivery', 'shipping']))
]);

const postUyoEgcSelfFlow = fromJS([
    ({ hasAccessories }) => (
        hasAccessories
            ? fromJS(['amount', 'message'])
            : fromJS(['amount']))
]);

const pgcSelfFlow = fromJS([
    'design',
    ({ isUYO }) => (isUYO ? uyoFlow.concat(postUyoPgcSelfFlow) : postUyoPgcSelfFlow)
]);
const egcSelfFlow = fromJS([
    'design',
    ({ isUYO }) => (isUYO ? uyoFlow.concat(postUyoEgcSelfFlow) : postUyoEgcSelfFlow)
]);
const selfFlow = fromJS([
    'type',
    ({ isPlastic }) => (isPlastic ? pgcSelfFlow : egcSelfFlow)
]);

const buyFlow = fromJS([
    'program',
    'recipient',
    ({ isSelfBuy }) => (isSelfBuy ? selfFlow : giftFlow)
]);

const postBuyFlow = fromJS([
    'cart', 'pay-method', 'payment'
]);



const postPurchaseFlow = fromJS([
    'confirmation'
]);

const defaultFlowConfig = fromJS({
    buy: buyFlow,
    uyo: uyoFlow,
    postBuy: postBuyFlow,
    postPurchase: postPurchaseFlow
});

export default defaultFlowConfig;
