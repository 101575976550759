import { put, takeEvery } from 'redux-saga/effects';

import { fieldChangedAction } from '../payment/paymentFormModule';

import {
    ACCEPTED_CARDHOLDER_AGREEMENT,
    ACCEPTED_GDPR_TERMS_AGREEMENT,
    setCardholderAgreementAcceptance,
    setGdprTermsAgreementAcceptance
} from './consentModule';


function* updateCardHolderAcceptance({ newValue }) {
    yield put(setCardholderAgreementAcceptance(newValue));
}

function* updateGdprTermsAgreement({ newValue }) {
    yield put(setGdprTermsAgreementAcceptance(newValue));
}

export function* consentWatcher() {
    yield takeEvery(fieldChangedAction(ACCEPTED_CARDHOLDER_AGREEMENT), updateCardHolderAcceptance);
    yield takeEvery(fieldChangedAction(ACCEPTED_GDPR_TERMS_AGREEMENT), updateGdprTermsAgreement);
}
