import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { useSelector } from 'react-redux';

import PhoneNumberField from '../primitive/PhoneNumberField';
import { giftCardFieldNames, newItemSmsCheckbox } from '../item/newItemForm';
import itemMessages from '../item/itemMessages';
import { getBrandName, getSMSDialingCountries } from '../brand/brandSelectors';

const SMSField = ({
    intl, cashbotName, fieldLabel, fieldHelper, recipientName, smsDeliveryEnabled, change
}) => {
    const brandName = useSelector(state => getBrandName(state));
    const currentSmsState = useSelector(state => newItemSmsCheckbox(state));
    const dialingCountries = useSelector(state => getSMSDialingCountries(state));
    const smsProps = {
        name: giftCardFieldNames.RECIPIENT_SMS_FIELD,
        label: intl.formatMessage(fieldLabel, { name: recipientName }),
        helper: intl.formatMessage(fieldHelper, { name: recipientName, brandName }),
        isRequiredField: false,
        cashbotName,
        disabled: smsDeliveryEnabled && !currentSmsState,
        countries: dialingCountries,
        change
    };
    return <PhoneNumberField {...smsProps} />;
};

SMSField.defaultProps = {
    fieldLabel: itemMessages.recipientSMSLabel,
    fieldHelper: itemMessages.recipientSMSHelper,
    recipientName: '',
    disabled: false,
    smsDeliveryEnabled: false,
    change: () => {}
};

SMSField.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    fieldLabel: PropTypes.object,
    fieldHelper: PropTypes.object,
    recipientName: PropTypes.string,
    intl: intlShape.isRequired,
    disabled: PropTypes.bool,
    smsDeliveryEnabled: PropTypes.bool,
    change: PropTypes.func
};

export default SMSField;
