import { fromJS, List as IList } from 'immutable';


export const REQUEST_FACEPLATE_GROUPS = '@@storefront/REQUEST_FACEPLATE_GROUPS';
export const SET_FACEPLATE_GROUPS = '@@storefront/SET_FACEPLATE_GROUPS';
export const FACEPLATE_PREFETCH_END = '@@storefront/FACEPLATE_PREFETCH_END';
export const FACEPLATE_PREFETCH_IMAGES = '@@storefront/FACEPLATE_PREFETCH_IMAGES';
export const FACEPLATES_LOADED = '@@storefront/FACEPLATES_LOADED';



export const faceplateReducer = (state = IList(), action) => {
    switch (action.type) {
        case SET_FACEPLATE_GROUPS: {
            return fromJS(action.faceplates);
        }
        default:
            return state;
    }
};


export const requestFaceplateGroups = params => ({
    type: REQUEST_FACEPLATE_GROUPS,
    params
});

export const setFaceplateGroups = faceplates => ({
    type: SET_FACEPLATE_GROUPS,
    faceplates
});

export const faceplatePrefetchImages = faceplates => ({
    type: FACEPLATE_PREFETCH_IMAGES,
    faceplates
});
