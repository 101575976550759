import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { LinkButton } from 'cstar-react-primitives/lib/redux-form/buttons/LinkButton';
import Markdown from 'markdown-to-jsx';

import { mapDispatchModalToProps } from '../modal/modalModule';
import ConnectedModal from '../modal/ConnectedModal';
import { getPromoTerms } from '../promo/promoSelectors';

import { promoBannerMessages as messages } from './promoMessages';


export const PROMO_TERMS_MODAL = 'promo-terms';

export const PromoTermsModal = ({
    modalId,
    content,
    handleCloseModal
}) => {
    const modalProps = {
        modalId,
        cashbotName: 'promo-modal',
        headerText: messages.modalHeader,
        contentLabel: messages.modalContentLabel,
        handleCloseModal
    };

    return (
        <ConnectedModal {...modalProps}>
            <Markdown>{content}</Markdown>
        </ConnectedModal>
    );
};

PromoTermsModal.defaultProps = {
    handleCloseModal: () => {}
};

PromoTermsModal.propTypes = {
    modalId: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    handleCloseModal: PropTypes.func
};

export const PromoTermsLink = ({
    modalId,
    promoTerms,
    setModalDisplay
}) => {
    const linkProps = {
        cashbotName: 'promo-link-button',
        onClick: () => setModalDisplay(modalId, true),
        cssClass: 'btn-link promo-link'
    };
    const modalProps = {
        modalId,
        content: promoTerms,
        handleCloseModal: () => setModalDisplay(modalId, false)
    };

    if (!promoTerms) {
        return null;
    }
    return (
        <div>
            <LinkButton {...linkProps}>
                <FormattedMessage {...messages.termsLink} />
            </LinkButton>
            <PromoTermsModal {...modalProps} />
        </div>
    );
};

PromoTermsLink.defaultProps = {
    modalId: PROMO_TERMS_MODAL,
    setModalDisplay: () => {},
    promoTerms: ''
};

PromoTermsLink.propTypes = {
    modalId: PropTypes.string,
    
    setModalDisplay: PropTypes.func,
    
    promoTerms: PropTypes.string
};

export const mapStateToProps = state => ({
    promoTerms: getPromoTerms(state)
});

export default connect(
    mapStateToProps,
    mapDispatchModalToProps
)(PromoTermsLink);
