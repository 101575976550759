import {
    fromJS, List as IList, Map as IMap, Set as ISet
} from 'immutable';
import { createSelector } from 'reselect';


export const getProgramState = state => state.get('programs', IMap());


export const getProgramConfigLoadTime = createSelector(
    getProgramState,
    programState => programState.get('programConfigLoadTime', null)
);


export const getProgramsLoaded = createSelector(
    getProgramState,
    programState => programState.has('programConfigLoadTime')
);


export const getActiveProgramCode = createSelector(
    getProgramState,
    programState => programState.get('activeProgramCode', null)
);


export const getProgramConfigs = createSelector(
    getProgramState,
    programState => programState.get('programConfig', IMap())
);


export const getProgramSelectorList = createSelector(
    getProgramState,
    programState => programState.get('programList', IList())
);


export const getPublishedProgramsList = createSelector(
    getProgramConfigs,
    programConfigs => programConfigs.map((config, programCode) => config.set('programCode', programCode)).toList()
);


export const programIsActive = createSelector(
    getActiveProgramCode,
    programCode => programCode !== null
);


export const isProgramConfigured = createSelector(
    getProgramConfigs,
    programConfigs => programCode => programConfigs.has(programCode)
);


export const isActiveProgramConfigured = createSelector(
    isProgramConfigured,
    getActiveProgramCode,
    (programConfigured, programCode) => programConfigured(programCode)
);


export const getProgramConfig = createSelector(
    getProgramConfigs,
    programConfigs => programCode => programConfigs.get(programCode, IMap())
);


export const getActiveProgramConfig = createSelector(
    getProgramConfig,
    getActiveProgramCode,
    (programConfig, activeProgramCode) => programConfig(activeProgramCode)
);


export const getProgramCatalogs = createSelector(
    getProgramConfig,
    programConfig => programCode => programConfig(programCode).get('catalogs', IMap())
);


export const getActiveProgramCatalogs = createSelector(
    getActiveProgramConfig,
    programConfig => programConfig.get('catalogs', IMap())
);


export const getProgramElectronicCurrencyList = createSelector(
    getProgramConfig,
    programConfig => programCode => programConfig(programCode).get('electronicCurrencyList', IList())
);


export const getActiveProgramElectronicCurrencyList = createSelector(
    getActiveProgramConfig,
    programConfig => programConfig.get('electronicCurrencyList', IList())
);


export const getProgramPlasticCurrencyList = createSelector(
    getProgramConfig,
    programConfig => programCode => programConfig(programCode).get('plasticCurrencyList', IList())
);


export const getActiveProgramPlasticCurrencyList = createSelector(
    getActiveProgramConfig,
    programConfig => programConfig.get('plasticCurrencyList', IList())
);


export const getAllProgramCurrencies = createSelector(
    getProgramPlasticCurrencyList,
    getProgramElectronicCurrencyList,
    (digitalCurrencies, plasticCurrencies) => programCode => ISet(digitalCurrencies(programCode))
        .union(plasticCurrencies(programCode))
);


export const getProgramIncludedBillingStates = createSelector(
    getProgramConfigs,
    programConfigs => (programCode) => {
        const program = programConfigs.get(programCode);
        if (!program) {
            return undefined;
        }
        const includedBillingStates = program.get('includedBillingStates');
        if (!includedBillingStates) {
            return undefined;
        }
        return ISet(includedBillingStates.split(','));
    }
);


export const getAllActiveProgramCurrencies = createSelector(
    getActiveProgramElectronicCurrencyList,
    getActiveProgramPlasticCurrencyList,
    (digitalCurrencies, plasticCurrencies) => ISet(digitalCurrencies).union(plasticCurrencies)
);


export const getActiveProgramSupportedCardTypes = createSelector(
    getActiveProgramConfig,
    (programConfig) => {
        if (programConfig.size === 0) {
            return fromJS({
                supportsDigitalCards: false,
                supportsPlasticCards: false
            });
        }
        const supportsDigital = programConfig.get('supportsDigitalCards');
        const supportsPlastic = programConfig.get('supportsPlasticCards');
        return fromJS({
            supportsDigitalCards: supportsDigital,
            supportsPlasticCards: supportsPlastic
        });
    }
);

export const getProgramUYOEgcPhotoSupported = createSelector(
    getActiveProgramConfig,
    programConfig => programConfig.get('uploadEgcPhotoEnabled', true)
);

export const getProgramUYOPlasticPhotoSupported = createSelector(
    getActiveProgramConfig,
    programConfig => programConfig.get('uploadPlasticPhotoEnabled', true)
);

export const getProgramSupportedUYO = createSelector(
    [getProgramUYOEgcPhotoSupported, getProgramUYOPlasticPhotoSupported],
    (supportsUYOEgcPhoto, supportsUYOPlasticPhoto) => fromJS({
        supportsUYOEgcPhoto,
        supportsUYOPlasticPhoto
    })
);


export const isProgramSelectionEnabled = createSelector(
    getProgramState,
    programState => programState.get('programPickerEnabled', false)
);


export const getActiveProgramExcludedBillingStates = createSelector(
    getActiveProgramConfig,
    activeProgramConfig => activeProgramConfig.get('excludedBillingStates', '')
);
