
import { defineMessages } from 'react-intl';

const programMessages = defineMessages({
    title: {
        id: 'program.programMessages.title',
        description: 'Top-level heading for the program selection step',
        defaultMessage: 'Select a Gift'
    },
    programTitle: {
        id: 'program.programMessages.programTitle',
        description: 'Top-level heading for an individual program on the program selection step',
        defaultMessage: 'Program {programCode}'
    },
    classicExperienceTitle: {
        id: 'program.programMessages.classicExperienceTitle',
        description: 'Top-level heading for the classic gift card experience on the program selection screen',
        defaultMessage: 'Classic Gift Cards'
    },
    programLegend: {
        id: 'program.programMessages.programLegend',
        description: 'Aria label to give context to the program picker radiogroup',
        defaultMessage: 'Select a Gift'
    },
    noProgramSelectedWarning: {
        id: 'program.programMessages.noProgramSelectedWarning',
        description: 'Warning message displayed when clicking next on program selection step with no program selected',
        defaultMessage: '<span id="error-message"> Please select a gift to continue</span>'
    },
    programStateExclusionWarning: {
        id: 'program.programMessages.programStateExclusionWarning',
        description: 'Warning message displayed per program indicating billing state exclusions',
        defaultMessage: '{programName} cards are only available for purchase and redemption in {count, plural, one {{state}} other {select markets}}.'
    },
    billingStateCartConflictWarning: {
        id: 'program.programMessages.billingStateCartConflictWarning.md',
        description: 'Warning message displayed on the programs page indicating that the selection is limited due to billing state restrictions of cards already in the cart',
        defaultMessage: 'You currently have a **{programName}** card in your cart. You may add another, but you will need to complete your purchase in order to buy a card from another market.'
    }
});


export const optionalProgramMessageIds = {
    customCardHeader: 'program.programMessages.customCardHeader',
    customCardTitle: 'program.programMessages.customCardTitle',
    customCardDescription: 'program.programMessages.customCardDescription.md',
    customCardImage: 'program.programMessages.customCardImage',
    customCardActionButton: 'program.programMessages.customCardActionButton',
    customCardActionUrl: 'program.programMessages.customCardActionUrl',
    customCardImageAlt: 'program.programMessages.customCardImageAlt',
    classicCardDescription: 'program.programMessages.classicCardDescription.md',
    programDescription: 'program.programMessages.programDescription.md',
    pickerPreviewImage: 'program.programMessages.pickerPreviewImage',
    pickerPreviewImageAlt: 'program.programMessages.pickerPreviewImageAlt'
};

export default programMessages;
