import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedMessage } from 'react-intl';

import accessoriesMessages from './accessoriesMessages';


export const AccessoryImgMissing = () => (
    <div className="accessory-image-missing">
        <FormattedMessage {...accessoriesMessages.noImageAvailable} />
    </div>
);

export const AccessoryImg = ({
    accessory,
    cssClass,
    altText,
    ...rest
}) => {
    if (!accessory) return <AccessoryImgMissing />;

    const { imageUrl } = accessory.toJS();

    return (
        <img
            className={cssClass}
            src={imageUrl}
            alt={altText}
            {...rest}
        />
    );
};

AccessoryImg.defaultProps = {
    accessory: null,
    cssClass: 'accessory-image',
    altText: null
};

AccessoryImg.propTypes = {
    accessory: ImmutablePropTypes.contains({
        imageUrl: PropTypes.string.isRequired
    }),
    cssClass: PropTypes.string,
    altText: PropTypes.string
};

export default AccessoryImg;
