import { fromJS } from 'immutable';
import { giftCardFieldAttrs } from '../item/newItemForm';


export const PAYPAL_PAYMENT_TYPE = 'paypal';


export const CITY_MAX = 50;
export const STATE_MAX = 50;
export const STREET1_MAX = 100;
export const POSTAL_CODE_MAX = 10;

export const paypalAuthFieldAttrs = {
    CITY_MAX,
    STATE_MAX,
    STREET1_MAX,
    POSTAL_CODE_MAX

};


export const paypalAuthPaymentSerializer = (authData) => {
    const addressLessPayPal = fromJS({
        paymentMethod: PAYPAL_PAYMENT_TYPE,
        
        
        orderID: authData.id,
        response: authData.status,
        firstName: authData.payer.name.given_name.slice(0, giftCardFieldAttrs.NAME_FIELD_MAX),
        lastName: authData.payer.name.surname.slice(0, giftCardFieldAttrs.LASTNAME_FIELD_MAX),
        senderEmail: authData.payer.email_address.slice(0, giftCardFieldAttrs.EMAIL_FIELD_MAX),
        payerID: authData.payer.payer_id,
        countryCode: authData.payer.address.country_code,
        approved: authData.status === 'COMPLETED'
    });
    if (authData.purchase_units[0].shipping.address) {
        return addressLessPayPal.merge(fromJS({
            city: authData.purchase_units[0].shipping.address.admin_area_2
                .slice(0, paypalAuthFieldAttrs.CITY_MAX),
            street1: authData.purchase_units[0].shipping.address.address_line_1
                .slice(0, paypalAuthFieldAttrs.STREET1_MAX),
            state: authData.purchase_units[0].shipping.address.admin_area_1
                .slice(0, paypalAuthFieldAttrs.STATE_MAX),
            postalCode: authData.purchase_units[0].shipping.address.postal_code
                .slice(0, paypalAuthFieldAttrs.POSTAL_CODE_MAX)
        }));
    }
    return addressLessPayPal;
};



export const paypalPaymentDataHandler = authExecutor => (
    Promise.resolve(authExecutor()).then(data => (paypalAuthPaymentSerializer(data)))
);
