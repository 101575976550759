import { Map as IMap, fromJS } from 'immutable';
import { RETRIEVED_BRAND_CONFIG } from 'capi/redux';

import { parseCatalogs } from '../catalogs/catalogUtils';


export const PROGRAM_STORE_NAME = 'programs';
export const PICKER_CLASS = 'program-picker';


export const SET_PROGRAM_CODE = '@@storefront/SET_PROGRAM_CODE';
export const PROGRAM_LOADED = '@@storefront/PROGRAM_LOADED';
export const CHANGE_PROGRAM = '@@storefront/CHANGE_PROGRAM';
export const CLEAR_PROGRAM = '@@storefront/CLEAR_PROGRAM';
export const PROGRAM_CLEARED = '@@storefront/PROGRAM_CLEARED';
export const PROGRAM_CHANGE_COMPLETE = '@@storefront/PROGRAM_CHANGE_COMPLETE';
export const PROGRAM_CONFIG_LOADED = '@@storefront/PROGRAM_CONFIG_LOADED';

export const initialState = fromJS({
    activeProgramCode: null,
    programConfig: {}
});

const parseProgramConfigs = (programConfigs) => {
    const programCodes = Object.keys(programConfigs);
    return IMap(programCodes
        .map((programCode) => {
            const {
                catalog: {
                    catalogs,
                    electronicCurrencyList,
                    plasticCurrencyList,
                    supportsDigitalCards,
                    supportsPlasticCards
                },
                features: {
                    uploadPlasticPhotoEnabled,
                    uploadEgcPhotoEnabled
                },
                payment: {
                    excludedBillingStates,
                    includedBillingStates
                }
            } = programConfigs[programCode];
            const parsedCatalog = {
                catalogs: parseCatalogs(catalogs),
                electronicCurrencyList,
                plasticCurrencyList,
                supportsDigitalCards,
                supportsPlasticCards,
                uploadEgcPhotoEnabled,
                uploadPlasticPhotoEnabled,
                excludedBillingStates,
                includedBillingStates
            };
            return [
                programCode,
                fromJS(parsedCatalog)
            ];
        }));
};


export default function programReducer(state = initialState, action) {
    switch (action.type) {
        case RETRIEVED_BRAND_CONFIG.PROGRAMS: {
            const { programConfig, programPickerEnabled, programList } = action.data;
            return state.merge({
                programConfig: parseProgramConfigs(programConfig),
                programPickerEnabled,
                programList: fromJS(programList)
            });
        }
        case PROGRAM_CONFIG_LOADED:
            return state.set('programConfigLoadTime', action.programConfigLoadTime);
        case SET_PROGRAM_CODE:
            return state.set('activeProgramCode', action.programCode);
        default:
            return state;
    }
}



export function setProgramCode(programCode) {
    return {
        type: SET_PROGRAM_CODE,
        programCode
    };
}


export function programLoaded() {
    return {
        type: PROGRAM_LOADED
    };
}


export function programChangeComplete() {
    return {
        type: PROGRAM_CHANGE_COMPLETE
    };
}


export function changeProgram(programCode) {
    return {
        type: CHANGE_PROGRAM,
        programCode
    };
}


export function clearProgram() {
    return {
        type: CLEAR_PROGRAM
    };
}


export function programCleared() {
    return {
        type: PROGRAM_CLEARED
    };
}



export function programConfigLoaded(programConfigLoadTime) {
    return {
        type: PROGRAM_CONFIG_LOADED,
        programConfigLoadTime
    };
}
