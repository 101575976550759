import { fromJS } from 'immutable';
import {
    fork, take, put, call, select, all
} from 'redux-saga/effects';

import { FINISH_GET_PROMO } from '../promo/promoModule';
import { replace, removeQueryParams } from '../routing/routing';
import { getPaymentMethodsPath } from '../routing/flow';
import { getMasterpassCheckoutId } from '../brand/brandSelectors';
import { processPayment } from '../payment/paymentModule';
import { logError } from '../utils/errorUtils';
import { getLoyaltyNumber } from '../loyalty/loyaltySelectors';

import { MASTERPASS_PAYMENT_TYPE } from './masterpassSerializers';
import { START_MASTERPASS_CALLBACK } from './masterpassModule';



export function* masterpassGetDataSaga({ location }) {
    const params = new window.URLSearchParams(location.search);
    const status = params.get('mpstatus');
    const masterpassToken = params.get('oauth_verifier');

    
    
    yield call(replace, { pathname: getPaymentMethodsPath() });
    yield call(removeQueryParams, ['oauth_token', 'mpstatus', 'oauth_verifier', 'checkoutId', 'checkout_resource_url']);

    if (status === 'success') {
        const checkoutId = yield select(getMasterpassCheckoutId);
        const loyaltyNumber = yield select(getLoyaltyNumber);
        yield put(processPayment(MASTERPASS_PAYMENT_TYPE, fromJS({ masterpassToken, checkoutId, loyaltyNumber })));
    } else if (status !== 'cancel') {
        yield call(logError, new Error(
            `We got back an unexpected masterpass status of ${status} for masterpassToken: ${masterpassToken}`
        ));
    }
}


export function* masterpassSagasWatcher() {
    
    
    const results = yield all({
        finishGetDefaultPromo: take(FINISH_GET_PROMO),
        startMasterpassCallback: take(START_MASTERPASS_CALLBACK)
    });

    yield fork(masterpassGetDataSaga, results.startMasterpassCallback);
}
