import { Map as IMap } from 'immutable';


export const SET_SHOW_DETAILS = '@@storefront/SET_SHOW_DETAILS';
export const SET_EDIT_DETAILS = '@@storefront/SET_EDIT_DETAILS';
export const SET_EDIT_ADDRESS = '@@storefront/SET_EDIT_ADDRESS';
export const SET_EDIT_QUANTITY = '@@storefront/SET_EDIT_QUANTITY';
export const SET_FORM_INVALID_STATUS = '@@storefront/SET_FORM_INVALID_STATUS';
export const UNSAFE_REMOVE_ITEM_DISPLAY = '@@storefront/UNSAFE_REMOVE_ITEM_DISPLAY';
export const RESET_ITEM_DISPLAY = '@@storefront/RESET_ITEM_DISPLAY';
export const SET_FOCUS_SHIPPING_SELECT = '@@storefront/SET_FOCUS_SHIPPING_SELECT';
export const SET_ADDRESS_CHANGED = '@@storefront/SET_ADDRESS_CHANGED';
export const SET_SHIPPING_METHOD_CHANGED = '@@storefront/SET_SHIPPING_METHOD_CHANGED';
export const CHANGE_ITEM_DISPLAY_VALUE = '@@storefront/CHANGE_ITEM_DISPLAY_VALUE';

export const SHOW_DETAILS_KEY = 'showDetails';
export const EDIT_DETAILS_KEY = 'editDetails';
export const EDIT_ADDRESS_KEY = 'editAddress';
export const EDIT_QUANTITY_KEY = 'editQuantity';
export const EDIT_FORM_INVALID_KEY = 'editFormInValidStatus';
export const ITEM_DISPLAY_STORE_NAME = 'itemDisplay';
export const ITEM_FOCUS_SHIPPING_SELECT = 'itemShippingSelectFocus';
export const SHIPPING_ADDRESS_CHANGED = 'shippingAddressChanged';
export const SHIPPING_METHOD_CHANGED = 'shippingMethodChanged';


export const initialState = IMap();






export default function itemDisplayReducer(state = initialState, action) {
    switch (action.type) {
        case UNSAFE_REMOVE_ITEM_DISPLAY:
            return state.delete(action.item.get('id'));
        case SET_SHOW_DETAILS:
            return state.setIn([
                action.payload.id,
                action.payload.context,
                SHOW_DETAILS_KEY
            ], action.payload.showDetails);
        case SET_EDIT_DETAILS:
            return state.setIn([action.id, EDIT_DETAILS_KEY], action.editDetails);
        case SET_EDIT_ADDRESS:
            return state.setIn([action.id, EDIT_ADDRESS_KEY], action.editAddress);
        case SET_EDIT_QUANTITY:
            return state.setIn([action.id, EDIT_QUANTITY_KEY], action.editQuantity);
        case SET_FORM_INVALID_STATUS:
            return state.setIn([action.id, EDIT_FORM_INVALID_KEY], action.formValidStatus);
        case SET_FOCUS_SHIPPING_SELECT:
            return state.setIn([action.id, ITEM_FOCUS_SHIPPING_SELECT], action.setFocusShippingMethodSelect);
        case SET_ADDRESS_CHANGED: {
            return state.setIn([action.itemID, SHIPPING_ADDRESS_CHANGED], action.addressChanged);
        }
        case SET_SHIPPING_METHOD_CHANGED: {
            return state.setIn([action.itemID, SHIPPING_METHOD_CHANGED], action.methodChanged);
        }
        case RESET_ITEM_DISPLAY:
            return initialState;
        default:
            return state;
    }
}





export function removeItemDisplay(item) {
    return {
        type: UNSAFE_REMOVE_ITEM_DISPLAY,
        item
    };
}

export function resetItemDisplay() {
    return {
        type: RESET_ITEM_DISPLAY
    };
}



export const setDisplayDetails = (item, context, showDetails) => ({
    type: SET_SHOW_DETAILS,
    payload: {
        id: item.get('id'),
        context,
        showDetails
    }
});


export const setEditDetails = (item, editDetails) => ({
    type: SET_EDIT_DETAILS,
    id: item.get('id'),
    editDetails
});


export const setFormValidStatus = (item, formValidStatus) => ({
    type: SET_FORM_INVALID_STATUS,
    id: item.get('id'),
    formValidStatus
});


export const setEditAddress = (item, editAddress) => ({
    type: SET_EDIT_ADDRESS,
    id: item.get('id'),
    editAddress
});


export const setEditQuantity = (item, editQuantity) => ({
    type: SET_EDIT_QUANTITY,
    id: item.get('id'),
    editQuantity
});


export const setFocusShippingSelect = (item, setFocusShippingMethodSelect) => ({
    type: SET_FOCUS_SHIPPING_SELECT,
    id: item.get('id'),
    setFocusShippingMethodSelect
});


export const setShippingAddressChanged = (item, addressChanged) => ({
    type: SET_ADDRESS_CHANGED,
    itemID: item.get('id'),
    addressChanged
});


export const setShippingMethodChanged = (item, methodChanged) => ({
    type: SET_SHIPPING_METHOD_CHANGED,
    itemID: item.get('id'),
    methodChanged
});


export const changeItemDisplayValue = (itemId, fieldName, value) => ({
    type: CHANGE_ITEM_DISPLAY_VALUE,
    itemId,
    fieldName,
    value
});
