const MERIDIEM = {
    AM: 'AM',
    PM: 'PM'
};


const get24HourTime = (hour, meridiem, offset = 12) => {
    if (meridiem === MERIDIEM.AM && hour === offset) {
        return hour - offset;
    }

    if (meridiem === MERIDIEM.PM && hour < offset) {
        return hour + offset;
    }

    return hour;
};

export default get24HourTime;
