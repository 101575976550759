import { defineMessages } from 'react-intl';

export const addressFields = {};

addressFields.COMMON = defineMessages({
    fullNameLabel: {
        id: 'address.addressFields.COMMON.fullNameLabel',
        description: 'Label for a full name field on an address form.',
        defaultMessage: 'Full Name'
    },
    firstNameLabel: {
        id: 'address.addressFields.COMMON.firstNameLabel',
        description: 'Label for a first name field on an address form',
        defaultMessage: 'First Name'
    },
    lastNameLabel: {
        id: 'address.addressFields.COMMON.lastNameLabel',
        description: 'Label for a last name field on an address form',
        defaultMessage: 'Last Name'
    },
    countryLabel: {
        id: 'address.addressFields.COMMON.countryLabel',
        description: 'Label for a country field on an address form',
        defaultMessage: 'Country/Territory'
    },
    phoneLabelRequired: {
        id: 'address.addressFields.COMMON.phoneLabelRequired',
        description: 'Label for a phone number field on an address form',
        defaultMessage: 'Phone Number'
    },
    phoneLabelOptional: {
        id: 'address.addressFields.COMMON.phoneLabelOptional',
        description: 'Label for an optional phone number field on an address form',
        defaultMessage: 'Phone Number (optional)'
    },
    fullNameRequired: {
        id: 'address.addressFields.COMMON.fullNameRequired',
        description: 'Error message displayed when the full name was not provided on an address form',
        defaultMessage: `Please enter the recipient's full name`
    },
    firstNameRequired: {
        id: 'address.addressFields.COMMON.firstNameRequired',
        description: 'Error message displayed when the first name was not provided on an address form',
        defaultMessage: `Please enter the recipient's first name`
    },
    lastNameRequired: {
        id: 'address.addressFields.COMMON.lastNameRequired',
        description: 'Error message displayed when the last name was not provided on an address form',
        defaultMessage: `Please enter the recipient's last name`
    },
    countryRequired: {
        id: 'address.addressFields.COMMON.countryRequired',
        description: 'Error message displayed when the country was not provided on an address form',
        defaultMessage: 'Please select a Country/Territory'
    },
    street1Required: {
        id: 'address.addressFields.COMMON.street1Required',
        description: 'Error message displayed when the street address was not provided on an address form',
        defaultMessage: 'Please enter an address'
    },
    phoneRequired: {
        id: 'address.addressFields.COMMON.phoneRequired',
        description: 'Error message displayed when a phone number was not provided on an address form',
        defaultMessage: 'Please enter a valid phone number'
    },
    phoneInvalid: {
        id: 'address.addressFields.COMMON.phoneInvalid',
        description: 'Error message displayed when an invalid phone number was provided on an address form',
        defaultMessage: 'Please enter a valid phone number containing between 10 and 15 digits'
    },
    invalidCharacterUsed: {
        id: 'address.addressFields.COMMON.invalidCharacterUsed',
        description: 'Error message displayed when the address has scripting characters used inside',
        defaultMessage: 'Invalid characters used'
    }
});

addressFields.BILLING = defineMessages({
    firstNameRequired: {
        id: 'address.addressFields.BILLING.firstNameRequired',
        description: 'Error message displayed when the first name was not provided on an address form',
        defaultMessage: 'Please enter your first name as it appears on your credit card'
    },
    lastNameRequired: {
        id: 'address.addressFields.BILLING.lastNameRequired',
        description: 'Error message displayed when the last name was not provided on an address form',
        defaultMessage: 'Please enter your last name as it appears on your credit card'
    }
});

addressFields.SHIPPING = defineMessages({
    phoneHelper: {
        id: 'address.addressFields.SHIPPING.recipientPhoneHelper',
        description: 'Descriptive text for physical delivery phone number input',
        defaultMessage: 'In case we need package delivery confirmation.'
    },
    fullNameRequired: {
        id: 'address.addressFields.SHIPPING.fullNameRequired',
        description: 'Error message displayed when the full name was not provided on an address form',
        defaultMessage: `Please enter the recipient's full name`
    }
});

addressFields.US = defineMessages({
    street1Label: {
        id: 'address.addressFields.US.street1Label',
        description: 'Label for an address 1 field on a US address form',
        defaultMessage: 'Street Address'
    },
    street2Label: {
        id: 'address.addressFields.US.street2Label',
        description: 'Label for an address 2 field on a US address form',
        defaultMessage: 'Apt, Suite, Etc (optional)'
    },
    cityLabel: {
        id: 'address.addressFields.US.cityLabel',
        description: 'Label for a city field on a US address form',
        defaultMessage: 'City'
    },
    stateLabel: {
        id: 'address.addressFields.US.stateLabel',
        description: 'Label for a state field on a US address form',
        defaultMessage: 'State'
    },
    postalCodeLabel: {
        id: 'address.addressFields.US.postalCodeLabel',
        description: 'Label for a postal field on a US address form',
        defaultMessage: 'Postal Code'
    },
    cityRequired: {
        id: 'address.addressFields.US.cityRequired',
        description: 'Error message displayed when the city was not provided on a US address form',
        defaultMessage: 'Please enter a city'
    },
    stateRequired: {
        id: 'address.addressFields.US.stateRequired',
        description: 'Error message displayed when the state was not provided on a US address form',
        defaultMessage: 'Please select a state'
    },
    postCodeRequired: {
        id: 'address.addressFields.US.postcodeRequired',
        description: 'Error message displayed when the postal or zip code was not provided on a US address form',
        defaultMessage: 'Please enter a postal code'
    },
    postCodeInvalid: {
        id: 'address.addressFields.US.postCodeInvalid',
        description: 'Error message displayed when the postal or zip code was not provided on a US address form',
        defaultMessage: 'Please enter a valid postal code'
    }
});

addressFields.CA = defineMessages({
    street1Label: {
        id: 'address.addressFields.CA.street1Label',
        description: 'Label for an address 1 field on a CA address form',
        defaultMessage: 'Street Address'
    },
    street2Label: {
        id: 'address.addressFields.CA.street2Label',
        description: 'Label for an address 2 field on a CA address form',
        defaultMessage: 'Apt, Suite, Etc (optional)'
    },
    cityLabel: {
        id: 'address.addressFields.CA.cityLabel',
        description: 'Label for a city field on a CA address form',
        defaultMessage: 'City'
    },
    stateLabel: {
        id: 'address.addressFields.CA.stateLabel',
        description: 'Label for a province field on a CA address form',
        defaultMessage: 'Province'
    },
    postalCodeLabel: {
        id: 'address.addressFields.CA.postalCodeLabel',
        description: 'Label for a postal field on a CA address form',
        defaultMessage: 'Postal Code'
    },
    cityRequired: {
        id: 'address.addressFields.CA.cityRequired',
        description: 'Error message displayed when the city was not provided on a CA address form',
        defaultMessage: 'Please enter a city'
    },
    stateRequired: {
        id: 'address.addressFields.CA.stateRequired',
        description: 'Error message displayed when the province was not provided on a CA address form',
        defaultMessage: 'Please select a province'
    },
    postCodeRequired: {
        id: 'address.addressFields.CA.postcodeRequired',
        description: 'Error message displayed when the postal or postal code was not provided on a CA address form',
        defaultMessage: 'Please enter a postal code'
    },
    postCodeInvalid: {
        id: 'address.addressFields.CA.postCodeInvalid',
        description: 'Error message displayed when the postal or postal code was not provided on a CA address form',
        defaultMessage: 'Please enter a valid postal code'
    }
});

addressFields.CN = defineMessages({
    street1Label: {
        id: 'address.addressFields.CN.street1Label',
        description: 'Label for an address 1 field on a CN address form',
        defaultMessage: 'Street Address'
    },
    street2Label: {
        id: 'address.addressFields.CN.street2Label',
        description: 'Label for an address 2 field on a CN address form',
        defaultMessage: 'Apt, Suite, Etc (optional)'
    },
    cityLabel: {
        id: 'address.addressFields.CN.cityLabel',
        description: 'Label for a city field on a CN address form',
        defaultMessage: 'City'
    },
    stateLabel: {
        id: 'address.addressFields.CN.stateLabel',
        description: 'Label for a state field on a CN address form',
        defaultMessage: 'Province'
    },
    postalCodeLabel: {
        id: 'address.addressFields.CN.postalCodeLabel',
        description: 'Label for a postal field on a CN address form',
        defaultMessage: 'Postal Code'
    },
    cityRequired: {
        id: 'address.addressFields.CN.cityRequired',
        description: 'Error message displayed when the city was not provided on a CN address form',
        defaultMessage: 'Please enter a city'
    },
    stateRequired: {
        id: 'address.addressFields.CN.stateRequired',
        description: 'Error message displayed when the state was not provided on a CN address form',
        defaultMessage: 'Please enter a province'
    },
    postCodeRequired: {
        id: 'address.addressFields.CN.postcodeRequired',
        description: 'Error message displayed when the postal or zip code was not provided on a CN address form',
        defaultMessage: 'Please enter a postal code'
    }
});

addressFields.GB = defineMessages({
    street1Label: {
        id: 'address.addressFields.GB.street1Label',
        description: 'Label for an address 1 field on a GB address form',
        defaultMessage: 'Street Address'
    },
    street2Label: {
        id: 'address.addressFields.GB.street2Label',
        description: 'Label for an address 2 field on a GB address form',
        defaultMessage: 'Apt, Suite, Etc (optional)'
    },
    cityLabel: {
        id: 'address.addressFields.GB.cityLabel',
        description: 'Label for a city field on a GB address form',
        defaultMessage: 'City'
    },
    stateLabel: {
        id: 'address.addressFields.GB.stateLabel',
        description: 'Label for a county field on a GB address form',
        defaultMessage: 'County'
    },
    postalCodeLabel: {
        id: 'address.addressFields.GB.postalCodeLabel',
        description: 'Label for a postcode field on a GB address form',
        defaultMessage: 'Postcode'
    },
    cityRequired: {
        id: 'address.addressFields.GB.cityRequired',
        description: 'Error message displayed when the city was not provided on a GB address form',
        defaultMessage: 'Please enter a city'
    },
    stateRequired: {
        id: 'address.addressFields.GB.stateRequired',
        description: 'Error message displayed when the county was not provided on a GB address form',
        defaultMessage: 'Please select a county'
    },
    postCodeRequired: {
        id: 'address.addressFields.GB.postcodeRequired',
        description: 'Error message displayed when the postal or postcode was not provided on a GB address form',
        defaultMessage: 'Please enter a postcode'
    },
    postCodeInvalid: {
        id: 'address.addressFields.GB.postCodeInvalid',
        description: 'Error message displayed when the postal or postcode was not provided on a GB address form',
        defaultMessage: 'Please enter a valid postcode'
    }
});

addressFields.IE = defineMessages({
    street1Label: {
        id: 'address.addressFields.IE.street1Label',
        description: 'Label for an address 1 field on an IE address form',
        defaultMessage: 'Street Address'
    },
    street2Label: {
        id: 'address.addressFields.IE.street2Label',
        description: 'Label for an address 2 field on an IE address form',
        defaultMessage: 'Apt, Suite, Etc (optional)'
    },
    cityLabel: {
        id: 'address.addressFields.IE.cityLabel',
        description: 'Label for a city field on an IE address form',
        defaultMessage: 'City'
    },
    stateLabel: {
        id: 'address.addressFields.IE.stateLabel',
        description: 'Label for a county field on an IE address form',
        defaultMessage: 'County (optional)'
    },
    postalCodeLabel: {
        id: 'address.addressFields.IE.postalCodeLabel',
        description: 'Label for a postcode field on an IE address form',
        defaultMessage: 'Postal Code (optional)'
    },
    cityRequired: {
        id: 'address.addressFields.IE.cityRequired',
        description: 'Error message displayed when the city was not provided on an IE address form',
        defaultMessage: 'Please enter a city'
    }
});

addressFields.HK = defineMessages({
    street1Label: {
        id: 'address.addressFields.HK.street1Label',
        description: 'Label for an address 1 field on a HK address form',
        defaultMessage: 'Street Address'
    },
    street2Label: {
        id: 'address.addressFields.HK.street2Label',
        description: 'Label for an address 2 field on a HK address form',
        defaultMessage: 'Apt, Suite, Etc (optional)'
    },
    cityLabel: {
        id: 'address.addressFields.HK.cityLabel',
        description: 'Label for a district field on a HK address form',
        defaultMessage: 'District/Town'
    },
    cityRequired: {
        id: 'address.addressFields.HK.cityRequired',
        description: 'Error message displayed when the district/town was not provided on a HK address form',
        defaultMessage: 'Please enter a district/town'
    }
});

addressFields.JP = defineMessages({
    street1Label: {
        id: 'address.addressFields.JP.street1Label',
        description: 'Label for an address 1 field on a JP address form',
        defaultMessage: 'Street Address'
    },
    street2Label: {
        id: 'address.addressFields.JP.street2Label',
        description: 'Label for an address 2 field on a JP address form',
        defaultMessage: 'Street Address 2 (optional)'
    },
    stateLabel: {
        id: 'address.addressFields.JP.stateLabel',
        description: 'Label for a prefecture field on a JP address form',
        defaultMessage: 'Prefecture'
    },
    postalCodeLabel: {
        id: 'address.addressFields.JP.postalCodeLabel',
        description: 'Label for a postal code field on a JP address form',
        defaultMessage: 'Postal Code'
    },
    stateRequired: {
        id: 'address.addressFields.JP.stateRequired',
        description: 'Error message displayed when the prefecture was not provided on a JP address form',
        defaultMessage: 'Please select a prefecture'
    }
});

addressFields.AU = defineMessages({
    cityLabel: {
        id: 'address.addressFields.AU.cityLabel',
        description: 'Label for a city field on an AU address form',
        defaultMessage: 'City'
    },
    stateLabel: {
        id: 'address.addressFields.AU.stateLabel',
        description: 'Label for a State/Territory field on an AU address form',
        defaultMessage: 'State/Territory'
    },
    postalCodeLabel: {
        id: 'address.addressFields.AU.postalCodeLabel',
        description: 'Label for a postcode field on an AU address form',
        defaultMessage: 'Postcode'
    },
    stateRequired: {
        id: 'address.addressFields.AU.stateRequired',
        description: 'Error message displayed when the State/Territory was not provided on an AU address form',
        defaultMessage: 'Please select a state/territory'
    },
    street1Label: {
        id: 'address.addressFields.AU.street1Label',
        description: 'Label for an address 1 field on an AU address form',
        defaultMessage: 'Street Address'
    },
    street2Label: {
        id: 'address.addressFields.AU.street2Label',
        description: 'Label for an address 2 field on a generic address form',
        defaultMessage: 'Street Address 2 (optional)'
    },
    postCodeRequired: {
        id: 'address.addressFields.AU.postcodeRequired',
        description: 'Error message displayed when the postcode was not provided on the form',
        defaultMessage: 'Please enter a postcode'
    },
    postCodeInvalid: {
        id: 'address.addressFields.AU.postCodeInvalid',
        description: 'Error message displayed when the postcode is invalid',
        defaultMessage: 'Please enter a valid postcode'
    }
});

addressFields.BR = defineMessages({
    stateLabel: {
        id: 'address.addressFields.BR.stateLabel',
        description: 'Label for a State field on an BR address form',
        defaultMessage: 'State'
    },
    postalCodeLabel: {
        id: 'address.addressFields.BR.postalCodeLabel',
        description: 'Label for a postal code field on an BR address form',
        defaultMessage: 'Postal Code'
    },
    stateRequired: {
        id: 'address.addressFields.BR.stateRequired',
        description: 'Error message displayed when the State was not provided on an BR address form',
        defaultMessage: 'Please select a state'
    },
    street2Label: {
        id: 'address.addressFields.BR.street2Label',
        description: 'Label for an address 2 field on a generic address form',
        defaultMessage: 'Street Address 2 (optional)'
    }
});

addressFields.MX = defineMessages({
    stateLabel: {
        id: 'address.addressFields.MX.stateLabel',
        description: 'Label for a State field on an MX address form',
        defaultMessage: 'State'
    },
    postalCodeLabel: {
        id: 'address.addressFields.MX.postalCodeLabel',
        description: 'Label for a postal code field on a MX address form',
        defaultMessage: 'Postal Code'
    },
    stateRequired: {
        id: 'address.addressFields.MX.stateRequired',
        description: 'Error message displayed when the State was not provided on an MX address form',
        defaultMessage: 'Please select a state'
    },
    street2Label: {
        id: 'address.addressFields.MX.street2Label',
        description: 'Label for an address 2 field on a generic address form',
        defaultMessage: 'Street Address 2 (optional)'
    }
});

addressFields.NL = defineMessages({
    stateLabel: {
        id: 'address.addressFields.NL.stateLabel',
        description: 'Label for a Province field on an NL address form',
        defaultMessage: 'Province'
    },
    postalCodeLabel: {
        id: 'address.addressFields.NL.postalCodeLabel',
        description: 'Label for a postcode field on an NL address form',
        defaultMessage: 'Postcode'
    },
    postCodeRequired: {
        id: 'address.addressFields.NL.postcodeRequired',
        description: 'Error message displayed when the postcode was not provided on the form',
        defaultMessage: 'Please enter a postcode'
    },
    postCodeInvalid: {
        id: 'address.addressFields.NL.postCodeInvalid',
        description: 'Error message displayed when the postcode is invalid',
        defaultMessage: 'Please enter a valid postcode'
    },
    stateRequired: {
        id: 'address.addressFields.NL.stateRequired',
        description: 'Error message displayed when the Province was not provided on an NL address form',
        defaultMessage: 'Please select a province'
    },
    street2Label: {
        id: 'address.addressFields.NL.street2Label',
        description: 'Label for an address 2 field on a generic address form',
        defaultMessage: 'Street Address 2 (optional)'
    }
});

addressFields.DEFAULT = defineMessages({
    street1Label: {
        id: 'address.addressFields.DEFAULT.street1Label',
        description: 'Label for an address 1 field on a generic address form',
        defaultMessage: 'Street Address'
    },
    street2Label: {
        id: 'address.addressFields.DEFAULT.street2Label',
        description: 'Label for an address 2 field on a generic address form',
        defaultMessage: 'Apt, Suite, Etc (optional)'
    },
    cityLabel: {
        id: 'address.addressFields.DEFAULT.cityLabel',
        description: 'Label for a city field on a generic address form',
        defaultMessage: 'City'
    },
    stateLabel: {
        id: 'address.addressFields.DEFAULT.stateLabel',
        description: 'Label for a state field on a generic address form',
        defaultMessage: 'State (optional)'
    },
    postalCodeLabel: {
        id: 'address.addressFields.DEFAULT.postalCodeLabel',
        description: 'Label for a postal field on a generic address form',
        defaultMessage: 'Postal Code (optional)'
    },
    postalCodeLabelRequired: {
        id: 'address.addressFields.DEFAULT.postalCodeLabelRequired',
        description: 'Label for a required postal field on a generic address form',
        defaultMessage: 'Postal Code'
    },
    postCodeRequired: {
        id: 'address.addressFields.DEFAULT.postcodeRequired',
        description: 'Error message displayed when the postal or zip code was not provided on the form',
        defaultMessage: 'Please enter a postal code'
    },
    postCodeInvalid: {
        id: 'address.addressFields.DEFAULT.postCodeInvalid',
        description: 'Error message displayed when the postal code is invalid',
        defaultMessage: 'Please enter a valid postal code'
    },
    cityRequired: {
        id: 'address.addressFields.DEFAULT.cityRequired',
        description: 'Error message displayed when the city was not provided on a generic address form',
        defaultMessage: 'Please enter a city'
    }
});

export default addressFields;
