import { List as IList, Map as IMap } from 'immutable';

export const SET_MODAL = '@@storefront/SET_MODAL';

export const initialDisplayState = IList();


export default function modalDisplayReducer(state = initialDisplayState, action) {
    switch (action.type) {
        case SET_MODAL: {
            const { id, isOpen } = action;
            const modalIndex = state.findIndex(modal => modal.get('id') === id);
            if (modalIndex === -1) {
                return state.push(IMap({ id, isOpen }));
            }
            const updated = state.get(modalIndex).merge({
                isOpen
            });
            return state.set(modalIndex, updated);
        }
        default:
            return state;
    }
}




export function setModalDisplay(id, isOpen) {
    return {
        type: SET_MODAL,
        id,
        isOpen
    };
}

export const mapDispatchModalToProps = {
    setModalDisplay
};
