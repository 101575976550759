import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { injectIntl, intlShape } from 'react-intl';
import { RadioGroup } from 'cstar-react-primitives/lib/redux-form/microFields/RadioGroup';

import { giftCardFieldNames } from '../item/newItemForm';

import recipientMessages from './recipientMessages';

const { IS_SELFBUY_FIELD } = giftCardFieldNames;

export const SelfBuyFields = ({
    cashbotName,
    intl,
    isPDPFlow = false
}) => {
    const getDeliveryTypeIconForNotSelfBy = (isNotSelfBy) => {
        let TypeIcon = null;
        switch (isNotSelfBy) {
            case true:
                TypeIcon = (
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M20.4678 7H18.2878C18.3978 6.69 18.4678 6.35 18.4678 6C18.4678 4.34 17.1278 3 15.4678 3C14.4178 3 13.5078 3.54 12.9678 4.35L12.4678 5.02L11.9678 4.34C11.4278 3.54 10.5178 3 9.46777 3C7.80777 3 6.46777 4.34 6.46777 6C6.46777 6.35 6.53777 6.69 6.64777 7H4.46777C3.35777 7 2.47777 7.89 2.47777 9L2.46777 20C2.46777 21.11 3.35777 22 4.46777 22H20.4678C21.5778 22 22.4678 21.11 22.4678 20V9C22.4678 7.89 21.5778 7 20.4678 7ZM15.4678 5C16.0178 5 16.4678 5.45 16.4678 6C16.4678 6.55 16.0178 7 15.4678 7C14.9178 7 14.4678 6.55 14.4678 6C14.4678 5.45 14.9178 5 15.4678 5ZM9.46777 5C10.0178 5 10.4678 5.45 10.4678 6C10.4678 6.55 10.0178 7 9.46777 7C8.91777 7 8.46777 6.55 8.46777 6C8.46777 5.45 8.91777 5 9.46777 5ZM20.4678 20H4.46777V18H20.4678V20ZM20.4678 15H4.46777V9H9.54777L7.46777 11.83L9.08777 13L12.4678 8.4L15.8478 13L17.4678 11.83L15.3878 9H20.4678V15Z" fill="#505050" />
                    </svg>
                );
                break;
            case false:
                TypeIcon = (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M20 7H17.82C17.93 6.69 18 6.35 18 6C18 4.34 16.66 3 15 3C13.95 3 13.04 3.54 12.5 4.35L12 5.02L11.5 4.34C10.96 3.54 10.05 3 9 3C7.34 3 6 4.34 6 6C6 6.35 6.07 6.69 6.18 7H4C2.89 7 2.01 7.89 2.01 9L2 20C2 21.11 2.89 22 4 22H20C21.11 22 22 21.11 22 20V9C22 7.89 21.11 7 20 7ZM15 5C15.55 5 16 5.45 16 6C16 6.55 15.55 7 15 7C14.45 7 14 6.55 14 6C14 5.45 14.45 5 15 5ZM9 5C9.55 5 10 5.45 10 6C10 6.55 9.55 7 9 7C8.45 7 8 6.55 8 6C8 5.45 8.45 5 9 5ZM20 20H4V18H20V20ZM20 15H4V9H9.08L7 11.83L8.62 13L12 8.4L15.38 13L17 11.83L14.92 9H20V15Z" fill="white" />
                    </svg>
                );
                break;
            default:
                return '';
        }

        return (
            <React.Fragment>
                {TypeIcon}
            </React.Fragment>
        );
    };

    const getDeliveryTypeIconForSelfBy = (isSelfBy) => {
        let TypeIcon = null;
        switch (isSelfBy) {
            case true:
                TypeIcon = (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M2 18.5V5.5C2 5.1 2.15 4.75 2.45 4.45C2.75 4.15 3.1 4 3.5 4L20.5 4C20.9 4 21.25 4.15 21.55 4.45C21.85 4.75 22 5.1 22 5.5V18.5C22 18.9 21.85 19.25 21.55 19.55C21.25 19.85 20.9 20 20.5 20L3.5 20C3.1 20 2.75 19.85 2.45 19.55C2.15 19.25 2 18.9 2 18.5ZM20.5 15.775L3.5 15.775V18.5L20.5 18.5V15.775ZM20.5 12.55V5.5L3.5 5.5V12.55L20.5 12.55ZM20.5 5.5V18.5V5.5Z" fill="white" />
                    </svg>
                );
                break;
            case false:
                TypeIcon = (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M2 18.5V5.5C2 5.1 2.15 4.75 2.45 4.45C2.75 4.15 3.1 4 3.5 4L20.5 4C20.9 4 21.25 4.15 21.55 4.45C21.85 4.75 22 5.1 22 5.5V18.5C22 18.9 21.85 19.25 21.55 19.55C21.25 19.85 20.9 20 20.5 20L3.5 20C3.1 20 2.75 19.85 2.45 19.55C2.15 19.25 2 18.9 2 18.5ZM20.5 15.775L3.5 15.775V18.5L20.5 18.5V15.775ZM20.5 12.55V5.5L3.5 5.5V12.55L20.5 12.55ZM20.5 5.5V18.5V5.5Z" fill="#505050" />
                    </svg>
                );

                break;
            default:
                return '';
        }

        return (
            <React.Fragment>
                {TypeIcon}
            </React.Fragment>
        );
    };

    const [selectedDeliveryType, setSelectedDeliveryType] = useState(false);

    const setTabSelection = (value) => {
        setSelectedDeliveryType(value);
    };

    const radioOptions = [
        {
            value: false,
            children: (
                <React.Fragment>
                    <span>{isPDPFlow && getDeliveryTypeIconForNotSelfBy(selectedDeliveryType)}</span>
                    <span>{intl.formatMessage(recipientMessages.notSelfbuy)}</span>
                </React.Fragment>
            )
        },
        {
            value: true,
            children: (
                <React.Fragment>
                    <span>{isPDPFlow && getDeliveryTypeIconForSelfBy(selectedDeliveryType)}</span>
                    <span>{intl.formatMessage(recipientMessages.isSelfbuy)}</span>
                </React.Fragment>)
        }
    ];
    const radioGroupProps = {
        component: RadioGroup,
        name: IS_SELFBUY_FIELD,
        cashbotName,
        legend: intl.formatMessage(recipientMessages.selfBuyLegend),
        styleType: 'picker',
        containerClass: 'tab-container',
        radioButtonClass: 'tab',
        role: 'tablist',
        radioButtonRole: 'tab',
        radioOptions,
        onChange: setTabSelection
    };

    return (
        <Field {...radioGroupProps} />
    );
};

SelfBuyFields.defaultProps = {
    isPDPFlow: false
};

SelfBuyFields.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    isPDPFlow: PropTypes.bool
};

export default injectIntl(SelfBuyFields);
