import { preferencesAreLoaded } from '../brand/brandSelectors';
import { catalogsLoadedAndSoldOut } from '../catalogs/catalogSelectors';
import { getSoldOutPath } from '../routing/flow';

import amountMessages from './amountMessages';
import { amountStepPostConditions, amountStepPreConditions, amountStepSkippable } from './amountStepSelectors';


const amountStepConfig = {
    component: () => import('./AmountStep'),
    flow: 'buy',
    isReady: state => (
        preferencesAreLoaded(state)
    ),
    canLoad: state => ({
        isAllowedOrPath: (!catalogsLoadedAndSoldOut(state) || getSoldOutPath())
    }),
    preConditions: amountStepPreConditions,
    postConditions: amountStepPostConditions,
    getSubmitMessage: ({ isPlastic }) => (
        isPlastic
            ? amountMessages.submitButtonDigitalText
            : amountMessages.submitButtonPhysicalText),
    canSkip: state => (
        amountStepSkippable(state)
    )
};

export default amountStepConfig;
