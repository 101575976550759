import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map as IMap } from 'immutable';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { getShippingRecipientPhone } from '../brand/brandSelectors';
import { getAllCartExcludedBillingStates } from '../utils/addressUtils';

import {
    NameFields,
    PhoneField
} from './Fields';
import {
    addressFieldPropTypes,
    defaultFieldProps,
    ADDRESS_CONTEXT
} from './addressFieldsModule';
import {
    getBillingCountryList,
    getShippingCountryList,
    hasIntlBilling,
    hasIntlShipping
} from './addressFieldsSelectors';
import AddressPicker from './AddressPicker';

export const AddressFields = ({
    addressContext,
    billingCountries,
    collectRecipientPhone,
    cashbotName,
    countryCode,
    street1,
    street2,
    city,
    state,
    excludedBillingStates,
    postalCode,
    phoneNumber,
    doFieldValidation,
    doFocus,
    form,
    intl,
    item,
    shippingCountries,
    showIntlBilling,
    showIntlShipping,
    showUntouchedErrors,
    useAddressAutocomplete,
    setAddressChanged,
    usePreviousAddress,
    isPDP = false
}) => {
    const commonFieldProps = {
        addressContext,
        cashbotName,
        doFieldValidation,
        intl,
        showUntouchedErrors
    };
    const addressPickerProps = {
        ...commonFieldProps,
        billingCountries,
        shippingCountries,
        showIntlBilling,
        showIntlShipping,
        countryCode,
        item,
        street1,
        street2,
        city,
        state,
        excludedBillingStates,
        postalCode,
        phoneNumber,
        form,
        useAddressAutocomplete,
        setAddressChanged,
        usePreviousAddress,
        isPDP
    };
    const isShipping = addressContext === ADDRESS_CONTEXT.SHIPPING;
    return (
        <div>
            {
                isShipping && (
                    <Fragment>
                        <NameFields {...commonFieldProps} doFocus={doFocus} />
                        { collectRecipientPhone && <PhoneField {...commonFieldProps} />}
                    </Fragment>
                )
            }
            <AddressPicker {...addressPickerProps} />
        </div>
    );
};

AddressFields.defaultProps = {
    ...defaultFieldProps,
    doFocus: false,
    item: IMap(),
    showIntlBilling: false,
    showIntlShipping: false,
    street1: '',
    street2: '',
    city: '',
    state: '',
    postalCode: '',
    phoneNumber: '',
    usePreviousAddress: true,
    setAddressChanged: () => {},
    isPDP: false
};

AddressFields.propTypes = {
    ...addressFieldPropTypes,
    billingCountries: ImmutablePropTypes.list.isRequired,
    doFocus: PropTypes.bool,
    item: ImmutablePropTypes.map,
    shippingCountries: ImmutablePropTypes.list.isRequired,
    showIntlBilling: PropTypes.bool,
    showIntlShipping: PropTypes.bool,
    setAddressChanged: PropTypes.func
};

export const mapStateToProps = state => ({
    billingCountries: getBillingCountryList(state),
    excludedBillingStates: getAllCartExcludedBillingStates(state),
    shippingCountries: getShippingCountryList(state),
    showIntlBilling: hasIntlBilling,
    showIntlShipping: hasIntlShipping,
    collectRecipientPhone: getShippingRecipientPhone(state)
});

export default connect(mapStateToProps)(injectIntl(AddressFields));
