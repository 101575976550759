import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map as IMap } from 'immutable';
import { injectIntl, intlShape } from 'react-intl';
import classname from 'classnames';
import Markdown from 'markdown-to-jsx';
import { LinkExternalWithHref } from 'cstar-react-primitives/lib/redux-form/LinkExternalWithHref';

import { getFormattedAmountWithCurrency } from '../utils/numberUtils';
import { getIntlCurrencyCode } from '../intl/intlSelectors';
import {
    getDigitalAccessoryByOrdinal,
    getPhysicalAccessoryByOrdinal
} from '../brand/brandSelectors';
import { ITEM_ACCESSORY, newItemHasAccessory } from '../item/newItemForm';
import { changeValue } from '../item/newItemFormModule';
import { getAccessoriesPromoData } from '../promo/promoSelectors';

import AccessoryImg from './AccessoryImg';
import AccessoriesToggle from './AccessoriesToggle';
import AccessoryCost from './AccessoryCost';
import { optionalAccessoriesMessageIds } from './accessoriesMessages';
import {
    getAccessoryCostByCurrency,
    getHasAccessoryPromo,
    formatAccessoryMessage,
    getAccessoryImage
} from './accessoriesSelectors';


export const PureAccessories = ({
    accessoryCost,
    accessoryAdded,
    currentAccessory,
    changeAccessoryInItemValue,
    hasAccessoryPromo,
    currencyCode,
    intl
}) => {
    const accessoryTitle = formatAccessoryMessage(
        intl,
        currentAccessory,
        optionalAccessoriesMessageIds.accessoryTitle
    );
    const accessoryDescription = formatAccessoryMessage(
        intl,
        currentAccessory,
        optionalAccessoriesMessageIds.accessoryDescription,
        { price: getFormattedAmountWithCurrency(intl, accessoryCost) }
    );
    const accessoryImage = getAccessoryImage(currentAccessory);
    const cashbotName = 'accessories';
    const showPrice = hasAccessoryPromo || accessoryCost > 0;
    const setAccessoryValue = () => {
        if (!accessoryAdded) {
            changeAccessoryInItemValue(ITEM_ACCESSORY, currentAccessory);
        } else {
            changeAccessoryInItemValue(ITEM_ACCESSORY, null);
        }
    };
    const accessoryImageWithoutPriceClassName = classname('accessory-image-container', {
        'without-price': !showPrice
    });

    return (
        <div className="container--accessories">
            <AccessoriesToggle
                handleClick={setAccessoryValue}
                cashbotName={cashbotName}
                value={accessoryAdded}
                intl={intl}
            />
            <div className="accessory-label">{accessoryTitle}</div>
            <div className="accessory-description">
                <Markdown
                    options={{
                        overrides: {
                            
                            a: {
                                component: LinkExternalWithHref
                            }
                        }
                    }}
                >
                    {accessoryDescription}
                </Markdown>
            </div>
            <div className="accessory-image-price-block">
                <div className={accessoryImageWithoutPriceClassName}>
                    <AccessoryImg accessory={accessoryImage} altText={accessoryTitle} />
                </div>
                { showPrice && <AccessoryCost cost={accessoryCost} intl={intl} currencyCode={currencyCode} /> }
            </div>
        </div>
    );
};

PureAccessories.propTypes = {
    
    intl: intlShape.isRequired,
    
    accessoryAdded: PropTypes.bool.isRequired,
    accessoryCost: PropTypes.string.isRequired,
    currentAccessory: PropTypes.instanceOf(IMap).isRequired,
    hasAccessoryPromo: PropTypes.bool.isRequired,
    currencyCode: PropTypes.string.isRequired,
    
    changeAccessoryInItemValue: PropTypes.func.isRequired
};

export const mapStateToProps = (state, ownProps) => {
    const { isPlastic } = ownProps;
    const currentAccessory = isPlastic
        ? getPhysicalAccessoryByOrdinal(state)(1)
        : getDigitalAccessoryByOrdinal(state)(1);
    const accessoryAdded = newItemHasAccessory(state);
    const currentCurrency = getIntlCurrencyCode(state);
    const accessoryPromoData = getAccessoriesPromoData(state);
    const accessoryCost = getAccessoryCostByCurrency(currentAccessory, accessoryPromoData, currentCurrency);
    const hasAccessoryPromo = getHasAccessoryPromo(state)(currentAccessory);

    return {
        accessoryCost,
        accessoryAdded,
        currentAccessory,
        hasAccessoryPromo,
        currencyCode: currentCurrency
    };
};

export const mapDispatchToProps = {
    changeAccessoryInItemValue: (fieldName, value) => changeValue(fieldName, value)
};

const Accessories = connect(mapStateToProps, mapDispatchToProps)(injectIntl(PureAccessories));

Accessories.propTypes = {
    isPlastic: PropTypes.bool.isRequired
};

export default Accessories;
