import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';


const ModalHeader = (props) => {
    const { children, headerText } = props;
    return (
        <div className="modal-header">
            { children
                || <h2><FormattedMessage {...headerText} /></h2> }
        </div>
    );
};

ModalHeader.defaultProps = {
    children: null,
    headerText: {}
};

ModalHeader.propTypes = {
    children: PropTypes.node,
    headerText: PropTypes.object
};

export default ModalHeader;
