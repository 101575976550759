const APPLE_PAY_VERSION = 3;


export const browserSupportsApplePay = () => {
    try {
        return typeof window.ApplePaySession !== 'undefined'
            && window.ApplePaySession.canMakePayments()
            && window.ApplePaySession.supportsVersion(APPLE_PAY_VERSION);
    } catch (error) {
        return false;
    }
};


const getLineItem = (label, amount) => (
    {
        label,
        amount,
        type: 'final'
    }
);


export const getApplePayPaymentRequest = ({
    countryCode,
    currencyCode,
    items,
    totalLabel,
    totalCost
}) => ({
    countryCode,
    currencyCode,
    requiredBillingContactFields: ['postalAddress'],
    
    
    requiredShippingContactFields: ['email', 'phone'],
    merchantCapabilities: ['supports3DS'],
    supportedNetworks: ['amex', 'discover', 'masterCard', 'visa'],
    lineItems: items.map(item => getLineItem(item.label, item.cost)),
    total: getLineItem(totalLabel, totalCost)
});


export const startApplePaySession = (paymentRequest, validationCallback, authorizationCallback) => {
    const session = new window.ApplePaySession(APPLE_PAY_VERSION, paymentRequest);
    session.onvalidatemerchant = validationCallback;
    session.onpaymentauthorized = authorizationCallback;
    session.begin();
};
