import numbersOnlyNormalizer from './numbersOnlyNormalizer';


const postalCodeNormalizerUS = (value) => {
    if (!value) return value;
    let normalize = numbersOnlyNormalizer(value);
    if (value.length > 5) {
        normalize = `${normalize.slice(0, 5)}-${normalize.slice(5, 9)}`;
    }
    return normalize;
};

export default postalCodeNormalizerUS;
