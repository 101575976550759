import React from 'react';
import PropTypes from 'prop-types';
import { Map as IMap } from 'immutable';
import { sanitizeUrl } from '../utils/urlUtils';

export function PickerCard({
    i, cellClass, customCardMessages, submitAction, selectProgram, value, children
}) {
    const isCustomTile = value < 0; 
    const tileName = isCustomTile
        ? children.props.children.props.text
        : children.props.text;

    return (
        <div
            id={`program-${i}`}
            className={cellClass}
        >
            <button
                id={tileName}
                type='button'
                aria-label={tileName}
                onClick={() => {
                    selectProgram(value);
                    if (isCustomTile) {
                        const cleanUrl = sanitizeUrl(customCardMessages.get('customCardActionUrl'));
                        window.open(cleanUrl, 'noopener');
                    } else {
                        submitAction(IMap({ programCode: value }));
                    }
                }}
            />
            {children}
        </div>
    );
}

PickerCard.propTypes = {
    i: PropTypes.number.isRequired,
    cellClass: PropTypes.string.isRequired,
    customCardMessages: PropTypes.instanceOf(IMap).isRequired,
    selectProgram: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    children: PropTypes.any.isRequired
};
