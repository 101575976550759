import { fromJS, Map as IMap } from 'immutable';

import { logError } from '../utils/errorUtils';


export const earlyGuessData = fromJS({
    dinersclub: /^(30[0-5]|3[689])/,
    americanexpress: /^3[47]/,
    nordstrom: /^44704[123]/,
    visa: /^4/,
    maestro: /^(50|6[37])/,
    mastercard: /^5[12345]|^2[234567]/,
    ulta: /^57/,
    discovercard: /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/,
    jcpenney: /^\d{10}1$/,
    belk: /^604583/,
    'belk-mastercard': /^524300/
});


export const creditCardTypeDeducer = (creditCardNumber, deductionData) => {
    let acceptedTypes = [];
    try {
        acceptedTypes = deductionData.filter(data => RegExp(data.get('regex')).test(creditCardNumber));
    } catch (error) {
        logError(error);
    }
    if (acceptedTypes.size === 0) {
        return null;
    }
    if (acceptedTypes.size === 1) {
        return acceptedTypes.keySeq().first();
    }
    const plccTypes = acceptedTypes.filter(type => type.get('isPlcc'));
    if (plccTypes.size === 1) {
        return plccTypes.keySeq().first();
    }
    const cobrandTypes = acceptedTypes.filter(type => type.get('isCobrand'));
    if (cobrandTypes.size === 1) {
        return cobrandTypes.keySeq().first();
    }
    return null;
};


export const guessCreditCardType = (creditCardNumber, acceptedCreditCardData) => {
    const acceptedWithRegexes = acceptedCreditCardData.map((value, key) => IMap({
        regex: earlyGuessData.get(key),
        isCobrand: value.get('isCobrand'),
        isPlcc: value.get('isPlcc')
    }));
    return creditCardTypeDeducer(creditCardNumber, acceptedWithRegexes);
};
