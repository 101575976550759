import { fromJS, Map as IMap } from 'immutable';
import { createSelector } from 'reselect';
import { getBrandSupportedUYO } from '../brand/brandSelectors';
import { getProgramSupportedUYO } from '../program/programSelectors';


export const getUYOData = state => state.get('uyo', IMap());

export const getHasAgreedToTerms = createSelector(
    getUYOData,
    uyo => uyo.get('uyoTermsAgreement')
);

export const getUYOFileUrl = createSelector(
    getUYOData,
    uyo => uyo.getIn(['uyoFile', 'cdnUrl'])
);


export const getUYOFileResWarning = createSelector(
    getUYOData,
    uyo => uyo.get('uyoFileResWarning')
);

export const getSupportedUYO = createSelector(
    [getBrandSupportedUYO, getProgramSupportedUYO],
    (brandUYOSupport, programUYOSupport) => {
        const brandOrProgramSupportsUYO = type => (brandUYOSupport.get(type) ? programUYOSupport.get(type) : false);
        return fromJS({
            supportsUYOPlasticPhoto: brandOrProgramSupportsUYO('supportsUYOPlasticPhoto'),
            supportsUYOEgcPhoto: brandOrProgramSupportsUYO('supportsUYOEgcPhoto'),
            supportsUYOEgcVideo: brandUYOSupport.get('supportsUYOEgcVideo')
        });
    }
);
