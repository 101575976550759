import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import emptyFunc from '../utils/emptyFunc';


export class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
        const { location } = this.props;

        if (location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        const { doScroll, children, onScrollComplete } = this.props;

        if (doScroll) {
            window.scrollTo(0, 0);
            onScrollComplete();
        }
        return children;
    }
}

ScrollToTop.defaultProps = {
    location: null,
    doScroll: false,
    onScrollComplete: emptyFunc
};

ScrollToTop.propTypes = {
    
    location: PropTypes.object,
    
    children: PropTypes.node.isRequired,
    doScroll: PropTypes.bool,
    onScrollComplete: PropTypes.func
};

export default withRouter(ScrollToTop);
