import { createSelector } from 'reselect';

import getNowTimeStamp from '../utils/time/getNowTimestamp';

import { CALENDAR_LOCALE, IS_LOADING } from './futureSendModule';
import { getMinuteValueList, meridiemOptions, meridiemOptionsJP } from './time/timeData';


const MERIDIEM = {};
meridiemOptions.forEach(({ label, value }) => {
    MERIDIEM[label] = value;
});

meridiemOptionsJP.forEach(({ label, value }) => {
    MERIDIEM[label] = value;
});


export const getFutureSendUI = state => state.get('future');


export const getCalendarLocale = createSelector(
    getFutureSendUI,
    future => future.get(CALENDAR_LOCALE)
);


export const getFutureSendIsLoading = createSelector(
    getFutureSendUI,
    future => future.get(IS_LOADING)
);



export const currentDateTime = new Date(getNowTimeStamp());

export const currentHour = currentDateTime.getHours();

export const currentMinute = currentDateTime.getMinutes();

export const minuteList = getMinuteValueList();

export const maxMinuteOption = Math.max.apply(null, minuteList);

export const getHourInterval = (
    hour = currentHour,
    min = currentMinute,
    maxMinute = maxMinuteOption
) => {
    if (min > maxMinute) {
        return (hour === 24) ? 1 : hour + 1;
    }
    return hour;
};

export const getMinuteInterval = (min = currentMinute) => (
    minuteList.find(value => (min <= value)) || 0
);

export const getMeridiemInterval = (hour = getHourInterval()) => (
    (hour >= 12) ? MERIDIEM.PM : MERIDIEM.AM
);



export const getNextTimeInterval = (
    hour = getHourInterval(),
    minute = getMinuteInterval(),
    meridiem = getMeridiemInterval(),
) => {
    const offSet = 12;
    return {
        hour: hour > offSet ? hour - offSet : hour,
        minute,
        meridiem
    };
};
