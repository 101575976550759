import { List as IList } from 'immutable';

import { createSelector } from 'reselect';
import { programStepCompleted } from '../../program/programStepSelectors';
import { cardTypeStepCompleted } from '../../cardType/cardTypeStepSelectors';
import { uyoInitStepCompleted } from '../init/uyoInitStepSelectors';
import { getUYOFileUrl } from '../uyoSelectors';



export const uyoSelectStepCompleted = createSelector(
    getUYOFileUrl,
    Boolean
);


export const uyoSelectStepPreConditions = IList([
    programStepCompleted,
    cardTypeStepCompleted,
    uyoInitStepCompleted
]);


export const uyoSelectStepPostConditions = IList([
    uyoSelectStepCompleted
]);
