

export const initializeRiskified = (sessionKey, brandUrl) => {
    function riskifiedBeaconLoad() {
        const storeDomain = brandUrl;
        const sessionId = sessionKey;
        const protocol = (document.location.protocol === 'https:' ? 'https://' : 'http://');
        const url = `${protocol}beacon.riskified.com?shop=${storeDomain}&sid=${sessionId}`;
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = url;
        const x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(script, x);
    }
    if (window.attachEvent) {
        window.attachEvent('onload', riskifiedBeaconLoad);
    } else {
        window.addEventListener('load', riskifiedBeaconLoad, false);
    }
};
