import { createSelector } from 'reselect';
import { getFeatureFlags } from '../app/bootstrap';


const FEATURE_FLAGS = getFeatureFlags();


export const FEATURES = {
    INTL_BILLING: 'intl_billing',
    INTL_SHIPPING: 'intl_shipping',
    INTL_CURRENCIES: 'intl_currencies',
    UYO_PHOTO_DIGITAL: 'uyo_photo_digital',
    UYO_PHOTO_PHYSICAL: 'uyo_photo_physical',
    UYO_VIDEO_DIGITAL: 'uyo_video_digital',
    ADDRESS_AUTOCOMPLETE: 'address_autocomplete',
    PROMO_CODE: 'promo_code',
    DISCOUNT_PROMO: 'discount_promo',
    PROGRAM_PICKER: 'program_picker'
};

export const isFeatureEnabled = (feature) => {
    if (!FEATURE_FLAGS) return false;

    return FEATURE_FLAGS.get(feature, false);
};


export const doWhenFeatureEnabled = (feature, func, elseFunc) => {
    if (isFeatureEnabled(feature)) {
        return (...args) => func.apply({}, args);
    }
    return (...args) => () => elseFunc.apply({}, args);
};


export const createSelectorWhenEnabled = (feature, disabledResult = null) => (
    doWhenFeatureEnabled(feature, createSelector, () => disabledResult)
);
