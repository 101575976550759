import { Big } from 'big.js';

import { getBrandCode } from '../brand/brandSelectors';
import {
    getPromoItems,
    getPaidItems,
    getShippingTotal,
    getPromoItemDenomination, getItemCurrency, getGrandTotalCostMinusFees
} from '../item/itemSelectors';
import { getActivePromo, getDiscountRewardAmount } from '../promo/promoSelectors';
import { getStorefrontSessionKey } from '../session/sessionSelectors';
import {
    getNewItemFormValues,
    giftCardFieldNames as FIELDS,
    newItemIsPlastic,
    newItemIsSelfBuy
} from '../item/newItemForm';
import { formatPrice } from '../utils/numberUtils';
import { CONSUMER_PRODUCT_CODE } from '../payment/paymentConstants';
import { getGrandTotal } from '../payment/paymentSelectors';
import { getStorefrontFlowIsPDP } from '../routing/flowSelectors';



let LPT = '';
let LCT = '';


export const analyticsNamesMap = {
    program: 'Program',
    recipient: 'Recipient',
    type: 'Type',
    design: 'Design',
    'uyo-init': 'UYOTerms',
    'uyo-select': 'UYOSelect',
    'uyo-review': 'UYOReview',
    amount: 'Amount',
    message: 'Message',
    delivery: 'Delivery',
    shipping: 'Shipping',
    cart: 'Cart',
    'pay-method': 'Payment',
    payment: 'Checkout',
    'masterpass-review': 'MasterpassReview',
    confirmation: 'Confirmation',
    processing: 'Processing',
    'product-detail': 'Product-detail'
};


export const getAnalyticsName = path => analyticsNamesMap[path];

export const getLatestDeliveryMethodType = (item) => {
    if (item) {
        const isEmailDelivery = item.get('checkedEmail');
        const isSMSDelivery = item.get('checkedSms');
        const isShareableLinkDelivery = item.get('isSharedViaLink');
        const isPlasticDelivery = item.get('isPlastic');
        if (isEmailDelivery) {
            return isSMSDelivery ? 'EMSMS' : 'EMAIL';
        }
        if (isPlasticDelivery) {
            return 'PLASTIC';
        }
        if (isSMSDelivery) {
            return 'SMS';
        }
        if (isShareableLinkDelivery) {
            return 'LINK';
        }
    }
    return '';
};


export const itemSegmentData = item => ({
    cart_id: 'None', 
    product_id: item.get(FIELDS.FACEPLATE_FIELD, 'None'),
    sku: '',
    name: nameCardType(item.get(FIELDS.IS_PLASTIC_FIELD), false),
    variant: item.get(FIELDS.IS_SELFBUY_FIELD) ? 'SELF' : 'GIFT',
    price: item.get(FIELDS.AMOUNT_FIELD, 'None'),
    currency: item.get(FIELDS.CURRENCY_FIELD, 'None'),
    quantity: item.get(FIELDS.QUANTITY_FIELD, 'None'),
    category: '',
    position: '',
    programCode: item.get(FIELDS.PROGRAM_CODE, ''),
    giftCardBrandCode: item.get('brandCode', ''),
    deliveryMethod: getLatestDeliveryMethodType(item)
});


export const promoItemSegmentData = item => ({
    product_id: item.getIn([FIELDS.FACEPLATE_FIELD, 'code'], 'not available'),
    sku: '',
    name: nameCardType(item.get('certType') === 'PLST', true),
    price: formatPrice(getPromoItemDenomination(item)),
    quantity: item.get(FIELDS.QUANTITY_FIELD, 'not available'),
    category: '',
    variant: 'PROMO',
    programCode: item.get(FIELDS.PROGRAM_CODE, '')
});

export const getNewItemSegmentValues = (state) => {
    const item = getNewItemFormValues(state);
    return item ? itemSegmentData(item) : {};
};

export const getLatestPurchaseType = (state, pathName) => {
    
    if (pathName === 'Type' || pathName === 'Design') {
        if (newItemIsSelfBuy(state)) {
            LPT = 'Self Buy';
        } else {
            LPT = 'Gifted';
        }
    }
    return LPT;
};

export const getLatestCardType = (state, pathName) => {
    if (pathName === 'Design') {
        if (newItemIsPlastic(state)) {
            LCT = 'Plastic';
        } else {
            LCT = 'Digital';
        }
    }
    return LCT;
};

export const getBaseEventProperties = (state, sessionID, pathName) => ({
    buyFlow: (getStorefrontFlowIsPDP(state) || pathName === analyticsNamesMap['product-detail'])
        ? 'Storefront PDP flow' : 'Storefront Fixed',
    productCode: CONSUMER_PRODUCT_CODE,
    brandCode: getBrandCode(state),
    billableProductFeatureCode: 'ECOM',
    cashStarSessionId: sessionID || getStorefrontSessionKey(state),
    latestPurchaseType: getLatestPurchaseType(state, pathName),
    latestCardType: getLatestCardType(state, pathName)
});

export const calculateGoGoLoad = (promoItems) => {
    let gogoTotal = 0;
    promoItems.forEach((item) => {
        gogoTotal += formatPrice(getPromoItemDenomination(item)) * item.get(FIELDS.QUANTITY_FIELD, 0);
    });
    return gogoTotal;
};


export const calculateDiscount = (promoItems, discountPromoReward) => (
    calculateGoGoLoad(promoItems) - discountPromoReward
);


export const calculateRevenue = (total, gogoLoad, shipping) => new Big(total).sub(gogoLoad).sub(shipping);


export function nameCardType(isPlastic, isPromo) {
    let actualName = 'Digital Gift Card'; 
    if (isPlastic && isPromo) {
        actualName = 'Plastic Promo Card';
    } else if (isPlastic && !isPromo) {
        actualName = 'Plastic Gift Card';
    } else if (!isPlastic && isPromo) {
        actualName = 'Digital Promo Card';
    }
    return actualName;
}


export const parseOrderCompleted = (state, orderNumber, currency) => {
    const parsedItems = [].concat(
        getPaidItems(state).map(item => itemSegmentData(item)).toArray(),
        getPromoItems(state).map(item => promoItemSegmentData(item)).toArray()
    );
    const discount = calculateDiscount(getPromoItems(state), getDiscountRewardAmount(state));
    const gogoLoad = calculateGoGoLoad(getPromoItems(state));
    const total = getGrandTotal(state).add(gogoLoad);
    const shipping = getShippingTotal(state);
    const activePromo = getActivePromo(state);
    const promoCode = activePromo ? activePromo.get('promoCode', 'None') : 'None'; 
    const revenue = calculateRevenue(total, gogoLoad, shipping);
    const sessionKey = getStorefrontSessionKey(state);
    return {
        checkout_id: sessionKey,
        order_id: orderNumber,
        total: parseFloat(total.toFixed(2)),
        revenue: parseFloat(revenue.toFixed(2)),
        shipping,
        discount,
        coupon: promoCode,
        currency,
        products: parsedItems
    };
};


export const parseCartViewed = (state) => {
    const parsedItems = [].concat(
        getPaidItems(state).map(item => itemSegmentData(item)).toArray()
    );
    const currency = parsedItems.length >= 1 ? parsedItems[0].currency : 'None';
    const sessionKey = getStorefrontSessionKey(state);
    return {
        cart_id: sessionKey,
        currency,
        products: parsedItems
    };
};



export const parseCheckoutStarted = (state) => {
    const parsedItems = [].concat(
        getPaidItems(state).map(item => itemSegmentData(item)).toArray(),
        getPromoItems(state).map(item => promoItemSegmentData(item)).toArray()
    );
    const discount = calculateDiscount(getPromoItems(state), getDiscountRewardAmount(state));
    const gogoLoad = calculateGoGoLoad(getPromoItems(state));
    const total = getGrandTotalCostMinusFees(state).add(gogoLoad);
    const shipping = getShippingTotal(state);
    const activePromo = getActivePromo(state);
    const promoCode = activePromo ? activePromo.get('promoCode', 'None') : 'None'; 
    const revenue = calculateRevenue(total, gogoLoad, shipping);
    const currency = getPaidItems.size === 1 ? getItemCurrency(getPaidItems.first()) : 'USD';
    const sessionKey = getStorefrontSessionKey(state);
    return {
        checkout_id: sessionKey,
        total: parseFloat(total.toFixed(2)),
        revenue: parseFloat(revenue.toFixed(2)),
        shipping,
        discount,
        coupon: promoCode,
        currency,
        products: parsedItems
    };
};

export const promoToSocialSegmentData = (orderData, buttonClicked, promoCode) => ({
    orderID: orderData.get('orderNumber'),
    
    category: promoCode,
    label: buttonClicked
});
