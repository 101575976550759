import React from 'react';
import PropTypes from 'prop-types';
import { Map as IMap } from 'immutable';

import { ITEM_UNDO } from './notifyModule';
import NotificationDismiss from './NotificationDismiss';

import NotifyItemUndoRemove from './NotifyItemUndoRemove';


export const NotificationItem = (props) => {
    const { notification, type } = props;

    return (
        <div className="notification-item">
            { type === ITEM_UNDO && <NotifyItemUndoRemove notification={notification} /> }
            <NotificationDismiss notification={notification} />
        </div>
    );
};


NotificationItem.propTypes = {
    notification: PropTypes.instanceOf(IMap).isRequired,
    type: PropTypes.oneOf([ITEM_UNDO]).isRequired
};

export default NotificationItem;
