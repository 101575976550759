
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
    reduxForm,
    getFormValues,
    getFormMeta,
    formValueSelector,
    isPristine,
    getFormInitialValues
} from 'redux-form/immutable';
import { fromJS } from 'immutable';

import { currentDateTime } from '../futureSend/futureSendSelectors';
import { addressFieldNames, getAddressHash } from '../address/addressModule';


export const NEW_ITEM_FORM_NAME = 'newItemForm';


export const MAX_QUANTITY = 100;

export const ITEM_ID = 'id';
export const ADDRESS_ID = 'addressID';
export const MESSAGE_FIELD = 'message';
export const CURRENCY_FIELD = 'currencyCode';
export const QUANTITY_FIELD = 'quantity';
export const UYO_IMAGE_URL = 'uyoImageURL';
export const AMOUNT_FIELD = 'amount';
export const FACEPLATE_FIELD = 'faceplate';
export const FACEPLATE_DATA = 'faceplateData';
export const IS_PLASTIC_FIELD = 'isPlastic';
export const IS_SELFBUY_FIELD = 'isSelfBuy';
export const SENDER_NAME_FIELD = 'senderName';
export const RECIPIENT_SMS_FIELD = 'recipientSms';
export const SMS_FIELD_SELECTED = 'checkedSms';
export const IS_SHARE_VIA_LINK = 'isSharedViaLink';
export const RECIPIENT_ERROR_LABEL = 'errorLabel';
export const RECIPIENT_NAME_FIELD = 'recipientName';
export const RECIPIENT_EMAIL_FIELD = 'recipientEmail';
export const EMAIL_FIELD_SELECTED = 'checkedEmail';
export const RECIPIENT_EMAIL_FIELD_CONFIRMATION = 'recipientEmailConfirmation';
export const SHIPPING_METHOD_FIELD = 'shippingMethod';
export const PREV_SHIPPING_METHOD_FIELD = 'prevShippingMethod';
export const SELECTED_GROUP_ORDINAL = 'selectedGroupOrdinal';
export const RECIPIENT_SEND_TIMEZONE_FIELD = 'sendTimezone';
export const RECIPIENT_SEND_DATETIME_FIELD = 'deliveryDate';
export const RECIPIENT_SEND_TIME_FIELD = 'deliveryTime';
export const RECIPIENT_PLASTIC_NOTIFICATION = 'hasPlasticNotification';
export const ITEM_ACCESSORY = 'accessory';
export const PROGRAM_CODE = 'programCode';
export const RECIPIENT_CHOICE_FIELD = 'recipientChoice';
export const CPF_NUMBER_FIELD = 'cpfNumber';
export const { ADDRESS_COUNTRY } = addressFieldNames;


export const NAME_FIELD_MAX = 30;
export const FIRSTNAME_FIELD_MAX = 15;
export const LASTNAME_FIELD_MAX = 25;
export const EMAIL_FIELD_MAX = 254;
export const MESSAGE_MAX_LEN_DIGITAL = 250;
export const CPF_FIELD_MAX = 14;

export const giftCardFieldNames = {
    ITEM_ID,
    ADDRESS_ID,
    AMOUNT_FIELD,
    UYO_IMAGE_URL,
    MESSAGE_FIELD,
    CURRENCY_FIELD,
    QUANTITY_FIELD,
    FACEPLATE_FIELD,
    FACEPLATE_DATA,
    IS_PLASTIC_FIELD,
    IS_SELFBUY_FIELD,
    ITEM_ACCESSORY,
    SENDER_NAME_FIELD,
    RECIPIENT_SMS_FIELD,
    SMS_FIELD_SELECTED,
    IS_SHARE_VIA_LINK,
    RECIPIENT_NAME_FIELD,
    RECIPIENT_EMAIL_FIELD,
    EMAIL_FIELD_SELECTED,
    SHIPPING_METHOD_FIELD,
    SELECTED_GROUP_ORDINAL,
    RECIPIENT_SEND_TIMEZONE_FIELD,
    RECIPIENT_SEND_DATETIME_FIELD,
    RECIPIENT_SEND_TIME_FIELD,
    RECIPIENT_PLASTIC_NOTIFICATION,
    PROGRAM_CODE,
    RECIPIENT_ERROR_LABEL,
    CPF_NUMBER_FIELD
};

export const giftCardFieldAttrs = {
    NAME_FIELD_MAX,
    FIRSTNAME_FIELD_MAX,
    LASTNAME_FIELD_MAX,
    EMAIL_FIELD_MAX,
    MESSAGE_MAX_LEN_DIGITAL,
    CPF_FIELD_MAX
};


export const getInitialNewItemFormValues = getFormInitialValues(NEW_ITEM_FORM_NAME);


export const getNewItemFormValues = getFormValues(NEW_ITEM_FORM_NAME);


export const newItemFormValueSelector = formValueSelector(NEW_ITEM_FORM_NAME);


export const newItemFormMetaSelector = getFormMeta(NEW_ITEM_FORM_NAME);



export const isFieldPristine = fieldName => state => isPristine(NEW_ITEM_FORM_NAME)(state, fieldName);


export const newItemIsPlastic = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(IS_PLASTIC_FIELD, false) : false)
);


export const newItemIsSelfBuy = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(IS_SELFBUY_FIELD, false) : false)
);

export const newItemMessage = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(MESSAGE_FIELD, false) : false)
);


export const newItemHasAccessory = createSelector(
    getNewItemFormValues,
    values => (values ? !!values.get(ITEM_ACCESSORY, false) : false)
);


export const newItemSenderName = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(SENDER_NAME_FIELD, '') : '')
);


export const newItemRecipientName = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(RECIPIENT_NAME_FIELD, '') : '')
);


export const newItemRecipientEmail = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(RECIPIENT_EMAIL_FIELD, '') : '')
);

export const newItemShareViaLink = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(IS_SHARE_VIA_LINK, false) : false)
);

export const newItemEmailCheckbox = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(EMAIL_FIELD_SELECTED, '') : '')
);

export const newItemSmsCheckbox = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(SMS_FIELD_SELECTED, '') : '')
);


export const newItemCurrency = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(CURRENCY_FIELD, '') : '')
);


export const newItemDenomination = createSelector(
    getNewItemFormValues,
    values => (values && values.get(AMOUNT_FIELD, '0') !== null ? values.get(AMOUNT_FIELD, '0').toString() : '0')
);


export const newItemQuantity = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(QUANTITY_FIELD, '0') : '0')
);



export const newItemSendDate = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(RECIPIENT_SEND_DATETIME_FIELD, currentDateTime) : currentDateTime)
);


export const newItemAddressHash = createSelector(
    getNewItemFormValues,
    values => (values ? getAddressHash(values) : '')
);


export const newItemCountry = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(ADDRESS_COUNTRY, '') : '')
);


export const newItemHasRecipientPlasticNotification = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(RECIPIENT_PLASTIC_NOTIFICATION, false) : false)
);


export const newItemFaceplate = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(FACEPLATE_FIELD, '') : '')
);

export const newItemSelectedGroupOrdinal = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(SELECTED_GROUP_ORDINAL) : 1)
);

export const newItemProgramCode = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(PROGRAM_CODE, '') : null)
);

export const newItemFutureSendTimezone = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(RECIPIENT_SEND_TIMEZONE_FIELD, null) : null)
);


export const newItemCPFNumber = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(CPF_NUMBER_FIELD, '') : '')
);


export const initialNewItem = fromJS({
    [QUANTITY_FIELD]: 1,
    [SELECTED_GROUP_ORDINAL]: 1,
    [IS_SELFBUY_FIELD]: false,
    [RECIPIENT_SEND_DATETIME_FIELD]: currentDateTime,
    [RECIPIENT_PLASTIC_NOTIFICATION]: false,
    [PREV_SHIPPING_METHOD_FIELD]: false,
    [ITEM_ACCESSORY]: null,
    [RECIPIENT_SEND_TIME_FIELD]: currentDateTime,
    [EMAIL_FIELD_SELECTED]: true
});


export const mapStateToProps = (state) => {
    const currentInitialValues = getFormInitialValues(NEW_ITEM_FORM_NAME)(state);
    
    
    
    return {
        initialValues: currentInitialValues || initialNewItem
    };
};


export const newItemReduxForm = (Form, args = {}) => {
    const mergedArgs = {
        form: NEW_ITEM_FORM_NAME,
        destroyOnUnmount: false,
        enableReinitialize: false,
        initialValues: initialNewItem,
        ...args
    };
    const NewItemReduxForm = reduxForm(mergedArgs)(Form);

    return connect(mapStateToProps)(NewItemReduxForm);
};
