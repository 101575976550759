
const regexAstralSymbols = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;

const countSymbols = string => (
    string
        
        .replace(regexAstralSymbols, '_')
        
        .length
);

const isLength = (string, options) => {
    const { min = 0, max = 0 } = options;
    const len = countSymbols(string);
    return len >= min && len <= max;
};

export default isLength;
