import { List as IList } from 'immutable';

import { createSelector } from 'reselect';
import { programStepCompleted } from '../program/programStepSelectors';
import { cardTypeStepCompleted } from '../cardType/cardTypeStepSelectors';
import { getFlowProgress, getPreferredAmount, getPreferredCurrencyCode } from '../routing/flowSelectors';
import { AMOUNT_FIELD, CURRENCY_FIELD, newItemIsPlastic } from '../item/newItemForm';
import { getFixedEgcCatalog, getFixedPlasticCatalog, getMaxTransactionAmount } from '../catalogs/catalogSelectors';
import { getSubTotal } from '../item/itemSelectors';


export const amountStepCompleted = createSelector(
    getFlowProgress,
    flowState => flowState.has(AMOUNT_FIELD) && flowState.has(CURRENCY_FIELD)
);


export const amountStepPreConditions = IList([
    programStepCompleted,
    cardTypeStepCompleted
]);


export const amountStepPostConditions = IList([
    amountStepCompleted
]);

export const amountStepSkippable = createSelector(
    getPreferredAmount,
    getPreferredCurrencyCode,
    getMaxTransactionAmount,
    getSubTotal,
    getFixedEgcCatalog,
    getFixedPlasticCatalog,
    newItemIsPlastic,
    (preferredAmount, preferredCurrency, maxTransaction, subtotal, fixedEgcDenoms, fixedPlasticDenoms, isPlastic) => {
        if (!!preferredAmount && !!preferredCurrency) {
            return maxTransaction.gte(subtotal.plus(preferredAmount));
        }
        if (isPlastic && fixedPlasticDenoms.size === 1) {
            return maxTransaction.gte(subtotal.plus(0));
        }
        if (fixedEgcDenoms.size === 1) {
            return maxTransaction.gte(subtotal.plus(0));
        }
        return false;
    }
);
