import React, { useEffect } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { Container } from 'cstar-react-primitives/lib/redux-form/layout/Container';
import { useSelector } from 'react-redux';

import ProgramPicker from './ProgramPicker';
import programMessages from './programMessages';
import { programStepSkippable } from './programStepSelectors';
import { getProgramsLoaded } from './programSelectors';
import { getShowProgramSelection } from './programListSelectors';
import LoadingIndicator from '../primitive/LoadingIndicator';
import { useFlow } from '../routing/FlowProvider';
import TranslatedPageTitle from '../header/TranslatedPageTitle';

export const ProgramStep = ({ intl, stepName }) => {
    
    const { goToNextStep } = useFlow();

    
    const programsLoaded = useSelector(state => getProgramsLoaded(state));
    const canShowPrograms = useSelector(state => getShowProgramSelection(state));
    const canSkip = useSelector(state => programStepSkippable(state));

    const programPickerProps = {
        name: stepName,
        cashbotName: 'program-picker',
        submitAction: (values) => {
            goToNextStep(stepName, {}, values);
        },
        intl,
        shouldFocus: false
    };

    useEffect(() => {
        if (programsLoaded && (!canShowPrograms || canSkip)) {
            goToNextStep(stepName, { shouldPushHistory: false });
        }
    }, [programsLoaded]);

    if (!programsLoaded) {
        return <LoadingIndicator />;
    }

    return (
        <Container heading={intl.formatMessage(programMessages.title)} HeadingElement="h1">
            <TranslatedPageTitle pageTitle={programMessages.title} />
            <ProgramPicker {...programPickerProps} />
        </Container>
    );
};

ProgramStep.propTypes = {
    intl: intlShape.isRequired,
    stepName: PropTypes.string.isRequired
};

export default injectIntl(ProgramStep);
