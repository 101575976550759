import React from 'react';

import { addressFieldNames as fields } from '../address/addressModule';
import {
    defaultFieldProps,
    addressPropTypes
} from './addressFieldsModule';

import { fieldValidatorsMX } from './addressValidation';
import addressFields from './addressFieldMessages';
import mexicoStateMap from './data/MXStateMap';
import {
    Street1Field,
    Street2Field,
    CityField,
    StateSelectField,
    PostalCodeField
} from './Fields';
import postalCodeNormalizerMX from '../validate/postalCodeNormalizerMX';


export const makeStateList = (intl) => {
    const states = Object.keys(mexicoStateMap);
    const placeholder = {
        value: '',
        label: intl.formatMessage(addressFields.MX.stateLabel)
    };
    return [placeholder].concat(states.map(code => ({
        value: code,
        label: intl.formatMessage(mexicoStateMap[code])
    })));
};


export const AddressFieldsMX = ({
    addressContext,
    cashbotName,
    doFieldValidation,
    intl,
    showUntouchedErrors,
    updateShippingAddress,
    validators,
    countryCode
}) => {
    const commonFieldProps = {
        addressContext,
        cashbotName,
        doFieldValidation,
        intl,
        showUntouchedErrors
    };
    const street1Props = {
        ...commonFieldProps,
        label: addressFields.DEFAULT.street1Label,
        updateShippingAddress,
        useAddressAutocomplete: false, 
        validate: validators.get(fields.ADDRESS_STREET1),
        countryCode
    };
    const street2Props = {
        ...commonFieldProps,
        validate: validators.get(fields.ADDRESS_STREET2),
        label: addressFields.MX.street2Label
    };
    const cityProps = {
        ...commonFieldProps,
        label: addressFields.DEFAULT.cityLabel,
        validate: validators.get(fields.ADDRESS_CITY)
    };
    const stateProps = {
        ...commonFieldProps,
        label: addressFields.MX.stateLabel,
        options: makeStateList(intl),
        validate: validators.get(fields.ADDRESS_STATE)
    };
    const postalCodeProps = {
        ...commonFieldProps,
        fieldType: 'tel',
        normalize: postalCodeNormalizerMX,
        label: addressFields.MX.postalCodeLabel,
        validate: validators.get(fields.ADDRESS_POSTCODE)
    };

    return (
        <React.Fragment>
            <Street1Field {...street1Props} />
            <Street2Field {...street2Props} />
            <CityField {...cityProps} />
            <StateSelectField {...stateProps} />
            <PostalCodeField {...postalCodeProps} />
        </React.Fragment>
    );
};

AddressFieldsMX.defaultProps = {
    ...defaultFieldProps,
    validators: fieldValidatorsMX
};

AddressFieldsMX.propTypes = {
    ...addressPropTypes
};

export default AddressFieldsMX;
