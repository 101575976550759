import { List as IList } from 'immutable';

import { programStepCompleted } from '../../program/programStepSelectors';
import { cardTypeStepCompleted } from '../../cardType/cardTypeStepSelectors';
import { uyoSelectStepCompleted } from '../select/uyoSelectStepSelectors';


export const uyoReviewStepPreConditions = IList([
    programStepCompleted,
    cardTypeStepCompleted,
    uyoSelectStepCompleted
]);
