import { createSelector } from 'reselect';
import { Map as IMap } from 'immutable';

import { messages } from '../intl/intlSelectors';
import { hasDigitalCategories, hasPhysicalCategories } from '../faceplate/faceplateSelectors';

export const PAGINATION_CHUNK_SIZE = 6;
export const INSPIRATIONAL_MESSAGES_ID = 'inspiration';
export const FACEPLATE_CATEGORY_MESSAGES_ID = 'faceplate.category';

const messageOrdinal = messageId => messageId.split('.')[5];

export const hasGroupCategories = (state, isPlastic) => (
    (isPlastic ? hasPhysicalCategories(state) : hasDigitalCategories(state))
);

export const numFaceplatesDisplayedForGroup = group => (
    Math.min(PAGINATION_CHUNK_SIZE, group.get('faceplates', IMap()).size)
);

export const faceplateCategorizedMessages = createSelector(
    messages,
    msgs => msgs.filter((_, id) => id.startsWith(FACEPLATE_CATEGORY_MESSAGES_ID))
);

export const inspirationalMessages = createSelector(
    faceplateCategorizedMessages,
    messageIds => messageIds.filter((_, id) => id.includes(INSPIRATIONAL_MESSAGES_ID))
);

export const orderInspirationalMessages = (state, isPlastic, categoryName) => {
    const inspirations = inspirationalMessages(state);
    const delivery = isPlastic ? 'plastic' : 'egc';
    if (hasGroupCategories(state, isPlastic)) {
        return inspirations.filter((message, messageId) => {
            const idParts = messageId.split('.');
            return (idParts[3] === delivery && idParts[4] === encodeURI(categoryName));
        }).sortBy((copy, id) => messageOrdinal(id));
    }
    return inspirations.filter((message, messageId) => messageId.includes(`.${delivery}.default`))
        .sortBy((copy, id) => messageOrdinal(id));
};

export const getInspirationsForGroup = (state, isPlastic, categoryName) => (
    orderInspirationalMessages(state, isPlastic, categoryName).toIndexedSeq()
);
