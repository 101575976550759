import { EMAIL_FIELD_MAX } from '../item/newItemForm';

export const splitEmail = (email) => {
    const atIndex = email.indexOf('@');
    const user = email.slice(0, atIndex);
    const domain = email.slice(atIndex + 1);
    return [user, domain];
};

export const isValidEmail = (allegedEmail) => {
    if (typeof allegedEmail !== 'string') {
        return false;
    }
    const trimmedEmail = allegedEmail.trim();
    
    if (trimmedEmail.length > EMAIL_FIELD_MAX) {
        return false;
    }

    
    
    
    const userRegex = new RegExp(/^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*$/i);
    const domainRegex = new RegExp(/^((?:[A-Z0-9](?:[A-Z0-9-]{0,247}[A-Z0-9])?\.)+)(?:[A-Z]{2,6}|([A-Z0-9]+[A-Z0-9]))$/i);
    const literalRegex = new RegExp(/\[([A-f0-9:.]+)]$/i);

    if (trimmedEmail.indexOf('@') < 0) {
        
        return false;
    }
    const [userEmailPart, domainEmailPart] = splitEmail(trimmedEmail);
    if (userRegex.exec(userEmailPart) === null) {
        return false;
    }

    if (!domainRegex.exec(domainEmailPart)) {
        if (!literalRegex.exec(domainEmailPart)) {
            return false;
        }
    }
    
    return true;
};


export const isValidEmailRule = errorMessage => (value) => {
    if (!isValidEmail(value)) {
        return errorMessage;
    }
    return undefined;
};
