import React from 'react';
import PropTypes from 'prop-types';
import { FieldValidationMessage } from 'cstar-react-primitives/lib/redux-form/microFields/FieldValidationMessage';
import {
    getAllFieldElementIds,
    getValidationProps
} from 'cstar-react-primitives/lib/redux-form/microFields/fieldUtils';

const AmountLabel = ({
    input,
    meta,
    amount,
    prefix,
    suffix
}) => {
    const fieldIds = getAllFieldElementIds(input.name);
    const validationProps = getValidationProps(meta, input);
    return (
        <div className='denom-label'>
            <span className="currency__prefix">{prefix}</span>
            <span className='denom-label__text'>{amount}</span>
            <span className="currency__suffix">{suffix}</span>
            <div className="field-group--center">
                <FieldValidationMessage
                    id={fieldIds.validation.id}
                    fieldName={input.name}
                    validationProps={validationProps}
                />
            </div>
        </div>
    );
};
AmountLabel.defaultProps = {
    suffix: null,
    prefix: null
};

AmountLabel.propTypes = {
    amount: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    suffix: PropTypes.string,
    prefix: PropTypes.string
};

export default AmountLabel;
