import { fromJS } from 'immutable';

import { CREDIT_CARD_PAYMENT_TYPE, creditCardPaymentDataHandler } from '../creditcard/creditCardModule';

import { PAYPAL_PAYMENT_TYPE, paypalPaymentDataHandler } from '../paypal/paypalSerializers';
import { APPLE_PAY_PAYMENT_TYPE, applePayPaymentDataHandler } from '../applePay/applePaySerializers';
import { MASTERPASS_PAYMENT_TYPE, masterpassPaymentDataHandler } from '../masterpass/masterpassSerializers';
import { PAYMENT_PLUS_PAYMENT_TYPE, paymentPlusPaymentDataHandler } from '../paymentJS/paymentPlusSerializers';
import { promoToSocialSegmentData } from '../segment/segmentSelectors';


export const PAYMENT_STATUS = fromJS({
    CONFIG_PROCESSING: 'config_processing',
    CONFIG_SUCCESS: 'config_success',
    CONFIG_FAILED: 'config_failed',
    INIT: 'init',
    ORDERING: 'ordering',
    FAILED: 'failed',
    SUCCESS: 'success'
});


export const initialState = fromJS({
    status: null,
    numAttempts: 0,
    response: null
});

export const PAYMENT_METHODS = {
    applePay: APPLE_PAY_PAYMENT_TYPE,
    paypal: PAYPAL_PAYMENT_TYPE,
    creditCard: CREDIT_CARD_PAYMENT_TYPE,
    masterpass: MASTERPASS_PAYMENT_TYPE,
    paymentPlus: PAYMENT_PLUS_PAYMENT_TYPE
};


export const paymentMethodDataHandlers = fromJS({
    [APPLE_PAY_PAYMENT_TYPE]: applePayPaymentDataHandler,
    [CREDIT_CARD_PAYMENT_TYPE]: creditCardPaymentDataHandler,
    [PAYPAL_PAYMENT_TYPE]: paypalPaymentDataHandler,
    [MASTERPASS_PAYMENT_TYPE]: masterpassPaymentDataHandler,
    [PAYMENT_PLUS_PAYMENT_TYPE]: paymentPlusPaymentDataHandler
});


export const PROCESS_PAYMENT = '@@storefront/PROCESS_PAYMENT';
export const START_SEND_ORDER = '@@storefront/START_SEND_ORDER';
export const PAYMENT_COMPLETE = '@@storefront/PAYMENT_COMPLETE';
export const PAYMENT_SUCCESS = '@@storefront/PAYMENT_SUCCESS';
export const PAYMENT_FAILED = '@@storefront/PAYMENT_FAILED';
export const PAYMENT_RESET = '@@storefront/PAYMENT_RESET';
export const SET_CONSUMER_FEES = '@@storefront/SET_CONSUMER_FEES';
export const SET_GRAND_TOTAL = '@@storefront/SET_GRAND_TOTAL';
export const SET_UYOP_FEES_TOTAL = '@@storefront/SET_UYOP_FEES_TOTAL';
export const SET_ACTIVATION_FEES_TOTAL = '@@storefront/SET_ACTIVATION_FEES_TOTAL';
export const SET_ACTIVATION_FEES_COUNT = '@@storefront/SET_ACTIVATION_FEES_COUNT';
export const SET_CHECKOUT_ID = '@@storefront/SET_CHECKOUT_ID';
export const SET_PAYMENT_URL_PARAMS = '@@storefront/SET_PAYMENT_URL_PARAMS';
export const PROCESS_INITIATE_CONFIG = '@@storefront/PROCESS_INITIATE_CONFIG';
export const INITIATE_CONFIG_SUCCESS = '@@storefront/INITIATE_CONFIG_SUCCESS';
export const INITIATE_CONFIG_FAILED = '@@storefront/INITIATE_CONFIG_FAILED';
export const PROMO_TO_SOCIAL_BUTTON = '@@storefront/SET_PROMO_TO_SOCIAL_BUTTON_CLICKED';
export const RESET_PAYMENT_STATUS = '@@storefront/RESET_PAYMENT_STATUS';
export const TRACK_PAYMENT_PERFORMANCE = '@@storefront/TRACK_PAYMENT_PERFORMANCE';
export const SET_SHARE_VIA_LINK_PAYLOAD = '@@storefront/SET_SHARE_VIA_LINK_PAYLOAD';



export default function reducer(state = initialState, action) {
    switch (action.type) {
        case PROCESS_PAYMENT:
            return state.merge({
                status: PAYMENT_STATUS.get('INIT'),
                numAttempts: state.get('numAttempts') + 1
            });
        case START_SEND_ORDER:
            return state.merge({ status: PAYMENT_STATUS.get('ORDERING') });
        case PAYMENT_SUCCESS:
            return state.merge({
                status: PAYMENT_STATUS.get('SUCCESS'),
                response: action.payload
            });
        case PAYMENT_FAILED:
            return state.merge({
                status: PAYMENT_STATUS.get('FAILED'),
                response: action.payload
            });
        case PAYMENT_COMPLETE:
            return initialState;
        case PAYMENT_RESET:
            return initialState;
        case SET_CONSUMER_FEES:
            
            
            return state.set('fees', action.fees);
        case SET_GRAND_TOTAL:
            
            return state.set('grandTotal', action.total);
        case SET_UYOP_FEES_TOTAL:
            
            return state.set('uyopFeesTotal', action.total);
        case SET_ACTIVATION_FEES_TOTAL:
            
            return state.set('activationFeesTotal', action.total);
        case SET_ACTIVATION_FEES_COUNT:
            
            return state.set('activationFeesCount', action.count);
        case SET_CHECKOUT_ID:
            return state.set('checkoutId', action.checkoutId);
        case SET_PAYMENT_URL_PARAMS:
            return state.set('paymentUrlParams', action.paymentUrlParams);
        case PROCESS_INITIATE_CONFIG:
            return state.set('status', PAYMENT_STATUS.get('CONFIG_PROCESSING'));
        case INITIATE_CONFIG_FAILED:
            return state.merge({
                status: PAYMENT_STATUS.get('CONFIG_FAILED'),
                initiateConfigError: action.initiateConfigError
            });
        case INITIATE_CONFIG_SUCCESS:
            return state.merge({
                status: PAYMENT_STATUS.get('CONFIG_SUCCESS'),
                checkoutId: action.payload.checkoutId,
                merchantId: action.payload.merchantId,
                cartAmount: action.payload.grandTotal,
                countryCode: action.payload.countryCode
            });
        case RESET_PAYMENT_STATUS:
            return state.set('status', initialState.get('status'));
        case SET_SHARE_VIA_LINK_PAYLOAD:
            return state.set('sharableLinkItems', action.payload);
        default:
    }
    return state;
}



export function processPayment(paymentMethod, paymentData) {
    return {
        type: PROCESS_PAYMENT,
        paymentMethod,
        paymentData
    };
}

export function processInitiateConfig(data) {
    return {
        type: PROCESS_INITIATE_CONFIG,
        data
    };
}

export function initiateConfigRequestSuccess(payload) {
    return {
        type: INITIATE_CONFIG_SUCCESS,
        payload
    };
}

export function initiateConfigRequestFailed(error) {
    return {
        type: INITIATE_CONFIG_FAILED,
        initiateConfigError: error && error.response && error.response.data ? error.response.data
            : null
    };
}

export function startSendOrder() {
    return {
        type: START_SEND_ORDER
    };
}

export function paymentFailed(err) {
    return {
        type: PAYMENT_FAILED,
        payload: err
    };
}

export function paymentSuccess(resp) {
    return {
        type: PAYMENT_SUCCESS,
        payload: resp
    };
}

export function paymentComplete() {
    return {
        type: PAYMENT_COMPLETE
    };
}

export function paymentReset() {
    return {
        type: PAYMENT_RESET
    };
}


export function setConsumerFees(fees) {
    return {
        type: SET_CONSUMER_FEES,
        fees
    };
}


export function setGrandTotal(total) {
    return {
        type: SET_GRAND_TOTAL,
        total
    };
}


export function setUyopFeesTotal(total) {
    return {
        type: SET_UYOP_FEES_TOTAL,
        total
    };
}


export function setActivationFeesTotal(total) {
    return {
        type: SET_ACTIVATION_FEES_TOTAL,
        total
    };
}


export function setActivationFeesCount(count) {
    return {
        type: SET_ACTIVATION_FEES_COUNT,
        count
    };
}



export function setCheckoutId(checkoutId) {
    return {
        type: SET_CHECKOUT_ID,
        checkoutId
    };
}



export function setConfirmationUrlParams(paymentUrlParams) {
    return {
        type: SET_PAYMENT_URL_PARAMS,
        paymentUrlParams
    };
}


export function promoToSocialSegmentEvent(orderData, buttonClicked, promoCode) {
    return {
        type: PROMO_TO_SOCIAL_BUTTON,
        meta: {
            segment: {
                event: 'track',
                eventAction: 'Promo To Social Button Clicked',
                eventData: promoToSocialSegmentData(orderData, buttonClicked, promoCode)
            }
        }
    };
}


export function resetPaymentStatus() {
    return {
        type: RESET_PAYMENT_STATUS
    };
}

export function setShareViaLinkPayload(payload) {
    return {
        type: SET_SHARE_VIA_LINK_PAYLOAD,
        payload
    };
}


export function trackPaymentPerformance(triggeredBy, data = {}) {
    return {
        type: TRACK_PAYMENT_PERFORMANCE,
        meta: {
            segment: {
                event: 'track',
                eventAction: `track performance - ${triggeredBy}`,
                eventData: data
            }
        }
    };
}
