import { List as IList, Map as IMap } from 'immutable';
import { createSelector } from 'reselect';
import { Big } from 'big.js';
import {
    getBrandCatalogs,
    brandCatalogIsLoaded,
    getBrandElectronicCurrencyList,
    getBrandPlasticCurrencyList,
    getBrandSupportedCardTypes
} from '../brand/brandSelectors';
import { getIntlCurrencyCode } from '../intl/intlSelectors';
import { newItemIsPlastic, newItemCurrency } from '../item/newItemForm';
import {
    getActiveProgramCatalogs,
    getActiveProgramElectronicCurrencyList,
    getActiveProgramPlasticCurrencyList,
    getActiveProgramSupportedCardTypes,
    programIsActive
} from '../program/programSelectors';
import { getInCatalog } from './catalogUtils';


export const getCatalogForCurrency = (catalogs, currency) => (
    catalogs.get(currency) || IMap()
);


export const getCatalogs = createSelector(
    getBrandCatalogs,
    programIsActive,
    getActiveProgramCatalogs,
    (brandCatalogs, useProgram, programCatalogs) => (useProgram ? programCatalogs : brandCatalogs)
);


export const getPlasticCurrencyList = createSelector(
    getBrandPlasticCurrencyList,
    programIsActive,
    getActiveProgramPlasticCurrencyList,
    (brandPlasticCurrencies, useProgram, programPlasticCurrencies) => (
        useProgram ? programPlasticCurrencies : brandPlasticCurrencies
    )
);


export const getElectronicCurrencyList = createSelector(
    getBrandElectronicCurrencyList,
    programIsActive,
    getActiveProgramElectronicCurrencyList,
    (brandElectronicCurrencies, useProgram, programElectronicCurrencies) => (
        useProgram ? programElectronicCurrencies : brandElectronicCurrencies
    )
);


export const getSupportedCardTypes = createSelector(
    getBrandSupportedCardTypes,
    programIsActive,
    getActiveProgramSupportedCardTypes,
    (brandSupportedCardTypes, useProgram, programSupportedCardTypes) => (
        useProgram ? programSupportedCardTypes : brandSupportedCardTypes
    )
);


export const getEgcRecommendedDenoms = createSelector(
    getCatalogs,
    getIntlCurrencyCode,
    (catalogs, currency) => {
        const egcRecommendedDenoms = getInCatalog(
            getCatalogForCurrency(catalogs, currency),
            ['egcRecommendedDenoms'],
            IList()
        );
        return egcRecommendedDenoms.map(value => new Big(parseFloat(value).toFixed(2)));
    }
);


export const getPlasticRecommendedDenoms = createSelector(
    getCatalogs,
    getIntlCurrencyCode,
    (catalogs, currency) => {
        const plasticRecommendedDenoms = getInCatalog(
            getCatalogForCurrency(catalogs, currency),
            ['plasticRecommendedDenoms'],
            IList()
        );
        return plasticRecommendedDenoms.map(value => new Big(parseFloat(value).toFixed(2)));
    }
);


export const getPlasticDefaultAmount = createSelector(
    getCatalogs,
    getIntlCurrencyCode,
    (catalogs, currency) => {
        const plasticDefault = getInCatalog(
            getCatalogForCurrency(catalogs, currency),
            ['plasticDefaultAmount'],
            null
        );
        return plasticDefault ? new Big(parseFloat(plasticDefault).toFixed(2)) : null;
    }
);


export const getDefaultItemDigitalCurrency = createSelector(
    getIntlCurrencyCode,
    getElectronicCurrencyList,
    (intlCurrencyCode, electronicCurrencyList) => (electronicCurrencyList.includes(intlCurrencyCode)
        ? intlCurrencyCode
        : electronicCurrencyList.first())
);


export const getDefaultItemPlasticCurrency = createSelector(
    getIntlCurrencyCode,
    getPlasticCurrencyList,
    (intlCurrencyCode, plasticCurrencyList) => (plasticCurrencyList.includes(intlCurrencyCode)
        ? intlCurrencyCode
        : plasticCurrencyList.first())
);


export const getDefaultItemCurrency = createSelector(
    newItemIsPlastic,
    getDefaultItemDigitalCurrency,
    getDefaultItemPlasticCurrency,
    (isPlastic, electronicCurrency, plasticCurrency) => (isPlastic ? plasticCurrency : electronicCurrency)
);


export const getMaxTransactionAmount = createSelector(
    getCatalogs,
    getIntlCurrencyCode,
    (catalogs, currency) => new Big(getInCatalog(
        getCatalogForCurrency(catalogs, currency),
        ['maxTransactionAmount'],
        '500.00'
    ))
);


export const getFixedEgcCatalog = createSelector(
    getCatalogs,
    getIntlCurrencyCode,
    (catalogs, currency) => {
        const fixedEgcCatalog = getInCatalog(
            getCatalogForCurrency(catalogs, currency),
            ['egcFixedCatalog'],
            IList()
        );
        return fixedEgcCatalog.map(value => new Big(parseFloat(value).toFixed(2)));
    }
);


export const isFixedEgcCatalogPresent = createSelector(
    getFixedEgcCatalog,
    catalog => catalog.size > 0
);


export const getFixedPlasticCatalog = createSelector(
    getCatalogs,
    getIntlCurrencyCode,
    (catalogs, currency) => {
        const fixedPlasticCatalog = getInCatalog(
            getCatalogForCurrency(catalogs, currency),
            ['plasticFixedCatalog'],
            IList()
        );
        return fixedPlasticCatalog.map(value => new Big(parseFloat(value).toFixed(2)));
    }
);


export const isFixedPlasticCatalogPresent = createSelector(
    getFixedPlasticCatalog,
    catalog => catalog.size > 0
);


export const getRangedEgcCatalog = createSelector(
    getCatalogs,
    getIntlCurrencyCode,
    (catalogs, currency) => {
        const egcRangeCatalog = getInCatalog(
            getCatalogForCurrency(catalogs, currency),
            ['egcRangedCatalog'],
            IMap()
        );
        return egcRangeCatalog.map(value => new Big(parseFloat(value).toFixed(2)));
    }
);


export const getRangedPlasticCatalog = createSelector(
    getCatalogs,
    getIntlCurrencyCode,
    (catalogs, currency) => {
        const rangedPlasticCatalog = getInCatalog(
            getCatalogForCurrency(catalogs, currency),
            ['plasticRangedCatalog'],
            IMap()
        );
        return rangedPlasticCatalog.map(value => new Big(parseFloat(value).toFixed(2)));
    }
);


export const getMaxFromCatalog = (isFixed, fixedCatalog, rangedCatalog) => (
    new Big(isFixed ? fixedCatalog.last() : rangedCatalog.get('end', '500.00'))
);


export const getMinFromCatalog = (isFixed, fixedCatalog, rangedCatalog) => (
    new Big(isFixed ? fixedCatalog.first() : rangedCatalog.get('start', '1.00'))
);


export const getMaxEgcValue = createSelector(
    isFixedEgcCatalogPresent,
    getFixedEgcCatalog,
    getRangedEgcCatalog,
    getMaxFromCatalog
);


export const getMinEgcValue = createSelector(
    isFixedEgcCatalogPresent,
    getFixedEgcCatalog,
    getRangedEgcCatalog,
    getMinFromCatalog
);


export const getMaxPlasticValue = createSelector(
    isFixedPlasticCatalogPresent,
    getFixedPlasticCatalog,
    getRangedPlasticCatalog,
    getMaxFromCatalog
);


export const getMinPlasticValue = createSelector(
    isFixedPlasticCatalogPresent,
    getFixedPlasticCatalog,
    getRangedPlasticCatalog,
    getMinFromCatalog
);


export const getPreferredDenoms = (fixedCatalog, min, max, denoms) => {
    if (fixedCatalog && fixedCatalog.size > 0) {
        return fixedCatalog.map(value => new Big(value));
    }
    return denoms.map(value => new Big(value)).filter(denom => denom.gte(min) && denom.lte(max));
};

const soldOut = (isFixedPresent, rangedCatalog) => !isFixedPresent && rangedCatalog.isEmpty();

export const isElectronicSoldOut = createSelector(
    isFixedEgcCatalogPresent,
    getRangedEgcCatalog,
    soldOut
);

export const isPlasticSoldOut = createSelector(
    isFixedPlasticCatalogPresent,
    getRangedPlasticCatalog,
    soldOut
);

const isAnyCatalogAvailable = (eSoldOut, pSoldOut, supportedTypes) => (
    supportedTypes.get('supportsDigitalCards') && !eSoldOut)
    || (supportedTypes.get('supportsPlasticCards') && !pSoldOut);

export const catalogAvailable = createSelector(
    isElectronicSoldOut,
    isPlasticSoldOut,
    getSupportedCardTypes,
    isAnyCatalogAvailable
);

export const catalogsLoadedAndSoldOut = createSelector(
    brandCatalogIsLoaded,
    catalogAvailable,
    (loaded, available) => (loaded && !available)
);

export const getElectronicDenoms = createSelector(
    getFixedEgcCatalog,
    getMinEgcValue,
    getMaxEgcValue,
    getEgcRecommendedDenoms,
    getPreferredDenoms
);

export const getPlasticDenoms = createSelector(
    getFixedPlasticCatalog,
    getMinPlasticValue,
    getMaxPlasticValue,
    getPlasticRecommendedDenoms,
    getPreferredDenoms
);

export const getMultipleCurrenciesSupported = createSelector(
    getCatalogs,
    catalogs => catalogs.size > 1
);


export const getValidDefaultAmountRangedCatalog = (rangedCatalog, defaultAmount) => {
    const start = rangedCatalog.get('start', 1);
    
    const end = rangedCatalog.get('end', 500);

    
    try {
        const rangedDefaultAmount = new Big(defaultAmount);
        if (rangedDefaultAmount.gte(start) && rangedDefaultAmount.lte(end)) {
            return rangedDefaultAmount;
        }
        return new Big(start).plus(Math.ceil((end - start) / 2));
    } catch (invalidNumber) {
        return new Big(start).plus(Math.ceil((end - start) / 2));
    }
};


export const getValidDefaultAmountFixedCatalog = (fixedDenoms, defaultAmount) => {
    try {
        if (fixedDenoms.includes(defaultAmount) || fixedDenoms.includes(defaultAmount.toFixed(2))) {
            return new Big(defaultAmount);
        }
        return new Big(fixedDenoms.get(Math.ceil((fixedDenoms.size - 1) / 2)));
    } catch (invalidNumber) {
        return new Big(fixedDenoms.get(Math.ceil((fixedDenoms.size - 1) / 2)));
    }
};


export const getValidDefaultAmountForCatalogs = (fixedCatalog, rangedCatalog, defaultAmount) => (
    new Big(fixedCatalog && fixedCatalog.size > 0
        ? getValidDefaultAmountFixedCatalog(fixedCatalog, defaultAmount)
        : getValidDefaultAmountRangedCatalog(rangedCatalog, defaultAmount))
);


export const getEgcDefaultAmount = createSelector(
    getCatalogs,
    getIntlCurrencyCode,
    (catalogs, currency) => {
        const egcDefaultAmount = getInCatalog(getCatalogForCurrency(catalogs, currency), ['egcDefaultAmount'], null);
        return egcDefaultAmount ? new Big(parseFloat(egcDefaultAmount).toFixed(2)) : null;
    }
);


export const getValidEgcDefaultAmount = createSelector(
    getFixedEgcCatalog,
    getRangedEgcCatalog,
    getEgcDefaultAmount,
    (fixedCatalog, rangedCatalog, defaultAmount) => (
        new Big(getValidDefaultAmountForCatalogs(fixedCatalog, rangedCatalog, defaultAmount).toFixed(2))
    )
);


export const getValidPlasticDefaultAmount = createSelector(
    getFixedPlasticCatalog,
    getRangedPlasticCatalog,
    getPlasticDefaultAmount,
    (fixedCatalog, rangedCatalog, defaultAmount) => (
        new Big(getValidDefaultAmountForCatalogs(fixedCatalog, rangedCatalog, defaultAmount).toFixed(2))
    )
);


export const getDefaultAmount = createSelector(
    getValidPlasticDefaultAmount,
    getValidEgcDefaultAmount,
    newItemIsPlastic,
    (plasticDefaultAmount, egcDefaultAmount, isPlastic) => new Big(isPlastic
        ? plasticDefaultAmount
        : egcDefaultAmount)

);


export const getIsCPFEnabledForSelectedCurrency = createSelector(
    getCatalogs,
    newItemCurrency,
    (catalogs, selectedCurrencyCode) => {
        const catalogResult = catalogs.filter(catalog => catalog.get('cpfFlag') === true);
        const cpfEnabledCurrencyList = Object.keys(catalogResult.toJS());
        return cpfEnabledCurrencyList.includes(selectedCurrencyCode);
    }
);
