import { fromJS } from 'immutable';


export const UYO_CONTEXT = {
    REVIEW: 'review',
    CART: 'cart'
};


export const SET_TERMS_AGREEMENT = '@@storefront/SET_TERMS_AGREEMENT';
export const SET_SELECTED_FILE = '@@storefront/SET_SELECTED_FILE';
export const REMOVE_UYO_DATA = '@@storefront/REMOVE_UYO_DATA';
export const SET_UYO_FILE_RES_WARNING = '@@storefront/SET_UYO_FILE_RES_WARNING';
export const UPDATE_UYO_LOCALE_COMPLETE = '@@storefront/UPDATE_UYO_LOCALE_COMPLETE';


export const RES_WARNING_FILE = 'FILE';
export const RES_WARNING_CROP = 'CROP';


export const initialState = fromJS({
    uyoTermsAgreement: false,
    uyoFile: null,
    uyoFileResWarning: null
});


export default function uyoReducer(state = initialState, action) {
    switch (action.type) {
        case SET_TERMS_AGREEMENT: {
            return state.set('uyoTermsAgreement', action.hasAgreed);
        }
        case SET_SELECTED_FILE: {
            return state.set('uyoFile', fromJS(action.fileInfo));
        }
        case REMOVE_UYO_DATA: {
            return state.merge({
                uyoFile: null,
                uyoTermsAgreement: false
            });
        }
        case SET_UYO_FILE_RES_WARNING: {
            return state.set('uyoFileResWarning', action.warningType);
        }
        default:
            return state;
    }
}



export function setTermsAgreement(hasAgreed) {
    return {
        type: SET_TERMS_AGREEMENT,
        hasAgreed
    };
}

export function setSelectedFile(fileInfo) {
    return {
        type: SET_SELECTED_FILE,
        fileInfo
    };
}

export function setUYOFileResWarning(warningType) {
    return {
        type: SET_UYO_FILE_RES_WARNING,
        warningType
    };
}


export const removeUYOData = () => ({
    type: REMOVE_UYO_DATA
});

export const updateUploadcareLocaleComplete = () => ({
    type: UPDATE_UYO_LOCALE_COMPLETE
});
