import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Field } from 'redux-form/immutable';
import classNames from 'classnames/dedupe';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { RadioGroup } from 'cstar-react-primitives/lib/redux-form/microFields/RadioGroup';

import { getFormattedAmountWithCurrency, fixDecimalFormat, getCurrencyFractionDigits } from '../utils/numberUtils';

import denomMessages from './denomMessages';


export const DenomPickerField = ({
    intl,
    denominations,
    maxDenom,
    currencyCode,
    doFocus,
    name
}) => {
    
    const availDenoms = denominations.filter(value => value <= maxDenom);
    const availableFullRow = availDenoms.size % 3 === 0;
    
    const containerClass = classNames('denom-picker', {
        'denom-picker--center': !availableFullRow && availDenoms.size <= 4,
        'denom-picker--start': !availableFullRow && availDenoms.size >= 5,
        'denom-picker--between': availableFullRow
    });

    const buttonList = availDenoms.map((availDenom) => {
        const fractionDigits = getCurrencyFractionDigits(currencyCode);
        const value = fixDecimalFormat(availDenom, fractionDigits);
        const denom = value;
        const formattedDenom = getFormattedAmountWithCurrency(intl, value, currencyCode);
        return {
            value,
            children: (<FormattedMessage {...denomMessages.denomRadioButtonValue} values={{ formattedDenom, denom }} />)
        };
    }).toJS();


    const containerProps = {
        component: RadioGroup,
        name,
        cashbotName: 'denom-radiogroup',
        legend: intl.formatMessage(denomMessages.radiogroupLabel),
        styleType: 'picker',
        containerClass,
        radioButtonClass: 'pill',
        radioOptions: buttonList,
        doFocus
    };

    return (
        <Field {...containerProps} />
    );
};

DenomPickerField.defaultProps = {
    doFocus: false
};

DenomPickerField.propTypes = {
    
    intl: intlShape.isRequired,
    currencyCode: PropTypes.string.isRequired,
    denominations: PropTypes.instanceOf(List).isRequired,
    doFocus: PropTypes.bool,
    maxDenom: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
};

export default injectIntl(DenomPickerField);
