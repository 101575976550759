
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { injectIntl, intlShape } from 'react-intl';
import Markdown from 'markdown-to-jsx';
import { useDispatch, useSelector } from 'react-redux';
import { Panel } from 'cstar-react-primitives/lib/redux-form/layout/Panel';
import { RadioGroup } from 'cstar-react-primitives/lib/microFields/RadioGroup';
import { Field } from 'redux-form/immutable';
import FormButton from '../primitive/buttons/FormButton';
import {
    newItemReduxForm,
    newItemShareViaLink,
    giftCardFieldNames,
    newItemEmailCheckbox,
    IS_SHARE_VIA_LINK,
    EMAIL_FIELD_SELECTED,
    SMS_FIELD_SELECTED
} from '../item/newItemForm';
import { changeValue, setDefaultFieldValues } from '../item/newItemFormModule';
import FutureSend from '../futureSend/FutureSend';

import deliveryMessages from './deliveryMessages';
import itemMessages from '../item/itemMessages';
import { getIsShareViaLinkDefaultDeliveryOption } from '../brand/brandSelectors';
import RecipientEmailField from './RecipientEmailField';
import SMSField from './SMSField';
import CheckBox from './sendCheckBox';
import validate from './electronicValidation';
import SubheaderField from './SubheaderField';


export const DeliveryMethodRadio = ({ intl, setRadioSelection }) => {
    const isShareViaLinkDefaultDeliveryOption = useSelector(getIsShareViaLinkDefaultDeliveryOption);
    const isShareViaLink = useSelector(state => newItemShareViaLink(state));
    const radioOptions = isShareViaLinkDefaultDeliveryOption ? [
        { value: true, children: intl.formatMessage(deliveryMessages.sendBySharableLink) },
        { value: false, children: intl.formatMessage(deliveryMessages.sendItByEmail) }
    ] : [
        { value: false, children: intl.formatMessage(deliveryMessages.sendItByEmail) },
        { value: true, children: intl.formatMessage(deliveryMessages.sendBySharableLink) }
    ];

    const radioGroupProps = {
        name: 'radio-delivery-method',
        cashbotName: 'delivery-method-picker',
        legend: 'Choose your delivery method',
        styleType: 'radiobutton',
        checkmarkClass: 'radio-icon',
        containerClass: 'delivery-method-container',
        radioButtonClass: 'delivery-method-radio-button',
        radioOptions,
        defaultValue: isShareViaLink !== undefined ? isShareViaLink : radioOptions[0].value,
        handleChange: setRadioSelection
    };

    return (
        <div>
            <RadioGroup {...radioGroupProps} />
        </div>
    );
};

DeliveryMethodRadio.propTypes = {
    intl: intlShape.isRequired,
    setRadioSelection: PropTypes.func.isRequired
};

export const ElectronicDeliveryForm = ({
    sendDateTime,
    submitAction,
    getSubmitMessage,
    recipientName,
    handleSubmit,
    anyTouched,
    invalid,
    change,
    submitFailed,
    cashbotName,
    intl,
    form,
    isRecipientSMSEnabled,
    isSMSDeliveryForRecipientEnabled,
    isShareViaLinkDeliveryOptionEnabled,
    isShareViaLinkDefaultDeliveryOption
}) => {
    const isShareViaLink = useSelector(state => newItemShareViaLink(state));
    const [focusSendTimeLink, setFocusSendTimeLink] = useState(null);
    const isCheckedEmailState = useSelector(state => newItemEmailCheckbox(state));
    const [checkedEmail, setCheckedEmail] = useState(true);
    const [checkedSms, setCheckedSms] = useState(false);
    let defaultRadioOption;
    if (isShareViaLinkDeliveryOptionEnabled) {
        if (isShareViaLink !== undefined) {
            defaultRadioOption = isShareViaLink;
        } else {
            defaultRadioOption = isShareViaLinkDefaultDeliveryOption;
        }
    } else {
        defaultRadioOption = false;
    }
    const [isSharableLinkSelected, setIsSharableLinkSelected] = useState(defaultRadioOption);
    const smsFieldLabelMessage = isSMSDeliveryForRecipientEnabled ? itemMessages.recipientSMSLabelNotOptional : itemMessages.recipientSMSLabel;
    const dispatch = useDispatch();

    const emailProps = {
        intl,
        recipientName,
        isPlastic: false,
        cashbotName,
        doFocus: false,
        confirmEmailFieldEnabled: !isSMSDeliveryForRecipientEnabled,
        spellCheckEmailPromptEnabled: true,
        smsDeliveryEnabled: isSMSDeliveryForRecipientEnabled
    };
    const smsProps = {
        intl,
        cashbotName,
        recipientName,
        smsDeliveryEnabled: isSMSDeliveryForRecipientEnabled,
        fieldLabel: smsFieldLabelMessage,
        change
    };
    const futureSend = {
        intl,
        cashbotName,
        recipientName,
        handleDateTimeChange: newDateTime => change(giftCardFieldNames.RECIPIENT_SEND_DATETIME_FIELD, newDateTime),
        handleTimeChange: newDateTime => change(giftCardFieldNames.RECIPIENT_SEND_TIME_FIELD, newDateTime),
        handleTimeZoneChange: newTimeZone => change(giftCardFieldNames.RECIPIENT_SEND_TIMEZONE_FIELD, newTimeZone),
        initialDate: sendDateTime,
        focusSendTimeLink,
        setFocusSendTimeLink,
        isShareViaLinkDeliveryOptionEnabled
    };
    const buttonProps = {
        btnText: intl.formatMessage(getSubmitMessage()),
        anyTouched,
        invalid,
        submitFailed,
        cashbotName,
        formName: form,
        doFocus: focusSendTimeLink !== null ? !focusSendTimeLink : false
    };
    const checkBoxProps = {
        intl,
        cashbotName
    };
    const checkEmailProps = {
        setUpdate: setCheckedEmail,
        name: giftCardFieldNames.EMAIL_FIELD_SELECTED,
        label: deliveryMessages.sendByEmail,
        isChecked: isCheckedEmailState || checkedEmail,
        ...checkBoxProps
    };
    const checkSmsProps = {
        setUpdate: setCheckedSms,
        name: giftCardFieldNames.SMS_FIELD_SELECTED,
        label: deliveryMessages.sendBySms,
        isChecked: checkedSms,
        ...checkBoxProps
    };
    const subheaderProps = {
        intl,
        recipientName,
        smsDeliveryEnabled: isSMSDeliveryForRecipientEnabled,
        name: giftCardFieldNames.RECIPIENT_ERROR_LABEL,
        component: SubheaderField
    };

    const setRadioSelection = (value) => {
        setIsSharableLinkSelected(value);
    };

    
    useEffect(() => {
        const recipientSmsInput = document.querySelector('input[name="recipientSms"]');

        let includeSMSField = false;
        if (!isSharableLinkSelected) {
            if (isRecipientSMSEnabled || (checkedSms && recipientSmsInput.value)) {
                includeSMSField = true;
            }
        }
        const updatedValues = fromJS({
            [IS_SHARE_VIA_LINK]: isSharableLinkSelected,
            [EMAIL_FIELD_SELECTED]: !isSharableLinkSelected,
            [SMS_FIELD_SELECTED]: includeSMSField
        });
        dispatch(setDefaultFieldValues(updatedValues, true));
    }, [isSharableLinkSelected]);

    useEffect(() => {
        if (isShareViaLinkDefaultDeliveryOption) {
            dispatch(changeValue(IS_SHARE_VIA_LINK, true));
        }
    }, []);

    
    return (
        <form method="POST" onSubmit={handleSubmit(submitAction)} noValidate>
            {isShareViaLinkDeliveryOptionEnabled && <DeliveryMethodRadio intl={intl} setRadioSelection={setRadioSelection} />}
            {!isSharableLinkSelected ? (
                <Panel>
                    { isSMSDeliveryForRecipientEnabled && <Field {...subheaderProps} /> }
                    <div className='field-group__div'>
                        { isSMSDeliveryForRecipientEnabled && <CheckBox {...checkEmailProps} /> }
                        { <RecipientEmailField {...emailProps} /> }
                        { isSMSDeliveryForRecipientEnabled && <CheckBox {...checkSmsProps} /> }
                        { (isSMSDeliveryForRecipientEnabled || isRecipientSMSEnabled) && <SMSField {...smsProps} /> }
                    </div>
                    <FutureSend {...futureSend} />
                </Panel>
            ) : (
                <Panel>
                    <Markdown className='sharable-link-helper-text'>
                        {intl.formatMessage(deliveryMessages.sharableLinkMessage, { name: recipientName })}
                    </Markdown>
                </Panel>
            )
            }
            <FormButton {...buttonProps} />
        </form>
    );
};

ElectronicDeliveryForm.defaultProps = {
    isRecipientSMSEnabled: false,
    isSMSDeliveryForRecipientEnabled: false,
    isShareViaLinkDeliveryOptionEnabled: false,
    isShareViaLinkDefaultDeliveryOption: false,
    anyTouched: false,
    invalid: false,
    submitFailed: false
};

ElectronicDeliveryForm.propTypes = {
    
    cashbotName: PropTypes.string.isRequired,
    isRecipientSMSEnabled: PropTypes.bool,
    isSMSDeliveryForRecipientEnabled: PropTypes.bool,
    isShareViaLinkDeliveryOptionEnabled: PropTypes.bool,
    isShareViaLinkDefaultDeliveryOption: PropTypes.bool,
    form: PropTypes.string.isRequired,
    submitAction: PropTypes.func.isRequired,
    getSubmitMessage: PropTypes.func.isRequired,
    recipientName: PropTypes.string.isRequired,
    sendDateTime: PropTypes.instanceOf(Date).isRequired,
    isShareViaLink: PropTypes.bool.isRequired,
    
    anyTouched: PropTypes.bool,
    change: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    submitFailed: PropTypes.bool,
    intl: intlShape.isRequired
};


export default injectIntl(newItemReduxForm(ElectronicDeliveryForm, { validate }));
