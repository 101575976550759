import { List as IList } from 'immutable';

import { createSelector } from 'reselect';
import { programStepCompleted } from '../program/programStepSelectors';
import { cardTypeStepCompleted } from '../cardType/cardTypeStepSelectors';
import { deliveryStepCompleted } from '../delivery/deliveryStepSelectors';
import { getFlowProgress } from '../routing/flowSelectors';
import { SHIPPING_METHOD_FIELD } from '../item/newItemForm';



export const shippingStepCompleted = createSelector(
    getFlowProgress,
    flowState => flowState.has(SHIPPING_METHOD_FIELD)
);


export const shippingMethodStepPreConditions = IList([
    programStepCompleted,
    cardTypeStepCompleted
]);


export const shippingMethodStepPostConditions = IList([
    deliveryStepCompleted
]);


export const shippingMethodStepSkippable = () => false;
