import React from 'react';
import PropTypes from 'prop-types';


export const ModalContentInner = ({ children }) => (
    <div className="modal-content-inner">
        { children }
    </div>
);

ModalContentInner.defaultProps = {
    children: null
};

ModalContentInner.propTypes = {
    children: PropTypes.node
};


const ModalContent = ({ children }) => (
    <div className="modal-content">
        { children }
    </div>
);

ModalContent.defaultProps = {
    children: null
};

ModalContent.propTypes = {
    children: PropTypes.node
};

export default ModalContent;
