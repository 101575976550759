import React from 'react';

import { fieldValidatorsPostalReq } from './addressValidation';
import addressFields from './addressFieldMessages';
import { addressFieldNames as fields } from '../address/addressModule';
import {
    defaultFieldProps,
    addressPropTypes
} from './addressFieldsModule';

import {
    Street1Field,
    Street2Field,
    CityField,
    StateField,
    PostalCodeField
} from './Fields';



export const AddressFieldsRequiredPostal = ({
    addressContext,
    cashbotName,
    doFieldValidation,
    intl,
    showUntouchedErrors,
    updateShippingAddress,
    useAddressAutocomplete,
    validators,
    countryCode
}) => {
    const commonFieldProps = {
        addressContext,
        cashbotName,
        doFieldValidation,
        intl,
        showUntouchedErrors
    };
    const street1Props = {
        ...commonFieldProps,
        label: addressFields.DEFAULT.street1Label,
        updateShippingAddress,
        useAddressAutocomplete,
        validate: validators.get(fields.ADDRESS_STREET1),
        countryCode
    };
    const street2Props = {
        ...commonFieldProps,
        validate: validators.get(fields.ADDRESS_STREET2),
        label: addressFields.DEFAULT.street2Label
    };
    const cityProps = {
        ...commonFieldProps,
        label: addressFields.DEFAULT.cityLabel,
        validate: validators.get(fields.ADDRESS_CITY)
    };
    const stateProps = {
        ...commonFieldProps,
        isRequiredField: false,
        doFieldValidation: false,
        label: addressFields.DEFAULT.stateLabel,
        validate: null
    };
    const postalCodeProps = {
        ...commonFieldProps,
        isRequiredField: true,
        label: addressFields.DEFAULT.postalCodeLabelRequired,
        normalize: null,
        validate: validators.get(fields.ADDRESS_POSTCODE)
    };

    return (
        <React.Fragment>
            <Street1Field {...street1Props} />
            <Street2Field {...street2Props} />
            <CityField {...cityProps} />
            <StateField {...stateProps} />
            <PostalCodeField {...postalCodeProps} />
        </React.Fragment>
    );
};

AddressFieldsRequiredPostal.defaultProps = {
    ...defaultFieldProps,
    validators: fieldValidatorsPostalReq
};

AddressFieldsRequiredPostal.propTypes = {
    ...addressPropTypes
};

export default AddressFieldsRequiredPostal;
