
import { fromJS, List as IList } from 'immutable';

import { itemSegmentData } from '../segment/segmentSelectors';
import { SHIPPING_METHOD_FIELD, RECIPIENT_PLASTIC_NOTIFICATION, PREV_SHIPPING_METHOD_FIELD } from './newItemForm';

export const DEFAULT_DISPLAY_CONTEXT = 'allItems';
export const SHIPPING_OPTIONS_CHANGED = 'shippingOptionsChanged';
export const SHIPPING_ADDRESS_CHANGED = 'shippingAddressChanged';
export const ITEM_IS_VALID = 'isValid';


export const ITEM_RESET = '@@storefront/ITEM_RESET';
export const ITEM_IS_REMOVED = '@@storefront/ITEM_IS_REMOVED';
export const ITEM_UNDO_REMOVE = '@@storefront/ITEM_UNDO_REMOVE';
export const EDIT_ITEM = '@@storefront/EDIT_ITEM';
export const EDIT_ADDRESS = '@@storefront/EDIT_ADDRESS';
export const UPDATE_ITEM = '@@storefront/UPDATE_ITEM';
export const ITEM_PLASTIC_NOTIFICATION_IS_CHANGED = '@@storefront/ITEM_PLASTIC_NOTIFICATION_IS_CHANGED';


export const SAVE_NEW_ITEM = '@@storefront/SAVE_NEW_ITEM';
export const UNSAFE_SAVE_NEW_ITEM = '@@storefront/UNSAFE_SAVE_NEW_ITEM';
export const ROLLBACK_ITEM_EDIT = '@@storefront/ROLLBACK_ITEM_EDIT';
export const REVERT_SHIPPING_METHOD = '@@storefront/REVERT_SHIPPING_METHOD';
export const SET_ADDRESS_CHANGED = '@@storefront/SET_ADDRESS_CHANGED';
export const REMOVE_PREV_SHIPPING_METHOD = '@@storefront/REMOVE_PREV_SHIPPING_METHOD';
export const SAVE_NEW_ITEM_END = '@@storefront/SAVE_NEW_ITEM_END';
export const EDIT_ITEM_END = '@@storefront/EDIT_ITEM_END';
export const RESET_ITEM = '@@storefront/RESET_ITEM';
export const SET_SHIPPING_METHOD_TOKEN = '@@storefront/SET_SHIPPING_METHOD_TOKEN';
export const SET_FORM_DELIVERY_TYPE = '@@storefront/SET_FORM_DELIVERY_TYPE';


export const initialState = IList();





export default function itemReducer(state = initialState, action) {
    switch (action.type) {
        case UNSAFE_SAVE_NEW_ITEM:
            return state.push(action.item);
        case UPDATE_ITEM: {
            
            const newState = state.map((item, index) => {
                if (item.get('id') === action.itemUpdates.get('id')) {
                    return state.get(index).mergeDeep(action.itemUpdates);
                }
                return item;
            });
            return fromJS(newState);
        }
        case ROLLBACK_ITEM_EDIT: {
            const { itemID, iterations } = action;
            const itemIndex = state.findIndex(item => item.get('id') === itemID);
            let targetItem = state.get(itemIndex);
            let x = iterations;
            while ((x > 0) && targetItem.has('previousItem')) {
                targetItem = targetItem.get('previousItem');
                x -= 1;
            }
            return state.set(itemIndex, targetItem);
        }
        case SET_SHIPPING_METHOD_TOKEN: {
            const { itemID, token } = action;
            const itemIndex = state.findIndex(item => item.get('id') === itemID);
            const prevToken = state.get(itemIndex).get('shippingMethod');
            const updatedItem = state.get(itemIndex).merge({
                [SHIPPING_METHOD_FIELD]: token,
                [SHIPPING_OPTIONS_CHANGED]: false,
                [PREV_SHIPPING_METHOD_FIELD]: prevToken
            });
            return state.set(itemIndex, updatedItem);
        }
        case REVERT_SHIPPING_METHOD: {
            const { itemID } = action;
            const itemIndex = state.findIndex(item => item.get('id') === itemID);
            const token = state.get(itemIndex).get('prevShippingMethod');
            let updatedItem = state.get(itemIndex);
            if (token) {
                updatedItem = updatedItem.merge({
                    [SHIPPING_METHOD_FIELD]: token
                });
            }
            return state.set(itemIndex, updatedItem);
        }
        case REMOVE_PREV_SHIPPING_METHOD: {
            const { itemID } = action;
            const itemIndex = state.findIndex(item => item.get('id') === itemID);
            const token = state.get(itemIndex).get('prevShippingMethod');
            let updatedItem = state.get(itemIndex);
            if (token) {
                updatedItem = updatedItem.merge({
                    [PREV_SHIPPING_METHOD_FIELD]: false
                });
            }
            return state.set(itemIndex, updatedItem);
        }
        case ITEM_PLASTIC_NOTIFICATION_IS_CHANGED: {
            return state.setIn([action.itemID, RECIPIENT_PLASTIC_NOTIFICATION], action.editPlasticNotification);
        }
        case RESET_ITEM:
            return initialState;
        default:
            return state;
    }
}





export function unsafeSaveNewItem(item) {
    return {
        type: UNSAFE_SAVE_NEW_ITEM,
        item
    };
}

export function resetItemStore() {
    return {
        type: RESET_ITEM
    };
}





export function saveNewItemAction(values, replaceHistory = false, isBundle = false) {
    return {
        type: SAVE_NEW_ITEM,
        values,
        isBundle,
        replaceHistory,
        meta: {
            segment: {
                event: 'track',
                eventAction: 'Product Added',
                eventData: itemSegmentData(values)
            }
        }
    };
}


export function editItem(formName, skipHistory = false, updateAddress = false, updateItemNotification = false) {
    return {
        type: EDIT_ITEM,
        formName,
        skipHistory,
        updateAddress,
        updateItemNotification
    };
}


export function editAddress(formName) {
    return {
        type: EDIT_ADDRESS,
        formName
    };
}


export function rollbackEditItem(itemID, iterations = 1) {
    return {
        type: ROLLBACK_ITEM_EDIT,
        itemID,
        iterations
    };
}


export function revertShippingMethod(item) {
    return {
        type: REVERT_SHIPPING_METHOD,
        itemID: item.get('id')
    };
}


export function removePrevShippingMethod(item) {
    return {
        type: REMOVE_PREV_SHIPPING_METHOD,
        itemID: item.get('id')
    };
}


export function updateItem(itemUpdates) {
    return {
        type: UPDATE_ITEM,
        itemUpdates
    };
}


export function setShippingMethodToken(itemID, token) {
    return {
        type: SET_SHIPPING_METHOD_TOKEN,
        itemID,
        token
    };
}


export function removeCartItem(item, bundleItems = false) {
    
    let segmentData = { bundleGiftCards: [] };
    if (bundleItems) {
        bundleItems.forEach(bundleItem => segmentData.bundleGiftCards.push(itemSegmentData(bundleItem)));
    } else {
        segmentData = itemSegmentData(item);
    }

    return {
        type: ITEM_IS_REMOVED,
        item,
        meta: {
            segment: {
                event: 'track',
                eventAction: 'Product Removed',
                eventData: segmentData
            }
        }
    };
}


export function updateCartPlasticNotification(item, editPlasticNotification) {
    return {
        type: ITEM_PLASTIC_NOTIFICATION_IS_CHANGED,
        itemID: item.get('id'),
        editPlasticNotification
    };
}


export function itemReset() {
    return {
        type: ITEM_RESET
    };
}


export function undoItemRemove(item, uid) {
    return {
        type: ITEM_UNDO_REMOVE,
        item,
        uid,
        meta: {
            segment: {
                event: 'track',
                eventAction: 'Product Added',
                eventData: itemSegmentData(item)
            }
        }
    };
}
