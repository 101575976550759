import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { intlShape } from 'react-intl';
import { Map as IMap, List as IList } from 'immutable';
import Markdown from 'markdown-to-jsx';

import { Alert } from 'cstar-react-primitives/lib/redux-form/alert/Alert';
import DenomRange from '../denomination/DenomRange';
import { getIntlCurrencyCode } from '../intl/intlSelectors';
import {
    getBrandCatalogs,
    getBrandCode
} from '../brand/brandSelectors';
import ScrollIntoView from '../primitive/ScrollIntoViewWrap';
import windowSize from '../utils/windowSize';
import { isMobileView } from '../utils/mobileUtils';
import { getStaticMediaURL } from '../app/bootstrap';

import programMessages from './programMessages';
import { formatProgramMessage } from './formatProgramMessage';
import {
    getSelectableProgramsInfo,
    getClassicCardDescription,
    getCustomCardMessages,
    getDefaultFaceplateForProgram,
    DEFAULT_ELECTRONIC_FACEPLATES,
    DEFAULT_PHYSICAL_FACEPLATES,
    getAlternateImageForProgram,
    getShowCurrencyCodeOnProgramTiles,
    getShowCurrencyListOnProgramTiles,
    getExcludedBillingStatesForProgram,
    getIncludedBillingStatesForProgram
} from './programListSelectors';
import { changeProgram, PICKER_CLASS } from './programModule';
import { CustomCard } from './CustomCard';
import ProgramPreview from './ProgramPreview';
import {
    generateProgramProps
} from './programUtils';
import stateMap from '../addressFields/data/USStateMap';
import { PickerCard } from './PickerCard';



export const CUSTOM_TILE_VALUE = -1;

export const ProgramSelect = ({
    intl,
    name,
    text,
    description,
    classicCardsDescription,
    showClassicCardsDescription,
    previewFaceplates,
    minRange,
    maxRange,
    rangeCurrencyCode,
    currencyList,
    showDenomRange,
    brandCode,
    previewImageFilename,
    previewImageAltText,
    showCurrencyCode,
    showCurrencyList,
    excludedBillingStates,
    includedBillingStates
}) => {
    const denomRangeProps = {
        minRange,
        maxRange,
        rangeCurrencyCode,
        currencyList,
        showCurrencyCode,
        showCurrencyList
    };
    const previewProps = {
        defaultFaceplates: previewFaceplates
    };

    const showAltImage = previewImageFilename && previewImageAltText;
    const staticUrl = getStaticMediaURL(`/images/${brandCode}/${previewImageFilename}`);
    const includedStates = includedBillingStates && includedBillingStates.replace(/ /g, '').split(',');
    const count = includedStates.length;
    const message = programMessages.programStateExclusionWarning;
    message.values = {
        programName: name,
        count,
        state: count === 1 && intl.formatMessage(stateMap[includedStates.shift()])
    };

    const renderCardHead = () => (
        <div className='card-head'>
            { !showAltImage && <ProgramPreview {...previewProps} /> }
            <span className="card-title" aria-hidden>{text}</span>
            { showDenomRange && <DenomRange {...denomRangeProps} /> }
        </div>
    );

    return (
        <Fragment>
            { (excludedBillingStates.trim().length > 0)
                && <div className='picker-alert'><Alert alertType='info' hasHeading={false} message={message} /></div>}
            { showAltImage && <img className='card-image' src={staticUrl} alt='' /> }
            { renderCardHead() }
            { description && <span className="card-description"><Markdown>{description}</Markdown></span> }
            { showClassicCardsDescription
                && <span className="classic-description"><Markdown>{classicCardsDescription}</Markdown></span> }
        </Fragment>
    );
};

ProgramSelect.defaultProps = {
    description: null,
    classicCardsDescription: '',
    previewFaceplates: IList(),
    minRange: 0,
    maxRange: 0,
    showDenomRange: false,
    showClassicCardsDescription: false,
    previewImageFilename: null,
    previewImageAltText: null,
    excludedBillingStates: '',
    includedBillingStates: ''
};

ProgramSelect.propTypes = {
    intl: intlShape.isRequired,
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    description: PropTypes.string,
    classicCardsDescription: PropTypes.string,
    previewFaceplates: PropTypes.instanceOf(IList),
    minRange: PropTypes.number,
    maxRange: PropTypes.number,
    rangeCurrencyCode: PropTypes.string.isRequired,
    currencyList: PropTypes.instanceOf(IList).isRequired,
    showDenomRange: PropTypes.bool,
    showClassicCardsDescription: PropTypes.bool,
    brandCode: PropTypes.string.isRequired,
    previewImageFilename: PropTypes.string,
    previewImageAltText: PropTypes.string,
    showCurrencyCode: PropTypes.bool.isRequired,
    showCurrencyList: PropTypes.bool.isRequired,
    excludedBillingStates: PropTypes.string,
    includedBillingStates: PropTypes.string
};



const getSelectOptions = (
    programsInfo,
    programProps,
    customCardMessages,
    customTileSelected,
    brandCode,
    windowWidth
) => {
    const selectOptions = programsInfo.get('programs').map((group, index) => {
        const { programCode, ...programSelectProps } = generateProgramProps(group, index, programProps);
        return {
            value: programCode,
            children: (<ProgramSelectWrapper {...programSelectProps} />)
        };
    }).toJS();

    const showCustomTile = Boolean(
        customCardMessages.get('customCardTitle') && customCardMessages.get('customCardActionUrl')
    );
    if (showCustomTile) {
        const customTileProps = {
            text: customCardMessages.get('customCardTitle', ''),
            description: customCardMessages.get('customCardDescription', ''),
            header: customCardMessages.get('customCardHeader', ''),
            imgUrl: customCardMessages.get('customCardImage', ''),
            imgAlt: customCardMessages.get('customCardImageAlt', ''),
            brandCode
        };
        const customTileScrollProps = {
            doScroll: customTileSelected && isMobileView(windowWidth)
        };

        selectOptions.push({
            value: CUSTOM_TILE_VALUE,
            children: (
                <ScrollIntoView {...customTileScrollProps}>
                    <CustomCard {...customTileProps} />
                </ScrollIntoView>
            )
        });
    }

    return selectOptions;
};



export const getCartConflictMessage = (
    programsInfo,
    intl
) => {
    const conflictingCartProgramCode = programsInfo.get('conflictingCartProgramCode');
    const isReducedByBillingStateConflicts = programsInfo.get('isReducedByBillingStateConflicts');
    const programs = programsInfo.get('programs');
    if (programs && isReducedByBillingStateConflicts && conflictingCartProgramCode) {
        const conflictingCartProgram = (
            programs.find(program => program.get('programCode') === conflictingCartProgramCode)
        );
        if (conflictingCartProgram) {
            const conflictingCartProgramName = formatProgramMessage(
                intl,
                programMessages.programTitle,
                conflictingCartProgramCode,
                { programCode: conflictingCartProgramCode }
            );
            return intl.formatMessage(programMessages.billingStateCartConflictWarning, {
                programName: conflictingCartProgramName
            });
        }
    }
    return undefined;
};


const ProgramSelectWrapper = (
    programProps
) => {
    const {
        shouldFocus,
        isChecked,
        windowWidth,
        index
    } = programProps;

    if ((index === 0) && shouldFocus) {
        programProps.doFocus = shouldFocus;
    }
    const programScrollProps = {
        doScroll: isChecked && isMobileView(windowWidth)
    };
    return (
        <ScrollIntoView {...programScrollProps}>
            <ProgramSelect {...programProps} />
        </ScrollIntoView>
    );
};


export const ProgramPicker = ({
    intl,
    cashbotName,
    name,
    programsInfo,
    shouldFocus,
    switchProgram,
    submitAction,
    classicCardsDescription,
    getFaceplateByProgram,
    classicCatalogs,
    defaultCurrencyCode,
    customCardMessages,
    brandCode,
    windowWidth,
    getAltImageByProgram,
    showCurrencyCode,
    showCurrencyList,
    getExcludedBillingStates,
    getIncludedBillingStates
}) => {
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [customTileSelected, setCustomTileSelected] = useState(false);

    const isReducedByBillingStateConflicts = programsInfo.get('isReducedByBillingStateConflicts');
    const conflictingCartProgramCode = programsInfo.get('conflictingCartProgramCode');
    const cartConflictMessage = getCartConflictMessage(programsInfo, intl);

    function selectProgram(programCode) {
        if (programCode === CUSTOM_TILE_VALUE) {
            setSelectedProgram(null);
            setCustomTileSelected(true);
        } else {
            switchProgram(programCode);
            setSelectedProgram(programCode);
            setCustomTileSelected(false);
        }
    }

    const programProps = {
        shouldFocus,
        intl,
        getFaceplateByProgram,
        classicCatalogs,
        defaultCurrencyCode,
        classicCardsDescription,
        name,
        selectedProgram,
        windowWidth,
        DEFAULT_PHYSICAL_FACEPLATES,
        DEFAULT_ELECTRONIC_FACEPLATES,
        getAltImageByProgram,
        brandCode,
        showCurrencyCode,
        showCurrencyList,
        getExcludedBillingStates,
        getIncludedBillingStates
    };

    const selectOptions = getSelectOptions(
        programsInfo,
        programProps,
        customCardMessages,
        customTileSelected,
        brandCode,
        windowWidth
    );

    const cardProps = {
        customCardMessages,
        selectProgram,
        submitAction,
        cellClass: `${PICKER_CLASS}--cell`
    };

    return (
        <Fragment>
            { isReducedByBillingStateConflicts && conflictingCartProgramCode && (
                <div className='picker-alert'>
                    <Alert alertType='info' hasHeading={false} message={cartConflictMessage} />
                </div>
            ) }

            <div
                className={PICKER_CLASS}
                tabIndex='-1'
                data-cashbot-id={`${cashbotName}-${name}`}
                aria-label={intl.formatMessage(programMessages.programLegend)}
                aria-describedby='error-message'
            >
                {selectOptions.map((option, i) => <PickerCard key={option.value} {...{ i, ...cardProps, ...option }} />)}
            </div>
        </Fragment>
    );
};

ProgramPicker.defaultProps = {
    shouldFocus: true,
    classicCardsDescription: '',
    customCardMessages: IMap()
};

ProgramPicker.propTypes = {
    intl: intlShape.isRequired,
    cashbotName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    programsInfo: ImmutablePropTypes.map.isRequired,
    shouldFocus: PropTypes.bool,
    switchProgram: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    classicCardsDescription: PropTypes.string,
    getFaceplateByProgram: PropTypes.func.isRequired,
    classicCatalogs: PropTypes.objectOf(IMap).isRequired,
    customCardMessages: PropTypes.instanceOf(IMap),
    brandCode: PropTypes.string.isRequired,
    defaultCurrencyCode: PropTypes.string.isRequired,
    getAltImageByProgram: PropTypes.func.isRequired,
    showCurrencyCode: PropTypes.bool.isRequired,
    showCurrencyList: PropTypes.bool.isRequired,
    
    windowWidth: PropTypes.number.isRequired,
    getExcludedBillingStates: PropTypes.func.isRequired,
    getIncludedBillingStates: PropTypes.func.isRequired
};

export const mapStateToProps = state => ({
    programsInfo: getSelectableProgramsInfo(state),
    classicCardsDescription: getClassicCardDescription(state),
    getFaceplateByProgram: getDefaultFaceplateForProgram(state),
    classicCatalogs: getBrandCatalogs(state),
    defaultCurrencyCode: getIntlCurrencyCode(state),
    customCardMessages: getCustomCardMessages(state),
    brandCode: getBrandCode(state),
    getAltImageByProgram: getAlternateImageForProgram(state),
    showCurrencyCode: getShowCurrencyCodeOnProgramTiles(state),
    showCurrencyList: getShowCurrencyListOnProgramTiles(state),
    getExcludedBillingStates: getExcludedBillingStatesForProgram(state),
    getIncludedBillingStates: getIncludedBillingStatesForProgram(state)
});

export const mapDispatchToProps = {
    switchProgram: changeProgram
};

export default connect(mapStateToProps, mapDispatchToProps)(windowSize(ProgramPicker));
