import { Map as IMap } from 'immutable';
import {
    getCapiSessionID,
    getCapiUrl,
    getCapiToken
} from 'capi/redux/immutable';
import { createCapiClient, createCapiClientFormEncoded } from 'capi';
import { createSelector } from 'reselect';

import { getIovation } from '../iovation/iovationModule';


export const getCapiClient = createSelector(
    getCapiSessionID, getCapiUrl, getCapiToken, getIovation,
    (sessionID, capiUrl, capiToken, iovation) => (
        createCapiClient(
            capiUrl,
            capiToken,
            sessionID,
            iovation.get('blackbox'),
            iovation.get('blackboxUrl'),
            'v1'
        )
    )
);

export const getCapiClientFormEncoded = createSelector(
    getCapiSessionID, getCapiUrl, getCapiToken, getIovation,
    (sessionID, capiUrl, capiToken, iovation) => (
        createCapiClientFormEncoded(
            capiUrl,
            capiToken,
            sessionID,
            iovation.get('blackbox'),
            iovation.get('blackboxUrl'),
            'v1'
        )
    )
);


export const getStorefrontSession = state => state.get('session', IMap());


export const getStorefrontSessionKey = createSelector(
    getStorefrontSession,
    session => session.get('sessionKey')
);


export const getIsCartEdited = createSelector(
    getStorefrontSession,
    session => session.get('isCartEdited')
);


export const getFirstLoad = createSelector(
    getStorefrontSession,
    session => session.get('firstLoad')
);


export const getCapiCall = state => state.get('capiCall', IMap());


export const getCapiSessionCallIndicator = createSelector(
    getCapiCall,
    session => session.get('sessionCallInProgress')
);
