import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';

import { getIntlLocale } from '../intl/intlSelectors';

import CashstarLink from './CashstarLink';
import footerLinksDefault from './footerLinksMessages';
import { setFAQViewed } from '../routing/flowModule';


export const CashstarLinksDefault = ({
    intl,
    selectedLocale,
    dispatchFAQClicked
}) => (
    <Fragment>
        <CashstarLink
            url='/self_service/v2/about/faq'
            linkText={intl.formatMessage(footerLinksDefault.faq)}
            external
            onClick={dispatchFAQClicked}
        />
        <CashstarLink
            url={`/self_service/register/?locale=${selectedLocale}`}
            linkText={intl.formatMessage(footerLinksDefault.support)}
            external
        />
        <CashstarLink
            url='/self_service/v2/about/terms_and_conditions'
            linkText={intl.formatMessage(footerLinksDefault.terms)}
            external
        />
        <CashstarLink
            url='/store/self_service/v2/about/privacy_policy'
            linkText={intl.formatMessage(footerLinksDefault.privacy)}
            external
        />
        <CashstarLink
            url={intl.formatMessage(footerLinksDefault.accessibilityLink)}
            linkText={intl.formatMessage(footerLinksDefault.accessibility)}
            external
        />
    </Fragment>
);

CashstarLinksDefault.propTypes = {
    selectedLocale: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    dispatchFAQClicked: PropTypes.func.isRequired
};

export const mapStateToProps = state => ({
    selectedLocale: getIntlLocale(state)
});

export const mapDispatchToProps = dispatch => ({
    dispatchFAQClicked: () => dispatch(setFAQViewed({}))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CashstarLinksDefault));
