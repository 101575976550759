import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';

import { LinkButton } from 'cstar-react-primitives/lib/redux-form/buttons/LinkButton';

export const messages = defineMessages({
    sendLinkCancel: {
        id: 'futureSend.time.sendTime.sendLinkCancel',
        description: 'Link text to cancel delivery hour and timezone selection',
        defaultMessage: 'Nevermind'
    }
});

export const SendTimeLinkCancel = ({
    cashbotName,
    handleReset
}) => {
    const linkProps = {
        cashbotName,
        onClick: handleReset,
        atts: {
            'aria-expanded': 'true'
        }
    };
    return (
        <LinkButton {...linkProps}>
            <FormattedMessage {...messages.sendLinkCancel} />
        </LinkButton>
    );
};

SendTimeLinkCancel.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    handleReset: PropTypes.func.isRequired
};

export default SendTimeLinkCancel;
