

import {
    takeEvery, put, select, call
} from 'redux-saga/effects';
import {
    actionTypes, initialize, change, reset, unregisterField
} from 'redux-form/immutable';

import { getFormChangeDetails, createActionPatternForForm } from '../utils/reduxFormUtils';

import {
    NEW_ITEM_FORM_NAME,
    initialNewItem,
    getInitialNewItemFormValues
} from './newItemForm';
import {
    fieldChanged,
    formInitialized,
    formReset,
    SET_DEFAULT_FIELD_VALUES,
    DEFAULT_FIELD_VALUES_UPDATED,
    SET_INITIAL_VALUES,
    CHANGE_VALUE,
    RESET_VALUES,
    REMOVE_VALUE
} from './newItemFormModule';


const newItemFormActionPattern = createActionPatternForForm(NEW_ITEM_FORM_NAME);


function* setInitialValues({ defaultValues }) {
    const initialValues = yield select(getInitialNewItemFormValues);
    
    
    const updatedInitialValues = typeof initialValues !== 'undefined'
        ? initialValues.merge(defaultValues)
        : initialNewItem.merge(defaultValues);

    yield put(
        initialize(
            NEW_ITEM_FORM_NAME,
            updatedInitialValues,
            { keepDirty: true, keepSubmitSucceeded: true, keepValues: true }
        )
    );
}


function* removeInitialValue({ fieldName }) {
    let initialValues = yield select(getInitialNewItemFormValues);
    if (typeof initialValues !== 'undefined' && initialValues.has(fieldName)) {
        initialValues = initialValues.delete(fieldName);
        yield put(
            initialize(
                NEW_ITEM_FORM_NAME,
                initialValues,
                { keepDirty: false, keepSubmitSucceeded: true, keepValues: true }
            )
        );
    }
}


function* changeValue({ fieldName, value }) {
    yield put(change(NEW_ITEM_FORM_NAME, fieldName, value));
}


function* removeValue({ fieldName }) {
    yield put(unregisterField(NEW_ITEM_FORM_NAME, fieldName));
    
    yield call(removeInitialValue, { fieldName });
}


function* setDefaultFieldValues({ defaultValues, updateValues }) {
    
    yield call(setInitialValues, { defaultValues });

    
    
    for (const [fieldName, value] of defaultValues.entries()) {
        
        if (updateValues) {
            yield call(changeValue, { fieldName, value });
        }
    }

    yield put({ type: DEFAULT_FIELD_VALUES_UPDATED });
}


function* resetValues() {
    yield put(reset(NEW_ITEM_FORM_NAME));
}


export function* newItemFormAdapterWatcher() {
    yield takeEvery(SET_DEFAULT_FIELD_VALUES, setDefaultFieldValues);
    yield takeEvery(SET_INITIAL_VALUES, setInitialValues);
    yield takeEvery(CHANGE_VALUE, changeValue);
    yield takeEvery(REMOVE_VALUE, removeValue);
    yield takeEvery(RESET_VALUES, resetValues);
}


export function* newItemFormActionWatcher() {
    
    yield takeEvery(newItemFormActionPattern(actionTypes.CHANGE), function* handleFormChange(action) {
        const changeDetails = yield call(getFormChangeDetails, action);
        yield put(fieldChanged(changeDetails.field, changeDetails.value));
    });

    
    yield takeEvery(newItemFormActionPattern(actionTypes.INITIALIZE), function* handleFormInitialize() {
        yield put(formInitialized());
    });

    
    yield takeEvery(newItemFormActionPattern(actionTypes.RESET), function* handleFormReset() {
        yield put(formReset());
    });
}
