import { List as IList } from 'immutable';
import { createSelector } from 'reselect';

import { getFlowProgress, getPreferredClassicGift, getPreferredProgramCode } from '../routing/flowSelectors';
import { PROGRAM_CODE } from '../item/newItemForm';

import { getShowProgramSelection } from './programListSelectors';
import { getPublishedProgramsList } from './programSelectors';
import { PREFERRED_CLASSIC_GIFT } from '../routing/flowProgressModule';



export const programStepCompleted = createSelector(
    getFlowProgress,
    getShowProgramSelection,
    (flowState, showProgramSelection) => !showProgramSelection
        || flowState.has(PROGRAM_CODE)
        || flowState.has(PREFERRED_CLASSIC_GIFT)
);

export const programStepPostConditions = IList([
    programStepCompleted
]);

export const programStepSkippable = createSelector(
    getPreferredProgramCode,
    getPublishedProgramsList,
    getPreferredClassicGift,
    (preferredProgramCode, programConfigs, preferredClassicGift) => (
        (!!preferredClassicGift
            || (!!preferredProgramCode
                && programConfigs.some(program => program.get('programCode') === preferredProgramCode)))
    )
);
