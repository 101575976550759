import { getHomePath } from '../routing/flowSelectors';

import { CART_STATUS } from './cartModule';
import { getCartStatus } from './cartSelectors';
import { cartPostConditions, cartStepSkippable } from './cartStepSelectors';



const cartStepConfig = {
    
    
    component: () => import('./Cart'),
    flow: 'postBuy',
    hideCartLink: false,
    flowProps: (state) => {
        const cartStatus = getCartStatus(state);

        return {
            cartStatus
        };
    },
    isReady: (state, ownProps) => {
        const { cartStatus } = ownProps;
        return cartStatus !== CART_STATUS.PROCESSING;
    },
    canLoad: (state, ownProps) => {
        const { cartStatus } = ownProps;
        let isAllowedOrPath = false;
        if (cartStatus === CART_STATUS.INVALID) {
            isAllowedOrPath = getHomePath(state);
        
        
        
        } else if (cartStatus === CART_STATUS.REDIRECT_TO_DESIGN) {
            isAllowedOrPath = '/store/design';
        } else {
            isAllowedOrPath = true;
        }
        
        return { isAllowedOrPath };
    },
    postConditions: cartPostConditions,
    
    
    getSubmitMessage: null,
    canSkip: state => (
        cartStepSkippable(state)
    )
};

export default cartStepConfig;
