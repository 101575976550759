import { List as IList } from 'immutable';

import { createSelector } from 'reselect';
import { programStepCompleted } from '../program/programStepSelectors';
import { cardTypeStepCompleted } from '../cardType/cardTypeStepSelectors';
import { getFlowProgress, getPreferredFaceplateCode } from '../routing/flowSelectors';
import { FACEPLATE_FIELD } from '../item/newItemForm';
import { canSkipDesignPageForASingleFaceplate } from '../faceplate/faceplateSelectors';
import { getSupportedUYO } from '../uyo/uyoSelectors';


export const designStepCompleted = createSelector(
    getFlowProgress,
    flowState => flowState.has(FACEPLATE_FIELD)
);


export const designStepPreConditions = IList([
    programStepCompleted,
    cardTypeStepCompleted
]);


export const designStepPostConditions = IList([
    designStepCompleted
]);

export const designStepSkippable = createSelector(
    getPreferredFaceplateCode,
    canSkipDesignPageForASingleFaceplate,
    getSupportedUYO,
    (preferredFaceplateCode, singleFaceplate, supportedUYO) => {
        if (preferredFaceplateCode) {
            return true;
        }
        if (supportedUYO.find(value => value)) {
            return false;
        }
        if (singleFaceplate) {
            return true;
        }
        return false;
    }
);
