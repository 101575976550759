import { push } from '../../routing/routing';
import {
    getUYOPath,
    getDesignPath
} from '../../routing/flow';

import { getSupportedUYO } from '../uyoSelectors';
import { uyoSelectStepPostConditions, uyoSelectStepPreConditions } from './uyoSelectStepSelectors';
import { brandCatalogIsLoaded, preferencesAreLoaded } from '../../brand/brandSelectors';



const uyoStepConfig = {
    component: () => import('./UYOSelectStep'),
    flow: 'buy',
    isReady: (state) => {
        const preferencesLoaded = preferencesAreLoaded(state);
        const catalogsLoaded = brandCatalogIsLoaded(state);
        return preferencesLoaded && catalogsLoaded;
    },
    flowProps: (state, ownProps) => {
        const { isPlastic } = ownProps;

        const supportedUYO = getSupportedUYO(state);
        const isPhotoSupported = isPlastic
            ? supportedUYO.get('supportsUYOPlasticPhoto')
            : supportedUYO.get('supportsUYOEgcPhoto');
        const isVideoSupported = !isPlastic ? supportedUYO.get('supportsUYOEgcVideo') : false;

        const uyoBasePath = getUYOPath();

        const goBackPath = `${uyoBasePath}-init`;
        const submitPath = `${uyoBasePath}-review`;

        return {
            isPlastic,
            isPhotoSupported,
            isVideoSupported,
            submitPath,
            goBack: () => { push({ pathname: goBackPath }); },
            goToDesign: () => { push({ pathname: getDesignPath() }); },
            isUYO: true
        };
    },
    canLoad: (state, ownProps) => {
        const { isPhotoSupported, isVideoSupported } = ownProps;
        return { isAllowedOrPath: (isPhotoSupported || isVideoSupported) || getDesignPath() };
    },
    preConditions: uyoSelectStepPreConditions,
    postConditions: uyoSelectStepPostConditions
};

export default uyoStepConfig;
