import { createSelector } from 'reselect';

import { getDefaultOptInValue } from '../brand/brandSelectors';
import { SET_SELF_BUY_SMS_DELIVERY } from './senderModule';


export const getSenderData = state => state.get('sender');

export const getSenderOptIn = createSelector(
    getSenderData, getDefaultOptInValue,
    (sender, defaultVal) => {
        const selectedVal = sender.get('optIn');
        
        
        if (typeof selectedVal === 'undefined') {
            return defaultVal;
        }
        return selectedVal;
    }
);

export const getSenderSMSDelivery = createSelector(
    getSenderData,
    sender => sender && sender.get(SET_SELF_BUY_SMS_DELIVERY)
);
