import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Map as IMap } from 'immutable';
import { Transition } from 'cstar-react-primitives/lib/redux-form/animate/Transition';
import { OutsideClickWrap } from 'cstar-react-primitives/lib/redux-form/OutsideClickWrap';

import TimeSelects from './TimeSelects';
import TimeInputField from './TimeInputField';


export const TimeFields = ({
    intl,
    cashbotName,
    hour,
    onHourChange,
    minute,
    onMinuteChange,
    meridiem,
    onMeridiemChange,
    timeZone
}) => {
    const [hasTimeSelects, setShowSelects] = React.useState(true);

    const timeInputProps = {
        intl,
        cashbotName,
        timeZone,
        onFocus: () => setShowSelects(true)
    };
    const selectProps = {
        intl,
        cashbotName,
        hour,
        onHourChange,
        minute,
        onMinuteChange,
        meridiem,
        onMeridiemChange,
        setShowSelects,
        key: `picker-selects-{hasTimeSelects}`
    };
    const closeSelects = () => setShowSelects(false);

    if (!hasTimeSelects) {
        return <TimeInputField {...timeInputProps} />;
    }
    return (
        <OutsideClickWrap onOutsideClick={closeSelects}>
            <TimeInputField {...timeInputProps} doFocus />
            <Transition>
                { hasTimeSelects && <TimeSelects {...selectProps} /> }
            </Transition>
        </OutsideClickWrap>
    );
};

TimeFields.propTypes = {
    intl: intlShape.isRequired,
    cashbotName: PropTypes.string.isRequired,
    hour: PropTypes.number.isRequired,
    onHourChange: PropTypes.func.isRequired,
    minute: PropTypes.number.isRequired,
    onMinuteChange: PropTypes.func.isRequired,
    meridiem: PropTypes.string.isRequired,
    onMeridiemChange: PropTypes.func.isRequired,
    timeZone: PropTypes.instanceOf(IMap).isRequired
};

export default TimeFields;
