import numbersOnlyNormalizer from './numbersOnlyNormalizer';


const postalCodeNormalizerJP = (value) => {
    if (!value || typeof value !== 'string') return value;
    let normalized = numbersOnlyNormalizer(value);
    if (normalized.length > 3) {
        normalized = `${normalized.slice(0, 3)}-${normalized.slice(3, 7)}`;
    }
    return normalized;
};

export default postalCodeNormalizerJP;
