import React from 'react';
import ReactDOM, { render } from 'react-dom';
import { BRAND_CONFIG_FIELDS } from 'capi';
import { fetchBrandConfig } from 'capi/redux';
import { runSaga } from 'redux-saga';

import App from './app/App';
import appStore from './app/appStore';
import loadPolyfills from './app/polyfills';
import { initializeIntl } from './intl/intlSagas';
import initializeIovation from './iovation/iovationModule';
import getWebfonts from './fontloaders/fontModule';
import { getInitialPromo } from './promo/promoModule';
import { getOrCreateStorefrontSession } from './session/sessionModule';
import { getCurrentLocationStepConfig } from './routing/flowSelectors';
import {
    createLocationChangeActionDispatcher,
    history
} from './routing/routing';
import { changeLocation } from './routing/routingModule';

if (window.BOOTSTRAP_DATA.staticAssetUrl) {
    
    __webpack_public_path__ = window.BOOTSTRAP_DATA.staticAssetUrl; 
}


const run = async () => {
    

    
    if (process.env.NODE_ENV === 'development') {
        
        const axe = require('@axe-core/react');
        axe(React, ReactDOM, 1000);

        
        const whyDidYouRender = require('@welldone-software/why-did-you-render');
        whyDidYouRender(React);
    }

    
    const sessionAction = getOrCreateStorefrontSession();
    appStore.dispatch(sessionAction);

    
    const requiredFields = [
        BRAND_CONFIG_FIELDS.PREFERENCES,
        BRAND_CONFIG_FIELDS.CATALOG,
        BRAND_CONFIG_FIELDS.PAYMENT,
        BRAND_CONFIG_FIELDS.SHIPPING,
        BRAND_CONFIG_FIELDS.FEATURES,
        BRAND_CONFIG_FIELDS.BALANCE,
        BRAND_CONFIG_FIELDS.META,
        BRAND_CONFIG_FIELDS.PROGRAMS,
        BRAND_CONFIG_FIELDS.ACCESSORIES,
        BRAND_CONFIG_FIELDS.LIMITS
    ];
    const configAction = fetchBrandConfig(requiredFields);
    appStore.dispatch(configAction);

    const sagaArgs = { dispatch: appStore.dispatch, getState: appStore.getState };
    await Promise.all([
        
        getWebfonts(appStore),
        
        
        
        (runSaga(sagaArgs, initializeIntl)).done
    ]);

    
    createLocationChangeActionDispatcher(appStore);
    
    
    
    
    if (getCurrentLocationStepConfig(appStore.getState()).get('component')) {
        
        
        
        appStore.dispatch(changeLocation(history.location));
    }

    
    const activeOrDefaultPromoAction = getInitialPromo();
    appStore.dispatch(activeOrDefaultPromoAction);

    
    const mountNode = document.querySelector('#app-root');
    
    render(<App history={history} />, mountNode);

    
    
    
    initializeIovation(appStore);
};

loadPolyfills().then(() => {
    run();
});
