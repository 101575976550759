import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Field } from 'redux-form/immutable';
import itemMessages from '../item/itemMessages';

const CheckBox = ({
    intl, cashbotName, setUpdate, isChecked, name, label
}) => {
    const newCheckbox = {
        name,
        label: intl.formatMessage(label),
        helper: intl.formatMessage(label),
        component: 'input',
        cashbotName,
        checked: isChecked
    };
    return (
        <div
            className='edelivery-container'
        >
            {}
            <label className="edelivery-label">
            {}
                <Field
                    onChange={(e) => { setUpdate(e.target.checked); }}
                    type='checkbox'
                    {...newCheckbox}
                />
                <span>{newCheckbox.label}</span>
            </label>
        </div>
    );
};

CheckBox.defaultProps = {
    label: itemMessages.recipientSMSLabel,
    setUpdate: null,
    name: 'default',
    isChecked: false
};

CheckBox.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    label: PropTypes.object,
    setUpdate: PropTypes.func,
    intl: intlShape.isRequired,
    name: PropTypes.string,
    isChecked: PropTypes.bool
};

export default CheckBox;
