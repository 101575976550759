import { fromJS } from 'immutable';


export const LOYALTY_FIELD = 'loyaltyNumber';
export const LOYALTY_NUMBER_MAX_LENGTH = 20;
export const LOYALTY_HELP_MESSAGE_ID = 'loyalty.loyaltyMessages.loyaltyHelpText';


export const SET_LOYALTY_NUMBER = '@@storefront/SET_LOYALTY_NUMBER';

export const initialState = fromJS({
    showLoyaltyInputOnPaymentStep: true,
    loyaltyNumber: ''
});


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_LOYALTY_NUMBER:
            return state
                .set('loyaltyNumber', action.loyaltyNumber)
                .set('showLoyaltyInputOnPaymentStep', action.showLoyaltyInputOnPaymentStep);
        default:
    }
    return state;
}



export function setLoyaltyNumber(loyaltyNumber) {
    return {
        type: SET_LOYALTY_NUMBER,
        loyaltyNumber,
        showLoyaltyInputOnPaymentStep: !loyaltyNumber 
    };
}


export function resetLoyalty() {
    return setLoyaltyNumber('');
}
