import shippingMethodMessages from './shippingMethodMessages';
import {
    getCurrentShippingMethodProfile,
    getMethodStatus,
    getMethodErrorStatus
} from '../shipping/shippingSelectors';
import { METHOD_REQUEST_STATES } from '../shipping/shippingModule';
import { switchCardIsPlastic } from '../cardType/cardTypeModule';
import { getDesignPath } from '../routing/flow';
import { isStillFetchingPromoRewards } from '../promo/promoSelectors';
import {
    shippingMethodStepPostConditions,
    shippingMethodStepPreConditions,
    shippingMethodStepSkippable
} from './shippingMethodStepSelectors';



const shippingMethodStepConfig = {
    component: () => import('./ShippingMethodStep'),
    flow: 'buy',
    flowProps: (state, ownProps) => {
        const { isPlastic, isSelfBuy, submitPath } = ownProps;

        const profile = getCurrentShippingMethodProfile(state);
        const shippingMethodStatus = getMethodStatus(state, profile);
        const shippingMethodError = getMethodErrorStatus(state, profile);

        return {
            
            submitPath: shippingMethodStatus === METHOD_REQUEST_STATES.get('FAILED') ? getDesignPath() : submitPath,
            isPlastic,
            isSelfBuy,
            profile,
            shippingMethodStatus,
            shippingMethodError
        };
    },
    isReady: (state, ownProps) => {
        const { shippingMethodStatus } = ownProps;

        
        
        const isStillFetching = typeof shippingMethodStatus === 'undefined'
            || shippingMethodStatus === METHOD_REQUEST_STATES.get('ACTIVE');

        return !isStillFetching
            && !isStillFetchingPromoRewards(state);
    },
    onExit: (dispatch, ownProps) => {
        const { shippingMethodStatus } = ownProps;

        
        
        if (shippingMethodStatus === METHOD_REQUEST_STATES.get('FAILED')) {
            dispatch(switchCardIsPlastic(false));
        }
    },
    preConditions: shippingMethodStepPreConditions,
    postConditions: shippingMethodStepPostConditions,
    getSubmitMessage: () => shippingMethodMessages.submitButtonText,
    canSkip: state => (
        shippingMethodStepSkippable(state)
    )
};

export default shippingMethodStepConfig;
