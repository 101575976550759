import { List as IList, Map as IMap } from 'immutable';
import { v4 as uuid } from 'uuid';


export const NOTIFICATION_ADD = '@@storefront/NOTIFICATION_ADD';
export const NOTIFICATION_CREATE = '@@storefront/NOTIFICATION_CREATE';
export const NOTIFICATION_REMOVE = '@@storefront/NOTIFICATION_REMOVE';


export const ITEM_UNDO = 'item-undo';

export const initialNotifyState = IList();


export default function notifyDisplayReducer(state = initialNotifyState, action) {
    switch (action.type) {
        case NOTIFICATION_ADD:
            return state.push(action.notification);
        case NOTIFICATION_REMOVE:
            return state.filterNot(map => map.get('uid') === action.uid);
        default:
            return state;
    }
}




export function createNotification(type, ...rest) {
    return {
        type: NOTIFICATION_CREATE,
        notification: IMap({
            uid: uuid(),
            type,
            ...rest
        })
    };
}


export function removeNotification(uid) {
    return {
        type: NOTIFICATION_REMOVE,
        uid
    };
}


export function addNotification(notification) {
    return {
        type: NOTIFICATION_ADD,
        notification
    };
}
