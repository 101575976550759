import React, { useEffect, useState } from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';

import mailcheck from 'mailcheck';

import { LinkButton } from 'cstar-react-primitives/lib/redux-form/buttons/LinkButton';

import * as deliveryConstants from '../delivery/deliveryConstants';
import { isValidEmail } from '../validate/email';
import emptyFunc from '../utils/emptyFunc';

const EmailDomainSpellCheckAlert = ({
    email,
    intl,
    cashbotName,
    ariaDescribedby,
    message,
    doFocus,
    handleEmailChange
}) => {
    const [suggestion, setSuggestion] = useState(null);

    useEffect(() => {
        mailcheck.run({
            email,
            domains: deliveryConstants.DEFAULT_DOMAINS,
            secondLevelDomains: deliveryConstants.DEFAULT_SECOND_LEVEL_DOMAINS,
            suggested: setSuggestion,
            empty: () => {
                setSuggestion(null);
            }
        });
    }, [email]);

    const linkProps = {
        doFocus,
        cashbotName,
        className: 'email-domain-spell-check-prompt',
        isCentered: false,
        atts: {
            'aria-expanded': 'false',
            'aria-describedby': ariaDescribedby
        }
    };

    return (
        isValidEmail(email) && suggestion && (
            <div className='email-domain-spell-check-prompt'>
                <LinkButton {...linkProps} onClick={() => handleEmailChange(suggestion.full)}>
                    <Markdown>{intl.formatMessage(message, { suggestion: suggestion.full })}</Markdown>
                </LinkButton>
            </div>
        )
    );
};

EmailDomainSpellCheckAlert.defaultProps = {
    email: '',
    doFocus: false,
    ariaDescribedby: null,
    handleEmailChange: emptyFunc
};

EmailDomainSpellCheckAlert.propTypes = {
    email: PropTypes.string,
    intl: intlShape.isRequired,
    cashbotName: PropTypes.string.isRequired,
    doFocus: PropTypes.bool,
    ariaDescribedby: PropTypes.string,
    message: PropTypes.object.isRequired,
    
    handleEmailChange: PropTypes.func
};

EmailDomainSpellCheckAlert.displayName = 'EmailDomainSpellCheckAlert';

export default EmailDomainSpellCheckAlert;
