import { defineMessages } from 'react-intl';


const countryNameMap = defineMessages({
    AX: {
        id: 'country.countryNameMap.AlandIslands.AX',
        description: 'country label for Aland Islands',
        defaultMessage: 'Aland Islands'
    },
    AF: {
        id: 'country.countryNameMap.Afghanistan.AF',
        description: 'country label for Afghanistan',
        defaultMessage: 'Afghanistan'
    },
    AL: {
        id: 'country.countryNameMap.Albania.AL',
        description: 'country label for Albania',
        defaultMessage: 'Albania'
    },
    DZ: {
        id: 'country.countryNameMap.Algeria.DZ',
        description: 'country label for Algeria',
        defaultMessage: 'Algeria'
    },
    AS: {
        id: 'country.countryNameMap.AmericanSamoa.AS',
        description: 'country label for American Samoa',
        defaultMessage: 'American Samoa'
    },
    AD: {
        id: 'country.countryNameMap.Andorra.AD',
        description: 'country label for Andorra',
        defaultMessage: 'Andorra'
    },
    AO: {
        id: 'country.countryNameMap.Angola.AO',
        description: 'country label for Angola',
        defaultMessage: 'Angola'
    },
    AI: {
        id: 'country.countryNameMap.Anguilla.AI',
        description: 'country label for Anguilla',
        defaultMessage: 'Anguilla'
    },
    AQ: {
        id: 'country.countryNameMap.Antarctica.AQ',
        description: 'country label for Antarctica',
        defaultMessage: 'Antarctica'
    },
    AG: {
        id: 'country.countryNameMap.Antigua.AG',
        description: 'country label for Antigua',
        defaultMessage: 'Antigua and Barbuda'
    },
    AR: {
        id: 'country.countryNameMap.Argentina.AR',
        description: 'country label for Argentina',
        defaultMessage: 'Argentina'
    },
    AM: {
        id: 'country.countryNameMap.Armenia.AM',
        description: 'country label for Armenia',
        defaultMessage: 'Armenia'
    },
    AW: {
        id: 'country.countryNameMap.Aruba.AW',
        description: 'country label for Aruba',
        defaultMessage: 'Aruba'
    },
    AU: {
        id: 'country.countryNameMap.Australia.AU',
        description: 'country label for Australia',
        defaultMessage: 'Australia'
    },
    AT: {
        id: 'country.countryNameMap.Austria.AT',
        description: 'country label for Austria',
        defaultMessage: 'Austria'
    },
    AZ: {
        id: 'country.countryNameMap.Azerbaijan.AZ',
        description: 'country label for Azerbaijan',
        defaultMessage: 'Azerbaijan'
    },
    BS: {
        id: 'country.countryNameMap.Bahamas.BS',
        description: 'country label for Bahamas',
        defaultMessage: 'Bahamas'
    },
    BH: {
        id: 'country.countryNameMap.Bahrain.BH',
        description: 'country label for Bahrain',
        defaultMessage: 'Bahrain'
    },
    BD: {
        id: 'country.countryNameMap.Bangladesh.BD',
        description: 'country label for Bangladesh',
        defaultMessage: 'Bangladesh'
    },
    BB: {
        id: 'country.countryNameMap.Barbados.BB',
        description: 'country label for Barbados',
        defaultMessage: 'Barbados'
    },
    BY: {
        id: 'country.countryNameMap.Belarus.BY',
        description: 'country label for Belarus',
        defaultMessage: 'Belarus'
    },
    BE: {
        id: 'country.countryNameMap.Belgium.BE',
        description: 'country label for Belgium',
        defaultMessage: 'Belgium'
    },
    BZ: {
        id: 'country.countryNameMap.Belize.BZ',
        description: 'country label for Belize',
        defaultMessage: 'Belize'
    },
    BJ: {
        id: 'country.countryNameMap.Benin.BJ',
        description: 'country label for Benin',
        defaultMessage: 'Benin'
    },
    BM: {
        id: 'country.countryNameMap.Bermuda.BM',
        description: 'country label for Bermuda',
        defaultMessage: 'Bermuda'
    },
    BT: {
        id: 'country.countryNameMap.Bhutan.BT',
        description: 'country label for Bhutan',
        defaultMessage: 'Bhutan'
    },
    BO: {
        id: 'country.countryNameMap.Bolivia.BO',
        description: 'country label for Bolivia',
        defaultMessage: 'Bolivia'
    },
    BQ: {
        id: 'country.countryNameMap.BonaireSintEustatiusSaba.BQ',
        description: 'country label for Bonaire, Sint Eustatius and Saba',
        defaultMessage: 'Bonaire, Sint Eustatius and Saba'
    },
    BW: {
        id: 'country.countryNameMap.Botswana.BW',
        description: 'country label for Botswana',
        defaultMessage: 'Botswana'
    },
    BA: {
        id: 'country.countryNameMap.Bosnia.BA',
        description: 'country label for Bosnia and Herzegovina',
        defaultMessage: 'Bosnia and Herzegovina'
    },
    BV: {
        id: 'country.countryNameMap.BouvetIsland.BV',
        description: 'country label for Bouvet Island',
        defaultMessage: 'Bouvet Island'
    },
    BR: {
        id: 'country.countryNameMap.Brazil.BR',
        description: 'country label for Brazil',
        defaultMessage: 'Brazil'
    },
    IO: {
        id: 'country.countryNameMap.BritishIndianOceanTerritory.IO',
        description: 'country label for British Indian Ocean Territory',
        defaultMessage: 'British Indian Ocean Territory'
    },
    VG: {
        id: 'country.countryNameMap.BritishVirginIs.VG',
        description: 'country label for British Virgin Is.',
        defaultMessage: 'British Virgin Is.'
    },
    BN: {
        id: 'country.countryNameMap.Brunei.BN',
        description: 'country label for Brunei',
        defaultMessage: 'Brunei'
    },
    BG: {
        id: 'country.countryNameMap.Bulgaria.BG',
        description: 'country label for Bulgaria',
        defaultMessage: 'Bulgaria'
    },
    BF: {
        id: 'country.countryNameMap.BurkinoFaso.BF',
        description: 'country label for Burkino Faso',
        defaultMessage: 'Burkino Faso'
    },
    BI: {
        id: 'country.countryNameMap.Burundi.BI',
        description: 'country label for Burundi',
        defaultMessage: 'Burundi'
    },
    KH: {
        id: 'country.countryNameMap.Cambodia.KH',
        description: 'country label for Cambodia',
        defaultMessage: 'Cambodia'
    },
    CM: {
        id: 'country.countryNameMap.Cameroon.CM',
        description: 'country label for Cameroon',
        defaultMessage: 'Cameroon'
    },
    CA: {
        id: 'country.countryNameMap.Canada.CA',
        description: 'country label for Canada',
        defaultMessage: 'Canada'
    },
    CV: {
        id: 'country.countryNameMap.CapeVerde.CV',
        description: 'country label for Cape Verde',
        defaultMessage: 'Cape Verde'
    },
    KY: {
        id: 'country.countryNameMap.CaymanIslands.KY',
        description: 'country label for Cayman Islands',
        defaultMessage: 'Cayman Islands'
    },
    CF: {
        id: 'country.countryNameMap.CentralAfrican.CF',
        description: 'country label for Central African',
        defaultMessage: 'Central African'
    },
    TD: {
        id: 'country.countryNameMap.Chad.TD',
        description: 'country label for Chad',
        defaultMessage: 'Chad'
    },
    CL: {
        id: 'country.countryNameMap.Chile.CL',
        description: 'country label for Chile',
        defaultMessage: 'Chile'
    },
    CN: {
        id: 'country.countryNameMap.China.CN',
        description: 'country label for China',
        defaultMessage: 'China'
    },
    CX: {
        id: 'country.countryNameMap.ChristmasIsland.CX',
        description: 'country label for Christmas Island',
        defaultMessage: 'Christmas Island'
    },
    CO: {
        id: 'country.countryNameMap.Colombia.CO',
        description: 'country label for Colombia',
        defaultMessage: 'Colombia'
    },
    KM: {
        id: 'country.countryNameMap.Comoros.KM',
        description: 'country label for Comoros',
        defaultMessage: 'Comoros'
    },
    CG: {
        id: 'country.countryNameMap.Congo.CG',
        description: 'country label for Congo',
        defaultMessage: 'Congo'
    },
    CD: {
        id: 'country.countryNameMap.TheRepublicOfCongo.CD',
        description: 'country label for The Republic of Congo',
        defaultMessage: 'Congo, The Republic of'
    },
    CC: {
        id: 'country.countryNameMap.Cocos(Keeling)Islands.CC',
        description: 'country label for Cocos (Keeling) Islands',
        defaultMessage: 'Cocos (Keeling) Islands'
    },
    CK: {
        id: 'country.countryNameMap.CookIslands.CK',
        description: 'country label for Cook Islands',
        defaultMessage: 'Cook Islands'
    },
    CR: {
        id: 'country.countryNameMap.CostaRica.CR',
        description: 'country label for Costa Rica',
        defaultMessage: 'Costa Rica'
    },
    HR: {
        id: 'country.countryNameMap.Croatia.HR',
        description: 'country label for Croatia',
        defaultMessage: 'Croatia'
    },
    CU: {
        id: 'country.countryNameMap.Cuba.CU',
        description: 'country label for Cuba',
        defaultMessage: 'Cuba'
    },
    CW: {
        id: 'country.countryNameMap.Curaçao.CW',
        description: 'country label for Curaçao',
        defaultMessage: 'Curaçao'
    },
    CY: {
        id: 'country.countryNameMap.Cyprus.CY',
        description: 'country label for Cyprus',
        defaultMessage: 'Cyprus'
    },
    CZ: {
        id: 'country.countryNameMap.CzechRepublic.CZ',
        description: 'country label for Czech Republic',
        defaultMessage: 'Czech Republic'
    },
    DK: {
        id: 'country.countryNameMap.Denmark.DK',
        description: 'country label for Denmark',
        defaultMessage: 'Denmark'
    },
    DJ: {
        id: 'country.countryNameMap.Djibouti.DJ',
        description: 'country label for Djibouti',
        defaultMessage: 'Djibouti'
    },
    DM: {
        id: 'country.countryNameMap.Dominica.DM',
        description: 'country label for Dominica',
        defaultMessage: 'Dominica'
    },
    DO: {
        id: 'country.countryNameMap.DominicanRepublic.DO',
        description: 'country label for Dominican Republic',
        defaultMessage: 'Dominican Republic'
    },
    EC: {
        id: 'country.countryNameMap.Ecuador.EC',
        description: 'country label for Ecuador',
        defaultMessage: 'Ecuador'
    },
    EG: {
        id: 'country.countryNameMap.Egypt.EG',
        description: 'country label for Egypt',
        defaultMessage: 'Egypt'
    },
    SV: {
        id: 'country.countryNameMap.ElSalvador.SV',
        description: 'country label for El Salvador',
        defaultMessage: 'El Salvador'
    },
    GQ: {
        id: 'country.countryNameMap.EquatorialGuinea.GQ',
        description: 'country label for Equatorial Guinea',
        defaultMessage: 'Equatorial Guinea'
    },
    ER: {
        id: 'country.countryNameMap.Eritrea.ER',
        description: 'country label for Eritrea',
        defaultMessage: 'Eritrea'
    },
    EE: {
        id: 'country.countryNameMap.Estonia.EE',
        description: 'country label for Estonia',
        defaultMessage: 'Estonia'
    },
    ET: {
        id: 'country.countryNameMap.Ethiopia.ET',
        description: 'country label for Ethiopia',
        defaultMessage: 'Ethiopia'
    },
    FO: {
        id: 'country.countryNameMap.FaeroeIslands.FO',
        description: 'country label for Faeroe Islands',
        defaultMessage: 'Faeroe Islands'
    },
    FK: {
        id: 'country.countryNameMap.FalklandIslands.FK',
        description: 'country label for Falkland Islands',
        defaultMessage: 'Falkland Islands (Malvinas)'
    },
    FJ: {
        id: 'country.countryNameMap.Fiji.FJ',
        description: 'country label for Fiji',
        defaultMessage: 'Fiji'
    },
    FI: {
        id: 'country.countryNameMap.Finland.FI',
        description: 'country label for Finland',
        defaultMessage: 'Finland'
    },
    FR: {
        id: 'country.countryNameMap.France.FR',
        description: 'country label for France',
        defaultMessage: 'France'
    },
    GF: {
        id: 'country.countryNameMap.FrenchGuiana.GF',
        description: 'country label for French Guiana',
        defaultMessage: 'French Guiana'
    },
    PF: {
        id: 'country.countryNameMap.FrenchPolynesia.PF',
        description: 'country label for French Polynesia',
        defaultMessage: 'French Polynesia'
    },
    GA: {
        id: 'country.countryNameMap.Gabon.GA',
        description: 'country label for Gabon',
        defaultMessage: 'Gabon'
    },
    GM: {
        id: 'country.countryNameMap.Gambia.GM',
        description: 'country label for Gambia',
        defaultMessage: 'Gambia'
    },
    GE: {
        id: 'country.countryNameMap.RepublicOfGeorgia.GE',
        description: 'country label for Republic of Georgia',
        defaultMessage: 'Georgia, Republic of'
    },
    DE: {
        id: 'country.countryNameMap.Germany.DE',
        description: 'country label for Germany',
        defaultMessage: 'Germany'
    },
    GH: {
        id: 'country.countryNameMap.Ghana.GH',
        description: 'country label for Ghana',
        defaultMessage: 'Ghana'
    },
    GI: {
        id: 'country.countryNameMap.Gibraltar.GI',
        description: 'country label for Gibraltar',
        defaultMessage: 'Gibraltar'
    },
    GR: {
        id: 'country.countryNameMap.Greece.GR',
        description: 'country label for Greece',
        defaultMessage: 'Greece'
    },
    GL: {
        id: 'country.countryNameMap.Greenland.GL',
        description: 'country label for Greenland',
        defaultMessage: 'Greenland'
    },
    GD: {
        id: 'country.countryNameMap.Grenada.GD',
        description: 'country label for Grenada',
        defaultMessage: 'Grenada'
    },
    GP: {
        id: 'country.countryNameMap.Guadeloupe.GP',
        description: 'country label for Guadeloupe',
        defaultMessage: 'Guadeloupe'
    },
    GU: {
        id: 'country.countryNameMap.Guam.GU',
        description: 'country label for Guam',
        defaultMessage: 'Guam'
    },
    GT: {
        id: 'country.countryNameMap.Guatemala.GT',
        description: 'country label for Guatemala',
        defaultMessage: 'Guatemala'
    },
    GN: {
        id: 'country.countryNameMap.Guinea.GN',
        description: 'country label for Guinea',
        defaultMessage: 'Guinea'
    },
    GW: {
        id: 'country.countryNameMap.GuineaBissau.GW',
        description: 'country label for Guinea-Bissau',
        defaultMessage: 'Guinea-Bissau'
    },
    GG: {
        id: 'country.countryNameMap.Guernsey.GG',
        description: 'country label for Guernsey',
        defaultMessage: 'Guernsey'
    },
    GY: {
        id: 'country.countryNameMap.Guyana.GY',
        description: 'country label for Guyana',
        defaultMessage: 'Guyana'
    },
    HT: {
        id: 'country.countryNameMap.Haiti.HT',
        description: 'country label for Haiti',
        defaultMessage: 'Haiti'
    },
    HM: {
        id: 'country.countryNameMap.HeardIsland.HM',
        description: 'country label for Heard Island and McDonald Islands',
        defaultMessage: 'Heard Island and McDonald Islands'
    },
    HN: {
        id: 'country.countryNameMap.Honduras.HN',
        description: 'country label for Honduras',
        defaultMessage: 'Honduras'
    },
    HK: {
        id: 'country.countryNameMap.HongKong.HK',
        description: 'country label for Hong Kong',
        defaultMessage: 'Hong Kong'
    },
    HU: {
        id: 'country.countryNameMap.Hungary.HU',
        description: 'country label for Hungary',
        defaultMessage: 'Hungary'
    },
    IS: {
        id: 'country.countryNameMap.Iceland.IS',
        description: 'country label for Iceland',
        defaultMessage: 'Iceland'
    },
    IN: {
        id: 'country.countryNameMap.India.IN',
        description: 'country label for India',
        defaultMessage: 'India'
    },
    ID: {
        id: 'country.countryNameMap.Indonesia.ID',
        description: 'country label for Indonesia',
        defaultMessage: 'Indonesia'
    },
    IR: {
        id: 'country.countryNameMap.Iran.IR',
        description: 'country label for Iran',
        defaultMessage: 'Iran'
    },
    IQ: {
        id: 'country.countryNameMap.Iraq.IQ',
        description: 'country label for Iraq',
        defaultMessage: 'Iraq'
    },
    IE: {
        id: 'country.countryNameMap.Ireland.IE',
        description: 'country label for Ireland',
        defaultMessage: 'Ireland'
    },
    IM: {
        id: 'country.countryNameMap.IsleofMan.IM',
        description: 'country label for Isle of Man',
        defaultMessage: 'Isle of Man'
    },
    IL: {
        id: 'country.countryNameMap.Israel.IL',
        description: 'country label for Israel',
        defaultMessage: 'Israel'
    },
    IT: {
        id: 'country.countryNameMap.Italy.IT',
        description: 'country label for Italy',
        defaultMessage: 'Italy'
    },
    CI: {
        id: 'country.countryNameMap.IvoryCoast.CI',
        description: 'country label for Ivory Coast',
        defaultMessage: 'Ivory Coast'
    },
    JM: {
        id: 'country.countryNameMap.Jamaica.JM',
        description: 'country label for Jamaica',
        defaultMessage: 'Jamaica'
    },
    JP: {
        id: 'country.countryNameMap.Japan.JP',
        description: 'country label for Japan',
        defaultMessage: 'Japan'
    },
    JE: {
        id: 'country.countryNameMap.Jersey.JE',
        description: 'country label for Jersey',
        defaultMessage: 'Jersey'
    },
    JO: {
        id: 'country.countryNameMap.Jordan.JO',
        description: 'country label for Jordan',
        defaultMessage: 'Jordan'
    },
    KZ: {
        id: 'country.countryNameMap.Kazakhstan.KZ',
        description: 'country label for Kazakhstan',
        defaultMessage: 'Kazakhstan'
    },
    KE: {
        id: 'country.countryNameMap.Kenya.KE',
        description: 'country label for Kenya',
        defaultMessage: 'Kenya'
    },
    KI: {
        id: 'country.countryNameMap.Kiribati.KI',
        description: 'country label for Kiribati',
        defaultMessage: 'Kiribati'
    },
    KW: {
        id: 'country.countryNameMap.Kuwait.KW',
        description: 'country label for Kuwait',
        defaultMessage: 'Kuwait'
    },
    KG: {
        id: 'country.countryNameMap.Kyrgyzstan.KG',
        description: 'country label for Kyrgyzstan',
        defaultMessage: 'Kyrgyzstan'
    },
    LA: {
        id: 'country.countryNameMap.Laos.LA',
        description: 'country label for Laos',
        defaultMessage: 'Laos'
    },
    LV: {
        id: 'country.countryNameMap.Latvia.LV',
        description: 'country label for Latvia',
        defaultMessage: 'Latvia'
    },
    LB: {
        id: 'country.countryNameMap.Lebanon.LB',
        description: 'country label for Lebanon',
        defaultMessage: 'Lebanon'
    },
    LS: {
        id: 'country.countryNameMap.Lesotho.LS',
        description: 'country label for Lesotho',
        defaultMessage: 'Lesotho'
    },
    LR: {
        id: 'country.countryNameMap.Liberia.LR',
        description: 'country label for Liberia',
        defaultMessage: 'Liberia'
    },
    LY: {
        id: 'country.countryNameMap.Libya.LY',
        description: 'country label for Libya',
        defaultMessage: 'Libya'
    },
    LI: {
        id: 'country.countryNameMap.Liechtenstein.LI',
        description: 'country label for Liechtenstein',
        defaultMessage: 'Liechtenstein'
    },
    LT: {
        id: 'country.countryNameMap.Lithuania.LT',
        description: 'country label for Lithuania',
        defaultMessage: 'Lithuania'
    },
    LU: {
        id: 'country.countryNameMap.Luxembourg.LU',
        description: 'country label for Luxembourg',
        defaultMessage: 'Luxembourg'
    },
    MO: {
        id: 'country.countryNameMap.Macau.MO',
        description: 'country label for Macau',
        defaultMessage: 'Macau'
    },
    MK: {
        id: 'country.countryNameMap.Macedonia.MK',
        description: 'country label for Macedonia',
        defaultMessage: 'Macedonia'
    },
    MG: {
        id: 'country.countryNameMap.Madagascar.MG',
        description: 'country label for Madagascar',
        defaultMessage: 'Madagascar'
    },
    MW: {
        id: 'country.countryNameMap.Malawi.MW',
        description: 'country label for Malawi',
        defaultMessage: 'Malawi'
    },
    MY: {
        id: 'country.countryNameMap.Malaysia.MY',
        description: 'country label for Malaysia',
        defaultMessage: 'Malaysia'
    },
    MV: {
        id: 'country.countryNameMap.Maldives.MV',
        description: 'country label for Maldives',
        defaultMessage: 'Maldives'
    },
    ML: {
        id: 'country.countryNameMap.Mali.ML',
        description: 'country label for Mali',
        defaultMessage: 'Mali'
    },
    MT: {
        id: 'country.countryNameMap.Malta.MT',
        description: 'country label for Malta',
        defaultMessage: 'Malta'
    },
    MH: {
        id: 'country.countryNameMap.MarshallIslands.MH',
        description: 'country label for Marshall Islands',
        defaultMessage: 'Marshall Islands'
    },
    MQ: {
        id: 'country.countryNameMap.Martinique.MQ',
        description: 'country label for Martinique',
        defaultMessage: 'Martinique'
    },
    MR: {
        id: 'country.countryNameMap.Mauritania.MR',
        description: 'country label for Mauritania',
        defaultMessage: 'Mauritania'
    },
    MU: {
        id: 'country.countryNameMap.Mauritius.MU',
        description: 'country label for Mauritius',
        defaultMessage: 'Mauritius'
    },
    MX: {
        id: 'country.countryNameMap.Mexico.MX',
        description: 'country label for Mexico',
        defaultMessage: 'Mexico'
    },
    FM: {
        id: 'country.countryNameMap.Micronesia.FM',
        description: 'country label for Micronesia',
        defaultMessage: 'Micronesia'
    },
    MD: {
        id: 'country.countryNameMap.Moldova.MD',
        description: 'country label for Moldova',
        defaultMessage: 'Moldova'
    },
    MC: {
        id: 'country.countryNameMap.Monaco.MC',
        description: 'country label for Monaco',
        defaultMessage: 'Monaco'
    },
    MN: {
        id: 'country.countryNameMap.Mongolia.MN',
        description: 'country label for Mongolia',
        defaultMessage: 'Mongolia'
    },
    ME: {
        id: 'country.countryNameMap.Montenegro.ME',
        description: 'country label for Montenegro',
        defaultMessage: 'Montenegro'
    },
    MS: {
        id: 'country.countryNameMap.Montserrat.MS',
        description: 'country label for Montserrat',
        defaultMessage: 'Montserrat'
    },
    MA: {
        id: 'country.countryNameMap.Morocco.MA',
        description: 'country label for Morocco',
        defaultMessage: 'Morocco'
    },
    MZ: {
        id: 'country.countryNameMap.Mozambique.MZ',
        description: 'country label for Mozambique',
        defaultMessage: 'Mozambique'
    },
    MM: {
        id: 'country.countryNameMap.Myanmar.MM',
        description: 'country label for Myanmar',
        defaultMessage: 'Myanmar'
    },
    NA: {
        id: 'country.countryNameMap.Namibia.NA',
        description: 'country label for Namibia',
        defaultMessage: 'Namibia'
    },
    NR: {
        id: 'country.countryNameMap.Nauru.NR',
        description: 'country label for Nauru',
        defaultMessage: 'Nauru'
    },
    NP: {
        id: 'country.countryNameMap.Nepal.NP',
        description: 'country label for Nepal',
        defaultMessage: 'Nepal'
    },
    NL: {
        id: 'country.countryNameMap.Netherlands.NL',
        description: 'country label for Netherlands',
        defaultMessage: 'Netherlands'
    },
    AN: {
        id: 'country.countryNameMap.NetherlandsAntilles.AN',
        description: 'country label for Netherlands Antilles',
        defaultMessage: 'Netherlands Antilles'
    },
    NC: {
        id: 'country.countryNameMap.NewCaledonia.NC',
        description: 'country label for New Caledonia',
        defaultMessage: 'New Caledonia'
    },
    NZ: {
        id: 'country.countryNameMap.NewZealand.NZ',
        description: 'country label for New Zealand',
        defaultMessage: 'New Zealand'
    },
    NI: {
        id: 'country.countryNameMap.Nicaragua.NI',
        description: 'country label for Nicaragua',
        defaultMessage: 'Nicaragua'
    },
    NE: {
        id: 'country.countryNameMap.Niger.NE',
        description: 'country label for Niger',
        defaultMessage: 'Niger'
    },
    NG: {
        id: 'country.countryNameMap.Nigeria.NG',
        description: 'country label for Nigeria',
        defaultMessage: 'Nigeria'
    },
    NU: {
        id: 'country.countryNameMap.Niue.NU',
        description: 'country label for Niue',
        defaultMessage: 'Niue'
    },
    NF: {
        id: 'country.countryNameMap.Norfolk Island.NF',
        description: 'country label for Norfolk Island',
        defaultMessage: 'Norfolk Island'
    },
    KP: {
        id: 'country.countryNameMap.NorthKorea.KP',
        description: 'country label for North Korea',
        defaultMessage: 'North Korea'
    },
    NO: {
        id: 'country.countryNameMap.Norway.NO',
        description: 'country label for Norway',
        defaultMessage: 'Norway'
    },
    OM: {
        id: 'country.countryNameMap.Oman.OM',
        description: 'country label for Oman',
        defaultMessage: 'Oman'
    },
    PK: {
        id: 'country.countryNameMap.Pakistan.PK',
        description: 'country label for Pakistan',
        defaultMessage: 'Pakistan'
    },
    PW: {
        id: 'country.countryNameMap.Palau.PW',
        description: 'country label for Palau',
        defaultMessage: 'Palau'
    },
    PA: {
        id: 'country.countryNameMap.Panama.PA',
        description: 'country label for Panama',
        defaultMessage: 'Panama'
    },
    PG: {
        id: 'country.countryNameMap.PapuaNewGuinea.PG',
        description: 'country label for Papua New Guinea',
        defaultMessage: 'Papua New Guinea'
    },
    PY: {
        id: 'country.countryNameMap.Paraguay.PY',
        description: 'country label for Paraguay',
        defaultMessage: 'Paraguay'
    },
    PE: {
        id: 'country.countryNameMap.Peru.PE',
        description: 'country label for Peru',
        defaultMessage: 'Peru'
    },
    PH: {
        id: 'country.countryNameMap.Philippines.PH',
        description: 'country label for Philippines',
        defaultMessage: 'Philippines'
    },
    PN: {
        id: 'country.countryNameMap.Pitcairn.PN',
        description: 'country label for Pitcairn',
        defaultMessage: 'Pitcairn'
    },
    PL: {
        id: 'country.countryNameMap.Poland.PL',
        description: 'country label for Poland',
        defaultMessage: 'Poland'
    },
    PT: {
        id: 'country.countryNameMap.Portugal.PT',
        description: 'country label for Portugal',
        defaultMessage: 'Portugal'
    },
    QA: {
        id: 'country.countryNameMap.Qatar.QA',
        description: 'country label for Qatar',
        defaultMessage: 'Qatar'
    },
    RE: {
        id: 'country.countryNameMap.ReunionIsland.RE',
        description: 'country label for Reunion Island',
        defaultMessage: 'Reunion Island'
    },
    RO: {
        id: 'country.countryNameMap.Romania.RO',
        description: 'country label for Romania',
        defaultMessage: 'Romania'
    },
    RU: {
        id: 'country.countryNameMap.Russia.RU',
        description: 'country label for Russia',
        defaultMessage: 'Russia'
    },
    RW: {
        id: 'country.countryNameMap.Rwanda.RW',
        description: 'country label for Rwanda',
        defaultMessage: 'Rwanda'
    },
    BL: {
        id: 'country.countryNameMap.SaintBarthélemy.BA',
        description: 'country label for Saipan',
        defaultMessage: 'Saint Barthélemy'
    },
    MF: {
        id: 'country.countryNameMap.SaintMartin.MF',
        description: 'country label for Saint Martin',
        defaultMessage: 'Saint Martin'
    },
    PM: {
        id: 'country.countryNameMap.SaintPierreMiquelon.PM',
        description: 'country label for Saint Pierre and Miquelon',
        defaultMessage: 'Saint Pierre and Miquelon'
    },
    MP: {
        id: 'country.countryNameMap.Saipan.MP',
        description: 'country label for Saipan',
        defaultMessage: 'Saipan'
    },
    SM: {
        id: 'country.countryNameMap.SanMarino.SM',
        description: 'country label for San Marino',
        defaultMessage: 'San Marino'
    },
    SA: {
        id: 'country.countryNameMap.SaudiArabia.SA',
        description: 'country label for Saudi Arabia',
        defaultMessage: 'Saudi Arabia'
    },
    SN: {
        id: 'country.countryNameMap.Senegal.SN',
        description: 'country label for Senegal',
        defaultMessage: 'Senegal'
    },
    SC: {
        id: 'country.countryNameMap.Seychelles.SC',
        description: 'country label for Seychelles',
        defaultMessage: 'Seychelles'
    },
    SL: {
        id: 'country.countryNameMap.SierraLeone.SL',
        description: 'country label for Sierra Leone',
        defaultMessage: 'Sierra Leone'
    },
    SG: {
        id: 'country.countryNameMap.Singapore.SG',
        description: 'country label for Singapore',
        defaultMessage: 'Singapore'
    },
    SK: {
        id: 'country.countryNameMap.SlovakRepublic.SK',
        description: 'country label for Slovak Republic',
        defaultMessage: 'Slovak Republic'
    },
    SI: {
        id: 'country.countryNameMap.Slovenia.SI',
        description: 'country label for Slovenia',
        defaultMessage: 'Slovenia'
    },
    ZA: {
        id: 'country.countryNameMap.SouthAfrica.ZA',
        description: 'country label for South Africa',
        defaultMessage: 'South Africa'
    },
    GS: {
        id: 'country.countryNameMap.SouthGeorgia.GS',
        description: 'country label for South Georgia and the South Sandwich Islands',
        defaultMessage: 'South Georgia and the South Sandwich Islands'
    },
    KR: {
        id: 'country.countryNameMap.SouthKorea.KR',
        description: 'country label for South Korea',
        defaultMessage: 'South Korea'
    },
    ES: {
        id: 'country.countryNameMap.Spain.ES',
        description: 'country label for Spain',
        defaultMessage: 'Spain'
    },
    LK: {
        id: 'country.countryNameMap.SriLanka.LK',
        description: 'country label for Sri Lanka',
        defaultMessage: 'Sri Lanka'
    },
    KN: {
        id: 'country.countryNameMap.StKittsAndNevis.KN',
        description: 'country label for St. Kitts & Nevis',
        defaultMessage: 'St. Kitts & Nevis'
    },
    LC: {
        id: 'country.countryNameMap.StLucia.LC',
        description: 'country label for St. Lucia',
        defaultMessage: 'St. Lucia'
    },
    VC: {
        id: 'country.countryNameMap.StVincent.VC',
        description: 'country label for St. Vincent',
        defaultMessage: 'St. Vincent'
    },
    SR: {
        id: 'country.countryNameMap.Suriname.SR',
        description: 'country label for Suriname',
        defaultMessage: 'Suriname'
    },
    SZ: {
        id: 'country.countryNameMap.Swaziland.SZ',
        description: 'country label for Swaziland',
        defaultMessage: 'Swaziland'
    },
    SE: {
        id: 'country.countryNameMap.Sweden.SE',
        description: 'country label for Sweden',
        defaultMessage: 'Sweden'
    },
    CH: {
        id: 'country.countryNameMap.Switzerland.CH',
        description: 'country label for Switzerland',
        defaultMessage: 'Switzerland'
    },
    SY: {
        id: 'country.countryNameMap.Syria.SY',
        description: 'country label for Syria',
        defaultMessage: 'Syria'
    },
    TW: {
        id: 'country.countryNameMap.Taiwan.TW',
        description: 'country label for Taiwan',
        defaultMessage: 'Taiwan'
    },
    TZ: {
        id: 'country.countryNameMap.Tanzania.TZ',
        description: 'country label for Tanzania',
        defaultMessage: 'Tanzania'
    },
    TH: {
        id: 'country.countryNameMap.Thailand.TH',
        description: 'country label for Thailand',
        defaultMessage: 'Thailand'
    },
    TG: {
        id: 'country.countryNameMap.Togo.TG',
        description: 'country label for Togo',
        defaultMessage: 'Togo'
    },
    TT: {
        id: 'country.countryNameMap.TrinidadAndTobago.TT',
        description: 'country label for Trinidad & Tobago',
        defaultMessage: 'Trinidad & Tobago'
    },
    TN: {
        id: 'country.countryNameMap.Tunisia.TN',
        description: 'country label for Tunisia',
        defaultMessage: 'Tunisia'
    },
    TR: {
        id: 'country.countryNameMap.Turkey.TR',
        description: 'country label for Turkey',
        defaultMessage: 'Turkey'
    },
    TM: {
        id: 'country.countryNameMap.RepublicOfTurkmenistan.TM',
        description: 'country label for Republic of Turkmenistan',
        defaultMessage: 'Turkmenistan, Republic of'
    },
    TC: {
        id: 'country.countryNameMap.TurksAndCaicosIs.TC',
        description: 'country label for Turks & Caicos Is.',
        defaultMessage: 'Turks & Caicos Is.'
    },
    VI: {
        id: 'country.countryNameMap.USVirginIslands.VI',
        description: 'country label for U.S. Virgin Islands',
        defaultMessage: 'U.S. Virgin Islands'
    },
    UG: {
        id: 'country.countryNameMap.Uganda.UG',
        description: 'country label for Uganda',
        defaultMessage: 'Uganda'
    },
    UA: {
        id: 'country.countryNameMap.Ukraine.UA',
        description: 'country label for Ukraine',
        defaultMessage: 'Ukraine'
    },
    AE: {
        id: 'country.countryNameMap.UnitedArabEmirates.AE',
        description: 'country label for United Arab Emirates (U.A.E.)',
        defaultMessage: 'United Arab Emirates'
    },
    GB: {
        id: 'country.countryNameMap.UnitedKingdom.GB',
        description: 'country label for United Kingdom',
        defaultMessage: 'United Kingdom'
    },
    US: {
        id: 'country.countryNameMap.UnitedStates.US',
        description: 'country label for United States (U.S.A.)',
        defaultMessage: 'United States'
    },
    UY: {
        id: 'country.countryNameMap.Uruguay.UY',
        description: 'country label for Uruguay',
        defaultMessage: 'Uruguay'
    },
    UZ: {
        id: 'country.countryNameMap.Uzbekistan.UZ',
        description: 'country label for Uzbekistan',
        defaultMessage: 'Uzbekistan'
    },
    VU: {
        id: 'country.countryNameMap.Vanuatu.VU',
        description: 'country label for Vanuatu',
        defaultMessage: 'Vanuatu'
    },
    VA: {
        id: 'country.countryNameMap.VaticanCity.VA',
        description: 'country label for Vatican City',
        defaultMessage: 'Vatican City'
    },
    VE: {
        id: 'country.countryNameMap.Venezuela.VE',
        description: 'country label for Venezuela',
        defaultMessage: 'Venezuela'
    },
    VN: {
        id: 'country.countryNameMap.Vietnam.VN',
        description: 'country label for Vietnam',
        defaultMessage: 'Vietnam'
    },
    WF: {
        id: 'country.countryNameMap.WallisAndFutunaIslands.WF',
        description: 'country label for Wallis & Futuna Islands',
        defaultMessage: 'Wallis & Futuna Islands'
    },
    EH: {
        id: 'country.countryNameMap.Western Sahara.EH',
        description: 'country label for Western Sahara',
        defaultMessage: 'Western Sahara'
    },
    YE: {
        id: 'country.countryNameMap.Yemen.YE',
        description: 'country label for Yemen',
        defaultMessage: 'Yemen'
    },
    ZM: {
        id: 'country.countryNameMap.Zambia.ZM',
        description: 'country label for Zambia',
        defaultMessage: 'Zambia'
    },
    ZW: {
        id: 'country.countryNameMap.Zimbabwe.ZW',
        description: 'country label for Zimbabwe',
        defaultMessage: 'Zimbabwe'
    },
    PR: {
        id: 'country.countryNameMap.PuertoRico.PR',
        description: 'country label for Puerto Rico',
        defaultMessage: 'Puerto Rico'
    },
    PS: {
        id: 'country.countryNameMap.Palestine.PS',
        description: 'country label for Palestine',
        defaultMessage: 'Palestine'
    },
    RS: {
        id: 'country.countryNameMap.Serbia.RS',
        description: 'country label for Serbia',
        defaultMessage: 'Serbia'
    },
    SB: {
        id: 'country.countryNameMap.SolomonIslands.SB',
        description: 'country label for Solomon Islands',
        defaultMessage: 'Solomon Islands'
    },
    SD: {
        id: 'country.countryNameMap.Sudan.SD',
        description: 'country label for Sudan',
        defaultMessage: 'Sudan'
    },
    SH: {
        id: 'country.countryNameMap.SaintHelena.SH',
        description: 'country label for Saint Helena',
        defaultMessage: 'Saint Helena'
    },
    SJ: {
        id: 'country.countryNameMap.SvalbardJanMayen.SJ',
        description: 'country label for Svalbard and Jan Mayen',
        defaultMessage: 'Svalbard and Jan Mayen'
    },
    SO: {
        id: 'country.countryNameMap.Somalia.SO',
        description: 'country label for Somalia',
        defaultMessage: 'Somalia'
    },
    SS: {
        id: 'country.countryNameMap.SouthSudan.SS',
        description: 'country label for South Sudan',
        defaultMessage: 'South Sudan'
    },
    ST: {
        id: 'country.countryNameMap.SaoTomePrincipe.ST',
        description: 'country label for Sao Tome and Principe',
        defaultMessage: 'Sao Tome and Principe'
    },
    SX: {
        id: 'country.countryNameMap.SintMaarten.SX',
        description: 'country label for Sint Maarten',
        defaultMessage: 'Sint Maarten'
    },
    TF: {
        id: 'country.countryNameMap.FrenchSouthernTerritories.TF',
        description: 'country label for French Southern Territories',
        defaultMessage: 'French Southern Territories'
    },
    TJ: {
        id: 'country.countryNameMap.Tajikistan.TJ',
        description: 'country label for Tajikistan',
        defaultMessage: 'Tajikistan'
    },
    TK: {
        id: 'country.countryNameMap.Tokelau.TK',
        description: 'country label for Tokelau',
        defaultMessage: 'Tokelau'
    },
    TL: {
        id: 'country.countryNameMap.TimorLeste.TL',
        description: 'country label for Timor-Leste',
        defaultMessage: 'Timor-Leste'
    },
    TO: {
        id: 'country.countryNameMap.Tonga.TO',
        description: 'country label for Tonga',
        defaultMessage: 'Tonga'
    },
    TV: {
        id: 'country.countryNameMap.Tuvalu.TV',
        description: 'country label for Tuvalu',
        defaultMessage: 'Tuvalu'
    },
    UM: {
        id: 'country.countryNameMap.USMinorOutlyingIslands.UM',
        description: 'country label for United States Minor Outlying Islands',
        defaultMessage: 'United States Minor Outlying Islands'
    },
    WS: {
        id: 'country.countryNameMap.Samoa.WS',
        description: 'country label for Samoa',
        defaultMessage: 'Samoa'
    },
    YT: {
        id: 'country.countryNameMap.Mayotte.YT',
        description: 'country label for Mayotte',
        defaultMessage: 'Mayotte'
    }
});

export default countryNameMap;
