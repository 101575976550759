import { List as IList, Map as IMap, fromJS } from 'immutable';

const createCatalogMap = (catalog) => {
    const electronicCatalog = catalog.get('electronicCatalog', IMap());
    const plasticCatalog = catalog.get('plasticCatalog', IMap());
    const maxTransactionAmount = catalog.get('maxTransactionAmount');
    const uyopFeeAmount = catalog.get('uyopFeeAmount', '0.00');
    const plasticActivationFeeAmount = catalog.get('plasticActivationFeeAmount', '0.00');
    const digitalActivationFeeAmount = catalog.get('digitalActivationFeeAmount', '0.00');
    const brandCode = catalog.get('brandCode', '');
    const cpfFlag = catalog.get('cpfFlag', false);

    return IMap({
        egcRangedCatalog: electronicCatalog.get('rangedCatalog', IMap()),
        egcFixedCatalog: electronicCatalog.get('fixedCatalog', IList()),
        plasticRangedCatalog: plasticCatalog.get('rangedCatalog', IMap()),
        plasticFixedCatalog: plasticCatalog.get('fixedCatalog', IList()),
        egcRecommendedDenoms: electronicCatalog.get('recommendedDenoms', IList()),
        plasticRecommendedDenoms: plasticCatalog.get('recommendedDenoms', IList()),
        egcDefaultAmount: electronicCatalog.get('defaultAmount'),
        plasticDefaultAmount: plasticCatalog.get('defaultAmount'),
        uyopFeeAmount,
        plasticActivationFeeAmount,
        digitalActivationFeeAmount,
        maxTransactionAmount,
        brandCode,
        cpfFlag
    });
};


export const parseCatalogs = catalogs => fromJS(catalogs)
    .mapEntries(([currency, catalog]) => {
        if (IList.isList(catalog)) {
            const parsedCatalogs = catalog.map(brandCatalog => createCatalogMap(brandCatalog));
            return [
                currency,
                parsedCatalogs
            ];
        }

        return [
            currency,
            createCatalogMap(catalog)
        ];
    });

export const getInCatalog = (catalog, keys, defaultValue = null, catalogIndex = 0) => {
    if (IList.isList(catalog)) {
        return catalog.get(catalogIndex).getIn(keys, defaultValue);
    }
    return catalog.getIn(keys, defaultValue);
};
