import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Map as IMap } from 'immutable';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { icons } from 'cstar-react-primitives/lib/redux-form/icons/iconConst';
import { LinkButton } from 'cstar-react-primitives/lib/redux-form/buttons/LinkButton';

import { getFormattedAmountWithCurrency } from '../utils/numberUtils';
import { undoItemRemove } from '../item/itemModule';
import {
    getBundleValue,
    getItemCurrency,
    getItemDenomination,
    getItemQuantity,
    getItemRecipientName,
    isBundleItem,
    isSelfBuyItem
} from '../item/itemSelectors';

import notificationMessages from './notificationMessages';
import { getProgramTitleWithProvidedProgramCode } from '../program/programListSelectors';




export const NotifyUndoLink = (props) => {
    const { item, uid, undoItemRemove } = props;
    const linkProps = {
        onClick: () => undoItemRemove(item, uid),
        cashbotName: 'undo-remove'
    };
    return (
        <LinkButton {...linkProps}>
            <span className="notification-undo-remove__icon">{icons.UNDO_ICON}</span>
            <FormattedMessage {...notificationMessages.undoRemoveLinkText} />
        </LinkButton>
    );
};

NotifyUndoLink.defaultProps = {
    undoItemRemove: () => {}
};

NotifyUndoLink.propTypes = {
    item: PropTypes.instanceOf(IMap).isRequired,
    uid: PropTypes.string.isRequired,
    undoItemRemove: PropTypes.func
};

const mapDispatchToProps = {
    undoItemRemove
};

export const NotifyUndo = connect(
    null,
    mapDispatchToProps
)(NotifyUndoLink);


export const NotifyItemUndoRemove = ({ notification, intl }) => {
    const item = notification.getIn(['0', 'item']);
    const isSelfBuy = isSelfBuyItem(item);
    const cardCurrency = getItemCurrency(item);

    const isItemInBundle = useSelector(state => isBundleItem(state, item.get('id')));
    const cardValue = isItemInBundle
        ? useSelector(state => getBundleValue(state, item.get('id')))
        : getItemDenomination(item);

    const customCardMessages = useSelector(
        state => getProgramTitleWithProvidedProgramCode(state, item.get('programCode'))
    );
    let programLabel = customCardMessages.get('programTitle', '');
    if (programLabel === '') {
        programLabel = item.get('programCode');
    }

    let notificationMessage;
    if (isSelfBuy) {
        notificationMessage = isItemInBundle
            ? notificationMessages.undoRemoveContentBundleSelfBuy
            : notificationMessages.undoRemoveContentSelfBuy;
    } else {
        notificationMessage = isItemInBundle
            ? notificationMessages.undoRemoveContentBundle
            : notificationMessages.undoRemoveContent;
    }

    const messageProps = {
        ...notificationMessage,
        values: {
            quantity: getItemQuantity(item),
            recipientName: getItemRecipientName(item),
            denomination: getFormattedAmountWithCurrency(intl, cardValue, cardCurrency),
            programLabel
        }
    };
    const undoProps = {
        item,
        uid: notification.get('uid')
    };
    const isRemovCard = notification.get('1') === 'removeCard';
    return (
        <div className="notification-undo-remove">
            {!isRemovCard && <NotifyUndo {...undoProps} />}
            <h3 className="notification-undo-remove__message">
                <FormattedMessage {...messageProps} />
            </h3>
        </div>
    );
};

NotifyItemUndoRemove.propTypes = {
    notification: PropTypes.instanceOf(IMap).isRequired,
    intl: intlShape.isRequired
};

export default injectIntl(NotifyItemUndoRemove);
