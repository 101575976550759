
export const PROGRAM_STEP = 'program';
export const RECIPIENT_STEP = 'recipient';
export const TYPE_STEP = 'type';
export const DESIGN_STEP = 'design';
export const UYO_INIT_STEP = 'uyo-init';
export const UYO_SELECT_STEP = 'uyo-select';
export const UYO_REVIEW_STEP = 'uyo-review';
export const AMOUNT_STEP = 'amount';
export const MESSAGE_STEP = 'message';
export const DELIVERY_STEP = 'delivery';
export const SHIPPING_STEP = 'shipping';
export const CART_STEP = 'cart';
export const PAY_METHOD_STEP = 'pay-method';
export const PAYMENT_STEP = 'payment';
export const REDIRECTION_ORDER_STEP = 'redirection-flow-order';
export const CONFIRMATION_STEP = 'confirmation';
export const PRODUCT_DETAIL = 'product-detail';




export const HEADER_FLOW_PATHS = [
    `*/${PROGRAM_STEP}`,
    `*/${RECIPIENT_STEP}`,
    `*/${TYPE_STEP}`
];
