import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import classNames from 'classnames/dedupe';
import { injectIntl, intlShape } from 'react-intl';

import { getCashbotItemAttrs } from '../utils/cashbotUtils';
import useScrollBottomListener from '../primitive/useScrollBottomListener';

import ModalContent from './ModalContent';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';



ReactModal.setAppElement('#app-root');

const modalCssClasses = {
    className: 'modal',
    overlayClassName: 'modal-overlay'
};

const ariaAttrs = {
    role: 'dialog',
    aria: {
        live: 'polite',
        modal: 'true'
    }
};

export const ModalBaseContainer = ({
    intl,
    isOpen,
    children,
    className,
    cashbotName,
    contentLabel,
    handleCloseModal,
    handleScrollToBottom,
    customAttr
}) => {
    const modalContentRef = useScrollBottomListener(handleScrollToBottom, 20);
    const modalContentLabel = (cashbotName === 'remove-card' ? null : intl.formatMessage(contentLabel));
    const modalProps = {
        ...ariaAttrs,
        ...customAttr,
        className: classNames(modalCssClasses.className, className),
        overlayClassName: modalCssClasses.overlayClassName,
        
        onRequestClose: () => handleCloseModal(),
        isOpen,
        contentRef: modalContentRef
    };
    if (modalContentLabel) {
        
        modalProps.contentLabel = modalContentLabel;
    }
    const wrapper = {
        ...getCashbotItemAttrs(cashbotName, 'modal')
    };

    return (
        <ReactModal {...modalProps}>
            <div {...wrapper}>
                { children }
            </div>
        </ReactModal>
    );
};

ModalBaseContainer.defaultProps = {
    isOpen: false,
    className: null,
    handleCloseModal: () => {},
    handleScrollToBottom: () => {},
    customAttr: {}
};

ModalBaseContainer.propTypes = {
    intl: intlShape.isRequired,
    isOpen: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    cashbotName: PropTypes.string.isRequired,
    contentLabel: PropTypes.object.isRequired,
    handleCloseModal: PropTypes.func,
    handleScrollToBottom: PropTypes.func,
    customAttr: PropTypes.object
};

export const ModalContainer = injectIntl(ModalBaseContainer);


const ModalWrapper = ({
    isOpen,
    children,
    contentLabel,
    handleCloseModal,
    headerText,
    cashbotName
}) => {
    const modalProps = {
        isOpen,
        cashbotName,
        contentLabel,
        handleCloseModal
    };
    return (
        <ModalContainer {...modalProps}>
            <ModalHeader headerText={headerText} />
            <ModalContent>
                { children }
            </ModalContent>
            <ModalFooter handleCloseModal={handleCloseModal} cashbotName={cashbotName} />
        </ModalContainer>
    );
};

ModalWrapper.defaultProps = {
    isOpen: false,
    children: null,
    handleCloseModal: () => {}
};

ModalWrapper.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    children: PropTypes.node,
    contentLabel: PropTypes.object.isRequired,
    handleCloseModal: PropTypes.func,
    headerText: PropTypes.object.isRequired,
    isOpen: PropTypes.bool
};

export default ModalWrapper;
