import { List as IList } from 'immutable';

import { history } from '../routing/routing';


export const paymentStepCompleted = () => (
    !!(history.location.state
        && history.location.state.orderData
        && history.location.state.paymentData)
);


export const paymentStepPreConditions = IList([]);


export const paymentStepPostConditions = IList([
    paymentStepCompleted
]);


export const paymentStepSkippable = () => false;
