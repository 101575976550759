import { defineMessages } from 'react-intl';




const stateMap = defineMessages({
    AL: {
        id: 'address.US.StateMap.Alabama.AL',
        description: 'us state label for Alabama',
        defaultMessage: 'Alabama'
    },
    AK: {
        id: 'address.US.StateMap.Alaska.AK',
        description: 'us state label for Alaska',
        defaultMessage: 'Alaska'
    },
    AZ: {
        id: 'address.US.StateMap.Arizona.AZ',
        description: 'us state label for Arizona',
        defaultMessage: 'Arizona'
    },
    AR: {
        id: 'address.US.StateMap.Arkansas.AR',
        description: 'us state label for Arkansas',
        defaultMessage: 'Arkansas'
    },
    CA: {
        id: 'address.US.StateMap.California.CA',
        description: 'us state label for California',
        defaultMessage: 'California'
    },
    CO: {
        id: 'address.US.StateMap.Colorado.CO',
        description: 'us state label for Colorado',
        defaultMessage: 'Colorado'
    },
    CT: {
        id: 'address.US.StateMap.Connecticut.CT',
        description: 'us state label for Connecticut',
        defaultMessage: 'Connecticut'
    },
    DE: {
        id: 'address.US.StateMap.Delaware.DE',
        description: 'us state label for Delaware',
        defaultMessage: 'Delaware'
    },
    FL: {
        id: 'address.US.StateMap.Florida.FL',
        description: 'us state label for Florida',
        defaultMessage: 'Florida'
    },
    GA: {
        id: 'address.US.StateMap.Georgia.GA',
        description: 'us state label for Georgia',
        defaultMessage: 'Georgia'
    },
    HI: {
        id: 'address.US.StateMap.Hawaii.HI',
        description: 'us state label for Hawaii',
        defaultMessage: 'Hawaii'
    },
    ID: {
        id: 'address.US.StateMap.Idaho.ID',
        description: 'us state label for Idaho',
        defaultMessage: 'Idaho'
    },
    IL: {
        id: 'address.US.StateMap.Illinois.IL',
        description: 'us state label for Illinois',
        defaultMessage: 'Illinois'
    },
    IN: {
        id: 'address.US.StateMap.Indiana.IN',
        description: 'us state label for Indiana',
        defaultMessage: 'Indiana'
    },
    IA: {
        id: 'address.US.StateMap.Iowa.IA',
        description: 'us state label for Iowa',
        defaultMessage: 'Iowa'
    },
    KS: {
        id: 'address.US.StateMap.Kansas.KS',
        description: 'us state label for Kansas',
        defaultMessage: 'Kansas'
    },
    KY: {
        id: 'address.US.StateMap.Kentucky.KY',
        description: 'us state label for Kentucky',
        defaultMessage: 'Kentucky'
    },
    LA: {
        id: 'address.US.StateMap.Louisiana.LA',
        description: 'us state label for Louisiana',
        defaultMessage: 'Louisiana'
    },
    ME: {
        id: 'address.US.StateMap.Maine.ME',
        description: 'us state label for Maine',
        defaultMessage: 'Maine'
    },
    MD: {
        id: 'address.US.StateMap.Maryland.MD',
        description: 'us state label for Maryland',
        defaultMessage: 'Maryland'
    },
    MA: {
        id: 'address.US.StateMap.Massachusetts.MA',
        description: 'us state label for Massachusetts',
        defaultMessage: 'Massachusetts'
    },
    MI: {
        id: 'address.US.StateMap.Michigan.MI',
        description: 'us state label for Michigan',
        defaultMessage: 'Michigan'
    },
    MN: {
        id: 'address.US.StateMap.Minnesota.MN',
        description: 'us state label for Minnesota',
        defaultMessage: 'Minnesota'
    },
    MS: {
        id: 'address.US.StateMap.Mississippi.MS',
        description: 'us state label for Mississippi',
        defaultMessage: 'Mississippi'
    },
    MO: {
        id: 'address.US.StateMap.Missouri.MO',
        description: 'us state label for Missouri',
        defaultMessage: 'Missouri'
    },
    MT: {
        id: 'address.US.StateMap.Montana.MT',
        description: 'us state label for Montana',
        defaultMessage: 'Montana'
    },
    NE: {
        id: 'address.US.StateMap.Nebraska.NE',
        description: 'us state label for Nebraska',
        defaultMessage: 'Nebraska'
    },
    NV: {
        id: 'address.US.StateMap.Nevada.NV',
        description: 'us state label for Nevada',
        defaultMessage: 'Nevada'
    },
    NH: {
        id: 'address.US.StateMap.NewHampshire.NH',
        description: 'us state label for New Hampshire',
        defaultMessage: 'New Hampshire'
    },
    NJ: {
        id: 'address.US.StateMap.NewJersey.NJ',
        description: 'us state label for New Jersey',
        defaultMessage: 'New Jersey'
    },
    NM: {
        id: 'address.US.StateMap.NewMexico.NM',
        description: 'us state label for New Mexico',
        defaultMessage: 'New Mexico'
    },
    NY: {
        id: 'address.US.StateMap.NewYork.NY',
        description: 'us state label for New York',
        defaultMessage: 'New York'
    },
    NC: {
        id: 'address.US.StateMap.NorthCarolina.NC',
        description: 'us state label for North Carolina',
        defaultMessage: 'North Carolina'
    },
    ND: {
        id: 'address.US.StateMap.NorthDakota.ND',
        description: 'us state label for North Dakota',
        defaultMessage: 'North Dakota'
    },
    OH: {
        id: 'address.US.StateMap.Ohio.OH',
        description: 'us state label for Ohio',
        defaultMessage: 'Ohio'
    },
    OK: {
        id: 'address.US.StateMap.Oklahoma.OK',
        description: 'us state label for Oklahoma',
        defaultMessage: 'Oklahoma'
    },
    OR: {
        id: 'address.US.StateMap.Oregon.OR',
        description: 'us state label for Oregon',
        defaultMessage: 'Oregon'
    },
    PA: {
        id: 'address.US.StateMap.Pennsylvania.PA',
        description: 'us state label for Pennsylvania',
        defaultMessage: 'Pennsylvania'
    },
    RI: {
        id: 'address.US.StateMap.RhodeIsland.RI',
        description: 'us state label for Rhode Island',
        defaultMessage: 'Rhode Island'
    },
    SC: {
        id: 'address.US.StateMap.SouthCarolina.SC',
        description: 'us state label for South Carolina',
        defaultMessage: 'South Carolina'
    },
    SD: {
        id: 'address.US.StateMap.SouthDakota.SD',
        description: 'us state label for South Dakota',
        defaultMessage: 'South Dakota'
    },
    TN: {
        id: 'address.US.StateMap.Tennessee.TN',
        description: 'us state label for Tennessee',
        defaultMessage: 'Tennessee'
    },
    TX: {
        id: 'address.US.StateMap.Texas.TX',
        description: 'us state label for Texas',
        defaultMessage: 'Texas'
    },
    UT: {
        id: 'address.US.StateMap.Utah.UT',
        description: 'us state label for Utah',
        defaultMessage: 'Utah'
    },
    VT: {
        id: 'address.US.StateMap.Vermont.VT',
        description: 'us state label for Vermont',
        defaultMessage: 'Vermont'
    },
    VA: {
        id: 'address.US.StateMap.Virginia.VA',
        description: 'us state label for Virginia',
        defaultMessage: 'Virginia'
    },
    WA: {
        id: 'address.US.StateMap.Washington.WA',
        description: 'us state label for Washington',
        defaultMessage: 'Washington'
    },
    WV: {
        id: 'address.US.StateMap.WestVirginia.WV',
        description: 'us state label for West Virginia',
        defaultMessage: 'West Virginia'
    },
    WI: {
        id: 'address.US.StateMap.Wisconsin.WI',
        description: 'us state label for Wisconsin',
        defaultMessage: 'Wisconsin'
    },
    WY: {
        id: 'address.US.StateMap.Wyoming.WY',
        description: 'us state label for Wyoming',
        defaultMessage: 'Wyoming'
    },
    DC: {
        id: 'address.US.StateMap.District of Columbia .DC',
        description: 'us state label for District of Columbia ',
        defaultMessage: 'District of Columbia'
    },
    AA: {
        id: 'address.US.StateMap.ArmedForcesAmericas.AA',
        description: 'us state label for Armed Forces Americas',
        defaultMessage: 'Armed Forces Americas'
    },
    AE: {
        id: 'address.US.StateMap.ArmedForcesEurope.AE',
        description: 'us state label for Armed Forces Europe',
        defaultMessage: 'Armed Forces Europe'
    },
    AP: {
        id: 'address.US.StateMap.ArmedForcesPacific.AP',
        description: 'us state label for Armed Forces Pacific',
        defaultMessage: 'Armed Forces Pacific'
    },
    AS: {
        id: 'address.US.StateMap.American Samoa.AS',
        description: 'us state label for American Samoa',
        defaultMessage: 'American Samoa'
    },
    FM: {
        id: 'address.US.StateMap.Federated States of Micronesia.FM',
        description: 'us state label for Federated States of Micronesia',
        defaultMessage: 'Federated States of Micronesia'
    },
    GU: {
        id: 'address.US.StateMap.Guam.GU',
        description: 'us state label for Guam',
        defaultMessage: 'Guam'
    },
    MH: {
        id: 'address.US.StateMap.Marshall Islands.MH',
        description: 'us state label for Marshall Islands',
        defaultMessage: 'Marshall Islands'
    },
    MP: {
        id: 'address.US.StateMap.Northern Mariana Islands.MP',
        description: 'us state label for Northern Mariana Islands',
        defaultMessage: 'Northern Mariana Islands'
    },
    PW: {
        id: 'address.US.StateMap.Palau.PW',
        description: 'us state label for Palau',
        defaultMessage: 'Palau'
    },
    PR: {
        id: 'address.US.StateMap.PuertoRico.PR',
        description: 'us state label for Puerto Rico',
        defaultMessage: 'Puerto Rico'
    },
    VI: {
        id: 'address.US.StateMap.VirginIslands.VI',
        description: 'us state label for Virgin Islands',
        defaultMessage: 'Virgin Islands'
    }
});

export const usStateField = defineMessages({
    stateLabel: {
        id: 'address.US.StateMap.stateLabel',
        description: 'displayed in US state dropdown as default empty value',
        defaultMessage: 'State'
    }
});

export default stateMap;
