import { Map as IMap, List as IList, fromJS } from 'immutable';
import { createSelector } from 'reselect';

import { ITEM_ID, newItemIsPlastic } from '../item/newItemForm';

import {
    PROMO_STORE_KEY,
    ACTIVE_PROMO,
    REJECTED_PROMO_CODE,
    PROMO_ERROR,
    PROMO_STATUS,
    PROMO_REWARDS,
    PROMO_REWARDS_STATE,
    DIGITAL_PROMO_REWARD_CARDS,
    PLASTIC_PROMO_REWARD_CARDS,
    SHIPPING_PROMO_REWARDS,
    ACCESSORY_PROMO_REWARDS,
    DISCOUNT_PROMO_REWARD,
    REWARDS_REQUEST_STATE,
    PROMO_RECIPIENT_FIELDS,
    PROMO_BY_CODE_REQUEST_STATE,
    CUSTOM_REWARD_CARD_RECIPIENT_STORE_KEY,
    PROMO_NUDGES,
    CARTQUALIFIES
} from './promoModule';


export const getPromoState = state => state.get(PROMO_STORE_KEY, IMap());


export const getActivePromo = createSelector(
    getPromoState,
    promoState => promoState.get(ACTIVE_PROMO, null)
);


export const getRejectedPromo = createSelector(
    getPromoState,
    promoState => promoState.get(REJECTED_PROMO_CODE, null)
);


export const getPromoError = createSelector(
    getPromoState,
    promoState => promoState.get(PROMO_ERROR, null)
);


export const getPromoStatus = createSelector(
    getPromoState,
    promoState => promoState.get(PROMO_STATUS, IMap())
);


export const getPromoRewards = createSelector(
    getPromoState,
    promoState => promoState.get(PROMO_REWARDS, IMap())
);

export const getPromoRewardsState = createSelector(
    getPromoRewards,
    rewards => (rewards ? rewards.get(PROMO_REWARDS_STATE) : undefined)
);

export const getDigitalPromoCards = createSelector(
    getPromoRewards,
    rewards => (rewards ? rewards.get(DIGITAL_PROMO_REWARD_CARDS, IList()) : IList())
);

export const getPlasticPromoCards = createSelector(
    getPromoRewards,
    rewards => (rewards ? rewards.get(PLASTIC_PROMO_REWARD_CARDS, IList()) : IList())
);

export const getShippingPromoData = createSelector(
    getPromoRewards,
    rewards => (rewards ? rewards.get(SHIPPING_PROMO_REWARDS, IMap()) : IMap())
);

export const getAccessoriesPromoData = createSelector(
    getPromoRewards,
    rewards => (rewards ? rewards.get(ACCESSORY_PROMO_REWARDS, IMap()) : IMap())
);


export const getDiscountRewardAmount = createSelector(
    getPromoRewards,
    rewards => (rewards ? 0 - rewards.get(DISCOUNT_PROMO_REWARD, 0) : 0)
);

export const hasPlasticPromoCards = createSelector(
    getPlasticPromoCards,
    plasticList => plasticList.length > 0
);


export const getCartQualifies = createSelector(
    getPromoRewards,
    rewards => (rewards ? rewards.get(CARTQUALIFIES, false) : false)
);


export const getPromoRewardCards = createSelector(
    getDigitalPromoCards,
    getPlasticPromoCards,
    (digital, plastic) => digital.concat(plastic)
);



export const getPromoRewardsCount = createSelector(
    getPromoRewardCards,
    rewards => rewards.size
);


export const hasPromoRewards = createSelector(
    getPromoRewardsCount,
    count => count > 0
);


export const getPromoLabel = promo => ((promo && IMap.isMap(promo)) ? promo.get('displayLabel') : '');

export const getPromoCertType = promo => promo.get('certType');

export const getPromoDeliveryDate = promo => promo.get('deliveryDate', '');

export const getPromoCertIntendedRecipient = promo => promo.get('intendedRecipient', '');

export const isPromoSelfBonus = promo => promo.get('selfBonus', false);


export const getPromoCertRecipient = (promo) => {
    const recipient = promo.get('recipient');
    return fromJS({
        [PROMO_RECIPIENT_FIELDS.RECIPIENT_NAME]: recipient.get('recipient_name'),
        [PROMO_RECIPIENT_FIELDS.FIRST_NAME]: recipient.get('first_name'),
        [PROMO_RECIPIENT_FIELDS.LAST_NAME]: recipient.get('last_name'),
        [PROMO_RECIPIENT_FIELDS.EMAIL]: recipient.get('email'),
        [PROMO_RECIPIENT_FIELDS.PHONE]: recipient.get('phone'),
        [PROMO_RECIPIENT_FIELDS.STREET1]: recipient.get('street'),
        [PROMO_RECIPIENT_FIELDS.STREET2]: recipient.get('street2'),
        [PROMO_RECIPIENT_FIELDS.CITY]: recipient.get('city'),
        [PROMO_RECIPIENT_FIELDS.STATE]: recipient.get('state'),
        [PROMO_RECIPIENT_FIELDS.ZIP_CODE]: recipient.get('zip_code'),
        [PROMO_RECIPIENT_FIELDS.COUNTRY_CODE]: recipient.get('country_code')
    });
};
export const getCustomPromoCertRecipient = (promo) => {
    const recipient = fromJS(promo);
    return fromJS({
        [PROMO_RECIPIENT_FIELDS.RECIPIENT_NAME]: recipient.get('recipient_name'),
        [PROMO_RECIPIENT_FIELDS.FIRST_NAME]: recipient.get('first_name'),
        [PROMO_RECIPIENT_FIELDS.LAST_NAME]: recipient.get('last_name'),
        [PROMO_RECIPIENT_FIELDS.EMAIL]: recipient.get('email')
    });
};

export const getPromoTerms = createSelector(
    getActivePromo,
    promo => (promo ? promo.get('terms') : '')
);

export const hasDigitalBanners = createSelector(
    getActivePromo,
    promo => (promo ? !!promo.get('digitalBannerSmall') && !!promo.get('digitalBannerLarge') : false)
);

export const hasPlasticBanners = createSelector(
    getActivePromo,
    promo => (promo ? !!promo.get('plasticBannerSmall') && !!promo.get('plasticBannerLarge') : false)
);

export const hasSoldOutBanners = createSelector(
    getActivePromo,
    promo => (promo ? !!promo.get('soldOutBannerSmall') && !!promo.get('soldOutBannerLarge') : false)
);

export const getDigitalBanners = createSelector(
    getActivePromo,
    promo => (promo ? {
        small: promo.get('digitalBannerSmall'),
        large: promo.get('digitalBannerLarge')
    } : null)
);

export const getPlasticBanners = createSelector(
    getActivePromo,
    promo => (promo ? {
        small: promo.get('plasticBannerSmall'),
        large: promo.get('plasticBannerLarge')
    } : null)
);

export const getSoldOutBanners = createSelector(
    getActivePromo,
    promo => (promo ? {
        small: promo.get('soldOutBannerSmall'),
        large: promo.get('soldOutBannerLarge')
    } : null)
);

export const activePromoSoldOut = createSelector(
    getActivePromo,
    promo => (promo ? promo.get('soldOut') : false)
);

export const getCartPromoProposition = createSelector(
    getActivePromo,
    promo => (promo ? promo.get('cartPromoProposition', null) : null)
);

export const getGogoPromoRewardAmount = createSelector(
    getActivePromo,
    promo => (promo ? promo.get('gogoPromoRewardAmount', null) : null)
);

export const getGogoPromoCondition = createSelector(
    getActivePromo,
    promo => (promo ? promo.get('gogoPromoCondition', null) : null)
);

export const getIsPromoNudgeActive = createSelector(
    getActivePromo,
    promo => (promo ? promo.get('promoNudge') : false)
);

export const getPromoBanners = createSelector(
    [
        newItemIsPlastic,
        hasDigitalBanners,
        hasPlasticBanners,
        getDigitalBanners,
        getPlasticBanners,
        activePromoSoldOut,
        getSoldOutBanners
    ],
    (
        isPlastic,
        hasDigital,
        hasPlastic,
        digital,
        plastic,
        soldOut,
        soldOutBanners
    ) => {
        if (soldOut) {
            return soldOutBanners;
        } if (hasPlastic && (isPlastic || !hasDigital)) {
            return plastic;
        } if (hasDigital) {
            return digital;
        }
        return null;
    }
);

export const getActivePromoCode = createSelector(
    getActivePromo,
    promo => (promo ? promo.get('promoCode') : null)
);



export const getMatchingShippingPromoMethod = (promoShippingMethods, shippingMethod) => (
    promoShippingMethods !== null && shippingMethod !== null
        ? promoShippingMethods.get(shippingMethod.get('token', ''))
        : undefined
);


export const getPromoShippingCost = (items, originalShippingMethod, promoShippingMethods, shippingGroupCost) => {
    let shippingCost = 0;
    if (originalShippingMethod) {
        shippingCost = originalShippingMethod.get('cost');

        
        
        if (shippingGroupCost < shippingCost) {
            return shippingGroupCost;
        }

        
        
        
        const matchingShippingMethod = getMatchingShippingPromoMethod(promoShippingMethods, originalShippingMethod);
        if (matchingShippingMethod) {
            const cartTokens = matchingShippingMethod.get('cartTokens');
            if (cartTokens && items.some(item => cartTokens.includes(item.get(ITEM_ID)))) {
                shippingCost = matchingShippingMethod.get('updatedCost');
            }
        }
    }
    return shippingCost;
};

export const getPromoIsLoading = createSelector(
    getPromoState,
    promo => promo.get('promoLoading', false)
);


export const isStillFetchingPromoRewards = createSelector(
    getActivePromo,
    getPromoRewardsState,
    (promo, rewardState) => (promo && rewardState === REWARDS_REQUEST_STATE.ACTIVE)
);

export const isFetchByCodeActive = createSelector(
    getPromoState,
    promo => (promo.get(PROMO_BY_CODE_REQUEST_STATE) === REWARDS_REQUEST_STATE.ACTIVE)
);


export const getCustomRewardCardRecipients = createSelector(
    getPromoState,
    promo => promo.get(CUSTOM_REWARD_CARD_RECIPIENT_STORE_KEY, IMap())
);

export const addPromoRewardCardRecipient = (customRewardCardRecipients, promoReward) => {
    const recipient = promoReward.get('recipient');
    const parentCardCartToken = promoReward.get('parentCardCartToken');
    const selfBonus = promoReward.get('selfBonus');
    if (!(parentCardCartToken in customRewardCardRecipients) && promoReward.get('recipientChoice') === true) {
        customRewardCardRecipients[parentCardCartToken] = {
            recipient_name: `${recipient.get('first_name')} ${recipient.get('last_name')}`,
            email: recipient.get('email'),
            selfBonus
        };
    }
};


export const getPromoRewardCardRecipients = createSelector(
    getDigitalPromoCards,
    getPlasticPromoCards,
    (digitalPromoCards, plasticPromoCards) => {
        const customRewardCardRecipients = {};
        digitalPromoCards.forEach(
            promoReward => addPromoRewardCardRecipient(customRewardCardRecipients, promoReward)
        );
        plasticPromoCards.forEach(
            promoReward => addPromoRewardCardRecipient(customRewardCardRecipients, promoReward)
        );
        return fromJS(customRewardCardRecipients);
    }
);

export const getPromoNudgesValue = createSelector(
    getPromoState,
    values => (values ? values.get(PROMO_NUDGES, '') : '')
);


export const getParentCartTokenForItem = (state, item) => {
    const customCardRecipients = getCustomRewardCardRecipients(state).toJS();
    const parentCardCartToken = Object.keys(customCardRecipients).find((recipientKey) => {
        const recipient = customCardRecipients[recipientKey];
        if ('selectedRecipient' in recipient) {
            if (item.get('id') === recipient.selectedRecipient) {
                return recipientKey;
            }
        }
        return undefined;
    });
    return parentCardCartToken;
};
