import {
    takeLatest,
    select,
    put
} from 'redux-saga/effects';

import {
    IS_PLASTIC_FIELD,
    SELECTED_GROUP_ORDINAL,
    newItemIsPlastic
} from '../item/newItemForm';
import { changeValue } from '../item/newItemFormModule';

import {
    SWITCH_CARD_TYPE,
    completeCardTypeSwitch
} from './cardTypeModule';



export function* switchCardTypeSaga({ isPlastic }) {
    const prevIsPlastic = yield select(newItemIsPlastic);

    if (isPlastic === prevIsPlastic) {
        yield put(completeCardTypeSwitch());
        return;
    }

    yield put(changeValue(IS_PLASTIC_FIELD, isPlastic));
    yield put(changeValue(SELECTED_GROUP_ORDINAL, 1));

    

    yield put(completeCardTypeSwitch());
}

export function* watchSwitchCardType() {
    yield takeLatest(SWITCH_CARD_TYPE, switchCardTypeSaga);
}
