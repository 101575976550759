import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import trustwaveMessages from './trustwaveMessages';


const Trustwave = ({ intl, logoStyle }) => {
    const imgSrc = `https://sealserver.trustwave.com/seal_image.php?customerId=&size=105x54&style=${logoStyle}`;
    const altText = intl.formatMessage(trustwaveMessages.trustwaveLogoAlt);
    const alertText = intl.formatMessage(trustwaveMessages.trustwaveWarning);
    const url = window.location.hostname;
    const handleClick = (e) => {
        e.preventDefault();
        
        const newWindow = window.open(`https://sealserver.trustwave.com/cert.php?customerId=&size=105x54&style=${logoStyle}&baseURL=${url}`,
            'c_TW',
            'location=no, toolbar=no, resizable=yes, scrollbars=yes, directories=no, status=no, width=615, height=720');
        newWindow.opener = null;
        return false;
    };
    const handleRightClick = (e) => {
        e.preventDefault();
        window.alert(alertText); 
        return false;
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleClick(e);
        }
    };
    return (
        
        <button
            className="footer-logo-trustwave-btn"
            type="button"
            onClick={handleClick}
            onContextMenu={handleRightClick}
            onKeyDown={handleKeyDown}
        >
            <img
                id="trustwaveSealImage"
                className="footer-logo--trustwave"
                src={imgSrc}
                style={{ border: 0, cursor: 'pointer', maxWidth: '105px' }}
                alt={altText}
                title={altText}
            />
        </button>
    );
};

Trustwave.defaultProps = {
    logoStyle: 'invert' 
};

Trustwave.propTypes = {
    intl: intlShape.isRequired,
    logoStyle: PropTypes.oneOf(['invert', 'normal'])
};

export default injectIntl(Trustwave);
