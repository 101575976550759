import { createSelector } from 'reselect';
import { getIntlIsLoading } from '../intl/intlSelectors';
import { getPromoIsLoading } from '../promo/promoSelectors';


export const getShowAppLoadingSpinner = createSelector(
    getIntlIsLoading,
    getPromoIsLoading,
    (intlIsLoading, promoIsLoading) => (intlIsLoading || promoIsLoading)
);
