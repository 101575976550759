
const getCashbotAttrs = (input, cashbotName, options = {}) => {
    const cashbotAttrs = {
        'data-cashbot-id': `${cashbotName}-${input.name}`
    };

    if (options.maxLength) {
        cashbotAttrs['data-cashbot-maxlength'] = options.maxLength;
    }

    return cashbotAttrs;
};



const getCashbotButtonAttrs = (cashbotName, type) => {
    const cashbotButtonAttrs = {
        'data-cashbot-id': `${cashbotName}-button-${type}`
    };

    return cashbotButtonAttrs;
};



const getCashbotItemAttrs = (cashbotName, attrName) => {
    const cashbotItemAttrs = {
        'data-cashbot-id': `${cashbotName}-${attrName}`
    };

    return cashbotItemAttrs;
};

export {
    getCashbotItemAttrs,
    getCashbotButtonAttrs,
    getCashbotAttrs as default
};
