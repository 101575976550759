import { actionTypes } from 'redux-form/immutable';

import { NEW_ITEM_FORM_NAME } from './newItemForm';


export const SET_DEFAULT_FIELD_VALUES = '@@storefront/SET_DEFAULT_FIELD_VALUES';
export const SET_INITIAL_VALUES = '@@storefront/SET_INITIAL_VALUES';
export const CHANGE_VALUE = '@@storefront/CHANGE_VALUE';
export const REMOVE_VALUE = '@@storefront/REMOVE_VALUE';
export const RESET_VALUES = '@@storefront/RESET_VALUES';
export const DEFAULT_FIELD_VALUES_UPDATED = '@@storefront/DEFAULT_FIELD_VALUES_UPDATED';
export const FORM_INITIALIZED = '@@storefront/FORM_INITIALIZED';
export const FORM_RESET = '@@storefront/FORM_RESET';
export const fieldChangedAction = fieldName => `@@storefront/${fieldName}_FIELD_CHANGED`;


export const newItemFormReducer = {
    [NEW_ITEM_FORM_NAME]: (state, action) => {
        switch (action.type) {
            case actionTypes.CHANGE:
                
                break;
            default:
                break;
        }
        return state;
    }
};



export const setDefaultFieldValues = (defaultValues, updateValues = false) => ({
    type: SET_DEFAULT_FIELD_VALUES,
    defaultValues,
    updateValues
});

export const setInitialValues = defaultValues => ({
    type: SET_INITIAL_VALUES,
    defaultValues
});

export const changeValue = (fieldName, value) => ({
    type: CHANGE_VALUE,
    fieldName,
    value
});

export const removeValue = fieldName => ({
    type: REMOVE_VALUE,
    fieldName
});

export const resetFieldValues = () => ({
    type: RESET_VALUES
});

export const fieldChanged = (fieldName, newValue) => ({
    type: fieldChangedAction(fieldName),
    newValue
});

export const formInitialized = () => ({
    type: FORM_INITIALIZED
});

export const formReset = () => ({
    type: FORM_RESET
});
