import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TestingEnvBanner } from 'cstar-react-primitives/lib/layout/TestingEnvBanner';

import { getTestEnvMessages } from '../intl/intlSelectors';


export const TestEnvironmentBanner = (props) => {
    const {
        message
    } = props;

    const bannerProps = {
        message
    };

    return (
        <Fragment>
            <TestingEnvBanner {...bannerProps} />
        </Fragment>
    );
};

TestEnvironmentBanner.defaultProps = {
    message: null
};

TestEnvironmentBanner.propTypes = {
    message: PropTypes.string
};

const mapStateToProps = state => ({
    message: getTestEnvMessages(state)
});

export const ConnectedTestEnvironmentBanner = connect(mapStateToProps)(TestEnvironmentBanner);
