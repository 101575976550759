import {
    call, put, select, takeEvery
} from 'redux-saga/effects';
import { addLocaleData } from 'react-intl';

import { getDefaultLocaleCode, getPreferredLocaleCode } from '../app/bootstrap';
import { getElectronicCurrencyList, getPlasticCurrencyList } from '../catalogs/catalogSelectors';
import { updateSearch } from '../routing/routing';
import { STEP_CHANGE } from '../routing/stepUtils';
import { isAmountStep, isCardTypeStep } from '../routing/flow';
import { getPreferredCurrencyCode } from '../routing/flowSelectors';
import { getBrandCode, getSupportedLocales } from '../brand/brandSelectors';

import { getCopy } from './intlGetCopy';
import {
    SET_LOCALE,
    updateIntlMessages,
    updateIntlCurrency,
    setIntlIsLoading,
    completeLanguageChange,
    initializeLocale,
    setIntlLocale,
    setShouldPredictIntlCurrency
} from './intlModule';
import {
    getIntl,
    getShouldPredictIntlCurrency,
    getIntlLocale,
    formats
} from './intlSelectors';
import { dynamicLocaleImport } from './dynamicLocaleImport';
import { predictIntlCurrency } from './data/currencyByLocale';


export const getLocale = (hasStoredIntlData, supportedLocaleCodes, intlLocale) => {
    const preferredLocale = getPreferredLocaleCode();
    if (preferredLocale) {
        return preferredLocale;
    }
    const storedLocale = (hasStoredIntlData && supportedLocaleCodes.contains(intlLocale))
        ? intlLocale
        : null;
    return storedLocale || getDefaultLocaleCode() || 'en-us';
};


export function* updateCurrency(currency) {
    const plasticCurrencyList = yield select(getPlasticCurrencyList);
    const electronicCurrencyList = yield select(getElectronicCurrencyList);
    const intlFormats = yield select(formats);

    
    
    if (plasticCurrencyList.includes(currency) || electronicCurrencyList.includes(currency)) {
        yield put(updateIntlCurrency(intlFormats, currency));
    }
}


export function* changeLanguage({ locale = 'en-us' }) {
    yield put(setIntlIsLoading(true));
    const brandCode = yield select(getBrandCode);
    const messages = yield call(getCopy, brandCode, locale);
    const localeData = yield call(dynamicLocaleImport, locale);
    yield call(addLocaleData, localeData);
    yield put(updateIntlMessages(messages, locale));
    yield call(updateSearch, { locale });
    const shouldPredictIntlCurrency = yield select(getShouldPredictIntlCurrency);
    const preferredCurrencyCode = yield select(getPreferredCurrencyCode);
    
    if (shouldPredictIntlCurrency) {
        const currency = preferredCurrencyCode || predictIntlCurrency(locale);
        yield call(updateCurrency, currency);
    }
    yield put(setIntlIsLoading(false));
    yield put(completeLanguageChange());
}


export function* initializeIntl() {
    const intl = yield select(getIntl);
    const intlLocale = yield select(getIntlLocale);
    const supportedLocaleCodes = yield select(getSupportedLocales);
    
    
    
    const hasStoredIntlData = Boolean(intl && intl.formats);
    const locale = getLocale(hasStoredIntlData, supportedLocaleCodes, intlLocale);
    yield put(initializeLocale(locale));
    
    yield put(setIntlLocale(locale));
    
    
    if (!hasStoredIntlData) {
        
        if (locale) {
            yield put(setShouldPredictIntlCurrency(true));
        }
    }
    yield call(changeLanguage, { locale });
}


export function* updateCurrencyPredictionLock({ path }) {
    if (path) {
        
        
        if (isAmountStep(path) || isCardTypeStep(path)) {
            yield put(setShouldPredictIntlCurrency(false));
        }
    }
}


export function* intlSagasWatcher() {
    yield takeEvery(SET_LOCALE, changeLanguage);
    yield takeEvery(STEP_CHANGE, updateCurrencyPredictionLock);
}
