
const validateFieldsByRules = (values, constraints, intl = null, passAllValues = false, fieldSequence = null) => {
    const errors = {};
    let fieldNames;
    if (fieldSequence) {
        fieldNames = fieldSequence;
    } else {
        fieldNames = constraints.keySeq();
    }

    fieldNames.forEach((fieldName) => {
        const fieldRules = constraints.get(fieldName);

        fieldRules.some((rule) => {
            const vals = passAllValues ? values : values.get(fieldName);
            const error = rule(vals);
            if (error) {
                
                errors[fieldName] = intl ? intl.formatMessage(error, error.values) : error;
                return true;
            }
            return false;
        });
    });
    return errors;
};

export default validateFieldsByRules;
