import React from 'react';
import { connect } from 'react-redux';
import { change, untouch } from 'redux-form';
import { Map as IMap } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { addressFieldNames, shippingAddressSelected } from '../address/addressModule';
import { getFilteredStatesFromExcludedBillingStates } from '../utils/addressUtils';

import {
    addressFieldPropTypes,
    defaultFieldProps,
    COUNTRY_FIELDS,
    ADDRESS_CONTEXT
} from './addressFieldsModule';
import AddressFieldsMX from './AddressFieldsMX';
import AddressFieldsAU from './AddressFieldsAU';
import AddressFieldsBR from './AddressFieldsBR';
import AddressFieldsNL from './AddressFieldsNL';
import AddressFieldsCA from './AddressFieldsCA';
import AddressFieldsHK from './AddressFieldsHK';
import AddressFieldsIE from './AddressFieldsIE';
import AddressFieldsJP from './AddressFieldsJP';
import AddressFieldsGB from './AddressFieldsGB';
import AddressFieldsUS from './AddressFieldsUS';
import AddressFieldsCN from './AddressFieldsCN';
import AddressFieldsDEFAULT from './AddressFieldsDEFAULT';
import AddressFieldsRequiredPostal from './AddressFieldsRequiredPostal';
import { countriesRequiringPostalCode } from './addressValidation';





export class AddressFieldsSwitch extends React.Component {
    constructor(props) {
        super(props);
        this.fieldProps = this.fieldProps.bind(this);
    }

    
    componentDidUpdate(prevProps) {
        const {
            countryCode,
            item,
            street1,
            street2,
            city,
            state,
            postalCode,
            fieldChange,
            fieldUntouch,
            form,
            setAddressChanged,
            addressContext,
            excludedBillingStates
        } = this.props;
        

        
        if (prevProps.countryCode !== countryCode) {
            const filteredStates = getFilteredStatesFromExcludedBillingStates(excludedBillingStates);
            
            if (addressContext === ADDRESS_CONTEXT.SHIPPING
                || (addressContext === ADDRESS_CONTEXT.BILLING && filteredStates.length > 1)) {
                
                fieldChange(form, addressFieldNames.ADDRESS_STATE, '');
                fieldChange(form, addressFieldNames.ADDRESS_STREET1, '');
                fieldChange(form, addressFieldNames.ADDRESS_STREET2, '');
                fieldChange(form, addressFieldNames.ADDRESS_CITY, '');
                fieldChange(form, addressFieldNames.ADDRESS_POSTCODE, '');

                
                fieldUntouch(form, addressFieldNames.ADDRESS_STATE);
                fieldUntouch(form, addressFieldNames.ADDRESS_STREET1);
                fieldUntouch(form, addressFieldNames.ADDRESS_STREET2);
                fieldUntouch(form, addressFieldNames.ADDRESS_CITY);
                fieldUntouch(form, addressFieldNames.ADDRESS_POSTCODE);
            }
        }

        if (
            prevProps.street1 !== street1
            || prevProps.street2 !== street2
            || prevProps.city !== city
            || prevProps.state !== state
            || prevProps.postalCode !== postalCode
        ) {
            setAddressChanged(item, true);
        }
    }

    fieldProps() {
        const {
            addressContext,
            cashbotName,
            intl,
            showUntouchedErrors,
            updateShippingAddress,
            useAddressAutocomplete,
            excludedBillingStates,
            countryCode,
            isPDP
        } = this.props;
        return {
            addressContext,
            cashbotName,
            doFieldValidation: !isPDP,
            intl,
            showUntouchedErrors,
            updateShippingAddress,
            useAddressAutocomplete,
            excludedBillingStates,
            countryCode
        };
    }

    render() {
        const {
            countryCode
        } = this.props;
        if (typeof countryCode === 'string' && countryCode.length === 2) {
            if (countryCode === COUNTRY_FIELDS.CANADA) {
                return <AddressFieldsCA {...this.fieldProps()} />;
            } if (countryCode === COUNTRY_FIELDS.HONG_KONG) {
                return <AddressFieldsHK {...this.fieldProps()} />;
            } if (countryCode === COUNTRY_FIELDS.IRELAND) {
                return <AddressFieldsIE {...this.fieldProps()} />;
            } if (countryCode === COUNTRY_FIELDS.JAPAN) {
                return <AddressFieldsJP {...this.fieldProps()} />;
            } if (countryCode === COUNTRY_FIELDS.GREAT_BRITAIN) {
                return <AddressFieldsGB {...this.fieldProps()} />;
            } if (countryCode === COUNTRY_FIELDS.UNITED_STATES) {
                return <AddressFieldsUS {...this.fieldProps()} />;
            } if (countryCode === COUNTRY_FIELDS.CHINA) {
                return <AddressFieldsCN {...this.fieldProps()} />;
            } if (countryCode === COUNTRY_FIELDS.AUSTRALIA) {
                return <AddressFieldsAU {...this.fieldProps()} />;
            } if (countryCode === COUNTRY_FIELDS.MEXICO) {
                return <AddressFieldsMX {...this.fieldProps()} />;
            } if (countryCode === COUNTRY_FIELDS.BRAZIL) {
                return <AddressFieldsBR {...this.fieldProps()} />;
            } if (countryCode === COUNTRY_FIELDS.NETHERLANDS) {
                return <AddressFieldsNL {...this.fieldProps()} />;
            } if (countriesRequiringPostalCode.has(countryCode)) {
                return <AddressFieldsRequiredPostal {...this.fieldProps()} />;
            }
            return <AddressFieldsDEFAULT {...this.fieldProps()} />;
        }
        
        return <AddressFieldsUS {...this.fieldProps()} />;
    }
}

AddressFieldsSwitch.defaultProps = {
    ...defaultFieldProps,
    countryCode: 'US',
    item: IMap(),
    street1: '',
    street2: '',
    city: '',
    state: '',
    postalCode: '',
    phoneNumber: '',
    setAddressChanged: () => {}
};

AddressFieldsSwitch.propTypes = {
    ...addressFieldPropTypes,
    item: ImmutablePropTypes.map
};

export const mapDispatchToProps = {
    fieldChange: change,
    fieldUntouch: untouch,
    updateShippingAddress: (address, placeId) => shippingAddressSelected(address, placeId)
};

export default connect(null, mapDispatchToProps)(AddressFieldsSwitch);
