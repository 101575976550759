import React from 'react';
import PropTypes from 'prop-types';
import { FieldLabel } from 'cstar-react-primitives/lib/redux-form/microFields/FieldLabel';
import { FieldWrapper } from 'cstar-react-primitives/lib/redux-form/FieldWrapper';
import { FieldDivWrapper } from 'cstar-react-primitives/lib/redux-form/layout/FieldLayout';
import { FieldValidationMessage } from 'cstar-react-primitives/lib/redux-form/microFields/FieldValidationMessage';
import { inputAttributes } from 'cstar-react-primitives/lib/redux-form/microFields/fieldAttrsUtils';
import {
    getValidationProps,
    getAllFieldElementIds
} from 'cstar-react-primitives/lib/redux-form/microFields/fieldUtils';
import PlacesAutocomplete from './PlacesAutocomplete/PlacesAutocomplete';

export const AutocompleteItem = ({ formattedSuggestion }) => (
    <div>
        <strong>{ formattedSuggestion.mainText }</strong>
        {' '}
        <small>{ formattedSuggestion.secondaryText }</small>
    </div>
);

AutocompleteItem.propTypes = {
    formattedSuggestion: PropTypes.object.isRequired
};

const PlacesAutoInputGroup = (props) => {
    const {
        atts,
        input,
        meta,
        label,
        cashbotName,
        handleSelect,
        isRequiredField,
        showUntouchedErrors,
        countryCode
    } = props;
    const fieldIds = getAllFieldElementIds(input.name);
    const validationProps = getValidationProps(meta, input, false, showUntouchedErrors);
    const { isInvalid } = validationProps;
    const labelProps = {
        fieldName: input.name,
        label,
        isInvalid
    };
    const validationMsgProps = {
        id: fieldIds.validation.id,
        fieldName: input.name,
        validationProps
    };

    const inputProps = {
        ...inputAttributes({
            atts,
            input,
            label,
            isRequiredField,
            isInvalid
        }),
        value: input.value,
        autoFocus: false,
        autoComplete: 'nope', 
        onChange: address => input.onChange(address)
    };

    const cssClasses = {
        input: inputProps.className,
        autocompleteContainer: inputProps.className
    };

    const options = {
        componentRestrictions: { country: [countryCode] },
        types: ['address']
    };

    return (
        <FieldWrapper input={input} cashbotName={cashbotName}>
            <FieldLabel {...labelProps} />
            <FieldDivWrapper>
                <PlacesAutocomplete
                    inputProps={inputProps}
                    options={options}
                    classNames={cssClasses}
                    autocompleteItem={AutocompleteItem}
                    onSelect={handleSelect}
                />
            </FieldDivWrapper>
            <FieldValidationMessage {...validationMsgProps} />
        </FieldWrapper>
    );
};

PlacesAutoInputGroup.defaultProps = {
    isRequiredField: true,
    showUntouchedErrors: false,
    countryCode: 'US'
};

PlacesAutoInputGroup.propTypes = {
    atts: PropTypes.shape({
        maxLength: PropTypes.number.isRequired,
        type: PropTypes.oneOf(['email', 'tel', 'text'])
    }).isRequired,
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    cashbotName: PropTypes.string.isRequired,
    handleSelect: PropTypes.func.isRequired,
    isRequiredField: PropTypes.bool,
    showUntouchedErrors: PropTypes.bool,
    countryCode: PropTypes.string
};

export default PlacesAutoInputGroup;
