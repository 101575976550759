import React, { useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Map as IMap } from 'immutable';
import { Field } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { SelectGroup } from 'cstar-react-primitives/lib/redux-form/SelectGroup';
import { InputGroup } from 'cstar-react-primitives/lib/redux-form/InputGroup';
import {
    FieldLayoutSmall,
    FieldLayoutTwoColumn
} from 'cstar-react-primitives/lib/redux-form/layout/FieldLayout';

import { NAME_FIELD_MAX, FIRSTNAME_FIELD_MAX, LASTNAME_FIELD_MAX } from '../item/newItemForm';
import PhoneNumberField, { phoneNormalizer } from '../primitive/PhoneNumberField';
import getFieldCounterAriaLabel from '../primitive/getFieldCounterAriaLabel';
import { isFeatureEnabled, FEATURES } from '../utils/featureFlag';
import { addressFieldNames as fields } from '../address/addressModule';
import validatePhoneNumberRule from '../validate/validatePhoneNumberRule';
import translateErrorOrWarnings from '../validate/translateErrorOrWarning';

import PlacesAutoInputGroup from './PlacesAutoInputGroup';
import { defaultFieldValidators } from './addressValidation';
import { ADDRESS_CONTEXT } from './addressFieldsModule';
import addressFields from './addressFieldMessages';
import countryNameMap from './data/countryNameMap';
import { getFilteredStatesFromExcludedBillingStates } from '../utils/addressUtils';
import creditCardMessages from '../creditcard/creditCardMessages';

import { hasSelfBuyItems } from '../item/itemSelectors';
import { getSenderSMSDelivery } from '../sender/senderSelectors';
import { getSMSDialingCountries } from '../brand/brandSelectors';

export const NameFields = ({
    addressContext,
    cashbotName,
    doFieldValidation,
    doFocus,
    intl,
    showUntouchedErrors,
    validators,
    countryCode
}) => {
    const isShipping = addressContext === ADDRESS_CONTEXT.SHIPPING;
    const fieldCounterAriaLabel = getFieldCounterAriaLabel(intl);
    const nameLabel = isShipping
        ? intl.formatMessage(addressFields.COMMON.fullNameLabel)
        : intl.formatMessage(creditCardMessages.firstNameLabel);
    const firstNameFieldName = isShipping ? fields.ADDRESS_FULLNAME : fields.ADDRESS_FIRSTNAME;
    
    const firstNameValidator = useCallback(
        doFieldValidation ? translateErrorOrWarnings(validators.get(firstNameFieldName), intl) : null,
        [doFieldValidation, validators, addressContext, intl]
    );
    const firstNameProps = {
        name: firstNameFieldName,
        atts: {
            type: 'text',
            maxLength: (isShipping ? NAME_FIELD_MAX : FIRSTNAME_FIELD_MAX),
            autoComplete: 'cc-given-name'
        },
        label: nameLabel,
        component: InputGroup,
        cashbotName,
        validate: firstNameValidator,
        showUntouchedErrors,
        doFocus,
        hasMaxLengthCounter: true,
        counterAriaLabel: fieldCounterAriaLabel
    };
    const lastNameValidator = useCallback(
        doFieldValidation ? translateErrorOrWarnings(validators.get(fields.ADDRESS_LASTNAME), intl) : null,
        [doFieldValidation, validators, addressContext, intl]
    );
    const lastNameProps = {
        name: fields.ADDRESS_LASTNAME,
        atts: { type: 'text', maxLength: LASTNAME_FIELD_MAX, autoComplete: 'cc-family-name' },
        label: intl.formatMessage(creditCardMessages.lastNameLabel),
        component: InputGroup,
        cashbotName,
        showUntouchedErrors,
        validate: lastNameValidator,
        hasMaxLengthCounter: true,
        counterAriaLabel: fieldCounterAriaLabel
    };

    if (isShipping) {
        return <Field {...firstNameProps} />;
    }

    return (
        countryCode === 'JP'
            ? (
                <FieldLayoutTwoColumn mobileFullWidth>
                    <Field {...lastNameProps} />
                    <Field {...firstNameProps} />
                </FieldLayoutTwoColumn>
            ) : (
                <FieldLayoutTwoColumn mobileFullWidth>
                    <Field {...firstNameProps} />
                    <Field {...lastNameProps} />
                </FieldLayoutTwoColumn>
            )
    );
};

NameFields.defaultProps = {
    validators: defaultFieldValidators,
    doFieldValidation: false,
    showUntouchedErrors: false,
    doFocus: false,
    countryCode: 'US'
};

NameFields.propTypes = {
    addressContext: PropTypes.string.isRequired,
    cashbotName: PropTypes.string.isRequired,
    doFieldValidation: PropTypes.bool,
    doFocus: PropTypes.bool,
    intl: intlShape.isRequired,
    showUntouchedErrors: PropTypes.bool,
    validators: PropTypes.object,
    countryCode: PropTypes.string
};


const countryCodeToOptionMapper = intl => code => IMap({
    value: code,
    label: code ? intl.formatMessage(countryNameMap[code]) : '-----'
});


export const makeCountryOptions = (intl, countryList) => {
    const codeToOption = countryCodeToOptionMapper(intl);

    const preSeparatorCountries = countryList
        .takeWhile(code => !!code) 
        .map(codeToOption); 

    const postSeparatorCountries = countryList
        .skipWhile(code => !!code) 
        .shift() 
        .map(codeToOption) 
        .sortBy(option => option.get('label')); 

    let options = preSeparatorCountries;
    if (postSeparatorCountries.size) {
        options = options
            .push({ value: null, label: '-----' })
            .concat(postSeparatorCountries);
    }

    return options.toJS();
};

export const CountrySelectField = ({
    addressContext,
    billingCountries,
    cashbotName,
    intl,
    shippingCountries,
    showIntlBilling,
    showIntlShipping,
    showUntouchedErrors
}) => {
    const isBilling = addressContext === ADDRESS_CONTEXT.BILLING;
    const isShipping = addressContext === ADDRESS_CONTEXT.SHIPPING;
    const optionList = isBilling ? billingCountries : shippingCountries;
    const countrySelectProps = {
        name: fields.ADDRESS_COUNTRY,
        atts: { autoComplete: 'country' },
        label: intl.formatMessage(addressFields.COMMON.countryLabel),
        component: SelectGroup,
        cashbotName,
        options: makeCountryOptions(intl, optionList),
        showUntouchedErrors
    };

    if (isBilling && (!showIntlBilling || !billingCountries.size)) {
        return null;
    }

    if (isShipping && (!showIntlShipping || !shippingCountries.size)) {
        return null;
    }

    return (
        <Field {...countrySelectProps} />
    );
};

CountrySelectField.defaultProps = {
    showIntlBilling: false,
    showIntlShipping: false,
    showUntouchedErrors: false
};

CountrySelectField.propTypes = {
    addressContext: PropTypes.string.isRequired,
    billingCountries: PropTypes.object.isRequired,
    cashbotName: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    shippingCountries: PropTypes.object.isRequired,
    showIntlBilling: PropTypes.bool,
    showIntlShipping: PropTypes.bool,
    showUntouchedErrors: PropTypes.bool
};



export const Street1Field = ({
    cashbotName,
    doFieldValidation,
    intl,
    label,
    updateShippingAddress,
    useAddressAutocomplete,
    showUntouchedErrors,
    validate,
    countryCode
}) => {
    
    const validator = useCallback(
        doFieldValidation ? translateErrorOrWarnings(validate, intl) : null,
        [doFieldValidation, validate, intl]
    );

    const streetRef = useRef();
    const street1Props = {
        name: fields.ADDRESS_STREET1,
        atts: {
            type: 'text',
            maxLength: 100,
            ref: streetRef,
            autoComplete: 'address-line1'
        },
        label: intl.formatMessage(label),
        component: InputGroup,
        cashbotName,
        doFocus: false,
        showUntouchedErrors,
        validate: validator,
        hasMaxLengthCounter: true,
        counterAriaLabel: getFieldCounterAriaLabel(intl),
        countryCode
    };

    useEffect(() => {
        const listener = (event) => {
            const start = event.target.selectionStart;
            const end = event.target.selectionEnd;
            const elementName = document.activeElement.name;

            if (event.which === 38 && end !== 0 && elementName === 'street1') {
                streetRef.current.select();
                event.preventDefault();
            }
            if (event.which === 40 && start === 0 && elementName === 'street1') {
                streetRef.current.select();
                event.preventDefault();
            }
        };
        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, []);

    if (isFeatureEnabled(FEATURES.ADDRESS_AUTOCOMPLETE) && useAddressAutocomplete) {
        street1Props.component = PlacesAutoInputGroup;
        street1Props.handleSelect = updateShippingAddress;
    }
    return <Field {...street1Props} />;
};


Street1Field.defaultProps = {
    updateShippingAddress: () => {},
    doFieldValidation: false,
    useAddressAutocomplete: false,
    showUntouchedErrors: false,
    validate: null,
    countryCode: ''
};

Street1Field.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    doFieldValidation: PropTypes.bool,
    intl: intlShape.isRequired,
    label: PropTypes.object.isRequired,
    updateShippingAddress: PropTypes.func,
    useAddressAutocomplete: PropTypes.bool,
    showUntouchedErrors: PropTypes.bool,
    validate: PropTypes.func,
    countryCode: PropTypes.string
};


export const Street2Field = ({
    cashbotName,
    intl,
    label,
    showUntouchedErrors,
    validate,
    doFieldValidation
}) => {
    const validator = useCallback(
        doFieldValidation ? translateErrorOrWarnings(validate, intl) : null,
        [doFieldValidation, validate, intl]
    );

    const street2Props = {
        atts: { type: 'text', maxLength: 100, autoComplete: 'address-line2' },
        cashbotName,
        component: InputGroup,
        isRequiredField: false,
        label: intl.formatMessage(label),
        name: fields.ADDRESS_STREET2,
        showUntouchedErrors,
        validate: validator,
        hasMaxLengthCounter: true,
        counterAriaLabel: getFieldCounterAriaLabel(intl)
    };
    return <Field {...street2Props} />;
};

Street2Field.defaultProps = {
    showUntouchedErrors: false,
    doFieldValidation: false,
    validate: null
};

Street2Field.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    doFieldValidation: PropTypes.bool,
    intl: intlShape.isRequired,
    label: PropTypes.object.isRequired,
    showUntouchedErrors: PropTypes.bool,
    validate: PropTypes.func
};

export const CityField = ({
    cashbotName,
    doFieldValidation,
    intl,
    label,
    showUntouchedErrors,
    validate
}) => {
    
    const validator = useCallback(
        doFieldValidation ? translateErrorOrWarnings(validate, intl) : null,
        [doFieldValidation, validate, intl]
    );
    const cityProps = {
        atts: { type: 'text', maxLength: 35 },
        name: fields.ADDRESS_CITY,
        cashbotName,
        component: InputGroup,
        label: intl.formatMessage(label),
        showUntouchedErrors,
        validate: validator,
        hasMaxLengthCounter: true,
        counterAriaLabel: getFieldCounterAriaLabel(intl)
    };
    return <Field {...cityProps} />;
};

CityField.defaultProps = {
    validate: null,
    doFieldValidation: false,
    showUntouchedErrors: false
};

CityField.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    doFieldValidation: PropTypes.bool,
    intl: intlShape.isRequired,
    label: PropTypes.object.isRequired,
    showUntouchedErrors: PropTypes.bool,
    validate: PropTypes.func
};

export const StateSelectField = ({
    cashbotName,
    doFieldValidation,
    intl,
    label,
    options,
    showUntouchedErrors,
    validate,
    addressContext,
    excludedBillingStates
}) => {
    
    const validator = useCallback(
        doFieldValidation ? translateErrorOrWarnings(validate, intl) : null,
        [doFieldValidation, validate, intl]
    );
    const stateProps = {
        cashbotName,
        component: SelectGroup,
        label: intl.formatMessage(label),
        name: fields.ADDRESS_STATE,
        options,
        showUntouchedErrors,
        validate: validator,
        isDisabled: false
    };
    if (addressContext === ADDRESS_CONTEXT.BILLING
        && getFilteredStatesFromExcludedBillingStates(excludedBillingStates).length === 1) {
        stateProps.isDisabled = true;
    }
    return (
        <Field {...stateProps} />
    );
};

StateSelectField.defaultProps = {
    validate: null,
    doFieldValidation: false,
    showUntouchedErrors: false,
    excludedBillingStates: ''
};

StateSelectField.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    doFieldValidation: PropTypes.bool,
    intl: intlShape.isRequired,
    label: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    showUntouchedErrors: PropTypes.bool,
    validate: PropTypes.func,
    addressContext: PropTypes.string.isRequired,
    excludedBillingStates: PropTypes.string
};


export const StateField = ({
    cashbotName,
    doFieldValidation,
    intl,
    isRequiredField,
    label,
    showUntouchedErrors,
    validate
}) => {
    
    const validator = useCallback(
        doFieldValidation ? translateErrorOrWarnings(validate, intl) : null,
        [doFieldValidation, validate, intl]
    );
    const stateProps = {
        atts: {
            type: 'text',
            maxLength: 50 
        },
        cashbotName,
        component: InputGroup,
        isRequiredField,
        label: intl.formatMessage(label),
        name: fields.ADDRESS_STATE,
        showUntouchedErrors,
        validate: validator,
        hasMaxLengthCounter: true,
        counterAriaLabel: getFieldCounterAriaLabel(intl)
    };
    return (
        <FieldLayoutSmall>
            <Field {...stateProps} />
        </FieldLayoutSmall>
    );
};

StateField.defaultProps = {
    isRequiredField: true,
    doFieldValidation: false,
    showUntouchedErrors: false,
    validate: null
};

StateField.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    doFieldValidation: PropTypes.bool,
    intl: intlShape.isRequired,
    isRequiredField: PropTypes.bool,
    label: PropTypes.object.isRequired,
    showUntouchedErrors: PropTypes.bool,
    validate: PropTypes.func
};

export const PostalCodeField = ({
    cashbotName,
    doFieldValidation,
    fieldType,
    intl,
    isRequiredField,
    label,
    normalize,
    showUntouchedErrors,
    validate
}) => {
    
    const validator = useCallback(
        doFieldValidation ? translateErrorOrWarnings(validate, intl) : null,
        [doFieldValidation, validate, intl]
    );
    const postalCodeProps = {
        atts: {
            type: fieldType,
            maxLength: 10 ,
            autoComplete: 'postal-code'
        },
        cashbotName,
        component: InputGroup,
        hasMaxLengthCounter: false,
        isRequiredField,
        label: intl.formatMessage(label),
        name: fields.ADDRESS_POSTCODE,
        normalize,
        showUntouchedErrors,
        validate: validator
    };
    return (
        <Field {...postalCodeProps} />
    );
};

PostalCodeField.defaultProps = {
    fieldType: 'text', 
    isRequiredField: true,
    normalize: null,
    validate: null,
    doFieldValidation: false,
    showUntouchedErrors: false
};

PostalCodeField.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    doFieldValidation: PropTypes.bool,
    fieldType: PropTypes.string,
    intl: intlShape.isRequired,
    isRequiredField: PropTypes.bool,
    label: PropTypes.object.isRequired,
    normalize: PropTypes.func,
    showUntouchedErrors: PropTypes.bool,
    validate: PropTypes.func
};

export const PhoneField = ({
    addressContext,
    cashbotName,
    doFieldValidation,
    intl,
    showUntouchedErrors,
    validators,
    change
}) => {
    const hasSelfBuy = useSelector(hasSelfBuyItems) || false;
    const isSenderSMS = useSelector(getSenderSMSDelivery) || false;
    const isBilling = addressContext === ADDRESS_CONTEXT.BILLING;
    const phoneValidation = isBilling
        ? validators.get(fields.ADDRESS_PHONE)
        : validatePhoneNumberRule(addressFields.COMMON.phoneInvalid);
    const phoneLabelMessage = isBilling
        ? addressFields.COMMON.phoneLabelRequired : addressFields.COMMON.phoneLabelOptional;
    
    const phoneValidator = useCallback(
        doFieldValidation ? translateErrorOrWarnings(phoneValidation, intl) : null,
        [doFieldValidation, addressContext, intl]
    );
    const dialingCountries = useSelector(state => getSMSDialingCountries(state));
    const phoneProps = {
        name: fields.ADDRESS_PHONE,
        label: intl.formatMessage(phoneLabelMessage),
        isRequiredField: isBilling,
        cashbotName,
        showUntouchedErrors,
        validate: phoneValidator,
        countries: dialingCountries,
        change
    };
    const phoneFieldProps = {
        atts: {
            type: 'tel',
            maxLength: 15,
            minLength: 10,
            autoComplete: 'tel'
        },
        component: InputGroup,
        hasMaxLengthCounter: false,
        normalize: phoneNormalizer,
        ...phoneProps
    };
    if (addressContext === ADDRESS_CONTEXT.SHIPPING) {
        phoneProps.helper = intl.formatMessage(addressFields.SHIPPING.phoneHelper);
    }

    
    if (hasSelfBuy && isSenderSMS) {
        return <PhoneNumberField {...phoneProps} />;
    }
    return <Field {...phoneFieldProps} />;
};

PhoneField.defaultProps = {
    validators: defaultFieldValidators,
    doFieldValidation: false,
    showUntouchedErrors: false,
    change: () => {}
};

PhoneField.propTypes = {
    addressContext: PropTypes.string.isRequired,
    cashbotName: PropTypes.string.isRequired,
    doFieldValidation: PropTypes.bool,
    intl: intlShape.isRequired,
    showUntouchedErrors: PropTypes.bool,
    validators: PropTypes.object,
    change: PropTypes.func
};
