import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import { getAccessoryCostDisplay } from './accessoriesUtils';


export const AccessoryCost = ({ cost, intl, currencyCode }) => {
    const costDisplayValue = getAccessoryCostDisplay(cost, intl, currencyCode);

    return (
        <div className="accessory-price">
            { costDisplayValue }
        </div>
    );
};

AccessoryCost.propTypes = {
    cost: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    currencyCode: PropTypes.string.isRequired
};

export default AccessoryCost;
