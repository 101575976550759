import React from 'react';
import PropTypes from 'prop-types';
import { Seq } from 'immutable';
import { injectIntl, intlShape } from 'react-intl';
import { ContentSpacer } from 'cstar-react-primitives/lib/redux-form/layout/ContentSpacer';

import { giftCardFieldNames } from '../item/newItemForm';
import MessageInspCollapsed from './MessageInspCollapsed';
import MessageInspExpanded from './MessageInspExpanded';

export class MessageInspiration extends React.Component {
    constructor(props) {
        super(props);

        
        
        
        this.state = {
            expanded: false,
            focusCollapsedLink: false
        };

        this.showMessageInspiration = this.showMessageInspiration.bind(this);
        this.hideMessageInspiration = this.hideMessageInspiration.bind(this);
    }

    showMessageInspiration() {
        this.setState({
            expanded: true
        });
    }

    hideMessageInspiration() {
        this.setState({
            focusCollapsedLink: true,
            expanded: false
        });
    }

    render() {
        const { focusCollapsedLink, expanded } = this.state;
        const { options, intl } = this.props;

        const collapsedProps = {
            intl,
            onClick: this.showMessageInspiration,
            doFocus: focusCollapsedLink
        };
        const expandedProps = {
            intl,
            options,
            name: giftCardFieldNames.MESSAGE_FIELD,
            onClick: this.hideMessageInspiration
        };

        return (
            <ContentSpacer>
                { expanded
                    ? <MessageInspExpanded {...expandedProps} />
                    : <MessageInspCollapsed {...collapsedProps} />
                }
            </ContentSpacer>
        );
    }
}

MessageInspiration.propTypes = {
    intl: intlShape.isRequired,
    options: PropTypes.instanceOf(Seq).isRequired
};

export default injectIntl(MessageInspiration);
