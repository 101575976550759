import { Map as IMap } from 'immutable';
import { createSelector } from 'reselect';

import { messages } from '../intl/intlSelectors';

import {
    CONSENT_STORE_NAME,
    COOKIE_CONSENT_GIVEN,
    ACCEPTED_CARDHOLDER_AGREEMENT,
    ACCEPTED_GDPR_TERMS_AGREEMENT
} from './consentModule';
import { getCardholderAgreementFormat, getGdprTermsAcknowledgementFormat } from '../brand/brandSelectors';



export const getConsentState = state => state.get(CONSENT_STORE_NAME, IMap());


export const getCookieConsentGiven = createSelector(
    getConsentState,
    cookieConsentState => cookieConsentState.get(COOKIE_CONSENT_GIVEN, false)
);


export const getCardholderAgreementAcceptance = createSelector(
    getConsentState,
    getCardholderAgreementFormat,
    (consentState, cardholderAgreementFormat) => (
        (cardholderAgreementFormat === 'EXPLICIT' || cardholderAgreementFormat === 'HOSTED')
            ? consentState.get(ACCEPTED_CARDHOLDER_AGREEMENT, false)
            : true)
);


export const getCardholderAgreementModalContent = createSelector(
    messages,
    msgs => msgs.get('consent.cardholderAgreementModalMessages.cardholderAgreementContent.md', '')
);


export const getGdprTermsAgreementAcceptance = createSelector(
    getConsentState,
    getGdprTermsAcknowledgementFormat,
    (consentState, gdprTermsAcknowledgementFormat) => (
        gdprTermsAcknowledgementFormat === 'EXPLICIT'
            ? consentState.get(ACCEPTED_GDPR_TERMS_AGREEMENT, false)
            : true)
);
