import { List as IList, Map as IMap } from 'immutable';
import { createSelector } from 'reselect';

import { programStepCompleted } from '../program/programStepSelectors';
import {
    IS_SELFBUY_FIELD, newItemIsPlastic, newItemIsSelfBuy, RECIPIENT_NAME_FIELD, SENDER_NAME_FIELD
} from '../item/newItemForm';
import { getActiveProgramCode } from '../program/programSelectors';
import {
    faceplateForCode,
    isFaceplateDigital,
    isFaceplatePhysical
} from '../faceplate/faceplateSelectors';
import {
    getFlowProgress,
    getPreferredCardType,
    getPreferredFaceplateCode
} from '../routing/flowSelectors';
import {
    DEFAULT_ELECTRONIC_FACEPLATES,
    DEFAULT_PHYSICAL_FACEPLATES,
    getDefaultFaceplatesForProgram
} from '../program/programListSelectors';



export const recipientStepCompleted = createSelector(
    getFlowProgress,
    newItemIsSelfBuy,
    (flowState, isSelfBuy) => (
        isSelfBuy
            ? flowState.has(IS_SELFBUY_FIELD)
            : (
                flowState.has(RECIPIENT_NAME_FIELD)
                && flowState.has(SENDER_NAME_FIELD)
                && flowState.has(IS_SELFBUY_FIELD))
    )
);


export const recipientStepPreConditions = IList([
    programStepCompleted
]);


export const recipientStepPostConditions = IList([
    recipientStepCompleted
]);


const getPreferredFaceplateInfo = (state, preferredFaceplateCode) => {
    const preferredCardTypeIsPlastic = getPreferredCardType(state);
    if (preferredCardTypeIsPlastic !== null) {
        if (preferredCardTypeIsPlastic && isFaceplatePhysical(state)(preferredFaceplateCode)) {
            return { faceplate: faceplateForCode(state, preferredFaceplateCode), showFaceplate: true };
        }
        if (!preferredCardTypeIsPlastic && isFaceplateDigital(state)(preferredFaceplateCode)) {
            return { faceplate: faceplateForCode(state, preferredFaceplateCode), showFaceplate: true };
        }
    }
    return { faceplate: IMap(), showFaceplate: false };
};


export const shouldDisplayFaceplate = (state) => {
    const preferredFaceplateCode = getPreferredFaceplateCode(state);
    const programCode = getActiveProgramCode(state);
    const faceplateType = newItemIsPlastic(state) ? DEFAULT_PHYSICAL_FACEPLATES : DEFAULT_ELECTRONIC_FACEPLATES;
    if (programCode) {
        const faceplates = getDefaultFaceplatesForProgram(state)(programCode, faceplateType);
        const showFaceplate = faceplates && faceplates.size > 0;
        return { faceplates, showFaceplate };
    }
    if (preferredFaceplateCode) {
        return getPreferredFaceplateInfo(state, preferredFaceplateCode);
    }
    return { faceplate: IMap(), showFaceplate: false, faceplates: IList() };
};
