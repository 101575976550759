import { takeLatest, put } from 'redux-saga/effects';

import { IS_PLASTIC_FIELD, ITEM_ACCESSORY } from '../item/newItemForm';
import { changeValue, fieldChangedAction } from '../item/newItemFormModule';
import { applyActivePromoPreCart } from '../promo/promoModule';
import { GET_ACCESSORY_PROMO_DATA } from './accessoriesModule';

const IS_PLASTIC_FIELD_CHANGED = fieldChangedAction(IS_PLASTIC_FIELD);

export function* getAccessoryPromoSaga() {
    yield put(applyActivePromoPreCart(false, false));
}

export function* resetAccessorySaga() {
    yield put(changeValue(ITEM_ACCESSORY, null));
}

export function* watchAccessoriesSagas() {
    yield takeLatest(GET_ACCESSORY_PROMO_DATA, getAccessoryPromoSaga);
    yield takeLatest(IS_PLASTIC_FIELD_CHANGED, resetAccessorySaga);
}
