import { defineMessages } from 'react-intl';

const timezoneMessages = defineMessages({
    timezoneFieldLabel: {
        id: 'field.timezoneFieldMessages.timezoneField.label',
        description: 'label for the field that displays timezone of the delivery time of day',
        defaultMessage: `{name}'s Timezone (optional)`
    },
    timezoneFieldLabelNoName: {
        id: 'field.timezoneFieldMessages.timezoneFieldLabelNoName.label',
        description: 'label for timezone field when we have no recipient name',
        defaultMessage: `Recipient's Timezone (optional)`
    }
});

export default timezoneMessages;
