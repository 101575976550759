import axios from 'axios';

import { logAxiosError } from '../utils/errorUtils';
import { getDefaultLocaleCode } from '../app/bootstrap';



export const getStaticUrl = () => {
    if (window.BOOTSTRAP_DATA) {
        return window.BOOTSTRAP_DATA.staticUrl;
    }
    return undefined;
};


export const getMessagesFromUrl = async (url) => {
    try {
        const response = await axios.get(url);
        
        if (typeof response.data === 'object') {
            return response.data;
        }
    } catch (error) {
        logAxiosError(error);
        return error.response.status;
    }
    return 0;
};


export const getCopy = async (brandCode, locale, staticUrl = getStaticUrl()) => {
    let copy = {};

    if (staticUrl) {
        const localePath = locale.replace('-', '/');
        let defaultLocalePath = getDefaultLocaleCode() || 'en-us';
        defaultLocalePath = defaultLocalePath.replace('-', '/');
        const urls = [
            `${staticUrl}/messages/${brandCode}/${localePath}.json`,
            `${staticUrl}/messages/DEFAULT/${localePath}.json`,
            `${staticUrl}/messages/${brandCode}/${defaultLocalePath}.json`
        ];
        
        
        for (let i = 0; i < urls.length; i++) {
            const response = await getMessagesFromUrl(urls[i]);
            if (typeof response === 'object') {
                copy = response;
            }
            if (response !== 0 && response !== 404) {
                return copy;
            }
        }
    }
    
    return copy;
};
