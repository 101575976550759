import React from 'react';
import PropTypes from 'prop-types';


export const LoadingSpinner = ({ overlay, children }) => {
    const renderLoadingSpinnerNode = () => (
        <React.Fragment>
            <div className="loading-spinner">{children}</div>
        </React.Fragment>
    );

    const renderLoadingSpinnerWithOverlay = () => (
        <div className="loading-spinner--overlay">
            {renderLoadingSpinnerNode()}
        </div>
    );

    return (
        <div>
            {overlay
                ? renderLoadingSpinnerWithOverlay()
                : renderLoadingSpinnerNode()}
        </div>
    );
};

LoadingSpinner.defaultProps = {
    overlay: false,
    children: null
};

LoadingSpinner.propTypes = {
    overlay: PropTypes.bool,
    children: PropTypes.node
};

export default LoadingSpinner;
