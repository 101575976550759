import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { intlShape } from 'react-intl';
import { guessUserTimezone } from '@cashstar/react-timezone';
import moment from 'moment-timezone';
import { LoadingIndicator } from '../primitive/LoadingIndicator';
import { getCalendarLocale, getFutureSendIsLoading, getNextTimeInterval } from './futureSendSelectors';
import FutureSendLink from './FutureSendLink';
import FutureSendSubHeader from './FutureSendSubHeader';
import DateField from './react-dates/DateField';
import TimePicker from './time/TimePicker';
import { parseSendDateTime } from './futureSendUtils';
import { newItemFutureSendTimezone } from '../item/newItemForm';
import { TimeZoneProp } from './time/timeProps';
import get12HourTime from '../utils/time/get12HourTime';
import getNowTimestamp from '../utils/time/getNowTimestamp';


export const getInitialState = (initialDate, initialTimezone, today) => {
    const originalDateTime = new Date(initialDate);

    const originalTimeZone = initialTimezone || fromJS(guessUserTimezone());
    const originalMomentDateTime = moment(originalDateTime.toISOString()).tz(originalTimeZone.get('zoneName'));
    const originalMomentToday = moment(today.toISOString()).tz(originalTimeZone.get('zoneName'));

    const originalYear = originalMomentDateTime.year();
    const originalMonth = originalMomentDateTime.month();
    const originalDay = originalMomentDateTime.date();
    const originalMinutes = originalMomentDateTime.minutes();
    const [originalHour, originalMeridiem] = get12HourTime(originalMomentDateTime.hour());
    return {
        originalYear,
        originalMonth,
        originalDay,
        originalHour,
        originalMinutes,
        originalMeridiem,
        originalTimeZone,
        originalDateTime,
        originalMomentToday
    };
};

export const FutureSend = ({
    intl,
    cashbotName,
    recipientName,
    handleDateTimeChange,
    handleTimeChange,
    handleTimeZoneChange,
    initialDate,
    calendarLocale,
    loading,
    initialTimezone,
    today,
    focusSendTimeLink,
    setFocusSendTimeLink,
    isShareViaLinkDeliveryOptionEnabled
}) => {
    

    const {
        originalYear,
        originalMonth,
        originalDay,
        originalHour,
        originalMinutes,
        originalMeridiem,
        originalTimeZone,
        originalDateTime,
        originalMomentToday
    } = getInitialState(initialDate, initialTimezone, today);

    const [year, setYear] = useState(originalYear);
    const [month, setMonth] = useState(originalMonth);
    const [day, setDay] = useState(originalDay);
    const [hour, setHour] = useState(originalHour);
    const [minute, setMinute] = useState(originalMinutes);
    const [meridiem, setMeridiem] = useState(originalMeridiem);
    const [timeZone, setTimeZone] = useState(originalTimeZone);
    const [sendDateTime, setSendDateTime] = useState(originalDateTime);

    const isInitialDate = (
        today.getDate() === day && today.getMonth() === month && today.getFullYear() === year);
    const initialHasTimePicker = (Boolean(initialTimezone) && !isInitialDate);

    const [hasTimePicker, setShowTimeFields] = useState(initialHasTimePicker);

    
    const showTimeFields = () => {
        const nextInterval = getNextTimeInterval(originalHour, originalMinutes, originalMeridiem);

        setHour(nextInterval.hour);
        setMinute(nextInterval.minute);
        setMeridiem(nextInterval.meridiem);

        setShowTimeFields(true);
    };

    const resetTime = () => {
        setShowTimeFields(false);

        setHour(originalMomentToday.hour());
        setMinute(originalMomentToday.minutes());
        setMeridiem(originalMeridiem);
        setTimeZone(originalTimeZone);
    };

    
    
    useEffect(() => {
        handleDateTimeChange(sendDateTime);
        handleTimeChange(sendDateTime);
    }, [sendDateTime]);

    
    useEffect(() => {
        setSendDateTime(parseSendDateTime(year, month, day, hour, minute, meridiem, timeZone.get('zoneName')));
    }, [year, month, day, hour, minute, meridiem, timeZone.get('zoneAbbr')]);

    
    useEffect(() => {
        handleTimeZoneChange(timeZone);
    }, [timeZone.get('zoneAbbr')]);

    const onDateChange = useCallback((newDate) => {
        setDay(newDate.getDate());
        setMonth(newDate.getMonth());
        setYear(newDate.getFullYear());
    }, []);

    
    const dateProps = {
        intl,
        cashbotName,
        onDateChange,
        onReset: resetTime,
        timeZone,
        setFocusSendTimeLink
    };
    const timeProps = {
        intl,
        cashbotName,
        isInitialDate,
        recipientName,
        hour,
        onHourChange: setHour,
        minute,
        onMinuteChange: setMinute,
        meridiem,
        onMeridiemChange: setMeridiem,
        timeZone,
        onTimeZoneChange: setTimeZone,
        hasTimePicker
    };
    const linkProps = {
        cashbotName,
        hasTimePicker,
        handleShowTimeFields: showTimeFields,
        handleReset: resetTime,
        doFocus: focusSendTimeLink !== null ? focusSendTimeLink : false
    };

    if (loading) {
        
        return <LoadingIndicator overlay />;
    }
    return (
        
        <div key={calendarLocale}>
            {!isShareViaLinkDeliveryOptionEnabled && <FutureSendSubHeader />}
            <DateField {...dateProps} />
            <TimePicker {...timeProps} />
            { !isInitialDate && <FutureSendLink {...linkProps} /> }
        </div>
    );
};

FutureSend.defaultProps = {
    loading: false,
    initialTimezone: null,
    focusSendTimeLink: false,
    setFocusSendTimeLink: () => {}
};

FutureSend.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    recipientName: PropTypes.string.isRequired,
    handleDateTimeChange: PropTypes.func.isRequired,
    handleTimeChange: PropTypes.func.isRequired,
    handleTimeZoneChange: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    calendarLocale: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    initialDate: PropTypes.instanceOf(Date).isRequired,
    initialTimezone: TimeZoneProp,
    today: PropTypes.instanceOf(Date).isRequired,
    focusSendTimeLink: PropTypes.bool,
    setFocusSendTimeLink: PropTypes.func,
    isShareViaLinkDeliveryOptionEnabled: PropTypes.bool.isRequired
};

export const mapStateToProps = state => ({
    calendarLocale: getCalendarLocale(state), 
    loading: getFutureSendIsLoading(state),
    initialTimezone: newItemFutureSendTimezone(state),
    today: new Date(getNowTimestamp())
});

export default connect(mapStateToProps)(FutureSend);
