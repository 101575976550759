import { defineMessages } from 'react-intl';

const modalMessages = defineMessages({
    ariaLabel: {
        id: 'modal.modalMessages.ariaLabel',
        description: 'aria-label of the modal close widget - for screen readers not visible to users',
        defaultMessage: 'modal-close'
    },
    close: {
        id: 'modal.modalMessages.close',
        description: 'Default button text for application modals to close the modal window',
        defaultMessage: 'Done'
    }
});

export default modalMessages;
