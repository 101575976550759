
export const ABORT_APPLE_PAY_SESSION = '@@storefront/ABORT_APPLE_PAY_SESSION';
export const COMPLETE_MERCHANT_VALIDATION = '@@storefront/COMPLETE_MERCHANT_VALIDATION';
export const COMPLETE_APPLE_PAY_PAYMENT = '@@storefront/COMPLETE_APPLE_PAY_PAYMENT';
export const FETCH_MERCHANT_SESSION = '@@storefront/FETCH_MERCHANT_SESSION';



export function abortApplePaySession(applePaySession) {
    return {
        type: ABORT_APPLE_PAY_SESSION,
        applePaySession
    };
}


export const completePayment = (paymentEvent, validationMessages) => ({
    type: COMPLETE_APPLE_PAY_PAYMENT,
    paymentEvent,
    validationMessages
});


export function fetchMerchantSession(validateMerchantEvent) {
    return {
        type: FETCH_MERCHANT_SESSION,
        validateMerchantEvent
    };
}


export function completeMerchantValidation(applePaySession, merchantSession) {
    return {
        type: COMPLETE_MERCHANT_VALIDATION,
        applePaySession,
        merchantSession
    };
}
