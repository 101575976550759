import { createSelector } from 'reselect';
import {
    fromJS, Map as IMap, Set as ISet, List as IList
} from 'immutable';

import { FEATURES, createSelectorWhenEnabled } from '../utils/featureFlag';
import { getCartProgramCodes, getCurrencyCodes, getPaidCardsCount } from '../item/itemSelectors';
import { getBrandCatalogs, getBrandElectronicCurrencyList, getBrandPlasticCurrencyList } from '../brand/brandSelectors';
import {
    getIntlLocale,
    intlForProgram,
    messages,
    messagesForProgram
} from '../intl/intlSelectors';
import { getPreferredCurrencyCode } from '../routing/flowSelectors';

import {
    getProgramConfigs,
    getProgramElectronicCurrencyList,
    getProgramPlasticCurrencyList,
    getProgramSelectorList,
    getPublishedProgramsList,
    isProgramSelectionEnabled,
    getProgramIncludedBillingStates
} from './programSelectors';
import { optionalProgramMessageIds } from './programMessages';


export const DEFAULT_PHYSICAL_FACEPLATES = 'defaultPhysicalFaceplates';
export const DEFAULT_ELECTRONIC_FACEPLATES = 'defaultElectronicFaceplates';


export const getSelectableProgramsInfo = createSelector(
    getPublishedProgramsList,
    getProgramSelectorList,
    getCurrencyCodes,
    getBrandCatalogs,
    getPreferredCurrencyCode,
    getCartProgramCodes,
    getProgramIncludedBillingStates,
    (
        publishedProgramsList,
        programSelectorList,
        cartCurrencyCodes,
        brandCatalogs,
        preferredCurrencyCode,
        cartProgramCodes,
        programIncludedBillingStates
    ) => {
        
        const publishedListWithDefault = publishedProgramsList.push(fromJS({ programCode: '' }));
        let selectablePrograms = [];
        let isProgramsListReducedByBillingStateConflicts = false;
        let conflictingCartProgramCode;
        programSelectorList.forEach((selectorProgram) => {
            const selectableProgram = publishedListWithDefault.find(
                publishedProgram => publishedProgram.get('programCode') === selectorProgram.get('programCode')
            );
            if (selectableProgram) {
                selectablePrograms.push(selectableProgram);
            }
        });
        
        
        
        
        
        if (preferredCurrencyCode && cartCurrencyCodes.isEmpty()) {
            selectablePrograms = selectablePrograms.filter((program) => {
                const isProgram = program.has('catalogs');
                return isProgram ? program.getIn(['catalogs', preferredCurrencyCode])
                    : brandCatalogs.get(preferredCurrencyCode);
            });
        }

        
        if (cartCurrencyCodes.size > 0) {
            selectablePrograms = selectablePrograms.filter((program) => {
                const isProgram = program.has('catalogs');
                return isProgram ? program.getIn(['catalogs', cartCurrencyCodes.first()])
                    : brandCatalogs.get(cartCurrencyCodes.first());
            });
        }

        
        if (cartProgramCodes.size > 0) {
            
            const cartRestrictions = [];
            cartProgramCodes.forEach((programCode) => {
                const programBillingStates = programIncludedBillingStates(programCode);
                if (programBillingStates) {
                    cartRestrictions.push(programBillingStates);
                }
            });

            
            if (cartRestrictions.length > 0) {
                const cartBillingStates = cartRestrictions.reduce((previous, current) => previous.intersect(current));
                selectablePrograms = selectablePrograms.filter((program) => {
                    const programBillingStates = programIncludedBillingStates(program.get('programCode'));
                    if (programBillingStates) {
                        
                        const intersectingStates = cartBillingStates.intersect(programBillingStates);
                        if (intersectingStates.size > 0) {
                            return true;
                        }

                        
                        cartProgramCodes.forEach((programCode) => {
                            const cartItemBillingStates = programIncludedBillingStates(programCode);
                            if (cartItemBillingStates
                                && programBillingStates.intersect(cartItemBillingStates).size === 0) {
                                conflictingCartProgramCode = programCode;
                            }
                        });
                        isProgramsListReducedByBillingStateConflicts = true;
                        return false;
                    }
                    
                    return true;
                });
            }
        }

        return IMap({
            programs: fromJS(selectablePrograms),
            isReducedByBillingStateConflicts: isProgramsListReducedByBillingStateConflicts,
            conflictingCartProgramCode
        });
    }
);


export const getShowProgramSelection = createSelectorWhenEnabled(FEATURES.PROGRAM_PICKER, false)(
    isProgramSelectionEnabled,
    getSelectableProgramsInfo,
    (isEnabled, programsInfo) => {
        
        if (!isEnabled) {
            return false;
        }
        
        if (programsInfo.get('programs').isEmpty()) {
            return false;
        }
        return true;
    }
);


export const getClassicCardDescription = createSelector(
    messages,
    msgs => msgs.get(optionalProgramMessageIds.classicCardDescription, '')
);


export const getCustomCardMessages = createSelector(
    messages,
    msgs => (
        fromJS({
            customCardHeader: msgs.get(optionalProgramMessageIds.customCardHeader, ''),
            customCardTitle: msgs.get(optionalProgramMessageIds.customCardTitle, ''),
            customCardDescription: msgs.get(optionalProgramMessageIds.customCardDescription, ''),
            customCardImage: msgs.get(optionalProgramMessageIds.customCardImage, ''),
            customCardActionButton: msgs.get(optionalProgramMessageIds.customCardActionButton, ''),
            customCardActionUrl: msgs.get(optionalProgramMessageIds.customCardActionUrl, ''),
            customCardImageAlt: msgs.get(optionalProgramMessageIds.customCardImageAlt, '')
        })
    )
);


export const getProgramTitleWithProvidedProgramCode = (state, programCode) => {
    const intl = intlForProgram(state);
    const programMessages = intl(programCode);
    const msgs = fromJS(programMessages.messages);
    return fromJS({
        programTitle: msgs.get('program.programMessages.programTitle', '')
    });
};


export const getAlternateImageForProgram = createSelector(
    messagesForProgram,
    programMessages => (programCode) => {
        
        
        
        
        
        
        const imageMessageId = programCode
            ? `${programCode}.${optionalProgramMessageIds.pickerPreviewImage}`
            : optionalProgramMessageIds.pickerPreviewImage;
        const altTextMessageId = programCode
            ? `${programCode}.${optionalProgramMessageIds.pickerPreviewImageAlt}`
            : optionalProgramMessageIds.pickerPreviewImageAlt;
        return fromJS({
            previewImageFilename: programMessages(programCode).get(imageMessageId),
            previewImageAltText: programMessages(programCode).get(altTextMessageId)
        });
    }
);


export const getDefaultFaceplateForProgram = createSelector(
    getProgramSelectorList,
    getIntlLocale,
    (programList, locale) => (programCode, faceplateType, brandCode) => {
        
        
        const programConfig = programList.find(program => program.get('programCode') === (programCode || ''));
        if (!programConfig) {
            return IMap();
        }
        return programConfig.getIn([faceplateType, locale, brandCode], IMap());
    }
);

export const getDefaultFaceplatesForProgram = createSelector(
    getProgramSelectorList,
    getIntlLocale,
    (programList, locale) => (programCode, faceplateType) => {
        
        
        const programConfig = programList.find(program => program.get('programCode') === (programCode || ''));
        if (!programConfig) {
            return IList();
        }
        const faceplateMap = programConfig.getIn([faceplateType, locale], IMap());
        return faceplateMap.valueSeq().toList();
    }
);


export const getAllAvailableCurrencies = createSelector(
    getBrandPlasticCurrencyList,
    getBrandElectronicCurrencyList,
    getProgramElectronicCurrencyList,
    getProgramPlasticCurrencyList,
    getPublishedProgramsList,
    (
        plasticCurrencies,
        electronicCurrencies,
        programElectronicCurrencyList,
        programPhysicalCurrencyList,
        programList
    ) => {
        const programCurrencies = programList.flatMap((program) => {
            const programCode = program.get('programCode');
            return programPhysicalCurrencyList(programCode).concat(programElectronicCurrencyList(programCode));
        });
        return ISet(plasticCurrencies).union(electronicCurrencies).union(programCurrencies);
    }
);


export const getShowCurrencyCodeOnProgramTiles = createSelector(
    getAllAvailableCurrencies,
    getPaidCardsCount,
    (allAvailableCurrencies, paidCardsCount) => (
        paidCardsCount === 0 && allAvailableCurrencies.size > 1
    )
);


export const getShowCurrencyListOnProgramTiles = createSelector(
    getAllAvailableCurrencies,
    getPaidCardsCount,
    (allAvailableCurrencies, paidCardsCount) => (
        paidCardsCount === 0 && allAvailableCurrencies.size > 1
    )
);


export const getExcludedBillingStatesForProgram = createSelector(
    getProgramConfigs,
    programConfigs => (programCode) => {
        const programConfig = programConfigs.get(programCode);
        return programConfig ? programConfig.get('excludedBillingStates') : '';
    }
);


export const getIncludedBillingStatesForProgram = createSelector(
    getProgramConfigs,
    programConfigs => (programCode) => {
        const programConfig = programConfigs.get(programCode);
        return programConfig ? programConfig.get('includedBillingStates') : '';
    }
);

export const getCartExcludedBillingStatesForProgram = createSelector(
    [getCartProgramCodes, getProgramConfigs],
    (cartProgramCodes, programConfigs) => {
        let result = '';
        cartProgramCodes.forEach((code) => {
            const program = programConfigs.get(code);
            if (program) {
                const states = program.get('excludedBillingStates');
                if (states) {
                    result = [...ISet([...result, ...states.split(',')])];
                }
            }
        });
        return [...result].toString();
    }
);
