import { defineMessages } from 'react-intl';

const senderValidationMessages = defineMessages({
    senderEmailRequired: {
        id: 'sender.senderValidationMessages.senderEmailRequired',
        description: 'Error displayed when no card sender email was provided',
        defaultMessage: 'Please enter your email'
    },
    senderEmailInvalid: {
        id: 'sender.senderValidationMessages.senderEmailInvalid',
        description: 'Error displayed when an invalid card sender email was provided',
        defaultMessage: 'Please enter a valid email address'
    }
});

export default senderValidationMessages;
