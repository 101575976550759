import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { useDispatch } from 'react-redux';
import { List as IList } from 'immutable';
import { InnerPrimaryButton } from 'cstar-react-primitives/lib/redux-form/buttons/PrimaryButton';

import CurrencyPickerField from '../currency/CurrencyPickerField';
import { giftCardFieldNames } from '../item/newItemForm';
import { changeValue } from '../item/newItemFormModule';
import CurrencyPickerDisabled from '../currency/CurrencyPickerDisabled';
import { setCurrency, translateCurrency } from '../intl/intlModule';

import cardTypeMessages from './cardTypeMessages';


export const DIGITAL_MSG_KEY = 'digitalDescriptionLine';
export const PHYSICAL_MSG_KEY = 'physicalDescriptionLine';
export const DIGITAL_SHARE_VIA_LINK_MSG_KEY = 'digitalShareViaLinkDescriptionLine';


export const createLineList = (messageKey, intl) => {
    const allowedKeys = [];
    Object.keys(cardTypeMessages).forEach((key) => {
        if (key.startsWith(messageKey)) {
            allowedKeys.push(key);
        }
    });
    const lineList = allowedKeys.sort().map(key => (
        <li key={key}>
            {intl.messages[cardTypeMessages[key].id] !== '' && (
                <FormattedMessage {...cardTypeMessages[key]} />
            )}
        </li>
    ));

    return lineList;
};


const getCurrencyPicker = (allowChangingCurrency, currencyPickerProps, currencyCodeButtonsProps) => {
    if (allowChangingCurrency) {
        return <CurrencyPickerField {...currencyPickerProps} />;
    }
    return <CurrencyPickerDisabled {...currencyCodeButtonsProps} />;
};

export const CardTypeOption = ({
    isPlastic,
    selectedCurrency,
    prevIsPlastic,
    submitAction,
    cardHeading,
    messageKey,
    legend,
    currencyList,
    btnText,
    showCurrencyPicker,
    allowChangingCurrency,
    morePlasticCardsAllowed,
    intl
}) => {
    const currencyPickerName = isPlastic ? 'physical_currency_picker' : 'digital_currency_picker';
    const buttonName = isPlastic ? 'physical_card' : 'digital_card';
    const [pickerCurrency, setPickerCurrency] = useState(translateCurrency(selectedCurrency));
    const dispatch = useDispatch();

    const onButtonClick = () => {
        
        if (isPlastic !== prevIsPlastic) {
            dispatch(changeValue(giftCardFieldNames.IS_PLASTIC_FIELD, isPlastic));
        }

        if (translateCurrency(pickerCurrency) !== translateCurrency(selectedCurrency)) {
            dispatch(setCurrency(translateCurrency(pickerCurrency)));
        }

        submitAction({ isPlastic });
    };

    const onCurrencySelect = (newCurrency) => {
        setPickerCurrency(newCurrency);
    };

    const currencyPickerProps = {
        name: currencyPickerName,
        cashbotName: 'cardType-currency-picker',
        legend,
        currencyList,
        selectedCurrency,
        onChange: onCurrencySelect
    };

    const currencyCodeButtonsProps = {
        name: currencyPickerName,
        cashbotName: 'cardType-currency-code-buttons',
        currencyList,
        selectedCurrency
    };

    const InnerPrimaryButtonProps = {
        name: buttonName,
        cashbotName: 'cardType-btn',
        onClick: onButtonClick,
        type: 'button',
        btnText,
        disabled: isPlastic && !morePlasticCardsAllowed
    };

    return (
        <div className="decision-card">
            <h2>{cardHeading}</h2>
            <ul>
                { createLineList(messageKey, intl) }
            </ul>
            {
                showCurrencyPicker
                && getCurrencyPicker(allowChangingCurrency, currencyPickerProps, currencyCodeButtonsProps)
            }
            <InnerPrimaryButton {...InnerPrimaryButtonProps} />
        </div>
    );
};

CardTypeOption.defaultProps = {
    doFocus: false,
    isPlastic: false,
    morePlasticCardsAllowed: true,
    prevIsPlastic: false,
    showCurrencyPicker: false,
    allowChangingCurrency: false
};

CardTypeOption.propTypes = {
    doFocus: PropTypes.bool,
    cardHeading: PropTypes.string.isRequired,
    messageKey: PropTypes.oneOf([
        DIGITAL_MSG_KEY,
        PHYSICAL_MSG_KEY,
        DIGITAL_SHARE_VIA_LINK_MSG_KEY
    ]).isRequired,
    isPlastic: PropTypes.bool,
    morePlasticCardsAllowed: PropTypes.bool,
    prevIsPlastic: PropTypes.bool,
    showCurrencyPicker: PropTypes.bool,
    allowChangingCurrency: PropTypes.bool,
    selectedCurrency: PropTypes.string.isRequired,
    currencyList: PropTypes.instanceOf(IList).isRequired,
    legend: PropTypes.string.isRequired,
    submitAction: PropTypes.func.isRequired,
    btnText: PropTypes.string.isRequired,
    intl: intlShape.isRequired
};

export default injectIntl(CardTypeOption);
