import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { getAddress } from './addressSelectors';
import { addressFieldNames as fields } from './addressModule';


export const AddressNameLine = ({ address }) => {
    const fullName = address.get(fields.ADDRESS_FULLNAME);
    const country = address.get(fields.ADDRESS_COUNTRY);

    if (fullName) {
        return <p>{fullName}</p>;
    }
    if (country && country === 'JP') {
        return <p>{ `${address.get(fields.ADDRESS_LASTNAME)} ${address.get(fields.ADDRESS_FIRSTNAME)}` }</p>;
    }
    return <p>{ `${address.get(fields.ADDRESS_FIRSTNAME)} ${address.get(fields.ADDRESS_LASTNAME)}` }</p>;
};

AddressNameLine.propTypes = {
    address: ImmutablePropTypes.contains({
        [fields.ADDRESS_FIRSTNAME]: PropTypes.string,
        [fields.ADDRESS_LASTNAME]: PropTypes.string,
        [fields.ADDRESS_FULLNAME]: PropTypes.string,
        [fields.ADDRESS_COUNTRY]: PropTypes.string
    }).isRequired
};


export const AddressDisplay = ({
    address, hidePhoneNumber, hideRecipientName, inlineCountry
}) => {
    const address2 = address.get(fields.ADDRESS_STREET2);
    const country = address.get(fields.ADDRESS_COUNTRY);
    const phone = address.get(fields.ADDRESS_PHONE);
    const city = address.get(fields.ADDRESS_CITY);
    const state = address.get(fields.ADDRESS_STATE);
    const postalCode = address.get(fields.ADDRESS_POSTCODE);
    const cityStateLineList = [city, state, postalCode];
    const cityStateZip = cityStateLineList.join(' ').replace('  ', ' ').trim();
    const cityStateCountry = inlineCountry
        ? (
            
            <span>{cityStateZip}, {country}</span>
        ) : (
            <React.Fragment>
                <p>{cityStateZip}</p>
                {country && <p>{country}</p>}
            </React.Fragment>
        );

    if (country === 'JP') {
        return (
            <React.Fragment>
                { !hideRecipientName && <AddressNameLine address={address} /> }
                <p>{ postalCode }</p>
                <p>{ country }</p>
                <p>{ state }</p>
                <p>{ city }</p>
                <p>{ address.get(fields.ADDRESS_STREET1) }</p>
                { address2 && <p>{ address2 }</p> }
                { !hidePhoneNumber && phone && <p>{ phone }</p> }
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            { !hideRecipientName && <AddressNameLine address={address} /> }
            <p>{ address.get(fields.ADDRESS_STREET1) }</p>
            { address2 && <p>{ address2 }</p> }
            { cityStateCountry }
            { !hidePhoneNumber && phone && <p>{ phone }</p> }
        </React.Fragment>
    );
};

AddressDisplay.defaultProps = {
    hidePhoneNumber: false,
    hideRecipientName: false,
    inlineCountry: false
};

AddressDisplay.propTypes = {
    address: ImmutablePropTypes.contains({
        [fields.ADDRESS_FIRSTNAME]: PropTypes.string,
        [fields.ADDRESS_LASTNAME]: PropTypes.string,
        [fields.ADDRESS_FULLNAME]: PropTypes.string,
        [fields.ADDRESS_CITY]: PropTypes.string.isRequired,
        [fields.ADDRESS_STATE]: PropTypes.string,
        [fields.ADDRESS_POSTCODE]: PropTypes.string,
        [fields.ADDRESS_STREET1]: PropTypes.string.isRequired,
        [fields.ADDRESS_STREET2]: PropTypes.string,
        [fields.ADDRESS_COUNTRY]: PropTypes.string,
        [fields.ADDRESS_PHONE]: PropTypes.string
    }).isRequired,
    hidePhoneNumber: PropTypes.bool,
    hideRecipientName: PropTypes.bool,
    inlineCountry: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => ({
    address: getAddress(state, ownProps.addressID),
    hidePhoneNumber: ownProps.hidePhoneNumber,
    hideRecipientName: ownProps.hideRecipientName
});

const AddressDisplayID = connect(mapStateToProps)(AddressDisplay);

AddressDisplayID.defaultProps = {
    hidePhoneNumber: false,
    hideRecipientName: false,
    inlineCountry: false
};

AddressDisplayID.propTypes = {
    
    addressID: PropTypes.string.isRequired,
    hidePhoneNumber: PropTypes.bool,
    hideRecipientName: PropTypes.bool,
    inlineCountry: PropTypes.bool
};

export default AddressDisplayID;
