


const logNonException = (level = 'info', message, context, fingerprint) => {
    if (window.Raven) {
        window.Raven.captureMessage(message, {
            level: (level === 'warn') ? 'warning' : level,
            fingerprint: fingerprint ? ['{{ default }}'] + fingerprint : undefined,
            extra: context
        });
    }

    window && window.console && window.console[level] && window.console[level](message);
};


export const logError = (ex, context, fingerprint) => {
    if (window.Raven) {
        window.Raven.captureException(ex, {
            fingerprint: fingerprint ? ['{{ default }}'] + fingerprint : undefined,
            extra: context
        });
        
        
        
    }

    window && window.console && window.console.error && window.console.error(ex);
};


export const logWarning = (message, context, fingerprint) => {
    logNonException('warn', message, context, fingerprint);
};


export const logInfo = (message, context, fingerprint) => {
    logNonException('info', message, context, fingerprint);
};


export const logAxiosError = (error) => {
    if (error.response) {
        
        const fingerprint = [error.response.status];
        error.message = `${error.message} - ${error.response.status}`;

        if (error.request) {
            
            
            fingerprint.push(error.request.responseURL);
            error.message = `${error.message} - ${error.request.responseURL}`;
        }

        logError(error, {
            data: error.response.data,
            status: error.response.status,
            headers: error.response.headers
        }, fingerprint);
    } else {
        logError(error, {
            message: error.message
        });
    }
};
