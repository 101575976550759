import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LinkButton } from 'cstar-react-primitives/lib/redux-form/buttons/LinkButton';
import { FieldDivWrapper } from 'cstar-react-primitives/lib/redux-form/layout/FieldLayout';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames/dedupe';

import ConnectedModal from '../modal/ConnectedModal';
import { mapDispatchModalToProps } from '../modal/modalModule';

import currencyMessages from './currencyMessages';
import currencyNameMap from './data/currencyNameMap';


export const CURRENCY_MODAL_ID = 'currency';

export const CurrencyModal = ({ modalId, content, handleCloseModal }) => {
    const modalProps = {
        modalId,
        cashbotName: 'currency-modal',
        headerText: currencyMessages.modalHeader,
        contentLabel: currencyMessages.modalContentLabel,
        handleCloseModal
    };
    return (
        <ConnectedModal {...modalProps}>
            {content}
        </ConnectedModal>
    );
};

CurrencyModal.propTypes = {
    modalId: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    handleCloseModal: PropTypes.func.isRequired
};

export const CurrencyCodeButton = ({ cssClass, currencyCode, clickHandler }) => {
    const linkProps = {
        cashbotName: 'btn-currency',
        cssClass,
        onClick: clickHandler
    };

    return (
        <LinkButton {...linkProps}>
            <span>
                {currencyCode}
            </span>
        </LinkButton>
    );
};

CurrencyCodeButton.defaultProps = {
    clickHandler: () => {}
};

CurrencyCodeButton.propTypes = {
    clickHandler: PropTypes.func,
    cssClass: PropTypes.string.isRequired,
    currencyCode: PropTypes.string.isRequired
};

export const CurrencyCodeButtonWrapper = ({
    intl, modalId, currencyCode, setModalDisplay
}) => {
    const currencyCodeButtonProps = {
        cssClass: classNames('btn-currency', 'btn-currency--center'),
        currencyCode,
        clickHandler: () => setModalDisplay(modalId, true)
    };

    const modalProps = {
        modalId,
        content: intl.formatMessage(currencyMessages.modalContent,
            {
                currencyName: intl.formatMessage(currencyNameMap[currencyCode]),
                currencyCode
            }),
        handleCloseModal: () => setModalDisplay(modalId, false)
    };

    return (
        <FieldDivWrapper>
            <CurrencyCodeButton {...currencyCodeButtonProps} />
            <CurrencyModal {...modalProps} />
        </FieldDivWrapper>
    );
};

CurrencyCodeButtonWrapper.defaultProps = {
    modalId: CURRENCY_MODAL_ID,
    cssClass: classNames('btn-currency', 'btn-currency--center')
};

CurrencyCodeButtonWrapper.propTypes = {
    intl: intlShape.isRequired,
    modalId: PropTypes.string,
    cssClass: PropTypes.string,
    setModalDisplay: PropTypes.func.isRequired,
    currencyCode: PropTypes.string.isRequired
};

export default connect(null, mapDispatchModalToProps)(injectIntl(CurrencyCodeButtonWrapper));
