import createSagaMiddleware from 'redux-saga';
import persistState from 'redux-localstorage';
import adapter from 'redux-localstorage/lib/adapters/localStorage';
import filter from 'redux-localstorage-filter';
import { serialize } from 'redux-localstorage-immutable';
import { createStore, applyMiddleware, compose } from 'redux';
import { getCapiSessionID } from 'capi/redux/immutable';


import { createCapiSaga } from 'capi/redux';

import {
    watchCatalogsReceived,
    watchIsPlasticAndCurrencyChange
} from '../catalogs/catalogSagas';
import { watchSwitchCardType } from '../cardType/cardTypeSagas';
import placeOrder, { initiateConfigWatcherSaga } from '../payment/paymentSagas';
import {
    createClearSessionSaga,
    checkSessionSaga,
    getOrCreateSessionSaga,
    sessionApiFailureWatcherSaga,
    sessionApiSuccessWatcherSaga
} from '../session/sessionSagas';
import { itemSagasWatcher } from '../item/itemSagas';
import { blackboxResetWatcher } from '../iovation/iovationModule';
import { promoSagasWatcher } from '../promo/promoSagas';
import { watchShippingAddressChange, watchCurrencyChange } from '../address/addressSagas';
import { watchGetShippingMethod } from '../shipping/shippingSagas';
import {
    faceplatesReceivedWatcher,
    faceplatePrefetchImagesWatcher,
    faceplateSetDefaultWatcher,
    faceplateUpdateWatcher,
    requestFaceplatesWatcher,
    updateFaceplatesForLanguageWatcher
} from '../faceplate/faceplateSagas';
import { watchNotifications } from '../notifications/notifySagas';
import { watchPaypalSagas } from '../paypal/paypalSagas';
import { applePaySagasWatcher } from '../applePay/applePaySagas';
import { masterpassLoaderSaga } from '../masterpass/masterpassModule';
import { newItemFormAdapterWatcher, newItemFormActionWatcher } from '../item/newItemFormSagas';
import { paymentFormActionWatcher } from '../payment/paymentFormSagas';
import { intlSagasWatcher } from '../intl/intlSagas';
import { futureSendSagasWatcher } from '../futureSend/futureSendSagas';
import { masterpassSagasWatcher } from '../masterpass/masterpassSagas';
import { programSagasWatcher } from '../program/programSagas';
import { updateUYOForLanguageWatcher } from '../uyo/uyoSagas';
import { flowSagasWatcher } from '../routing/flowSagas';
import { watchAccessoriesSagas } from '../accessories/accessoriesSagas';
import { consentWatcher } from '../consent/consentSagas';


import { PAYMENT_RESET } from '../payment/paymentModule';
import { sanitizeCreditCardFields } from '../creditcard/creditCardModule';
import { segmentWatcher } from '../segment/segmentSagas';
import { getCapiClient } from '../session/sessionSelectors';
import { PAYMENT_FORM_NAME } from '../payment/paymentConstants';
import { CONSENT_STORE_NAME } from '../consent/consentModule';
import { FLOW_PROGRESS_STORE_NAME } from '../routing/flowProgressModule';

import { segmentInit } from '../segment/segment';

import appReducer from './appReducer';
import { getEnv, getSegmentKey, getCookieConsentEnabled } from './bootstrap';
import { cookiesAllowed } from '../cookie/cookieUtils';

const sagaMiddleware = createSagaMiddleware({
    
    
    onError: window.Raven ? window.Raven.captureException : undefined
});

const sagas = [
    watchCatalogsReceived,
    watchIsPlasticAndCurrencyChange,
    watchSwitchCardType,
    placeOrder,
    initiateConfigWatcherSaga,
    itemSagasWatcher,
    blackboxResetWatcher,
    promoSagasWatcher,
    watchNotifications,
    checkSessionSaga,
    getOrCreateSessionSaga,
    watchGetShippingMethod,
    watchShippingAddressChange,
    watchCurrencyChange,
    createClearSessionSaga(PAYMENT_RESET),
    createCapiSaga(getCapiClient, getCapiSessionID),
    faceplatesReceivedWatcher,
    faceplatePrefetchImagesWatcher,
    faceplateSetDefaultWatcher,
    requestFaceplatesWatcher,
    applePaySagasWatcher,
    masterpassLoaderSaga,
    newItemFormAdapterWatcher,
    faceplateUpdateWatcher,
    newItemFormActionWatcher,
    paymentFormActionWatcher,
    intlSagasWatcher,
    futureSendSagasWatcher,
    masterpassSagasWatcher,
    programSagasWatcher,
    updateFaceplatesForLanguageWatcher,
    updateUYOForLanguageWatcher,
    flowSagasWatcher,
    watchAccessoriesSagas,
    watchPaypalSagas,
    consentWatcher,
    sessionApiFailureWatcherSaga,
    sessionApiSuccessWatcherSaga
];


function reloadOnUserPreferenceChange(data) {
    if (data
        && data.message && data.message === 'submit_preferences'
        && data.source && data.source === 'preference_manager') {
        
        

        
        window.location.reload();
    }
}


window.addEventListener('message', (event) => {
    if (window.analytics) { return; }

    let { data } = event;
    if (typeof data === 'string') {
        try {
            data = JSON.parse(data);
        } catch (ex) { return; }
    }

    reloadOnUserPreferenceChange(data);
}, false);








const middleware = [sagaMiddleware];

const persistedPaths = [
    'addresses',
    'brand',
    'capi',
    'faceplate',
    'iovation',
    'itemDisplay',
    'items',
    'programs',
    'lastAction',
    'shippingMethods',
    'intl.locale',
    'intl.formats',
    'intl.defaultLocale',
    'promo.rejectedPromoCode',
    'promo.activePromo.promoCode',
    'promo.customRewardRecipients',
    'loyalty.loyaltyNumber',
    'session',
    'paymentPlus',
    'form.newItemForm',
    'uyo',
    FLOW_PROGRESS_STORE_NAME,
    CONSENT_STORE_NAME,
    'cart.cartPromoError',
    'cart.cartCustomPromo'
];

const storage = compose(
    serialize,
    filter(persistedPaths)
)(adapter(window.localStorage));



const enhancers = compose(
    
    
    applyMiddleware(...middleware),
    
    
    persistState(storage, 'cashstar-storefront'),
    getEnv() !== 'PROD'
    && typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f  
);


const appStore = createStore(appReducer, undefined, enhancers);

const cookieConsentEnabled = getCookieConsentEnabled();
const allowAllCookies = cookiesAllowed(cookieConsentEnabled);

function doLoadSegment() {
    const segmentKey = getSegmentKey();

    if (segmentKey) {
        segmentInit(segmentKey);
        sagas.push(segmentWatcher);
    }
}

if (allowAllCookies) {
    
    
    
    
    
    doLoadSegment();
}



if (typeof window._getSanitizedState !== 'undefined') {
    window._getSanitizedState = () => { 
        let state = appStore.getState(); 
        const paymentVals = state.getIn(['form', PAYMENT_FORM_NAME, 'values']);
        if (paymentVals) {
            const cleanVals = sanitizeCreditCardFields(paymentVals);
            state = state.setIn(['form', PAYMENT_FORM_NAME, 'values'], cleanVals);
        }
        
        const { ...intl } = state.get('intl');
        delete intl.messages;
        state = state.set('intl', intl);
        
        state = state.deleteIn(['flow', 'stepConfigs']);
        return state.toJS();
    };
}


sagas.map(sagaMiddleware.run);

export default appStore;
