
export const getFormChangeDetails = action => ({
    form: action.meta.form,
    field: action.meta.field,
    value: action.payload
});


export const createActionPatternForForm = formName => actionType => (action) => {
    if (!action.meta || !action.meta.form) return false;
    return actionType === action.type && action.meta.form === formName;
};
