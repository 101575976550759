import { Map as IMap } from 'immutable';

export const snareURL = 'https://mpsnare.iesnare.com/snare.js';


const getBlackboxExtras = blackbox => IMap({
    blackbox,
    blackboxUrl: window.location.href
});


export const loadSnare = async () => (
    
    
    
    await new Promise((resolve, reject) => {
        
        if (window.io_loaded) {
            const { blackbox } = window.ioGetBlackbox();
            resolve(getBlackboxExtras(blackbox));
        }

        
        window.io_install_stm = false;

        
        window.io_install_flash = false;

        
        window.io_enable_rip = true;

        
        window.io_bb_callback = (function () {
            return function (blackbox, isComplete) {
                if (!isComplete) {
                    return;
                }
                window.io_loaded = true;
                resolve(getBlackboxExtras(blackbox));
            };
        }());

        const scriptEl = document.createElement('script');
        scriptEl.src = snareURL;
        document.body.appendChild(scriptEl);
    })
);

export default loadSnare;
