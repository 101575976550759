import { fromJS } from 'immutable';


export const METHOD_REQUEST_STATES = fromJS({
    ACTIVE: 'active',
    FAILED: 'failed',
    SUCCESS: 'success'
});


export const SHIPPING_RESET = '@@storefront/SHIPPING_RESET';

export const GET_SHIPPING_METHOD = '@@storefront/GET_SHIPPING_METHOD';
export const STORE_PROFILE_METHODS = '@@storefront/STORE_PROFILE_METHODS';
export const CREATE_PROFILE_ACTIVE = '@@storefront/CREATE_PROFILE_ACTIVE';
export const CREATE_PROFILE_FAILED = '@@storefront/CREATE_PROFILE_FAILED';
export const CREATE_PROFILE_SUCCESS = '@@storefront/CREATE_PROFILE_SUCCESS';
export const PROFILE_ALREADY_EXISTS = '@@storefront/PROFILE_ALREADY_EXISTS';


export const initialState = fromJS({});

export const shippingMethodReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_PROFILE_ACTIVE: {
            return state.set(action.profile, fromJS({
                status: METHOD_REQUEST_STATES.get('ACTIVE')
            }));
        }
        case STORE_PROFILE_METHODS: {
            return state.setIn([action.profile, 'methods'], fromJS(action.methods));
        }
        case CREATE_PROFILE_SUCCESS: {
            return state.setIn([action.profile, 'status'], METHOD_REQUEST_STATES.get('SUCCESS'));
        }
        case CREATE_PROFILE_FAILED: {
            return state.set(action.profile, fromJS({
                status: METHOD_REQUEST_STATES.get('FAILED'),
                errorStatus: action.errorStatus,
                errorMessage: action.errorMessage
            }));
        }
        case SHIPPING_RESET:
            return initialState;
        default:
            return state;
    }
};



export const getShippingMethods = (shippingAddress, isNewItem = false) => ({
    type: GET_SHIPPING_METHOD,
    shippingAddress,
    isNewItem
});


export const storeProfileMethods = (profile, methods) => ({
    type: STORE_PROFILE_METHODS,
    profile,
    methods
});


export const createProfileActive = profile => ({
    type: CREATE_PROFILE_ACTIVE,
    profile
});


export const createProfileSuccess = (profile, methods) => ({
    type: CREATE_PROFILE_SUCCESS,
    profile,
    methods
});


export const createProfileFailed = (profile, errorStatus, errorMessage) => ({
    type: CREATE_PROFILE_FAILED,
    profile,
    errorStatus,
    errorMessage
});


export const profileAlreadyExists = profile => ({
    type: PROFILE_ALREADY_EXISTS,
    profile
});


export const shippingReset = () => ({ type: SHIPPING_RESET });
