import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import MdReportProblem from 'react-icons/lib/md/report-problem';
import IMask from 'imask';
import { Map as IMap, List as IList } from 'immutable';
import { InputGroup } from 'cstar-react-primitives/lib/redux-form/InputGroup';
import { giftCardFieldNames } from '../item/newItemForm';
import { PAYMENT_FORM_NAME } from '../payment/paymentConstants';

export const PHONE_MIN_LENGTH = 10;
export const PHONE_MAX_LENGTH = 17;

export const phoneNormalizer = (value) => {
    if (!value) return value;
    return value.replace(/[^+.()\d\s-]/g, '');
};

const defaultCountries = IList([
    IMap({
        display_name: 'United States',
        country_code: 'US',
        flag: `🇺🇸`,
        mask: `+{1} (000) 000-0000`
    })
]);




const PhoneWithCountry = (field) => {
    const [country, setCountry] = React.useState(null);

    const masked = IMask.createMask({
        mask: country ? country.mask : '+{1}(000)000-0000'
    });

    const normalizeStructured = (value) => {
        masked.resolve(value);
        return masked.value;
    };

    useEffect(() => {
        if (!country) {
            
            const localStore = JSON.parse(window.localStorage.getItem('cashstar-storefront'));
            const localCountry = localStore ? localStore.form.newItemForm.values[`${giftCardFieldNames.RECIPIENT_SMS_FIELD}Country`] : null;
            if (localCountry) {
                
                setCountry(JSON.parse(localCountry));
            } else if (field.countries.size > 0) {
                
                if (field.selectedCountry) {
                    setCountry(JSON.parse(field.selectedCountry));
                } else {
                    const matched = field.countries.find(cnt => cnt.get('country_code') === field.defaultCountry);
                    setCountry(matched ? matched.toJSON() : field.countries.first().toJSON());
                }
            } else {
                
                setCountry(defaultCountries.first().toJSON());
            }
        }
        if (!navigator.userAgent.includes('Macintosh')) {
            
            document.getElementsByClassName('field-phone-group__icon')[0].innerHTML = `<i class='flag ${country ? country.country_code.toLowerCase() : 'us'}' />`;
        }
    });
    return (
        <div className={`${field.cashbotName}-${giftCardFieldNames.RECIPIENT_SMS_FIELD}`}>
            <div
                className='field-group'
                data-cashbot-id={`${field.cashbotName}-${giftCardFieldNames.RECIPIENT_SMS_FIELD}`}
            >
                {
                    
                    
                    <label
                        id={`${giftCardFieldNames.RECIPIENT_SMS_FIELD}-input-label`}
                        htmlFor={`${giftCardFieldNames.RECIPIENT_SMS_FIELD}-input`}
                        className='field-group__label'
                    >
                        {field.label}
                    </label>
                    
                }
                <div className='field-group__div'>
                    <div className='field-phone-group__container'>
                        <div className='field-phone-group__country'>
                            <Field
                                component='select'
                                name='recipientSmsCountry'
                                className='field-phone-group__select'
                                id={`${field.input.name}-country`}
                                disabled={field.atts.disabled || field.countries.size < 2}
                                aria-label={giftCardFieldNames.SMS_COUNTRY_SELECT}
                                onChange={(e) => {
                                    const selectedCountry = JSON.parse(e.target.value).country_code;
                                    if (selectedCountry !== country.country_code) {
                                        
                                        const mappedCountry = field.countries.find(cnt => cnt.get('country_code') === selectedCountry);
                                        if (mappedCountry) {
                                            setCountry(mappedCountry.toJSON());
                                            
                                            field.change(giftCardFieldNames.RECIPIENT_SMS_FIELD, null); 
                                            field.change(PAYMENT_FORM_NAME, 'phoneNumber', null); 
                                        }
                                    }
                                }}
                                
                                props={{ value: JSON.stringify(country) }}
                            >
                                {field.countries.map(countryOption => (
                                    <option
                                        key={countryOption.get('country_code')}
                                        value={JSON.stringify(countryOption)}
                                    >
                                        {navigator.userAgent.includes('Macintosh')
                                            ? countryOption.get('flag') + countryOption.get('display_name')
                                            : countryOption.get('display_name')}
                                    </option>
                                ))}
                            </Field>
                            <div className='field-phone-group__icon'>{country ? country.flag : '📞' }</div>
                            {field.countries.size > 1 && <div className='field-phone-group__arrow' />}
                        </div>
                        <Field
                            component="input"
                            type="tel"
                            name={field.input.name}
                            validate={field.validate}
                            minLength={field.atts.minLength}
                            maxLength={field.atts.maxLength}
                            required={field.isRequiredField}
                            className='field-phone-group__input'
                            id={`${field.input.name}-input`}
                            aria-describedby={`${giftCardFieldNames.RECIPIENT_SMS_FIELD}-input-label`}
                            aria-labelledby={`${giftCardFieldNames.RECIPIENT_SMS_FIELD}-input-helper`}
                            normalize={normalizeStructured}
                            onFocus={() => document
                                .getElementById(`${giftCardFieldNames.RECIPIENT_SMS_FIELD}-input-helper`)
                                .classList.remove('field-msg--hidden')
                            }
                            onBlur={() => document
                                .getElementById(`${giftCardFieldNames.RECIPIENT_SMS_FIELD}-input-helper`)
                                .classList.add('field-msg--hidden')
                            }
                            disabled={field.atts.disabled}
                        />
                    </div>
                </div>
                <div />
                <span>
                    <span
                        id={`${giftCardFieldNames.RECIPIENT_SMS_FIELD}-input-validation`}
                        className="field-msg field-msg--error"
                        aria-live="polite"
                        aria-relevant="additions text"
                    >
                        {field.meta.error && (
                            <span className="field-msg__content">
                                <span
                                    id="recipientEmailConfirmation-input-icon"
                                    className="field-icon-validation field-icon-validation--error"
                                >
                                    <span className="field-icon-validation__content">
                                        <MdReportProblem />
                                    </span>
                                </span>
                                <span>{field.meta.error}</span>
                            </span>
                        )}
                    </span>
                    <span
                        className="field-msg"
                        aria-live="polite"
                        aria-relevant="additions text"
                        aria-atomic="true"
                    >
                        <div />
                    </span>
                </span>
                <span
                    id={`${giftCardFieldNames.RECIPIENT_SMS_FIELD}-input-helper`}
                    className="field-msg field-msg--helper field-msg--hidden"
                    aria-hidden="true"
                >
                    {field.helper}
                </span>
            </div>
        </div>
    );
};

const PhoneNumberField = ({
    cashbotName,
    component,
    helper,
    isRequiredField,
    label,
    name,
    showUntouchedErrors,
    validate,
    disabled,
    defaultCountry,
    countries,
    legacySMS,
    change,
    selectedCountry
}) => {
    const phoneProps = {
        atts: {
            type: 'tel',
            maxLength: PHONE_MAX_LENGTH,
            minLength: PHONE_MIN_LENGTH,
            disabled
        },
        cashbotName,
        component: legacySMS ? InputGroup : component,
        hasMaxLengthCounter: false,
        isRequiredField,
        label,
        name,
        normalize: phoneNormalizer,
        showUntouchedErrors,
        validate,
        defaultCountry,
        countries,
        change,
        selectedCountry
    };
    if (helper) {
        phoneProps.helper = helper;
    }

    return (
        <Field {...phoneProps} />
    );
};

PhoneNumberField.defaultProps = {
    component: PhoneWithCountry,
    helper: '',
    isRequiredField: true,
    showUntouchedErrors: false,
    validate: null,
    disabled: false,
    defaultCountry: 'US',
    countries: defaultCountries,
    legacySMS: false,
    change: () => {},
    selectedCountry: null
};

PhoneNumberField.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    component: PropTypes.func,
    helper: PropTypes.string,
    isRequiredField: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    showUntouchedErrors: PropTypes.bool,
    validate: PropTypes.func,
    disabled: PropTypes.bool,
    defaultCountry: PropTypes.string,
    countries: PropTypes.object,
    legacySMS: PropTypes.bool,
    change: PropTypes.func,
    selectedCountry: PropTypes.string
};

export default PhoneNumberField;
