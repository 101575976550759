import { defineMessages } from 'react-intl';

const mexicoStateMap = defineMessages({
    CMX: {
        id: 'address.MX.StateMap.MexicanFederalDistrict.CMX',
        description: 'mx state label for Mexican Federal District',
        defaultMessage: 'Mexican Federal District'
    },
    AGU: {
        id: 'address.MX.StateMap.Aguascalientes.AGU',
        description: 'mx state label for Aguascalientes',
        defaultMessage: 'Aguascalientes'
    },
    BCN: {
        id: 'address.MX.StateMap.BajaCalifornia.BCN',
        description: 'mx state label for Baja California',
        defaultMessage: 'Baja California'
    },
    BCS: {
        id: 'address.MX.StateMap.BajaCaliforniaSur.BCS',
        description: 'mx state label for Baja California Sur',
        defaultMessage: 'Baja California Sur'
    },
    CAM: {
        id: 'address.MX.StateMap.Campeche.CAM',
        description: 'mx state label for Campeche',
        defaultMessage: 'Campeche'
    },
    COA: {
        id: 'address.MX.StateMap.Coahuila.COA',
        description: 'mx state label for Coahuila',
        defaultMessage: 'Coahuila'
    },
    COL: {
        id: 'address.MX.StateMap.Colima.COL',
        description: 'mx state label for Colima',
        defaultMessage: 'Colima'
    },
    CHP: {
        id: 'address.MX.StateMap.Chiapas.CHP',
        description: 'mx state label for Chiapas',
        defaultMessage: 'Chiapas'
    },
    CHH: {
        id: 'address.MX.StateMap.Chihuahua.CHH',
        description: 'mx state label for Chihuahua',
        defaultMessage: 'Chihuahua'
    },
    DUR: {
        id: 'address.MX.StateMap.Durango.DUR',
        description: 'mx state label for Durango',
        defaultMessage: 'Durango'
    },
    GUA: {
        id: 'address.MX.StateMap.Guanajuato.GUA',
        description: 'mx state label for Guanajuato',
        defaultMessage: 'Guanajuato'
    },
    GRO: {
        id: 'address.MX.StateMap.Guerrero.GRO',
        description: 'mx state label for Guerrero',
        defaultMessage: 'Guerrero'
    },
    HID: {
        id: 'address.MX.StateMap.Hidalgo.HID',
        description: 'mx state label for Hidalgo',
        defaultMessage: 'Hidalgo'
    },
    JAL: {
        id: 'address.MX.StateMap.Jalisco.JAL',
        description: 'mx state label for Jalisco',
        defaultMessage: 'Jalisco'
    },
    MEX: {
        id: 'address.MX.StateMap.México.MEX',
        description: 'mx state label for México',
        defaultMessage: 'México'
    },
    MIC: {
        id: 'address.MX.StateMap.Michoacán.MIC',
        description: 'mx state label for Michoacán',
        defaultMessage: 'Michoacán'
    },
    MOR: {
        id: 'address.MX.StateMap.Morelos.MOR',
        description: 'mx state label for Morelos',
        defaultMessage: 'Morelos'
    },
    NAY: {
        id: 'address.MX.StateMap.Nayarit.NAY',
        description: 'mx state label for Nayarit',
        defaultMessage: 'Nayarit'
    },
    NLE: {
        id: 'address.MX.StateMap.NuevoLeón.NLE',
        description: 'mx state label for Nuevo León',
        defaultMessage: 'Nuevo León'
    },
    OAX: {
        id: 'address.MX.StateMap.Oaxaca.OAX',
        description: 'mx state label for Oaxaca',
        defaultMessage: 'Oaxaca'
    },
    PUE: {
        id: 'address.MX.StateMap.Puebla.PUE',
        description: 'mx state label for Puebla',
        defaultMessage: 'Puebla'
    },
    QUE: {
        id: 'address.MX.StateMap.Querétaro.QUE',
        description: 'mx state label for Querétaro',
        defaultMessage: 'Querétaro'
    },
    ROO: {
        id: 'address.MX.StateMap.QuintanaRoo.ROO',
        description: 'mx state label for Quintana Roo',
        defaultMessage: 'Quintana Roo'
    },
    SLP: {
        id: 'address.MX.StateMap.SanLuisPotosí.SLP',
        description: 'mx state label for San Luis Potosí',
        defaultMessage: 'San Luis Potosí'
    },
    SIN: {
        id: 'address.MX.StateMap.Sinaloa.SIN',
        description: 'mx state label for Sinaloa',
        defaultMessage: 'Sinaloa'
    },
    SON: {
        id: 'address.MX.StateMap.Sonora.SON',
        description: 'mx state label for Sonora',
        defaultMessage: 'Sonora'
    },
    TAB: {
        id: 'address.MX.StateMap.Tabasco.TAB',
        description: 'mx state label for Tabasco',
        defaultMessage: 'Tabasco'
    },
    TAM: {
        id: 'address.MX.StateMap.Tamaulipas.TAM',
        description: 'mx state label for Tamaulipas',
        defaultMessage: 'Tamaulipas'
    },
    TLA: {
        id: 'address.MX.StateMap.Tlaxcala.TLA',
        description: 'mx state label for Tlaxcala',
        defaultMessage: 'Tlaxcala'
    },
    VER: {
        id: 'address.MX.StateMap.Veracruz.VER',
        description: 'mx state label for Veracruz',
        defaultMessage: 'Veracruz'
    },
    YUC: {
        id: 'address.MX.StateMap.Yucatán.YUC',
        description: 'mx state label for Yucatán',
        defaultMessage: 'Yucatán'
    },
    ZAC: {
        id: 'address.MX.StateMap.Zacatecas.ZAC',
        description: 'mx state label for Zacatecas',
        defaultMessage: 'Zacatecas'
    }
});

export default mexicoStateMap;
