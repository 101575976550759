import { defineMessages } from 'react-intl';


const itemMessages = defineMessages({
    noImageAvailable: {
        id: 'item.itemMessages.noImageAvailable',
        description: 'displayed when we have no faceplate image to display',
        defaultMessage: 'Sorry, no image available'
    },
    selfBuyEgcDelivery: {
        id: 'item.itemMessages.selfBuyEgcDelivery.md',
        description: 'cart/confirmation page description where the card is being sent when not a gift',
        defaultMessage: '**For:** You'
    },
    viewDetails: {
        id: 'item.itemMessages.itemItemViewDetails',
        description: 'Text displayed as a link to show details of an item for purchase',
        defaultMessage: 'View Details'
    },
    viewEditDetails: {
        id: 'item.itemMessages.itemItemViewEditDetails',
        description: 'Text displayed as a link to show details of an item for purchase',
        defaultMessage: 'View / Edit Details'
    },
    changeRecipient: {
        id: 'item.itemMessages.itemItemChangeRecipient',
        description: 'Text displayed as a link to show the available-recipients modal',
        defaultMessage: 'Change Recipient'
    },
    hideDetails: {
        id: 'item.itemMessages.itemItemHideDetails',
        description: 'Text displayed as a link to hide details of an item for purchase',
        defaultMessage: 'Hide Details'
    },
    shippingLabel: {
        id: 'item.itemMessages.shippingLabel',
        description: 'Label for shipping details of an item',
        defaultMessage: 'Shipping:'
    },
    recipientLabel: {
        id: 'item.itemMessages.recipientLabel',
        description: 'Label for recipient details of an item',
        defaultMessage: 'To:'
    },
    recipientLabelHelper: {
        id: 'item.itemMessages.recipientLabelHelper',
        description: 'Descriptive text for recipient details of an item',
        defaultMessage: 'Gift card recipient field for {name}'
    },
    senderLabel: {
        id: 'item.itemMessages.senderLabel',
        description: 'Label for the details of the sender of an item',
        defaultMessage: 'From:'
    },
    senderLabelHelper: {
        id: 'item.itemMessages.senderLabelHelper',
        description: 'Descriptive text for the details of the sender of an item',
        defaultMessage: 'Gift card sender field for {name}'
    },
    messageLabel: {
        id: 'item.itemMessages.messageLabel',
        description: 'Label for the message associated with an item',
        defaultMessage: 'Message (optional):'
    },
    messageLabelHelper: {
        id: 'item.itemMessages.messageLabelHelper',
        description: 'Descriptive text for the message associated with an item',
        defaultMessage: 'Gift card message field for {name}'
    },
    recipientSMSLabel: {
        id: 'item.itemMessages.recipientSMSLabel',
        description: 'Label text for recipient SMS phone number input',
        defaultMessage: 'Send {name} an SMS (optional)'
    },
    recipientSMSLabelNotOptional: {
        id: 'item.itemMessages.recipientSMSLabelNotOptional',
        description: 'Label text for new recipient SMS phone number input without (optional) text',
        defaultMessage: 'Send {name} an SMS'
    },
    recipientSMSHelper: {
        id: 'item.itemMessages.recipientSMSHelper',
        description: 'Descriptive text for the recipient SMS phone number input',
        defaultMessage: 'By providing the recipient\'s mobile number, you confirm that you have permission to send text messages to the mobile phone number entered and that the recipient consents to receive such messages. Message and data rates may apply.'
    },
    recipientNotificationLabel: {
        id: 'item.itemMessages.recipientNotificationLabel',
        description: 'Label text for toggling notification to the recipient of a physical delivery',
        defaultMessage: 'Tell {name} to expect a gift?'
    },
    recipientEmailLabel: {
        id: 'item.itemMessages.recipientEmailLabel',
        description: 'Label text for the card recipient email input',
        defaultMessage: 'Email:'
    },
    recipientEmailLabelHelper: {
        id: 'item.itemMessages.recipientEmailLabelHelper',
        description: 'Descriptive text for the card recipient email input',
        defaultMessage: 'Gift card for {name} email address field'
    },
    shippingAddressLabel: {
        id: 'item.itemMessages.shippingAddressLabel',
        description: 'Label text for the physical shipping address input fields',
        defaultMessage: 'Shipping Address:'
    },
    shippingMethodLabel: {
        id: 'item.itemMessages.shippingMethodLabel',
        description: 'Label text for selection of a physical shipping method',
        defaultMessage: 'Shipping Method:'
    },
    deliveryDateLabel: {
        id: 'item.itemMessages.deliveryDateLabel',
        description: 'Label text for the delivery date input for an electronic item',
        defaultMessage: 'Delivery Date:'
    },
    deliveryDateValue: {
        id: 'item.itemMessages.deliveryDateValue',
        description: 'Text value for the delivery date input for an electronic item',
        defaultMessage: '{deliveryDateTime} {timeZone}'
    },
    removeLabel: {
        id: 'item.itemMessages.removeLabel',
        description: 'Text for the link to remove an item from the cart',
        defaultMessage: 'Remove'
    },
    editQuantityAriaLabel: {
        id: 'item.itemMessages.editQuantityAriaLabel',
        description: 'aria-label for editing an item\'s quantity in the cart - not user visible',
        defaultMessage: 'Gift card quantity field for {name}'
    },
    orderSummaryHeader: {
        id: 'item.itemMessages.orderSummaryHeader',
        description: 'Sub-heading for an order summary',
        defaultMessage: 'Order Summary'
    },
    recipientPhoneLabel: {
        id: 'item.itemMessages.recipientPhoneLabel',
        description: 'Label text for physical delivery phone number input',
        defaultMessage: 'Recipient Phone Number (optional)'
    },
    recipientPhoneHelper: {
        id: 'item.itemMessages.recipientPhoneHelper',
        description: 'Descriptive text for physical delivery phone number input',
        defaultMessage: 'In case we need package delivery confirmation.'
    },
    orderSummaryCurrencyLabel: {
        id: 'item.itemMessages.orderSummaryCurrencyLabel',
        description: 'Order summary row label for the currency the order is in',
        defaultMessage: 'Currency'
    },
    orderTotalSubTotal: {
        id: 'item.itemMessages.orderTotalSubTotal',
        description: 'Order summary row label for the order subtotal with the number of cards being purchased',
        defaultMessage: 'Card Subtotal ({count, number})'
    },
    orderTotalAccessories: {
        id: 'item.itemMessages.orderTotalAccessories',
        description: 'Order summary row label for the accessories with the number of accessories being purchased',
        defaultMessage: 'Accessories ({count, number})'
    },
    orderTotalEstShipping: {
        id: 'item.itemMessages.orderTotalEstShipping',
        description: 'Order summary row label for the shipping cost of the items being purchased',
        defaultMessage: 'Shipping Subtotal'
    },
    orderTotalSpecialOffers: {
        id: 'item.itemMessages.orderTotalSpecialOffers',
        description: 'Order summary row for special offers, including their denomination and quantity '
            + 'with a dynamic label for the item name',
        defaultMessage: '{denom} {label} ({count, number})'
    },
    orderTotalNonDenomLabel: {
        id: 'item.itemMessages.orderTotalNonDenomLabel',
        description: 'Order summary row for non-denominated promos and quantity '
            + 'with a dynamic label for the item name',
        defaultMessage: '{label} ({count, number})'
    },
    orderTotalBonusValue: {
        id: 'item.itemMessages.orderTotalBonusValue',
        description: 'Order summary row label for the value of special offers',
        defaultMessage: 'Bonus'
    },
    orderTotalDiscount: {
        id: 'item.itemMessages.orderTotalDiscount',
        description: 'Order summary row label for the discount applied',
        defaultMessage: 'Discount'
    },
    orderTotalOrderTotal: {
        id: 'item.itemMessages.orderTotalOrderTotal',
        description: 'Order summary row label for the total order cost',
        defaultMessage: 'Order Total'
    },
    orderTotalUYOPlasticFee: {
        id: 'item.itemMessages.orderTotalUYOPlasticFee',
        description: 'Order summary row label for upload your own plastic fee',
        defaultMessage: 'Custom Photo ({count})'
    },
    orderTotalActivationFees: {
        id: 'item.itemMessages.orderTotalActivationFees',
        description: 'Order summary row label for activation fees',
        defaultMessage: 'Activation Fees ({count})'
    },
    openEditDetailsLink: {
        id: 'item.itemMessages.openEditDetailsLink',
        description: 'Link text to edit an item in the cart',
        defaultMessage: 'Change gift details'
    },
    closeEditDetailsLink: {
        id: 'item.itemMessages.closeEditDetailsLink',
        description: 'Link text to cancel editing an item in the cart',
        defaultMessage: 'Cancel'
    },
    openEditShippingLink: {
        id: 'item.itemMessages.openEditShippingLink',
        description: 'Link text to edit a physical item\'s delivery address',
        defaultMessage: 'Change shipping address'
    },
    closeEditShippingLink: {
        id: 'item.itemMessages.closeEditShippingLink',
        description: 'Link text to cancel editing a physical item\'s delivery address',
        defaultMessage: 'Cancel'
    },
    editDetailsSubmit: {
        id: 'item.itemMessages.editDetailsSubmit',
        description: 'Link text to save an edited item in the cart',
        defaultMessage: 'Save Changes'
    },
    editAddressSubmit: {
        id: 'item.itemMessages.editAddressSubmit',
        description: 'Link text to save an edited delivery address for a physical item in the cart',
        defaultMessage: 'Save Changes'
    },
    recipientNameInvalid: {
        id: 'item.itemMessages.recipientNameInvalid',
        description: 'Error message displayed when a recipient name is not provided',
        defaultMessage: 'Recipient name is required.'
    },
    senderNameInvalid: {
        id: 'item.itemMessages.senderNameInvalid',
        description: 'Error message displayed when a sender name is not provided',
        defaultMessage: 'Sender name is required.'
    },
    smsInvalid: {
        id: 'item.itemMessages.smsInvalid',
        description: 'Error message displayed when an entered SMS phone number is invalid',
        defaultMessage: 'Please enter a valid phone number.'
    },
    editDetailsInvalid: {
        id: 'item.itemMessages.editDetailsInvalid',
        description: 'Error message displayed when there are one or more errors in an item\'s edited details',
        defaultMessage: 'Please fix detail errors.'
    },
    messageInvalidTooLong: {
        id: 'item.itemMessages.messageInvalidTooLong',
        description: 'Error message displayed when an entered message exceeds the maximum allowed length',
        defaultMessage: 'Your message may not be longer than {maxLength} characters'
    },
    messageWarnNearMax: {
        id: 'item.itemMessages.messageWarnNearMax',
        description: 'Warning message displayed when an entered message is nearing the maximum allowed length',
        defaultMessage: 'You are near the maximum message length of {maxLength} characters'
    },
    messageWarnAtMax: {
        id: 'item.itemMessages.messageWarnAtMax',
        description: 'Warning message displaued when an entered message has reached the maximum allowed length',
        defaultMessage: 'You have reached your maximum character count'
    },
    emailRequired: {
        id: 'item.itemMessages.emailRequired',
        description: 'Error message displayed when no email was provided',
        defaultMessage: 'Please enter an email'
    },
    emailInvalid: {
        id: 'item.itemMessages.emailInvalid',
        description: 'Error message displayed when an invalid email was provided',
        defaultMessage: 'Please enter a valid email address.'
    },
    confirmEmailMatchInvalid: {
        id: 'item.itemMessages.confirmEmailMatchInvalid',
        description: 'Error message displayed when an entered recipient email is invalid',
        defaultMessage: 'Email addresses must match.'
    },
    smsPhoneInvalid: {
        id: 'item.itemMessages.smsPhoneInvalid',
        description: 'Error message displayed when an invalid recipient SMS phone number was provided',
        defaultMessage: 'Please enter a valid phone number containing between 10 and 15 digits'
    },
    itemValue: {
        id: 'item.itemMessages.abridged.details.itemValue',
        description: 'aria-label for the value of an item in the cart - not user visibile',
        defaultMessage: 'card value'
    },
    itemQuantity: {
        id: 'item.itemMessages.abridged.details.itemQuantity',
        description: 'aria-label for the quantity of items in a group in the cart - not user visible',
        defaultMessage: 'quantity'
    },
    itemTotal: {
        id: 'item.itemMessages.lineitemtotal.itemTotal',
        description: 'aria-label for the total cost of a group of cards - not user visible',
        defaultMessage: 'card total'
    },
    itemFree: {
        id: 'item.itemMessagesitemFree.itemFree',
        description: 'displays when a promotional item is redeemable for a specific item instead of a value',
        defaultMessage: 'FREE'
    },
    
    recipientFullNameRequired: {
        id: 'item.itemMessages.recipientFullNameRequired',
        description: 'Error message displayed when a full name was not provided for a recipient\'s physical address',
        defaultMessage: 'Please enter recipient\'s full name'
    },
    recipientStreet1Required: {
        id: 'item.itemMessages.recipientStreet1Required',
        description: 'Error message displayed when a street address was not provided for '
            + 'a recipient\'s physical address',
        defaultMessage: 'Please enter recipient\'s delivery address'
    },
    recipientCityRequired: {
        id: 'item.itemMessages.recipientCityRequired',
        description: 'Error message displayed when a city was not provided for a recipient\'s physcial address',
        defaultMessage: 'Please enter recipient\'s delivery city'
    },
    recipientStateRequired: {
        id: 'item.itemMessages.recipientStateRequired',
        description: 'Error message displayed when a state was not provided for a recipient\'s physical address',
        defaultMessage: 'Please select recipient\'s delivery state'
    },
    recipientPostalCodeRequired: {
        id: 'item.itemMessages.recipientPostalCodeRequired',
        description: 'Error message displayed when an invalid postal code was provided for '
            + 'a recipient\'s physical address',
        defaultMessage: 'Please enter a valid delivery postal code'
    },
    recipientPhoneInvalid: {
        id: 'item.itemMessages.recipientPhoneInvalid',
        description: 'Error message displayed when an invalid phone number was provided for a recipient',
        defaultMessage: 'Please enter a valid phone number containing between 10 and 15 digits'
    },
    recipientNameCardLabel: {
        id: 'item.itemMessages.recipientNameCardLabel.md',
        description: 'Label text for recipient name',
        defaultMessage: '**For:** {name}'
    },
    shareableLinkDeliveryLabel: {
        id: 'item.itemMessages.shareableLinkDeliveryLabel.md',
        description: 'Label text for shareable link delivery type card',
        defaultMessage: '**Delivery:** You’ll share a link'
    },
    shareViaLinkLabel: {
        id: 'item.itemMessages.shareViaLabel',
        description: 'Label for shareable link in view details',
        defaultMessage: 'You’ll share a link'
    },
    cardDeliveryLabel: {
        id: 'item.itemMessages.cardDeliveryLabel.md',
        description: 'Label text for delivery',
        defaultMessage: '**Delivery:**'
    },
    recipientYourCardLabel: {
        id: 'item.itemMessages.recipientYourCardLabel.md',
        description: 'Label text for recipient selfbuy card',
        defaultMessage: '**For:** You'
    },
    profaneRecipientName: {
        id: 'item.itemMessages.profaneRecipientName',
        description: 'Profanity error message',
        defaultMessage: 'No profanity allowed'
    },
    profaneSenderName: {
        id: 'item.itemMessages.profaneSenderName',
        description: 'Profanity error message',
        defaultMessage: 'No profanity allowed'
    },
    profaneMessage: {
        id: 'item.itemMessages.profaneMessage',
        description: 'Profanity error message',
        defaultMessage: 'No profanity allowed'
    },
    linkPromoFromLabel: {
        id: 'item.itemMessages.promo.fromLabel',
        description: 'from Label for promo eGift Link',
        defaultMessage: '{label} from {fromName}'
    },
    linkPromoCartInstruction: {
        id: 'item.itemMessages.promo.cartPromoInfo',
        description: 'Link Promo info for the cart page',
        defaultMessage: 'You will receive this by email. You’ll also get a link to share if you’d like to give it to someone as a gift.'
    },
    linkPromoSuccessfulInstruction: {
        id: 'item.itemMessages.promo.successfulPromoInfo',
        description: 'Link Promo info for the order confirmation page',
        defaultMessage: 'You have received this in an email. You can keep it for yourself, or use this link to give it as a gift.'
    }
    
});

export default itemMessages;
