import { fromJS } from 'immutable';


export const MASTERPASS_PAYMENT_TYPE = 'masterpass';




export const MASTERPASS_CART_ID = 'NOT_USED';


export const masterpassPaymentDataHandler = paymentData => (
    fromJS({
        approved: true,
        paymentMethod: MASTERPASS_PAYMENT_TYPE,
        masterpassOauthToken: paymentData.get('masterpassToken'),
        checkoutId: paymentData.get('checkoutId'),
        cartId: MASTERPASS_CART_ID,
        loyaltyNumber: paymentData.get('loyaltyNumber')
    }).filterNot(val => typeof val === 'undefined')
);
