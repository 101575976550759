import numbersOnlyNormalizer from './numbersOnlyNormalizer';


const postalCodeNormalizerMX = (value) => {
    if (!value || typeof value !== 'string') return value;
    let normalized = numbersOnlyNormalizer(value);
    if (normalized.length > 5) {
        normalized = normalized.slice(0, 5);
    }
    return normalized;
};

export default postalCodeNormalizerMX;
