
import moment from 'moment-timezone';
import { fromJS } from 'immutable';




const LOCALE_DATE = 'll';


const LOCALE_DAY_AND_DATE = 'dddd, LL';


const MONTH_YEAR = 'MMMM YYYY';


const YEAR_MONTH = 'YYYY MMMM';


const YEAR_MONTH_DAY = 'YYYY-MM-DD';


const TIME_ONLY = 'hh:mm';


const MERIDIEM_ONLY = 'A';


const LOCALE_TIME = 'LT';


const LOCALE_DATE_TIME = 'lll';

export const FORMATS = {
    LOCALE_DATE,
    LOCALE_DAY_AND_DATE,
    MONTH_YEAR,
    YEAR_MONTH_DAY,
    TIME_ONLY,
    MERIDIEM_ONLY,
    LOCALE_TIME,
    LOCALE_DATE_TIME,
    YEAR_MONTH
};


export const toMomentYearMonthDay = date => moment(date, FORMATS.YEAR_MONTH_DAY);


export const toMomentTime = time => moment(time, FORMATS.LOCALE_TIME);


export const toMomentMonthYear = (month, year) => moment(`${month} ${year}`, 'MM YY');


export const mergeDateTime = (date, time, timezone) => {
    const merged = moment(date)
        .hours(time.hours())
        .minutes(time.minutes());

    
    if (!timezone || !moment.tz.zone(timezone)) return merged;

    return moment.tz(
        merged.format(FORMATS.LOCALE_DATE_TIME),
        FORMATS.LOCALE_DATE_TIME,
        timezone
    );
};


export const toDate = date => date.toDate();


export const toYearMonthDayString = date => date.format(FORMATS.YEAR_MONTH_DAY);


export const toLocalizedDateString = date => date.format(FORMATS.LOCALE_DATE);


export const toLocalizedTimeString = time => time.format(FORMATS.LOCALE_TIME);


export const toShortTimeString = time => time.format(FORMATS.TIME_ONLY);


export const toLocalizedDateTimeString = (date, time, timezone) => {
    const dateMoment = moment.isMoment(date) ? date : toMomentYearMonthDay(date);
    const timeMoment = moment.isMoment(time) ? time : toMomentTime(time);
    const stringFormat = `${FORMATS.LOCALE_DATE_TIME} z`;

    return mergeDateTime(dateMoment, timeMoment, timezone).format(stringFormat);
};


export const convertTimeZone = (dateTimeString, timeZone = 'UTC') => {
    
    const actualTimeZone = moment.tz.zone(timeZone) ? timeZone : 'UTC';

    
    const timezoneAwareDateTime = moment.tz(dateTimeString, 'YYYY-MM-DD HH:mm:ss', actualTimeZone);

    
    return timezoneAwareDateTime.utc().format();
};

export const isSameDay = (firstDate, secondDate) => {
    if (!moment.isMoment(firstDate) || !moment.isMoment(secondDate)) return false;
    return firstDate.isSame(secondDate, 'day');
};

export const isInclusivelyAfterDay = (firstDate, secondDate) => {
    if (!moment.isMoment(firstDate) || !moment.isMoment(secondDate)) return false;
    return firstDate.isAfter(secondDate) || isSameDay(firstDate, secondDate);
};

export const isInclusivelyBeforeDay = (firstDate, secondDate) => {
    if (!moment.isMoment(firstDate) || !moment.isMoment(secondDate)) return false;
    return firstDate.isBefore(secondDate) || isSameDay(firstDate, secondDate);
};

export const getCurrentDateTime = () => moment();


export const getMinute = time => time.minute();


export const getHour = time => time.hour();


export const getMeridiem = time => moment(time).format(FORMATS.MERIDIEM_ONLY);


export const getTimeZoneAbbr = timezone => moment.tz(timezone).zoneAbbr();


const commonLanguages = fromJS({
    
    fr: async () => (await import('moment/locale/fr')).default,
    en: async () => Promise.resolve(),
    es: async () => (await import('moment/locale/es')).default,
    sv: async () => (await import('moment/locale/sv')).default,
    de: async () => (await import('moment/locale/de')).default,
    pt: async () => (await import('moment/locale/pt')).default,
    ja: async () => (await import('moment/locale/ja')).default,
    nl: async () => (await import('moment/locale/nl')).default,
    it: async () => (await import('moment/locale/it')).default
});


export async function momentLocaleImport(locale) {
    
    
    const language = locale.split('-')[0];

    
    if (commonLanguages.has(language)) {
        return commonLanguages.get(language)();
    }
    
    const languageFile = `moment/locale/${language}.js`;
    return (await import(languageFile)).default;
}

export default {
    toMomentTime,
    toMomentMonthYear,
    mergeDateTime,
    toLocalizedDateString,
    toShortTimeString,
    toLocalizedDateTimeString,
    convertTimeZone,
    isSameDay,
    isInclusivelyAfterDay,
    isInclusivelyBeforeDay,
    getCurrentDateTime,
    getMeridiem,
    momentLocaleImport,
    FORMATS
};
