import { getUsePaymentMethodStep, preferencesAreLoaded } from '../brand/brandSelectors';
import { isStillFetchingPromoRewards } from '../promo/promoSelectors';

import {
    paymentMethodPostConditions,
    paymentMethodPreConditions,
    paymentMethodStepSkippable
} from './paymentMethodStepSelectors';
import paymentMethodMessages from './paymentMethodMessages';


const paymentMethodStepConfig = {
    component: () => import('./PaymentMethodStep'),
    flow: 'postBuy',
    isReady: state => preferencesAreLoaded(state) && !isStillFetchingPromoRewards(state),
    canLoad: (state, ownProps) => {
        const { submitPath } = ownProps;
        return { isAllowedOrPath: getUsePaymentMethodStep(state) || submitPath };
    },
    preConditions: paymentMethodPreConditions,
    postConditions: paymentMethodPostConditions,
    getSubmitMessage: () => paymentMethodMessages.submitButtonText,
    canSkip: state => (
        paymentMethodStepSkippable(state)
    )
};

export default paymentMethodStepConfig;
