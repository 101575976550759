import { createSelector } from 'reselect';
import { Big } from 'big.js';

import {
    getSubTotal,
    getShippingTotal,
    getItemAccessoriesGrandTotalCost
} from '../item/itemSelectors';
import {
    getDiscountRewardAmount
} from '../promo/promoSelectors';
import { getIntlLocale } from '../intl/intlSelectors';
import { getGrandTotal } from '../payment/paymentSelectors';


export const getPaypalPaymentRequest = createSelector(
    getGrandTotal,
    getSubTotal,
    getShippingTotal,
    state => state.getIn(['paypal', 'paypalHandlingTotal']),
    getItemAccessoriesGrandTotalCost,
    getDiscountRewardAmount,
    (total, subtotal, shippingTotal, handlingTotal, itemAccessoriesTotal, discount) => ({
        intent: 'AUTHORIZE',
        purchase_units: [
            {
                amount: {
                    value: total.toFixed(2),
                    currency_code: 'USD',
                    breakdown: {
                        item_total: {
                            value: new Big(subtotal).add(itemAccessoriesTotal).add(discount).toFixed(2),
                            currency_code: 'USD'
                        },
                        shipping: {
                            value: shippingTotal.toFixed(2),
                            currency_code: 'USD'
                        },
                        handling: {
                            value: handlingTotal.toFixed(2),
                            currency_code: 'USD'
                        }
                    }
                }
            }
        ]
    })
);


export const getPaypalLocaleCode = createSelector(
    getIntlLocale,
    locale => payPalLocaleMap => payPalLocaleMap.get(locale, 'en_US')
);
