import React from 'react';
import PropTypes from 'prop-types';
import { List as IList } from 'immutable';
import classNames from 'classnames/dedupe';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { FieldDivWrapper } from 'cstar-react-primitives/lib/redux-form/layout/FieldLayout';
import { translateCurrency } from '../intl/intlModule';

import currencyNameMap from './data/currencyNameMap';
import currencyMessages from './currencyMessages';
import { CURRENCY_MODAL_ID, CurrencyCodeButton, CurrencyModal } from './CurrencyCodeButton';
import { mapDispatchModalToProps } from '../modal/modalModule';

export const CurrencyPickerDisabled = ({
    intl,
    name,
    cashbotName,
    currencyList,
    selectedCurrency,
    modalId,
    setModalDisplay
}) => {
    const availableFullRow = currencyList.size % 4 === 0;
    
    const containerClasses = classNames('tab-container', 'tab-container--top-flush', 'tab-currency', {
        'tab-currency--center': !availableFullRow && currencyList.size <= 5,
        'tab-currency--start': !availableFullRow && currencyList.size >= 6,
        'tab-currency--between': availableFullRow
    });

    const containerProps = {
        name,
        cashbotName,
        className: containerClasses
    };

    const modalProps = {
        modalId,
        content: intl.formatMessage(currencyMessages.modalContent,
            {
                currencyName: intl.formatMessage(currencyNameMap[selectedCurrency]),
                currencyCode: selectedCurrency
            }),
        handleCloseModal: () => setModalDisplay(modalId, false)
    };

    const currencyButtonList = currencyList.map((currencyCode) => {
        const isSelectedCurrency = currencyCode === selectedCurrency;

        const currencyCodeButtonProps = {
            key: `${currencyCode}-${name}`,
            cssClass: classNames('tab', {
                'tab--selected': isSelectedCurrency,
                'tab--disabled': !isSelectedCurrency
            }),
            currencyCode: translateCurrency(currencyCode)
        };

        if (!isSelectedCurrency) {
            currencyCodeButtonProps.clickHandler = () => setModalDisplay(modalId, true);
        }

        return <CurrencyCodeButton {...currencyCodeButtonProps} />;
    });

    return (
        <FieldDivWrapper {...containerProps}>
            {currencyButtonList}
            <CurrencyModal {...modalProps} />
        </FieldDivWrapper>
    );
};

CurrencyPickerDisabled.defaultProps = {
    modalId: CURRENCY_MODAL_ID
};

CurrencyPickerDisabled.propTypes = {
    modalId: PropTypes.string,
    intl: intlShape.isRequired,
    name: PropTypes.string.isRequired,
    cashbotName: PropTypes.string.isRequired,
    currencyList: PropTypes.instanceOf(IList).isRequired,
    selectedCurrency: PropTypes.string.isRequired,
    setModalDisplay: PropTypes.func.isRequired
};

export default connect(null, mapDispatchModalToProps)(injectIntl(CurrencyPickerDisabled));
