import { createSelector } from 'reselect';


export const getPaymentPlus = state => state.get('paymentPlus');


export const getBackedUpData = createSelector(
    getPaymentPlus,
    paymentPlus => paymentPlus.get('backupData')
);
