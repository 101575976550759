import { push } from '../../routing/routing';
import {
    getUYOPath,
    getDesignPath
} from '../../routing/flow';

import { getSupportedUYO } from '../uyoSelectors';
import { uyoInitStepPreConditions, uyoInitStepPostConditions } from './uyoInitStepSelectors';
import { brandCatalogIsLoaded, preferencesAreLoaded } from '../../brand/brandSelectors';


const uyoStepConfig = {
    component: () => import('./UYOInitStep'),
    flow: 'buy',
    flowProps: (state, ownProps) => {
        const { isPlastic } = ownProps;

        const supportedUYO = getSupportedUYO(state);
        const isPhotoSupported = isPlastic
            ? supportedUYO.get('supportsUYOPlasticPhoto')
            : supportedUYO.get('supportsUYOEgcPhoto');
        const isVideoSupported = !isPlastic ? supportedUYO.get('supportsUYOEgcVideo') : false;

        const uyoBasePath = getUYOPath();

        const goBackPath = getDesignPath();
        const submitPath = `${uyoBasePath}-select`;

        return {
            isPlastic,
            isPhotoSupported,
            isVideoSupported,
            submitPath,
            goBack: () => { push({ pathname: goBackPath }); },
            goToDesign: () => { push({ pathname: getDesignPath() }); },
            isUYO: true
        };
    },
    isReady: (state) => {
        const preferencesLoaded = preferencesAreLoaded(state);
        const catalogsLoaded = brandCatalogIsLoaded(state);
        return preferencesLoaded && catalogsLoaded;
    },
    canLoad: (state, ownProps) => {
        const { isPhotoSupported, isVideoSupported } = ownProps;
        return { isAllowedOrPath: (isPhotoSupported || isVideoSupported) || getDesignPath() };
    },
    preConditions: uyoInitStepPreConditions,
    postConditions: uyoInitStepPostConditions
};

export default uyoStepConfig;
