import { defineMessages } from 'react-intl';


const messages = defineMessages({
    title: {
        id: 'notfound.NotFound.title',
        description: 'Top-level heading displayed when a page is not found for a given URL',
        defaultMessage: 'Page Not Found',
        meta: {
            DEPRECATED: true
        }
    },
    notFoundTitle: {
        id: 'error.NotFound.title',
        description: 'Top-level heading displayed when a page is not found for a given URL',
        defaultMessage: 'Page Not Found'
    },
    errorRefreshTitle: {
        id: 'error.ErrorRefresh.title',
        description: 'Top-level heading displayed when an unrecoverable error occurs and the user should refresh the page and retry',
        defaultMessage: 'Error'
    },
    errorRefreshDescription: {
        id: 'error.ErrorRefresh.description',
        description: 'Description displayed when an unrecoverable error occurs and the user should refresh the page and retry',
        defaultMessage: 'Please refresh the page and try again. For questions, please contact Customer Support.'
    },
    errorNoRetryTitle: {
        id: 'error.ErrorNoRetry.title',
        description: 'Top-level heading displayed when an unrecoverable error occurs and the user should check their order status before any retry',
        defaultMessage: 'Error'
    },
    errorNoRetryDescription: {
        id: 'error.ErrorNoRetry.description',
        description: 'Description displayed when an unrecoverable error occurs and the user should check their order status before any retry',
        defaultMessage: 'If you were placing an order, please check your email for a confirmation before attempting to place the order again. For questions, please contact Customer Support.'
    }
});

export default messages;
