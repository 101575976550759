import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withHighContrastMode } from 'react-a11y-utils';

import { LinkExternal } from 'cstar-react-primitives/lib/redux-form/LinkExternal';
import headerMessages from './headerMessages';
import { getStaticMediaURL } from '../app/bootstrap';

export const HeaderImageLink = (props) => {
    const {
        intl, brandUrl, brandName, brandCode, highContrastMode
    } = props;
    const imgLinkProps = {
        cssClass: 'header__img',
        url: brandUrl,
        atts: {
            'aria-label': intl.formatMessage(headerMessages.imageAriaLabel, { brandName })
        }
    };
    const imgLinkPropsInHighContrastMode = {
        url: brandUrl,
        atts: {
            'aria-label': intl.formatMessage(headerMessages.imageAriaLabel, { brandName })
        }
    };
    const logoSrc = intl.formatMessage(headerMessages.logoSrc, { brandCode });
    let imgUrl = `${getStaticMediaURL()}/images/${brandCode}/${logoSrc}`;

    if (window.brandLogoUrl) {
        imgUrl = `${getStaticMediaURL()}${window.brandLogoUrl}`;
    }

    const imgProps = {
        src: imgUrl,
        className: 'header__img',
        alt: ''
    };

    if (highContrastMode) {
        return (
            <div className=' header__logo-high-contrast-mode-container'>
                <LinkExternal {...imgLinkPropsInHighContrastMode}>{ imgProps.alt }</LinkExternal>
            </div>
        );
    }

    
    return (<LinkExternal {...imgLinkProps}><img {...imgProps} /></LinkExternal>);
};

HeaderImageLink.defaultProps = {
    highContrastMode: null
};

HeaderImageLink.propTypes = {
    intl: intlShape.isRequired,
    brandUrl: PropTypes.string.isRequired,
    brandName: PropTypes.string.isRequired,
    brandCode: PropTypes.string.isRequired,
    highContrastMode: PropTypes.string

};

export default withHighContrastMode(injectIntl(HeaderImageLink));
