import { List as IList } from 'immutable';
import { createSelector } from 'reselect';

import { getPaidItemsCount } from '../item/itemSelectors';


export const cartStepCompleted = createSelector(
    getPaidItemsCount,
    itemsCount => itemsCount !== 0
);

export const cartPostConditions = IList([
    cartStepCompleted
]);


export const cartStepSkippable = () => false;
