import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';



const useScrollBottomListener = (handleScrollToBottom, scrollOffset = 0) => {
    const [scrollRef, setScrollRef] = useState(null);

    
    const onScroll = useCallback(() => {
        const contentScrollHeight = Math.round(scrollRef.scrollHeight - scrollOffset);
        const scrolledDistance = Math.round(scrollRef.scrollTop + scrollRef.offsetHeight);

        if (scrolledDistance >= contentScrollHeight) {
            handleScrollToBottom();
        }
    }, [handleScrollToBottom, scrollRef]);

    const debouncedOnScroll = debounce(onScroll, 200, { trailing: true });

    
    useEffect(() => {
        if (scrollRef) {
            scrollRef.addEventListener('scroll', debouncedOnScroll);

            
            
            onScroll();
        }

        return () => {
            if (scrollRef) {
                scrollRef.removeEventListener('scroll', debouncedOnScroll);
            }
        };
    }, [scrollRef]);

    
    
    
    
    return useCallback((node) => {
        if (node !== null) {
            setScrollRef(node);
        }
    }, []);
};

export default useScrollBottomListener;
