import { defineMessages } from 'react-intl';

const datePickerMessages = defineMessages({
    datePickerCalendarLabel: {
        id: 'field.calendar.datePickerMessages.calendar.label',
        description: 'Label text for the calendar',
        defaultMessage: 'Calendar'
    },
    datePickerFieldlabel: {
        id: 'field.calendar.datePickerMessages.field.label',
        description: 'Label text for recipient delivery date input',
        defaultMessage: 'Delivery Date (optional)'
    },
    closeDatePickerLabel: {
        id: 'field.calendar.datePickerMessages.closeDatePickerLabel',
        description: 'a11y label for closing the date picker',
        defaultMessage: 'Close'
    },
    clearDateLabel: {
        id: 'field.calendar.datePickerMessages.clearDateLabel',
        description: 'a11y label for clearing the selected date',
        defaultMessage: 'Clear Date'
    },
    jumpToPrevMonthLabel: {
        id: 'field.calendar.datePickerMessages.jumpToPrevMonthLabel',
        description: 'a11y label for jumping to the previous month',
        defaultMessage: 'Jump to previous month'
    },
    jumpToNextMonthLabel: {
        id: 'field.calendar.datePickerMessages.jumpToNextMonthLabel',
        description: 'a11y label for jumping to the next month',
        defaultMessage: 'Jump to next month'
    },
    keyboardShortcutsLabel: {
        id: 'field.calendar.datePickerMessages.keyboardShortcutsLabel',
        description: 'a11y label for the panel displaying the keyboard shortcuts',
        defaultMessage: 'Keyboard Shortcuts'
    },
    showKeyboardShortcutsPanelLabel: {
        id: 'field.calendar.datePickerMessages.showKeyboardShortcutsPanelLabel',
        description: 'a11y label for opening the keyboard shortcuts panel',
        defaultMessage: 'Open the keyboard shortcuts panel'
    },
    hideKeyboardShortcutsPanelLabel: {
        id: 'field.calendar.datePickerMessages.hideKeyboardShortcutsPanelLabel',
        description: 'a11y label for closing the keyboard shortcuts panel',
        defaultMessage: 'Close the keyboard shortcuts panel'
    },
    enterKeyLabel: {
        id: 'field.calendar.datePickerMessages.enterKeyLabel',
        description: 'a11y label for the Enter key shortcut',
        defaultMessage: 'Enter key'
    },
    selectFocusedDateLabel: {
        id: 'field.calendar.datePickerMessages.selectFocusedDateLabel',
        description: 'instructions for using keyboard shortcuts to select the date that is in focus',
        defaultMessage: 'Select the date in focus'
    },
    leftArrowRightArrowLabel: {
        id: 'field.calendar.datePickerMessages.leftArrowRightArrowLabel',
        description: 'a11y label for the left and right arrow key shortcuts',
        defaultMessage: 'Right and left arrow keys'
    },
    moveFocusByOneDayLabel: {
        id: 'field.calendar.datePickerMessages.moveFocusByOneDayLabel',
        description: 'instructions for using keyboard shortcuts to move back or forward one day',
        defaultMessage: 'Move backward (left) and forward (right) by one day'
    },
    upArrowDownArrowLabel: {
        id: 'field.calendar.datePickerMessages.upArrowDownArrowLabel',
        description: 'a11y label for the up and down arrow key shortcuts',
        defaultMessage: 'Up and down arrow keys'
    },
    moveFocusByOneWeekLabel: {
        id: 'field.calendar.datePickerMessages.moveFocusByOneWeekLabel',
        description: 'instructions for using keyboard shortcuts to move back or forward one week',
        defaultMessage: 'Move backward (up) and forward (down) by one week'
    },
    pageUpPageDownLabel: {
        id: 'field.calendar.datePickerMessages.pageUpPageDownLabel',
        description: 'a11y label for the page up and down key shortcuts',
        defaultMessage: 'Page up and page down keys'
    },
    moveFocusByOneMonthLabel: {
        id: 'field.calendar.datePickerMessages.moveFocusByOneMonthLabel',
        description: 'instructions for using keyboard shortcuts to move back or forward one month',
        defaultMessage: 'Switch months'
    },
    homeEndLabel: {
        id: 'field.calendar.datePickerMessages.homeEndLabel',
        description: 'a11y label for the home and end key shortcuts',
        defaultMessage: 'Home and end keys'
    },
    moveFocustoStartAndEndOfWeekLabel: {
        id: 'field.calendar.datePickerMessages.moveFocustoStartAndEndOfWeekLabel',
        description: 'instructions for using keyboard shortcuts to jump to the first or last day of the week',
        defaultMessage: 'Go to the first or last day of a week'
    },
    escapeLabel: {
        id: 'field.calendar.datePickerMessages.escapeLabel',
        description: 'a11y label for the Escape key shortcut',
        defaultMessage: 'Escape key'
    },
    returnFocusToInputLabel: {
        id: 'field.calendar.datePickerMessages.returnFocusToInputLabel',
        description: 'instructions for using keyboard shortcuts to return focus to the date input field',
        defaultMessage: 'Return to the date input field'
    },
    questionMarkLabel: {
        id: 'field.calendar.datePickerMessages.questionMarkLabel',
        description: 'a11y label for the Question mark key shortcut',
        defaultMessage: 'Question mark key'
    },
    openThisPanelLabel: {
        id: 'field.calendar.datePickerMessages.openThisPanelLabel',
        description: 'instructions for using keyboard shortcuts to open the shortcuts legend panel',
        defaultMessage: 'Open this panel'
    },
    keyboardNavigationInstructionsLabel: {
        id: 'field.calendar.datePickerMessages.keyboardNavigationInstructionsLabel',
        description: 'a11y instructions for the calendar navigation',
        defaultMessage: 'Press the tab key to interact with the calendar. Then, use the left and right arrows keys to '
            + 'move dates and the space bar or enter key to select a date. For detailed instructions, press the '
            + 'question mark key to open the keyboard shortcuts menu.'
    },
    chooseAvailableDateLabel: {
        id: 'field.calendar.datePickerMessages.chooseAvailableDateLabel',
        description: 'a11y label for the currently focused date on the calendar, when the date is available to select',
        defaultMessage: '{date}'
    },
    dateIsUnavailableLabel: {
        id: 'field.calendar.datePickerMessages.dateIsUnavailableLabel',
        description: 'a11y label for the currently focused date on the '
        + 'calendar, when the date is NOT available to select',
        defaultMessage: 'Not available. {date}'
    },
    dateIsSelectedLabel: {
        id: 'field.calendar.datePickerMessages.dateIsSelectedLabel',
        description: 'a11y label for the currently focused date on the '
        + 'calendar, when the date is the currently selected date',
        defaultMessage: 'Selected. {date}'
    }
});

export default datePickerMessages;
