import { newItemIsAccessoriesStepForSelfBuy } from '../accessories/accessoriesUtils';
import { getAccessoryPromoData } from '../accessories/accessoriesModule';
import { getPromoRewardsState } from '../promo/promoSelectors';
import { REWARDS_REQUEST_STATE } from '../promo/promoModule';
import { MESSAGE_FIELD, newItemIsPlastic, newItemMessage } from '../item/newItemForm';
import { maxMessageCharLength } from '../brand/brandSelectors';
import { changeValue } from '../item/newItemFormModule';

import messageIntl from './messageIntl';
import { messageStepPreConditions, messageStepPostConditions, messageStepSkippable } from './messageStepSelectors';


const messageStepConfig = {
    component: () => import('./MessageStep'),
    flow: 'buy',
    flowProps: (state) => {
        const isPlastic = newItemIsPlastic(state);
        return {
            hasAccessories: newItemIsAccessoriesStepForSelfBuy(state),
            promoRewardsState: getPromoRewardsState(state),
            preferredMessage: newItemMessage(state),
            maxMessageLength: maxMessageCharLength(state, { isPlastic })
        };
    },
    beforeRender: (dispatch, ownProps) => {
        const { hasAccessories, preferredMessage, maxMessageLength } = ownProps;
        if (hasAccessories) {
            dispatch(getAccessoryPromoData());
        }
        if (preferredMessage) {
            let message = preferredMessage.slice(0, maxMessageLength);
            
            
            if (/""/g.test(message)) {
                message = '';
            }
            dispatch(changeValue(MESSAGE_FIELD, message));
        }
    },
    isReady: (state, ownProps) => {
        const { hasAccessories, promoRewardsState } = ownProps;

        const isStillFetching = hasAccessories
            
            
            && promoRewardsState === REWARDS_REQUEST_STATE.ACTIVE;

        return !isStillFetching;
    },
    preConditions: messageStepPreConditions,
    postConditions: messageStepPostConditions,
    getSubmitMessage: ({ hasAccessories, isSelfBuy }) => (
        isSelfBuy && hasAccessories
            ? messageIntl.accessoryHeader
            : messageIntl.submitButtonText),
    canSkip: state => (
        messageStepSkippable(state)
    )
};

export default messageStepConfig;
