import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from './LoadingSpinner';


export const LoadingIndicator = ({ children, overlay }) => (
    <div
        data-cashbot-id="loading-indicator"
        data-testid="loading-indicator"
    >
        {children || <LoadingSpinner overlay={overlay} />}
    </div>
);


LoadingIndicator.defaultProps = {
    children: null,
    overlay: false
};

LoadingIndicator.propTypes = {
    children: PropTypes.node,
    overlay: PropTypes.bool
};

export default LoadingIndicator;
