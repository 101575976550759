import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { Seq } from 'immutable';
import { injectIntl, intlShape } from 'react-intl';
import { RadioGroup } from 'cstar-react-primitives/lib/redux-form/microFields/RadioGroup';

import messageIntl from './messageIntl';


export const InspMessageFields = ({
    intl,
    cashbotName,
    name,
    options
}) => {
    const messageList = options.map(msg => ({ value: msg })).toJS();
    const groupProps = {
        component: RadioGroup,
        name,
        cashbotName,
        legend: intl.formatMessage(messageIntl.inspMessageRadioLegend),
        styleType: 'radiobutton',
        containerClass: 'message-list',
        radioOptions: messageList,
        doFocus: true
    };

    return (
        <Field {...groupProps} />
    );
};

InspMessageFields.propTypes = {
    
    intl: intlShape.isRequired,
    cashbotName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.instanceOf(Seq).isRequired
};

export default injectIntl(InspMessageFields);
