import { List as IList } from 'immutable';


const chooseForMeBlacklistBrands = IList([
    'TCF',
    'NORTHITALIA',
    'GRANDLUX',
    'FIDELITYYOUTH'
]);

export default chooseForMeBlacklistBrands;
