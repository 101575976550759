import React from 'react';
import PropTypes from 'prop-types';
import { List as IList } from 'immutable';
import classNames from 'classnames/dedupe';
import { RadioGroup } from 'cstar-react-primitives/lib/microFields/RadioGroup';
import { translateCurrency } from '../intl/intlModule';


export const CurrencyPickerField = ({
    selectedCurrency,
    name,
    cashbotName,
    doFocus,
    currencyList,
    legend,
    onChange
}) => {
    const availableFullRow = currencyList.size % 4 === 0;
    
    const containerClass = classNames('tab-container', 'tab-container--top-flush', 'tab-currency', {
        'tab-currency--center': !availableFullRow && currencyList.size <= 5,
        'tab-currency--start': !availableFullRow && currencyList.size >= 6,
        'tab-currency--between': availableFullRow
    });

    const currencyButtonList = currencyList.map(currency => ({ value: translateCurrency(currency) })).toJS();
    const containerProps = {
        defaultValue: translateCurrency(selectedCurrency),
        name,
        legend,
        cashbotName,
        styleType: 'picker',
        containerClass,
        radioButtonClass: 'tab',
        radioOptions: currencyButtonList,
        handleChange: onChange,
        doFocus
    };

    return (
        <RadioGroup {...containerProps} />
    );
};

CurrencyPickerField.defaultProps = {
    doFocus: false
};

CurrencyPickerField.propTypes = {
    selectedCurrency: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    doFocus: PropTypes.bool,
    name: PropTypes.string.isRequired,
    cashbotName: PropTypes.string.isRequired,
    currencyList: PropTypes.instanceOf(IList).isRequired,
    legend: PropTypes.string.isRequired
};

export default CurrencyPickerField;
