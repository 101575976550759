import messages from './datePickerMessages';

const getPhrases = intl => ({
    calendarLabel: intl.formatMessage(messages.datePickerCalendarLabel),
    closeDatePicker: intl.formatMessage(messages.closeDatePickerLabel),
    clearDate: intl.formatMessage(messages.clearDateLabel),
    jumpToNextMonth: intl.formatMessage(messages.jumpToNextMonthLabel),
    jumpToPrevMonth: intl.formatMessage(messages.jumpToPrevMonthLabel),
    keyboardShortcuts: intl.formatMessage(messages.keyboardShortcutsLabel),
    showKeyboardShortcutsPanel: intl.formatMessage(messages.showKeyboardShortcutsPanelLabel),
    hideKeyboardShortcutsPanel: intl.formatMessage(messages.hideKeyboardShortcutsPanelLabel),
    openThisPanel: intl.formatMessage(messages.openThisPanelLabel),
    enterKey: intl.formatMessage(messages.enterKeyLabel),
    leftArrowRightArrow: intl.formatMessage(messages.leftArrowRightArrowLabel),
    upArrowDownArrow: intl.formatMessage(messages.upArrowDownArrowLabel),
    pageUpPageDown: intl.formatMessage(messages.pageUpPageDownLabel),
    homeEnd: intl.formatMessage(messages.homeEndLabel),
    escape: intl.formatMessage(messages.escapeLabel),
    questionMark: intl.formatMessage(messages.questionMarkLabel),
    selectFocusedDate: intl.formatMessage(messages.selectFocusedDateLabel),
    moveFocusByOneDay: intl.formatMessage(messages.moveFocusByOneDayLabel),
    moveFocusByOneWeek: intl.formatMessage(messages.moveFocusByOneWeekLabel),
    moveFocusByOneMonth: intl.formatMessage(messages.moveFocusByOneMonthLabel),
    moveFocustoStartAndEndOfWeek: intl.formatMessage(messages.moveFocustoStartAndEndOfWeekLabel),
    returnFocusToInput: intl.formatMessage(messages.returnFocusToInputLabel),
    keyboardNavigationInstructions: intl.formatMessage(messages.keyboardNavigationInstructionsLabel),
    chooseAvailableDate: ({ date }) => intl.formatMessage(messages.chooseAvailableDateLabel, { date }),
    dateIsUnavailable: ({ date }) => intl.formatMessage(messages.dateIsUnavailableLabel, { date }),
    dateIsSelected: ({ date }) => intl.formatMessage(messages.dateIsSelectedLabel, { date })
});

export default getPhrases;
