import { fromJS } from 'immutable';
import Big from 'big.js';

import validateFieldsByRules from '../validate/ruleValidator';
import { giftCardFieldNames } from '../item/newItemForm';
import { getFormattedAmountWithCurrency } from '../utils/numberUtils';

import amountMessages from './amountMessages';


export const validateHasAmount = (values) => {
    const amount = parseFloat(values.get(giftCardFieldNames.AMOUNT_FIELD, 0));

    if (!amount) {
        return amountMessages.amountMissingErr;
    }
    return undefined;
};

export const validateMaxCart = (intl, currencyCode, cartMax, subTotal) => (values) => {
    const amount = values.get(giftCardFieldNames.AMOUNT_FIELD, 0);
    const quantity = values.get(giftCardFieldNames.QUANTITY_FIELD, 1);

    
    try {
        const potentialTotal = new Big(amount).times(quantity).plus(subTotal);
        const total = parseFloat(new Big(subTotal).toFixed(2));
        const actualAmount = parseFloat(new Big(amount).times(quantity).toFixed(2));
        const max = parseFloat(new Big(cartMax).toFixed(2));
        if (potentialTotal.gt(cartMax)) {
            if (subTotal && subTotal > 0) {
                return {
                    ...amountMessages.amountCartMaxError,
                    values: {
                        total: getFormattedAmountWithCurrency(intl, total, currencyCode),
                        amount: getFormattedAmountWithCurrency(intl, actualAmount, currencyCode),
                        max: getFormattedAmountWithCurrency(intl, max, currencyCode)
                    }
                };
            }
            return {
                ...amountMessages.amountCartMaxErrorEmptyCart,
                values: {
                    amount: getFormattedAmountWithCurrency(intl, actualAmount, currencyCode),
                    max: getFormattedAmountWithCurrency(intl, max, currencyCode)
                }
            };
        }
        return undefined;
    } catch (invalidNumber) {
        return undefined;
    }
};

export const validateCardAmount = (intl, currencyCode, min, max) => (values) => {
    const amount = parseFloat(values.get(giftCardFieldNames.AMOUNT_FIELD, 0));

    if (amount > max || amount < min) {
        return {
            ...amountMessages.amountRangeErr,
            values: {
                min: getFormattedAmountWithCurrency(intl, min, currencyCode),
                max: getFormattedAmountWithCurrency(intl, max, currencyCode)
            }
        };
    }

    return undefined;
};

export const amountValidator = (values, props) => {
    const {
        maxTransaction, subTotal, minDenomAllowed, maxDenomAllowed, intl, currencyCode
    } = props;
    const constraints = fromJS({
        [giftCardFieldNames.AMOUNT_FIELD]: [
            validateHasAmount,
            validateMaxCart(intl, currencyCode, maxTransaction, subTotal),
            validateCardAmount(intl, currencyCode, minDenomAllowed, maxDenomAllowed)
        ]
    });
    return validateFieldsByRules(values, constraints, intl, true);
};
