import { defineMessages } from 'react-intl';


const brazilStateMap = defineMessages({
    AC: {
        id: 'address.brazil.brazilStateMap.Acre.AC',
        description: 'State label for Acre',
        defaultMessage: 'Acre'
    },
    AL: {
        id: 'address.brazil.brazilStateMap.Alagoas.AL',
        description: 'State label for Alagoas',
        defaultMessage: 'Alagoas'
    },
    AP: {
        id: 'address.brazil.brazilStateMap.Amapá.AP',
        description: 'State label for Amapá',
        defaultMessage: 'Amapá'
    },
    AM: {
        id: 'address.brazil.brazilStateMap.Amazonas.AM',
        description: 'State label for Amazonas',
        defaultMessage: 'Amazonas'
    },
    BA: {
        id: 'address.brazil.brazilStateMap.Bahia.BA',
        description: 'State label for Bahia',
        defaultMessage: 'Bahia'
    },
    CE: {
        id: 'address.brazil.brazilStateMap.Ceará.CE',
        description: 'State label for Ceará',
        defaultMessage: 'Ceará'
    },
    DF: {
        id: 'address.brazil.brazilStateMap.DistritoFederal.DF',
        description: 'State label for Distrito Federal',
        defaultMessage: 'Distrito Federal'
    },
    ES: {
        id: 'address.brazil.brazilStateMap.EspíritoSanto.ES',
        description: 'State label for Espírito Santo',
        defaultMessage: 'Espírito Santo'
    },
    GO: {
        id: 'address.brazil.brazilStateMap.Goiás.GO',
        description: 'State label for Goiás',
        defaultMessage: 'Goiás'
    },
    MA: {
        id: 'address.brazil.brazilStateMap.Maranhão.MA',
        description: 'State label for Maranhão',
        defaultMessage: 'Maranhão'
    },
    MT: {
        id: 'address.brazil.brazilStateMap.MatoGrosso.MT',
        description: 'State label for Mato Grosso',
        defaultMessage: 'Mato Grosso'
    },
    MS: {
        id: 'address.brazil.brazilStateMap.MatoGrossodoSul.MS',
        description: 'State label for Mato Grosso do Sul',
        defaultMessage: 'Mato Grosso do Sul'
    },
    MG: {
        id: 'address.brazil.brazilStateMap.MinasGerais.MG',
        description: 'State label for Minas Gerais',
        defaultMessage: 'Minas Gerais'
    },
    PA: {
        id: 'address.brazil.brazilStateMap.Pará.PA',
        description: 'State label for Pará',
        defaultMessage: 'Pará'
    },
    PB: {
        id: 'address.brazil.brazilStateMap.Paraíba.PB',
        description: 'State label for Paraíba',
        defaultMessage: 'Paraíba'
    },
    PR: {
        id: 'address.brazil.brazilStateMap.Paraná.PR',
        description: 'State label for Paraná',
        defaultMessage: 'Paraná'
    },
    PE: {
        id: 'address.brazil.brazilStateMap.Pernambuco.PE',
        description: 'State label for Pernambuco',
        defaultMessage: 'Pernambuco'
    },
    PI: {
        id: 'address.brazil.brazilStateMap.Piauí.PI',
        description: 'State label for Piauí',
        defaultMessage: 'Piauí'
    },
    RJ: {
        id: 'address.brazil.brazilStateMap.RiodeJaneiro.RJ',
        description: 'State label for Rio de Janeiro',
        defaultMessage: 'Rio de Janeiro'
    },
    RN: {
        id: 'address.brazil.brazilStateMap.RioGrandedoNorte.RN',
        description: 'State label for Rio Grande do Norte',
        defaultMessage: 'Rio Grande do Norte'
    },
    RS: {
        id: 'address.brazil.brazilStateMap.RioGrandedoSul.RS',
        description: 'State label for Rio Grande do Sul',
        defaultMessage: 'Rio Grande do Sul'
    },
    RO: {
        id: 'address.brazil.brazilStateMap.Rondônia.RO',
        description: 'State label for Rondônia',
        defaultMessage: 'Rondônia'
    },
    RR: {
        id: 'address.brazil.brazilStateMap.Roraima.RR',
        description: 'State label for Roraima',
        defaultMessage: 'Roraima'
    },
    SC: {
        id: 'address.brazil.brazilStateMap.SantaCatarina.SC',
        description: 'State label for Santa Catarina',
        defaultMessage: 'Santa Catarina'
    },
    SP: {
        id: 'address.brazil.brazilStateMap.SãoPaulo.SP',
        description: 'State label for São Paulo',
        defaultMessage: 'São Paulo'
    },
    SE: {
        id: 'address.brazil.brazilStateMap.Sergipe.SE',
        description: 'State label for Sergipe',
        defaultMessage: 'Sergipe'
    },
    TO: {
        id: 'address.brazil.brazilStateMap.Tocantins.TO',
        description: 'State label for Tocantins',
        defaultMessage: 'Tocantins'
    }
});

export default brazilStateMap;
