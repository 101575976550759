import React from 'react';
import { List as IList } from 'immutable';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { getFormattedAmountWithCurrency } from '../utils/numberUtils';
import denomMessages from './denomMessages';
import { translateCurrency } from '../intl/intlModule';


export const DenomRange = ({
    intl,
    minRange,
    maxRange,
    rangeCurrencyCode,
    currencyList,
    showCurrencyCode,
    showCurrencyList
}) => {
    let rangeString = '';
    if (minRange === maxRange) {
        rangeString = getFormattedAmountWithCurrency(intl, minRange, rangeCurrencyCode);
    } else if (minRange < maxRange) {
        const minRangeString = getFormattedAmountWithCurrency(intl, minRange, rangeCurrencyCode);
        const maxRangeString = getFormattedAmountWithCurrency(intl, maxRange, rangeCurrencyCode);

        const messageProps = {
            minRange: minRangeString,
            maxRange: maxRangeString,
            currencyCode: translateCurrency(rangeCurrencyCode)
        };
        rangeString = showCurrencyCode
            ? intl.formatMessage(denomMessages.denomRangeWithCurrencyCode, messageProps)
            : intl.formatMessage(denomMessages.denomRange, messageProps);
    } else {
        return null;
    }

    return (
        <div className='denom-range'>
            <div>{rangeString}</div>
            { showCurrencyList && currencyList.size > 0 && (
                <div className='currency-list'>
                    <div className="currency-list-helper">
                        {intl.formatMessage(denomMessages.otherAvailableCurrenciesLabel)}
                    </div>
                    {currencyList
                        .map(currencyCode => (
                            <div className='currency' key={currencyCode}>
                                {translateCurrency(currencyCode)}
                            </div>
                        ))}
                </div>
            ) }
        </div>
    );
};

DenomRange.propTypes = {
    intl: intlShape.isRequired,
    minRange: PropTypes.number.isRequired,
    maxRange: PropTypes.number.isRequired,
    rangeCurrencyCode: PropTypes.string.isRequired,
    currencyList: PropTypes.instanceOf(IList).isRequired,
    showCurrencyCode: PropTypes.bool.isRequired,
    showCurrencyList: PropTypes.bool.isRequired
};

export default injectIntl(DenomRange);
