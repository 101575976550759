import React, { Fragment } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import CashstarLink from './CashstarLink';

export const CashstarLinksCustom = ({
    customLinks
}) => {
    const linkList = customLinks.map((link) => {
        const { text, url } = link.toJS();
        return (
            <CashstarLink
                linkText={text}
                url={url}
                key={url}
            />
        );
    });

    return (
        <Fragment>
            {linkList}
        </Fragment>
    );
};

CashstarLinksCustom.propTypes = {
    customLinks: ImmutablePropTypes.list.isRequired
};

export default CashstarLinksCustom;
