import React from 'react';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';


export const FooterCardholderAgreement = ({ cardholderText }) => (
    <div className='footer-cardholder'>
        <Markdown>{cardholderText}</Markdown>
    </div>
);

FooterCardholderAgreement.defaultProps = {
    cardholderText: null
};


FooterCardholderAgreement.propTypes = {
    cardholderText: PropTypes.string
};


export default FooterCardholderAgreement;
