import {
    requestDefaultBrandPromo,
    requestPromoForCode,
    calculatePromoRewards,
    getApplicableShippingRewards
} from 'capi';
import {
    getPromoNudgeConfig,
    PROMO_NUDGE_SUCCESS,
    PROMO_NUDGE_FAILED
} from 'capi/redux';
import { List as IList, Map as IMap, fromJS } from 'immutable';
import {
    put,
    call,
    select,
    take,
    takeLatest,
    all
} from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

import { logError } from '../utils/errorUtils';
import { getPreferredPromoCode } from '../app/bootstrap';
import { orderSerializer } from '../payment/capiOrderV4ItemSerializer';
import { CATALOGS_LOADED } from '../brand/brandModule';
import { getBrandCatalogLoadTime, getRequireRecipientEmailForPhysicalGift } from '../brand/brandSelectors';
import {
    getNewItemFormValues,
    newItemDenomination,
    CURRENCY_FIELD,
    SENDER_NAME_FIELD,
    RECIPIENT_EMAIL_FIELD,
    RECIPIENT_NAME_FIELD,
    ITEM_ID,
    IS_PLASTIC_FIELD
} from '../item/newItemForm';
import {
    getPaidDigitalItems,
    getShippingGroups,
    getActiveItems
} from '../item/itemSelectors';
import { fieldChangedAction } from '../item/newItemFormModule';
import {
    precartOrderMetaData,
    cartOrderMetaData,
    getCartCurrencyCode
} from '../payment/paymentSelectors';
import { getCapiClient } from '../session/sessionSelectors';
import { removeQueryParams } from '../routing/routing';
import { getIntlLocale } from '../intl/intlSelectors';
import { SET_CURRENCY, SET_LOCALE } from '../intl/intlModule';

import {
    getActivePromo,
    getActivePromoCode
} from './promoSelectors';
import {
    GET_INITIAL_PROMO,
    GET_DEFAULT_PROMO,
    GET_PROMO_FOR_CODE,
    APPLY_ACTIVE_PROMO,
    APPLY_ACTIVE_PROMO_PRE_CART,
    INVALID_PROMO_ERROR,
    EXPIRED_PROMO_ERROR,
    SOLD_OUT_PROMO_ERROR,
    PROMO_VALID_SUCCESS,
    SET_REJECTED_PROMO,
    RESET_PROMO,
    REWARDS_REQUEST_STATE,
    GET_PROMO_NUDGE_CONFIG_DATA,
    setPromo,
    setPromoError,
    setPromoRewards,
    setRejectedPromo,
    finishPromoLoad,
    setPromoStatus,
    clearPromoStatus,
    clearRejectedPromos,
    clearActivePromo,
    setRewardsRequestActive,
    setRewardsRequestFailure,
    finishedGettingPromoRewards,
    removePromoRewards,
    setPromoByCodeRequestState,
    removeCustomRewardCardRecipients,
    getPromoNudgeConfigSuccess,
    getPromoNudgeConfigFailed
} from './promoModule';

const CURRENCY_FIELD_CHANGED = fieldChangedAction(CURRENCY_FIELD);


const isSuccessStatus = status => status === PROMO_VALID_SUCCESS;


const getPromoStatus = (promoData, applySoldOutPromo) => {
    if (!promoData || !promoData.promoCode) {
        return INVALID_PROMO_ERROR;
    }

    if (!promoData.active) {
        return EXPIRED_PROMO_ERROR;
    }

    if (promoData.soldOut && !applySoldOutPromo) {
        return SOLD_OUT_PROMO_ERROR;
    }

    return PROMO_VALID_SUCCESS;
};


function* getPromoBy(args) {
    const capiClient = yield select(getCapiClient);
    let { locale, currency } = args;
    const {
        promoFetchFn,
        getPromoArgs,
        errorReturnVal,
        codeOrDefault
    } = args;
    if (!locale) {
        locale = yield select(getIntlLocale);
    }
    if (!currency) {
        currency = yield select(getCartCurrencyCode);
        if (!currency) {
            
            
            yield take(CURRENCY_FIELD_CHANGED);
            currency = yield select(getCartCurrencyCode);
        }
    }
    try {
        
        return yield call(
            promoFetchFn,
            capiClient,
            ...getPromoArgs(locale, currency)
        );
    } catch (error) {
        yield put(setPromoError(codeOrDefault, error));
        return errorReturnVal;
    }
}


function* getDefaultPromo(locale, currency) {
    return yield call(getPromoBy, {
        codeOrDefault: 'default',
        promoFetchFn: requestDefaultBrandPromo,
        getPromoArgs: (promoLocale, promoCurrency) => [promoLocale, promoCurrency],
        errorReturnVal: null,
        locale,
        currency
    });
}

function* setDefaultPromo(defaultPromoData) {
    const statusType = yield call(getPromoStatus, defaultPromoData, true);
    if (defaultPromoData && isSuccessStatus(statusType)) {
        yield put(setPromo(defaultPromoData));
    }
}


export function* fetchDefaultPromo() {
    const defaultPromoData = yield call(getDefaultPromo);
    yield call(setDefaultPromo, defaultPromoData);
}


function* getPromoForCode(promoCode, locale, currency) {
    yield put(setPromoByCodeRequestState(REWARDS_REQUEST_STATE.ACTIVE));
    const promo = yield call(getPromoBy, {
        codeOrDefault: promoCode,
        promoFetchFn: requestPromoForCode,
        getPromoArgs: (promoLocale, promoCurrency) => [promoCode, promoLocale, promoCurrency],
        errorReturnVal: {},
        locale,
        currency
    });
    yield put(setPromoByCodeRequestState(REWARDS_REQUEST_STATE.SUCCESS));
    return promo;
}


export function* fetchPromoForCode(action) {
    const { promoCode, applySoldOutPromo } = action;
    const promoData = yield call(getPromoForCode, promoCode);
    const statusType = getPromoStatus(promoData, applySoldOutPromo);

    if (isSuccessStatus(statusType)) {
        yield put(setPromo(promoData));
    }
    yield put(setPromoStatus({ statusType, promoCode }));

    return statusType;
}


function* capiV4ApplyPromoRewards(promoCode, metaData, digitalItems, shippingGroups, isApplicableShipping) {
    
    const payment = IMap({
        senderEmail: `${uuid()}@dummy.email`,
        firstName: 'DUMMYNAME'
    });
    const requireRecipientEmailForPhysicalGift = yield select(getRequireRecipientEmailForPhysicalGift);
    const orderData = yield call(
        orderSerializer,
        payment,
        metaData,
        digitalItems,
        shippingGroups,
        requireRecipientEmailForPhysicalGift
    );

    const capiClient = yield select(getCapiClient);
    try {
        yield put(setRewardsRequestActive(orderData, promoCode));
        let rewards;
        if (isApplicableShipping) {
            rewards = yield call(
                getApplicableShippingRewards,
                capiClient,
                promoCode,
                orderData
            );
        } else {
            rewards = yield call(
                calculatePromoRewards,
                capiClient,
                promoCode,
                orderData
            );
        }

        if (rewards) {
            yield put(setPromoRewards(fromJS(rewards), orderData, promoCode));
        } else {
            yield put(setPromoRewards(IMap()));
        }
    } catch (error) {
        yield put(setPromoError(promoCode, error));
        yield put(setRewardsRequestFailure(orderData, promoCode, error));
        logError(error, { promoCode, additionalMessage: 'Error while processing promo' });
    }
    yield put(finishedGettingPromoRewards());
}


export function* applyPromoRewardsPreCart({
    includeAllItems, skipDigital, isApplicableShipping, item
}) {
    const newItemFormValues = yield select(getNewItemFormValues);
    const newItem = item || newItemFormValues;
    const promo = yield select(getActivePromo);
    const isPlastic = newItem ? newItem.get(IS_PLASTIC_FIELD) : false;

    yield* applyRewardsOrFinish(
        promo,
        newItem,
        skipDigital,
        isPlastic,
        includeAllItems,
        isApplicableShipping
    );
}

function* applyRewardsOrFinish(
    promo,
    newItem,
    skipDigital,
    isPlastic,
    includeAllItems,
    isApplicableShipping
) {
    if (promo && promo.size && newItem && (!skipDigital || (skipDigital && isPlastic))) {
        yield call(
            applyDigitalAndPhysicalPromoRewards,
            promo,
            includeAllItems,
            isPlastic,
            newItem,
            isApplicableShipping
        );
    } else {
        yield put(finishedGettingPromoRewards());
    }
}

export function* applyDigitalAndPhysicalPromoRewards(promo, includeAllItems, isPlastic, item, isApplicableShipping) {
    const newItem = item.merge({
        [SENDER_NAME_FIELD]: 'DUMMYNAME',
        [RECIPIENT_EMAIL_FIELD]: `${uuid()}@dummy.email`,
        [RECIPIENT_NAME_FIELD]: 'DUMMYNAME',
        [ITEM_ID]: 'DUMMY_ID'
    });
    const promoCode = promo.get('promoCode');
    const metaData = yield select(precartOrderMetaData);
    let digitalItems = includeAllItems ? yield select(getPaidDigitalItems) : IList();
    let shippingGroups = includeAllItems ? yield select(getShippingGroups) : IList();
    const newItemSumFaceValue = yield select(newItemDenomination);
    if (isPlastic) {
        const newItemShippingGroup = fromJS({
            address: {
                city: newItem.get('city', 'Portland'),
                countryCode: newItem.get('countryCode', 'US'),
                fullName: newItem.get('fullName', 'DUMMYNAME'),
                postalCode: newItem.get('postalCode', '04101'),
                state: newItem.get('state', 'ME'),
                street1: newItem.get('street1', '25 Pearl Street')
            },
            items: [newItem],
            shippingCost: 0,
            shippingMethod: { token: 'DUMMYTOKEN' },
            sumFaceValue: newItemSumFaceValue
        });
        shippingGroups = shippingGroups.push(newItemShippingGroup);
    } else {
        digitalItems = digitalItems.push(newItem);
    }
    yield call(capiV4ApplyPromoRewards, promoCode, metaData, digitalItems, shippingGroups, isApplicableShipping);
}

export function* applyPromoRewards() {
    const activeItems = yield select(getActiveItems);
    const promo = yield select(getActivePromo);

    
    if (promo && promo.size && activeItems && activeItems.size) {
        const promoCode = promo.get('promoCode');
        const metaData = yield select(cartOrderMetaData);
        const digitalItems = yield select(getPaidDigitalItems);
        const shippingGroups = yield select(getShippingGroups);
        yield call(capiV4ApplyPromoRewards, promoCode, metaData, digitalItems, shippingGroups);
    } else {
        yield put(finishedGettingPromoRewards());
    }
}


function* handleDefaultPromo() {
    yield call(fetchDefaultPromo);
    yield call(applyPromoRewards);
    yield put(finishPromoLoad());
}


function* handleNonDefaultPromo(action) {
    const statusType = yield call(fetchPromoForCode, action);
    if (isSuccessStatus(statusType)) {
        yield call(applyPromoRewards);
        
        yield call(removeQueryParams, ['promoCode']);
    }
    yield put(finishPromoLoad());
}


function* checkStatusAndSetPromo(promoCode, promoData) {
    let success = false;
    if (promoData) {
        const statusType = yield call(getPromoStatus, promoData, true);
        success = isSuccessStatus(statusType);
    }

    if (success) {
        yield put(setPromo(promoData));
    } else {
        yield put(setRejectedPromo(promoCode));
    }

    return success;
}


function* getAllPromos(preferredPromoCode, storedPromoCode, locale, currency) {
    const fetchPromoActions = { default: call(getDefaultPromo, locale, currency) };
    if (preferredPromoCode) {
        fetchPromoActions.request = call(getPromoForCode, preferredPromoCode, locale, currency);
    }
    if (storedPromoCode) {
        fetchPromoActions.stored = call(getPromoForCode, storedPromoCode, locale, currency);
    }
    return yield all(fetchPromoActions);
}


function* initialGetPromo({ locale, currency }) {
    const brandCatalogLoadTime = yield select(getBrandCatalogLoadTime);
    if (!brandCatalogLoadTime) {
        yield take(CATALOGS_LOADED);
    }
    const preferredPromoCode = yield call(getPreferredPromoCode);
    const storedPromoCode = yield select(getActivePromoCode);

    const allPromoData = yield call(getAllPromos, preferredPromoCode, storedPromoCode, locale, currency);

    
    let validPreferredPromo = false;
    if (preferredPromoCode) {
        validPreferredPromo = yield call(checkStatusAndSetPromo, preferredPromoCode, allPromoData.request);
    }

    let validStoredPromo = false;
    
    if (storedPromoCode && !validPreferredPromo) {
        validStoredPromo = yield call(checkStatusAndSetPromo, storedPromoCode, allPromoData.stored);
    }

    
    if (allPromoData.default && !validPreferredPromo && !validStoredPromo) {
        yield call(setDefaultPromo, allPromoData.default);
    }

    
    yield call(applyPromoRewards);
    yield put(finishPromoLoad());
}


function* clearPromotionData() {
    yield put(clearPromoStatus());
    yield put(clearActivePromo());
    yield put(removePromoRewards());
    yield put(clearRejectedPromos());
    yield put(removeCustomRewardCardRecipients());
}


function* updatePromoForLanguageAndCurrencyUpdates({ locale, currency }) {
    const storedPromoCode = yield select(getActivePromoCode);

    yield put(setRewardsRequestActive());
    yield call(clearPromotionData);

    const allPromoData = yield call(getAllPromos, null, storedPromoCode, locale, currency);

    let validStoredPromo = false;
    
    if (storedPromoCode) {
        validStoredPromo = yield call(checkStatusAndSetPromo, storedPromoCode, allPromoData.stored);
    }

    
    if (allPromoData.default && !validStoredPromo) {
        yield call(setDefaultPromo, allPromoData.default);
    }

    
    yield call(applyPromoRewards);
}


function* removeRequestPromo() {
    yield call(removeQueryParams, ['promoCode']);
}


function* handlePromoReset() {
    yield call(removeRequestPromo);
    yield call(clearPromotionData);
    yield call(handleDefaultPromo);
}


export function* promoSagasWatcher() {
    yield takeLatest(GET_INITIAL_PROMO, initialGetPromo);
    yield takeLatest(GET_DEFAULT_PROMO, handleDefaultPromo);
    yield takeLatest(GET_PROMO_FOR_CODE, handleNonDefaultPromo);
    yield takeLatest(APPLY_ACTIVE_PROMO, applyPromoRewards);
    yield takeLatest(APPLY_ACTIVE_PROMO_PRE_CART, applyPromoRewardsPreCart);
    yield takeLatest(SET_REJECTED_PROMO, removeRequestPromo);
    yield takeLatest(RESET_PROMO, handlePromoReset);
    yield takeLatest([SET_LOCALE, SET_CURRENCY], updatePromoForLanguageAndCurrencyUpdates);
    yield takeLatest(GET_PROMO_NUDGE_CONFIG_DATA, getPromoNudges);
}

export function* getPromoNudges(action) {
    
    const { promoCode, cartAmount, cardAmount } = action;
    yield put(getPromoNudgeConfig(promoCode, cartAmount, cardAmount));

    const result = yield take([
        PROMO_NUDGE_SUCCESS, PROMO_NUDGE_FAILED
    ]);
    switch (result.type) {
        case PROMO_NUDGE_SUCCESS:
            yield put(getPromoNudgeConfigSuccess(result.data));
            break;
        case PROMO_NUDGE_FAILED:
            
            logError('Error on promo nudge config call. Capi-js returned a request failure');
            
            yield put(getPromoNudgeConfigFailed());
            break;
        default:
            break;
    }
}
