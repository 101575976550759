import { Map as IMap } from 'immutable';

const currencyByLocale = IMap({
    ca: 'CAD',
    gb: 'GBP',
    nz: 'NZD',
    au: 'AUD',
    se: 'SEK',
    hk: 'HKD',
    ch: 'CHF',
    li: 'CHF',
    at: 'EUR',
    be: 'EUR',
    et: 'EUR',
    fi: 'EUR',
    fr: 'EUR',
    de: 'EUR',
    el: 'EUR',
    ie: 'EUR',
    it: 'EUR',
    lv: 'EUR',
    lt: 'EUR',
    lu: 'EUR',
    mt: 'EUR',
    nl: 'EUR',
    pt: 'EUR',
    sk: 'EUR',
    sl: 'EUR',
    es: 'EUR',
    us: 'USD',
    ec: 'USD',
    sv: 'USD',
    jp: 'JPY'
});


export const predictIntlCurrency = (locale) => {
    const regionCodeMatch = locale.match(/\w{2}$/);
    return regionCodeMatch && regionCodeMatch[0]
        ? currencyByLocale.get(regionCodeMatch[0].toLowerCase(), 'USD') : 'USD';
};
