import {
    takeLatest, takeEvery, call, put, select
} from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { geocodeByPlaceId } from '../addressFields/PlacesAutocomplete/utils';

import {
    CURRENCY_FIELD,
    newItemCountry
} from '../item/newItemForm';
import {
    fieldChangedAction,
    setDefaultFieldValues,
    changeValue
} from '../item/newItemFormModule';
import { logError } from '../utils/errorUtils';
import currencyToCountry from '../intl/currencyToCountry';
import { COUNTRY_FIELDS } from '../addressFields/addressFieldsModule';
import { getShippingCountryList } from '../addressFields/addressFieldsSelectors';

import {
    SHIPPING_ADDRESS_SELECTED,
    shippingAddressUpdated,
    addressFieldNames as fields
} from './addressModule';
import { getAddressCityQueryId } from './addressUtils';


const CURRENCY_FIELD_CHANGED = fieldChangedAction(CURRENCY_FIELD);




const shouldLogError = status => (
    status === 'OVER_QUERY_LIMIT' || status === 'REQUEST_DENIED' || status === 'INVALID_REQUEST'
);


const shouldLogWarning = status => (status === 'UNKNOWN_ERROR' || status === 'ERROR');


const shouldDoNothing = status => (status === 'ZERO_RESULTS');


function* getAddressComponents(placeId) {
    let places = [];
    try {
        
        places = yield call(geocodeByPlaceId, placeId);
    } catch (error) { 
        const errorMessage = `${error} when trying to geocode by placeId: ${placeId}`;

        if (shouldLogError(error)) {
            logError(
                errorMessage,
                { placeId },
                ['google-places-geocode', error]
            );
        }

        if (shouldLogWarning(error)) {
            console.warn(errorMessage);
        }

        if (shouldDoNothing(error)) {
            
        }
    }
    if (places && places.length > 0) {
        return places[0].address_components;
    }
    return [];
}


const obtainCityDataWithCorrectQueryId = (countryCode, addressComponents) => {
    const countryCityQueryId = getAddressCityQueryId(countryCode);
    if (countryCityQueryId === undefined) {
        return null;
    }
    return addressComponents.find(
        addressField => addressField.types.find(
            addressQueryId => addressQueryId === countryCityQueryId
        )
    );
};





const findAddressNameByType = (addressComponents, query) => {
    const countryCode = addressComponents.find(addressFields => addressFields.types[0] === 'country');

    const addressObj = addressComponents.find(address => address.types[0] === query);
    if (addressObj !== undefined) {
        if (query === 'administrative_area_level_1') {
            return 'short_name' in addressObj ? addressObj.short_name : 'default short';
        }
        return 'long_name' in addressObj ? addressObj.long_name : 'default long';
    }

    
    
    
    if (addressObj === undefined && query === 'locality') {
        const cityFromCorrectQuery = obtainCityDataWithCorrectQueryId(countryCode.short_name, addressComponents);
        if (cityFromCorrectQuery === null) {
            return null;
        }
        return 'long_name' in cityFromCorrectQuery ? cityFromCorrectQuery.long_name : 'default long';
    }

    return null;
};


export function* updateShippingAddressWithGooglePlace({ placeId }) {
    const addressComponents = yield call(getAddressComponents, placeId);

    if (!addressComponents || !addressComponents.length) return;

    const getAddressComponent = componentType => findAddressNameByType(addressComponents, componentType);

    const streetNumber = getAddressComponent('street_number');
    const route = getAddressComponent('route');
    const city = getAddressComponent('locality');
    const state = getAddressComponent('administrative_area_level_1').toUpperCase();
    const postalCode = getAddressComponent('postal_code');

    if (streetNumber !== null) {
        const combinedStreet = `${streetNumber} ${route}`;
        yield put(changeValue(fields.ADDRESS_STREET1, combinedStreet));
    } else {
        yield put(changeValue(fields.ADDRESS_STREET1, route));
    }

    

    yield put(changeValue(fields.ADDRESS_CITY, city));
    yield put(changeValue(fields.ADDRESS_STATE, state));
    yield put(changeValue(fields.ADDRESS_POSTCODE, postalCode));

    yield put(shippingAddressUpdated());
}


export const getDefaultShippingCountry = (currency, countries) => {
    if (currency) {
        const defaultCountry = currencyToCountry.get(currency, '');
        if (defaultCountry && countries.includes(defaultCountry)) {
            return defaultCountry;
        }
    }
    return (countries.size ? countries.first() : '');
};


export function* setAddressFieldsByCurrency(currencyUpdatedAction) {
    const currency = currencyUpdatedAction.newValue;
    const prevCountryCode = yield select(newItemCountry);
    const shippingCountries = yield select(getShippingCountryList);
    const newCountryCode = yield call(getDefaultShippingCountry, currency, shippingCountries);

    if (prevCountryCode === newCountryCode) return;

    
    const defaultValues = fromJS({
        [fields.ADDRESS_COUNTRY]: newCountryCode
    });
    yield put(setDefaultFieldValues(defaultValues, true));

    
    yield put(changeValue(fields.ADDRESS_STATE, ''));

    
    
    if (newCountryCode === COUNTRY_FIELDS.HONG_KONG) {
        yield put(changeValue(fields.ADDRESS_POSTCODE, ''));
    }

    yield put(shippingAddressUpdated());
}


export function* watchShippingAddressChange() {
    yield takeLatest(SHIPPING_ADDRESS_SELECTED, updateShippingAddressWithGooglePlace);
}


export function* watchCurrencyChange() {
    yield takeEvery(CURRENCY_FIELD_CHANGED, setAddressFieldsByCurrency);
}
