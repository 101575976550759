import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { LinkExternal } from 'cstar-react-primitives/lib/redux-form/LinkExternal';
import { withHighContrastMode } from 'react-a11y-utils';
import classNames from 'classnames/dedupe';
import cashstarLogoMessages from './cashstarLogoMessages';


export const CashstarSVG = ({
    height,
    width,
    title,
    highContrastMode
}) => (
    <svg
        id="cashstar-logo"
        className={
            classNames('footer-logo-cashstar', {
                'footer-logo-cashstar-high-contrast-light': highContrastMode === 'light',
                'footer-logo-cashstar-high-contrast-dark': highContrastMode === 'dark'
            })
        }
        data-name="cashstar"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 290.67 70.31"
        fill="currentColor"
        x="0px"
        y="0px"
        width={width}
        height={height}
        focusable="false"
        aria-labelledby="footer-logo-url"
    >
        <title id="footer-logo-url">{title}</title>
        <path d="M177.17,27.61l-9.27-1.5a17.77,17.77,0,0,1-3-.72l-6.87,5,1,3a27.88,27.88,0,0,0,4.16.87l7.48,1c3.49.46,4.43,1.57,4.08,3.2-.51,2.41-3.27,4-9,4-3.64,0-5.7-.67-6.46-2.69l-7.06-5.14-3.9,2.83c-.85,8.65,4.25,10.86,17.16,10.86,13.18,0,18.59-5.16,19.83-11C186.25,32.5,184.18,28.71,177.17,27.61Zm98.17-13.9a13.5,13.5,0,0,0-12.25,6.66H263c.43-2,.72-4,1-6h-9.53c-.34,2.22-.82,5.09-1.49,8.22l-5.22,24.54h9.92l2.82-13.25c1.91-9,7-12.46,15-12.46,1,0,1.81.07,2.52.13a15.29,15.29,0,0,1,2,.26l1.58-7.44A30.23,30.23,0,0,0,275.34,13.7Zm-41.25-.59c-7.19,0-15.12,1.46-19.05,7l1.23-5.79H204.59l2.12-10-10.56,3-1.48,7h-9.14l-.47,2.21c-2.79-2.59-7.78-3.45-12.51-3.45-9.88,0-15.36,3-18.08,6.42l1.29,4h7.14a3.48,3.48,0,0,1,.09-1c.46-2.15,3.1-3.52,6.82-3.52,5.09,0,7.42,1.31,6.95,5.09h10.31a9.7,9.7,0,0,0,.16-3.46h6.12L189.9,36.81c-1.64,7.7.69,10.9,10.6,10.9a75.15,75.15,0,0,0,8.54-.59l.4-1.9c2,1.86,5.59,3.14,11.21,3.14a25,25,0,0,0,16.75-5.74,35.8,35.8,0,0,0-.7,4.5h9.14c.28-2.22.6-4,1.06-6.2l2.91-13.7C252.27,15.73,243.29,13.12,234.09,13.12Zm-9.92,29.76c-5.68,0-6.87-3-6.39-5.22.83-3.92,5.41-5.48,12.91-5.48h8.16C237.05,40,229.07,42.88,224.18,42.88ZM240.1,26.3h-7.9c-12.53,0-22.69,1.76-24.87,12a7.86,7.86,0,0,0-.07,3c-1,.08-2.14.13-3.14.13-3.79,0-4.87-2-4.11-5.55l3.25-15.27h11.46a13,13,0,0,0-1.39,2.94h10.31c.93-2.55,4.1-4.57,8.93-4.57S241.33,20.49,240.1,26.3Z" />
        <path d="M146.25,30.38l-5.42-3.95a19.9,19.9,0,0,1-.45,3.33l-3.69,17.36h9.92l2.12-10-6.13,4.45Zm-5.72-6.89h8l1.59-4.85c-2.27-4.09-7.33-5.52-11.24-5.52-6,0-10.94,2-14.86,6.66h-.13L128,0h-9.92L111,33.7c-.56-3-3-5.28-8.17-6.09l-9.27-1.5c-4.79-.78-5.29-1.83-4.91-3.59.46-2.15,3.1-3.52,6.82-3.52,5.09,0,7.42,1.31,6.95,5.09h10.31c1.83-8.61-6.68-11-14.51-11-14,0-19.14,5.94-20.17,10.77-.82,3.85-.24,8.81,10.73,10.31l7.48,1c3.49.46,4.43,1.57,4.08,3.2-.51,2.41-3.27,4-9,4-5,0-7-1.24-6.81-5.42H74l2.09-9.85c2.44-11.49-6.53-14.1-15.73-14.1-8.32,0-17.61,2-20.58,10-.32-5.71-4.79-10-16-10C12.16,13.12,3,19.25.52,30.74-1.88,42,4.15,48.36,16.61,48.36c6.63,0,13.17-1.88,17.29-5.81,1.22,3.19,5,5.81,13.06,5.81a25,25,0,0,0,16.75-5.74,35.76,35.76,0,0,0-.7,4.5h9.14c.28-2.22.6-4,1.06-6.2l.59-2.79C73.26,46.25,78.43,48.36,91,48.36c10.33,0,15.88-3.17,18.4-7.36l-1.3,6.12H118l3.36-15.79c1.69-8,7.48-11.55,12.64-11.55C137.17,19.77,139.68,20.6,140.53,23.49ZM50.47,42.88c-5.68,0-6.87-3-6.4-5.22.83-3.92,5.41-5.48,12.91-5.48h8.16C63.35,40,55.37,42.88,50.47,42.88ZM66.4,26.3H58.5c-11.38,0-20.81,1.45-24.07,9.4h-6.9c-1.51,4.63-5.1,6.79-9.73,6.79-7,0-8.34-5.29-7-11.62C12.54,22.71,17.7,19,22.92,19c4.44,0,7,2.48,6.44,6.85H39.67a14,14,0,0,0,.15-2.28H49.95C50.88,21,54,19,58.88,19S67.63,20.49,66.4,26.3Z" />
        <path d="M290.67,9.59a4.58,4.58,0,1,1-4.57-4.46A4.48,4.48,0,0,1,290.67,9.59Zm-8,0a3.46,3.46,0,0,0,3.48,3.56,3.41,3.41,0,0,0,3.4-3.53,3.44,3.44,0,1,0-6.88,0Zm2.75,2.34h-1V7.47a9,9,0,0,1,1.71-.14,2.78,2.78,0,0,1,1.55.33,1.24,1.24,0,0,1,.44,1,1.14,1.14,0,0,1-.92,1v.05a1.32,1.32,0,0,1,.82,1.09,3.4,3.4,0,0,0,.33,1.11h-1.11a3.82,3.82,0,0,1-.35-1.09c-.08-.49-.35-.71-.92-.71h-.49Zm0-2.53h.49c.57,0,1-.19,1-.65s-.3-.68-1-.68a2.47,2.47,0,0,0-.57.05Z" />
        <path d="M44.95,70.14,48.75,60h2.19l3.8,10.12H52.51L51.9,68.4H47.74l-.61,1.74Zm4.86-7.55-1.4,3.95h2.82l-1.4-3.95Z" />
        <path d="M59.18,70.14V60h4.27c2.32,0,3.36,1.24,3.36,2.78A2.34,2.34,0,0,1,65.41,65,2.32,2.32,0,0,1,67,67.28c0,1.74-1.13,2.86-3.32,2.86Zm2-8.25v2.19h2.24c.8,0,1.28-.37,1.28-1.1s-.37-1.1-1.28-1.1Zm0,4.08v2.31h2.38c.88,0,1.28-.44,1.28-1.15S64.47,66,63.58,66Z" />
        <path d="M68.51,70.14V59.45h2V70.14Z" />
        <path d="M72,68c0-1.3.9-2.07,2.89-2.38l2-.3v-.07c0-.73-.46-1.1-1.34-1.1s-1.31.4-1.38.91h-2c.11-1.3,1-2.58,3.36-2.58,2.19,0,3.28,1,3.28,2.78V68.8A3.93,3.93,0,0,0,79,70.14h-2a2.93,2.93,0,0,1-.09-.83,2.64,2.64,0,0,1-2.37,1C72.9,70.31,72,69.36,72,68Zm3.09-.94c-.87.16-1.14.47-1.14.88s.31.78,1.13.78a1.61,1.61,0,0,0,1.81-1.67v-.3Z" />
        <path d="M83.9,62.5a3.38,3.38,0,0,1,3.56,2.91H85.38a1.5,1.5,0,0,0-1.5-1.11c-1.08,0-1.71.85-1.71,2.11s.63,2.11,1.71,2.11a1.5,1.5,0,0,0,1.5-1.11h2.08a3.38,3.38,0,0,1-3.56,2.91,3.91,3.91,0,0,1,0-7.81Z" />
        <path d="M96,62.67l-2.79,3.18,3,4.29H93.82l-1.94-2.95-1.13,1.28v1.67h-2V59.45h2v6.61l2.72-3.39Z" />
        <path d="M98.88,63.36a2.49,2.49,0,0,1,2.07-.85c1.8,0,2.75,1.2,2.75,3v4.66h-2V65.71c0-.87-.37-1.47-1.27-1.47a1.44,1.44,0,0,0-1.52,1.64v4.26h-2V59.45h2Z" />
        <path d="M105.09,68c0-1.3.9-2.07,2.89-2.38l2-.3v-.07c0-.73-.46-1.1-1.34-1.1s-1.31.4-1.38.91h-2c.11-1.3,1-2.58,3.36-2.58,2.19,0,3.28,1,3.28,2.78V68.8a3.93,3.93,0,0,0,.21,1.34h-2a2.93,2.93,0,0,1-.09-.83,2.64,2.64,0,0,1-2.37,1C106,70.31,105.09,69.36,105.09,68Zm3.09-.94c-.87.16-1.14.47-1.14.88s.31.78,1.13.78A1.61,1.61,0,0,0,110,67.08v-.3Z" />
        <path d="M118.61,65.52h0l-1.52,4.62h-1.9l-2.61-7.47h2.09l1.5,4.92,1.54-4.92h1.82l1.55,4.94,1.51-4.94h2.05L122,70.14h-1.9Z" />
        <path d="M132.92,62.67l-2.79,3.18,3,4.29h-2.39l-1.94-2.95-1.13,1.28v1.67h-2V59.45h2v6.61l2.72-3.39Z" />
        <path d="M139.63,70.14h-2V60h2.09l4.42,6.68V60h2V70.14H144l-4.42-6.68Z" />
        <path d="M149.67,67a1.68,1.68,0,0,0,1.81,1.58,1.51,1.51,0,0,0,1.44-.81h2a3.57,3.57,0,0,1-3.53,2.55,3.91,3.91,0,1,1,3.69-3.92c0,.33,0,.6,0,.6Zm0-1.45h3.39a1.62,1.62,0,0,0-1.7-1.41A1.64,1.64,0,0,0,149.7,65.54Z" />
        <path d="M160,70.1a4.35,4.35,0,0,1-1,.09,2,2,0,0,1-2.29-2.28V64.27h-1v-1.6h1V60.82h2v1.85H160v1.6h-1.24v3.42c0,.5.23.73.76.73a1.54,1.54,0,0,0,.47-.07Z" />
        <path d="M166.46,65.52h0l-1.52,4.62H163l-2.61-7.47h2.09l1.5,4.92,1.54-4.92h1.82l1.55,4.94,1.51-4.94h2.05l-2.61,7.47H168Z" />
        <path d="M172.79,66.41a3.92,3.92,0,1,1,3.92,3.9A3.79,3.79,0,0,1,172.79,66.41Zm3.92,2.12a2.14,2.14,0,1,0-1.9-2.12A1.91,1.91,0,0,0,176.7,68.53Z" />
        <path d="M186.22,64.51a2.41,2.41,0,0,0-.4,0c-1.18,0-1.8.58-1.8,2v3.72h-2V62.67h1.87v1a2.26,2.26,0,0,1,2.11-1h.24Z" />
        <path d="M194.6,62.67l-2.79,3.18,3,4.29h-2.39l-1.94-2.95-1.13,1.28v1.67h-2V59.45h2v6.61l2.72-3.39Z" />
        <path d="M199.29,70.14V60h4.27c2.32,0,3.36,1.24,3.36,2.78A2.34,2.34,0,0,1,205.52,65a2.32,2.32,0,0,1,1.55,2.24c0,1.74-1.13,2.86-3.32,2.86Zm2-8.25v2.19h2.24c.8,0,1.28-.37,1.28-1.1s-.37-1.1-1.28-1.1Zm0,4.08v2.31h2.38c.88,0,1.28-.44,1.28-1.15s-.4-1.15-1.28-1.15Z" />
        <path d="M213.31,62.67h2v7.47h-1.87v-.91a2.32,2.32,0,0,1-2.19,1.08c-1.8,0-2.72-1.2-2.72-3V62.67h2V67.1c0,.87.34,1.47,1.24,1.47s1.5-.61,1.5-1.65Z" />
        <path d="M216.6,67.72h2.05c0,.5.4,1,1.24,1s1.17-.27,1.17-.68c0-1.25-4.15-.07-4.15-3,0-1.35,1.15-2.47,3-2.47,2.24,0,3,1.42,3.06,2.52h-2a1,1,0,0,0-1.13-.9c-.66,0-1,.31-1,.73,0,1.25,4.13,0,4.13,3.06,0,1.34-1.14,2.39-3.16,2.39C217.5,70.31,216.7,69.11,216.6,67.72Z" />
        <path d="M224.11,60.49a1.23,1.23,0,1,1,1.23,1.21A1.18,1.18,0,0,1,224.11,60.49Zm.21,2.18h2v7.47h-2Z" />
        <path d="M230.07,63.58a2.37,2.37,0,0,1,2.22-1.08c1.8,0,2.75,1.2,2.75,3v4.66h-2V65.71c0-.87-.37-1.47-1.27-1.47a1.44,1.44,0,0,0-1.52,1.62v4.27h-2V62.67h1.87Z" />
        <path d="M238.36,67a1.68,1.68,0,0,0,1.81,1.58,1.51,1.51,0,0,0,1.44-.81h2a3.57,3.57,0,0,1-3.53,2.55,3.91,3.91,0,1,1,3.69-3.92c0,.33,0,.6,0,.6Zm0-1.45h3.39a1.62,1.62,0,0,0-1.7-1.41A1.64,1.64,0,0,0,238.39,65.54Z" />
        <path d="M244.58,67.72h2.05c0,.5.4,1,1.24,1S249,68.42,249,68c0-1.25-4.15-.07-4.15-3,0-1.35,1.15-2.47,3-2.47,2.24,0,3,1.42,3.06,2.52h-2a1,1,0,0,0-1.13-.9c-.66,0-1,.31-1,.73,0,1.25,4.13,0,4.13,3.06,0,1.34-1.14,2.39-3.16,2.39C245.48,70.31,244.68,69.11,244.58,67.72Z" />
        <path d="M251.57,67.72h2.05c0,.5.4,1,1.24,1S256,68.42,256,68c0-1.25-4.15-.07-4.15-3,0-1.35,1.15-2.47,3-2.47,2.24,0,3,1.42,3.06,2.52h-2a1,1,0,0,0-1.13-.9c-.66,0-1,.31-1,.73,0,1.25,4.13,0,4.13,3.06,0,1.34-1.14,2.39-3.16,2.39C252.46,70.31,251.67,69.11,251.57,67.72Z" />
    </svg>
);

CashstarSVG.defaultProps = {
    height: '36.28px',
    width: '150px',
    highContrastMode: ''
};

CashstarSVG.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
    title: PropTypes.string.isRequired,
    highContrastMode: PropTypes.string
};

export class CashstarLogo extends React.PureComponent {
    render() {
        const { intl, url, highContrastMode } = this.props;
        const title = intl.formatMessage(cashstarLogoMessages.cashstarLogoAlt);
        return (
            <LinkExternal url={url} atts={{ 'aria-label': intl.formatMessage(cashstarLogoMessages.cashstarLogoAriaLabelMessage) }}>
                <CashstarSVG title={title} highContrastMode={highContrastMode} />
            </LinkExternal>
        );
    }
}

CashstarLogo.defaultProps = {
    url: 'https://www.cashstar.com/',
    highContrastMode: null
};

CashstarLogo.propTypes = {
    intl: intlShape.isRequired,
    url: PropTypes.string,
    highContrastMode: PropTypes.string
};


export default withHighContrastMode(injectIntl(CashstarLogo));
