import { defineMessages } from 'react-intl';


const canadianProvinceMap = defineMessages({
    AB: {
        id: 'address.canada.canadianProvinceMap.Alberta.AB',
        description: 'Province label for Alberta',
        defaultMessage: 'Alberta'
    },
    BC: {
        id: 'address.canada.canadianProvinceMap.BritishColumbia.BC',
        description: 'Province label for British Columbia',
        defaultMessage: 'British Columbia'
    },
    MB: {
        id: 'address.canada.canadianProvinceMap.Manitoba.MB',
        description: 'Province label for Manitoba',
        defaultMessage: 'Manitoba'
    },
    NB: {
        id: 'address.canada.canadianProvinceMap.NewBrunswick.NB',
        description: 'Province label for New Brunswick',
        defaultMessage: 'New Brunswick'
    },
    NL: {
        id: 'address.canada.canadianProvinceMap.NewfoundlandAndLabrador.NL',
        description: 'Province label for Newfoundland and Labrador',
        defaultMessage: 'Newfoundland and Labrador'
    },
    NT: {
        id: 'address.canada.canadianProvinceMap.NorthwestTerritories.NT',
        description: 'Province label for Northwest Territories',
        defaultMessage: 'Northwest Territories'
    },
    NS: {
        id: 'address.canada.canadianProvinceMap.NovaScotia.NS',
        description: 'Province label for Nova Scotia',
        defaultMessage: 'Nova Scotia'
    },
    NU: {
        id: 'address.canada.canadianProvinceMap.Nunavut.NU',
        description: 'Province label for Nunavut',
        defaultMessage: 'Nunavut'
    },
    ON: {
        id: 'address.canada.canadianProvinceMap.Ontario.ON',
        description: 'Province label for Ontario',
        defaultMessage: 'Ontario'
    },
    PE: {
        id: 'address.canada.canadianProvinceMap.PrinceEdwardIsland.PE',
        description: 'Province label for Prince Edward Island',
        defaultMessage: 'Prince Edward Island'
    },
    QC: {
        id: 'address.canada.canadianProvinceMap.Quebec.QC',
        description: 'Province label for Quebec',
        defaultMessage: 'Quebec'
    },
    SK: {
        id: 'address.canada.canadianProvinceMap.Saskatchewan.SK',
        description: 'Province label for Saskatchewan',
        defaultMessage: 'Saskatchewan'
    },
    YT: {
        id: 'address.canada.canadianProvinceMap.Yukon.YT',
        description: 'Province label for Yukon',
        defaultMessage: 'Yukon'
    }
});


export const canadianProvinceField = defineMessages({
    provinceLabel: {
        id: 'address.canada.provinceLabel',
        description: 'displayed in CA province dropdown as default empty value',
        defaultMessage: 'Province'
    }
});

export default canadianProvinceMap;
