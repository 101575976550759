import numbersOnlyNormalizer from './numbersOnlyNormalizer';


const postalCodeNormalizerBR = (value) => {
    if (!value || typeof value !== 'string') return value;
    let normalize = numbersOnlyNormalizer(value);
    if (normalize.length && value.length > 5) {
        normalize = `${normalize.slice(0, 5)}-${normalize.slice(5, 8)}`;
    }
    return normalize;
};

export default postalCodeNormalizerBR;
