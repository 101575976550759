import { fromJS, List as IList, Map as IMap } from 'immutable';

import { buildConditionalFlow } from './flow';
import { getStepConfigs, getFlowConfig } from './flowSelectors';

export const STEP_CHANGE = '@@storefront/STEP_CHANGE';


export const getStepDataRequirements = (flowConfig, stepConfigs, branchFlags) => {
    const flowOrder = buildConditionalFlow(flowConfig, branchFlags);
    
    
    
    
    
    
    
    
    
    
    
    return flowOrder
        .reduce((acc, step, index) => {
            
            
            
            
            
            
            
            const precedingRoute = index === 0 ? null : flowOrder.get(index - 1, null);
            const preConditions = stepConfigs.getIn([step, 'preConditions'], IList());
            const postConditions = stepConfigs.getIn([step, 'postConditions'], IList());
            const previousConditions = acc.getIn([precedingRoute, 'conditions'], IList());
            return acc.set(
                step,
                IMap({
                    step,
                    conditions: previousConditions.push(fromJS({ step, preConditions, postConditions })),
                    precedingRoute
                })
            );
        }, new IMap());
};


export const redirectIfDataRequirementsUnsatisfied = (state, flow, path, mergedProps) => {
    const currentStepConfigs = getStepConfigs(state);
    const flowConfigs = getFlowConfig(state);

    const dataRequirements = (
        getStepDataRequirements(flowConfigs.get(flow), currentStepConfigs, mergedProps));

    const currentStep = dataRequirements.get(path, null);
    if (currentStep) {
        const lastStepIndex = currentStep.get('conditions').size - 1;
        
        const conditions = currentStep.get('conditions');
        for (let index = 0; index < conditions.size; index++) {
            const step = conditions.get(index);
            const stepName = step.get('step');

            const precedingStep = dataRequirements.getIn([
                dataRequirements.getIn([stepName, 'precedingRoute'], null), 'step'], null);

            const preConditionsSatisfied = step.get('preConditions').every(validator => validator(state));
            if (precedingStep && !preConditionsSatisfied) {
                
                
                return precedingStep;
            }

            if (index !== lastStepIndex) {
                
                
                
                const postConditionsSatisfied = step.get('postConditions').every(validator => validator(state));
                if (!postConditionsSatisfied) {
                    return stepName;
                }
            }
        }
    }
    return null;
};


export function changeStep(path, sessionID = null) {
    return {
        type: STEP_CHANGE,
        path,
        sessionID
    };
}
