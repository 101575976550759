import React from 'react';
import PropTypes from 'prop-types';
import { List as IList } from 'immutable';
import FaceplateImg from '../faceplatePresentation/FaceplateImg';

export const ProgramPreview = ({
    defaultFaceplates
}) => (
    <div className={`program-faceplate-preview ${defaultFaceplates.size > 1 ? 'multiple' : ''}`}>
        { defaultFaceplates.map((defaultFaceplate) => {
            const faceplateImgProps = {
                faceplate: defaultFaceplate.has('altText') ? defaultFaceplate.set('altText', '') : defaultFaceplate,
                key: defaultFaceplate.get('code')
            };
            const showDefaultFaceplate = defaultFaceplate ? defaultFaceplate.has('imageURL') : null;
            return showDefaultFaceplate ? <FaceplateImg {...faceplateImgProps} /> : null;
        })}
    </div>
);

ProgramPreview.defaultProps = {
    defaultFaceplates: IList()
};

ProgramPreview.propTypes = {
    defaultFaceplates: PropTypes.instanceOf(IList)
};

export default ProgramPreview;
