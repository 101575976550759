import { push } from '../../routing/routing';
import {
    getUYOPath,
    getDesignPath,
    getNextPath,
    getBasePath
} from '../../routing/flow';
import { getBuyFlowConfig } from '../../routing/flowSelectors';

import { getSupportedUYO } from '../uyoSelectors';
import { setUYOFileResWarning } from '../uyoModule';
import { uyoReviewStepPreConditions } from './uyoReviewStepSelectors';
import { brandCatalogIsLoaded, preferencesAreLoaded } from '../../brand/brandSelectors';


const uyoStepConfig = {
    component: () => import('./UYOReviewStep'),
    flow: 'buy',
    flowProps: (state, ownProps) => {
        const { isPlastic, isSelfBuy } = ownProps;

        const supportedUYO = getSupportedUYO(state);
        const isPhotoSupported = isPlastic
            ? supportedUYO.get('supportsUYOPlasticPhoto')
            : supportedUYO.get('supportsUYOEgcPhoto');
        const isVideoSupported = !isPlastic ? supportedUYO.get('supportsUYOEgcVideo') : false;

        const uyoBasePath = getUYOPath();
        const goBackPath = `${uyoBasePath}-select`;
        const nextPath = getNextPath(
            getBuyFlowConfig(state),
            'uyo-review',
            { isSelfBuy, isPlastic, isUYO: true }
        );
        const submitPath = nextPath ? `/${getBasePath()}/${nextPath}` : nextPath;

        return {
            isPlastic,
            isPhotoSupported,
            isVideoSupported,
            submitPath,
            goBack: () => { push({ pathname: goBackPath }); },
            goToDesign: () => { push({ pathname: getDesignPath() }); },
            isUYO: true
        };
    },
    isReady: (state) => {
        const preferencesLoaded = preferencesAreLoaded(state);
        const catalogsLoaded = brandCatalogIsLoaded(state);
        return preferencesLoaded && catalogsLoaded;
    },
    canLoad: (state, ownProps) => {
        const { isPhotoSupported, isVideoSupported } = ownProps;
        return { isAllowedOrPath: (isPhotoSupported || isVideoSupported) || getDesignPath() };
    },
    onExit: (dispatch) => {
        dispatch(setUYOFileResWarning(null));
    },
    preConditions: uyoReviewStepPreConditions
};

export default uyoStepConfig;
