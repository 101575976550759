export const CARD_TYPE = {
    PHYSICAL: 'physical',
    DIGITAL: 'digital'
};


export const SWITCH_CARD_TYPE = '@@storefront/SWITCH_CARD_TYPE';
export const CARD_TYPE_SWITCH_COMPLETE = '@@storefront/CARD_TYPE_SWITCH_COMPLETE';


export const switchCardIsPlastic = isPlastic => ({
    type: SWITCH_CARD_TYPE,
    isPlastic
});

export const completeCardTypeSwitch = () => ({
    type: CARD_TYPE_SWITCH_COMPLETE
});
