
export const formatProgramMessage = (intl, message, programCode, values = {}) => {
    let programMessageId;
    const defaultMessageId = `${message.id}.ORIGINAL`;

    
    
    if (programCode) {
        programMessageId = `${programCode}.${message.id}`;
    } else {
        programMessageId = defaultMessageId;
    }

    
    
    let programMessage;
    if (intl.messages[programMessageId]) {
        programMessage = { ...message, id: programMessageId };
    } else if (intl.messages[defaultMessageId]) {
        programMessage = { ...message, id: defaultMessageId };
    } else {
        
        
        
        programMessage = message;
    }

    return intl.formatMessage(programMessage, values);
};
