
const translateErrorOrWarning = (validator, intl) => (value, allValues, props) => {
    if (typeof validator !== 'function') return undefined;

    
    const validationResult = validator(value, allValues, props);

    if (!validationResult) return validationResult;

    return intl.formatMessage(validationResult, validationResult.values);
};

export default translateErrorOrWarning;
