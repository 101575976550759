import React from 'react';
import PropTypes from 'prop-types';

export class ScrollIntoView extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidUpdate() {
        const {
            doScroll
        } = this.props;
        if (doScroll && this.myRef.current) {
            this.myRef.current.scrollIntoView(true);
        }
    }

    render() {
        const { children } = this.props;

        return (
            <div ref={this.myRef}>
                {children}
            </div>
        );
    }
}

ScrollIntoView.defaultProps = {
    doScroll: false
};

ScrollIntoView.propTypes = {
    
    children: PropTypes.node.isRequired,
    doScroll: PropTypes.bool
};

export default ScrollIntoView;
