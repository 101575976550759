import { defineMessages } from 'react-intl';

const countyNameMap = defineMessages({
    ABERDEENSHIRE: {
        id: 'address.GB.CountyMap.Aberdeenshire',
        description: 'gb county label for Aberdeenshire',
        defaultMessage: 'Aberdeenshire'
    },
    ANGUS: {
        id: 'address.GB.CountyMap.Angus',
        description: 'gb county label for Angus',
        defaultMessage: 'Angus'
    },
    ARGYLL: {
        id: 'address.GB.CountyMap.Argyll',
        description: 'gb county label for Argyll',
        defaultMessage: 'Argyll'
    },
    AYRSHIRE: {
        id: 'address.GB.CountyMap.Ayrshire',
        description: 'gb county label for Ayrshire',
        defaultMessage: 'Ayrshire'
    },
    BANFFSHIRE: {
        id: 'address.GB.CountyMap.Banffshire',
        description: 'gb county label for Banffshire',
        defaultMessage: 'Banffshire'
    },
    BEDFORDSHIRE: {
        id: 'address.GB.CountyMap.Bedfordshire',
        description: 'gb county label for Bedfordshire',
        defaultMessage: 'Bedfordshire'
    },
    BERKSHIRE: {
        id: 'address.GB.CountyMap.Berkshire',
        description: 'gb county label for Berkshire',
        defaultMessage: 'Berkshire'
    },
    BERWICKSHIRE: {
        id: 'address.GB.CountyMap.Berwickshire',
        description: 'gb county label for Berwickshire',
        defaultMessage: 'Berwickshire'
    },
    BLAENAUGWENT: {
        id: 'address.GB.CountyMap.BlaenauGwent',
        description: 'gb county label for Blaenau Gwent',
        defaultMessage: 'Blaenau Gwent'
    },
    BRIDGEND: {
        id: 'address.GB.CountyMap.Bridgend',
        description: 'gb county label for Bridgend',
        defaultMessage: 'Bridgend'
    },
    BRISTOL: {
        id: 'address.GB.CountyMap.Bristol',
        description: 'gb county label for Bristol',
        defaultMessage: 'Bristol'
    },
    BUCKINGHAMSHIRE: {
        id: 'address.GB.CountyMap.Buckinghamshire',
        description: 'gb county label for Buckinghamshire',
        defaultMessage: 'Buckinghamshire'
    },
    BUTE: {
        id: 'address.GB.CountyMap.Bute',
        description: 'gb county label for Bute',
        defaultMessage: 'Bute'
    },
    CAERPHILLY: {
        id: 'address.GB.CountyMap.Caerphilly',
        description: 'gb county label for Caerphilly',
        defaultMessage: 'Caerphilly'
    },
    CAITHNESS: {
        id: 'address.GB.CountyMap.Caithness',
        description: 'gb county label for Caithness',
        defaultMessage: 'Caithness'
    },
    CAMBRIDGESHIRE: {
        id: 'address.GB.CountyMap.Cambridgeshire',
        description: 'gb county label for Cambridgeshire',
        defaultMessage: 'Cambridgeshire'
    },
    CARDIFF: {
        id: 'address.GB.CountyMap.Cardiff',
        description: 'gb county label for Cardiff',
        defaultMessage: 'Cardiff'
    },
    CARMARTHENSHIRE: {
        id: 'address.GB.CountyMap.Carmarthenshire',
        description: 'gb county label for Carmarthenshire',
        defaultMessage: 'Carmarthenshire'
    },
    CEREDIGION: {
        id: 'address.GB.CountyMap.Ceredigion',
        description: 'gb county label for Ceredigion',
        defaultMessage: 'Ceredigion'
    },
    CHESHIRE: {
        id: 'address.GB.CountyMap.Cheshire',
        description: 'gb county label for Cheshire',
        defaultMessage: 'Cheshire'
    },
    CLACKMANNANSHIRE: {
        id: 'address.GB.CountyMap.Clackmannanshire',
        description: 'gb county label for Clackmannanshire',
        defaultMessage: 'Clackmannanshire'
    },
    CONWY: {
        id: 'address.GB.CountyMap.Conwy',
        description: 'gb county label for Conwy',
        defaultMessage: 'Conwy'
    },
    CORNWALL: {
        id: 'address.GB.CountyMap.Cornwall',
        description: 'gb county label for Cornwall',
        defaultMessage: 'Cornwall'
    },
    CUMBRIA: {
        id: 'address.GB.CountyMap.Cumbria',
        description: 'gb county label for Cumbria',
        defaultMessage: 'Cumbria'
    },
    DENBIGHSHIRE: {
        id: 'address.GB.CountyMap.Denbighshire',
        description: 'gb county label for Denbighshire',
        defaultMessage: 'Denbighshire'
    },
    DERBYSHIRE: {
        id: 'address.GB.CountyMap.Derbyshire',
        description: 'gb county label for Derbyshire',
        defaultMessage: 'Derbyshire'
    },
    DEVON: {
        id: 'address.GB.CountyMap.Devon',
        description: 'gb county label for Devon',
        defaultMessage: 'Devon'
    },
    DORSET: {
        id: 'address.GB.CountyMap.Dorset',
        description: 'gb county label for Dorset',
        defaultMessage: 'Dorset'
    },
    DUMFRIESSHIRE: {
        id: 'address.GB.CountyMap.Dumfriesshire',
        description: 'gb county label for Dumfriesshire',
        defaultMessage: 'Dumfriesshire'
    },
    DUNBARTONSHIRE: {
        id: 'address.GB.CountyMap.Dunbartonshire',
        description: 'gb county label for Dunbartonshire',
        defaultMessage: 'Dunbartonshire'
    },
    DURHAM: {
        id: 'address.GB.CountyMap.Durham',
        description: 'gb county label for Durham',
        defaultMessage: 'Durham'
    },
    EASTLOTHIAN: {
        id: 'address.GB.CountyMap.EastLothian',
        description: 'gb county label for East Lothian',
        defaultMessage: 'East Lothian'
    },
    EASTSUSSEX: {
        id: 'address.GB.CountyMap.EastSussex',
        description: 'gb county label for East Sussex',
        defaultMessage: 'East Sussex'
    },
    EASTYORKSHIRE: {
        id: 'address.GB.CountyMap.EastYorkshire',
        description: 'gb county label for East Yorkshire',
        defaultMessage: 'East Yorkshire'
    },
    EDINBURGH: {
        id: 'address.GB.CountyMap.Edinburgh',
        description: 'gb county label for Edinburgh',
        defaultMessage: 'Edinburgh'
    },
    ESSEX: {
        id: 'address.GB.CountyMap.Essex',
        description: 'gb county label for Essex',
        defaultMessage: 'Essex'
    },
    FIFE: {
        id: 'address.GB.CountyMap.Fife',
        description: 'gb county label for Fife',
        defaultMessage: 'Fife'
    },
    FLINTSHIRE: {
        id: 'address.GB.CountyMap.Flintshire',
        description: 'gb county label for Flintshire',
        defaultMessage: 'Flintshire'
    },
    GLASGOW: {
        id: 'address.GB.CountyMap.Glasgow',
        description: 'gb county label for Glasgow',
        defaultMessage: 'Glasgow'
    },
    GLOUCESTERSHIRE: {
        id: 'address.GB.CountyMap.Gloucestershire',
        description: 'gb county label for Gloucestershire',
        defaultMessage: 'Gloucestershire'
    },
    GREATERLONDON: {
        id: 'address.GB.CountyMap.GreaterLondon',
        description: 'gb county label for Greater London',
        defaultMessage: 'Greater London'
    },
    GREATERMANCHESTER: {
        id: 'address.GB.CountyMap.GreaterManchester',
        description: 'gb county label for Greater Manchester',
        defaultMessage: 'Greater Manchester'
    },
    GWYNEDD: {
        id: 'address.GB.CountyMap.Gwynedd',
        description: 'gb county label for Gwynedd',
        defaultMessage: 'Gwynedd'
    },
    HAMPSHIRE: {
        id: 'address.GB.CountyMap.Hampshire',
        description: 'gb county label for Hampshire',
        defaultMessage: 'Hampshire'
    },
    HEREFORDSHIRE: {
        id: 'address.GB.CountyMap.Herefordshire',
        description: 'gb county label for Herefordshire',
        defaultMessage: 'Herefordshire'
    },
    HERTFORDSHIRE: {
        id: 'address.GB.CountyMap.Hertfordshire',
        description: 'gb county label for Hertfordshire',
        defaultMessage: 'Hertfordshire'
    },
    INNERHEBRIDES: {
        id: 'address.GB.CountyMap.InnerHebrides',
        description: 'gb county label for Inner Hebrides',
        defaultMessage: 'Inner Hebrides'
    },
    INVERNESSSHIRE: {
        id: 'address.GB.CountyMap.Invernessshire',
        description: 'gb county label for Inverness-shire',
        defaultMessage: 'Inverness-shire'
    },
    ISLEOFANGLESEY: {
        id: 'address.GB.CountyMap.IsleofAnglesey',
        description: 'gb county label for Isle of Anglesey',
        defaultMessage: 'Isle of Anglesey'
    },
    ISLEOFWIGHT: {
        id: 'address.GB.CountyMap.IsleofWight',
        description: 'gb county label for Isle of Wight',
        defaultMessage: 'Isle of Wight'
    },
    KENT: {
        id: 'address.GB.CountyMap.Kent',
        description: 'gb county label for Kent',
        defaultMessage: 'Kent'
    },
    KINCARDINESHIRE: {
        id: 'address.GB.CountyMap.Kincardineshire',
        description: 'gb county label for Kincardineshire',
        defaultMessage: 'Kincardineshire'
    },
    KINROSSSHIRE: {
        id: 'address.GB.CountyMap.Kinrossshire',
        description: 'gb county label for Kinross-shire',
        defaultMessage: 'Kinross-shire'
    },
    KIRKCUDBRIGHTSHIRE: {
        id: 'address.GB.CountyMap.Kirkcudbrightshire',
        description: 'gb county label for Kirkcudbrightshire',
        defaultMessage: 'Kirkcudbrightshire'
    },
    LANARKSHIRE: {
        id: 'address.GB.CountyMap.Lanarkshire',
        description: 'gb county label for Lanarkshire',
        defaultMessage: 'Lanarkshire'
    },
    LANCASHIRE: {
        id: 'address.GB.CountyMap.Lancashire',
        description: 'gb county label for Lancashire',
        defaultMessage: 'Lancashire'
    },
    LEICESTERSHIRE: {
        id: 'address.GB.CountyMap.Leicestershire',
        description: 'gb county label for Leicestershire',
        defaultMessage: 'Leicestershire'
    },
    LINCOLNSHIRE: {
        id: 'address.GB.CountyMap.Lincolnshire',
        description: 'gb county label for Lincolnshire',
        defaultMessage: 'Lincolnshire'
    },
    MERSEYSIDE: {
        id: 'address.GB.CountyMap.Merseyside',
        description: 'gb county label for Merseyside',
        defaultMessage: 'Merseyside'
    },
    MERTHYRTYDFIL: {
        id: 'address.GB.CountyMap.MerthyrTydfil',
        description: 'gb county label for Merthyr Tydfil',
        defaultMessage: 'Merthyr Tydfil'
    },
    MIDDLESEX: {
        id: 'address.GB.CountyMap.Middlesex',
        description: 'gb county label for Middlesex',
        defaultMessage: 'Middlesex'
    },
    MIDLOTHIAN: {
        id: 'address.GB.CountyMap.Midlothian',
        description: 'gb county label for Midlothian',
        defaultMessage: 'Midlothian'
    },
    MONMOUTHSHIRE: {
        id: 'address.GB.CountyMap.Monmouthshire',
        description: 'gb county label for Monmouthshire',
        defaultMessage: 'Monmouthshire'
    },
    MORAY: {
        id: 'address.GB.CountyMap.Moray',
        description: 'gb county label for Moray',
        defaultMessage: 'Moray'
    },
    NAIRNSHIRE: {
        id: 'address.GB.CountyMap.Nairnshire',
        description: 'gb county label for Nairnshire',
        defaultMessage: 'Nairnshire'
    },
    NEATHPORTTALBOT: {
        id: 'address.GB.CountyMap.NeathPortTalbot',
        description: 'gb county label for Neath Port Talbot',
        defaultMessage: 'Neath Port Talbot'
    },
    NEWPORT: {
        id: 'address.GB.CountyMap.Newport',
        description: 'gb county label for Newport',
        defaultMessage: 'Newport'
    },
    NORFOLK: {
        id: 'address.GB.CountyMap.Norfolk',
        description: 'gb county label for Norfolk',
        defaultMessage: 'Norfolk'
    },
    NORTHYORKSHIRE: {
        id: 'address.GB.CountyMap.NorthYorkshire',
        description: 'gb county label for North Yorkshire',
        defaultMessage: 'North Yorkshire'
    },
    NORTHAMPTONSHIRE: {
        id: 'address.GB.CountyMap.Northamptonshire',
        description: 'gb county label for Northamptonshire',
        defaultMessage: 'Northamptonshire'
    },
    NORTHUMBERLAND: {
        id: 'address.GB.CountyMap.Northumberland',
        description: 'gb county label for Northumberland',
        defaultMessage: 'Northumberland'
    },
    NOTTINGHAMSHIRE: {
        id: 'address.GB.CountyMap.Nottinghamshire',
        description: 'gb county label for Nottinghamshire',
        defaultMessage: 'Nottinghamshire'
    },
    ORKNEY: {
        id: 'address.GB.CountyMap.Orkney',
        description: 'gb county label for Orkney',
        defaultMessage: 'Orkney'
    },
    OUTERHEBRIDES: {
        id: 'address.GB.CountyMap.OuterHebrides',
        description: 'gb county label for Outer Hebrides',
        defaultMessage: 'Outer Hebrides'
    },
    OXFORDSHIRE: {
        id: 'address.GB.CountyMap.Oxfordshire',
        description: 'gb county label for Oxfordshire',
        defaultMessage: 'Oxfordshire'
    },
    PEEBLESSHIRE: {
        id: 'address.GB.CountyMap.Peeblesshire',
        description: 'gb county label for Peeblesshire',
        defaultMessage: 'Peeblesshire'
    },
    PEMBROKESHIRE: {
        id: 'address.GB.CountyMap.Pembrokeshire',
        description: 'gb county label for Pembrokeshire',
        defaultMessage: 'Pembrokeshire'
    },
    PERTHSHIRE: {
        id: 'address.GB.CountyMap.Perthshire',
        description: 'gb county label for Perthshire',
        defaultMessage: 'Perthshire'
    },
    POWYS: {
        id: 'address.GB.CountyMap.Powys',
        description: 'gb county label for Powys',
        defaultMessage: 'Powys'
    },
    RENFREWSHIRE: {
        id: 'address.GB.CountyMap.Renfrewshire',
        description: 'gb county label for Renfrewshire',
        defaultMessage: 'Renfrewshire'
    },
    RHONDDACYNONTAF: {
        id: 'address.GB.CountyMap.RhonddaCynonTaf',
        description: 'gb county label for Rhondda Cynon Taf',
        defaultMessage: 'Rhondda Cynon Taf'
    },
    ROSSANDCROMARTY: {
        id: 'address.GB.CountyMap.RossandCromarty',
        description: 'gb county label for Ross and Cromarty',
        defaultMessage: 'Ross and Cromarty'
    },
    ROXBURGHSHIRE: {
        id: 'address.GB.CountyMap.Roxburghshire',
        description: 'gb county label for Roxburghshire',
        defaultMessage: 'Roxburghshire'
    },
    RUTLAND: {
        id: 'address.GB.CountyMap.Rutland',
        description: 'gb county label for Rutland',
        defaultMessage: 'Rutland'
    },
    SELKIRKSHIRE: {
        id: 'address.GB.CountyMap.Selkirkshire',
        description: 'gb county label for Selkirkshire',
        defaultMessage: 'Selkirkshire'
    },
    SHETLAND: {
        id: 'address.GB.CountyMap.Shetland',
        description: 'gb county label for Shetland',
        defaultMessage: 'Shetland'
    },
    SHROPSHIRE: {
        id: 'address.GB.CountyMap.Shropshire',
        description: 'gb county label for Shropshire',
        defaultMessage: 'Shropshire'
    },
    SOMERSET: {
        id: 'address.GB.CountyMap.Somerset',
        description: 'gb county label for Somerset',
        defaultMessage: 'Somerset'
    },
    SOUTHYORKSHIRE: {
        id: 'address.GB.CountyMap.SouthYorkshire',
        description: 'gb county label for South Yorkshire',
        defaultMessage: 'South Yorkshire'
    },
    STAFFORDSHIRE: {
        id: 'address.GB.CountyMap.Staffordshire',
        description: 'gb county label for Staffordshire',
        defaultMessage: 'Staffordshire'
    },
    STIRLINGSHIRE: {
        id: 'address.GB.CountyMap.Stirlingshire',
        description: 'gb county label for Stirlingshire',
        defaultMessage: 'Stirlingshire'
    },
    SUFFOLK: {
        id: 'address.GB.CountyMap.Suffolk',
        description: 'gb county label for Suffolk',
        defaultMessage: 'Suffolk'
    },
    SURREY: {
        id: 'address.GB.CountyMap.Surrey',
        description: 'gb county label for Surrey',
        defaultMessage: 'Surrey'
    },
    SUTHERLAND: {
        id: 'address.GB.CountyMap.Sutherland',
        description: 'gb county label for Sutherland',
        defaultMessage: 'Sutherland'
    },
    SWANSEA: {
        id: 'address.GB.CountyMap.Swansea',
        description: 'gb county label for Swansea',
        defaultMessage: 'Swansea'
    },
    TORFAEN: {
        id: 'address.GB.CountyMap.Torfaen',
        description: 'gb county label for Torfaen',
        defaultMessage: 'Torfaen'
    },
    TYNEANDWEAR: {
        id: 'address.GB.CountyMap.TyneandWear',
        description: 'gb county label for Tyne and Wear',
        defaultMessage: 'Tyne and Wear'
    },
    VALEOFGLAMORGAN: {
        id: 'address.GB.CountyMap.ValeofGlamorgan',
        description: 'gb county label for Vale of Glamorgan',
        defaultMessage: 'Vale of Glamorgan'
    },
    WARWICKSHIRE: {
        id: 'address.GB.CountyMap.Warwickshire',
        description: 'gb county label for Warwickshire',
        defaultMessage: 'Warwickshire'
    },
    WESTLOTHIAN: {
        id: 'address.GB.CountyMap.WestLothian',
        description: 'gb county label for West Lothian',
        defaultMessage: 'West Lothian'
    },
    WESTMIDLANDS: {
        id: 'address.GB.CountyMap.WestMidlands',
        description: 'gb county label for West Midlands',
        defaultMessage: 'West Midlands'
    },
    WESTSUSSEX: {
        id: 'address.GB.CountyMap.WestSussex',
        description: 'gb county label for West Sussex',
        defaultMessage: 'West Sussex'
    },
    WESTYORKSHIRE: {
        id: 'address.GB.CountyMap.WestYorkshire',
        description: 'gb county label for West Yorkshire',
        defaultMessage: 'West Yorkshire'
    },
    WIGTOWNSHIRE: {
        id: 'address.GB.CountyMap.Wigtownshire',
        description: 'gb county label for Wigtownshire',
        defaultMessage: 'Wigtownshire'
    },
    WILTSHIRE: {
        id: 'address.GB.CountyMap.Wiltshire',
        description: 'gb county label for Wiltshire',
        defaultMessage: 'Wiltshire'
    },
    WORCESTERSHIRE: {
        id: 'address.GB.CountyMap.Worcestershire',
        description: 'gb county label for Worcestershire',
        defaultMessage: 'Worcestershire'
    },
    WREXHAM: {
        id: 'address.GB.CountyMap.Wrexham',
        description: 'gb county label for Wrexham',
        defaultMessage: 'Wrexham'
    },
    COUNTYANTRIM: {
        id: 'address.GB.CountyMap.CountyAntrim',
        description: 'gb county label for County Antrim',
        defaultMessage: 'County Antrim'
    },
    COUNTYARMAGH: {
        id: 'address.GB.CountyMap.CountyArmagh',
        description: 'gb county label for County Armagh',
        defaultMessage: 'County Armagh'
    },
    COUNTYDOWN: {
        id: 'address.GB.CountyMap.CountyDown',
        description: 'gb county label for County Down',
        defaultMessage: 'County Down'
    },
    COUNTYFERMANAGH: {
        id: 'address.GB.CountyMap.CountyFermanagh',
        description: 'gb county label for County Fermanagh',
        defaultMessage: 'County Fermanagh'
    },
    COUNTYLONDONDERRY: {
        id: 'address.GB.CountyMap.CountyLondonderry',
        description: 'gb county label for County Londonderry',
        defaultMessage: 'County Londonderry'
    },
    COUNTYTYRONE: {
        id: 'address.GB.CountyMap.CountyTyrone',
        description: 'gb county label for County Tyrone',
        defaultMessage: 'County Tyrone'
    }
});

export const gbCountyCodeMap = {
    ABERDEENSHIRE: 'Aberdeenshire',
    ANGUS: 'Angus',
    ARGYLL: 'Argyll',
    AYRSHIRE: 'Ayrshire',
    BANFFSHIRE: 'Banffshire',
    BEDFORDSHIRE: 'Bedfordshire',
    BERKSHIRE: 'Berkshire',
    BERWICKSHIRE: 'Berwickshire',
    BLAENAUGWENT: 'Blaenau Gwent',
    BRIDGEND: 'Bridgend',
    BRISTOL: 'Bristol',
    BUCKINGHAMSHIRE: 'Buckinghamshire',
    BUTE: 'Bute',
    CAERPHILLY: 'Caerphilly',
    CAITHNESS: 'Caithness',
    CAMBRIDGESHIRE: 'Cambridgeshire',
    CARDIFF: 'Cardiff',
    CARMARTHENSHIRE: 'Carmarthenshire',
    CEREDIGION: 'Ceredigion',
    CHESHIRE: 'Cheshire',
    CLACKMANNANSHIRE: 'Clackmannanshire',
    CONWY: 'Conwy',
    CORNWALL: 'Cornwall',
    CUMBRIA: 'Cumbria',
    DENBIGHSHIRE: 'Denbighshire',
    DERBYSHIRE: 'Derbyshire',
    DEVON: 'Devon',
    DORSET: 'Dorset',
    DUMFRIESSHIRE: 'Dumfriesshire',
    DUNBARTONSHIRE: 'Dunbartonshire',
    DURHAM: 'Durham',
    EASTLOTHIAN: 'East Lothian',
    EASTSUSSEX: 'East Sussex',
    EASTYORKSHIRE: 'East Yorkshire',
    EDINBURGH: 'Edinburgh',
    ESSEX: 'Essex',
    FIFE: 'Fife',
    FLINTSHIRE: 'Flintshire',
    GLASGOW: 'Glasgow',
    GLOUCESTERSHIRE: 'Gloucestershire',
    GREATERLONDON: 'Greater London',
    GREATERMANCHESTER: 'Greater Manchester',
    GWYNEDD: 'Gwynedd',
    HAMPSHIRE: 'Hampshire',
    HEREFORDSHIRE: 'Herefordshire',
    HERTFORDSHIRE: 'Hertfordshire',
    INNERHEBRIDES: 'Inner Hebrides',
    INVERNESSSHIRE: 'Inverness-shire',
    ISLEOFANGLESEY: 'Isle of Anglesey',
    ISLEOFWIGHT: 'Isle of Wight',
    KENT: 'Kent',
    KINCARDINESHIRE: 'Kincardineshire',
    KINROSSSHIRE: 'Kinross-shire',
    KIRKCUDBRIGHTSHIRE: 'Kirkcudbrightshire',
    LANARKSHIRE: 'Lanarkshire',
    LANCASHIRE: 'Lancashire',
    LEICESTERSHIRE: 'Leicestershire',
    LINCOLNSHIRE: 'Lincolnshire',
    MERSEYSIDE: 'Merseyside',
    MERTHYRTYDFIL: 'Merthyr Tydfil',
    MIDDLESEX: 'Middlesex',
    MIDLOTHIAN: 'Midlothian',
    MONMOUTHSHIRE: 'Monmouthshire',
    MORAY: 'Moray',
    NAIRNSHIRE: 'Nairnshire',
    NEATHPORTTALBOT: 'Neath Port Talbot',
    NEWPORT: 'Newport',
    NORFOLK: 'Norfolk',
    NORTHYORKSHIRE: 'North Yorkshire',
    NORTHAMPTONSHIRE: 'Northamptonshire',
    NORTHUMBERLAND: 'Northumberland',
    NOTTINGHAMSHIRE: 'Nottinghamshire',
    ORKNEY: 'Orkney',
    OUTERHEBRIDES: 'Outer Hebrides',
    OXFORDSHIRE: 'Oxfordshire',
    PEEBLESSHIRE: 'Peeblesshire',
    PEMBROKESHIRE: 'Pembrokeshire',
    PERTHSHIRE: 'Perthshire',
    POWYS: 'Powys',
    RENFREWSHIRE: 'Renfrewshire',
    RHONDDACYNONTAF: 'Rhondda Cynon Taf',
    ROSSANDCROMARTY: 'Ross and Cromarty',
    ROXBURGHSHIRE: 'Roxburghshire',
    RUTLAND: 'Rutland',
    SELKIRKSHIRE: 'Selkirkshire',
    SHETLAND: 'Shetland',
    SHROPSHIRE: 'Shropshire',
    SOMERSET: 'Somerset',
    SOUTHYORKSHIRE: 'South Yorkshire',
    STAFFORDSHIRE: 'Staffordshire',
    STIRLINGSHIRE: 'Stirlingshire',
    SUFFOLK: 'Suffolk',
    SURREY: 'Surrey',
    SUTHERLAND: 'Sutherland',
    SWANSEA: 'Swansea',
    TORFAEN: 'Torfaen',
    TYNEANDWEAR: 'Tyne and Wear',
    VALEOFGLAMORGAN: 'Vale of Glamorgan',
    WARWICKSHIRE: 'Warwickshire',
    WESTLOTHIAN: 'West Lothian',
    WESTMIDLANDS: 'West Midlands',
    WESTSUSSEX: 'West Sussex',
    WESTYORKSHIRE: 'West Yorkshire',
    WIGTOWNSHIRE: 'Wigtownshire',
    WILTSHIRE: 'Wiltshire',
    WORCESTERSHIRE: 'Worcestershire',
    WREXHAM: 'Wrexham',
    COUNTYANTRIM: 'County Antrim',
    COUNTYARMAGH: 'County Armagh',
    COUNTYDOWN: 'County Down',
    COUNTYFERMANAGH: 'County Fermanagh',
    COUNTYLONDONDERRY: 'County Londonderry',
    COUNTYTYRONE: 'County Tyrone'
};

export const gbCountyField = defineMessages({
    countyLabel: {
        id: 'address.GB.CountyMap.countyLabel',
        description: 'displayed in GB county dropdown as default empty value',
        defaultMessage: 'County'
    }
});

export default countyNameMap;
