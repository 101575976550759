import { addressFieldNames } from '../address/addressModule';


export const PAYMENT_FORM_NAME = 'payment';


export const SENDER_EMAIL_FIELD = 'senderEmail';
export const CONFIRM_SENDER_EMAIL_FIELD = 'confirmSenderEmail';
export const { ADDRESS_COUNTRY, ADDRESS_STATE } = addressFieldNames;
export const PAYMENT_METHOD_RADIOBUTTON = 'paymentMethod';

export const PAYMENT_PLUS_PAYMENT = 'paymentPlus';


export const EMAIL_FIELD_MAX = 254;


export const CONSUMER_PRODUCT_CODE = 'NORM';
