import { createBrowserHistory } from 'history';
import { changeLocation } from './routingModule';


const browserHistory = createBrowserHistory({});


export const createLocationChangeActionDispatcher = store => (
    browserHistory.listen((location, action) => {
        const { state } = location;
        if (!state || !state.bypassAnalytics) {
            store.dispatch(changeLocation(location, action));
        }
    })
);
export const history = browserHistory;

export const replace = ({ pathname, ...rest }) => {
    browserHistory.replace({ pathname, search: window.location.search, ...rest });
};

export const push = ({ pathname, ...rest }) => {
    browserHistory.push({ pathname, search: window.location.search, ...rest });
};

export const getURLSearchParams = () => new window.URLSearchParams(window.location.search);


export const updateSearch = (params) => {
    const search = new window.URLSearchParams(window.location.search);
    Object.entries(params).forEach(([key, value]) => search.set(key, value));
    history.replace({ search: search.toString() }, history.location.state);
};



export const removeQueryParams = (paramKeys) => {
    const search = new window.URLSearchParams(window.location.search);
    paramKeys.forEach(paramKey => search.delete(paramKey));
    const state = history.location.state || {};
    
    state.bypassAnalytics = true;
    history.replace({ search: search.toString() }, state);
};

export const { goBack } = browserHistory;
