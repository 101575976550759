import { select, takeEvery } from 'redux-saga/effects';
import { STEP_CHANGE } from '../routing/stepUtils';
import {
    SAVE_NEW_ITEM,
    ITEM_IS_REMOVED,
    ITEM_UNDO_REMOVE
} from '../item/itemModule';
import {
    CART_CHECKOUT_STARTED, CART_VIEWED,
    PROMO_EXPIRY_CHECKOUT_CLICKED,
    PROMO_EXPIRY_VIEWED
} from '../cart/cartModule';
import { SELFIE_LINK_CLICKED } from '../routing/flowModule';
import { getStorefrontSessionKey } from '../session/sessionSelectors';
import { PROMO_TO_SOCIAL_BUTTON, TRACK_PAYMENT_PERFORMANCE } from '../payment/paymentModule';

import { getBaseEventProperties, getAnalyticsName } from './segmentSelectors';
import { trackPage, trackEvent } from './segment';
import {
    SET_PROMO_REWARDS,
    SET_REWARDS_REQUEST_STATE,
    SET_REJECTED_PROMO
} from '../promo/promoModule';
import { LOG_SESSION_INFO_IN_SEGMENT } from '../session/sessionModule';
import { CHOOSE_FOR_ME_CLICKED } from '../recipient/recipientModule';



export function* sendRoutingEvent({ path, sessionID }) {
    const name = getAnalyticsName(path);

    if (name) {
        const baseProperties = yield select(getBaseEventProperties, sessionID, name);
        const segmentProperties = {
            name,
            properties: { ...baseProperties }
        };
        trackPage(name, segmentProperties, { context: { ip: '0.0.0.0' } });
    }
}


export function* sendTrackingEvent(action, data) {
    const baseProperties = yield select(getBaseEventProperties);
    const preexistingDataProperties = data.properties || {};
    data.properties = { ...baseProperties, ...preexistingDataProperties };
    trackEvent(action, data, { context: { ip: '0.0.0.0' } });
}




export function* sendSegment(action) {
    const { eventAction, eventData } = action.meta.segment;
    const sessionID = yield select(getStorefrontSessionKey);
    switch (action.type) {
        case SAVE_NEW_ITEM: {
            eventData.cart_id = sessionID;
            yield sendTrackingEvent(eventAction, eventData);
            break;
        }
        case ITEM_IS_REMOVED: {
            eventData.cart_id = sessionID;
            yield sendTrackingEvent(eventAction, eventData);
            break;
        }
        case ITEM_UNDO_REMOVE: {
            eventData.cart_id = sessionID;
            yield sendTrackingEvent(eventAction, eventData);
            break;
        }
        case CART_CHECKOUT_STARTED: {
            eventData.cart_id = sessionID;
            yield sendTrackingEvent(eventAction, eventData);
            break;
        }
        case CART_VIEWED: {
            eventData.cart_id = sessionID;
            yield sendTrackingEvent(eventAction, eventData);
            break;
        }
        case PROMO_TO_SOCIAL_BUTTON: {
            eventData.cart_id = sessionID;
            yield sendTrackingEvent(eventAction, eventData);
            break;
        }
        case TRACK_PAYMENT_PERFORMANCE: {
            eventData.cart_id = sessionID;
            yield sendTrackingEvent(eventAction, eventData);
            break;
        }
        case LOG_SESSION_INFO_IN_SEGMENT: {
            eventData.cart_id = sessionID;
            yield sendTrackingEvent(eventAction, eventData);
            break;
        }
        case SET_REWARDS_REQUEST_STATE: {
            
            eventData.cart_id = sessionID;
            yield sendTrackingEvent(eventAction, eventData);
            break;
        }
        case SET_PROMO_REWARDS: {
            
            eventData.cart_id = sessionID;
            yield sendTrackingEvent(eventAction, eventData);
            break;
        }
        case SET_REJECTED_PROMO: {
            
            eventData.cart_id = sessionID;
            yield sendTrackingEvent(eventAction, eventData);
            break;
        }
        case CHOOSE_FOR_ME_CLICKED: {
            
            eventData.cart_id = sessionID;
            yield sendTrackingEvent(eventAction, eventData);
            break;
        }
        case PROMO_EXPIRY_VIEWED: {
            
            eventData.cart_id = sessionID;
            yield sendTrackingEvent(eventAction, eventData);
            break;
        }
        case PROMO_EXPIRY_CHECKOUT_CLICKED: {
            
            eventData.cart_id = sessionID;
            yield sendTrackingEvent(eventAction, eventData);
            break;
        }
        case SELFIE_LINK_CLICKED: {
            eventData.cart_id = sessionID;
            yield sendTrackingEvent(eventAction, eventData);
            break;
        }
        default: {
            yield sendTrackingEvent(eventAction, eventData);
        }
    }
}


export const takeGeneralPattern = action => ((action.meta || {}).segment !== undefined);


export function* segmentWatcher() {
    yield takeEvery(takeGeneralPattern, sendSegment);
    yield takeEvery(STEP_CHANGE, sendRoutingEvent);
}
