import { List as IList } from 'immutable';

import { createSelector } from 'reselect';
import { programStepCompleted } from '../program/programStepSelectors';
import { cardTypeStepCompleted } from '../cardType/cardTypeStepSelectors';
import {
    getFlowProgress,
    getPreferredMessage,
    getPreferredSelfBuy
} from '../routing/flowSelectors';
import { MESSAGE_FIELD } from '../item/newItemForm';
import { newItemIsAccessoriesStepForSelfBuy } from '../accessories/accessoriesUtils';



export const messageStepCompleted = createSelector(
    getFlowProgress,
    newItemIsAccessoriesStepForSelfBuy,
    (flowState, supportsAccessories) => flowState.has(MESSAGE_FIELD) || supportsAccessories
);


export const messageStepPreConditions = IList([
    programStepCompleted,
    cardTypeStepCompleted
]);


export const messageStepPostConditions = IList([
    messageStepCompleted
]);

export const messageStepSkippable = createSelector(
    getPreferredMessage,
    getPreferredSelfBuy,
    (preferredMessage, preferredSelfBuy) => (
        !!preferredMessage || !!preferredSelfBuy
    )
);
