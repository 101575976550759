
export const MOBILE_WIDTH_CUTOFF = 640;

export const isMobileView = windowWidth => windowWidth < MOBILE_WIDTH_CUTOFF;

export const isMobileDevice = () => {
    
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
};
