import React from 'react';
import PropTypes from 'prop-types';
import { List as IList } from 'immutable';
import { injectIntl, intlShape } from 'react-intl';
import { Panel } from 'cstar-react-primitives/lib/redux-form/layout/Panel';
import { connect } from 'react-redux';
import { newItemReduxForm } from '../item/newItemForm';

import CardTypeOption, { DIGITAL_MSG_KEY, PHYSICAL_MSG_KEY, DIGITAL_SHARE_VIA_LINK_MSG_KEY } from './CardTypeOption';
import cardTypeMessages from './cardTypeMessages';
import { getIsShareViaLinkDeliveryOptionEnabled } from '../brand/brandSelectors';


export const CardTypeForm = ({
    intl,
    isPlastic,
    morePlasticCardsAllowed,
    showCurrencyPicker,
    allowChangingCurrency,
    selectedElectronicCurrency,
    selectedPhysicalCurrency,
    submitAction,
    physicalSubmitMessage,
    digitalSubmitMessage,
    electronicCurrencyList,
    physicalCurrencyList,
    currentCurrency,
    isShareViaLinkDeliveryOptionEnabled
}) => {
    const commonProps = {
        showCurrencyPicker,
        allowChangingCurrency,
        submitAction,
        prevIsPlastic: isPlastic,
        prevCurrency: currentCurrency
    };
    const digitalCardTypeOptionProps = {
        ...commonProps,
        selectedCurrency: selectedElectronicCurrency,
        currencyList: electronicCurrencyList,
        cardHeading: intl.formatMessage(cardTypeMessages.digitalDescriptionHeading),
        messageKey: isShareViaLinkDeliveryOptionEnabled ? DIGITAL_SHARE_VIA_LINK_MSG_KEY : DIGITAL_MSG_KEY,
        isPlastic: false,
        legend: intl.formatMessage(cardTypeMessages.digitalRadioGroupLegend),
        btnText: intl.formatMessage(digitalSubmitMessage),
        doFocus: true
    };
    const physicalCardTypeOptionProps = {
        ...commonProps,
        selectedCurrency: selectedPhysicalCurrency,
        currencyList: physicalCurrencyList,
        cardHeading: intl.formatMessage(cardTypeMessages.physicalDescriptionHeading),
        messageKey: PHYSICAL_MSG_KEY,
        isPlastic: true,
        morePlasticCardsAllowed,
        legend: intl.formatMessage(cardTypeMessages.physicalRadioGroupLegend),
        btnText: intl.formatMessage(physicalSubmitMessage)
    };

    return (
        <form method="POST">
            <Panel>
                <CardTypeOption {...digitalCardTypeOptionProps} />
                <CardTypeOption {...physicalCardTypeOptionProps} />
            </Panel>
        </form>
    );
};

CardTypeForm.defaultProps = {
    isPlastic: false,
    showCurrencyPicker: false,
    allowChangingCurrency: false,
    isShareViaLinkDeliveryOptionEnabled: false
};

CardTypeForm.propTypes = {
    isPlastic: PropTypes.bool,
    morePlasticCardsAllowed: PropTypes.bool.isRequired,
    showCurrencyPicker: PropTypes.bool,
    allowChangingCurrency: PropTypes.bool,
    submitAction: PropTypes.func.isRequired,
    digitalSubmitMessage: PropTypes.object.isRequired,
    physicalSubmitMessage: PropTypes.object.isRequired,
    selectedElectronicCurrency: PropTypes.string.isRequired,
    selectedPhysicalCurrency: PropTypes.string.isRequired,
    electronicCurrencyList: PropTypes.instanceOf(IList).isRequired,
    physicalCurrencyList: PropTypes.instanceOf(IList).isRequired,
    currentCurrency: PropTypes.string.isRequired,
    isShareViaLinkDeliveryOptionEnabled: PropTypes.bool,
    
    intl: intlShape.isRequired
};

const mapStateToProps = state => ({
    isShareViaLinkDeliveryOptionEnabled: getIsShareViaLinkDeliveryOptionEnabled(state)
});

export default connect(mapStateToProps)(injectIntl(newItemReduxForm(CardTypeForm)));
