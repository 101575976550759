import { defineMessages } from 'react-intl';


const consentMessages = defineMessages({
    cookieConsentMessage: {
        id: 'consent.consentMessages.cookieConsentMessage.md',
        description: 'Text that describes cookie consent and provides a privacy policy link',
        defaultMessage: 'This site uses cookies to improve and personalize your experience. By using this site, you '
        + 'consent to the placement of these cookies. Read our [Privacy Policy]({url}) to learn more.'
    },
    cookieConsentButtonText: {
        id: 'consent.consentMessages.cookieConsentButtonText',
        description: 'Text for the cookie consent button',
        defaultMessage: 'Agree & Close'
    },
    termsAcknowledgementMessage: {
        id: 'consent.consentMessages.termsAcknowledgementMessage.md',
        description: 'Message describing what checking the terms acknowledgement box does.',
        defaultMessage: 'By checking this box, you are agreeing to the [Terms and Conditions]({termsUrl}) and '
            + '[Privacy Policy]({policyUrl}).'
    },
    uncheckedTermsAcknowledgementMessage: {
        id: 'consent.consentMessages.uncheckedTermsAcknowledgementMessage',
        description: 'Error message displayed if terms acknowledgement box is unchecked',
        defaultMessage: 'You must agree to the Terms and Conditions and Privacy Policy.'
    },
    uncheckedCardholderAgreementMessage: {
        id: 'consent.consentMessages.uncheckedCardholderAgreementMessage',
        description: 'Error message displayed if cardholder agreement box is unchecked',
        defaultMessage: 'You must agree to the Cardholder Agreement to continue.'
    },
    termsAcknowledgementImplicitMessage: {
        id: 'consent.consentMessages.termsAcknowledgementImplicitMessage.md',
        description: 'GDPR implicit acknowledgement message',
        defaultMessage: 'By continuing to purchase, you are agreeing to the [Terms and Conditions]({termsUrl}) and [Privacy Policy]({policyUrl}).'
    }
});

export const cardholderAgreementModalMessages = defineMessages({
    cardholderAgreementTitle: {
        id: 'consent.cardholderAgreementModalMessages.cardholderAgreementTitle',
        description: 'The title of the Cardholder Agreement modal',
        defaultMessage: 'You\'re Almost There'
    },
    cardholderAgreementSubtitle: {
        id: 'consent.cardholderAgreementModalMessages.cardholderAgreementSubtitle',
        description: 'The subtitle of the Cardholder Agreement modal',
        defaultMessage: 'To continue with your purchase, please read and agree to the Cardholder Agreement'
    },
    cardholderAgreementPromptText: {
        id: 'consent.cardholderAgreementModalMessages.cardholderAgreementPromptText',
        description: 'The prompt asking if the customer agrees to the Cardholder Agreement',
        defaultMessage: 'Do you agree to the above Cardholder Agreement?'
    },
    cardholderAgreementDisagreeButton: {
        id: 'consent.cardholderAgreementModalMessages.cardholderAgreementDisagreeButton',
        description: 'The button to register disagreement with the Cardholder Agreement',
        defaultMessage: 'I Don\'t Agree'
    },
    cardholderAgreementAgreeButton: {
        id: 'consent.cardholderAgreementModalMessages.cardholderAgreementAgreeButton',
        description: 'The button to register agreement with the Cardholder Agreement',
        defaultMessage: 'I Agree'
    }
});

export default consentMessages;
