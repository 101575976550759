import { defineMessages } from 'react-intl';

const accessoriesMessages = defineMessages({
    toggleLabel: {
        id: 'accessories.accessoriesMessages.toggleLabel',
        description: 'Label of the  add accessory toggle button',
        defaultMessage: 'Include this accessory'
    },
    noImageAvailable: {
        id: 'accessories.accessoriesMessages.noImageAvailable',
        description: 'displayed when we have no accessory image to display',
        defaultMessage: 'Sorry, no image available'
    },
    free: {
        id: 'accessories.accessoriesMessages.free',
        description: 'Displayed when the accessory cost is 0.00',
        defaultMessage: 'FREE'
    },
    orderDetailsAccessoryDisplay: {
        id: 'accessories.accessoriesMessages.orderDetailsAccessoryDisplay',
        description: 'The accessory title and cost displayed in the order details',
        defaultMessage: '{accessoryTitle} {accessoryCostFormatted}'
    }
});


export const optionalAccessoriesMessageIds = {
    accessoryTitle: 'accessories.accessoriesMessages.accessoryTitle',
    accessoryDescription: 'accessories.accessoriesMessages.accessoryDescription'
};

export default accessoriesMessages;
