import { List as IList } from 'immutable';

const firstDataBrands = IList([
    'COSTCO',
    'DICKS',
    'DQ',
    'FIELDSTREAM',
    'GOLFGALAXY',
    'HOTELS',
    'TRUEVALUE',
    'WESCO'
]);

export default firstDataBrands;
