import { fromJS } from 'immutable';

import validateFieldsByRules from '../validate/ruleValidator';
import isEmptyRule from '../validate/isEmpty';
import { isValidEmailRule } from '../validate/email';
import { giftCardFieldNames } from '../item/newItemForm';
import validatePhoneNumberRule from '../validate/validatePhoneNumberRule';

import deliveryMessages from './deliveryMessages';

const constraints = fromJS({
    [giftCardFieldNames.RECIPIENT_EMAIL_FIELD]: [
        isEmptyRule(deliveryMessages.emailRequired),
        isValidEmailRule(deliveryMessages.emailInvalid)
    ],
    [giftCardFieldNames.RECIPIENT_SMS_FIELD]: [
        isEmptyRule(deliveryMessages.phoneRequired),
        validatePhoneNumberRule(deliveryMessages.smsPhoneInvalid)
    ]
});

const recipientSmsEnabled = (values, fieldSequence) => {
    
    fieldSequence.push(giftCardFieldNames.RECIPIENT_EMAIL_FIELD);
    
    if (values.get('recipientSms') && values.get('recipientSms').length) {
        fieldSequence.push(giftCardFieldNames.RECIPIENT_SMS_FIELD);
    }
};

const smsDeliveryForRecipientEnabled = (values, fieldSequence) => {
    
    if (values.get('checkedEmail')) {
        fieldSequence.push(giftCardFieldNames.RECIPIENT_EMAIL_FIELD);
    }
    if (values.get('checkedSms')) {
        fieldSequence.push(giftCardFieldNames.RECIPIENT_SMS_FIELD);
    }
};

const validate = (values, props) => {
    
    if (values.get('isSharedViaLink')) return {};

    const fieldSequence = [];
    
    if (!props.isRecipientSMSEnabled && !props.isSMSDeliveryForRecipientEnabled) {
        return validateFieldsByRules(values, constraints, props.intl, null, [giftCardFieldNames.RECIPIENT_EMAIL_FIELD]);
    }
    
    
    if (props.isRecipientSMSEnabled) {
        recipientSmsEnabled(values, fieldSequence);
    }
    
    
    if (props.isSMSDeliveryForRecipientEnabled) {
        smsDeliveryForRecipientEnabled(values, fieldSequence);
    }
    
    if (fieldSequence.length) {
        return validateFieldsByRules(values, constraints, props.intl, null, fieldSequence);
    }
    
    
    const errors = {};
    errors[giftCardFieldNames.RECIPIENT_ERROR_LABEL] = props.intl.formatMessage(deliveryMessages.selectAnyField);
    return errors;
};
export default validate;
