import { fromJS, Map as IMap } from 'immutable';


import {
    egiftCardsSerializer,
    getCustomRewardCardRecipient,
    metaDataSerializer,
    shippingGroupSerializer
} from '../payment/capiOrderV4ItemSerializer';


import { orderMetaData, getConsumerFees } from '../payment/paymentSelectors';
import { getPaidDigitalItems, getShippingGroups } from '../item/itemSelectors';
import { getRequireRecipientEmailForPhysicalGift } from '../brand/brandSelectors';
import { getCustomRewardCardRecipients } from '../promo/promoSelectors';
import { newItemCPFNumber } from '../item/newItemForm';

export const PAYMENT_PLUS_PAYMENT_TYPE = 'paymentPlus';


export const paymentPlusPaymentDataHandler = paymentData => (
    fromJS(paymentData).merge({
        approved: true,
        paymentMethod: PAYMENT_PLUS_PAYMENT_TYPE,
        CAPI_URI_VERSION: 'v5'
    })
);

export const defaultFailureResponse = {
    status: 402,
    statusText: 'Payment Required',
    data: {
        errorCode: 'PAYMENT_REQUIRED',
        fieldErrors: []
    }
};

export const parseErrorResponse = response => ({
    httpCode: response.status,
    httpMessage: response.statusText,
    capiCode: response.data.errorCode,
    fieldErrors: response.data.fieldErrors
});

export const accumulateErrors = (fieldErrors) => {
    
    if (!fieldErrors || !Array.isArray(fieldErrors)) return [];
    const reducer = (accumulator, currentValue) => {
        const errors = currentValue.get('errors');
        const errorId = typeof errors === 'string' ? errors : errors.get(0);
        accumulator.push(errorId);
        return accumulator;
    };
    return fieldErrors.reduce(reducer, []);
};

export const userDataSerializer = (eGiftCards) => {
    
    if (!eGiftCards || eGiftCards.isEmpty()) return null;
    const eGiftCard = eGiftCards.get(0);

    
    if (eGiftCard.get('isSelfBuy') || eGiftCard.get('isSharedViaLink')) {
        return null;
    }


    const [firstName, ...lastName] = eGiftCard.get('senderName').trim().split(' ');

    const userData = {
        firstName,
        lastName: lastName.join(' ').trim() || 'LNU',
        emailAddress: eGiftCard.get('recipientEmail')
    };

    return IMap(userData);
};


export const cartSerializer = (
    metaData,
    digitalItems,
    shippingGroups,
    requireRecipientEmailForPhysicalGift,
    customRewardCardRecipients = undefined,
    fees = [],
    cpfNumber = null
) => {
    const egiftCards = egiftCardsSerializer(
        digitalItems,
        '',
        '',
        requireRecipientEmailForPhysicalGift,
        fees,
    );

    const plasticItems = shippingGroups.map(group => shippingGroupSerializer(
        group,
        '',
        '',
        requireRecipientEmailForPhysicalGift,
        fees
    )).toList();

    let serializedCartData = metaDataSerializer(metaData)
        .merge({
            egiftCards,
            shippingGroups: plasticItems
        });

    
    
    serializedCartData = ['notifyOffers', 'loyaltyNumber']
        .reduce((map, key) => map.delete(key), serializedCartData);

    if (customRewardCardRecipients) {
        serializedCartData = serializedCartData.set(
            'customRewardCardRecipients',
            customRewardCardRecipients.map(
                recipient => getCustomRewardCardRecipient(fromJS(recipient))
            )
        );
    }
    serializedCartData = serializedCartData.set(
        'sessionIds',
        { RISKIFIED: window.RISKX && window.RISKX.getCartId() }
    );
    if (cpfNumber) {
        serializedCartData = serializedCartData.set('localCustomerId', cpfNumber);
    }

    return serializedCartData;
};


export const provideCartInformation = (state) => {
    const metaData = orderMetaData(state);
    const digitalItems = getPaidDigitalItems(state);
    const shippingGroups = getShippingGroups(state);
    const requireRecipientEmailForPhysicalGift = getRequireRecipientEmailForPhysicalGift(state);
    const customRewardCardRecipients = getCustomRewardCardRecipients(state);
    const fees = getConsumerFees(state);
    const cpfNumber = newItemCPFNumber(state);

    const cartInformation = cartSerializer(
        metaData,
        digitalItems,
        shippingGroups,
        requireRecipientEmailForPhysicalGift,
        customRewardCardRecipients,
        fees,
        cpfNumber
    );

    return cartInformation;
};



export const serializeAddress = (address) => {
    
    Object.keys(address).forEach((key) => {
        if (address[key] === 'N/A') {
            delete address[key];
        }
    });
    return address;
};
