import { NAME_FIELD_MAX, FIRSTNAME_FIELD_MAX, LASTNAME_FIELD_MAX } from '../item/newItemForm';
import { CARD_TYPE } from '../cardType/cardTypeModule';

import { isValidEmail } from './email';
import { isRegexMatch } from './matchesRegexRule';


const AMOUNT_REGEX = /^[0-9]+.?[0-9]{0,2}$/;
const isString = value => typeof value === 'string';

const isAmountValid = (amount) => {
    if (isString(amount)) {
        return AMOUNT_REGEX.test(amount);
    }
    return typeof amount === 'number';
};

const isCardTypeValid = (cardType, supportedCardTypes) => {
    const supportsPlastic = supportedCardTypes.get('supportsPlasticCards');
    const supportsDigital = supportedCardTypes.get('supportsDigitalCards');
    return (cardType === CARD_TYPE.PHYSICAL && supportsPlastic) || (cardType === CARD_TYPE.DIGITAL && supportsDigital);
};

export const isBooleanValid = (boolean) => {
    if (typeof boolean === 'boolean') {
        return boolean;
    }
    if (isString(boolean)) {
        const normalizedBoolean = boolean.trim().toLowerCase();
        switch (normalizedBoolean) {
            case 'true':
                return true;
            case 'false':
                return false;
            default:
                return null;
        }
    }
    return null;
};

const getValidName = (name, maxLength) => {
    if (isString(name)) {
        if (name.length > maxLength) {
            return name.substring(0, maxLength);
        }
        return name;
    }
    return null;
};

const isValidProgramCode = (programCodeValue, programCodeList) => {
    const matchedProgram = programCodeList.find(program => (program.get('programCode') === programCodeValue));
    return matchedProgram ? matchedProgram.get('programCode') : null;
};

export const validateProgramCode = (programCode, programCodeList) => (
    isString(programCode) ? isValidProgramCode(programCode, programCodeList) : null
);

export const validateName = name => getValidName(name, NAME_FIELD_MAX);

export const validateBillingFirstName = name => getValidName(name, FIRSTNAME_FIELD_MAX);

export const validateBillingLastName = name => getValidName(name, LASTNAME_FIELD_MAX);

export const validateEmail = email => (isValidEmail(email) ? email : null);



export const validateFaceplateCode = faceplateCode => (isString(faceplateCode) ? faceplateCode : null);

export const validateFaceplateCategory = preferredFaceplateCategory => (
    isString(preferredFaceplateCategory) ? preferredFaceplateCategory : null
);

export const validateAmount = amount => (isAmountValid(amount) ? parseFloat(amount) : null);

export const validateCardType = (cardType, supportedCardTypes) => (
    isCardTypeValid(cardType, supportedCardTypes) ? cardType : null
);

export const validateSelfBuy = (isSelfBuy, isSelfBuyEnabled) => {
    if (isSelfBuyEnabled) {
        return isBooleanValid(isSelfBuy);
    }
    return null;
};

export const validateMessage = message => (isString(message) ? message : null);

export const validateClassicGift = (isClassicGift, hasClassicCatalogs) => {
    if (hasClassicCatalogs) {
        return isBooleanValid(isClassicGift);
    }
    return null;
};

export const validateLoyaltyNumber = (validationRegex, loyaltyNumber) => {
    if (isString(loyaltyNumber)) {
        if (validationRegex) {
            return isRegexMatch(validationRegex, loyaltyNumber) ? loyaltyNumber : null;
        }
        return loyaltyNumber;
    }
    return null;
};
