import {
    call,
    put,
    select,
    take,
    takeEvery,
    takeLatest,
    all
} from 'redux-saga/effects';
import {
    RETRIEVED_FACEPLATE_GROUPS,
    RETRIEVED_BRAND_CONFIG,
    fetchFaceplateGroups
} from 'capi/redux';
import { List as IList, fromJS } from 'immutable';

import {
    changeValue,
    fieldChangedAction,
    setDefaultFieldValues,
    FORM_RESET
} from '../item/newItemFormModule';
import {
    IS_PLASTIC_FIELD,
    SELECTED_GROUP_ORDINAL,
    FACEPLATE_FIELD,
    newItemIsPlastic,
    newItemFaceplate,
    newItemSelectedGroupOrdinal
} from '../item/newItemForm';
import { getSupportedCardTypes } from '../catalogs/catalogSelectors';
import {
    convertFaceplateGroupsToMap,
    primaryFaceplateForGroup,
    primaryFaceplateForDeliveryType,
    isPlasticOnly,
    getSelectedCategoryHasFaceplate,
    isFaceplateInPreferredGroup,
    getFaceplateCategoryOrdinalForCategoryName,
    getFaceplateGroupForOrdinal,
    getDigitalFaceplateGroups,
    getPhysicalFaceplateGroups
} from './faceplateSelectors';
import {
    REQUEST_FACEPLATE_GROUPS,
    SET_FACEPLATE_GROUPS,
    FACEPLATE_PREFETCH_IMAGES,
    FACEPLATE_PREFETCH_END,
    FACEPLATES_LOADED,
    faceplatePrefetchImages,
    setFaceplateGroups,
    requestFaceplateGroups
} from './faceplateModule';
import { SET_LOCALE, INITIALIZE_LOCALE } from '../intl/intlModule';
import { getIntlLocale } from '../intl/intlSelectors';
import { getActiveProgramCode, programIsActive } from '../program/programSelectors';
import { PROGRAM_LOADED } from '../program/programModule';
import {
    getPreferredCardType,
    getPreferredFaceplateCategory,
    getPreferredFaceplateCode
} from '../routing/flowSelectors';
import {
    PREFERRED_FACEPLATE_CATEGORY,
    preferredValueHandledAction,
    removeFieldFromFlowProgress,
    removePreferredValue
} from '../routing/flowProgressModule';
import { validateFaceplateCode } from '../validate/preferredValuesValidators';

const IS_PLASTIC_FIELD_CHANGED = fieldChangedAction(IS_PLASTIC_FIELD);
const SELECTED_GROUP_ORDINAL_CHANGED = fieldChangedAction(SELECTED_GROUP_ORDINAL);


const getIsPlasticDefault = (supportsDigitalCards, supportsPlasticCards, onlyPlasticFaceplates, preferredCardType) => {
    if (preferredCardType !== null) {
        return preferredCardType;
    }
    return onlyPlasticFaceplates || (supportsPlasticCards && !supportsDigitalCards);
};

function* getDefaultOrdinal() {
    const selectedOrdinal = yield select(newItemSelectedGroupOrdinal);
    
    
    if (selectedOrdinal) {
        return selectedOrdinal;
    }
    return 1;
}

function* getDefaultFaceplate(isPlastic, ordinal) {
    const selectedCategory = (yield select(getFaceplateGroupForOrdinal))(isPlastic, ordinal);
    if (selectedCategory) {
        return yield call(primaryFaceplateForGroup, selectedCategory);
    }
    return yield select(primaryFaceplateForDeliveryType, isPlastic);
}

export function* getFaceplateOrdinalFromCategoryName(faceplateCategory, cardType) {
    const preferredFaceplateCode = yield select(getPreferredFaceplateCode);
    if (cardType !== null && !preferredFaceplateCode) {
        return (yield select(getFaceplateCategoryOrdinalForCategoryName))(cardType, faceplateCategory);
    }
    return null;
}

export function* validatePreferredFaceplateCode(faceplateCode) {
    const validatedFaceplateCode = validateFaceplateCode(faceplateCode);
    
    const { skipped } = yield take(preferredValueHandledAction(IS_PLASTIC_FIELD));
    if (skipped || !validatedFaceplateCode) return null;
    const cardType = yield select(getPreferredCardType);
    const isFaceplateCodeValid = (yield select(isFaceplateInPreferredGroup))(cardType, validatedFaceplateCode);
    return isFaceplateCodeValid ? validatedFaceplateCode : null;
}

function* resetSelectedGroupOrdinal() {
    const preferredCategory = yield select(getPreferredFaceplateCategory);
    const isPlastic = yield select(getPreferredCardType);
    const faceplateGroups = isPlastic
        ? yield select(getPhysicalFaceplateGroups)
        : yield select(getDigitalFaceplateGroups);
    let selectedOrdinal = 1;
    if (preferredCategory) {
        const ordinal = (yield call(getFaceplateOrdinalFromCategoryName, preferredCategory, isPlastic)) || 1;
        if (ordinal === 1) {
            yield put(removePreferredValue(PREFERRED_FACEPLATE_CATEGORY));
            yield put(removeFieldFromFlowProgress(PREFERRED_FACEPLATE_CATEGORY));
        }
        selectedOrdinal = ordinal;
    }

    
    const selectedGroup = faceplateGroups.find(group => group.get('ordinal') === selectedOrdinal);
    if (!selectedGroup) {
        const firstGroup = faceplateGroups.get(0);
        
        
        if (firstGroup !== undefined) {
            selectedOrdinal = firstGroup.get('ordinal');
        }
    }
    yield put(changeValue(SELECTED_GROUP_ORDINAL, selectedOrdinal));
}


function* resetDefaultFaceplate() {
    const isPlastic = yield select(newItemIsPlastic);
    const prevFaceplate = yield select(newItemFaceplate);
    const ordinal = yield call(getDefaultOrdinal);
    const updatedFaceplate = yield call(getDefaultFaceplate, isPlastic, ordinal);
    const preferredFaceplateCode = yield select(getPreferredFaceplateCode);

    
    if (preferredFaceplateCode) {
        return;
    }

    if (prevFaceplate === updatedFaceplate || updatedFaceplate === undefined) return;

    
    
    const selectedCategoryHasFaceplate = Boolean(prevFaceplate
        && (yield select(getSelectedCategoryHasFaceplate))(prevFaceplate));

    
    const defaultValues = fromJS({
        [FACEPLATE_FIELD]: selectedCategoryHasFaceplate ? prevFaceplate : updatedFaceplate.get('code')
    });

    yield put(setDefaultFieldValues(fromJS(defaultValues), true));
}


export function* handleSetDefaults(catalogs) {
    
    let cardTypeSupport;
    if (catalogs && catalogs.data) {
        cardTypeSupport = catalogs.data;
    } else {
        
        cardTypeSupport = (yield select(getSupportedCardTypes)).toJS();
    }
    const { supportsDigitalCards, supportsPlasticCards } = cardTypeSupport;

    
    const onlyPlasticFaceplates = yield select(isPlasticOnly);
    const preferredCardType = yield select(getPreferredCardType);
    const isPlasticDefault = yield call(getIsPlasticDefault,
        supportsDigitalCards, supportsPlasticCards, onlyPlasticFaceplates, preferredCardType);

    
    const preferredFaceplateCategory = yield select(getPreferredFaceplateCategory);
    const defaultOrdinal = yield call(getDefaultOrdinal);

    if (preferredFaceplateCategory && defaultOrdinal === 1) {
        yield put(removeFieldFromFlowProgress(SELECTED_GROUP_ORDINAL));
        yield put(removePreferredValue(SELECTED_GROUP_ORDINAL));
    }

    
    const defaultFaceplate = yield call(getDefaultFaceplate, isPlasticDefault, defaultOrdinal);

    
    const defaultValues = fromJS({
        [IS_PLASTIC_FIELD]: isPlasticDefault,
        [FACEPLATE_FIELD]: defaultFaceplate ? defaultFaceplate.get('code') : undefined,
        [SELECTED_GROUP_ORDINAL]: defaultOrdinal
    });
    yield put(setDefaultFieldValues(defaultValues, true));

    yield put({ type: FACEPLATES_LOADED });
}


function* prefetchImages(action) {
    yield action.faceplates.forEach((faceplate) => {
        const image = new Image();
        image.src = faceplate.get('imageURL');
    });

    yield put({ type: FACEPLATE_PREFETCH_END });
}

function* handleRetrievedFaceplates(action) {
    const { faceplateGroups } = action;
    yield put(setFaceplateGroups(faceplateGroups));

    const faceplates = yield call(convertFaceplateGroupsToMap, fromJS(faceplateGroups));
    yield put(faceplatePrefetchImages(faceplates));
}


export function* requestFaceplates(action) {
    const isProgramActive = yield select(programIsActive);
    const params = action.params || {};
    const intlLanguageCode = yield select(getIntlLocale);
    const languageCode = params.languageCode || intlLanguageCode;
    let programCode = null;
    if (isProgramActive) {
        programCode = yield select(getActiveProgramCode);
        programCode = `PROGRAM_${programCode}`;
    }
    yield put(fetchFaceplateGroups({ context: programCode, languageCode }));
}


export function* updateFaceplatesForLanguage(action) {
    const { locale } = action;
    
    yield put(setFaceplateGroups(IList()));
    yield put(requestFaceplateGroups({ languageCode: locale }));
    
    yield take(SET_FACEPLATE_GROUPS);
    yield call(resetSelectedGroupOrdinal);
}


export function* faceplateUpdateWatcher() {
    
    yield take(FACEPLATES_LOADED);

    
    yield takeLatest(IS_PLASTIC_FIELD_CHANGED, resetSelectedGroupOrdinal);
    yield takeLatest(SELECTED_GROUP_ORDINAL_CHANGED, resetDefaultFaceplate);

    
    yield takeLatest(FORM_RESET, handleSetDefaults);
}


export function* faceplateSetDefaultWatcher() {
    
    const actionsToAwait = {
        catalog: take(RETRIEVED_BRAND_CONFIG.CATALOG),
        faceplates: take(SET_FACEPLATE_GROUPS),
        programs: take(PROGRAM_LOADED)
    };

    yield all(actionsToAwait);
    const faceplateCodeAlreadySet = yield select(newItemFaceplate);
    if (!faceplateCodeAlreadySet) {
        yield call(handleSetDefaults);
    } else {
        yield put({ type: FACEPLATES_LOADED });
    }
}

export function* faceplatePrefetchImagesWatcher() {
    yield takeEvery(FACEPLATE_PREFETCH_IMAGES, prefetchImages);
}


export function* faceplatesReceivedWatcher() {
    yield takeEvery(RETRIEVED_FACEPLATE_GROUPS, handleRetrievedFaceplates);
}


export function* requestFaceplatesWatcher() {
    yield takeEvery(REQUEST_FACEPLATE_GROUPS, requestFaceplates);
}


export function* updateFaceplatesForLanguageWatcher() {
    yield takeEvery(SET_LOCALE, updateFaceplatesForLanguage);
    yield takeEvery(INITIALIZE_LOCALE, updateFaceplatesForLanguage);
}
