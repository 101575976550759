import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FaceplatePropType } from '../faceplate/propTypes';

import itemMessages from '../item/itemMessages';

export const FaceplateMissing = () => (
    <div className="faceplate-missing">
        <FormattedMessage {...itemMessages.noImageAvailable} />
    </div>
);

export const FaceplateImg = ({
    faceplate,
    cssClass,
    ...rest
}) => {
    if (!faceplate) return <FaceplateMissing />;

    const { imageURL, altText } = faceplate.toJS();
    return (
        <img
            className={cssClass}
            src={imageURL}
            alt={altText}
            {...rest}
        />
    );
};

FaceplateImg.defaultProps = {
    faceplate: null,
    cssClass: null
};

FaceplateImg.propTypes = {
    faceplate: FaceplatePropType,
    cssClass: PropTypes.string
};


export default FaceplateImg;
