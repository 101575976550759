import creditCardMessages, {
    creditCardExpiryMonthDropdownMap,
    creditCardExpiryMonthDropdownValueMap
} from './creditCardMessages';

import getCurrentFourDigitYear from '../utils/time/getCurrentFourDigitYear';


export const CREDITCARD_NUMBER = 'creditCard';
export const CREDITCARD_EXP_MONTH = 'expMonth';
export const CREDITCARD_EXP_YEAR = 'expYear';
export const CREDITCARD_CODE = 'securityCode';


export const constants = {
    CREDITCARD_NUMBER,
    CREDITCARD_EXP_MONTH,
    CREDITCARD_EXP_YEAR,
    CREDITCARD_CODE
};


export const CREDIT_CARD_PAYMENT_TYPE = 'creditCard';


export const creditCardPaymentDataHandler = formValues => (
    formValues.merge({
        approved: true, 
        paymentMethod: CREDIT_CARD_PAYMENT_TYPE,
        countryCode: formValues.get('countryCode'),
        expMonth: formValues.get(CREDITCARD_EXP_MONTH),
        expYear: formValues.get(CREDITCARD_EXP_YEAR)
    })
);


export const sanitizeCreditCardFields = (values) => {
    const sensitiveFields = [
        CREDITCARD_NUMBER,
        CREDITCARD_EXP_MONTH,
        CREDITCARD_EXP_YEAR,
        CREDITCARD_CODE
    ];
    
    const scrubbedFields = sensitiveFields.map(field => [field, 'X'.repeat(values.get(field).length)]);
    return values.merge(scrubbedFields);
};

export const yearsRange = (intl, lim = 15) => {
    
    const spread = [...Array(lim).keys()];
    const currentYear = parseInt(getCurrentFourDigitYear(), 10);
    const yearList = [{ label: intl.formatMessage(creditCardMessages.expiryYearDropdownEmptyLabel), value: '' }];
    (() => {
        spread.map((num) => {
            const year = num + currentYear;
            return yearList.push({ label: year.toString(), value: year });
        });
    })();
    return yearList;
};

export const theTwelveCurrentMonths = (intl) => {
    const months = Object.keys(creditCardExpiryMonthDropdownMap);
    const placeholder = {
        value: '',
        label: intl.formatMessage(creditCardMessages.expiryMonthDropdownEmptyLabel)
    };
    return [placeholder].concat(months.map(code => ({
        value: creditCardExpiryMonthDropdownValueMap[code],
        label: intl.formatMessage(creditCardExpiryMonthDropdownMap[code])
    })));
};

export const dropDownData = {
    twoDigitYears: yearsRange,
    monthList: theTwelveCurrentMonths
};
