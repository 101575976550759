import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFlow } from './FlowProvider';
import { replace } from './routing';
import { getBasePath } from './flow';


export const FlowStep = ({ component: Component, stepName }) => {
    const { canFirePageLoadSegmentEvent, firePageLoadSegmentEvent, findFirstIncompleteStep } = useFlow();

    
    useEffect(() => {
        firePageLoadSegmentEvent(stepName);
    }, [canFirePageLoadSegmentEvent(stepName)]);

    useEffect(() => {
        
        if (window.RISKX) {
            window.RISKX.go(`/${getBasePath()}/${stepName}`);
        }
        const firstIncompleteStep = findFirstIncompleteStep(stepName);
        if (firstIncompleteStep !== stepName) {
            replace({ path: `/${getBasePath()}/${firstIncompleteStep}` });
        }
    }, []);

    return <Component stepName={stepName} />;
};

FlowStep.displayName = 'FlowStep';
FlowStep.propTypes = {
    component: PropTypes.func.isRequired,
    stepName: PropTypes.string.isRequired
};

export default FlowStep;
