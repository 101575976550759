import { fromJS } from 'immutable';

import designStepConfig from '../../design/designStepConfig';
import uyoInitStepConfig from '../../uyo/init/uyoInitStepConfig';
import uyoSelectStepConfig from '../../uyo/select/uyoSelectStepConfig';
import uyoReviewStepConfig from '../../uyo/review/uyoReviewStepConfig';
import amountStepConfig from '../../amount/amountStepConfig';
import messageStepConfig from '../../message/messageStepConfig';
import deliveryStepConfig from '../../delivery/deliveryStepConfig';
import shippingMethodStepConfig from '../../shippingMethod/shippingMethodStepConfig';
import cartStepConfig from '../../cart/cartStepConfig';
import paymentMethodStepConfig from '../../paymentMethod/paymentMethodStepConfig';
import paymentStepConfig from '../../payment/paymentStepConfig';
import masterpassReviewStepConfig from '../../masterpass/masterpassReviewStepConfig';
import paymentConfirmationStepConfig from '../../payment/paymentConfirmationStepConfig';
import aboutStepConfig from '../../about/aboutStepConfig';
import soldOutStepConfig from '../../soldOut/soldOutStepConfig';
import errorNoRetryStepConfig from '../../error/errorNoRetryStepConfig';
import errorRefreshStepConfig from '../../error/errorRefreshStepConfig';


















const stepConfigs = fromJS({
    design: designStepConfig,
    'uyo-init': uyoInitStepConfig,
    'uyo-select': uyoSelectStepConfig,
    'uyo-review': uyoReviewStepConfig,
    amount: amountStepConfig,
    message: messageStepConfig,
    delivery: deliveryStepConfig,
    shipping: shippingMethodStepConfig,
    cart: cartStepConfig,
    'pay-method': paymentMethodStepConfig,
    payment: paymentStepConfig,
    'masterpass-review': masterpassReviewStepConfig,
    confirmation: paymentConfirmationStepConfig,
    'about/:docType': aboutStepConfig,
    'self_service/v2/about/:docType': aboutStepConfig,
    'sold-out': soldOutStepConfig,
    'error-no-retry': errorNoRetryStepConfig,
    'error-refresh': errorRefreshStepConfig
});

export default stepConfigs;
