import { defineMessages } from 'react-intl';


const japanPrefectureMap = defineMessages({
    HOKKAIDO: {
        id: 'address.japan.japanPrefectureMap.Hokkaido.HOKKAIDO',
        description: 'Prefecture label for Hokkaido',
        defaultMessage: 'Hokkaido'
    },
    AOMORI: {
        id: 'address.japan.japanPrefectureMap.Aomori.AOMORI',
        description: 'Prefecture label for Aomori',
        defaultMessage: 'Aomori'
    },
    AKITA: {
        id: 'address.japan.japanPrefectureMap.Akita.AKITA',
        description: 'Prefecture label for Akita',
        defaultMessage: 'Akita'
    },
    YAMAGATA: {
        id: 'address.japan.japanPrefectureMap.Yamagata.YAMAGATA',
        description: 'Prefecture label for Yamagata',
        defaultMessage: 'Yamagata'
    },
    NIIGATA: {
        id: 'address.japan.japanPrefectureMap.Niigata.NIIGATA',
        description: 'Prefecture label for Niigata',
        defaultMessage: 'Niigata'
    },
    IWATE: {
        id: 'address.japan.japanPrefectureMap.Iwate.IWATE',
        description: 'Prefecture label for Iwate',
        defaultMessage: 'Iwate'
    },
    MIYAGI: {
        id: 'address.japan.japanPrefectureMap.Miyagi.MIYAGI',
        description: 'Prefecture label for Miyagi',
        defaultMessage: 'Miyagi'
    },
    FUKUSHIMA: {
        id: 'address.japan.japanPrefectureMap.Fukushima.FUKUSHIMA',
        description: 'Prefecture label for Fukushima',
        defaultMessage: 'Fukushima'
    },
    GUMMA: {
        id: 'address.japan.japanPrefectureMap.Gumma.GUMMA',
        description: 'Prefecture label for Gumma',
        defaultMessage: 'Gumma'
    },
    TOCHIGI: {
        id: 'address.japan.japanPrefectureMap.Tochigi.TOCHIGI',
        description: 'Prefecture label for Tochigi',
        defaultMessage: 'Tochigi'
    },
    SAITAMA: {
        id: 'address.japan.japanPrefectureMap.Saitama.SAITAMA',
        description: 'Prefecture label for Saitama',
        defaultMessage: 'Saitama'
    },
    IBARAKI: {
        id: 'address.japan.japanPrefectureMap.Ibaraki.IBARAKI',
        description: 'Prefecture label for Ibaraki',
        defaultMessage: 'Ibaraki'
    },
    CHIBA: {
        id: 'address.japan.japanPrefectureMap.Chiba.CHIBA',
        description: 'Prefecture label for Chiba',
        defaultMessage: 'Chiba'
    },
    TOKYO: {
        id: 'address.japan.japanPrefectureMap.Tokyo.TOKYO',
        description: 'Prefecture label for Tokyo',
        defaultMessage: 'Tokyo'
    },
    KANAGAWA: {
        id: 'address.japan.japanPrefectureMap.Kanagawa.KANAGAWA',
        description: 'Prefecture label for Kanagawa',
        defaultMessage: 'Kanagawa'
    },
    GIFU: {
        id: 'address.japan.japanPrefectureMap.Gifu.GIFU',
        description: 'Prefecture label for Gifu',
        defaultMessage: 'Gifu'
    },
    NAGANO: {
        id: 'address.japan.japanPrefectureMap.Nagano.NAGANO',
        description: 'Prefecture label for Nagano',
        defaultMessage: 'Nagano'
    },
    YAMANASHI: {
        id: 'address.japan.japanPrefectureMap.Yamanashi.YAMANASHI',
        description: 'Prefecture label for Yamanashi',
        defaultMessage: 'Yamanashi'
    },
    SHIZUOKA: {
        id: 'address.japan.japanPrefectureMap.Shizuoka.SHIZUOKA',
        description: 'Prefecture label for Shizuoka',
        defaultMessage: 'Shizuoka'
    },
    AICHI: {
        id: 'address.japan.japanPrefectureMap.Aichi.AICHI',
        description: 'Prefecture label for Aichi',
        defaultMessage: 'Aichi'
    },
    MIE: {
        id: 'address.japan.japanPrefectureMap.Mie.MIE',
        description: 'Prefecture label for Mie',
        defaultMessage: 'Mie'
    },
    TOYAMA: {
        id: 'address.japan.japanPrefectureMap.Toyama.TOYAMA',
        description: 'Prefecture label for Toyama',
        defaultMessage: 'Toyama'
    },
    ISHIKAWA: {
        id: 'address.japan.japanPrefectureMap.Ishikawa.ISHIKAWA',
        description: 'Prefecture label for Ishikawa',
        defaultMessage: 'Ishikawa'
    },
    FUKUI: {
        id: 'address.japan.japanPrefectureMap.Fukui.FUKUI',
        description: 'Prefecture label for Fukui',
        defaultMessage: 'Fukui'
    },
    HYOGO: {
        id: 'address.japan.japanPrefectureMap.Hyogo.HYOGO',
        description: 'Prefecture label for Hyogo',
        defaultMessage: 'Hyogo'
    },
    KYOTO: {
        id: 'address.japan.japanPrefectureMap.Kyoto.KYOTO',
        description: 'Prefecture label for Kyoto',
        defaultMessage: 'Kyoto'
    },
    SHIGA: {
        id: 'address.japan.japanPrefectureMap.Shiga.SHIGA',
        description: 'Prefecture label for Shiga',
        defaultMessage: 'Shiga'
    },
    OSAKA: {
        id: 'address.japan.japanPrefectureMap.Osaka.OSAKA',
        description: 'Prefecture label for Osaka',
        defaultMessage: 'Osaka'
    },
    NARA: {
        id: 'address.japan.japanPrefectureMap.Nara.NARA',
        description: 'Prefecture label for Nara',
        defaultMessage: 'Nara'
    },
    WAKAYAMA: {
        id: 'address.japan.japanPrefectureMap.Wakayama.WAKAYAMA',
        description: 'Prefecture label for Wakayama',
        defaultMessage: 'Wakayama'
    },
    TOTTORI: {
        id: 'address.japan.japanPrefectureMap.Tottori.TOTTORI',
        description: 'Prefecture label for Tottori',
        defaultMessage: 'Tottori'
    },
    OKAYAMA: {
        id: 'address.japan.japanPrefectureMap.Okayama.OKAYAMA',
        description: 'Prefecture label for Okayama',
        defaultMessage: 'Okayama'
    },
    HIROSHIMA: {
        id: 'address.japan.japanPrefectureMap.Hiroshima.HIROSHIMA',
        description: 'Prefecture label for Hiroshima',
        defaultMessage: 'Hiroshima'
    },
    SHIMANE: {
        id: 'address.japan.japanPrefectureMap.Shimane.SHIMANE',
        description: 'Prefecture label for Shimane',
        defaultMessage: 'Shimane'
    },
    YAMAGUCHI: {
        id: 'address.japan.japanPrefectureMap.Yamaguchi.YAMAGUCHI',
        description: 'Prefecture label for Yamaguchi',
        defaultMessage: 'Yamaguchi'
    },
    KAGAWA: {
        id: 'address.japan.japanPrefectureMap.Kagawa.KAGAWA',
        description: 'Prefecture label for Kagawa',
        defaultMessage: 'Kagawa'
    },
    TOKUSHIMA: {
        id: 'address.japan.japanPrefectureMap.Tokushima.TOKUSHIMA',
        description: 'Prefecture label for Tokushima',
        defaultMessage: 'Tokushima'
    },
    EHIME: {
        id: 'address.japan.japanPrefectureMap.Ehime.EHIME',
        description: 'Prefecture label for Ehime',
        defaultMessage: 'Ehime'
    },
    KOCHI: {
        id: 'address.japan.japanPrefectureMap.Kochi.KOCHI',
        description: 'Prefecture label for Kochi',
        defaultMessage: 'Kochi'
    },
    FUKUOKA: {
        id: 'address.japan.japanPrefectureMap.Fukuoka.FUKUOKA',
        description: 'Prefecture label for Fukuoka',
        defaultMessage: 'Fukuoka'
    },
    SAGA: {
        id: 'address.japan.japanPrefectureMap.Saga.SAGA',
        description: 'Prefecture label for Saga',
        defaultMessage: 'Saga'
    },
    NAGASAKI: {
        id: 'address.japan.japanPrefectureMap.Nagasaki.NAGASAKI',
        description: 'Prefecture label for Nagasaki',
        defaultMessage: 'Nagasaki'
    },
    KUMAMOTO: {
        id: 'address.japan.japanPrefectureMap.Kumamoto.KUMAMOTO',
        description: 'Prefecture label for Kumamoto',
        defaultMessage: 'Kumamoto'
    },
    OITA: {
        id: 'address.japan.japanPrefectureMap.Oita.OITA',
        description: 'Prefecture label for Oita',
        defaultMessage: 'Oita'
    },
    MIYAZAKI: {
        id: 'address.japan.japanPrefectureMap.Miyazaki.MIYAZAKI',
        description: 'Prefecture label for Miyazaki',
        defaultMessage: 'Miyazaki'
    },
    KAGOSHIMA: {
        id: 'address.japan.japanPrefectureMap.Kagoshima.KAGOSHIMA',
        description: 'Prefecture label for Kagoshima',
        defaultMessage: 'Kagoshima'
    },
    OKINAWA: {
        id: 'address.japan.japanPrefectureMap.Okinawa.OKINAWA',
        description: 'Prefecture label for Okinawa',
        defaultMessage: 'Okinawa'
    }
});

export default japanPrefectureMap;
