import { defineMessages } from 'react-intl';


const netherlandsProvinceMap = defineMessages({
    DR: {
        id: 'address.netherlands.netherlandsProvinceMap.Drenthe.DR',
        description: 'Province label for Drenthe',
        defaultMessage: 'Drenthe'
    },
    FL: {
        id: 'address.netherlands.netherlandsProvinceMap.Flevoland.FL',
        description: 'Province label for Flevoland',
        defaultMessage: 'Flevoland'
    },
    FR: {
        id: 'address.netherlands.netherlandsProvinceMap.Fryslân.FR',
        description: 'Province label for Fryslân',
        defaultMessage: 'Fryslân'
    },
    GE: {
        id: 'address.netherlands.netherlandsProvinceMap.Gelderland.GE',
        description: 'Province label for Gelderland',
        defaultMessage: 'Gelderland'
    },
    GR: {
        id: 'address.netherlands.netherlandsProvinceMap.Groningen.GR',
        description: 'Province label for Groningen',
        defaultMessage: 'Groningen'
    },
    LI: {
        id: 'address.netherlands.netherlandsProvinceMap.Limburg.LI',
        description: 'Province label for Limburg',
        defaultMessage: 'Limburg'
    },
    NB: {
        id: 'address.netherlands.netherlandsProvinceMap.Noord-Brabant.NB',
        description: 'Province label for Noord-Brabant',
        defaultMessage: 'Noord-Brabant'
    },
    NH: {
        id: 'address.netherlands.netherlandsProvinceMap.Noord-Holland.NH',
        description: 'Province label for Noord-Holland',
        defaultMessage: 'Noord-Holland'
    },
    OV: {
        id: 'address.netherlands.netherlandsProvinceMap.Overijssel.OV',
        description: 'Province label for Overijssel',
        defaultMessage: 'Overijssel'
    },
    UT: {
        id: 'address.netherlands.netherlandsProvinceMap.Utrecht.UT',
        description: 'Province label for Utrecht',
        defaultMessage: 'Utrecht'
    },
    ZE: {
        id: 'address.netherlands.netherlandsProvinceMap.Zeeland.ZE',
        description: 'Province label for Zeeland',
        defaultMessage: 'Zeeland'
    },
    ZH: {
        id: 'address.netherlands.netherlandsProvinceMap.Zuid-Holland.ZH',
        description: 'Province label for Zuid-Holland',
        defaultMessage: 'Zuid-Holland'
    }
});

export default netherlandsProvinceMap;
