import { call, put, takeEvery } from 'redux-saga/effects';
import { completeLocaleChange, futureLocaleChange, setFutureSendLoading } from './futureSendModule';
import { INITIALIZE_LOCALE, SET_LOCALE } from '../intl/intlModule';


const importMoment = async () => import('moment-timezone');


const importMomentUtils = async () => import('../utils/time/momentUtils');



export function* updateCalendarLocale({ locale }) {
    const moment = (yield call(importMoment)).default;
    const { momentLocaleImport } = yield call(importMomentUtils);
    yield put(setFutureSendLoading(true));
    yield call(momentLocaleImport, locale);
    yield call(moment.locale, locale);
    
    
    yield put(futureLocaleChange(locale));
    yield put(setFutureSendLoading(false));
    yield put(completeLocaleChange());
}


export function* futureSendSagasWatcher() {
    
    yield takeEvery(SET_LOCALE, updateCalendarLocale);
    
    yield takeEvery(INITIALIZE_LOCALE, updateCalendarLocale);
}
