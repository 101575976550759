import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { Helmet } from 'react-helmet';
import { getBrandName } from '../brand/brandSelectors';


export const TranslatedPageTitle = ({
    pageTitle,
    intl,
    brandName
}) => {
    if (!pageTitle || !pageTitle.id) {
        return null;
    }
    return (
        <Helmet>
            <title>{intl.formatMessage(pageTitle, { brandName })}</title>
        </Helmet>
    );
};

TranslatedPageTitle.propTypes = {
    pageTitle: PropTypes.object.isRequired,
    intl: intlShape.isRequired,
    brandName: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    brandName: getBrandName(state)
});

export default connect(mapStateToProps)(injectIntl(TranslatedPageTitle));
