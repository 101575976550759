import { Map as IMap } from 'immutable';
import { logError } from '../utils/errorUtils';

const currencyCodeSymbolMapping = IMap({
    'ja-jp': { JPY: ['', '円'] }
});


export const getStrippedNumber = (formattedNumber) => {
    
    const regex = /([^\d,.])/g;
    return formattedNumber.toString().replace(regex, '');
};

const getSymbolOverrideByLocale = (intl, currencyCode) => {
    const currencyCodeSymbol = currencyCodeSymbolMapping.get(intl.locale, {})[currencyCode];
    if (currencyCodeSymbol) {
        const [prefix, suffix] = currencyCodeSymbol;
        return { prefix, suffix };
    }
    return null;
};





const getFormatter = (intl, currencyCode) => {
    if (intl.locale === 'fr-fr' && currencyCode === 'GBP') {
        return new Intl.NumberFormat(intl.locale, { style: 'currency', currency: currencyCode, currencyDisplay: 'narrowSymbol' });
    }
    return new Intl.NumberFormat(intl.locale, { style: 'currency', currency: currencyCode });
};

export const getCurrencySymbol = (intl, currencyCode) => {
    let prefix = '';
    let suffix = '';
    try {
        const formatter = getFormatter(intl, currencyCode);
        let currencyArray = formatter.formatToParts(1);
        
        currencyArray = currencyArray.filter(parts => !(parts.type === 'literal'
            && ((parts.value === '' || parts.value.codePointAt() === 8207) || parts.value.codePointAt() === 8206)));
        const pos = currencyArray.map(val => val.type).indexOf('currency');
        if (pos === currencyArray.length - 1) {
            suffix = currencyArray[pos].value;
        } else {
            prefix = currencyArray[pos].value;
        }
    } catch (error) {
        
        
        const regex = new RegExp('^([^\\d]*)?1([^\\d]*)?$');
        const currencyString = intl.formatNumber(1, {
            format: 'currencyInteger',
            currencyCode
        });

        const match = regex.exec(currencyString);
        if ((match === null) || (match.length < 3)) {
            logError(`Unable to find a currency code for locale="${intl.locale}", currency="${currencyCode}"`);
            return { prefix: '', suffix: '' };
        }
        prefix = match[1] ? match[1] : '';
        suffix = match[2] ? match[2] : '';
    }

    const symbolOverrideByLocale = getSymbolOverrideByLocale(intl, currencyCode);
    return symbolOverrideByLocale || { prefix, suffix };
};
