import { List as IList } from 'immutable';

import { createSelector } from 'reselect';
import { programStepCompleted } from '../program/programStepSelectors';
import { getFlowProgress, getPreferredCardType } from '../routing/flowSelectors';
import { IS_PLASTIC_FIELD } from '../item/newItemForm';


export const cardTypeStepCompleted = createSelector(
    getFlowProgress,
    flowState => flowState.has(IS_PLASTIC_FIELD)
);


export const cardTypeStepPreConditions = IList([
    programStepCompleted
]);


export const cardTypeStepPostConditions = IList([
    cardTypeStepCompleted
]);

export const cardTypeStepSkippable = createSelector(
    getPreferredCardType,
    preferredCardType => (
        preferredCardType !== null
    )
);
