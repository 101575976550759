import { Map as IMap } from 'immutable';


export const CALENDAR_LOCALE = 'calendarLocale';
export const IS_LOADING = 'loading';

export const FUTURE_LOCALE_CHANGE = '@@storefront/FUTURE_LOCALE_CHANGE';
export const FUTURE_LOCALE_CHANGE_COMPLETE = '@@storefront/FUTURE_LOCALE_CHANGE_COMPLETE';
export const FUTURE_SET_LOADING = '@@storefront/FUTURE_SET_LOADING';

export const initialState = IMap({
    [CALENDAR_LOCALE]: 'en-us',
    [IS_LOADING]: false
});

export default function futureSendReducer(state = initialState, action) {
    switch (action.type) {
        case FUTURE_LOCALE_CHANGE:
            return state.set(CALENDAR_LOCALE, action.locale);
        case FUTURE_SET_LOADING:
            return state.set(IS_LOADING, action.loading);
        default:
            return state;
    }
}


export const futureLocaleChange = locale => ({
    type: FUTURE_LOCALE_CHANGE,
    locale
});


export const completeLocaleChange = () => ({
    type: FUTURE_LOCALE_CHANGE_COMPLETE
});


export const setFutureSendLoading = loading => ({
    type: FUTURE_SET_LOADING,
    loading
});
