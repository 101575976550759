
export const hourOptions12 = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' }
];

export const minuteOptions = [
    { value: 0, label: '00' },
    { value: 15, label: '15' },
    { value: 30, label: '30' },
    { value: 45, label: '45' }
];

export const meridiemOptions = [
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' }
];

export const meridiemOptionsJP = [
    { value: 'AM', label: '午前' },
    { value: 'PM', label: '午後' }
];

export const getMinuteValueList = () => (
    minuteOptions.map(obj => obj.value)
);

export default {
    hourOptions12,
    minuteOptions,
    meridiemOptionsJP,
    meridiemOptions,
    getMinuteValueList
};
