import { fromJS } from 'immutable';

import isEmptyRule from '../validate/isEmpty';
import { isValidEmailRule } from '../validate/email';

import { SENDER_EMAIL_FIELD } from '../payment/paymentConstants';
import senderValidationMessages from './senderValidationMessages';


const senderConstraints = fromJS({
    [SENDER_EMAIL_FIELD]: [
        isEmptyRule(senderValidationMessages.senderEmailRequired),
        isValidEmailRule(senderValidationMessages.senderEmailInvalid)
    ]
});

export default senderConstraints;
