import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { SubHeader } from 'cstar-react-primitives/lib/redux-form/layout/Container';

export const futureSendSubHeader = defineMessages({
    subHeader: {
        id: 'futureSend.futureSendSubHeader.subHeader',
        description: 'Title heading of the delivery date and time section of the Delivery Details step',
        defaultMessage: 'When should it be sent?'
    }
});

export const FutureSendSubHeader = ({ intl, subHeader }) => (
    <SubHeader heading={intl.formatMessage(subHeader)} HeadingElement="h2" />
);

FutureSendSubHeader.defaultProps = {
    subHeader: futureSendSubHeader.subHeader
};

FutureSendSubHeader.propTypes = {
    
    intl: intlShape.isRequired,
    subHeader: PropTypes.object
};

export default injectIntl(FutureSendSubHeader);
