import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, intlShape } from 'react-intl';
import { Map as IMap } from 'immutable';
import { Field } from 'redux-form/immutable';
import { icons } from 'cstar-react-primitives/lib/redux-form/icons/iconConst';
import { InputGroup } from 'cstar-react-primitives/lib/redux-form/InputGroup';
import { FieldIconInset } from 'cstar-react-primitives/lib/redux-form/microFields/FieldIconsDisplay';

import { giftCardFieldNames } from '../../item/newItemForm';
import { getTimeFormat } from '../../intl/intlModule';


export const timeFieldMessages = defineMessages({
    timeFieldLabel: {
        id: 'field.timeFieldMessages.timeField.label',
        description: 'label for the field that displays the delivery time of day',
        defaultMessage: 'Delivery Time (optional)'
    }
});

export const ClockIcon = <FieldIconInset icon={icons.CLOCK} />;


export const TimeInputField = ({
    intl,
    cashbotName,
    fieldLabel,
    timeZone,
    onFocus,
    doFocus
}) => {
    const toLocalizedTimeString = dateTime => intl.formatTime(dateTime, getTimeFormat(timeZone.get('zoneName')));

    const timeProps = {
        atts: {
            readOnly: true,
            maxLength: 10,
            type: 'text',
            onBlur: null 
        },
        isRequiredField: false,
        hasMaxLengthCounter: false,
        wrappedElement: ClockIcon,
        label: intl.formatMessage(fieldLabel),
        component: InputGroup,
        name: giftCardFieldNames.RECIPIENT_SEND_TIME_FIELD,
        format: toLocalizedTimeString,
        cashbotName,
        onFocus,
        doFocus
    };

    React.useEffect(() => {
        
        document.getElementById('deliveryTime-input').removeAttribute('aria-labelledby');
    }, []);

    return <Field {...timeProps} />;
};

TimeInputField.defaultProps = {
    doFocus: false,
    fieldLabel: timeFieldMessages.timeFieldLabel
};

TimeInputField.propTypes = {
    doFocus: PropTypes.bool,
    cashbotName: PropTypes.string.isRequired,
    fieldLabel: PropTypes.object,
    timeZone: PropTypes.instanceOf(IMap).isRequired,
    intl: intlShape.isRequired,
    onFocus: PropTypes.func.isRequired
};

export default TimeInputField;
