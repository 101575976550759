import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { OverlayBanner } from 'cstar-react-primitives/lib/redux-form/layout/OverlayBanner';

import { getGdprEnabled } from '../brand/brandSelectors';

import { getCookieConsentGiven } from './consentSelectors';
import { setCookieConsentGiven } from './consentModule';
import consentMessages from './consentMessages';
import { ABOUT_PATHS } from '../routing/flow';

export const OldGDPRConsentBanner = ({
    intl,
    gdprEnabled,
    consentGiven,
    clickHandler
}) => {
    if (!gdprEnabled || consentGiven) {
        return null;
    }

    const bannerProps = {
        message: intl.formatMessage(consentMessages.cookieConsentMessage, { url: ABOUT_PATHS.PRIVACY_POLICY }),
        buttonText: intl.formatMessage(consentMessages.cookieConsentButtonText),
        cashbotName: 'cookie-consent-agree-button',
        onClick: clickHandler,
        openLinksInNewTab: true
    };
    return (
        <div>
            <OverlayBanner {...bannerProps} />
        </div>
    );
};

OldGDPRConsentBanner.defaultProps = {
    gdprEnabled: false,
    consentGiven: false
};

OldGDPRConsentBanner.propTypes = {
    intl: intlShape.isRequired,
    gdprEnabled: PropTypes.bool,
    consentGiven: PropTypes.bool,
    clickHandler: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    gdprEnabled: getGdprEnabled(state),
    consentGiven: getCookieConsentGiven(state)
});

const mapDispatchToProps = {
    clickHandler: () => setCookieConsentGiven(true)
};

export const ConnectedOldGDPRConsentBanner = React.memo(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(OldGDPRConsentBanner))
);
