import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { Transition, transitionConfigs } from 'cstar-react-primitives/lib/redux-form/animate/Transition';

import NotificationItem from './NotificationItem';


export const NotificationPortal = (props) => {
    const { notifications } = props;
    const containerProps = {
        className: 'notification-container',
        'aria-live': notifications.size ? 'polite' : 'off'
    };
    const childNotifications = notifications.map(notification => (
        <NotificationItem
            key={notification.get('uid')}
            notification={notification}
            type={notification.get('type')}
        />
    ));
    return (
        <Portal isOpened>
            <div {...containerProps}>
                <Transition config={transitionConfigs.notifyConfig}>
                    { (notifications.size !== 0) && childNotifications }
                </Transition>
            </div>
        </Portal>
    );
};

NotificationPortal.defaultProps = {
    notifications: new List()
};

NotificationPortal.propTypes = {
    notifications: PropTypes.instanceOf(List)
};

export const mapStateToProps = state => ({
    notifications: state.get('notifications')
});

export default connect(mapStateToProps)(NotificationPortal);
