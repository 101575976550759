import { defineMessages } from 'react-intl';

const currencyMessages = defineMessages({
    selectLabel: {
        id: 'currency.currencyMessages.selectLabel',
        description: 'label for currency select',
        defaultMessage: 'Choose a currency'
    },
    modalHeader: {
        id: 'currency.modal.modalHeader',
        description: 'Header text for the currency modal',
        defaultMessage: 'Currency'
    },
    modalContentLabel: {
        id: 'currency.modal.modalContentLabel',
        description: 'Currency already in cart - aria-label html attr - DO NOT CUSTOMIZE',
        defaultMessage: `You have a card in your cart. If you'd like to purchase a card in another currency, `
        + `please do so in a separate transaction.`,
        meta: {
            editable: false
        }
    },
    modalContent: {
        id: 'currency.modal.modalContent',
        description: 'Currency already in cart',
        defaultMessage: `You have a {currencyName} ({currencyCode}) card in your cart. If you'd like to purchase `
        + `a card in another currency, please do so in a separate transaction.`
    }
});

export default currencyMessages;
