import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export const FaceplatePropType = ImmutablePropTypes.contains({
    code: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
    imageURL: PropTypes.string.isRequired
});

export default { FaceplatePropType };
