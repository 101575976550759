import WebFont from 'webfontloader';
import axios from 'axios';

import { getBrandCode } from '../brand/brandSelectors';
import { getStaticMediaURL } from '../app/bootstrap';
import { logAxiosError } from '../utils/errorUtils';


export const checkResponseFormat = response => (
    typeof response === 'object' && Object.keys(response).indexOf('data') !== -1
);

export const getWebfonts = async (store) => {
    const brandCode = getBrandCode(store.getState());
    const configURL = getStaticMediaURL(`/webfont/${brandCode}.json`);
    let response;

    try {
        response = await axios.get(configURL);
    } catch (error) {
        if (!error.response || error.response.status !== 404) {
            logAxiosError(error);
        }
        return;
    }

    if (checkResponseFormat(response)) {
        const responseJson = response.data;
        
        
        if (Object.keys(responseJson).length) {
            
            if (responseJson.custom && responseJson.custom.urls) {
                responseJson.custom.urls = responseJson.custom.urls.map((val) => {
                    const staticMediaURL = getStaticMediaURL();
                    return val.replace('<STATIC_MEDIA_URL>', staticMediaURL);
                });
            }

            WebFont.load(responseJson);
        }
    }
};

export default getWebfonts;
