import { Map as IMap } from 'immutable';

export const START_PAYPAL_LOAD = '@@storefront/START_PAYPAL_LOAD';

export const PAYPAL_LOADED = '@@storefront/PAYPAL_LOADED';
export const PAYPAL_AUTH_FAILED = '@@storefront/PAYPAL_AUTH_FAILED';
export const SET_PAYPAL_HANDLING_TOTAL = '@@storefront/SET_PAYPAL_HANDLING_TOTAL';

const initialState = IMap({ paypalLoaded: false });

export const paypalReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case PAYPAL_LOADED: {
            return state.set('paypalLoaded', true);
        }
        case PAYPAL_AUTH_FAILED: {
            return state.set('paypalAuthFailed', true);
        }
        case SET_PAYPAL_HANDLING_TOTAL: {
            return state.set('paypalHandlingTotal', action.total);
        }
        default:
    }
    return state;
};

export const startPaypalLoad = () => ({
    type: START_PAYPAL_LOAD
});


export const paypalLoaded = () => ({
    type: PAYPAL_LOADED
});


export const paypalAuthFailed = () => ({
    type: PAYPAL_AUTH_FAILED
});


export const getPaypalLoaded = state => state.getIn(['paypal', 'paypalLoaded']);


export const getPaypalAuthFailed = state => state.getIn(['paypal', 'paypalAuthFailed']);


export const loadPaypal = (paypalId, localeCode) => new Promise((resolve, reject) => {
    const script = document.createElement('script');
    
    script.setAttribute('src', `https://www.paypal.com/sdk/js?client-id=${paypalId}&locale=${localeCode}&currency=USD&commit=true&vault=true&intent=authorize&disable-funding=credit,card&integration-date=2020-05-19`);
    
    script.onreadystatechange = script.onload = () => {
        resolve(window.paypal);
    };
    script.onerror = reject;
    document.getElementsByTagName('head')[0].appendChild(script);
});


export const importPayPalLocaleMap = async () => import('./data/paypalLocaleMap');

export const setPaypalHandlingTotal = total => ({
    type: SET_PAYPAL_HANDLING_TOTAL,
    total
});
