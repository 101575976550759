import { createSelector } from 'reselect';
import { Map as IMap } from 'immutable';

import { messages } from '../intl/intlSelectors';

import { LOYALTY_HELP_MESSAGE_ID } from './loyaltyModule';



export const getLoyaltyHelpText = createSelector(
    messages,
    msgs => msgs.get(LOYALTY_HELP_MESSAGE_ID, null)
);

export const getLoyalty = state => state.get('loyalty', IMap());

export const getLoyaltyNumber = createSelector(
    getLoyalty,
    loyalty => loyalty.get('loyaltyNumber', '')
);

export const getShowLoyaltyInputOnPaymentStep = createSelector(
    getLoyalty,
    loyalty => loyalty.get('showLoyaltyInputOnPaymentStep', true)
);


export const getLoyaltyImageSrc = createSelector(
    messages,
    msgs => msgs.get('loyalty.loyaltyMessage.logoSrc', '')
);
