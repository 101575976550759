import { List as IList } from 'immutable';

import { programStepCompleted } from '../../program/programStepSelectors';
import { cardTypeStepCompleted } from '../../cardType/cardTypeStepSelectors';
import { getHasAgreedToTerms } from '../uyoSelectors';



export const uyoInitStepCompleted = getHasAgreedToTerms;


export const uyoInitStepPreConditions = IList([
    programStepCompleted,
    cardTypeStepCompleted
]);


export const uyoInitStepPostConditions = IList([
    uyoInitStepCompleted
]);
