import get24HourTime from '../utils/time/get24HourTime';
import { convertTimeZone, FORMATS } from '../utils/time/momentUtils';


export const parseSendDateTime = (year, month, day, hour, minute, meridiem, timeZone) => {
    
    
    
    const dateString = `${year}-${month + 1}-${day}`;
    const timeString = `${get24HourTime(hour, meridiem)}:${minute}:00`;
    const timezoneUnawareDateTime = `${dateString} ${timeString}`;

    
    return new Date(convertTimeZone(timezoneUnawareDateTime, timeZone));
};

export const parseMonthFormat = intl => (intl.locale === 'ja-jp' ? FORMATS.YEAR_MONTH : FORMATS.MONTH_YEAR);
