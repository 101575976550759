import React from 'react';
import { Alert } from 'cstar-react-primitives/lib/redux-form/alert/Alert';
import { Container } from 'cstar-react-primitives/lib/redux-form/layout/Container';

import errorMessages from './errorMessages';
import TranslatedPageTitle from '../header/TranslatedPageTitle';


export const NotFound = () => (
    <Container hasSlimPadding>
        <TranslatedPageTitle pageTitle={errorMessages.notFoundTitle} />
        <Alert
            alertType="warning"
            hasHeading={false}
            message={errorMessages.notFoundTitle}
        />
    </Container>
);

export default NotFound;
