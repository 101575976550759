import {
    takeLatest, put, select, call, take
} from 'redux-saga/effects';
import { requestShippingMethods } from 'capi';

import { logError } from '../utils/errorUtils';
import { QUANTITY_FIELD, CURRENCY_FIELD, SHIPPING_METHOD_FIELD } from '../item/newItemForm';
import { changeValue } from '../item/newItemFormModule';
import { getAddressHash } from '../address/addressModule';
import { applyActivePromoPreCart, RECEIVED_PROMO_REWARDS } from '../promo/promoModule';
import { getCapiClient } from '../session/sessionSelectors';

import {
    hasProfile,
    getShippingMethodProfileId,
    getProfileLowestCostMethod,
    getRewardShippingMethods
} from './shippingSelectors';


import {
    createProfileActive,
    createProfileSuccess,
    createProfileFailed,
    storeProfileMethods,
    profileAlreadyExists,
    GET_SHIPPING_METHOD
} from './shippingModule';



export function* setDefaultShippingMethodToLowestCostOrPromo(profile) {
    const defaultMethod = yield select(getProfileLowestCostMethod, profile);
    let defaultMethodToken = defaultMethod.get('token');
    const defaultMethodCost = parseFloat(defaultMethod.get('cost'));

    const promoMethods = yield select(getRewardShippingMethods, profile);
    const promoMethodsToJS = promoMethods.toJS();

    
    Object.keys(promoMethodsToJS).forEach((promoMethodToken) => {
        const currUpdatedCost = parseFloat(promoMethodsToJS[promoMethodToken].updatedCost);
        
        
        if (currUpdatedCost <= defaultMethodCost) {
            defaultMethodToken = promoMethodToken;
        }
    });

    yield put(changeValue(SHIPPING_METHOD_FIELD, defaultMethodToken));
}


export function* createProfileMethods(profile, requestData, isNewItem) {
    yield put(createProfileActive(profile));

    const capiClient = yield select(getCapiClient);
    try {
        const methods = yield call(requestShippingMethods, capiClient, requestData.toJS());
        yield put(storeProfileMethods(profile, methods));

        if (isNewItem) {
            yield call(setDefaultShippingMethodToLowestCostOrPromo, profile);
        }

        yield put(createProfileSuccess(profile, methods));
    } catch (error) {
        logError(error);
        yield put(createProfileFailed(profile, error.response.status, error.message));
    }
}


export function* getShippingMethodSaga({ shippingAddress, isNewItem }) {
    yield put(applyActivePromoPreCart(true, true, true));
    
    yield take(RECEIVED_PROMO_REWARDS);

    let addressHash = shippingAddress.get('addressID', null);
    if (!addressHash) {
        addressHash = yield call(getAddressHash, shippingAddress);
    }

    const profile = getShippingMethodProfileId(
        addressHash,
        shippingAddress.get(QUANTITY_FIELD),
        shippingAddress.get(CURRENCY_FIELD),
        shippingAddress.get('hasAccessory')
    );

    const methodsExist = yield select(hasProfile, profile);
    if (methodsExist) {
        yield put(profileAlreadyExists(profile));

        if (isNewItem) {
            yield call(setDefaultShippingMethodToLowestCostOrPromo, profile);
        }

        return;
    }

    yield call(createProfileMethods, profile, shippingAddress, isNewItem);
}

export function* watchGetShippingMethod() {
    yield takeLatest(GET_SHIPPING_METHOD, getShippingMethodSaga);
}
