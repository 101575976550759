import { defineMessages } from 'react-intl';

const notificationMessages = defineMessages({
    ariaLabelDismiss: {
        id: 'notifications.notificationMessages.dismissAction',
        description: 'aria-label for dismissing an application notification - not user visible',
        defaultMessage: 'dismiss notification'
    },
    undoRemoveLinkText: {
        id: 'notifications.notificationMessages.undoRemoveLinkText',
        description: 'Visible link text in an application notification to undo the removal of an item from the cart',
        defaultMessage: 'Undo'
    },
    
    undoRemoveContent: {
        id: 'notifications.notificationMessages.undoRemoveContent',
        description: 'Message shown in an application notification when removing an item from the cart',
        defaultMessage: 'You removed {quantity} {denomination} Gift {quantity, plural, one {Card} other {Cards}} for {recipientName}'
    },
    undoRemoveContentSelfBuy: {
        id: 'notifications.notificationMessages.undoRemoveContentSelfBuy',
        description: 'Message shown in an application notification when removing an item from the cart which the purchaser is buying for themselves',
        defaultMessage: 'You removed {quantity} {denomination} Gift {quantity, plural, one {Card} other {Cards}} for yourself'
    },
    undoRemoveContentBundleSelfBuy: {
        id: 'notifications.notificationMessages.undoRemoveContentBundleSelfBuy',
        description: 'Message shown in an application notification when removing a bundle item from the cart which the purchaser is buying for themselves',
        defaultMessage: 'You removed {quantity} {denomination} {programLabel} {quantity, plural, one {Bundle} other {Bundles}} for yourself'
    },
    undoRemoveContentBundle: {
        id: 'notifications.notificationMessages.undoRemoveContentBundle',
        description: 'Message shown in an application notification when removing a bundle item from the cart',
        defaultMessage: 'You removed {quantity} {denomination} {programLabel} {quantity, plural, one {Bundle} other {Bundles}} for {recipientName}'
    }
});

export default notificationMessages;
