import { defineMessages } from 'react-intl';


const australiaStateMap = defineMessages({
    ACT: {
        id: 'address.australia.australiaStateMap.AustralianCapitalTerritory.ACT',
        description: 'State/Territory label for Australian Capital Territory',
        defaultMessage: 'Australian Capital Territory'
    },
    NSW: {
        id: 'address.australia.australiaStateMap.NewSouthWales.NSW',
        description: 'State/Territory label for New South Wales',
        defaultMessage: 'New South Wales'
    },
    NT: {
        id: 'address.australia.australiaStateMap.NorthernTerritory.NT',
        description: 'State/Territory label for Northern Territory',
        defaultMessage: 'Northern Territory'
    },
    QLD: {
        id: 'address.australia.australiaStateMap.Queensland.QLD',
        description: 'State/Territory label for Queensland',
        defaultMessage: 'Queensland'
    },
    SA: {
        id: 'address.australia.australiaStateMap.SouthAustralia.SA',
        description: 'State/Territory label for South Australia',
        defaultMessage: 'South Australia'
    },
    TAS: {
        id: 'address.australia.australiaStateMap.Tasmania.TAS',
        description: 'State/Territory label for Tasmania',
        defaultMessage: 'Tasmania'
    },
    VIC: {
        id: 'address.australia.australiaStateMap.Victoria.VIC',
        description: 'State/Territory label for Victoria',
        defaultMessage: 'Victoria'
    },
    WA: {
        id: 'address.australia.australiaStateMap.WesternAustralia.WA',
        description: 'State/Territory label for Western Australia',
        defaultMessage: 'Western Australia'
    }
});

export default australiaStateMap;
