import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { intlShape } from 'react-intl';
import { InputGroup } from 'cstar-react-primitives/lib/redux-form/InputGroup';

import { giftCardFieldNames, giftCardFieldAttrs } from '../item/newItemForm';
import getFieldCounterAriaLabel from '../primitive/getFieldCounterAriaLabel';

import recipientMessages from './recipientMessages';


export const SenderNameField = ({
    cashbotName,
    intl
}) => {
    const senderNameProps = {
        atts: { type: 'text', maxLength: giftCardFieldAttrs.NAME_FIELD_MAX },
        label: intl.formatMessage(recipientMessages.senderNameLabel),
        component: InputGroup,
        helper: intl.formatMessage(recipientMessages.SenderNameHelper),
        name: giftCardFieldNames.SENDER_NAME_FIELD,
        cashbotName,
        hasMaxLengthCounter: true,
        counterAriaLabel: getFieldCounterAriaLabel(intl)
    };
    return <Field {...senderNameProps} />;
};

SenderNameField.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    intl: intlShape.isRequired
};

export default SenderNameField;
