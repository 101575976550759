import { Map as IMap } from 'immutable';

export const CONSENT_STORE_NAME = 'consent';
export const COOKIE_CONSENT_GIVEN = 'cookieConsentGiven';
export const ACCEPTED_CARDHOLDER_AGREEMENT = 'acceptedCardholderAgreement';
export const ACCEPTED_GDPR_TERMS_AGREEMENT = 'acceptedGdprTermsAgreement';


export const SET_COOKIE_CONSENT_GIVEN = '@@storefront/SET_COOKIE_CONSENT_GIVEN';
export const SET_CARDHOLDER_AGREEMENT_ACCEPTANCE = '@@storefront/SET_CARDHOLDER_AGREEMENT_ACCEPTANCE';
export const RESET_CARDHOLDER_AGREEMENT_ACCEPTANCE = '@@storefront/RESET_CARDHOLDER_AGREEMENT_ACCEPTANCE';
export const SET_GDPR_TERMS_AGREEMENT_ACCEPTANCE = '@@storefront/SET_GDPR_TERMS_AGREEMENT_ACCEPTANCE';
export const RESET_GDPR_TERMS_AGREEMENT_ACCEPTANCE = '@@storefront/RESET_GDPR_TERMS_AGREEMENT_ACCEPTANCE';

export const initialState = IMap({
    [COOKIE_CONSENT_GIVEN]: false,
    [ACCEPTED_CARDHOLDER_AGREEMENT]: false,
    [ACCEPTED_GDPR_TERMS_AGREEMENT]: false
});

export default function consentReducer(state = initialState, action) {
    switch (action.type) {
        case SET_COOKIE_CONSENT_GIVEN: {
            return state.set(COOKIE_CONSENT_GIVEN, action.isGiven);
        }
        case SET_CARDHOLDER_AGREEMENT_ACCEPTANCE: {
            return state.set(ACCEPTED_CARDHOLDER_AGREEMENT, action.isAccepted);
        }
        case RESET_CARDHOLDER_AGREEMENT_ACCEPTANCE: {
            return state.set(ACCEPTED_CARDHOLDER_AGREEMENT, false);
        }
        case SET_GDPR_TERMS_AGREEMENT_ACCEPTANCE: {
            return state.set(ACCEPTED_GDPR_TERMS_AGREEMENT, action.isAccepted);
        }
        case RESET_GDPR_TERMS_AGREEMENT_ACCEPTANCE: {
            return state.set(ACCEPTED_GDPR_TERMS_AGREEMENT, false);
        }
        default:
            return state;
    }
}


export function setCookieConsentGiven(isGiven) {
    return {
        type: SET_COOKIE_CONSENT_GIVEN,
        isGiven
    };
}

export function setCardholderAgreementAcceptance(isAccepted) {
    return {
        type: SET_CARDHOLDER_AGREEMENT_ACCEPTANCE,
        isAccepted
    };
}

export function resetCardholderAgreementAcceptance() {
    return {
        type: RESET_CARDHOLDER_AGREEMENT_ACCEPTANCE
    };
}

export function setGdprTermsAgreementAcceptance(isAccepted) {
    return {
        type: SET_GDPR_TERMS_AGREEMENT_ACCEPTANCE,
        isAccepted
    };
}

export function resetGdprTermsAgreementAcceptance() {
    return {
        type: RESET_GDPR_TERMS_AGREEMENT_ACCEPTANCE
    };
}
