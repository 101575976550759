import { defineMessages } from 'react-intl';


export const promoBannerMessages = defineMessages({
    termsLink: {
        id: 'promo.banner.termsLink',
        description: 'Link text to view promotional terms and conditions',
        defaultMessage: 'Terms and Conditions'
    },
    modalHeader: {
        id: 'promo.modal.modalHeader',
        description: 'Header text for the promo terms and conditions modal',
        defaultMessage: 'Terms and Conditions'
    },
    modalContentLabel: {
        id: 'promo.modal.modalContentLabel',
        description: 'Promo terms and conditions - aria-label html attr - DO NOT CUSTOMIZE',
        defaultMessage: 'Promotion Terms and Conditions',
        meta: {
            editable: false
        }
    }
});

const promoMessages = defineMessages({
    digitalDelivery: {
        id: 'promo.item.digitalDelivery.md',
        description: 'Delivery info displayed when an electronic promotional reward will be emailed to the intended recipient',
        defaultMessage: '**For:** {name}'
    },
    physicalDelivery: {
        id: 'promo.item.physicalDelivery.md',
        description: 'Delivery info displayed when a physical promotional reward will be mailed '
            + 'to the intended recipient\'s address',
        defaultMessage: '**For:** {name}'
    },
    digitalDeliverySelfBuy: {
        id: 'promo.item.digitalDeliverySelfBuy.md',
        description: 'Delivery info displayed when an electronic promotional reward will be emailed to the purchaser',
        defaultMessage: '**For:** You'
    },
    physicalDeliverySelfBuy: {
        id: 'promo.item.physicalDeliverySelfBuy.md',
        description: 'Delivery info displayed when a physical promotional reward will be mailed '
            + 'to the purchaser\'s address',
        defaultMessage: '**For:** You'
    },
    plasticPromoQuantity: {
        id: 'promo.item.plasticPromoQuantity',
        description: 'Message used to describe the amount of plastic promo cards in a group',
        defaultMessage: '{itemCount, plural, one {{itemCount} bonus physical card will be shipped to} other '
            + '{{itemCount} bonus physical cards will be shipped to}}'
    },
    plasticPromoSelfBonusDelivery: {
        id: 'promo.item.plasticPromoSelfBonusDelivery',
        description: 'Message used to describe the amount of plastic promo cards being shipped to the sender',
        defaultMessage: '{itemCount, plural, one {{itemCount} bonus physical card will be shipped to you} other '
            + '{{itemCount} bonus physical cards will be shipped to you}}'
    },
    nonDenomDisplayValue: {
        id: 'promo.item.nonDenomDisplayValue',
        description: 'Total cost displayed for free promotional rewards',
        defaultMessage: 'FREE'
    },
    itemDeliveryDate: {
        id: 'promo.item.itemDeliveryDate',
        description: 'Display the date the promo will be sent to the recipient',
        defaultMessage: 'On {deliveryDate}'
    }
});

export const promoSummary = defineMessages({
    promoSummaryHeader: {
        id: 'promo.summary.promoSummaryHeader',
        description: 'Section Heading used to describe the promotional summary section in the cart',
        defaultMessage: 'Promotions'
    },
    activePromoCodeLabel: {
        id: 'promo.summary.activePromoCodeLabel',
        description: 'Promo Code Label',
        defaultMessage: 'Promo Code: {promoCode}'
    }
});

export const promoField = defineMessages({
    promoLabel: {
        id: 'promo.field.label.promoLabel',
        description: 'Label text for promo code input',
        defaultMessage: 'Promo Code (optional)'
    },
    promoLabelAdditional: {
        id: 'promo.field.label.promoLabelAdditional',
        description: 'Label text for promo code input when an promo code has previously been applied successfully',
        defaultMessage: 'Enter a Different Promo Code (optional)'
    },
    promoCodeButton: {
        id: 'promo.field.button.promoCodeButton',
        description: 'Button text for submitting an entered promo code',
        defaultMessage: 'Apply'
    },
    promoApplied: {
        id: 'promo.field.success.promoApplied',
        description: 'Text displayed when an entered promo code was successfully applied to the cart',
        defaultMessage: '{promoCode} successfully applied'
    },
    promoExpiredError: {
        id: 'promo.field.error.promoExpiredError',
        description: 'Text displayed when an entered promo code has expired',
        defaultMessage: '{promoCode} has expired'
    },
    promoInvalidError: {
        id: 'promo.field.error.promoInvalidError',
        description: 'Text dispalyed when an entered promo code is invalid',
        defaultMessage: '{promoCode} is an invalid promo code'
    }
});

export const promoErrorModalMessages = defineMessages({
    editCartButtonText: {
        id: 'promo.error.modal.editCartButtonText',
        description: 'Button text to give you the option of editing your cart.',
        defaultMessage: 'Edit Cart'
    },
    continueButtonText: {
        id: 'promo.error.modal.continueButtonText',
        description: 'Button text to continue with the purchase.',
        defaultMessage: 'Continue with Purchase'
    },
    closeButtonText: {
        id: 'promo.error.modal.closeButtonText',
        description: 'Button text to close the modal.',
        defaultMessage: 'Retry'
    }
});

export const promoCodeStatusMessages = defineMessages({
    invalidPromoCodeText: {
        id: 'promo.status.error.invalidPromoCodeText',
        description: 'The error message shown when the supplied promo code is invalid.',
        defaultMessage: '{promoCode} is an invalid promo code'
    },
    expiredPromoCodeText: {
        id: 'promo.status.error.expiredPromoCodeText',
        description: 'The error message shown when the supplied promo code has expired.',
        defaultMessage: '{promoCode} has expired'
    },
    soldOutPromoCodeText: {
        id: 'promo.status.error.soldOutPromoCodeText',
        description: 'The error message shown when the supplied promo code is sold out.',
        defaultMessage: '{promoCode} is sold out'
    },
    appliedPromoCodeText: {
        id: 'promo.status.success.appliedPromoCodeText',
        description: 'The message shown when the supplied promo code is applied successfully.',
        defaultMessage: '{promoCode} successfully applied'
    },
    mismatchedAuthAmountPromoCodeText: {
        id: 'promo.status.error.mismatchedAuthAmountPromoCodeText',
        description: 'The message shown when the promo reward amount is greater than what it should be.',
        defaultMessage: 'There was a problem with your order, please edit your cart or complete your purchase '
            + 'without the promotion.'
    }
});

export const promoUpsellMessages = defineMessages({
    promoUpsellForOneTierPromo: {
        id: 'promo.upsell.message.promoUpsellForOneTierPromo',
        description: 'Message displayed for promo upsell for one tier promo for amount page',
        defaultMessage: 'Buy {propositionAmount1} to get <strong>{rewardAmount1}</strong> {promoGiftCardSubText}.'
    },
    promoUpsellForTwoTierPromo: {
        id: 'promo.upsell.message.promoUpsellForTwoTierPromo',
        description: 'Message displayed for promo upsell for two tier promo for amount page',
        defaultMessage: 'Buy {propositionAmount1} to get <strong>{rewardAmount1}</strong> {promoGiftCardSubText} or buy {propositionAmount2} to get <strong>{rewardAmount2}</strong> {promoGiftCardSubText}.'
    },
    promoUpsellForOneTierPromoForCart: {
        id: 'promo.upsell.message.promoUpsellForOneTierPromoForCart',
        description: 'Message displayed for promo upsell for One tier promo for cart page & also same message for two tier promo when one condition of two tier is fullfilled',
        defaultMessage: 'Buy {remainingAmount1} to get {rewardAmount1} {promoGiftCardSubText}.'
    },
    promoUpsellForTwoTierPromoForCart: {
        id: 'promo.upsell.message.promoUpsellForTwoTierPromoForCart',
        description: 'Message displayed for promo upsell for two tier promo for cart page',
        defaultMessage: 'Buy {remainingAmount1} to get {rewardAmount1} {promoGiftCardSubText} or buy {remainingAmount2} to get {rewardAmount2} {promoGiftCardSubText}.'
    },
    promoBannerLabel: {
        id: 'promo.upsell.label.promoBannerLabel',
        description: 'Label text for promo banner on cart page',
        defaultMessage: 'Promotion'
    },
    promoGiftCardName: {
        id: 'promo.upsell.label.promoGiftCardName',
        description: 'Promo Gift Card Name sub text for promo on amount and cart page',
        defaultMessage: 'free coupon'
    }
});

export default promoMessages;
