import { Map as IMap } from 'immutable';

import getNowTimestamp from '../utils/time/getNowTimestamp';
import dateStringToDate from '../utils/time/dateStringToDate';
import { NEW_ITEM_FORM_NAME, RECIPIENT_SEND_DATETIME_FIELD } from '../item/newItemForm';
import { FLOW_PROGRESS_STORE_NAME } from '../routing/flowProgressModule';



export const CLEAR_STOREFRONT_SESSION = '@@storefront/CLEAR_STOREFRONT_SESSION';
export const SET_STOREFRONT_SESSION = '@@storefront/SET_STOREFRONT_SESSION';
export const GET_OR_CREATE_STOREFRONT_SESSION = '@@storefront/GET_OR_CREATE_STOREFRONT_SESSION';
export const SET_IS_CART_EDITED = '@@storefront/SET_IS_CART_EDITED';
export const LOG_SESSION_INFO_IN_SEGMENT = '@@storefront/LOG_SESSION_INFO_IN_SEGMENT';
export const CAPI_SESSION_CALL_INDICATOR = '@@storefront/CAPI_SESSION_CALL_INDICATOR';

export const SESSION_LIFETIME = 1000 * 60 * 60 * 24; 

export const sessionLifetimeDeserializer = (state, payload) => {
    const lastActionTimestamp = payload.lastAction;
    if (lastActionTimestamp) {
        const now = getNowTimestamp();
        const sessionExpired = (now - lastActionTimestamp) > SESSION_LIFETIME;
        if (sessionExpired) {
            return state;
        }
    }
    
    
    if (payload.brand && payload.brand.catalogConfigLoadTime) {
        delete payload.brand.catalogConfigLoadTime;
    }
    if (payload.programs && payload.programs.programConfigLoadTime) {
        delete payload.programs.programConfigLoadTime;
    }
    
    const { intl } = payload;
    delete payload.intl;
    const deliveryDateUpdater = (sendDateString) => {
        if (sendDateString) {
            const now = getNowTimestamp();
            const sendDate = dateStringToDate(sendDateString);
            if (sendDate.getTime() < now) {
                return new Date(now);
            }
            return sendDate;
        }
        return sendDateString;
    };
    return state.merge(payload).set('intl', intl)
        .updateIn([FLOW_PROGRESS_STORE_NAME, 'progress'],
            progress => (progress && progress.toSet ? progress.toSet() : progress))
        .updateIn(['form', NEW_ITEM_FORM_NAME],
            newItemForm => (newItemForm && newItemForm.updateIn
                ? newItemForm
                    .updateIn(['values', RECIPIENT_SEND_DATETIME_FIELD], deliveryDateUpdater)
                    .updateIn(['initial', RECIPIENT_SEND_DATETIME_FIELD], deliveryDateUpdater)
                : newItemForm))
        .update('items', items => (items && items.map
            ? items.map(item => item.update('deliveryDate', deliveryDateUpdater))
            : items));
};


const sessionReducer = (state = IMap({ isCartEdited: null }), action) => {
    switch (action.type) {
        case SET_STOREFRONT_SESSION: {
            const { sessionKey } = action;
            return state.set('sessionKey', sessionKey);
        }
        case SET_IS_CART_EDITED:
            return state.set('isCartEdited', action.value);
        case CLEAR_STOREFRONT_SESSION:
            return state.delete('sessionKey').set('isCartEdited', null);
        default:
    }
    return state;
};




export const capiCallReducer = (state = IMap({ sessionCallInProgress: false }), action) => {
    switch (action.type) {
        case CAPI_SESSION_CALL_INDICATOR:
            return state.set('sessionCallInProgress', action.flag);
        default:
    }
    return state;
};


export const clearStorefrontSession = () => ({
    type: CLEAR_STOREFRONT_SESSION
});

export const setStorefrontSession = sessionKey => ({
    type: SET_STOREFRONT_SESSION,
    sessionKey
});

export const getOrCreateStorefrontSession = () => ({
    type: GET_OR_CREATE_STOREFRONT_SESSION
});

export const setIsCartEdited = value => ({
    type: SET_IS_CART_EDITED,
    value
});

export const logSessionInfoInSegment = (eventAction, eventData = {}) => ({
    type: LOG_SESSION_INFO_IN_SEGMENT,
    meta: {
        segment: {
            event: 'track',
            eventAction,
            eventData
        }
    }
});

export const capiSessionCallIndicator = (flag = true) => ({
    type: CAPI_SESSION_CALL_INDICATOR,
    flag
});

export default sessionReducer;
