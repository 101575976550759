import { defineMessages } from 'react-intl';


export const footerFirstdata = defineMessages({
    title: {
        id: 'footer.logos.firstdata.title',
        description: 'firstdata logo html title',
        defaultMessage: 'First Data'
    }
});


export const footerLinksDefault = defineMessages({
    faq: {
        id: 'footer.links.default.faq',
        description: 'Footer Frequently Asked Questions link text',
        defaultMessage: 'FAQ'
    },
    terms: {
        id: 'footer.links.default.terms',
        description: 'Footer terms and conditions link text',
        defaultMessage: 'Terms and Conditions'
    },
    privacy: {
        id: 'footer.links.default.privacy',
        description: 'Footer privacy policy link text',
        defaultMessage: 'Privacy Policy'
    },
    support: {
        id: 'footer.links.default.support',
        description: 'Footer customer support link text',
        defaultMessage: 'Customer Support'
    },
    accessibility: {
        id: 'footer.links.default.accessibility',
        description: 'Footer commitment to accessibility link text',
        defaultMessage: 'Commitment to Accessibility'
    },
    accessibilityLink: {
        id: 'footer.links.default.accessibilityLink',
        description: 'Actual link for footer commitment to accessibility link',
        defaultMessage: 'https://blackhawknetwork.com/commitment-to-accessibility/'
    }
});


export default footerLinksDefault;
