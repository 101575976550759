import { Map as IMap, fromJS } from 'immutable';
import { createSelector } from 'reselect';

import { getActiveProgramCode } from '../program/programSelectors';
import { getProgramCopy } from '../program/getProgramCopy';


export const getIntl = state => state.get('intl');


export const intlSelector = (state) => {
    const intl = state.get('intl');
    let returnIntl = intl;
    if (IMap.isMap(intl)) {
        returnIntl = intl.toJS();
    }
    return returnIntl;
};


export const intlForProgram = createSelector(
    intlSelector,
    intl => (programCode) => {
        const messages = programCode ? getProgramCopy(intl.messages, programCode) : intl.messages;
        return {
            ...intl,
            key: `${intl.locale}-${programCode}`,
            messages
        };
    }
);


export const messagesForProgram = createSelector(
    intlForProgram,
    programIntlFn => programCode => fromJS(programIntlFn(programCode).messages)
);


export const activeProgramIntlSelector = createSelector(
    intlForProgram,
    getActiveProgramCode,
    (programIntlFn, programCode) => programIntlFn(programCode)
);


export const messages = createSelector(
    activeProgramIntlSelector,
    intl => fromJS(intl.messages)
);


export const formats = createSelector(
    activeProgramIntlSelector,
    intl => fromJS(intl).get('formats', IMap())
);


export const getCurrencyFormat = createSelector(
    formats,
    intlFormats => intlFormats.getIn(['number', 'currency'], IMap())
);


export const getIntlCurrencyCode = createSelector(
    getCurrencyFormat,
    currencyFormat => currencyFormat.get('currency')
);


export const getIntlLocale = createSelector(
    activeProgramIntlSelector,
    intl => fromJS(intl).get('locale')
);


export const getIntlIsLoading = createSelector(
    activeProgramIntlSelector,
    intl => fromJS(intl).get('loading', false)
);

export const getShouldPredictIntlCurrency = createSelector(
    activeProgramIntlSelector,
    intl => fromJS(intl).get('shouldPredictIntlCurrency', false)
);


export const getConfirmationBackButtonLabel = createSelector(
    messages,
    intlMessages => intlMessages.get('payment.paymentConfirmation.confirmationBackButtonLabel', null)
);

export const getConfirmationBackButtonUrl = createSelector(
    messages,
    intlMessages => intlMessages.get('payment.paymentConfirmation.confirmationBackButtonUrl', null)
);

export const getConfirmationBackButtonType = createSelector(
    messages,
    intlMessages => intlMessages.get('payment.paymentConfirmation.confirmationBackButtonType', null)
);

export const getTestEnvMessages = createSelector(
    messages,
    intlMessages => intlMessages.get('testEnvironmentBannerMessages.testEnvironmentBannerMessage', null)
);
