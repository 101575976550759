import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Field } from 'redux-form/immutable';

import { giftCardFieldNames } from '../../item/newItemForm';

import datePickerMessages from './datePickerMessages';
import DatePicker from './DatePicker';
import { TimeZoneProp } from '../time/timeProps';


const DateField = ({
    intl,
    cashbotName,
    fieldLabel,
    onDateChange,
    onReset,
    timeZone,
    setFocusSendTimeLink
}) => {
    const dateProps = {
        label: intl.formatMessage(fieldLabel),
        component: DatePicker,
        name: giftCardFieldNames.RECIPIENT_SEND_DATETIME_FIELD,
        cashbotName,
        handleChange: onDateChange,
        handleReset: onReset,
        timeZone,
        setFocusSendTimeLink
    };
    return <Field {...dateProps} />;
};

DateField.defaultProps = {
    fieldLabel: datePickerMessages.datePickerFieldlabel,
    onDateChange: null,
    onReset: null,
    setFocusSendTimeLink: () => {}
};

DateField.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    fieldLabel: PropTypes.object,
    intl: intlShape.isRequired,
    onDateChange: PropTypes.func,
    onReset: PropTypes.func,
    timeZone: TimeZoneProp.isRequired,
    setFocusSendTimeLink: PropTypes.func
};

export default DateField;
