import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Map as IMap, fromJS } from 'immutable';

import timezoneMessages from './timezoneMessages';
import TimezoneAutocomplete from './TimezoneAutocomplete';


export const TimezoneField = ({
    intl,
    cashbotName,
    fieldLabel,
    recipientName,
    initialTimeZone,
    onTimeZoneChange
}) => {
    const fieldProps = {
        isRequiredField: false,
        label: recipientName
            ? intl.formatMessage(fieldLabel, { name: recipientName })
            : intl.formatMessage(timezoneMessages.timezoneFieldLabelNoName),
        cashbotName,
        initialTimeZone: initialTimeZone ? initialTimeZone.toJS() : null,
        onTimeZoneChange: timeZone => onTimeZoneChange(fromJS(timeZone))
    };

    return (<TimezoneAutocomplete {...fieldProps} />);
};

TimezoneField.defaultProps = {
    fieldLabel: timezoneMessages.timezoneFieldLabel,
    initialTimeZone: null
};

TimezoneField.propTypes = {
    intl: intlShape.isRequired,
    cashbotName: PropTypes.string.isRequired,
    fieldLabel: PropTypes.object,
    recipientName: PropTypes.string.isRequired,
    initialTimeZone: PropTypes.instanceOf(IMap),
    onTimeZoneChange: PropTypes.func.isRequired
};

export default TimezoneField;
