import { Map as IMap } from 'immutable';
import {
    take, select, call, put
} from 'redux-saga/effects';
import { RETRIEVED_BRAND_CONFIG } from 'capi/redux';
import { getMasterpassData } from '../app/bootstrap';

import { getMasterpassEnabled } from '../brand/brandSelectors';


export const MASTERPASS_LOADED = '@@storefront/MASTERPASS_LOADED';
export const SET_MASTERPASS_DATA = '@@storefront/SET_MASTERPASS_DATA';
export const START_MASTERPASS_CALLBACK = '@@storefront/START_MASTERPASS_CALLBACK';

export const MASTERPASS_PAYMENT_TYPE = 'masterpass';


const initialState = IMap({ masterpassLoaded: false });

export const masterpassReducer = (state = initialState, action) => {
    switch (action.type) {
        case MASTERPASS_LOADED: {
            return state.set('masterpassLoaded', true);
        }
        case SET_MASTERPASS_DATA: {
            return state.merge({
                masterpassToken: action.token,
                address: action.address
            });
        }
        default:
    }
    return state;
};


export const masterpassLoaded = () => ({
    type: MASTERPASS_LOADED
});


export const startMasterpassCallback = location => ({
    type: START_MASTERPASS_CALLBACK,
    location
});


export const setMasterpassData = (token, address) => ({
    type: SET_MASTERPASS_DATA,
    token,
    address
});


export const getMasterpassLoaded = state => state.getIn(['masterpass', 'masterpassLoaded']);


export const loadMasterpass = () => new Promise((resolve) => {
    const script = document.createElement('script');
    script.setAttribute('src', getMasterpassData().get('integration_merchant_js_url'));
    
    script.onreadystatechange = script.onload = () => {
        resolve(window.masterpass);
    };
    document.getElementsByTagName('head')[0].appendChild(script);
});


export function* masterpassLoaderSaga() {
    
    while (true) {
        yield take(RETRIEVED_BRAND_CONFIG.PAYMENT);
        const masterpassIsEnabled = yield select(getMasterpassEnabled);
        const masterpassIsLoaded = yield select(getMasterpassLoaded);

        if (masterpassIsEnabled && !masterpassIsLoaded) {
            yield call(loadMasterpass);
            yield put(masterpassLoaded());
        }
    }
}
