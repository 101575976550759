import { defineMessages } from 'react-intl';


const currencyNameMap = defineMessages({
    AUD: {
        id: 'currency.currencyNameMap.AustralianDollar.AUD',
        description: 'currency label for Australian dollar',
        defaultMessage: 'Australian dollar'
    },
    CAD: {
        id: 'currency.currencyNameMap.CanadianDollar.CAD',
        description: 'currency label for Canadian dollar',
        defaultMessage: 'Canadian dollar'
    },
    CHF: {
        id: 'currency.currencyNameMap.SwissFranc.CHF',
        description: 'currency label for Swiss franc',
        defaultMessage: 'Swiss franc'
    },
    EUR: {
        id: 'currency.currencyNameMap.Euro.EUR',
        description: 'currency label for Euro',
        defaultMessage: 'Euro'
    },
    GBP: {
        id: 'currency.currencyNameMap.BritishPounds.GBP',
        description: 'currency label for British pounds',
        defaultMessage: 'British pounds'
    },
    HKD: {
        id: 'currency.currencyNameMap.HongKongDollar.HKD',
        description: 'currency label for Hong Kong dollar',
        defaultMessage: 'Hong Kong dollar'
    },
    NZD: {
        id: 'currency.currencyNameMap.NewZealandDollar.NZD',
        description: 'currency label for New Zealand dollar',
        defaultMessage: 'New Zealand dollar'
    },
    SEK: {
        id: 'currency.currencyNameMap.SwedishKrona.SEK',
        description: 'currency label for Swedish krona',
        defaultMessage: 'Swedish krona'
    },
    USD: {
        id: 'currency.currencyNameMap.UnitedStatesDollar.USD',
        description: 'currency label for United States dollar',
        defaultMessage: 'United States dollar'
    },
    BRL: {
        id: 'currency.currencyNameMap.BrazilianReal.BRL',
        description: 'currency label for Brazilian Real',
        defaultMessage: 'Brazilian Real'
    },
    JPY: {
        id: 'currency.currencyNameMap.JapaneseYen.JPY',
        description: 'currency label for Japanese Yen',
        defaultMessage: 'Japanese Yen'
    },
    MXN: {
        id: 'currency.currencyNameMap.MexicanDollar.MXN',
        description: 'currency label for Mexican Dollar',
        defaultMessage: 'Mexican Dollar'
    },
    SGD: {
        id: 'currency.currencyNameMap.SingaporeDollar.SGD',
        description: 'currency label for Singapore Dollar',
        defaultMessage: 'Singapore Dollar'
    }
});

export default currencyNameMap;
