import { getActiveProgramExcludedBillingStates } from '../program/programSelectors';
import { getCartExcludedBillingStatesForProgram } from '../program/programListSelectors';
import { getBrandExcludedBillingStates } from '../brand/brandSelectors';
import stateMap from '../addressFields/data/USStateMap';


export function getExcludedBillingStates(state) {
    const activeProgramEBS = getActiveProgramExcludedBillingStates(state).split(',');
    const brandEBS = getBrandExcludedBillingStates(state).split(',');

    
    return [...new Set([...activeProgramEBS, ...brandEBS])].filter(stateAbbr => stateAbbr).toString();
}


export function getAllCartExcludedBillingStates(state) {
    const cartProgramEBS = getCartExcludedBillingStatesForProgram(state).split(',');
    const brandEBS = getBrandExcludedBillingStates(state).split(',');

    
    return [...new Set([...cartProgramEBS, ...brandEBS])].filter(stateAbbr => stateAbbr).toString();
}


export function getFilteredStatesFromExcludedBillingStates(excludedBillingStates) {
    const excludedBillingStatesSanitizedArray = excludedBillingStates.replace(/ /g, '').split(',');
    return Object.keys(stateMap).filter(state => !excludedBillingStatesSanitizedArray.includes(state));
}
