import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classnames from 'classnames';
import { List as IList } from 'immutable';
import { icons } from 'cstar-react-primitives/lib/redux-form/icons/iconConst';
import { Select } from 'react-responsive-select';

import { getSupportedLocales } from '../brand/brandSelectors';
import languagePickerMessages from './languagePickerMessages';
import { setLocale as setLocaleActionCreator } from './intlModule';
import { getIntlLocale } from './intlSelectors';

const Flag = ({ countryCode, styleNames = {} }) => <i className={classnames(styleNames, 'flag', countryCode)} />;

Flag.defaultProps = {
    styleNames: {}
};

Flag.propTypes = {
    countryCode: PropTypes.string.isRequired,
    styleNames: PropTypes.object
};

const Country = ({ locale }) => (
    <span>
        <Flag countryCode={locale.split('-')[1]} />
        <FormattedMessage {...languagePickerMessages[locale]} />
    </span>
);

Country.propTypes = {
    locale: PropTypes.string.isRequired
};

export const LanguagePicker = ({
    intl, supportedLocales, selectedLocale, openUpwards, setLocale
}) => {
    const fullySupportedLocales = supportedLocales.filter(locale => locale in languagePickerMessages);
    return fullySupportedLocales.size <= 1
        ? null
        : (
            <div
                className={classnames('language_picker', { language_picker__up: openUpwards })}
                aria-label={intl.formatMessage(languagePickerMessages.languagePickerLabel)}
            >
                <form>
                    <div className="language_selector_div">
                        {intl.formatMessage(languagePickerMessages.languagePickerLabel)}
                    </div>
                    <Select
                        onChange={({ value, altered }) => {
                            if (altered) {
                                setLocale(value);
                            }
                        }}
                        options={
                            fullySupportedLocales.map(locale => ({
                                text: intl.formatMessage(languagePickerMessages[locale]),
                                value: locale,
                                markup: <Country locale={locale} />
                            })).toJS()
                        }
                        name="language_picker"
                        selectedValue={selectedLocale}
                        
                        customLabelRenderer={({ text, value }) => (value
                            ? <Country locale={value} />
                            : text)
                        }
                        caretIcon={(
                            <span
                                key="language-picker-caret"
                                className="language_picker__select-caret"
                                data-testid={openUpwards
                                    ? 'language_picker__select-caret-up' : 'language_picker__select-caret-down'}
                            >
                                {icons.CARET_DOWN}
                            </span>
                        )}
                    />
                </form>
            </div>
        );
};

LanguagePicker.defaultProps = {
    supportedLocales: IList(),
    openUpwards: false
};

LanguagePicker.propTypes = {
    openUpwards: PropTypes.bool,
    setLocale: PropTypes.func.isRequired,
    supportedLocales: ImmutablePropTypes.list,
    selectedLocale: PropTypes.string.isRequired,
    intl: intlShape.isRequired
};

export const mapStateToProps = state => ({
    selectedLocale: getIntlLocale(state),
    supportedLocales: getSupportedLocales(state)
});

export const mapDispatchToProps = {
    setLocale: setLocaleActionCreator
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LanguagePicker));
