import { Map as IMap } from 'immutable';


export const BACKUP_ESSENTIAL_DATA = '@@storefront/BACKUP_ESSENTIAL_DATA';
export const CLEAR_BACKUP_DATA = '@@storefront/CLEAR_BACKUP_DATA';



export default function reducer(state = IMap(), action) {
    switch (action.type) {
        case BACKUP_ESSENTIAL_DATA:
            return state.merge({
                backupData: {
                    postValidateResponse: action.data.postValidateResponse,
                    fees: action.data.fees,
                    grandTotal: action.data.grandTotal,
                    activationFeesTotal: action.data.activationFeesTotal,
                    uyopFeesTotal: action.data.uyopFeesTotal,
                    activationFeesCount: action.data.activationFeesCount,
                    checkoutId: action.data.checkoutId,
                    paymentUrlParams: window.location.search
                }
            });
        case CLEAR_BACKUP_DATA:
            return state.delete('backupData');
        default:
    }
    return state;
}




export function backupEssentialData(data) {
    return {
        type: BACKUP_ESSENTIAL_DATA,
        data
    };
}


export function clearBackupData() {
    return {
        type: CLEAR_BACKUP_DATA
    };
}
