import React, { useEffect, useRef, useState } from 'react';
import { intlShape } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/lib/md';

import paymentMessages from '../payment/paymentMessages';
import { trackPaymentPerformance } from '../payment/paymentModule';

export const STEP_UP_TIMEOUT_SECONDS = 600;

export const StepUpPopOut = ({
    intl,
    trackPaymentPerformance: trackPaymentPerformanceAction,
    merchantData,
    enrollmentAccessToken,
    stepUpUrl,
    closeStepUpWindow
}) => {
    const stepUpForm = useRef();
    const [hasSubmitted, setHasSubmitted] = useState(false);

    useEffect(() => {
        trackPaymentPerformanceAction('Launched: step up auth pop out window');
        setTimeout(() => {
            trackPaymentPerformanceAction('Expired (10 min): step up auth pop out window');
            closeStepUpWindow();
        }, STEP_UP_TIMEOUT_SECONDS * 1000);
    }, []);

    useEffect(() => {
        if (stepUpForm.current && !hasSubmitted) {
            setHasSubmitted(true);
            stepUpForm.current.submit();
        }
    }, [stepUpForm]);

    return (
        <div className='stepup-wrapper'>
            <button
                type='button'
                className='close-button__payment-modal'
                onClick={closeStepUpWindow}
                style={{
                    float: 'right',
                    margin: '10px',
                    border: 'none',
                    fontSize: '1.5rem',
                    background: 'none'
                }}
            >
                <MdClose />
            </button>

            <div
                className="container"
                style={{
                    margin: '0',
                    maxWidth: '100%',
                    height: '100%'
                }}
            >
                <h2 className="page-header" style={{ marginTop: '10px' }}>
                    <span>{ intl.formatMessage(paymentMessages.stepUpPleaseAuthenticate) }</span>
                </h2>
                <iframe title="StepUpAuth" name="step-up-iframe" style={{ width: '100%', minHeight: 'calc(100vh -  4rem)', border: 'none' }} />
                <form
                    id="step-up-form"
                    target="step-up-iframe"
                    ref={stepUpForm}
                    method="POST"
                    action={stepUpUrl}
                    data-testid="step-up-form"
                >
                    <input
                        type="hidden"
                        name="JWT"
                        value={enrollmentAccessToken}
                    />
                    <input
                        type="hidden"
                        name="MD"
                        value={merchantData}
                    />
                </form>
            </div>
        </div>
    );
};

StepUpPopOut.propTypes = {
    intl: intlShape.isRequired,
    trackPaymentPerformance: PropTypes.func.isRequired,
    merchantData: PropTypes.string.isRequired,
    enrollmentAccessToken: PropTypes.string.isRequired,
    stepUpUrl: PropTypes.string.isRequired,
    closeStepUpWindow: PropTypes.func.isRequired
};

export default connect(null, { trackPaymentPerformance })(StepUpPopOut);
