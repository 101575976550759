import { reset, getFormValues, change } from 'redux-form/immutable';
import { v4 as uuid } from 'uuid';
import { Map as IMap, fromJS } from 'immutable';
import {
    put,
    call,
    take,
    select,
    takeEvery
} from 'redux-saga/effects';

import { getAddress } from '../address/addressSelectors';
import { getUYOFileUrl } from '../uyo/uyoSelectors';
import { removeUYOData } from '../uyo/uyoModule';
import {
    getProfileLowestCostMethod,
    getShippingMethodProfileId,
    getItemShippingMethodProfile
} from '../shipping/shippingSelectors';
import {
    saveAddressFields,
    addressToID,
    getAddressHash,
    fieldKeys
} from '../address/addressModule';
import {
    getShippingMethods,
    CREATE_PROFILE_SUCCESS,
    CREATE_PROFILE_FAILED,
    PROFILE_ALREADY_EXISTS
} from '../shipping/shippingModule';
import {
    createNotification,
    removeNotification,
    ITEM_UNDO
} from '../notifications/notifyModule';
import {
    applyActivePromo,
    removeCustomRewardCardRecipients,
    removePromoRewards,
    setCustomRewardCardRecipientEmailForPromo,
    setCustomRewardCardRecipientNameForPromo
} from '../promo/promoModule';
import {
    setCartStatusProcessing,
    setCartStatusInvalid,
    setCartStatusReady,
    setCartStatusRedirectToDesign
} from '../cart/cartModule';
import { faceplateForCode, isFaceplateDigital, isFaceplatePhysical } from '../faceplate/faceplateSelectors';


import { resetFieldValues } from './newItemFormModule';
import {
    ITEM_RESET,
    ITEM_UNDO_REMOVE,
    ITEM_IS_REMOVED,
    SAVE_NEW_ITEM,
    SAVE_NEW_ITEM_END,
    EDIT_ITEM,
    EDIT_ADDRESS,
    EDIT_ITEM_END,
    SHIPPING_OPTIONS_CHANGED,
    ITEM_IS_VALID,
    updateItem,
    unsafeSaveNewItem,
    resetItemStore
} from './itemModule';
import {
    removeItemDisplay,
    resetItemDisplay,
    setEditDetails,
    setEditAddress,
    setEditQuantity,
    CHANGE_ITEM_DISPLAY_VALUE
} from './itemDisplayModule';
import { giftCardFieldNames as itemKeys } from './newItemForm';
import { findItemById, getShippingMethodChanged } from './itemSelectors';
import { resetFlowState, resetPreferredValues, setFlowMessage } from '../routing/flowProgressModule';
import { getCartPath, FLOW_MESSAGES } from '../routing/flow';
import { getParentCartTokenForItem } from '../promo/promoSelectors';



export const REQUIRED_GIFT_CARD_KEYS = fromJS({
    [itemKeys.QUANTITY_FIELD]: {
        requiredPlastic: true, requiredEgc: true, requiredGifting: true, requiredSelfBuy: true
    },
    [itemKeys.AMOUNT_FIELD]: {
        requiredPlastic: true, requiredEgc: true, requiredGifting: true, requiredSelfBuy: true
    },
    [itemKeys.FACEPLATE_FIELD]: {
        requiredPlastic: true, requiredEgc: true, requiredGifting: true, requiredSelfBuy: true
    },
    [itemKeys.RECIPIENT_NAME_FIELD]: {
        requiredPlastic: true, requiredEgc: true, requiredGifting: true, requiredSelfBuy: false
    },
    [itemKeys.SENDER_NAME_FIELD]: {
        requiredPlastic: true, requiredEgc: true, requiredGifting: true, requiredSelfBuy: false
    },
    [itemKeys.SHIPPING_METHOD_FIELD]: {
        requiredPlastic: true, requiredEgc: false, requiredGifting: true, requiredSelfBuy: true
    }
});


export const validateGiftCard = (item) => {
    const isPlastic = item.get(itemKeys.IS_PLASTIC_FIELD);
    const isSelfBuy = item.get(itemKeys.IS_SELFBUY_FIELD);
    const isValid = REQUIRED_GIFT_CARD_KEYS.every((reqFlags, field) => {
        const cardTypeRequires = isPlastic ? reqFlags.get('requiredPlastic') : reqFlags.get('requiredEgc');
        const recipientRequires = isSelfBuy ? reqFlags.get('requiredSelfBuy') : reqFlags.get('requiredGifting');
        const isRequired = (cardTypeRequires && recipientRequires);
        return isRequired ? item.has(field) : true;
    });
    return item.set(ITEM_IS_VALID, isValid);
};



export function validateCardTypeMatchesFaceplate(item, state) {
    
    
    
    if (item.get('faceplateData')) {
        return true;
    }

    const isPlastic = item.get(itemKeys.IS_PLASTIC_FIELD);
    const faceplateCode = item.get(itemKeys.FACEPLATE_FIELD);

    
    const isPhysicalFaceplate = isFaceplatePhysical(state);
    const isDigitalFaceplate = isFaceplateDigital(state);

    if (isPlastic && isPhysicalFaceplate(faceplateCode)) {
        return true;
    }
    if (!isPlastic && isDigitalFaceplate(faceplateCode)) {
        return true;
    }
    
    console.warn(
        'A digital card is being used with a physical faceplate or a '
        + 'physical card is being used with a digital faceplate.'
    );
    return false;
}



export function* saveNewItem({ values, replaceHistory, isBundle }) {
    yield put(setCartStatusProcessing(replaceHistory));
    let newItemValues = validateGiftCard(values);

    if (!newItemValues.get(ITEM_IS_VALID)) {
        yield put(setCartStatusInvalid());
    } else {
        
        const state = yield select();
        const cardAndFaceplateMatches = validateCardTypeMatchesFaceplate(values, state);
        if (!cardAndFaceplateMatches) {
            yield put(setCartStatusRedirectToDesign());
        } else {
            if (values.get(itemKeys.IS_PLASTIC_FIELD)) {
                yield put(saveAddressFields(newItemValues));
                newItemValues = addressToID(newItemValues, itemKeys.ADDRESS_ID);
                if (values.get(itemKeys.SHIPPING_METHOD_FIELD) === 'init') {
                    const shippingProfile = getShippingMethodProfileId(
                        newItemValues.get(itemKeys.ADDRESS_ID),
                        newItemValues.get(itemKeys.QUANTITY_FIELD),
                        newItemValues.get(itemKeys.CURRENCY_FIELD),
                        !!newItemValues.get(itemKeys.ITEM_ACCESSORY, false)
                    );
                    const method = yield select(getProfileLowestCostMethod, shippingProfile);
                    newItemValues = newItemValues.set(
                        itemKeys.SHIPPING_METHOD_FIELD,
                        method.get('token')
                    );
                }
            }

            
            
            
            const faceplateData = yield select(faceplateForCode, newItemValues.get(itemKeys.FACEPLATE_FIELD));
            
            
            
            if (faceplateData) {
                newItemValues = newItemValues.set(itemKeys.FACEPLATE_DATA, faceplateData);
            }

            const uyoPath = yield select(getUYOFileUrl);
            if (uyoPath) {
                newItemValues = newItemValues.set(itemKeys.UYO_IMAGE_URL, uyoPath);
            }


            
            if (isBundle) {
                newItemValues = newItemValues.set('fee', values.get('fee'));
            } else {
                newItemValues = newItemValues.set(itemKeys.ITEM_ID, uuid());
            }

            newItemValues = newItemValues.set('brandCode', values.get('brandCode'));

            yield put(unsafeSaveNewItem(newItemValues.asImmutable()));
            yield put(resetFieldValues());
            yield put(resetPreferredValues());
            yield put(removeUYOData());
            yield put(applyActivePromo());
            yield put(setCartStatusReady(replaceHistory));
            yield put(resetFlowState());
            if (replaceHistory) {
                yield put(setFlowMessage(getCartPath(), FLOW_MESSAGES.PRE_CART_SKIPPED));
            }
        }
    }
    yield put({ type: SAVE_NEW_ITEM_END });
}

export function* updateItemShipping(itemValues, hasAccessory) {
    let updateSuccessful = true;
    const updateValues = itemValues.asMutable();
    const prevItem = yield select(findItemById, itemValues.get(itemKeys.ITEM_ID));
    const addressID = itemValues.get(itemKeys.ADDRESS_ID) || prevItem.get(itemKeys.ADDRESS_ID);
    const quantity = itemValues.get(itemKeys.QUANTITY_FIELD) || prevItem.get(itemKeys.QUANTITY_FIELD);
    const currency = prevItem.get(itemKeys.CURRENCY_FIELD);
    const shippingAddressAndMethodChanged = yield select(getShippingMethodChanged, itemValues);

    const shippingProfile = getShippingMethodProfileId(addressID, quantity, currency, hasAccessory);

    
    if (getItemShippingMethodProfile(prevItem) !== shippingProfile) {
        if (!shippingAddressAndMethodChanged) {
            updateValues.set(SHIPPING_OPTIONS_CHANGED, true);
        }

        const address = yield select(getAddress, addressID);
        const shippingAddress = address.merge({
            [itemKeys.ADDRESS_ID]: addressID,
            [itemKeys.QUANTITY_FIELD]: quantity,
            [itemKeys.CURRENCY_FIELD]: currency,
            hasAccessory
        });

        yield put(getShippingMethods(shippingAddress));

        
        const { type, profile } = yield take([CREATE_PROFILE_SUCCESS, CREATE_PROFILE_FAILED, PROFILE_ALREADY_EXISTS]);
        if (type === CREATE_PROFILE_SUCCESS || type === PROFILE_ALREADY_EXISTS) {
            const defaultMethod = yield select(getProfileLowestCostMethod, profile);
            updateValues.set(itemKeys.SHIPPING_METHOD_FIELD, defaultMethod.get('token'));
        } else {
            updateSuccessful = false;

            
        }
    }

    if (updateSuccessful) {
        
        yield put(setEditDetails(prevItem, false));
        yield put(setEditAddress(prevItem, false));
        yield put(setEditQuantity(prevItem, false));
    }

    yield put(updateItem(updateValues.asImmutable()));
}


export function* editItemSaga(action) {
    const {
        formName,
        skipHistory,
        updateAddress
    } = action;
    let formVals = yield select(getFormValues(formName));
    const storedItem = yield select(findItemById, formVals.get(itemKeys.ITEM_ID));

    if (storedItem) {
        
        if (updateAddress) {
            yield put(saveAddressFields(formVals));
            formVals = addressToID(formVals, itemKeys.ADDRESS_ID);
        } else {
            formVals = formVals.filterNot(((_, fieldKey) => fieldKeys.includes(fieldKey)));
        }

        if (!skipHistory) {
            formVals = formVals.set('previousItem', storedItem);
        }

        if (storedItem.get(itemKeys.IS_PLASTIC_FIELD)) {
            
            yield call(updateItemShipping, formVals, !!storedItem.get(itemKeys.ITEM_ACCESSORY, false));
        } else {
            yield put(updateItem(formVals));

            
            yield put(setEditDetails(storedItem, false));
            yield put(setEditAddress(storedItem, false));
            yield put(setEditQuantity(storedItem, false));
        }

        yield put({ type: EDIT_ITEM_END });
        
        

        
        const parentCardCartToken = yield select(getParentCartTokenForItem, storedItem);
        if (parentCardCartToken) {
            yield put(setCustomRewardCardRecipientEmailForPromo(parentCardCartToken, formVals.get('recipientEmail')));
            yield put(setCustomRewardCardRecipientNameForPromo(parentCardCartToken, formVals.get('recipientName')));
        }

        yield put(applyActivePromo());
    } else {
        yield put({ type: EDIT_ITEM_END, error: `Cannot retrieve item with ID ${formVals.get('id')}` });
    }
}


export function* editAddressSaga(action) {
    const { formName } = action;
    const formVals = yield select(getFormValues(formName));
    const itemID = formVals.get(itemKeys.ITEM_ID);
    const storedItem = yield select(findItemById, itemID);
    if (!storedItem) {
        yield put({ type: EDIT_ITEM_END, error: `Cannot retrieve item with ID ${formVals.get('id')}` });
    } else if (!storedItem.get(itemKeys.IS_PLASTIC_FIELD)) {
        yield put({ type: EDIT_ITEM_END, error: 'Invalid gift card delivery type' });
    } else {
        const updatedItem = storedItem.asMutable();
        yield put(saveAddressFields(formVals));
        updatedItem.set(itemKeys.ADDRESS_ID, getAddressHash(formVals));
        updatedItem.set('previousItem', storedItem);

        
        yield call(updateItemShipping, updatedItem.asImmutable(), !!storedItem.get(itemKeys.ITEM_ACCESSORY, false));
        yield put({ type: EDIT_ITEM_END });
    }
}


export function* resetItem() {
    
    yield put(resetItemStore());
    
    yield put(resetItemDisplay());
    
    yield put(resetFieldValues());
}


export function* removeItem(action) {
    const { item } = action;
    const itemUpdates = IMap({
        id: item.get('id'),
        isRemoved: true
    });
    
    const formId = item.get('id');
    
    
    
    yield put(reset(formId));
    
    yield put(updateItem(itemUpdates));
    
    yield put(removePromoRewards());
    
    yield put(removeCustomRewardCardRecipients());
    
    yield put(applyActivePromo());
    
    yield put(removeItemDisplay(item));
    
    yield put(createNotification(ITEM_UNDO, IMap({ item }), 'removeCard'));
}


export function* undoRemoveItem(action) {
    const { item, uid } = action;
    
    yield put(removeNotification(uid));
    const itemUpdates = IMap({
        id: item.get('id'),
        isRemoved: false
    });
    
    yield put(updateItem(itemUpdates));
    
    yield put(applyActivePromo());
}


function* changeItemDisplayValue({ itemId, fieldName, value }) {
    yield put(change(itemId, fieldName, value));
}



export function* itemSagasWatcher() {
    yield takeEvery(ITEM_RESET, resetItem);
    yield takeEvery(SAVE_NEW_ITEM, saveNewItem);
    yield takeEvery(EDIT_ITEM, editItemSaga);
    yield takeEvery(EDIT_ADDRESS, editAddressSaga);
    yield takeEvery(ITEM_IS_REMOVED, removeItem);
    yield takeEvery(ITEM_UNDO_REMOVE, undoRemoveItem);
    yield takeEvery(CHANGE_ITEM_DISPLAY_VALUE, changeItemDisplayValue);
}
