
export const SET_DEFAULT_FIELD_VALUES = '@@storefront/PAYMENT/SET_DEFAULT_FIELD_VALUES';
export const SET_INITIAL_VALUES = '@@storefront/PAYMENT/SET_INITIAL_VALUES';
export const CHANGE_VALUE = '@@storefront/PAYMENT/CHANGE_VALUE';
export const REMOVE_VALUE = '@@storefront/PAYMENT/REMOVE_VALUE';
export const RESET_VALUES = '@@storefront/PAYMENT/RESET_VALUES';
export const DEFAULT_FIELD_VALUES_UPDATED = '@@storefront/PAYMENT/DEFAULT_FIELD_VALUES_UPDATED';
export const FORM_INITIALIZED = '@@storefront/PAYMENT/FORM_INITIALIZED';
export const FORM_RESET = '@@storefront/PAYMENT/FORM_RESET';
export const fieldChangedAction = fieldName => `@@storefront/PAYMENT/${fieldName}_FIELD_CHANGED`;



export const setDefaultFieldValues = (defaultValues, updateIfDirty = false) => ({
    type: SET_DEFAULT_FIELD_VALUES,
    defaultValues,
    updateIfDirty
});

export const setInitialValues = defaultValues => ({
    type: SET_INITIAL_VALUES,
    defaultValues
});

export const changeValue = (fieldName, value) => ({
    type: CHANGE_VALUE,
    fieldName,
    value
});

export const removeValue = fieldName => ({
    type: REMOVE_VALUE,
    fieldName
});

export const resetFieldValues = () => ({
    type: RESET_VALUES
});

export const fieldChanged = (fieldName, newValue) => ({
    type: fieldChangedAction(fieldName),
    newValue
});

export const formInitialized = () => ({
    type: FORM_INITIALIZED
});

export const formReset = () => ({
    type: FORM_RESET
});
