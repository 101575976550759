import {
    call,
    put,
    select,
    takeLatest,
    race
} from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { logError } from '../utils/errorUtils';
import {
    getPaypalClientID
} from '../brand/brandSelectors';
import { getPaypalLocaleCode } from './paypalSelectors';
import {
    importPayPalLocaleMap,
    loadPaypal,
    paypalLoaded,
    paypalAuthFailed,
    START_PAYPAL_LOAD
} from './paypalModule';
import { trackPaymentPerformance } from '../payment/paymentModule';


export function* paypalLoaderSaga() {
    const payPalLocaleMap = (yield call(importPayPalLocaleMap)).default;
    const localeCode = (yield select(getPaypalLocaleCode))(payPalLocaleMap);
    const paypalId = yield select(getPaypalClientID);
    try {
        yield put(trackPaymentPerformance('paypal_sdk_loading:started'));
        
        
        
        const PAYPAL_LOAD_TIMEOUT_THRESHOLD = 5; 
        const { timeout, response } = yield race({
            timeout: delay(PAYPAL_LOAD_TIMEOUT_THRESHOLD * 1000),
            response: call(loadPaypal, paypalId, localeCode)
        });

        if (timeout && !response) {
            yield put(trackPaymentPerformance('paypal_sdk_loading: timeout due to taking longer time to load sdk'));
            yield put(paypalAuthFailed());
        }
    } catch (event) {
        logError('Error loading PayPal SDK', { paypalId });
        yield put(paypalAuthFailed());
    }
    yield put(trackPaymentPerformance('paypal_sdk_loading:completed'));
    yield put(paypalLoaded());
}

export function* watchPaypalSagas() {
    yield takeLatest(START_PAYPAL_LOAD, paypalLoaderSaga);
}
