import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, intlShape } from 'react-intl';
import { SelectGroup } from 'cstar-react-primitives/lib/SelectGroup'; 
import { FieldLayoutThreeColumn } from 'cstar-react-primitives/lib/layout/FieldLayout'; 

import {
    hourOptions12,
    minuteOptions,
    meridiemOptions,
    meridiemOptionsJP
} from './timeData';


export const timeSelectMessages = defineMessages({
    hourFieldLabel: {
        id: 'field.timeSelectMessages.hour.label',
        description: 'the field label for selecting an hour increment to deliver the gift card',
        defaultMessage: 'Hour'
    },
    minuteFieldLabel: {
        id: 'field.timeSelectMessages.minute.label',
        description: 'the field label for selecting a minute increment to deliver the gift card',
        defaultMessage: 'Minute'
    },
    meridiemFieldLabel: {
        id: 'field.timeSelectMessages.meridiem.label',
        description: 'the field label for selecting am or pm to deliver the gift card',
        defaultMessage: 'AM/PM'
    }
});

export const formatSendTime = (hour, minute, meridiem) => {
    const formatMinute = minute < 10 ? `0${minute}` : minute;
    return `${hour}:${formatMinute} ${meridiem}`;
};

export const parseTimeNumber = (value) => {
    if (!value) return value;
    return parseInt(value, 10);
};

export const TimeSelects = ({
    cashbotName,
    intl,
    hour,
    onHourChange,
    minute,
    onMinuteChange,
    meridiem,
    onMeridiemChange
}) => {
    const hourProps = {
        fieldName: 'hour',
        value: hour,
        label: intl.formatMessage(timeSelectMessages.hourFieldLabel),
        atts: {
            value: hour,
            onChange: event => onHourChange(Number(event.target.value))
        },
        cashbotName,
        options: hourOptions12,
        isRequiredField: false
    };
    const minuteProps = {
        fieldName: 'minute',
        value: minute,
        label: intl.formatMessage(timeSelectMessages.minuteFieldLabel),
        atts: {
            value: minute,
            onChange: event => onMinuteChange(Number(event.target.value))
        },
        cashbotName,
        options: minuteOptions,
        isRequiredField: false
    };
    const meridiemProps = {
        fieldName: 'meridiem',
        value: meridiem,
        label: intl.formatMessage(timeSelectMessages.meridiemFieldLabel),
        atts: {
            value: meridiem,
            onChange: event => onMeridiemChange(event.target.value)
        },
        cashbotName,
        options: (intl.locale === 'ja-jp') ? meridiemOptionsJP : meridiemOptions,
        isRequiredField: false
    };
    return (
        <FieldLayoutThreeColumn>
            <SelectGroup {...hourProps} doFocus />
            <SelectGroup {...minuteProps} />
            <SelectGroup {...meridiemProps} />
        </FieldLayoutThreeColumn>
    );
};


TimeSelects.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    hour: PropTypes.number.isRequired,
    onHourChange: PropTypes.func.isRequired,
    minute: PropTypes.number.isRequired,
    onMinuteChange: PropTypes.func.isRequired,
    meridiem: PropTypes.string.isRequired,
    onMeridiemChange: PropTypes.func.isRequired,
    intl: intlShape.isRequired
};

export default TimeSelects;
