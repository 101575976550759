import React, {
    useEffect,
    useState
} from 'react';

import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { getIntlLocale } from '../intl/intlSelectors';

import pluginsMessages from './pluginsMessages';

export const TwitterFeed = (props) => {
    const {
        twitterUsername,
        limit,
        width,
        height,
        intl
    } = props;

    const [timelineActive, setTimelineActive] = useState(true);
    const timeLineToggle = () => setTimelineActive(!timelineActive);

    useEffect(() => {
        const twitterscript = document.createElement('script');
        twitterscript.src = '//platform.twitter.com/widgets.js';
        twitterscript.async = true;
        twitterscript.id = 'twitter-wjs';
        document.body.appendChild(twitterscript);

        return () => {
            document.body.removeChild(twitterscript);
        };
    }, [timelineActive]);

    const languageCode = useSelector(getIntlLocale);

    const twitterFeedHeader = intl.formatMessage(
        pluginsMessages.twitterFeedHeader, { twitterUsername }
    );

    return (
        <div id="twitterFeedPlugin">
            <h6>
                { twitterFeedHeader }
                &nbsp;
                <button
                    type="button"
                    onClick={timeLineToggle}
                    style={timelineActive ? { backgroundColor: '#ec0' } : { backgroundColor: '#ce0' }}
                    aria-label={timelineActive ? 'Hide tweets' : 'Show tweets'}
                >
                    { timelineActive ? '-' : '+'}
                </button>
            </h6>
            { timelineActive && (
                <div id="twitterLink">
                    <a
                        className="twitter-timeline"
                        href={`https://twitter.com/${twitterUsername}`}
                        data-tweet-limit={limit}
                        data-width={width}
                        data-height={height}
                        data-lang={languageCode}
                    >
                        {`Tweets by @${twitterUsername}`}
                    </a>
                </div>
            )}
        </div>
    );
};

TwitterFeed.defaultProps = {
    twitterUsername: '',
    limit: null,
    width: null,
    height: null
};

TwitterFeed.propTypes = {
    twitterUsername: PropTypes.string,
    limit: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    intl: intlShape.isRequired
};

export default injectIntl(TwitterFeed);
