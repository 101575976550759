import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getModalDisplay } from './modalSelectors';
import ModalWrapper from './ModalWrapper';



export const PureModal = ({
    isOpen,
    modalId,
    children,
    headerText,
    cashbotName,
    contentLabel,
    handleCloseModal
}) => {
    const modalProps = {
        cashbotName,
        headerText,
        contentLabel,
        isOpen,
        handleCloseModal
    };
    return (
        <ModalWrapper {...modalProps}>
            { children }
        </ModalWrapper>
    );
};

PureModal.defaultProps = {
    isOpen: false,
    handleCloseModal: () => {}
};

PureModal.propTypes = {
    isOpen: PropTypes.bool, 
    modalId: PropTypes.string.isRequired, 
    children: PropTypes.node.isRequired,
    headerText: PropTypes.object.isRequired,
    cashbotName: PropTypes.string.isRequired,
    contentLabel: PropTypes.object.isRequired,
    handleCloseModal: PropTypes.func
};

export const mapStateToProps = (state, ownProps) => {
    const { modalId } = ownProps;
    return {
        isOpen: getModalDisplay(state, modalId)
    };
};

const ConnectedModal = connect(mapStateToProps)(PureModal);

export default ConnectedModal;
