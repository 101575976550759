import React, { useEffect } from 'react';
import { List as IList } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

import { RadioGroup } from 'cstar-react-primitives/lib/microFields/RadioGroup';

import { AddressDisplay } from '../address/AddressDisplay';


export const ReusableAddressRadio = ({
    addresses,
    useSelectedAddress
}) => {
    const radioOptions = addresses.map((address, addressID) => ({
        value: addressID,
        children: (<AddressDisplay address={address} hidePhoneNumber hideRecipientName inlineCountry />)
    })).toList();

    const defaultAddressID = radioOptions.size ? radioOptions.get(0).value : null;
    if (defaultAddressID) {
        
        useEffect(() => useSelectedAddress(addresses.get(defaultAddressID)), []);
    }
    const radioGroupProps = {
        name: 'reusable-address-radio-group',
        cashbotName: 'reusable-address-picker',
        legend: 'reusable addresses',
        styleType: 'picker',
        containerClass: 'shipping-address-picker',
        radioButtonClass: 'shipping-address-picker--cell',
        radioOptions: radioOptions.toJS(),
        defaultValue: defaultAddressID,
        handleChange: addressID => useSelectedAddress(addresses.get(addressID))
    };

    return (
        <RadioGroup {...radioGroupProps} />
    );
};

ReusableAddressRadio.defaultProps = {
    addresses: IList()
};

ReusableAddressRadio.propTypes = {
    addresses: ImmutablePropTypes.orderedMap,
    useSelectedAddress: PropTypes.func.isRequired
};

export default ReusableAddressRadio;
