
export const isEmptyString = value => !value || !value.trim().length;

const isEmptyRule = errorMessage => (value) => {
    if (isEmptyString(value)) {
        return errorMessage;
    }
    return null;
};

export default isEmptyRule;
