import { Map as IMap } from 'immutable';


export const SET_SENDER_OPTIN = '@@storefront/SET_SENDER_OPTIN';
export const SET_SELF_BUY_SMS_DELIVERY = '@@storefront/SET_SELF_BUY_SMS_DELIVERY';




const initialState = IMap();

export const senderReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SENDER_OPTIN:
            return state.set('optIn', action.value);
        case SET_SELF_BUY_SMS_DELIVERY:
            return state.set(SET_SELF_BUY_SMS_DELIVERY, action.data);
        default:
    }
    return state;
};

export const setSenderOptin = value => ({
    type: SET_SENDER_OPTIN,
    value
});



export const setSelfBuySMSDelivery = update => ({
    type: SET_SELF_BUY_SMS_DELIVERY,
    data: update
});
