import { catalogsLoadedAndSoldOut } from '../catalogs/catalogSelectors';



const soldOutStepConfig = {
    component: () => import('./SoldOut'),
    canLoad: state => ({
        
        
        isAllowedOrPath: catalogsLoadedAndSoldOut(state) || '/store'
    })
};

export default soldOutStepConfig;
