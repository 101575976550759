import { call, takeEvery, put } from 'redux-saga/effects';

import { SET_LOCALE, INITIALIZE_LOCALE } from '../intl/intlModule';
import { updateUploadcareLocaleComplete } from './uyoModule';

export const importUploadcare = async () => import('uploadcare-widget');


const getLanguage = locale => locale.split('-')[0];


const createLocaleCheck = (locale) => {
    if (getLanguage(locale) === 'de') {
        return {
            dialog: {
                close: 'Schließen',
                openMenu: 'Menü öffnen',
                preview: 'preview',
                done: 'Schließen',
                showFiles: 'Dateien anzeigen',
                tabs: {
                    preview: {
                        unknownName: 'nicht bekannt',
                        change: 'Abbrechen',
                        back: 'Zurück',
                        done: 'Hinzufügen',
                        unknown: {
                            title: 'Upload läuft… Bitte warten Sie auf die Vorschau.',
                            done: 'Vorschau überspringen und Datei annehmen'
                        },
                        image: {
                            title: 'Nur Bilder sind akzeptiert.',
                            text: 'Bitte veruschen Sie es erneut mit einer anderen Datei.',
                            change: 'Abbrechen'
                        },
                        crop: {
                            title: 'Dieses Bild zuschneiden und hinzufügen',
                            done: 'Hinzufügen',
                            free: 'frei'
                        }
                    }
                }
            }
        };
    }
    return getLanguage(locale);
};


export function initUploadcareLocale({ locale }) {
    window.UPLOADCARE_LOCALE = getLanguage(locale);
    const localeCheck = createLocaleCheck(locale);
    window.UPLOADCARE_LOCALE_TRANSLATIONS = localeCheck;
}

export function* updateUploadcareLocale({ locale }) {
    const uploadcare = (yield call(importUploadcare)).default;
    if (getLanguage(locale) === 'de') {
        yield takeEvery(INITIALIZE_LOCALE, initUploadcareLocale);
        uploadcare.plugin((uploadcareWidget) => {
            uploadcareWidget.locale.rebuild({
                locale: getLanguage(locale),
                localeTranslations: {
                    dialog: {
                        close: 'Schließen',
                        openMenu: 'Menü öffnen',
                        preview: 'preview',
                        done: 'Schließen',
                        showFiles: 'Dateien anzeigen',
                        tabs: {
                            preview: {
                                unknownName: 'nicht bekannt',
                                change: 'Abbrechen',
                                back: 'Zurück',
                                done: 'Hinzufügen',
                                unknown: {
                                    title: 'Upload läuft… Bitte warten Sie auf die Vorschau.',
                                    done: 'Vorschau überspringen und Datei annehmen'
                                },
                                image: {
                                    title: 'Nur Bilder sind akzeptiert.',
                                    text: 'Bitte veruschen Sie es erneut mit einer anderen Datei.',
                                    change: 'Abbrechen'
                                },
                                crop: {
                                    title: 'Dieses Bild zuschneiden und hinzufügen',
                                    done: 'Hinzufügen',
                                    free: 'frei'
                                }
                            }
                        }
                    }
                }
            });
        });
    } else {
        uploadcare.plugin((uploadcareWidget) => {
            uploadcareWidget.locale.rebuild({ locale: getLanguage(locale) });
        });
    }

    yield put(updateUploadcareLocaleComplete());
}


export function* updateUYOForLanguageWatcher() {
    yield takeEvery(INITIALIZE_LOCALE, initUploadcareLocale);
    yield takeEvery(SET_LOCALE, updateUploadcareLocale);
}
