import React, { useEffect } from 'react';
import { Map as IMap } from 'immutable';
import Big from 'big.js';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { paymentStatus } from '../payment/paymentSelectors';
import { getIntlLocale } from '../intl/intlSelectors';
import { getBackedUpData } from './paymentPlusSelectors';
import { LoadingIndicator } from '../primitive/LoadingIndicator';
import { useFlow } from '../routing/FlowProvider';
import {
    processPayment,
    PAYMENT_METHODS,
    PAYMENT_STATUS,
    setConsumerFees,
    setGrandTotal,
    setActivationFeesTotal,
    setUyopFeesTotal,
    setActivationFeesCount,
    setCheckoutId,
    setConfirmationUrlParams,
    trackPaymentPerformance
} from '../payment/paymentModule';
import { clearBackupData } from './paymentPlusModule';


const restoreBackedUpData = (dispatch, backup) => {
    dispatch(setConsumerFees(backup.fees));
    dispatch(setGrandTotal(new Big(backup.grandTotal)));
    dispatch(setActivationFeesTotal(backup.activationFeesTotal));
    dispatch(setUyopFeesTotal(backup.uyopFeesTotal));
    dispatch(setActivationFeesCount(backup.activationFeesCount));
    dispatch(setCheckoutId(backup.checkoutId));
    dispatch(setConfirmationUrlParams(backup.paymentUrlParams));
    
    dispatch(clearBackupData());
};

export const RedirectHandler = (props) => {
    const {
        location: { search },
        submitAction,
        status,
        backedUpData,
        restoreBackupAction,
        stepName,
        selectedLocale,
        trackPaymentPerformanceAction
    } = props;

    const { goToNextStep } = useFlow();

    const params = new URLSearchParams(search);
    const authResponse = {
        decision: params.get('decision'),
        authorizationId: params.get('authorizationId'),
        caseId: params.get('caseId'),
        approvedAmount: params.get('approvedAmount')
    };
    const hmac = params.get('signature');

    useEffect(() => {
        
        if (status === PAYMENT_STATUS.get('FAILED')) {
            trackPaymentPerformanceAction('Order failed for redirect flow', { authResponse });
            goToNextStep(stepName, {
                shouldPushHistory: false,
                overwritingHistoryParams: { search: `?locale=${selectedLocale}` }
            });
        }
    }, [status]);

    useEffect(() => {
        
        trackPaymentPerformanceAction('Back from redirect flow', { authResponse });
        trackPaymentPerformanceAction(
            'Redirect flow data logger',
            { authResponse, backedUpData, backedUpDataToJS: backedUpData ? backedUpData.toJS() : null }
        );
        
        
        const backup = backedUpData.toJS();
        restoreBackupAction(backup);
        submitAction(PAYMENT_METHODS.paymentPlus, {
            paymentData: { response: authResponse, hmac },
            ...(backup.postValidateResponse ? backup.postValidateResponse.otherInformation : {})
        });
    }, []);

    return (
        status === PAYMENT_STATUS.get('ORDERING')
            ? <LoadingIndicator overlay /> : null
    );
};

RedirectHandler.defaultProps = {
    status: '',
    backedUpData: IMap()
};

RedirectHandler.propTypes = {
    submitAction: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    backedUpData: PropTypes.instanceOf(IMap),
    stepName: PropTypes.string.isRequired,
    status: PropTypes.string,
    restoreBackupAction: PropTypes.func.isRequired,
    selectedLocale: PropTypes.string.isRequired,
    trackPaymentPerformanceAction: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    status: paymentStatus(state),
    backedUpData: getBackedUpData(state),
    selectedLocale: getIntlLocale(state)
});

const mapDispatchToProps = dispatch => ({
    submitAction: (paymentMethod, paymentData) => dispatch(processPayment(paymentMethod, paymentData)),
    restoreBackupAction: backup => restoreBackedUpData(dispatch, backup),
    trackPaymentPerformanceAction: (triggeredBy, data = {}) => dispatch(trackPaymentPerformance(triggeredBy, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(RedirectHandler);
