import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { NumberStepper } from 'cstar-react-primitives/lib/redux-form/NumberStepper';
import { FieldValidationMessage } from 'cstar-react-primitives/lib/redux-form/microFields/FieldValidationMessage';
import { getAllFieldElementIds, getValidationProps } from 'cstar-react-primitives/lib/redux-form/microFields/fieldUtils';

import {
    fixDecimalFormat,
    isDecimal,
    isIncomplete,
    normalizeInternationalCurrency
} from '../utils/numberUtils';
import { getCurrencySymbol } from '../intl/getCurrencySymbol';
import { currencyFormatConfig, currencyIntegerFormatConfig } from '../intl/intlModule';

import amountMessages from './amountMessages';



export const customAmountNumberFormatter = (intl, number, currency, grouping = false) => {
    if (!number) {
        return '';
    }

    let denomination = number.toString();

    let formatConfig = {
        style: 'currency',
        currency,
        useGrouping: grouping,
        ...currencyFormatConfig
    };
    const numberFraction = denomination.split('.');
    if (isIncomplete(denomination)) {
        if (denomination === '.') {
            denomination = `0${number}`;
        }
        formatConfig.minimumFractionDigits = 1;
    } else if (!isDecimal(denomination)) {
        formatConfig = { ...formatConfig, ...currencyIntegerFormatConfig };
    }

    return new Intl.NumberFormat(intl.locale, formatConfig)
        .formatToParts(denomination).map(({ type, value }) => {
            switch (type) {
                case 'fraction': return (value.length > numberFraction[1].length ? '' : value);
                case 'currency': return '';
                case 'literal': return '';
                case 'group': return (grouping ? value : '');
                default: return value;
            }
        }).reduce((string, part) => string + part);
};

const CustomAmount = ({
    intl,
    label,
    incLabel,
    decLabel,
    input,
    meta,
    cashbotName,
    hideAmountInput,
    currencyCode
}) => {
    const fieldIds = getAllFieldElementIds(input.name);
    const validationProps = getValidationProps(meta, input);

    const displayError = () => (
        <div className="field-group--center">
            <FieldValidationMessage
                id={fieldIds.validation.id}
                fieldName={input.name}
                validationProps={validationProps}
            />
        </div>
    );
    const stringVal = String(input.value);
    const incValue = 5;
    
    const length = stringVal ? stringVal.length * 1.5 : 1;
    const currencySymbol = getCurrencySymbol(intl, currencyCode);
    const helperText = intl.formatMessage(amountMessages.customAmountHelper, { interval: incValue });

    const amountFieldProps = {
        input,
        atts: {
            type: 'tel',
            maxLength: 8,
            id: fieldIds.input.id,
            style: { width: `${length}ch` }
        },
        meta,
        label,
        incLabel,
        decLabel,
        helper: helperText,
        hiddenHelper: true,
        cashbotName,
        formatValue: fixDecimalFormat,
        hiddenLabel: true,
        showValidationErrors: true,
        isCentered: true,
        incValue,
        usePlusMinus: true,
        prefix: currencySymbol.prefix,
        suffix: currencySymbol.suffix,
        light: true,
        normalizeValue: normalizeInternationalCurrency(intl, currencyCode)
    };

    if (hideAmountInput) {
        
        return displayError();
    }
    return (
        <div className='denom__custom-amount'>
            <NumberStepper {...amountFieldProps} doFocus />
        </div>
    );
};

CustomAmount.defaultProps = {
    hideAmountInput: false
};

CustomAmount.propTypes = {
    intl: intlShape.isRequired,
    label: PropTypes.string.isRequired,
    incLabel: PropTypes.string.isRequired,
    decLabel: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    cashbotName: PropTypes.string.isRequired,
    hideAmountInput: PropTypes.bool,
    currencyCode: PropTypes.string.isRequired
};

export default CustomAmount;
