import React from 'react';
import PropTypes from 'prop-types';

export const TabPanel = ({
    children,
    tabindex,
    id,
    ariaLabelledBy
}) => (
    <div role="tabpanel" tabIndex={tabindex} id={id} aria-labelledby={ariaLabelledBy}>
        {children}
    </div>
);

TabPanel.propTypes = {
    children: PropTypes.object.isRequired,
    tabindex: PropTypes.number,
    id: PropTypes.string,
    ariaLabelledBy: PropTypes.string
};

TabPanel.defaultProps = {
    tabindex: -1,
    id: 'tabpanel',
    ariaLabelledBy: ''
};

export default TabPanel;
