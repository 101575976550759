import { fromJS } from 'immutable';
import defaultFlowConfig from './flows/defaultFlowConfig';
import stepConfigs from './flows/stepConfigs';



export const FLOW_STORE_NAME = 'flow';


export const SET_FLOW_CONFIG = '@@storefront/SET_FLOW_CONFIG';
export const SET_STEP_CONFIGS = '@@storefront/SET_STEP_CONFIGS';
export const RESTART_BUY_FLOW = '@@storefront/RESTART_BUY_FLOW';
export const FLOW_PARAMS_UPDATED = '@@storefront/FLOW_PARAMS_UPDATED';
export const ORDER_COMPLETE = '@@storefront/ORDER_COMPLETE';
export const BUNDLE_PURCHASE = '@@storefront/BUNDLE_PURCHASE';
export const SHOW_APP_ERROR = '@@storefront/SHOW_APP_ERROR';
export const FAQ_VIEWED = '@@storefront/FAQ_VIEWED';
export const SELFIE_LINK_CLICKED = '@@storefront/SELFIE_LINK_CLICKED';

export const initialState = fromJS({
    flowConfig: defaultFlowConfig,
    stepConfigs
});


export default function flowReducer(state = initialState, action) {
    switch (action.type) {
        case SET_FLOW_CONFIG:
            return state.set('flowConfig', action.flowConfig);
        case SET_STEP_CONFIGS:
            return state.set('stepConfigs', action.stepConfigs);
        default:
            return state;
    }
}




export function setFlowConfig(flowConfig) {
    return {
        type: SET_FLOW_CONFIG,
        flowConfig
    };
}


export function setStepConfigs(configs) {
    return {
        type: SET_STEP_CONFIGS,
        configs
    };
}


export function flowParamsUpdated() {
    return { type: FLOW_PARAMS_UPDATED };
}


export function restartBuyFlow() {
    return { type: RESTART_BUY_FLOW };
}


export function orderComplete(orderDetails, segmentData) {
    return {
        type: ORDER_COMPLETE,
        orderDetails,
        meta: {
            segment: {
                event: 'track',
                eventAction: 'Order Completed',
                eventData: segmentData
            }
        }
    };
}


export function bundlePurchase(eventData) {
    return {
        type: BUNDLE_PURCHASE,
        meta: {
            segment: {
                event: 'track',
                eventAction: 'Bundle Purchase',
                eventData
            }
        }
    };
}


export function showAppError(errorType) {
    return {
        type: SHOW_APP_ERROR,
        errorType
    };
}


export function setFAQViewed(eventData) {
    return {
        type: FAQ_VIEWED,
        meta: {
            segment: {
                event: 'track',
                eventAction: 'FAQ Viewed',
                eventData
            }
        }
    };
}


export function setSelfieLinkClicked(eventData) {
    return {
        type: SELFIE_LINK_CLICKED,
        meta: {
            segment: {
                event: 'track',
                eventAction: 'SELFIE LINK CLICKED',
                eventData
            }
        }
    };
}
