import { fromJS, Map as IMap } from 'immutable';
import itemMessages from '../item/itemMessages';
import isProfane from '../validate/isProfane';
import { MESSAGE_FIELD } from '../item/newItemForm';
import validateFieldsByRules from '../validate/ruleValidator';

const validate = (values, props) => {
    const {
        intl,
        isProfanityDetectionEnabled,
        profanityFlag,
        setProfanityFlag
    } = props;
    let constraints = IMap();
    constraints = constraints.merge(fromJS({
        [MESSAGE_FIELD]: [
            isProfane(
                itemMessages.profaneMessage, isProfanityDetectionEnabled, profanityFlag, setProfanityFlag
            )
        ]
    }));
    return validateFieldsByRules(values, constraints, intl);
};

const warn = (values, props) => {
    const { maxLength, intl } = props;
    const warning = {};
    const maxWarn = intl.formatMessage(
        itemMessages.messageWarnNearMax,
        { maxLength }
    );
    const maxLimit = intl.formatMessage(
        itemMessages.messageWarnAtMax,
        { maxLength }
    );
    if (values.get(MESSAGE_FIELD)) {
        if (values.get(MESSAGE_FIELD).length === (maxLength)) {
            warning[MESSAGE_FIELD] = maxLimit;
        } else if (values.get(MESSAGE_FIELD).length >= (maxLength - 10)) {
            warning[MESSAGE_FIELD] = maxWarn;
        }
    }
    return warning;
};

export { validate, warn };
