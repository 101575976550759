import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Markdown from 'markdown-to-jsx';

import windowSize from '../utils/windowSize';
import { getPromoBanners } from '../promo/promoSelectors';
import PromoTerms from './PromoTerms';
import { isMobileView } from '../utils/mobileUtils';




export const PromoContainer = ({
    banners,
    windowWidth
}) => {
    if (!banners) {
        return null;
    }

    const banner = isMobileView(windowWidth) ? banners.small : banners.large;

    return (
        <div className="promo-container" role="complementary">
            <Markdown>{banner}</Markdown>
            <PromoTerms />
        </div>
    );
};

PromoContainer.defaultProps = {
    banners: null
};

PromoContainer.propTypes = {
    banners: PropTypes.object,
    windowWidth: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
    banners: getPromoBanners(state)
});

const PromoBanner = windowSize(PromoContainer);

export default connect(mapStateToProps)(PromoBanner);
