import { fromJS, Map as IMap } from 'immutable';
import isEmptyRule from '../validate/isEmpty';
import isProfaneRule from '../validate/isProfane';
import validateFieldsByRules from '../validate/ruleValidator';
import { giftCardFieldNames, MESSAGE_FIELD } from '../item/newItemForm';
import itemMessages from '../item/itemMessages';
import addressFields from '../addressFields/addressFieldMessages';

export const isNameValidRule = errorMessage => (name) => {
    const pattern = /^[^#$%^()<>?@}{~:]*$/;

    if (!pattern.test(name)) {
        return errorMessage;
    }
    return null;
};

const recipientValidation = (values, props) => {
    const {
        intl,
        isProfanityDetectionEnabled,
        profanityFlag,
        setProfanityFlag
    } = props;
    const isSelfBuy = values.get('isSelfBuy');
    let constraints = IMap();
    if (!isSelfBuy) {
        constraints = constraints.merge(fromJS({
            [giftCardFieldNames.RECIPIENT_NAME_FIELD]: [
                isEmptyRule(itemMessages.recipientNameInvalid),
                isProfaneRule(
                    itemMessages.profaneRecipientName,
                    isProfanityDetectionEnabled,
                    profanityFlag,
                    setProfanityFlag
                ),
                isNameValidRule(addressFields.COMMON.invalidCharacterUsed)
            ],
            [giftCardFieldNames.SENDER_NAME_FIELD]: [
                isEmptyRule(itemMessages.senderNameInvalid),
                isProfaneRule(
                    itemMessages.profaneSenderName,
                    isProfanityDetectionEnabled,
                    profanityFlag,
                    setProfanityFlag
                ),
                isNameValidRule(addressFields.COMMON.invalidCharacterUsed)
            ],
            [MESSAGE_FIELD]: [
                isProfaneRule(
                    itemMessages.profaneMessage, isProfanityDetectionEnabled, profanityFlag, setProfanityFlag
                )
            ]
        }));
    } else {
        return {};
    }
    return validateFieldsByRules(values, constraints, intl);
};
export default recipientValidation;
