import { defineMessages } from 'react-intl';

const paymentMessages = defineMessages({
    mainTitle: {
        id: 'payment.paymentMethodMessages.mainTitle',
        description: 'Top-level header of the payment method step',
        defaultMessage: 'Choose Your Payment'
    },
    payWithCreditCardButton: {
        id: 'payment.paymentMethodMessages.payWithCreditCardButton',
        description: 'Button to select credit card as the payment method',
        defaultMessage: 'Credit/Debit Card'
    },
    submitButtonText: {
        id: 'payment.paymentMethodMessages.submitButtonText',
        description: 'Text shown on preceding steps on buttons which will move the user to the payment method step',
        defaultMessage: 'Checkout'
    }
});

export default paymentMessages;
