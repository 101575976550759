import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getIsBrandFirstData } from '../brand/brandSelectors';

import CashstarLogo from './CashstarLogo';
import Trustwave from './Trustwave';
import FirstData from './FirstData';


export const Logos = ({
    isFirstData
}) => (
    <div className="footer-logos">
        <CashstarLogo />
        { isFirstData && <FirstData /> }
        <Trustwave />
    </div>
);

Logos.defaultProps = {
    isFirstData: false
};

Logos.propTypes = {
    isFirstData: PropTypes.bool
};

export const mapStateToProps = state => ({
    isFirstData: getIsBrandFirstData(state)
});

export default connect(mapStateToProps)(Logos);
