import { fromJS } from 'immutable';
import { RETRIEVED_BRAND_CONFIG } from 'capi/redux';

import getNowTimestamp from '../utils/time/getNowTimestamp';
import { parseCatalogs } from '../catalogs/catalogUtils';
import { getBrandData } from '../app/bootstrap';


export const CATALOGS_LOADED = '@@storefront/CATALOGS_LOADED';

const getBrandInitialState = () => fromJS(getBrandData());





const brandReducer = (state = getBrandInitialState(), action) => {
    switch (action.type) {
        
        case RETRIEVED_BRAND_CONFIG.PREFERENCES: {
            const {
                notifyOffers,
                loyaltyProgram,
                physical,
                gdpr,
                cardholderAgreement,
                profanityDetectionEnabled
            } = action.data;
            return state.merge({
                preferencesConfigLoadTime: getNowTimestamp(),
                notifyOffers: fromJS(notifyOffers),
                loyaltyProgram: fromJS(loyaltyProgram),
                physical: fromJS(physical),
                gdpr: fromJS(gdpr),
                cardholderAgreement: fromJS(cardholderAgreement),
                isProfanityDetectionEnabled: profanityDetectionEnabled
            });
        }
        case RETRIEVED_BRAND_CONFIG.BALANCE: {
            const {
                balancePhoneNumber,
                balanceUrl
            } = action.data;
            return state.merge({
                balancePhoneNumber,
                balanceUrl
            });
        }
        case RETRIEVED_BRAND_CONFIG.CATALOG: {
            const {
                catalogs,
                supportsDigitalCards,
                supportsPlasticCards,
                electronicCurrencyList,
                plasticCurrencyList,
                requireRecipientEmailForPhysicalGift
            } = action.data;
            return state.merge({
                catalogs: parseCatalogs(catalogs),
                supportsDigitalCards,
                supportsPlasticCards,
                electronicCurrencyList,
                plasticCurrencyList,
                requireRecipientEmailForPhysicalGift
            });
        }
        case CATALOGS_LOADED: {
            return state.set('catalogConfigLoadTime', getNowTimestamp());
        }
        case RETRIEVED_BRAND_CONFIG.META: {
            const {
                brandLegalName,
                replyToEmailDisplayName
            } = action.data;
            return state.merge({
                brandLegalName,
                replyToEmailDisplayName
            });
        }
        case RETRIEVED_BRAND_CONFIG.PAYMENT: {
            const {
                creditCardValidationData,
                creditCardEnabled,
                applePayEnabled,
                masterpassEnabled,
                masterpassCheckoutId,
                paypalClientID,
                paypalEnv,
                allowedBillingCountries,
                allowAllInternationalBillingCountries,
                creditCardStatementName,
                excludedBillingStates,
                payViaWebDropInEnabled
            } = action.data;
            return state.merge({
                paymentConfigLoadTime: getNowTimestamp(),
                creditCardValidationData: fromJS(creditCardValidationData),
                creditCardEnabled,
                applePayEnabled,
                masterpassEnabled,
                masterpassCheckoutId,
                paypalClientID,
                paypalEnv,
                allowedBillingCountries,
                allowAllInternationalBillingCountries,
                creditCardStatementName,
                excludedBillingStates,
                paymentWidgetEnabledCurrencies: payViaWebDropInEnabled
            });
        }
        case RETRIEVED_BRAND_CONFIG.SHIPPING: {
            const {
                shippingCurrenciesByCountry,
                physicalMessageCharLimit
            } = action.data;
            return state.merge({
                shippingCurrenciesByCountry: fromJS(shippingCurrenciesByCountry),
                physicalMessageCharLimit
            });
        }
        case RETRIEVED_BRAND_CONFIG.FEATURES: {
            const {
                uploadEgcVideoEnabled,
                uploadEgcPhotoEnabled,
                uploadPlasticPhotoEnabled,
                uploadEgcPhotoBrandOverlayFaceplate,
                uploadPlasticPhotoBrandOverlayFaceplate,
                isRecipientSMSEnabled,
                isSMSDeliveryForRecipientEnabled,
                IsShareViaLinkDeliveryOptionEnabled,
                isShareViaLinkDefaultDeliveryOption,
                supportedSMSDialingCodes,
                promoBoxEnabled,
                cardAccessoriesEnabled,
                isSelfBuyDeliverySMSEnabled
            } = action.data;
            return state.merge({
                uploadEgcPhotoEnabled,
                uploadEgcVideoEnabled,
                uploadPlasticPhotoEnabled,
                uploadEgcPhotoBrandOverlayFaceplate: fromJS(uploadEgcPhotoBrandOverlayFaceplate),
                uploadPlasticPhotoBrandOverlayFaceplate: fromJS(uploadPlasticPhotoBrandOverlayFaceplate),
                isShareViaLinkDeliveryOptionEnabled: IsShareViaLinkDeliveryOptionEnabled,
                isShareViaLinkDefaultDeliveryOption,
                isRecipientSMSEnabled,
                isSMSDeliveryForRecipientEnabled,
                supportedSMSDialingCodes,
                promoBoxEnabled,
                cardAccessoriesEnabled,
                isSelfBuyDeliverySMSEnabled
            });
        }
        case RETRIEVED_BRAND_CONFIG.ACCESSORIES: {
            const {
                digitalAccessories,
                physicalAccessories
            } = action.data;
            return state.merge({
                cardAccessories: {
                    digitalAccessories,
                    physicalAccessories
                }
            });
        }
        case RETRIEVED_BRAND_CONFIG.LIMITS: {
            const {
                cartMaxLineItems,
                cartMaxNumPlasticCards
            } = action.data;
            return state.merge({
                cartMaxLineItems,
                cartMaxNumPlasticCards
            });
        }
        default:
    }
    return state;
};


export default brandReducer;
