import {
    select, take, put, takeLatest, call, actionChannel, all
} from 'redux-saga/effects';
import { List as IList } from 'immutable';
import { RETRIEVED_BRAND_CONFIG, RETRIEVED_FACEPLATE_GROUPS } from 'capi/redux';

import getNowTimestamp from '../utils/time/getNowTimestamp';
import { handleSetDefaults } from '../catalogs/catalogSagas';
import { getSupportedCardTypes } from '../catalogs/catalogSelectors';
import { requestFaceplateGroups, setFaceplateGroups } from '../faceplate/faceplateModule';
import { getCurrencyCodes } from '../item/itemSelectors';
import {
    changeValue,
    fieldChangedAction,
    removeValue
} from '../item/newItemFormModule';
import {
    IS_PLASTIC_FIELD,
    newItemProgramCode,
    PROGRAM_CODE
} from '../item/newItemForm';
import {
    getPreferredCardType,
    getPreferredCurrencyCode
} from '../routing/flowSelectors';
import { removeQueryParams, updateSearch } from '../routing/routing';
import { FLOW_STATE_INITIALIZED } from '../routing/flowProgressModule';

import {
    programLoaded,
    setProgramCode,
    programCleared,
    programChangeComplete,
    programConfigLoaded,
    CHANGE_PROGRAM,
    CLEAR_PROGRAM
} from './programModule';
import {
    getActiveProgramCode,
    getAllProgramCurrencies,
    isProgramConfigured
} from './programSelectors';



export function* rejectProgramCode() {
    yield call(programChange, null);
}

export function* isProgramCodeValid(programCode) {
    const programIsConfigured = (yield select(isProgramConfigured))(programCode);
    if (!programIsConfigured) {
        return false;
    }
    const cartCurrencies = yield select(getCurrencyCodes);
    const programCurrencies = (yield select(getAllProgramCurrencies))(programCode);
    if (!programCurrencies.isSuperset(cartCurrencies)) {
        return false;
    }
    
    const preferredCurrencyCode = yield select(getPreferredCurrencyCode);
    if (preferredCurrencyCode && !programCurrencies.includes(preferredCurrencyCode)) {
        return false;
    }
    return true;
}


export function* validateActiveProgramCode(forceDataChange = false) {
    const activeProgramCode = yield select(getActiveProgramCode);
    if (activeProgramCode) {
        const activeProgramIsValid = yield call(isProgramCodeValid, activeProgramCode);
        if (!activeProgramIsValid) {
            yield call(rejectProgramCode);
        } else {
            const newItemProgramCodeValue = yield select(newItemProgramCode);
            if (forceDataChange || activeProgramCode !== newItemProgramCodeValue) {
                yield call(programDataChange, activeProgramCode);
            }
        }
    } else {
        yield put(removeValue(PROGRAM_CODE));
    }
    
    yield put(programLoaded());
}

const IS_PLASTIC_FIELD_CHANGED = fieldChangedAction(IS_PLASTIC_FIELD);


export function* programChange(programCode) {
    yield put(setProgramCode(programCode));

    if (programCode) {
        yield call(updateSearch, { programCode });
    } else {
        yield call(removeQueryParams, ['programCode']);
    }

    yield call(programDataChange, programCode);
}


export function* programDataChange(programCode) {
    
    const faceplatesRetrievedChannel = yield actionChannel(RETRIEVED_FACEPLATE_GROUPS);
    
    yield put(setFaceplateGroups(IList()));
    yield put(requestFaceplateGroups(programCode));
    yield take(faceplatesRetrievedChannel);
    faceplatesRetrievedChannel.close();

    const programSupportedCardTypes = yield select(getSupportedCardTypes);
    const supportsPlastic = programSupportedCardTypes.get('supportsPlasticCards');
    const supportsDigital = programSupportedCardTypes.get('supportsDigitalCards');

    
    
    const preferredCardType = yield select(getPreferredCardType);
    
    const plasticChangeChannel = yield actionChannel(IS_PLASTIC_FIELD_CHANGED);
    const defaultCardType = (preferredCardType !== null && programCode == null)
        ? preferredCardType : (supportsPlastic && !supportsDigital);
    yield put(changeValue(IS_PLASTIC_FIELD, defaultCardType));

    if (programCode) {
        yield put(changeValue(PROGRAM_CODE, programCode));
    } else {
        yield put(changeValue(PROGRAM_CODE, null));
        yield put(removeValue(PROGRAM_CODE));
    }

    yield take(plasticChangeChannel);
    plasticChangeChannel.close();

    yield call(handleSetDefaults);
    yield put(programChangeComplete());
}


export function* validateAndChangeProgram({ programCode }) {
    const programIsValid = yield call(isProgramCodeValid, programCode);
    yield put(programLoaded());
    if (programIsValid) {
        yield call(programChange, programCode);
    } else {
        
        
        yield call(rejectProgramCode, programCode);
    }
}


export function* clearProgram() {
    const activeProgramCode = yield select(getActiveProgramCode);
    if (activeProgramCode) {
        yield call(validateAndChangeProgram, { programCode: null });
    }
    yield put(programCleared());
}


export function* programSagasWatcher() {
    
    
    const clearRetrievedChannel = yield actionChannel(CLEAR_PROGRAM);
    const changeRetrievedChannel = yield actionChannel(CHANGE_PROGRAM);
    const actionsToAwait = {
        catalog: take(RETRIEVED_BRAND_CONFIG.CATALOG),
        programs: take(RETRIEVED_BRAND_CONFIG.PROGRAMS),
        flowInit: take(FLOW_STATE_INITIALIZED)
    };
    yield all(actionsToAwait);
    
    
    yield call(validateActiveProgramCode, true);
    
    
    
    const now = yield call(getNowTimestamp);
    yield put(programConfigLoaded(now));
    yield takeLatest(clearRetrievedChannel, clearProgram);
    yield takeLatest(changeRetrievedChannel, validateAndChangeProgram);
}
