import React from 'react';
import PropTypes from 'prop-types';

import SendTimeLink from './time/SendTimeLink';
import SendTimeLinkCancel from './time/SendTimeLinkCancel';


export const FutureSendLink = ({
    cashbotName,
    hasTimePicker,
    handleShowTimeFields,
    handleReset,
    doFocus
}) => {
    const sendLinkProps = {
        cashbotName,
        doFocus,
        onClick: handleShowTimeFields
    };
    const cancelLinkProps = {
        cashbotName,
        handleReset
    };

    if (hasTimePicker) {
        return <SendTimeLinkCancel {...cancelLinkProps} />;
    }
    return <SendTimeLink {...sendLinkProps} />;
};

FutureSendLink.defaultProps = {
    hasTimePicker: false,
    doFocus: false
};

FutureSendLink.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    hasTimePicker: PropTypes.bool,
    handleShowTimeFields: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    doFocus: PropTypes.bool
};

export default FutureSendLink;
