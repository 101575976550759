import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import classNames from 'classnames/dedupe';

import {
    Transition,
    transitionConfigs
} from 'cstar-react-primitives/lib/redux-form/animate/Transition';
import { ButtonWrapper } from 'cstar-react-primitives/lib/redux-form/buttons/ButtonWrapper';
import { icons } from 'cstar-react-primitives/lib/redux-form/icons/iconConst';
import { getFilteredPaidCardsCount } from '../item/itemSelectors';
import cartMessages from './cartMessages';
import headerMessages from '../header/headerMessages';



export const CardCount = ({ number }) => {
    const iconClass = classNames('card-count', {
        'card-count--md': number > 9 && number < 100,
        'card-count--lg': number > 99
    });

    return (
        <Transition config={transitionConfigs.popConfig}>
            { number > 0 && <span className={iconClass} key={`icon-${number}`}>{number}</span> }
        </Transition>
    );
};

CardCount.propTypes = {
    number: PropTypes.number.isRequired
};


export const CartIcon = (props) => {
    const { numberOfCards, clickHandler, intl } = props;
    const cashbotName = 'cart-link';
    const cartIconProps = {
        cashbotName,
        ariaLabel: intl.formatMessage(cartMessages.cartIconLabel, { numberOfCards }),
        cssClass: 'cart-icon',
        onClick: clickHandler
    };
    return (
        <div className="cart-icon-wrapper">
            <ButtonWrapper {...cartIconProps}>
                {icons.CART_ICON}
                <CardCount number={numberOfCards} />
            </ButtonWrapper>
            <div className="header-icon-title cart-icon-label">{intl.formatMessage(headerMessages.cartIconTitle)}</div>
        </div>
    );
};

CartIcon.defaultProps = {
    numberOfCards: 0
};

CartIcon.propTypes = {
    intl: intlShape.isRequired,
    clickHandler: PropTypes.func.isRequired,
    numberOfCards: PropTypes.number
};

const mapStateToProps = state => ({
    numberOfCards: getFilteredPaidCardsCount(state)
});


export default connect(mapStateToProps)(injectIntl(CartIcon));
