import Big from 'big.js';

import { newItemIsPlastic } from '../item/newItemForm';
import { getDigitalAccessoriesList, getPhysicalAccessoriesList, isAccessoriesEnabled } from '../brand/brandSelectors';
import { getIntlCurrencyCode } from '../intl/intlSelectors';

import accessoriesMessages from './accessoriesMessages';
import { getFormattedAmountWithCurrency } from '../utils/numberUtils';



export const getAccessoryCostDisplay = (cost, intl, currencyCode) => (
    new Big(0).eq(cost)
        ? intl.formatMessage(accessoriesMessages.free)
        : getFormattedAmountWithCurrency(intl, parseFloat(new Big(cost).toFixed(2)), currencyCode, true)
);


export const newItemIsAccessoriesStepForSelfBuy = (state) => {
    if (!isAccessoriesEnabled(state)) {
        return false;
    }
    const isPlastic = newItemIsPlastic(state);
    const accessories = isPlastic ? getPhysicalAccessoriesList(state) : getDigitalAccessoriesList(state);
    if (!(accessories.size > 0)) {
        return false;
    }
    const firstAccessory = isPlastic
        ? accessories.find(accessory => accessory.get('plasticOrdinal') === 1)
        : accessories.find(accessory => accessory.get('egcOrdinal') === 1);
    if (!firstAccessory) {
        return false;
    }
    const currentCurrency = getIntlCurrencyCode(state);
    const shouldHaveAccessoriesStep = !!firstAccessory.getIn(['costs', currentCurrency, 'cost']);
    return !!shouldHaveAccessoriesStep;
};
