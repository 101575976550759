import { getShippingMethods } from '../shipping/shippingModule';
import { giftCardFieldNames } from '../item/newItemForm';
import { brandCatalogIsLoaded, preferencesAreLoaded } from '../brand/brandSelectors';

import { deliveryStepPreConditions, deliveryStepPostConditions, deliveryStepSkippable } from './deliveryStepSelectors';
import deliveryMessages from './deliveryMessages';


const deliveryStepConfig = {
    component: () => import('./DeliveryStep'),
    flow: 'buy',
    isReady: (state) => {
        const preferencesLoaded = preferencesAreLoaded(state);
        const catalogsLoaded = brandCatalogIsLoaded(state);
        return preferencesLoaded && catalogsLoaded;
    },
    onExit: (dispatch, ownProps, values) => {
        const { isPlastic } = ownProps;

        if (isPlastic) {
            const mergedValues = values.merge({
                hasAccessory: !!values.get(giftCardFieldNames.ITEM_ACCESSORY, false)
            });
            dispatch(getShippingMethods(mergedValues, true));
        }
    },
    preConditions: deliveryStepPreConditions,
    postConditions: deliveryStepPostConditions,
    getSubmitMessage: () => deliveryMessages.submitButtonText,
    canSkip: state => (
        deliveryStepSkippable(state)
    )
};

export default deliveryStepConfig;
