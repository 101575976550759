import React from 'react';
import MdReportProblem from 'react-icons/lib/md/report-problem';
import deliveryMessages from './deliveryMessages';
import { giftCardFieldNames } from '../item/newItemForm';


const SubheaderField = field => (
    <div className="center-text">
        { field.smsDeliveryEnabled && (
            <p className="">
                { field.intl.formatMessage(deliveryMessages.emailOrSmsSubheader, { recipient_name: field.recipientName }) }
            </p>
        ) }
        <span
            id={`${giftCardFieldNames.RECIPIENT_SMS_FIELD}-input-validation`}
            className="field-msg field-msg--error email-sms-subheader"
            aria-live="polite"
            aria-relevant="additions text"
        >
            {field.meta.error && (
                <span className="field-msg__content ">
                    <span
                        id="recipientEmailConfirmation-input-icon"
                        className="field-icon-validation field-icon-validation--error"
                    >
                        <span className="field-icon-validation__content">
                            <MdReportProblem />
                        </span>
                    </span>
                    <span>
                        { field.meta.error }
                    </span>
                </span>
            )}
        </span>
    </div>
);

export default SubheaderField;
