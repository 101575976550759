import { defineMessages } from 'react-intl';

const trustwaveMessages = defineMessages({
    trustwaveLogoAlt: {
        id: 'footer.trustwaveMessages.trustWaveLogoAlt',
        description: 'Alt text for Trustwave logo image',
        defaultMessage: 'Trusted Commerce Click to validate'
    },
    trustwaveWarning: {
        id: 'footer.trustwaveMessages.trustwaveWarning',
        description: 'Trustwave right click alert message',
        defaultMessage: 'Copying Prohibited by Law - Trusted Commerce is a Service Mark of TrustWave Holdings, Inc.'
    }
});

export default trustwaveMessages;
