export const CHOOSE_FOR_ME_CLICKED = '@@storefront/CHOOSE_FOR_ME_CLICKED';


export function setChooseForMeClicked(eventData) {
    return {
        type: CHOOSE_FOR_ME_CLICKED,
        meta: {
            segment: {
                event: 'track',
                eventAction: 'Choose For Me Print Nudge Clicks',
                eventData
            }
        }
    };
}
