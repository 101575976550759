import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { LinkExternal } from 'cstar-react-primitives/lib/redux-form/LinkExternal';
import { footerFirstdata } from './footerLinksMessages';


const FirstDataSVG = ({
    title
}) => (
    <svg
        className="footer-logo-firstdata"
        data-name="firstdata"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 129.26 22.87"
        fill="currentColor"
        x="0px"
        y="0px"
        width="150px"
        height="27px"
        focusable="false"
    >
        <title>{title}</title>
        <rect x="17.04" y="6.38" width="3.71" height="16.11" />
        <path d="M31.94,9.85l-.48-.08a5,5,0,0,0-1.13-.08c-1.78,0-3.67,1-3.67,4v8.8H22.95V6.38h3.71V8.56A5.13,5.13,0,0,1,31.06,6a5.54,5.54,0,0,1,.89.08V9.85Z" />
        <path d="M42.19,10.58a5.46,5.46,0,0,0-3.27-1.29c-1.5,0-2.22.77-2.22,1.62s.29,1.57,2.58,2.14c4.2,1.09,5.62,2.31,5.62,5.05S43,22.87,38.76,22.87a9.68,9.68,0,0,1-6.51-2.34l2.06-2.83a6.14,6.14,0,0,0,4.44,1.9c1.61,0,2.34-.68,2.34-1.62s-.69-1.45-3-2.06c-3.91-1-5.17-2.63-5.17-5.17C33,8.65,34.63,6,38.91,6a8.12,8.12,0,0,1,5.25,1.82Z" />
        <path d="M55.08,19.09l1.25,3a6.49,6.49,0,0,1-3.19.77c-3.6,0-5.33-1.86-5.33-5.29V9.61H45.55V6.38h2.26V3h3.72V6.37H55.2V9.6H51.53v8c0,1.37.73,2,1.81,2a2.83,2.83,0,0,0,1.74-.53" />
        <path d="M107.21,19.09l1.25,3a6.44,6.44,0,0,1-3.15.77c-3.63,0-5.37-1.86-5.37-5.29V9.61H97.68V6.38h2.26V3h3.71V6.37h3.68V9.6h-3.68v8c0,1.37.69,2,1.82,2a2.81,2.81,0,0,0,1.74-.53" />
        <path d="M89,19.94a4.19,4.19,0,0,0,4-4.2,13,13,0,0,0-4-.65c-2.34,0-3.43,1.29-3.43,2.5,0,1.55,1.49,2.35,3.47,2.35M83.55,7.51l.53-.29a12,12,0,0,1,5-1.21c5.17,0,7.59,1.9,7.59,6.54v9.93H93V20.62c-1.05,1.5-2.54,2.22-4.93,2.22-3.92,0-6-2.14-6-5.25s2.26-5.37,6.34-5.37a17,17,0,0,1,4.65.69v-.29c0-2.22-1.09-3.23-4-3.23A7.65,7.65,0,0,0,85,10.52Z" />
        <path d="M115.73,19.94a4.19,4.19,0,0,0,4-4.2,12.77,12.77,0,0,0-4-.65c-2.34,0-3.43,1.29-3.43,2.5,0,1.55,1.49,2.35,3.47,2.35M110.32,7.51s.24-.16.48-.29A12,12,0,0,1,115.85,6c5.17,0,7.59,1.9,7.59,6.54v9.93h-3.72V20.62c-1.05,1.5-2.54,2.22-4.88,2.22-4,0-6.06-2.14-6.06-5.25s2.26-5.37,6.38-5.37a16.67,16.67,0,0,1,4.6.69v-.29c0-2.22-1.09-3.23-4-3.23a7.65,7.65,0,0,0-4.08,1.13Z" />
        <path d="M69.94,18.93c4.77,0,7.83-3.19,7.83-7.59v-.08a7.4,7.4,0,0,0-7.83-7.67H65.5V18.94h4.44ZM61.54,0h8.4c7.11,0,12,4.85,12,11.23v0c0,6.38-4.88,11.31-12,11.31h-8.4Z" />
        <polygon points="0 0 15.78 0 15.78 3.63 3.95 3.63 3.95 9.77 14.33 9.77 14.33 13.36 3.95 13.36 3.95 22.57 0 22.57 0 0" />
        <path d="M127,18.21a2.22,2.22,0,1,1,0,4.44,2.22,2.22,0,1,1,0-4.44Zm0,4.08a1.86,1.86,0,1,0-1.9-1.86A1.88,1.88,0,0,0,127,22.29Zm.08-1.54h-.48v.68h-.37v-2h.85c.4,0,.73.16.73.65a.61.61,0,0,1-.37.61l.4.76h-.4Zm-.48-1v.65h.48c.24,0,.36-.08.36-.32s-.12-.32-.36-.32h-.48Z" />
    </svg>
);

FirstDataSVG.propTypes = {
    title: PropTypes.string.isRequired
};


export const FirstData = ({ intl, url }) => {
    const title = intl.formatMessage(footerFirstdata.title);
    return (
        <LinkExternal url={url}>
            <FirstDataSVG title={title} />
        </LinkExternal>
    );
};

FirstData.defaultProps = {
    url: '//www.firstdata.com'
};

FirstData.propTypes = {
    intl: intlShape.isRequired,
    url: PropTypes.string
};


export default injectIntl(FirstData);
