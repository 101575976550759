import { fromJS } from 'immutable';

export const getModals = state => state.get('modals') || fromJS([]);


export const getModalDisplay = (state, id) => {
    const modals = getModals(state);
    if (!modals.size) return false;
    return modals.some(modal => modal.get('id') === id && modal.get('isOpen', false));
};
