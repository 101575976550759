import React from 'react';
import { Link } from 'react-router-dom';
import ExternalLinkIcon from 'react-icons/lib/go/link-external';
import PropTypes from 'prop-types';

import { LinkExternal } from 'cstar-react-primitives/lib/redux-form/LinkExternal';

export const FOOTER_CLASS = 'footer-links__a';

export const CashstarLink = ({
    linkText,
    url,
    external
}) => {
    const linkProps = {
        url,
        cssClass: FOOTER_CLASS
    };
    return (
        <li>
            {external
                ? (
                    <LinkExternal {...linkProps} atts={{ title: 'Opens in a new window' }}>
                        {linkText}
                        <ExternalLinkIcon className="footer-links__svg" />
                    </LinkExternal>
                )
                : <Link to={url} className={FOOTER_CLASS}>{linkText}</Link>
            }
        </li>
    );
};

CashstarLink.defaultProps = {
    external: true
};

CashstarLink.propTypes = {
    linkText: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    external: PropTypes.bool
};

export default CashstarLink;
