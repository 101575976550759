import { defineMessages } from 'react-intl';


const cardTypeMessages = defineMessages({
    heading: {
        id: 'cardType.cardTypeMessages.heading',
        description: 'Heading text for digital or physical gift card selection page.',
        defaultMessage: 'What kind of gift card do you want?'
    },
    digitalDescriptionHeading: {
        id: 'cardType.cardTypeMessages.digitalDescriptionHeading',
        description: 'Heading of text description for digital selection.',
        defaultMessage: 'Digital Card'
    },
    digitalDescriptionLine1: {
        id: 'cardType.cardTypeMessages.digitalDescriptionLine1',
        description: 'First list item describing digital card',
        defaultMessage: 'Send a gift card through email, text or both.'
    },
    digitalDescriptionLine2: {
        id: 'cardType.cardTypeMessages.digitalDescriptionLine2',
        description: 'Second list item describing digital card',
        defaultMessage: 'Immediate delivery (or choose your own date and time).'
    },
    digitalShareViaLinkDescriptionLine1: {
        id: 'cardType.cardTypeMessages.digitalShareViaLinkDescriptionLine1',
        description: 'First list item describing digital card with share via link enabled',
        defaultMessage: 'Share an eGift card link or send by email.'
    },
    digitalShareViaLinkDescriptionLine2: {
        id: 'cardType.cardTypeMessages.digitalShareViaLinkDescriptionLine2',
        description: 'Second list item describing digital card with share via link enabled',
        defaultMessage: 'Send it now, or schedule it for later'
    },
    physicalDescriptionHeading: {
        id: 'cardType.cardTypeMessages.physicalDescriptionHeading',
        description: 'Heading of text description for physical selection.',
        defaultMessage: 'Physical Card'
    },
    physicalDescriptionLine1: {
        id: 'cardType.cardTypeMessages.physicalDescriptionLine1',
        description: 'First list item describing physical card',
        defaultMessage: 'PLEASE NOTE: For physical gift card orders, please allow for extra delivery time due to COVID-related impacts on all shipping carriers.'
    },
    physicalDescriptionLine2: {
        id: 'cardType.cardTypeMessages.physicalDescriptionLine2',
        description: 'Second list item describing physical card',
        defaultMessage: 'Send a traditional gift card through the mail. Flexible shipping options available.'
    },
    digitalRadioGroupLegend: {
        id: 'cardType.cardTypeMessages.digitalRadioGroupLegend',
        description: 'legend for digital currency radiogroup',
        defaultMessage: 'Choose a currency'
    },
    physicalRadioGroupLegend: {
        id: 'cardType.cardTypeMessages.physicalRadioGroupLegend',
        description: 'legend for physical currency radiogroup',
        defaultMessage: 'Choose a currency'
    },
    submitButtonText: {
        id: 'cardType.cardTypeMessages.submitButtonText',
        description: 'Text shown on preceding steps on buttons which will move the user to the card type step',
        defaultMessage: 'Pick a gift card'
    }
});

export default cardTypeMessages;
