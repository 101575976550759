import React from 'react';
import PropTypes from 'prop-types';
import { Redirect as ReactRouterRedirect } from 'react-router-dom';



const Redirect = ({ pathname }) => <ReactRouterRedirect to={{ pathname, search: window.location.search }} />;

Redirect.propTypes = {
    pathname: PropTypes.string.isRequired
};

export default Redirect;
