import { createSelector } from 'reselect';
import { Map as IMap } from 'immutable';



export const getCartState = state => state.get('cart', IMap());


export const getCartStatus = createSelector(
    getCartState,
    cart => cart.get('cartStatus', null)
);


export const getCartPromoError = createSelector(
    getCartState,
    cart => cart.get('cartPromoError', null)
);


export const getCartCustomPromo = createSelector(
    getCartState,
    cart => cart.get('cartCustomPromo', '')
);
