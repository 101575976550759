const MERIDIEM = {
    AM: 'AM',
    PM: 'PM'
};


const get12HourTime = (hour) => {
    if (hour === 0) {
        return [12, MERIDIEM.AM];
    }
    if (hour < 12) {
        return [hour, MERIDIEM.AM];
    }
    if (hour === 12) {
        return [12, MERIDIEM.PM];
    }
    return [hour - 12, MERIDIEM.PM];
};

export default get12HourTime;
