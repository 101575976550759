import {
    isPaypalEnabled,
    preferencesAreLoaded,
    brandPaymentConfigIsLoaded
} from '../brand/brandSelectors';
import { isStillFetchingPromoRewards } from '../promo/promoSelectors';
import { getPaypalLoaded, startPaypalLoad } from '../paypal/paypalModule';

import { paymentStepPreConditions, paymentStepPostConditions, paymentStepSkippable } from './paymentStepSelectors';
import paymentMethodMessages from '../paymentMethod/paymentMethodMessages';



const paymentStepConfig = {
    component: () => import('./PaymentStep'),
    flow: 'postBuy',
    flowProps: (state) => {
        const paypalIsLoaded = getPaypalLoaded(state);
        return {
            paypalIsLoaded,
            paypalIsEnabled: isPaypalEnabled(state),
            paymentConfigIsLoaded: brandPaymentConfigIsLoaded(state)
        };
    },
    hideCartLink: false,
    hidePromoBanner: true,
    beforeRender: (dispatch, ownProps) => {
        const { paypalIsEnabled, paypalIsLoaded, paymentConfigIsLoaded } = ownProps;
        if (paymentConfigIsLoaded && paypalIsEnabled && !paypalIsLoaded) {
            dispatch(startPaypalLoad());
        }
    },
    isReady: (state, ownProps) => {
        const { paypalIsEnabled, paypalIsLoaded } = ownProps;
        if (paypalIsEnabled) {
            return paypalIsLoaded && preferencesAreLoaded(state) && !isStillFetchingPromoRewards(state);
        }
        return preferencesAreLoaded(state) && !isStillFetchingPromoRewards(state);
    },
    preConditions: paymentStepPreConditions,
    postConditions: paymentStepPostConditions,
    getSubmitMessage: () => paymentMethodMessages.submitButtonText,
    canSkip: state => (
        paymentStepSkippable(state)
    )
};

export default paymentStepConfig;
