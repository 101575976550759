import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromJS, Set as ISet, Map as IMap } from 'immutable';
import { LoadingIndicator } from '../primitive/LoadingIndicator';

import { getNewItemFormValues, newItemIsPlastic, newItemIsSelfBuy } from '../item/newItemForm';
import { logError } from '../utils/errorUtils';
import { uyoSelectStepCompleted } from '../uyo/select/uyoSelectStepSelectors';
import { newItemIsAccessoriesStepForSelfBuy } from '../accessories/accessoriesUtils';

import Redirect from './Redirect';
import { push } from './routing';
import {
    getFlowConfig,
    getPathStepConfig,
    isPreCartFlowSkipped
} from './flowSelectors';
import {
    getBasePath,
    getNextPath,
    getNextPathSubmitMessage,
    isUYOStep,
    PAYMENT_PATH
} from './flow';
import { setFAQViewed } from './flowModule';
import { changeStep, redirectIfDataRequirementsUnsatisfied } from './stepUtils';
import { updateFlowProgress } from './flowProgressModule';
import flowMessages from './flowMessages';
import { parseCartViewed } from '../segment/segmentSelectors';
import { getShowAppLoadingSpinner } from '../app/appSelectors';
import { getActiveProgramCatalogs, getActiveProgramCode } from '../program/programSelectors';
import { submitItem } from '../bundles/bundlesUtils';
import { getDefaultFaceplateForProgram } from '../program/programListSelectors';
import { getBrandCode } from '../brand/brandSelectors';
import NotFound from '../error/NotFound';



export class AsyncFlowStep extends React.Component {
    constructor(props) {
        super(props);

        const { component } = this.props;

        this.state = {
            Component: React.lazy(component)
        };

        this.getChildProps = this.getChildProps.bind(this);
        this.getSubmitAction = this.getSubmitAction.bind(this);
        this.handleStepChange = this.handleStepChange.bind(this);
    }

    componentDidMount() {
        const {
            loadNextStep,
            beforeRender,
            dispatch,
            submitValues,
            activeProgram,
            activeCatalogs,
            cartViewedSegment,
            getFaceplates,
            brandCode,
            redirectPath
        } = this.props;

        if (loadNextStep) {
            
            
            
            loadNextStep();
        }

        beforeRender(dispatch, this.getChildProps());
        if (redirectPath === PAYMENT_PATH) {
            if (submitValues && submitValues !== {}) {
                submitItem(
                    dispatch,
                    submitValues,
                    activeProgram,
                    activeCatalogs,
                    cartViewedSegment,
                    getFaceplates,
                    brandCode
                );
            }
        }

        
        
        this.handleStepChange();
    }

    componentDidUpdate(prevProps) {
        const {
            isReadyToRender,
            isAllowedToRender,
            match
        } = this.props;

        
        
        if (isReadyToRender !== prevProps.isReadyToRender
                || isAllowedToRender !== prevProps.isAllowedToRender
                || match.url !== prevProps.match.url) {
            this.handleStepChange();
        }
    }

    
    getChildProps() {
        const {
            component, path, flowProps, beforeRender, isReady, canLoad, onExit,
            isReadyToRender, isAllowedToRender, redirectPath, dispatch, loadNextStep,
            ...childProps
        } = this.props;

        return childProps;
    }

    
    getSubmitAction() {
        const {
            submitPath,
            cartViewedSegment,
            onExit,
            dispatch,
            activeCatalogs,
            activeProgram,
            getFaceplates,
            brandCode
        } = this.props;

        return (values) => {
            onExit(dispatch, this.getChildProps(), values);
            if (values) {
                try {
                    dispatch(updateFlowProgress(fromJS(values).keySeq()));
                
                } catch (err) {
                    
                    
                    
                }
            }

            if (submitPath === null) {
                
                
                submitItem(
                    dispatch,
                    values,
                    activeProgram,
                    activeCatalogs,
                    cartViewedSegment,
                    getFaceplates,
                    brandCode
                );
            } else {
                push({ pathname: submitPath });
            }
        };
    }

    handleStepChange() {
        const {
            path,
            dispatch,
            isReadyToRender,
            isAllowedToRender,
            location,
            match
        } = this.props;

        
        const payloadSessionID = location.state ? location.state.sessionID : null;

        if (isReadyToRender && isAllowedToRender) {
            
            const stepPath = path.replace(/\/:(\w+)/gi, (regexMatch, p1) => `/${match.params[p1]}`);

            if (match.params.docType === 'faq') {
                dispatch(setFAQViewed({}));
            }

            dispatch(changeStep(stepPath, payloadSessionID));
        }
    }

    
    render() {
        const { Component } = this.state;
        const {
            path,
            isReadyToRender,
            isAllowedToRender,
            redirectPath,
            flowProgressUpdates,
            dispatch,
            submitValues,
            loading
        } = this.props;

        if (!isReadyToRender) {
            return null;
        }

        if (isAllowedToRender) {
            return <Component {...this.getChildProps()} submitAction={this.getSubmitAction()} />;
        }

        if (!redirectPath || redirectPath === PAYMENT_PATH) {
            if (submitValues && submitValues !== {}) {
                return !loading ? <LoadingIndicator /> : null;
            }
            
            logError(`step for path /store/${path} missing redirectPath when not allowed to render!`);
            return <NotFound />;
        }

        if (flowProgressUpdates) {
            dispatch(updateFlowProgress(flowProgressUpdates));
        }
        return <Redirect pathname={redirectPath} />;
    }
}

AsyncFlowStep.defaultProps = {
    flow: null,
    submitPath: null,
    isReadyToRender: false,
    isAllowedToRender: false,
    redirectPath: null,
    dispatch: () => {},
    loadNextStep: null,
    flowProgressUpdates: null,
    submitValues: null,
    replaceHistory: false,
    cartViewedSegment: null,
    loading: false,
    activeCatalogs: null,
    getFaceplates: () => {},
    bundles: [],
    addNewBundleItem: () => {},
    activeProgram: '',
    brandCode: ''
};

AsyncFlowStep.propTypes = {
    
    component: PropTypes.func.isRequired,
    flow: PropTypes.string,
    path: PropTypes.string.isRequired,
    submitPath: PropTypes.string,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    
    flowProps: PropTypes.func.isRequired,
    beforeRender: PropTypes.func.isRequired,
    isReady: PropTypes.func.isRequired,
    canLoad: PropTypes.func.isRequired,
    canSkip: PropTypes.func.isRequired,
    onExit: PropTypes.func.isRequired,
    
    bundles: PropTypes.array,
    addNewBundleItem: PropTypes.func,
    
    isReadyToRender: PropTypes.bool,
    isAllowedToRender: PropTypes.bool,
    redirectPath: PropTypes.string,
    loadNextStep: PropTypes.func,
    flowProgressUpdates: PropTypes.instanceOf(ISet),
    submitValues: PropTypes.instanceOf(IMap),
    replaceHistory: PropTypes.bool,
    activeCatalogs: PropTypes.instanceOf(IMap),
    getFaceplates: PropTypes.func,
    
    dispatch: PropTypes.func,
    cartViewedSegment: PropTypes.object,
    loading: PropTypes.bool,
    activeProgram: PropTypes.string,
    brandCode: PropTypes.string
};

const getPropsOptions = (state, path, flow, flowProps, ownProps) => {
    const isSelfBuy = newItemIsSelfBuy(state);
    const isPlastic = newItemIsPlastic(state);
    const isUYO = uyoSelectStepCompleted(state) || isUYOStep(path);
    const hasAccessories = newItemIsAccessoriesStepForSelfBuy(state);
    const loading = getShowAppLoadingSpinner(state);

    let flowConfig = null;
    let nextPath;
    let submitPath;
    let loadNextStep;
    const branchFlags = {
        isSelfBuy,
        isPlastic,
        isUYO,
        hasAccessories
    };
    if (flow) {
        flowConfig = getFlowConfig(state).get(flow);
        nextPath = getNextPath(flowConfig, path, branchFlags);

        if (nextPath) {
            submitPath = `/${getBasePath()}/${nextPath}`;

            
            loadNextStep = getPathStepConfig(state)(nextPath).get('component');
        }
    }

    const commonFlowProps = {
        isSelfBuy,
        isPlastic,
        submitPath,
        loading
    };

    
    const propsFromFlow = flowProps(state, {
        ...commonFlowProps,
        ...ownProps
    });

    const mergedProps = {
        ...ownProps,
        ...commonFlowProps,
        ...propsFromFlow
    };

    return {
        mergedProps,
        loadNextStep,
        submitPath,
        flowConfig,
        branchFlags
    };
};

const getPropsIfSkippable = (
    state,
    submitPath,
    mergedProps,
    isReadyToRender,
    flowProgressUpdates,
    loadNextStep,
    getSubmitMessage
) => {
    if (!submitPath) {
        const submitValues = getNewItemFormValues(state);
        const replaceHistory = isPreCartFlowSkipped(state);
        return {
            ...mergedProps,
            isReadyToRender,
            isAllowedToRender: false,
            redirectPath: submitPath,
            flowProgressUpdates,
            loadNextStep,
            getSubmitMessage,
            submitValues,
            replaceHistory
        };
    }
    return {
        ...mergedProps,
        isReadyToRender,
        isAllowedToRender: false,
        redirectPath: submitPath,
        flowProgressUpdates,
        loadNextStep,
        getSubmitMessage
    };
};


export const mapStateToProps = (state, ownProps) => {
    const {
        flow,
        path,
        flowProps,
        isReady,
        canLoad,
        canSkip
    } = ownProps;
    const cartViewedSegment = parseCartViewed(state);

    const {
        mergedProps,
        loadNextStep,
        submitPath,
        flowConfig,
        branchFlags
    } = getPropsOptions(state, path, flow, flowProps, ownProps);

    let isAllowedToRender = false;
    let redirectPath = null;
    let flowProgressUpdates = null;
    let getSubmitMessage = null;

    
    const isReadyToRender = isReady(state, mergedProps);

    
    
    if (isReadyToRender && canSkip(state)) {
        const skippableProps = getPropsIfSkippable(
            state,
            submitPath,
            mergedProps,
            isReadyToRender,
            flowProgressUpdates,
            loadNextStep,
            getSubmitMessage,
        );
        
        
        skippableProps.redirectPath = !skippableProps.redirectPath ? PAYMENT_PATH : skippableProps.redirectPath;
        return {
            ...skippableProps,
            activeCatalogs: getActiveProgramCatalogs(state),
            activeProgram: getActiveProgramCode(state),
            getFaceplates: getDefaultFaceplateForProgram(state),
            brandCode: getBrandCode(state)
        };
    }

    
    if (isReadyToRender) {
        const { isAllowedOrPath, flowProgress } = canLoad(state, mergedProps);
        flowProgressUpdates = flowProgress;

        if (flow) {
            getSubmitMessage = getNextPathSubmitMessage(state, flow, flowConfig, path, branchFlags);
            redirectPath = redirectIfDataRequirementsUnsatisfied(state, flow, path, mergedProps);
        } else {
            getSubmitMessage = () => flowMessages.genericSubmitButtonText;
        }

        if (redirectPath === null) {
            if (typeof isAllowedOrPath === 'string' || typeof isAllowedOrPath === 'undefined') {
                redirectPath = isAllowedOrPath;
            } else {
                isAllowedToRender = true;
            }
        }
    }
    return {
        ...mergedProps,
        isReadyToRender,
        isAllowedToRender,
        redirectPath,
        flowProgressUpdates,
        loadNextStep,
        getSubmitMessage,
        cartViewedSegment,
        activeCatalogs: getActiveProgramCatalogs(state),
        activeProgram: getActiveProgramCode(state),
        getFaceplates: getDefaultFaceplateForProgram(state),
        brandCode: getBrandCode(state)
    };
};

export default connect(mapStateToProps)(AsyncFlowStep);
