import alphaNumericNormalizer from './alphaNumericNormalizer';


const postalCodeNormalizerNL = (value) => {
    if (!value || typeof value !== 'string') return value;
    let normalize = alphaNumericNormalizer(value).toUpperCase();
    if (normalize.length && value.length > 4) {
        normalize = `${normalize.slice(0, 4)} ${normalize.slice(4, 6)}`;
    }
    return normalize;
};

export default postalCodeNormalizerNL;
