import { Map as IMap } from 'immutable';
import { createSelector } from 'reselect';

import { messages } from '../intl/intlSelectors';

export const FOOTER_CUSTOM_LINKS_ID = 'footer.links.custom';
export const FOOTER_LINK_CASHSTAR_ID = `${FOOTER_CUSTOM_LINKS_ID}.0`;

const linkPostition = messageId => messageId.split('.')[4];



export const getAllCustomFooterLinks = createSelector(
    messages,
    msgs => msgs.filter((_, id) => id.startsWith(FOOTER_CUSTOM_LINKS_ID))
);


export const getCustomCashstarLinkMessages = createSelector(
    getAllCustomFooterLinks,
    msgs => msgs.filter((_, id) => id.startsWith(FOOTER_LINK_CASHSTAR_ID))
);


export const getCustomCashstarLinks = createSelector(
    getCustomCashstarLinkMessages,
    (msgs) => {
        const textList = msgs.filter((_, id) => id.endsWith('text')).sortBy((_, id) => linkPostition(id)).toList();
        const urlList = msgs.filter((_, id) => id.endsWith('url')).sortBy((_, id) => linkPostition(id)).toList();
        return textList.map((text, i) => IMap({
            text,
            url: urlList.get(i)
        }));
    }
);

export const getCardholderAgreementText = createSelector(
    messages,
    intlMessages => intlMessages.get('footer.cardholder.agreementText.md', null)
);
