import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/dedupe';
import {
    getAllTimezones,
    timezoneSearch,
    guessUserTimezone,
    isTimezoneMatch,
    compareTimezones
} from '@cashstar/react-timezone';



import { InputAutocompleteGroup } from 'cstar-react-primitives/lib/InputAutocompleteGroup';


export const UTC_TIMEZONE = {
    city: 'UTC',
    zoneAbbr: 'UTC',
    zoneName: 'Etc/UTC'
};
const TIMEZONE_PARTS_DELIMITER = ' - ';
const MINIMUM_CHARS_REQUIRED_TO_SEARCH = 3;

const displayTimezone = (city, zoneAbbr) => `${city}${TIMEZONE_PARTS_DELIMITER}${zoneAbbr}`;


export const formatTimezone = (timezone) => {
    let city = '';
    let zoneAbbr = '';

    if (typeof timezone === 'string') {
        const searchResults = timezoneSearch({ zoneName: timezone });

        if (!searchResults || !searchResults[0]) {
            return timezone;
        }

        [{ city, zoneAbbr }] = searchResults;
    } else if (timezone && typeof timezone === 'object') {
        ({ city, zoneAbbr } = timezone);
    }

    if (!city || !zoneAbbr) return '';

    return displayTimezone(city, zoneAbbr);
};


export const parseTimezone = (timezoneDisplay) => {
    const timeZoneSearch = timezoneDisplay || 'UTC - UTC';

    
    const delimiterStart = timeZoneSearch.indexOf(TIMEZONE_PARTS_DELIMITER);

    if (delimiterStart > -1) {
        const delimiterEnd = delimiterStart + TIMEZONE_PARTS_DELIMITER.length;
        const city = timeZoneSearch.substring(0, delimiterStart);
        const zoneAbbr = timeZoneSearch.substring(delimiterEnd);

        if (city && zoneAbbr) {
            
            const searchResults = timezoneSearch({ city, zoneAbbr });

            if (searchResults && searchResults.length && searchResults[0]) {
                
                return searchResults[0];
            }
        }
    }

    return UTC_TIMEZONE;
};


export const getTimezoneItem = ({ city, zoneName, zoneAbbr }, isHighlighted) => (
    <div
        key={`${zoneName}-${zoneAbbr}`}
        className={classNames('field-auto-option', {
            'field-auto-option__highlighted': isHighlighted
        })}
    >
        {displayTimezone(city, zoneAbbr)}
    </div>
);

getTimezoneItem.propTypes = {
    city: PropTypes.string.isRequired,
    zoneName: PropTypes.string.isRequired,
    zoneAbbr: PropTypes.string.isRequired
};

export default class TimezoneAutocomplete extends React.Component {
    constructor(props) {
        super(props);

        const { initialTimeZone } = this.props;

        this.state = {
            inputValue: formatTimezone(initialTimeZone || guessUserTimezone())
        };

        this.handleTimezoneChange = this.handleTimezoneChange.bind(this);
        this.handleTimezoneSelect = this.handleTimezoneSelect.bind(this);
    }

    handleTimezoneChange(selection) {
        this.setState({ inputValue: selection });
    }

    handleTimezoneSelect(selection) {
        const { onTimeZoneChange } = this.props;

        this.setState({ inputValue: selection });
        onTimeZoneChange(parseTimezone(selection));
    }

    render() {
        const { inputValue } = this.state;
        const {
            label,
            cashbotName,
            onTimeZoneChange,
            isRequiredField
        } = this.props;

        const timezoneChoices = getAllTimezones();

        const autoCompleteProps = {
            fieldName: 'sendTimezone',
            value: inputValue,
            label,
            cashbotName,
            isRequiredField,
            choices: timezoneChoices,
            renderItem: getTimezoneItem,
            getItemValue: item => (
                displayTimezone(item.city, item.zoneAbbr)
            ),
            shouldItemRender: (item, searchValue) => (
                isTimezoneMatch(item, searchValue, MINIMUM_CHARS_REQUIRED_TO_SEARCH)
            ),
            sortItems: compareTimezones,
            onChange: this.handleTimezoneChange,
            onSelect: this.handleTimezoneSelect,
            onBlur: () => (
                onTimeZoneChange(parseTimezone(inputValue))
            )
        };

        return (
            <InputAutocompleteGroup {...autoCompleteProps} />
        );
    }
}

TimezoneAutocomplete.defaultProps = {
    initialTimeZone: null,
    isRequiredField: true
};

TimezoneAutocomplete.propTypes = {
    label: PropTypes.string.isRequired,
    cashbotName: PropTypes.string.isRequired,
    initialTimeZone: PropTypes.object,
    onTimeZoneChange: PropTypes.func.isRequired,
    isRequiredField: PropTypes.bool
};
