import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { LinkButton } from 'cstar-react-primitives/lib/redux-form/buttons/LinkButton';

import modalMessages from './modalMessages';



export const ModalCloseLink = (props) => {
    const { handleCloseModal, linkText, cashbotName } = props;
    const linkProps = {
        onClick: (event) => {
            if (event) {
                event.preventDefault();
            }
            handleCloseModal();
        },
        cashbotName
    };
    return (
        <LinkButton {...linkProps}>
            <FormattedMessage {...linkText} />
        </LinkButton>
    );
};

ModalCloseLink.defaultProps = {
    linkText: modalMessages.close
};

ModalCloseLink.propTypes = {
    handleCloseModal: PropTypes.func.isRequired,
    linkText: PropTypes.object, 
    cashbotName: PropTypes.string.isRequired
};

export const ModalClose = injectIntl(ModalCloseLink);


const ModalFooter = (props) => {
    const { children, handleCloseModal, cashbotName } = props;
    return (
        <div className="modal-footer">
            { children || <ModalClose handleCloseModal={handleCloseModal} cashbotName={cashbotName} /> }
        </div>
    );
};

ModalFooter.defaultProps = {
    children: null,
    handleCloseModal: () => {},
    cashbotName: ''
};

ModalFooter.propTypes = {
    children: PropTypes.node,
    handleCloseModal: PropTypes.func,
    cashbotName: PropTypes.string
};

export default ModalFooter;
