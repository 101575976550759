import { List as IList } from 'immutable';
import { createSelector } from 'reselect';
import {
    getCardholderAgreementEnabled,
    getGdprTermsAcknowledgementEnabled,
    getUsePaymentMethodStep
} from '../brand/brandSelectors';
import {
    getCardholderAgreementAcceptance,
    getGdprTermsAgreementAcceptance
} from '../consent/consentSelectors';
import { cartStepCompleted } from '../cart/cartStepSelectors';


export const paymentMethodStepCompleted = createSelector(
    getUsePaymentMethodStep,
    getCardholderAgreementEnabled,
    getCardholderAgreementAcceptance,
    getGdprTermsAcknowledgementEnabled,
    getGdprTermsAgreementAcceptance,
    (
        usePaymentMethodStep,
        cardholderAgreementEnabled,
        cardholderAgreementAcceptance,
        gdprTermsAcknowledgementEnabled,
        gdprTermsAgreementAcceptance
    ) => {
        if (!usePaymentMethodStep) {
            return true;
        }
        if (cardholderAgreementEnabled && !cardholderAgreementAcceptance) {
            return false;
        }
        if (gdprTermsAcknowledgementEnabled && !gdprTermsAgreementAcceptance) {
            return false;
        }
        return true;
    }
);

export const paymentMethodPreConditions = IList([
    cartStepCompleted
]);
export const paymentMethodPostConditions = IList([
    paymentMethodStepCompleted
]);


export const paymentMethodStepSkippable = () => true;
