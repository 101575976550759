import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, FormattedMessage } from 'react-intl';
import { icons } from 'cstar-react-primitives/lib/redux-form/icons/iconConst';
import { SubHeader } from 'cstar-react-primitives/lib/redux-form/layout/Container';
import { TertiaryButton } from 'cstar-react-primitives/lib/redux-form/buttons/TertiaryButton';

import messageIntl from './messageIntl';


const MessageInspCollapsed = ({
    intl,
    onClick,
    doFocus
}) => {
    const containerProps = {
        role: 'region',
        'aria-label': intl.formatMessage(messageIntl.inspMessageRegionAriaLabel)
    };
    const tertiaryBtnProps = {
        btnText: intl.formatMessage(messageIntl.msgInspirationOpenLink),
        isSmall: false,
        isLeftAligned: false,
        icon: icons.CARET_DOWN,
        onClick,
        cashbotName: 'message-collapsed-link',
        atts: {
            'aria-expanded': 'false'
        },
        doFocus
    };
    return (
        <div {...containerProps}>
            <SubHeader heading={intl.formatMessage(messageIntl.msgInspirationOffer)} HeadingElement="h2" />
            <TertiaryButton {...tertiaryBtnProps}>
                <FormattedMessage {...messageIntl.msgInspirationOpenLink} />
            </TertiaryButton>
        </div>
    );
};

MessageInspCollapsed.defaultProps = {
    doFocus: false
};

MessageInspCollapsed.propTypes = {
    intl: intlShape.isRequired,
    onClick: PropTypes.func.isRequired,
    doFocus: PropTypes.bool
};

export default MessageInspCollapsed;
