import React, { useEffect, useState } from 'react';
import { getCapiSessionID } from 'capi/redux/immutable';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProductDetail from './ProductDetail';
import { loadTrackingImagePixel } from '../validate/isProfane';

const ProductDetailWrapper = (props) => {
    
    const [profanityFlag, setProfanityFlag] = useState(null);
    useEffect(() => {
        loadTrackingImagePixel(profanityFlag, props.capiSessionID);
    }, [profanityFlag]);

    return (
        <ProductDetail {...props} profanityFlag={profanityFlag} setProfanityFlag={setProfanityFlag} />
    );
};

ProductDetailWrapper.propTypes = {
    capiSessionID: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    capiSessionID: getCapiSessionID(state)
});

export default connect(mapStateToProps)(ProductDetailWrapper);
