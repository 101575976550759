import recipeientValidation from './RecipientValidation';
import physicalValidation from '../delivery/physicalValidation';
import { amountValidator } from '../amount/validate';

const validate = (values, props) => {
    const AmountValidationResult = amountValidator(values, props);
    const recipeintValidationResult = recipeientValidation(values, props);
    const isPlastic = values.get('isPlastic');
    let physicalValidationResult = {};
    if (isPlastic) {
        physicalValidationResult = physicalValidation(values, props);
    }
    return { ...AmountValidationResult, ...recipeintValidationResult, ...physicalValidationResult };
};

export default validate;
