import { actionTypes } from 'redux-form/lib/immutable';
import { change } from 'redux-form/immutable';
import {
    call,
    put,
    takeEvery,
    select
} from 'redux-saga/effects';

import { createActionPatternForForm, getFormChangeDetails } from '../utils/reduxFormUtils';
import {
    fieldChanged,
    formInitialized,
    formReset,
    fieldChangedAction,
    FORM_INITIALIZED,
    CHANGE_VALUE
} from './paymentFormModule';
import {
    PAYMENT_FORM_NAME,
    ADDRESS_COUNTRY,
    ADDRESS_STATE
} from './paymentConstants';
import { paymentFormCountry } from './paymentSelectors';
import { getExcludedBillingStates, getFilteredStatesFromExcludedBillingStates } from '../utils/addressUtils';
import { COUNTRY_FIELDS } from '../addressFields/addressFieldsModule';

const paymentFormActionPattern = createActionPatternForForm(PAYMENT_FORM_NAME);
const COUNTRY_FIELD_CHANGED = fieldChangedAction(ADDRESS_COUNTRY);

export function* getFilteredStates() {
    const excludedBillingStates = yield select(getExcludedBillingStates);
    return getFilteredStatesFromExcludedBillingStates(excludedBillingStates);
}

export function* setStateValueByCountry(countryFieldChangedAction) {
    const initCountry = yield select(paymentFormCountry);
    const filteredStates = yield getFilteredStates();
    
    if (initCountry === countryFieldChangedAction.newValue) {
        return;
    }

    if (countryFieldChangedAction.newValue === COUNTRY_FIELDS.UNITED_STATES && filteredStates.length === 1) {
        yield put(change(PAYMENT_FORM_NAME, ADDRESS_STATE, filteredStates[0]));
    } else {
        yield put(change(PAYMENT_FORM_NAME, ADDRESS_STATE, ''));
    }
}

export function* setDefaultStateValue() {
    const initCountry = yield select(paymentFormCountry);
    const filteredStates = yield getFilteredStates();

    if (initCountry === COUNTRY_FIELDS.UNITED_STATES && filteredStates.length === 1) {
        yield put(change(PAYMENT_FORM_NAME, ADDRESS_STATE, filteredStates[0]));
    }
}


function* changeValue({ fieldName, value }) {
    yield put(change(PAYMENT_FORM_NAME, fieldName, value));
}

export function* paymentFormActionWatcher() {
    
    yield takeEvery(paymentFormActionPattern(actionTypes.CHANGE), function* handleFormChange(action) {
        const changeDetails = yield call(getFormChangeDetails, action);
        yield put(fieldChanged(changeDetails.field, changeDetails.value));
    });

    
    yield takeEvery(paymentFormActionPattern(actionTypes.INITIALIZE), function* handleFormInitialize() {
        yield put(formInitialized());
    });

    
    yield takeEvery(paymentFormActionPattern(actionTypes.RESET), function* handleFormReset() {
        yield put(formReset());
    });
    yield takeEvery(FORM_INITIALIZED, setDefaultStateValue);
    yield takeEvery(COUNTRY_FIELD_CHANGED, setStateValueByCountry);
    yield takeEvery(CHANGE_VALUE, changeValue);
}
