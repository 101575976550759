
import { defineMessages } from 'react-intl';

const pluginsMessages = defineMessages({
    twitterFeedHeader: {
        id: 'plugins.twitterFeed.twitterFeedHeader',
        description: 'TwitterFeed plugin header text',
        defaultMessage: '[INTL] Tweets by {twitterUsername}'
    }
});

export default pluginsMessages;
