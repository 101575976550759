import swearjar from 'swearjar-extended2';
import profaneWords from './profaneWordsList';



swearjar._badWords = profaneWords;

const isProfaneRule = (errorMessage, isProfanityDetectionEnabled, profanityFlag, setProfanityFlag) => (value) => {
    if (!isProfanityDetectionEnabled) return null;
    if (!value) return null;

    
    const profanityDetails = swearjar.detailedProfane(value);
    if (profanityDetails.profane) {
        const words = Object.keys(profanityDetails.wordCount);
        let curseWords = '';
        words.map((val, index) => {
            curseWords = index < (words.length - 1) ? `${curseWords + val}, ` : `${curseWords + val}`;
            return curseWords;
        });
        setProfanityFlag(true);
        return {
            ...errorMessage,
            values: {
                words: curseWords
            }
        };
    }
    if (profanityFlag) {
        setProfanityFlag(false);
    }
    return null;
};

export default isProfaneRule;



export function loadTrackingImagePixel(status, capiSessionID) {
    if (status === null) return;
    const profanityStatus = status ? 'DETECTED' : 'CLEARED';

    let url = '/static/img/empty.png';
    url = `${url}?profanityStatus=${profanityStatus}&sessionID=${capiSessionID}`;

    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.send();
}
