import { startApplePaySession } from 'capi';
import {
    call,
    put,
    select,
    takeLatest
} from 'redux-saga/effects';

import { getCapiClient } from '../session/sessionSelectors';
import { logError } from '../utils/errorUtils';
import { processPayment } from '../payment/paymentModule';
import senderConstraints from '../sender/senderConstraints';
import validateFieldsByRules from '../validate/ruleValidator';

import {
    ABORT_APPLE_PAY_SESSION,
    COMPLETE_MERCHANT_VALIDATION,
    COMPLETE_APPLE_PAY_PAYMENT,
    FETCH_MERCHANT_SESSION,
    abortApplePaySession,
    completeMerchantValidation
} from './applePayModule';
import { APPLE_PAY_PAYMENT_TYPE, getContactValidationMessages, mapContact } from './applePaySerializers';


export function* fetchMerchantSession(action) {
    const applePaySession = action.validateMerchantEvent.target;

    try {
        const capiClient = yield select(getCapiClient);
        const merchantSession = yield call(startApplePaySession, capiClient,
            {
                domain_name: window && window.location && window.location.hostname,
                validation_url: action.validateMerchantEvent.validationURL
            });
        yield put(completeMerchantValidation(applePaySession, merchantSession));
    } catch (error) {
        yield call(logError, error);
        yield put(abortApplePaySession(applePaySession));
    }
}


export function* completeApplePayMerchantValidation(action) {
    const { applePaySession } = action;

    try {
        yield call([applePaySession, applePaySession.completeMerchantValidation], action.merchantSession);
    } catch (error) {
        yield call(logError, error);
        yield put(abortApplePaySession(applePaySession));
    }
}


const importAddressValidation = async () => import('../addressFields/addressValidation');


export function* completePayment(action) {
    const { getLocalizedFieldValidation, billingNameAndPhoneValidation } = yield call(importAddressValidation);
    const applePaySession = action.paymentEvent.target;
    const { billingContact, shippingContact } = action.paymentEvent.payment;
    const addressConstraints = yield call(getLocalizedFieldValidation, billingContact.countryCode);
    const mappedAppleAddressValuesToAppAddressValues = yield call(mapContact, billingContact, shippingContact);
    const mergedAddressAndBillingConstraints = addressConstraints
        .merge(billingNameAndPhoneValidation, senderConstraints);
    const errorValues = yield call(validateFieldsByRules, mappedAppleAddressValuesToAppAddressValues,
        mergedAddressAndBillingConstraints);
    const errorKeys = Object.keys(errorValues);
    const billingContactValidationErrorsList = yield call(getContactValidationMessages,
        action.validationMessages);
    try {
        if (errorKeys.length > 0) {
            const applePayErrors = billingContactValidationErrorsList
                .map((validationError) => {
                    const { code, contactField, message } = validationError.errorArgs;
                    return errorKeys.includes(validationError.field)
                        ? new window.ApplePayError(code, contactField, message) : null;
                })
                .filter(validationError => validationError !== null);
            yield call([applePaySession, applePaySession.completePayment], {
                status: applePaySession.STATUS_FAILURE,
                errors: applePayErrors
            });
        } else {
            yield put(processPayment(APPLE_PAY_PAYMENT_TYPE, action.paymentEvent.payment));
            yield call([applePaySession, applePaySession.completePayment], { status: applePaySession.STATUS_SUCCESS });
        }
    } catch (error) {
        yield call(logError, error);
        yield put(abortApplePaySession(applePaySession));
    }
}


export function* abortSession(action) {
    const { applePaySession } = action;

    try {
        yield call([applePaySession, applePaySession.abort]);
    } catch (error) {
        yield call(logError, error);
    }
}


export function* applePaySagasWatcher() {
    yield takeLatest(FETCH_MERCHANT_SESSION, fetchMerchantSession);
    yield takeLatest(COMPLETE_MERCHANT_VALIDATION, completeApplePayMerchantValidation);
    yield takeLatest(COMPLETE_APPLE_PAY_PAYMENT, completePayment);
    yield takeLatest(ABORT_APPLE_PAY_SESSION, abortSession);
}
