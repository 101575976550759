import { Set as ISet } from 'immutable';

import {
    UYO_STEPS,
    getUYOPath
} from '../routing/flow';
import { push } from '../routing/routing';
import {
    hasDigital as hasDigitalFaceplates,
    hasPlastic as hasPlasticFaceplates,
    hasSingleFaceplateForNewItem,
    isFaceplateDigital,
    isFaceplatePhysical
} from '../faceplate/faceplateSelectors';
import { programIsActive } from '../program/programSelectors';
import { canUploadUYOPhoto } from '../item/itemSelectors';
import { removeUYOData } from '../uyo/uyoModule';
import { FACEPLATE_FIELD, newItemFaceplate, newItemIsPlastic } from '../item/newItemForm';

import choiceMessages from './choiceMessages';
import { designStepPostConditions, designStepPreConditions, designStepSkippable } from './designStepSelectors';
import { brandCatalogIsLoaded } from '../brand/brandSelectors';
import { getHomePath } from '../routing/flowSelectors';



export const validateFaceplateAndCardType = (state) => {
    const isPlastic = newItemIsPlastic(state);
    const faceplateCode = newItemFaceplate(state);

    if (
        (!isPlastic && isFaceplateDigital(state)(faceplateCode))
        || (isPlastic && isFaceplatePhysical(state)(faceplateCode))
    ) {
        return true;
    }
    return false;
};


const designStepConfig = {
    component: () => import('./DesignStep'),
    flow: 'buy',
    flowProps: () => {
        const uyoPath = `${getUYOPath()}-${UYO_STEPS.INIT}`;

        return {
            goToUYO: () => { push({ pathname: uyoPath }); }
        };
    },
    isReady: (state) => {
        const catalogLoaded = brandCatalogIsLoaded(state);
        const hasElect = hasDigitalFaceplates(state);
        const hasPlastic = hasPlasticFaceplates(state);

        return catalogLoaded && (hasElect || hasPlastic);
    },
    canLoad: (state, ownProps) => {
        const onlyOne = hasSingleFaceplateForNewItem(state);
        const programActive = programIsActive(state);
        const isUYOSupported = canUploadUYOPhoto(state);
        const skipStep = programActive && onlyOne && !isUYOSupported;
        
        const homePath = validateFaceplateAndCardType(state) ? '' : getHomePath(state);
        let flowProgress = null;
        if (skipStep) {
            
            flowProgress = ISet([FACEPLATE_FIELD]);
        }
        return { isAllowedOrPath: (homePath || !skipStep || ownProps.submitPath), flowProgress };
    },
    onExit: dispatch => (
        
        
        
        dispatch(removeUYOData())
    ),
    preConditions: designStepPreConditions,
    postConditions: designStepPostConditions,
    getSubmitMessage: ({ isPlastic }) => (
        isPlastic
            ? choiceMessages.submitButtonPhysicalText
            : choiceMessages.submitButtonDigitalText),
    canSkip: state => (
        designStepSkippable(state)
    )
};

export default designStepConfig;
