import { Map as IMap } from 'immutable';

export const currencyToCountry = IMap({
    AUD: 'AU',
    CAD: 'CA',
    CHF: 'CH',
    GBP: 'GB',
    HKD: 'HK',
    NZD: 'NZ',
    SEK: 'SE',
    USD: 'US',
    JPY: 'JP'
});

export default currencyToCountry;
