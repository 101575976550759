import React from 'react';

import postalCodeNormalizerJP from '../validate/postalCodeNormalizerJP';
import { addressFieldNames as fields } from '../address/addressModule';
import {
    defaultFieldProps,
    addressPropTypes
} from './addressFieldsModule';

import { fieldValidatorsJP } from './addressValidation';
import addressFields from './addressFieldMessages';
import japanPrefectureMap from './data/JPPrefectureMap';
import {
    Street1Field,
    Street2Field,
    CityField,
    StateSelectField,
    PostalCodeField
} from './Fields';


export const makePrefectureList = (intl) => {
    const prefectures = Object.keys(japanPrefectureMap);
    const placeholder = {
        value: '',
        label: intl.formatMessage(addressFields.JP.stateLabel)
    };

    return [placeholder].concat(prefectures.map(code => ({
        value: code,
        label: intl.formatMessage(japanPrefectureMap[code])
    })));
};

export const AddressFieldsJP = ({
    addressContext,
    cashbotName,
    doFieldValidation,
    intl,
    showUntouchedErrors,
    updateShippingAddress,
    validators,
    countryCode
}) => {
    const commonFieldProps = {
        addressContext,
        cashbotName,
        doFieldValidation,
        intl,
        showUntouchedErrors
    };
    const street1Props = {
        ...commonFieldProps,
        label: addressFields.JP.street1Label,
        updateShippingAddress,
        useAddressAutocomplete: false, 
        isRequiredField: true,
        validate: validators.get(fields.ADDRESS_STREET1),
        countryCode
    };
    const street2Props = {
        ...commonFieldProps,
        validate: validators.get(fields.ADDRESS_STREET2),
        label: addressFields.JP.street2Label
    };
    const cityProps = {
        ...commonFieldProps,
        isRequiredField: true,
        label: addressFields.DEFAULT.cityLabel,
        validate: validators.get(fields.ADDRESS_CITY)
    };
    const stateProps = {
        ...commonFieldProps,
        isRequiredField: true,
        label: addressFields.JP.stateLabel,
        options: makePrefectureList(intl),
        validate: validators.get(fields.ADDRESS_STATE)
    };
    const postalCodeProps = {
        ...commonFieldProps,
        isRequiredField: true,
        label: addressFields.JP.postalCodeLabel,
        normalize: postalCodeNormalizerJP,
        validate: validators.get(fields.ADDRESS_POSTCODE)
    };

    return (
        <React.Fragment>
            <PostalCodeField {...postalCodeProps} />
            <StateSelectField {...stateProps} />
            <CityField {...cityProps} />
            <Street1Field {...street1Props} />
            <Street2Field {...street2Props} />
        </React.Fragment>
    );
};

AddressFieldsJP.defaultProps = {
    ...defaultFieldProps,
    validators: fieldValidatorsJP
};

AddressFieldsJP.propTypes = {
    ...addressPropTypes
};

export default AddressFieldsJP;
