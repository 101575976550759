import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import { LinkButton } from 'cstar-react-primitives/lib/redux-form/buttons/LinkButton';

export const messages = defineMessages({
    sendLink: {
        id: 'futureSend.time.sendTime.sendLink',
        description: 'Link text to display fields to select delivery hour and timezone',
        defaultMessage: 'Send at a specific time?'
    }
});

export const SendTimeLink = ({
    cashbotName,
    onClick,
    doFocus
}) => {
    const linkProps = {
        doFocus,
        onClick,
        cashbotName,
        atts: {
            'aria-expanded': 'false'
        }
    };
    return (
        <LinkButton {...linkProps}>
            <FormattedMessage {...messages.sendLink} />
        </LinkButton>
    );
};

SendTimeLink.defaultProps = {
    doFocus: false
};

SendTimeLink.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    doFocus: PropTypes.bool,
    onClick: PropTypes.func.isRequired
};


export default SendTimeLink;
