import { defineMessages } from 'react-intl';


const addressMessages = defineMessages({
    fullNameLabel: {
        id: 'address.addressMessages.fullNameLabel',
        description: 'Label for a full name field on an address form.',
        defaultMessage: 'Full Name'
    },
    firstNameLabel: {
        id: 'address.addressMessages.firstNameLabel',
        description: 'Label for a first name field on an address form',
        defaultMessage: 'First Name'
    },
    lastNameLabel: {
        id: 'address.addressMessages.lastNameLabel',
        description: 'Label for a last name field on an address form',
        defaultMessage: 'Last Name'
    },
    countryLabel: {
        id: 'address.addressMessages.countryLabel',
        description: 'Label for a country field on an address form',
        defaultMessage: 'Country/Territory'
    },
    street1Label: {
        id: 'address.addressMessages.street1Label',
        description: 'Label for an address 1 field on an address form',
        defaultMessage: 'Street Address'
    },
    street2Label: {
        id: 'address.addressMessages.street2Label',
        description: 'Label for an address 2 field on an address form',
        defaultMessage: 'Apt, Suite, Etc (optional)'
    },
    cityLabel: {
        id: 'address.addressMessages.cityLabel',
        description: 'Label for a city field on an address form',
        defaultMessage: 'City'
    },
    stateLabel: {
        id: 'address.addressMessages.stateLabel',
        description: 'Label for a state field on an address form',
        defaultMessage: 'State'
    },
    postalCodeLabel: {
        id: 'address.addressMessages.postalCodeLabel',
        description: 'Label for a postal field on an address form',
        defaultMessage: 'Postal Code'
    },
    phoneNumberLabel: {
        id: 'address.addressMessages.phoneNumberLabel',
        description: 'Label for a phone number field on an address form',
        defaultMessage: 'Phone Number'
    },
    fullNameRequired: {
        id: 'address.addressMessages.fullNameRequired',
        description: 'Error message displayed when the full name was not provided on an address form',
        defaultMessage: 'Please enter your full name as it appears on your credit card'
    },
    firstNameRequired: {
        id: 'address.addressMessages.firstNameRequired',
        description: 'Error message displayed when the first name was not provided on an address form',
        defaultMessage: 'Please enter your first name as it appears on your credit card'
    },
    lastNameRequired: {
        id: 'address.addressMessages.lastNameRequired',
        description: 'Error message displayed when the last name was not provided on an address form',
        defaultMessage: 'Please enter your last name as it appears on your credit card'
    },
    street1Required: {
        id: 'address.addressMessages.street1Required',
        description: 'Error message displayed when the street address was not provided on an address form',
        defaultMessage: 'Please enter your billing address'
    },
    cityRequired: {
        id: 'address.addressMessages.cityRequired',
        description: 'Error message displayed when the city was not provided on an address form',
        defaultMessage: 'Please enter your billing city'
    },
    stateRequired: {
        id: 'address.addressMessages.stateRequired',
        description: 'Error message displayed when the state or province was not provided on an address form',
        defaultMessage: ' Please select your billing State or Province'
    },
    postCodeRequired: {
        id: 'address.addressMessages.postcodeRequired',
        description: 'Error message displayed when the postal or zip code was not provided on an address form',
        defaultMessage: 'Please enter your billing postal code'
    },
    phoneRequired: {
        id: 'address.addressMessages.phoneRequired',
        description: 'Error message displayed when a phone number was not provided on an address form',
        defaultMessage: 'Please enter a contact phone number'
    },
    phoneInvalid: {
        id: 'address.addressMessages.phoneInvalid',
        description: 'Error message displayed when an invalid phone number was provided on an address form',
        defaultMessage: 'Please enter a valid phone number containing between 10 and 15 digits'
    },
    postCodeInvalid: {
        id: 'address.addressMessages.postcodeInvalid',
        description: 'Error message displayed when an invalid postal or zip code was provided on an address form',
        defaultMessage: 'Please enter a valid postal code'
    },
    usePreviousAddress: {
        id: 'address.addressMessages.usePreviousAddress',
        description: 'Label for toggle to reuse an address that was previously entered',
        defaultMessage: 'Use Previous Address?'
    }
});

export default addressMessages;
