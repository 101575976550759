import React from 'react';
import PropTypes from 'prop-types';

const CardRow = ({
    imageSrc, altText, title, description
}) => (
    <div className='delivery-row'>
        <img className='faceplate-image' src={imageSrc} alt={altText} />
        <div className='delivery-info'>
            <h5>{title}</h5>
            <p>{description}</p>
        </div>
    </div>
);

CardRow.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default CardRow;
