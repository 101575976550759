import { List as IList } from 'immutable';


const selfBuyBlacklistBrands = IList([
    'EILEENFISHER',
    'MANDO',
    'PAPPASITOS',
    'PAPPASBBQ',
    'PAPPAS',
    'PAPPASBROS',
    'PAPPADEAUX',
    'STARBUCKSPROMO',
    'HOLTRENFREW',
    'BBUYCA',
    'BESTBUY',
    'DUNKINPROMO',
    'STOPANDSHOP',
    'GIANTFOOD',
    'GIANTFOODLAND',
    'MARTINS',
    'FIDELITYYOUTH'
]);

export default selfBuyBlacklistBrands;
