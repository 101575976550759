import { List as IList } from 'immutable';

import { createSelector } from 'reselect';
import { programStepCompleted } from '../program/programStepSelectors';
import { cardTypeStepCompleted } from '../cardType/cardTypeStepSelectors';
import { getFlowProgress, getPreferredRecipientEmail, getPreferredSelfBuy } from '../routing/flowSelectors';
import {
    getNewItemFormValues, newItemCountry, newItemIsPlastic, RECIPIENT_EMAIL_FIELD
} from '../item/newItemForm';
import { getLocalizedFieldValidation } from '../addressFields/addressValidation';
import validateFieldsByRules from '../validate/ruleValidator';



export const deliveryStepCompleted = createSelector(
    getFlowProgress,
    getNewItemFormValues,
    newItemIsPlastic,
    newItemCountry,
    (flowState, formValues, isPlastic, countryCode) => {
        if (!formValues) {
            
            
            return true;
        }
        if (!isPlastic) {
            return flowState.has(RECIPIENT_EMAIL_FIELD);
        }
        
        
        
        
        const addressConstraints = getLocalizedFieldValidation(countryCode);
        const errors = validateFieldsByRules(formValues, addressConstraints);
        return (Object.keys(errors).length === 0);
    }
);


export const deliveryStepPreConditions = IList([
    programStepCompleted,
    cardTypeStepCompleted
]);


export const deliveryStepPostConditions = IList([
    deliveryStepCompleted
]);

export const deliveryStepSkippable = createSelector(
    getPreferredRecipientEmail,
    newItemIsPlastic,
    getPreferredSelfBuy,
    (preferredRecipientEmail, cardTypeIsPlastic, isSelfBuy) => (
        (!!preferredRecipientEmail || !!isSelfBuy) && cardTypeIsPlastic === false
    ),
);

export const newItemRecipientEmail = createSelector(
    getNewItemFormValues,
    values => (values ? values.get(RECIPIENT_EMAIL_FIELD, '') : '')
);
