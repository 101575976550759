import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';

import { intlForProgram } from '../intl/intlSelectors';

import { getActiveProgramCode } from './programSelectors';



const PureProgramIntlProvider = ({
    children,
    programIntl
}) => {
    
    
    
    
    const memoizedProgramIntl = useMemo(
        () => programIntl,
        [programIntl.key, programIntl.formats]
    );
    return (
        <IntlProvider {...memoizedProgramIntl}>
            {children}
        </IntlProvider>
    );
};

PureProgramIntlProvider.defaultProps = {
    programIntl: {}
};

PureProgramIntlProvider.propTypes = {
    children: PropTypes.node.isRequired,
    programIntl: PropTypes.object
};

const mapStateToProps = (state) => {
    const programCode = getActiveProgramCode(state);
    const getProgramIntl = intlForProgram(state);

    return {
        programIntl: getProgramIntl(programCode)
    };
};

export default connect(mapStateToProps)(PureProgramIntlProvider);
