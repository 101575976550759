import 'react-dates/initialize'; 

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/dedupe';
import moment from 'moment-timezone';
import { injectIntl, intlShape } from 'react-intl';
import { SingleDatePicker, isSameDay } from 'react-dates';
import { HORIZONTAL_ORIENTATION, ANCHOR_LEFT } from 'react-dates/constants';

import { icons } from 'cstar-react-primitives/lib/redux-form/icons/iconConst';
import { FieldLabel } from 'cstar-react-primitives/lib/redux-form/microFields/FieldLabel';
import { FieldWrapper } from 'cstar-react-primitives/lib/redux-form/FieldWrapper';
import { FieldIconInset } from 'cstar-react-primitives/lib/redux-form/microFields/FieldIconsDisplay';
import { FieldDivWrapper } from 'cstar-react-primitives/lib/redux-form/layout/FieldLayout';
import {
    getValidationProps,
    getAllFieldElementIds
} from 'cstar-react-primitives/lib/redux-form/microFields/fieldUtils';
import { FieldValidationMessage } from 'cstar-react-primitives/lib/redux-form/microFields/FieldValidationMessage';
import { parseMonthFormat } from '../futureSendUtils';

import {
    isInclusivelyAfterDay,
    getCurrentDateTime,
    toDate,
    FORMATS
} from '../../utils/time/momentUtils';

import getPhrases from './getPhrases';
import { TimeZoneProp } from '../time/timeProps';
import datePickerMessages from './datePickerMessages';

export class DatePicker extends React.Component {
    constructor(props) {
        super(props);

        const { intl, input, timeZone } = props;

        
        this.initialDate = moment.tz(input.value, timeZone.get('zoneName'));

        this.state = {
            focused: false,
            date: this.initialDate
        };

        this.phrases = getPhrases(intl);

        this.onDateChange = this.onDateChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    onDateChange(date) {
        const { handleChange, handleReset } = this.props;

        this.setState({ date });
        handleChange(toDate(date));

        
        if (isSameDay(this.initialDate, date)) {
            handleReset();
        }
    }

    onFocusChange({ focused }) {
        const { input, meta } = this.props;

        this.setState({ focused });

        
        if (focused && !meta.active) {
            input.onFocus();
        }

        
    }

    onClose({ date }) {
        const { input, handleReset, setFocusSendTimeLink } = this.props;
        
        
        if (isSameDay(this.initialDate, date)) {
            handleReset();
            setFocusSendTimeLink(false);
        } else {
            input.onBlur(toDate(date));
            setFocusSendTimeLink(true);
        }
    }

    render() {
        const TODAY = getCurrentDateTime();
        const ONE_YEAR_FROM_TODAY = getCurrentDateTime().add(1, 'year');

        const isInThePast = day => (
            !isInclusivelyAfterDay(day, TODAY)
        );

        const isGreaterThanOneYearAway = day => (
            isInclusivelyAfterDay(day, ONE_YEAR_FROM_TODAY)
        );

        const isOutsideDayRange = day => (
            isInThePast(day) || isGreaterThanOneYearAway(day)
        );
        const {
            input, label, meta, cashbotName, intl
        } = this.props;
        const { focused, date } = this.state;

        const fieldIds = getAllFieldElementIds(input.name);
        const validationProps = getValidationProps(meta, input);
        const { isInvalid } = validationProps;

        const wrapForErrorStyle = {
            className: classNames({
                'datepicker-error': isInvalid
            })
        };
        const labelProps = {
            fieldName: input.name,
            label,
            isInvalid
        };
        const validationMsgProps = {
            id: fieldIds.validation.id,
            fieldName: input.name,
            validationProps
        };

        const datePickerProps = {
            
            id: fieldIds.input.id,
            date,
            focused,
            onDateChange: this.onDateChange,
            onFocusChange: this.onFocusChange,

            
            placeholder: null, 
            disabled: false,
            required: false,
            readOnly: true,
            showClearDate: false,
            customCloseIcon: null,
            keepFocusOnInput: true,

            
            orientation: HORIZONTAL_ORIENTATION,
            anchorDirection: ANCHOR_LEFT,
            horizontalMargin: 0,
            withPortal: false,
            withFullScreenPortal: false,
            initialVisibleMonth: null,
            numberOfMonths: 1,
            keepOpenOnDateSelect: false, 
            reopenPickerOnClearDate: false,
            onClose: this.onClose,

            
            onPrevMonthClick() {},
            onNextMonthClick() {},

            
            enableOutsideDays: false,
            isDayBlocked: () => false,
            isOutsideRange: isOutsideDayRange,
            isDayHighlighted: () => {},

            
            displayFormat: () => moment.localeData().longDateFormat(FORMATS.LOCALE_DATE),
            monthFormat: parseMonthFormat(intl),
            dayAriaLabelFormat: FORMATS.LOCALE_DAY_AND_DATE,
            
            phrases: this.phrases,
            screenReaderInputMessage: intl.formatMessage(datePickerMessages.keyboardNavigationInstructionsLabel)
        };

        return (
            <FieldWrapper input={input} cashbotName={cashbotName}>
                <FieldLabel {...labelProps} />
                <FieldDivWrapper>
                    <div {...wrapForErrorStyle}>
                        <SingleDatePicker {...datePickerProps} />
                        <FieldIconInset icon={icons.CALENDAR} />
                    </div>
                </FieldDivWrapper>
                <FieldValidationMessage {...validationMsgProps} />
            </FieldWrapper>
        );
    }
}

DatePicker.defaultProps = {
    handleChange: () => {},
    handleReset: () => {},
    setFocusSendTimeLink: () => {}
};

DatePicker.propTypes = {
    input: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    meta: PropTypes.object.isRequired,
    cashbotName: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    handleChange: PropTypes.func,
    handleReset: PropTypes.func,
    timeZone: TimeZoneProp.isRequired,
    setFocusSendTimeLink: PropTypes.func
};

export default injectIntl(DatePicker);
