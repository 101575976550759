import { Map as IMap, List as IList, fromJS } from 'immutable';
import { createSelector } from 'reselect';

import { getIntlCurrencyCode, messages } from '../intl/intlSelectors';
import { browserSupportsApplePay } from '../applePay/applePay';
import { OPTIONAL } from '../delivery/PhysicalDeliveryFormConstants';
import { FEATURES, createSelectorWhenEnabled } from '../utils/featureFlag';
import { getPaypalAuthFailed } from '../paypal/paypalModule';

import firstDataBrands from './data/firstDataBrands';
import selfBuyBlacklist from './data/selfBuyBlacklist';
import excludedShippingCountriesByBrand from './data/excludedShippingCountriesByBrand';
import excludedBillingCountriesByBrand from './data/excludedBillingCountriesByBrand';
import chooseForMeBlacklistBrands from './data/downloadAndPrintDigitalBlacklist';

import { getCookieConsentEnabled } from '../app/bootstrap';

export const MESSAGE_MAX_LEN_DIGITAL = 250;


export const getBrand = state => state.get('brand', IMap());


export const getBrandCatalogs = state => state.getIn(['brand', 'catalogs'], IMap());



export const hasClassicCatalogs = createSelector(
    getBrandCatalogs,
    brandCatalogs => !brandCatalogs.isEmpty()
);


export const getPreferencesLoadTime = createSelector(
    getBrand,
    brand => brand.get('preferencesConfigLoadTime')
);


export const preferencesAreLoaded = createSelector(
    getBrand,
    brand => brand.has('preferencesConfigLoadTime')
);


export const getBrandCatalogLoadTime = createSelector(
    getBrand,
    brand => brand.get('catalogConfigLoadTime')
);


export const brandCatalogIsLoaded = createSelector(
    getBrand,
    brand => brand.has('catalogConfigLoadTime')
);


export const getBrandPaymentConfigLoadTime = createSelector(
    getBrand,
    brand => brand.get('paymentConfigLoadTime')
);


export const brandPaymentConfigIsLoaded = createSelector(
    getBrand,
    brand => brand.has('paymentConfigLoadTime')
);


export const getBrandUrl = createSelector(
    getBrand,
    brand => brand.get('brandUrl')
);


export const getBrandName = createSelector(
    getBrand,
    brand => brand.get('brandName')
);


export const getBrandLegalName = createSelector(
    getBrand,
    brand => brand.get('brandLegalName')
);


export const getBrandCreditCardStatementName = createSelector(
    getBrand,
    brand => brand.get('creditCardStatementName')
);


export const getReplyToEmailDisplayName = createSelector(
    getBrand,
    brand => brand.get('replyToEmailDisplayName')
);



export const getViewBalanceUrl = createSelector(
    getBrand,
    brand => brand.get('balanceUrl')
);


export const getViewBalancePhone = createSelector(
    getBrand,
    brand => brand.get('balancePhoneNumber')
);


export const isAccessoriesEnabled = createSelector(
    getBrand,
    brand => brand.get('cardAccessoriesEnabled')
);


export const getAccessories = createSelector(
    getBrand,
    brand => brand.get('cardAccessories', IMap())
);


export const getDigitalAccessoriesList = createSelector(
    getAccessories,
    cardAccessories => cardAccessories.get('digitalAccessories', IList())
);


export const getDigitalAccessoryByOrdinal = createSelector(
    getDigitalAccessoriesList,
    digitalAccessories => (
        ordinal => digitalAccessories.find(accessory => accessory.get('egcOrdinal') === ordinal)
    )
);


export const getPhysicalAccessoriesList = createSelector(
    getAccessories,
    cardAccessories => cardAccessories.get('physicalAccessories', IList())
);


export const getPhysicalAccessoryByOrdinal = createSelector(
    getPhysicalAccessoriesList,
    physicalAccessories => (
        ordinal => physicalAccessories.find(accessory => accessory.get('plasticOrdinal') === ordinal)
    )
);


export const getBrandPlasticCurrencyList = createSelector(
    getBrand,
    brand => brand.get('plasticCurrencyList', IList())
);


export const getBrandElectronicCurrencyList = createSelector(
    getBrand,
    brand => brand.get('electronicCurrencyList', IList())
);


export const getRequireRecipientEmailForPhysicalGift = createSelector(
    getBrand,
    brand => brand.get('requireRecipientEmailForPhysicalGift', OPTIONAL)
);


export const orderingToken = createSelector(
    getBrand,
    brand => brand.get('orderingToken')
);


export const getBrandCode = createSelector(
    getBrand,
    brand => brand.get('brandCode')
);


export const getPromoBoxEnabled = createSelectorWhenEnabled(FEATURES.PROMO_CODE, false)(
    getBrand,
    brand => brand.get('promoBoxEnabled')
);



export const getIsBrandFirstData = createSelector(
    getBrandCode,
    brandCode => firstDataBrands.contains(brandCode)
);


export const getSelfBuyEnabled = createSelector(
    getBrandCode,
    brandCode => !selfBuyBlacklist.contains(brandCode)
);


export const getFooterLinks = createSelector(
    getBrand,
    brand => brand.get('footerLinks')
);


export const getCreditCardValidationData = createSelector(
    getBrand,
    brand => brand.get('creditCardValidationData', IMap())
);


export const getCreditCardTypeKeys = createSelector(
    getCreditCardValidationData,
    validationData => IList(validationData.keys())
);


export const getCreditCardTypeHasCvv = createSelector(
    getCreditCardValidationData,
    validationData => validationData.map(cardType => cardType.get('hasCvv'))
);


export const getCreditCardTypeHasExp = createSelector(
    getCreditCardValidationData,
    validationData => validationData.map(cardType => cardType.get('hasExp'))
);


export const getCreditCardTypeRegexes = createSelector(
    getCreditCardValidationData,
    validationData => validationData.map(cardType => fromJS({
        regex: cardType.get('regex'),
        isPlcc: cardType.get('isPlcc'),
        isCobrand: cardType.get('isCobrand')
    }))
);


export const getCreditCardEnabled = createSelector(
    getBrand,
    brand => brand.get('creditCardEnabled')
);


export const getApplePayEnabled = createSelector(
    getBrand, getIntlCurrencyCode,
    (brand, currencyCode) => brand.get('applePayEnabled') && browserSupportsApplePay() && currencyCode === 'USD'
);


export const getpaymentWidgetEnabledCurrencies = createSelector(
    getBrand,
    brand => brand.get('paymentWidgetEnabledCurrencies')
);


export const getMasterpassEnabled = createSelector(
    getBrand,
    brand => brand.get('masterpassEnabled')
);


export const getMasterpassCheckoutId = createSelector(
    getBrand,
    brand => brand.get('masterpassCheckoutId')
);


export const getPaypalEnv = createSelector(
    getBrand,
    brand => brand.get('paypalEnv', null)
);


export const getPaypalClientID = createSelector(
    getBrand,
    brand => brand.get('paypalClientID')
);


export const isPaypalEnabled = createSelector(
    [getPaypalEnv, getPaypalClientID, getPaypalAuthFailed],
    (env, clientID, authFailed) => Boolean(env && clientID && !authFailed)
);


export const isPaypalAllowedForCurrency = createSelector(
    [isPaypalEnabled, getIntlCurrencyCode],
    (isEnabled, currencyCode) => Boolean(isEnabled && currencyCode === 'USD')
);


export const getUsePaymentMethodStep = () => false;


export const getBrandSupportedCardTypes = createSelector(
    getBrand,
    brand => fromJS({
        supportsDigitalCards: brand.get('supportsDigitalCards'),
        supportsPlasticCards: brand.get('supportsPlasticCards')
    })
);

const getUYOEgcPhotoSupported = createSelectorWhenEnabled(FEATURES.UYO_PHOTO_DIGITAL, false)(
    getBrand,
    brand => brand.get('uploadEgcPhotoEnabled')
);

const getUYOEgcVideoSupported = createSelectorWhenEnabled(FEATURES.UYO_VIDEO_DIGITAL, false)(
    getBrand,
    brand => brand.get('uploadEgcVideoEnabled')
);

const getUYOPlasticPhotoSupported = createSelectorWhenEnabled(FEATURES.UYO_PHOTO_PHYSICAL, false)(
    getBrand,
    brand => brand.get('uploadPlasticPhotoEnabled')
);

export const getBrandSupportedUYO = createSelector(
    [getUYOEgcPhotoSupported, getUYOEgcVideoSupported, getUYOPlasticPhotoSupported],
    (supportsUYOEgcPhoto, supportsUYOEgcVideo, supportsUYOPlasticPhoto) => fromJS({
        supportsUYOEgcPhoto,
        supportsUYOEgcVideo,
        supportsUYOPlasticPhoto
    })
);

export const getUYOEgcPhotoBrandOverlayFaceplate = createSelector(
    getBrand,
    brand => brand.get('uploadEgcPhotoBrandOverlayFaceplate')
);

export const getUYOPlasticPhotoBrandOverlayFaceplate = createSelector(
    getBrand,
    brand => brand.get('uploadPlasticPhotoBrandOverlayFaceplate')
);

export const getNotifyOffersConfig = createSelector(
    getBrand,
    brand => brand.get('notifyOffers', IMap())
);

export const getSenderOptInEnabled = createSelector(
    getNotifyOffersConfig,
    offerConfig => offerConfig.get('senderEnabled', false)
);

export const getDefaultOptInValue = createSelector(
    getNotifyOffersConfig,
    offerConfig => offerConfig.get('senderDefaultValue', false)
);


export const getLoyaltyProgramConfig = createSelector(
    getBrand,
    brand => brand.get('loyaltyProgram', IMap())
);


export const getLoyaltyProgramEnabled = createSelector(
    getLoyaltyProgramConfig,
    loyaltyProgramConfig => loyaltyProgramConfig.get('loyaltyProgramEnabled', false)
);


export const getLoyaltyProgramValidator = createSelector(
    getLoyaltyProgramConfig,
    (loyaltyProgramConfig) => {
        let validatorRegexString = loyaltyProgramConfig.get('loyaltyNumberValidator', '');
        
        try {
            RegExp(validatorRegexString);
        } catch (error) {
            validatorRegexString = '';
        }
        return validatorRegexString;
    }
);


export const getBrandPaymentLegalDisclaimer = createSelector(
    messages,
    msgs => msgs.get('payment.brand.legalDisclaimer', '')
);


export const getConfiguredBillingCountries = createSelector(
    getBrand,
    brand => brand.get('allowedBillingCountries', IList())
);


export const getExcludedBillingCountries = createSelector(
    getBrandCode,
    brandCode => excludedBillingCountriesByBrand.get(brandCode, IList())
);


export const getAllowedBillingCountries = createSelector(
    getConfiguredBillingCountries,
    getExcludedBillingCountries,
    (allBillingCountries, excludedBillingCountries) => allBillingCountries.filter(
        shippingCountry => !excludedBillingCountries.includes(shippingCountry)
    )
);


export const getAllowsAllBillingCountries = createSelector(
    getBrand,
    brand => brand.get('allowAllInternationalBillingCountries')
);


export const getExcludedShippingCountries = createSelector(
    getBrandCode,
    brandCode => excludedShippingCountriesByBrand.get(brandCode, IList())
);


export const getConfiguredShippingCountries = createSelector(
    getBrand,
    brand => brand.get('shippingCurrenciesByCountry', IMap())
);


export const getAllowedShippingCountries = createSelector(
    [getConfiguredShippingCountries, getExcludedShippingCountries, getIntlCurrencyCode],
    (allShippingCountries, excludedShippingCountries, currencyCode) => allShippingCountries
        .filter(
            (currencies, shippingCountry) => (
                !excludedShippingCountries.includes(shippingCountry) && currencies.includes(currencyCode)
            )
        )
        .keySeq().toList()
);

export const getSupportedLocales = createSelector(
    getBrand,
    brand => brand.get('supportedLocales', IList())
);


export const getBrandDefaultLocale = createSelector(
    getBrand,
    brand => brand.get('defaultLocaleCode')
);


export const getShippingRecipientPhone = createSelector(
    getBrand, (brand) => {
        const physical = fromJS(brand.get('physical', IMap()));
        return physical ? physical.get('collectShippingPhoneNumber', false) : false;
    }
);

export const getCarrierMessageCharacterLimit = createSelector(
    getBrand,
    brand => brand.get('physicalMessageCharLimit')
);


export const maxMessageCharLength = createSelector(
    getCarrierMessageCharacterLimit,
    (state, props) => props.isPlastic,
    (physicalMessageLength, isPhysical) => (isPhysical ? physicalMessageLength : MESSAGE_MAX_LEN_DIGITAL)
);


export const getSMSEnabled = createSelector(
    getBrand,
    brand => brand.get('isRecipientSMSEnabled')
);


export const getSMSRecipientEnabled = createSelector(
    getBrand,
    brand => brand.get('isSMSDeliveryForRecipientEnabled')
);

export const getSMSDialingCountries = createSelector(
    getBrand,
    brand => brand.get('supportedSMSDialingCodes')
);


export const getGdprConfig = createSelector(
    getBrand,
    brand => brand.get('gdpr', IMap())
);


export const getGdprEnabled = createSelector(
    getGdprConfig,
    (gdprConfig) => {
        if (getCookieConsentEnabled()) {
            return false;
        }
        return gdprConfig.get('gdprEnabled', false);
    }
);


export const getGdprTermsAcknowledgementEnabled = createSelector(
    getGdprConfig,
    gdprConfig => gdprConfig.get('gdprTermsAcknowledgementEnabled', false)
);


export const getGdprTermsAcknowledgementFormat = createSelector(
    getGdprConfig,
    getGdprTermsAcknowledgementEnabled,
    (gdprConfig, gdprEnabled) => (gdprEnabled ? gdprConfig.get('gdprTermsAcknowledgementFormat', 'IMPLICIT') : null)
);


export const getCardholderAgreementConfig = createSelector(
    getBrand,
    brand => brand.get('cardholderAgreement', IMap())
);


export const getCardholderAgreementEnabled = createSelector(
    getCardholderAgreementConfig,
    cardHolderConfig => cardHolderConfig.get('cardholderAgreementEnabled', false)
);


export const getCardholderAgreementFormat = createSelector(
    getCardholderAgreementConfig,
    cardHolderConfig => cardHolderConfig.get('cardholderAgreementFormat')
);


export const getCartMaxLineItems = createSelector(
    getBrand,
    brand => brand.get('cartMaxLineItems')
);


export const getBrandExcludedBillingStates = createSelector(
    getBrand,
    brand => brand.get('excludedBillingStates', '')
);


export const getCartMaxNumPlasticCards = createSelector(
    getBrand,
    brand => brand.get('cartMaxNumPlasticCards')
);


export const getSelfBuyDeliverySMSEnabled = createSelector(
    getBrand,
    brand => brand.get('isSelfBuyDeliverySMSEnabled')
);


export const getProfanityDetectionEnabled = createSelector(
    getBrand,
    brand => brand.get('isProfanityDetectionEnabled', true)
);


export const getIsShareViaLinkDeliveryOptionEnabled = createSelector(
    getBrand,
    brand => brand.get('isShareViaLinkDeliveryOptionEnabled', false)
);


export const getIsShareViaLinkDefaultDeliveryOption = createSelector(
    getBrand,
    brand => brand.get('isShareViaLinkDefaultDeliveryOption', false)
);


export const getChooseForMeEnabled = createSelector(
    getBrandCode,
    brandCode => !chooseForMeBlacklistBrands.contains(brandCode)
);
